import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { DataCellEditBaseComponent } from '../data-cell-edit-base-component';
@Component({
  selector: 'app-data-cell-edit-text-area',
  template: `
    <div class="text-area" contenteditable="plaintext-only" [attr.data-test]="'input-' + property.slug" #inputField>
      {{ value }}
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      .text-area {
        font-size: 12px;
        line-height: normal;
        padding: 4px;
        position: absolute;
        min-width: calc(100% + 20px);
        min-height: 100px;
        left: -7px;
        top: -13px;
        border: solid 2px #00a0ff;
        z-index: 11;
        background: white;
        cursor: text;
        white-space: pre-wrap;
      }
      [contenteditable] {
        -webkit-user-select: text;
        user-select: text;
      }
    `,
  ],
})
export class DataCellEditTextAreaComponent extends DataCellEditBaseComponent implements AfterViewInit, OnDestroy {
  @ViewChild('inputField') input: ElementRef;
  currentValue = '';

  ngOnDestroy(): void {
    this.value = this.currentValue;
    super.handleChange();
  }

  ngAfterViewInit() {
    this.currentValue = this.value;
    this.focusEndOfText();
  }

  focusEndOfText() {
    // focus on the end of text.
    const el = this.input?.nativeElement;
    const selection = window.getSelection();
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(el);
    range.collapse(false);
    selection.addRange(range);
    el.focus();
  }

  @HostListener('document:keydown', ['$event'])
  handleEnterKey(event: KeyboardEvent = null) {
    event.stopPropagation();
    if (event.code === 'Tab') {
      this.endEdit();
    }
  }

  @HostListener('document:keydown.esc', ['$event'])
  handleEscapeKey(event: KeyboardEvent = null) {
    this.endEdit();
    event.stopPropagation();
  }

  @HostListener('document:keyup', ['$event'])
  handleEvents(event: KeyboardEvent = null) {
    const elem = event.target as HTMLElement;
    this.currentValue = elem.innerText;
    event.stopPropagation(); // stop propagating key event to other listeners
  }
}
