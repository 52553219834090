import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssortmentsActions, AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';
import { Assortment } from 'src/app/common/assortments/assortments-store/assortments.state';
import { PlansActions, RootStoreState } from 'src/app/root-store';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';

@Component({
  selector: 'app-create-plan',
  templateUrl: './create-plan.component.html',
  styleUrls: ['./create-plan.component.scss'],
})
export class CreatePlanComponent implements OnInit {
  public targetAssortments$: Observable<Array<Assortment>>;
  public sourceAssortments$: Observable<Array<Assortment>>;
  public sourceWorkspaces$: Observable<Array<Assortment>>;
  public nameFormControl = new UntypedFormControl('', Validators.required);
  public targetAssortmentFormControl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
  public sourceAssortmentFormControl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
  public sourceWorkspaceControl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
  public sourceMode = 'unrestricted';
  public targetMode = 'new';
  public initFromSource = false;

  public step = 'name';
  constructor(
    private store: Store<RootStoreState.State>,
    public dialogRef: MatDialogRef<CreatePlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.targetAssortments$ = this.store.select(AssortmentsSelectors.assortments);
    this.sourceWorkspaces$ = this.store.select(WorkspacesSelectors.workspaces);

    this.store.dispatch(AssortmentsActions.loadAssortments());
  }

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close();
  }
  save() {
    const plan: any = {
      name: this.nameFormControl.value,
      targetAssortmentId: this.targetAssortmentFormControl.value?.id,
    };

    const sourceAssortment = this.sourceAssortmentFormControl.value;
    if (sourceAssortment) {
      plan.sourceAssortmentIds = [this.sourceAssortmentFormControl.value.id];
      plan.initFromSource = this.initFromSource;
    }
    const targetAssortment = this.targetAssortmentFormControl.value;
    if (targetAssortment) {
      plan.targetAssortmentId = targetAssortment.id;
    }
    console.log('new plan: ', plan);
    this.store.dispatch(PlansActions.createPlan({ plan }));
    this.dialogRef.close();
  }
  isStateValid() {
    if (!this.nameFormControl.valid) {
      return false;
    }
    if (this.targetMode === 'existing' && !this.targetAssortmentFormControl.value) {
      return false;
    }
    if (this.sourceMode === 'restricted' && !this.sourceAssortmentFormControl.value) {
      return false;
    }
    return true;
  }
  isSourceValid() {
    if (this.sourceMode === 'unrestricted') {
      return true;
    }
    return this.sourceWorkspaceControl.value && this.sourceAssortmentFormControl.value;
  }
  isTargetValid() {
    return true;
  }

  changeSourceMode(event) {
    const value = event.value;
    if (value === 'restricted') {
      this.sourceWorkspaceControl.enable();
    } else {
      this.sourceAssortmentFormControl.setValue(null);
      this.sourceWorkspaceControl.setValue(null);
      this.sourceWorkspaceControl.disable();
      this.sourceAssortments$ = null;
    }
  }

  changeTargetMode(event) {
    const value = event.value;
    if (value === 'existing') {
      this.targetAssortmentFormControl.enable();
      this.sourceAssortmentFormControl.setValue(null);
    } else {
      this.targetAssortmentFormControl.setValue(null);
      this.targetAssortmentFormControl.disable();
    }
  }

  initSourceAssortments(workspaceId) {
    this.sourceAssortments$ = from(
      new Entities().get({
        entityName: 'assortment',
        criteria: { rootWorkspaceId: workspaceId, isArchived: false },
      }),
    ).pipe(
      map((assortments) => {
        return assortments
          .filter((a) => a.name?.indexOf('Backing') < 0)
          .filter((a) => a.assortmentType !== 'BACKING')
          .sort((a1, a2) => (a1.name?.toUpperCase() > a2.name?.toUpperCase() ? 1 : -1));
      }),
    );
  }
  handleWorkspaceChange(workspace) {
    if (workspace) {
      this.initSourceAssortments(workspace.id);
      this.sourceAssortmentFormControl.enable();
    } else {
      this.sourceAssortmentFormControl.disable();
    }
  }
}
