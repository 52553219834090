import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DataCellViewBaseComponent } from '../data-cell-view-base-component';

@Component({
  selector: 'app-data-cell-view-image',
  template: `
    <!-- {{value}} -->
    <img *ngIf="value" [attr.src]="value | secureImage | async" class="thumbnail" (mouseover)="handleHover($event)" />
    <div class="imageView" *ngIf="value && showHover" #imageView>
      <img [attr.src]="previewUrl || value | secureImage | async" />
    </div>
  `,
  styleUrls: ['./data-cell-view-image.component.scss'],
})
export class DataCellViewImageComponent extends DataCellViewBaseComponent implements OnInit {
  @Input() value;
  @Input() previewUrl;

  @ViewChild('imageView', { static: false }) imageView: ElementRef;
  public showHover = false;
  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {}

  handleHover(event) {
    this.showHover = true;
    const xPosition = this.elementRef.nativeElement.getBoundingClientRect().x + 20;
    let yPosition = this.elementRef.nativeElement.getBoundingClientRect().y + 20;
    const maxDim = window.innerHeight * 0.25 > 200 ? 200 : window.innerHeight * 0.25;
    if (yPosition + maxDim > window.innerHeight) {
      yPosition = yPosition - maxDim - 50; // show image above thumbnail
    }

    this.changeDetectorRef.detectChanges();
    if (xPosition + maxDim > window.innerWidth) {
      this.imageView.nativeElement.style.setProperty('right', '20px');
    } else {
      this.imageView.nativeElement.style.setProperty('left', `${xPosition}px`);
    }
    this.imageView.nativeElement.style.setProperty('top', `${yPosition}px`);
    this.imageView.nativeElement.style.setProperty('max-width', `${maxDim}px`);
    this.imageView.nativeElement.style.setProperty('max-height', `${maxDim}px`);
  }
}
