<div
  class="cell"
  [ngClass]="{
    right: columnDefinition.alignment === 'right',
    left: columnDefinition.alignment === 'left',
    center: columnDefinition.alignment === 'center'
  }"
>
  <div class="value">
    <ng-container *ngIf="!isImage">
      <span
        *ngIf="shortenCellCharacterLimit"
        class="w-full shortened-text-container"
        [matTooltip]="display"
        [matTooltipDisabled]="display?.length <= shortenCellCharacterLimit"
      >
        {{ display | shorten: shortenCellCharacterLimit }}
      </span>

      <span *ngIf="!shortenCellCharacterLimit">
        {{ display }}
      </span>
    </ng-container>

    <span *ngIf="isImage">
      <img *ngIf="isIcon" [src]="columnDefinition.constantValue || value" />
      <ng-container *ngIf="value && !isIcon">
        <img [attr.src]="value | secureImage | async" class="thumbnail-image" />
      </ng-container>
    </span>
  </div>
</div>
