import { Component, Input, OnInit } from '@angular/core';
import { Plan } from 'src/app/plans/plans-store/plans.state';
@Component({
  selector: 'app-compare-pie-chart',
  templateUrl: './compare-pie-chart.component.html',
  styleUrls: ['./compare-pie-chart.component.scss'],
})
export class ComparePieChartComponent implements OnInit {
  @Input() plan: Plan;
  @Input() aggreate: string;

  chartOptions = {
    chart: {},
    colors: ['#2085ec', '#72b4eb', '#0a417a', '#8464a0', '#cea9bc'],
    pieHole: 0.4,
    legend: 'none',
  };

  chartType: any = 'PieChart';
  chartData: Array<any>;
  //  chartColumns = ['Date', 'Sessions', 'Users'];
  totalUsers = 0;
  totalSessions = 0;
  constructor() {}

  ngOnInit(): void {
    const counts = this.plan.planSummary.aggregates.collection.counts;
    this.chartData = Object.entries(counts);
  }
}
