import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { CollectionDataEntity } from '../../collection-manager.service';
import { HeaderColumnReorderService } from './header-column-reorder.service';
import { CollectionManagerViewService } from '../../collection-manager-view.service';
import { AuthService } from '@common/auth/auth.service';
import { AuthSelectors } from '@common/auth/auth-store';

@Component({
  selector: 'app-header-row',
  templateUrl: './header-row.component.html',
  styleUrls: ['./header-row.component.scss'],
})
export class HeaderRowComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data: Array<CollectionDataEntity>;
  @Input() properties: Array<ViewPropertyConfiguration>;
  public enabledProperties: Array<ViewPropertyConfiguration>;
  @Input() side: string;
  allSelected = false;
  selectedIdsSub: Subscription;
  selectedIds: Array<any>;
  alertProperty: any;
  isOrgAdmin = false;
  currentView$: Observable<any>;

  constructor(
    private headerRowService: HeaderColumnReorderService,
    private authService: AuthService,
    private viewService: CollectionManagerViewService,
    private store: Store<RootStoreState.State>,
  ) {
    this.selectedIdsSub = this.store.select(CollectionManagerSelectors.selectedEntityIds).subscribe((selectedIds) => {
      if (selectedIds && this.data) {
        this.selectedIds = selectedIds;
        if (this.selectedIds.length !== this.data.length && this.allSelected) {
          this.allSelected = false;
        } else if (this.selectedIds.length === this.data.length && !this.allSelected) {
          this.allSelected = true;
        }
      }
    });
    this.alertProperty = this.viewService.alertProperty;
    this.currentView$ = this.store.select(CollectionManagerSelectors.selectCurrentView);
  }

  async ngOnInit(): Promise<void> {
    this.store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => {
      this.isOrgAdmin = authContext?.currentOrg?.role === 'ADMIN';
    });
  }

  ngOnDestroy(): void {
    this.selectedIdsSub.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.properties) {
      this.enabledProperties = this.properties.filter((prop) => prop.enabled);
    }
  }

  selectAll() {
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      const ids = this.data.map((rowData) => rowData.id);
      this.store.dispatch(CollectionManagerActions.setSelectedEntityIds({ ids }));
    } else {
      this.store.dispatch(CollectionManagerActions.clearSelectedEntityIds());
    }
  }
}
