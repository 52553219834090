import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { AssortmentsSelectors } from '../../assortments/assortments-store';
import { AuthSelectors } from '../../auth/auth-store';
import { FilterUtil } from '../../util/filter-util';
import { ChooserFilterConfig, ItemDataChooserDataSource } from './item-data-chooser-data-source';
import { SortDefinition } from '../../components/sort/sort-definition';
import { FilterObjects } from '@contrail/filters';
import { FilterDefinition } from '@common/types/filters/filter-definition';

export class SourceAssortmentChooserDataSource extends ItemDataChooserDataSource {
  constructor(
    protected store: Store<RootStoreState.State>,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    protected dateFilterSubject: Observable<FilterDefinition>,
  ) {
    super(store, filterConfigSubject, sortConfigSubject, existingItemIdsSubject, showAllSubject, dateFilterSubject);

    this.initFilteredDataObservable();
    this.initResultsObservable();
  }

  protected async initFilteredDataObservable() {
    this.filteredData$ = combineLatest([
      this.filterConfigSubject,
      this.dateFilterSubject,
      this.sortConfigSubject,
      this.store.select(AssortmentsSelectors.sourceAssortmentItemData),
      this.store.select(AssortmentsSelectors.sourceAssortmentItemDataHiearchy),
      this.store.select(AuthSelectors.selectAuthContext),
    ]).pipe(
      switchMap(
        async ([
          filterConfig,
          dateFilter,
          sortConfig,
          sourceAssortmentItemData,
          sourceAssortmentItemDataHiearchy,
          auth,
        ]) => {
          if (!filterConfig) {
            return;
          }
          console.log('SourceAssortmentChooserDataSource: filteredDataObservable');

          let data;
          this.assortmentSubject.next(sourceAssortmentItemData);
          const criteria = filterConfig.baseCriteria;
          const searchTerm = filterConfig.searchTerm;
          const filterDefinition = filterConfig.filterDefinition;

          // SOURCE ASSORTMENT CONSTRAINT, OPTION LEVEL

          if (criteria.roles === 'option') {
            data = sourceAssortmentItemData;
            data = this.filterItemsByLevel(data, criteria);
            this.allUnfilteredSourceItemDataSubject.next(data);
            data = this.filterLocalItemData(
              { searchTerm, itemFamilyId: criteria.itemFamilyId },
              data,
              this.searchableProperties,
            );
          } else if (criteria.roles === 'family') {
            data = sourceAssortmentItemDataHiearchy;
            this.allUnfilteredSourceItemDataSubject.next(data);
            data = this.filterLocalItemData({ searchTerm }, data, this.searchableProperties);
          }
          if (filterDefinition?.filterCriteria) {
            data = FilterObjects.filter(data, filterDefinition.filterCriteria);
          }
          if (dateFilter) {
            data = this.filterByAssortmentItems(data, dateFilter);
          }
          data = FilterUtil.filterDroppedItems(data, auth?.currentOrg?.orgSlug);
          this.sortData(data, sortConfig);
          console.log('sourceAssortmentSearch: ', data);
          return data;
        },
      ),
    );
  }

  private filterItemsByLevel(items: any[], criteria: any) {
    if (!items) {
      return;
    }

    if (criteria?.roles === 'option') {
      return items.filter((itemData) => itemData.item.itemFamilyId !== itemData.item.id);
    }

    if (criteria?.roles === 'family') {
      return items.filter((itemData) => itemData.item.itemFamilyId === itemData.item.id);
    }

    return items;
  }
}
