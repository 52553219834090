import { State } from '../collection-manager.state';

import { on } from '@ngrx/store';
import * as CollectionElementActions from './collection-status-messages.actions';
import { collectionStatusEntityAdapter } from './collection-status-messages.state';

const setData = (state: State, { messages }) => {
  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.setAll(messages, state.collectionStatusMessages),
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.removeAll(state.collectionStatusMessages),
  };
};
const add = (state: State, { message }) => {
  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.addOne(message, state.collectionStatusMessages),
  };
};
const addMany = (state: State, { messages }) => {
  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.addMany(messages, state.collectionStatusMessages),
  };
};
const update = (state: State, { id, changes }) => {
  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.updateOne({ id, changes }, state.collectionStatusMessages),
  };
};
const updateMany = (state: State, { changes }) => {
  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.updateMany(changes, state.collectionStatusMessages),
  };
};
const remove = (state: State, { id }) => {
  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.removeOne(id, state.collectionStatusMessages),
  };
};
const removeMultiple = (state: State, { ids }) => {
  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.removeMany(ids, state.collectionStatusMessages),
  };
};

export const collectionStatusMessagesReducers = [
  on(CollectionElementActions.clearStatusMessages, clearData),
  on(CollectionElementActions.addStatusMessage, add),
  on(CollectionElementActions.addStatusMessages, addMany),
  on(CollectionElementActions.setStatusMessages, setData),
  on(CollectionElementActions.removeStatusMessage, remove),
  on(CollectionElementActions.removeStatusMessages, removeMultiple),
  on(CollectionElementActions.updateStatusMessages, updateMany),
];
