import { Injectable } from '@angular/core';
import { PropertyValueFormatter, TypeProperty } from '@contrail/types';
import { AnalyticsService } from '../../analytics/analytics.service';
import { saveAs } from 'file-saver';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';

const propertyValueFormatter = new PropertyValueFormatter();

@Injectable({
  providedIn: 'root',
})
export class LocalCsvExportService {
  constructor(private analyticsService: AnalyticsService) {}
  downloadFile(data: any, exportProperties: Array<TypeProperty>, ownerId: any, fileName = 'data') {
    fileName = (fileName + this.getCurrentDateStr() + '.csv').replace('[^a-zA-Z0-9\\.\\-]', '_');
    this.analyticsService.emitEvent({
      eventName: 'CSV_EXPORT',
      eventCategory: EVENT_CATEGORY.EXPORT,
      eventTarget: `plan:${ownerId}`,
      eventLabel: fileName,
    });
    const csvHeaders = [];
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here

    exportProperties.forEach((property) => {
      csvHeaders.push(property.label);
    });

    const csv = data.map((row) => {
      return exportProperties
        .map((column: TypeProperty) => {
          const cellValue = propertyValueFormatter.formatValue(row[column.slug], column.propertyType);
          return JSON.stringify(cellValue, replacer);
        })
        .join(',');
    });

    // console.log(`csv data before header ${JSON.stringify(csv)}`);

    csv.unshift(csvHeaders.join(','));
    const csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, fileName);
  }

  getCurrentDateStr() {
    const date = new Date();
    const dateString =
      '_' +
      date.getFullYear() +
      '-' +
      date.getMonth() +
      '-' +
      date.getDate() +
      '_' +
      date.getHours() +
      '_' +
      date.getMinutes() +
      '_' +
      date.getSeconds();
    return dateString;
  }
}
