import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { PivotGoals } from '../../pivot-goals';
@Component({
  selector: 'app-pivot-data-row',
  templateUrl: './pivot-data-row.component.html',
  styleUrls: ['./pivot-data-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PivotDataRowComponent implements AfterViewInit, OnInit, OnChanges {
  constructor() {}

  @Input() dataRow: any;
  @Input() properties: Array<ViewPropertyConfiguration>;
  @Input() groupingProperties: Array<ViewPropertyConfiguration>;
  @Input() side: string;
  @Input() rowNumber: number;
  @Input() hoveredRowId = '';
  @Input() goals: Array<PivotGoals>;
  @Input() showGoals: boolean = false;
  @Output() hoverRow = new EventEmitter();
  @Output() rowAction = new EventEmitter();
  @Output() onContextMenu = new EventEmitter();

  public rowId: string;
  public rowHeight = 24;
  public rowGoals: PivotGoals;
  totalWidth = 0;
  rightPropertiesViewPortWidth: number;

  public ngAfterViewInit(): void {}
  ngOnInit() {}
  ngOnChanges() {
    //console.log("row: ", this.properties, this.rowGoals, this.dataRow)
    if (this.dataRow) {
      this.rowId = this.dataRow.id;
      if (this.side === 'left' && this.properties?.length > 0) {
        this.totalWidth = this.properties[0].width || this.properties.length * 100;
      }
      this.setGoals();
    }
  }

  setGoals() {
    if (!this.goals) {
      return;
    }
    //console.log("setGoals: ", this.side, this.groupingProperties)
    // We want to find a goal in the supplied set of goals that matches this row's grouping properties.
    const groupingProperties = this.groupingProperties;
    //console.log("groupingProperties: ", groupingProperties);
    let matchedGoal: PivotGoals;
    let rowGroupingValues = {}; // Values from the row that match the grouping properties.
    for (let p of groupingProperties) {
      let val = this.dataRow[p.slug];
      if (val) {
        rowGroupingValues[p.slug] = this.dataRow[p.slug];
      }
    }
    //console.log("groupingValues: ", rowGroupingValues, this.side);
    const rowGroupingDimensionCount = Object.keys(rowGroupingValues)?.length;
    if (rowGroupingDimensionCount < 1) {
      return;
    }
    // find matching goal
    const rowGroupingValueEntries = Object.entries(rowGroupingValues);
    matchedGoal = this.goals.find((g) => {
      const goalGroupingValuesEntries = Object.keys(g.groupingProperties);
      //console.log("goal: ", g, rowGroupingValues, rowGroupingValueEntries.length, goalGroupingValuesEntries.length)

      // If the goal doesnt have the same property count as the row, then no match.
      if (rowGroupingValueEntries.length !== goalGroupingValuesEntries.length) {
        return false;
      }

      // Compare each value from the goal with the values from the row
      for (let [key, value] of rowGroupingValueEntries) {
        if (g.groupingProperties[key] !== value) {
          return false;
        }
      }
      return true;
    });
    if (matchedGoal) {
      //console.log("matchedGoal: ", matchedGoal);
      this.rowGoals = matchedGoal;
    }
  }

  trackByFn(index, property: ViewPropertyConfiguration) {
    return property.slug;
  }

  @HostListener('mouseenter', ['$event']) public mouseEnter(evt) {
    this.hoverRow.emit(this.dataRow.id);
  }

  @HostListener('mouseleave', ['$event']) public mouseLeave(evt) {
    this.hoverRow.emit('');
  }

  onRowAction(event) {
    this.rowAction.emit(event);
  }

  handleContextMenu(event) {
    this.onContextMenu.emit({
      event,
      row: this.dataRow,
    });
  }
}
