<div class="grid-header">
  <ng-container *ngIf="side !== 'left'">
    <app-pivot-column-header
      [style.minWidth.px]="property.width || 150"
      class="header-cell"
      [ngStyle]="{ height: (showGoals$ | async) ? '56px' : '24px' }"
      *ngFor="let property of properties"
      [property]="property"
      [showGoals]="showGoals"
      [viewDefinition]="viewDefinition"
      (resizeColumn)="performResizeColumn($event)"
    >
    </app-pivot-column-header>
  </ng-container>

  <app-pivot-column-header
    *ngIf="side === 'left'"
    [style.minWidth.px]="groupingProperty.width || totalWidth"
    class="header-cell"
    [ngStyle]="{ height: (showGoals$ | async) ? '56px' : '24px' }"
    [fixedLabel]="groupingHeader"
    [property]="groupingProperty"
    [showGoals]="showGoals"
    [viewDefinition]="viewDefinition"
    (resizeColumn)="performResizeColumn($event)"
    (toggleRows)="performToggleRows($event)"
  >
  </app-pivot-column-header>
</div>
