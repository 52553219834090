import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DataCellEditBaseComponent } from '../data-cell-edit-base-component';
@Component({
  selector: 'app-data-cell-edit-date',
  template: `
    <div class="date-picker">
      <input
        [attr.data-test]="'input-' + property.slug"
        #inputField
        (change)="handleChange()"
        (input)="isValidInput()"
        (dblclick)="handleOpenPicker($event)"
        [(ngModel)]="value"
        matInput
        [matDatepicker]="picker"
        placeholder="mm/dd/yyyy"
        [ngClass]="{ error: !isValidCellValue, valid: isValidCellValue }"
      />
      <mat-datepicker #picker (closed)="pickerClosed()" (opened)="pickerOpened()"></mat-datepicker>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        justify-content: flex-end;
      }
      .date-picker {
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 20px;
        display: flex;
      }
      input {
        border: none;
        font-size: 12px;
        line-height: normal;
        padding: 0px;
        width: 95%;
        justify-content: flex-end;
        padding: 4px;
        text-align: left;
      }
      .error {
        color: red;
      }
      .valid {
        color: black;
      }
    `,
  ],
})
export class DataCellEditDateComponent extends DataCellEditBaseComponent implements AfterViewInit {
  @ViewChild('inputField') input: ElementRef;
  @ViewChild('picker') picker: MatDatepicker<Date>;
  @Input() valueCleared = false;
  editing = false;
  isValidCellValue = true;
  public adjustedValue;
  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.focusOnCell.emit(this.input?.nativeElement);
    setTimeout(() => {
      if (!this.valueCleared) {
        // Show the date picker if a user clicks on it. 'valueCleared' is true if the init happens when a user starts typing.
        // Do not show date picker if the initialization happens when a user starts typing.
        // this.picker.open();
      }
    }, 100);
  }
  ngOnChanges() {
    console.log('Date Edit: ', this.value, typeof this.value);
    if (this.value instanceof Date) {
      this.value = this.value.toISOString();
    }
    // NUETRALIZE TIMEZONE
    if (typeof this.value === 'string') {
      if (this.value.endsWith('Z')) {
        this.value = this.value.slice(0, -1);
      }
    }
  }

  handleChange() {
    let dateValue = this.input?.nativeElement.value;
    const re = new RegExp('^[0-9]{1,2}[-|/][0-9]{1,2}$'); // check for date entry of 1/22 or 1-22
    if (re.test(this.input?.nativeElement.value)) {
      dateValue = dateValue + '/' + new Date().getFullYear();
      console.log(dateValue);
      this.value = dateValue;
    }
    if (this.isValidInput()) {
      if (typeof this.value === 'string') {
        this.value = new Date(this.value);
      }
      if (this.value instanceof Date) {
        // console.log("handleChange: date", this.value)
        const isoDatePart = this.getIsoDatePart(this.value);
        const utCIso = isoDatePart + 'T00:00:00.000Z';
        this.value = utCIso;
      }
      super.handleChange();
    }
  }

  private getIsoDatePart(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  handleOpenPicker(event) {
    this.picker.open();
    event.preventDefault();
  }

  isValidInput(transformDate = false): boolean {
    // allow clearing the date
    if (!this.input?.nativeElement.value?.length) {
      return true;
    }
    const d = new Date(this.input?.nativeElement.value);
    if (d === null || isNaN(d.getTime()) || d.getTime() === 0) {
      this.isValidCellValue = false;
      this.input?.nativeElement.focus({ preventScroll: true });
    } else {
      this.isValidCellValue = true;
    }
    console.log(` this.isValidCellValue ${this.isValidCellValue}`);
    return this.isValidCellValue;
  }

  pickerOpened() {
    this.editing = true;
  }

  pickerClosed() {
    this.editing = false;
  }

  // @HostListener('document:keydown.enter', ['$event'])
  // handleEnterKey(event: KeyboardEvent = null) {
  //   let isCalendar = false;
  //   if (event.composedPath()[0] instanceof HTMLInputElement) {
  //     const element = event.composedPath()[0] as HTMLInputElement;
  //     if (element.getAttribute('data-mat-calendar')) {
  //       isCalendar = true;

  //     }
  //   }
  //   if (isCalendar && !this.picker.opened && !this.editing) {
  //     setTimeout(() => {
  //       this.picker.open();

  //     }, 100);
  //     event.stopPropagation();

  //   } else if (this.picker.opened && this.editing) {
  //     const target = event.target as HTMLElement;
  //     if (target.tagName === 'TD') { // datepicker date element is a TD.
  //       const selectedValue = parseInt(target.innerText, 10);
  //       if (!isNaN(selectedValue) && selectedValue <= 31) { // only select actual day, not month or year
  //         this.picker.close();
  //       }
  //     }
  //   }

  // }

  @HostListener('document:keydown', ['$event'])
  handleEvents(event: KeyboardEvent = null) {
    if (this.editing) {
      event.stopPropagation(); // stop propagating key event to other listeners
    }
  }
}
