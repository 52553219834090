import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebglModule } from '@common/webgl/webgl.module';
import { PipesModule } from '@common/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';

import { ContentPreviewComponent } from './components/content-preview.component';
import { ContentThumbnailComponent } from './components/content-thumbnail.component';
import { ContentTrayComponent } from './components/content-tray.component';
import { ContentUploadComponent } from './components/content-upload.component';
import { ComponentsModule } from '@common/components/components.module';
import { ContentCardComponent } from './components/content-card.component';
import { ContentHolderDetailsComponent } from './content-holder-details/content-holder-details.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ContextMenuModule } from '@common/components/context-menu/context-menu.module';
import { ContentHolderComponent } from './content-holder/content-holder.component';
import { ContentInfoPanelComponent } from './components/content-info-panel.component';
import { ContentModalHeaderComponent } from './content-modal/content-modal-header.component';
import { ContentModalComponent } from './content-modal/content-modal.component';
import { ContentModalCommentsComponent } from './content-modal/nav-components/content-modal-comments/content-modal-comments.component';
import { ContentModalDetailsComponent } from './content-modal/nav-components/content-modal-details/content-modal-details.component';
import { ContentModalEditorComponent } from './content-modal/nav-components/content-modal-editor/content-modal-editor.component';
import { ContentModalVersionsComponent } from './content-modal/nav-components/content-modal-versions/content-modal-versions.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { DirectivesModule } from '@common/directives/directives.module';

@NgModule({
  declarations: [
    ContentPreviewComponent,
    ContentThumbnailComponent,
    ContentTrayComponent,
    ContentUploadComponent,
    ContentCardComponent,
    ContentHolderDetailsComponent,
    ContentHolderComponent,
    ContentInfoPanelComponent,
    ContentModalHeaderComponent,
    ContentModalComponent,
    ContentModalDetailsComponent,
    ContentModalEditorComponent,
    ContentModalCommentsComponent,
    ContentModalVersionsComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    WebglModule,
    PipesModule,
    NgxDropzoneModule,
    VirtualScrollerModule,
    ContextMenuModule,
    ColorPickerModule,
    DirectivesModule,

    MatTooltipModule,
    MatIconModule,
    MatDividerModule,
    MatExpansionModule,
    MatMenuModule,
    MatSelectModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    DragDropModule,
  ],
  exports: [
    ContentPreviewComponent,
    ContentThumbnailComponent,
    ContentTrayComponent,
    ContentUploadComponent,
    ContentCardComponent,
    ContentHolderDetailsComponent,
    ContentHolderComponent,
  ],
})
export class ContentModule {}
