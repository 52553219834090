import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { SidePanelService, SidePanelView } from 'src/app/collection-manager/side-panel/side-panel.service';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../../collection-manager-store';

@Component({
  selector: 'app-vertical-scroller',
  templateUrl: './vertical-scroller.component.html',
  styleUrls: ['./vertical-scroller.component.scss'],
})
export class VerticalScrollerComponent implements AfterViewInit {
  constructor(
    private store: Store<RootStoreState.State>,
    private sidePanelService: SidePanelService,
  ) {
    this.currentViewSubject$ = this.sidePanelService.currentView;
  }
  public scrollBarHeight$: Observable<number>;
  private scrollSubject = new Subject();
  public barSize = 300;
  private currentScrollPercent = 0;

  currentViewSubject$: Observable<SidePanelView>;

  private isScrolling = false;
  scrollDragPosition = { x: 0, y: 0 };

  @Input() height: number;
  ngAfterViewInit(): void {
    this.scrollSubject
      .pipe(
        debounceTime(25),
        tap((event: any) => {
          this.handleScroll(event);
        }),
      )
      .subscribe();

    this.store.select(CollectionManagerSelectors.scrollVerticalPercentage).subscribe((percentage) => {
      this.setScrollBarPosition(percentage);
    });
    this.initScrollBarHeight();
  }
  initScrollBarHeight() {
    this.scrollBarHeight$ = combineLatest([
      this.store.select(CollectionManagerSelectors.totalRowHeight),
      this.store.select(CollectionManagerSelectors.rowViewPortHeight),
    ]).pipe(
      map(([totalRowHeight, viewPortHeight]) => {
        if (totalRowHeight < viewPortHeight) {
          return 0;
        }
        const viewedPercent = viewPortHeight / totalRowHeight;
        this.barSize = viewPortHeight * viewedPercent;
        return this.barSize;
      }),
    );
  }

  setScroll(event) {
    this.scrollSubject.next(event);
  }

  /** Note, we need this to set the position when scroll is initiated by other events,
   * such as mouse wheel or cursor movement. But the scroll bar itself is updating the
   * percentage on drag, so we need to use caution that we don't do something recursive or
   * strange.
   */
  setScrollBarPosition(scrollPercentage) {
    const scrollBar = document.querySelector('#scrollBar') as HTMLElement;
    if (scrollBar && !this.isScrolling) {
      const parentBox = scrollBar.parentElement.getBoundingClientRect();
      const availableDistance = parentBox.height - this.barSize;
      const newDistance = availableDistance * scrollPercentage;

      this.currentScrollPercent = scrollPercentage;
      // scrollBar.style.transform = `translate3d(0px, ${newDistance}px, 0px)`;
      this.scrollDragPosition = { x: this.scrollDragPosition.x, y: newDistance };
    }
    this.isScrolling = false;
  }
  handleScroll(event) {
    const element = event.source.getRootElement();
    const barBox = element.getBoundingClientRect();
    const parentBox = element.parentElement.getBoundingClientRect();
    const parentPosition = this.getPosition(element);
    const distanceFromTop = barBox.y - parentPosition.top;
    const availableDistance = parentBox.height - this.barSize;
    const percent = Math.abs(Math.round((distanceFromTop / availableDistance) * 100) / 100);
    this.currentScrollPercent = percent;
    this.isScrolling = true;
    this.store.dispatch(CollectionManagerActions.setScrollVerticalPercentage({ percentage: percent }));
  }
  getPosition(el) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }

  handleClick(event) {
    const element = event.srcElement.childNodes[0];
    if (!element) {
      return;
    }
    const barBox = element.getBoundingClientRect();
    const parentBox = element.parentElement.getBoundingClientRect();
    let distanceFromTop = 0;
    let factor = 1;
    // scroll down
    if (event.clientY >= barBox.y) {
      distanceFromTop = event.clientY - (barBox.y + this.barSize);
    } else {
      // scroll up
      distanceFromTop = barBox.y - event.clientY;
      factor = -1;
    }
    const availableDistance = parentBox.height - this.barSize;
    const newScrollPercent =
      this.currentScrollPercent + Math.abs(Math.round((distanceFromTop / availableDistance) * 100) / 100) * factor;

    this.currentScrollPercent = newScrollPercent;
    this.isScrolling = false;
    this.store.dispatch(CollectionManagerActions.setScrollVerticalPercentage({ percentage: newScrollPercent }));
  }
}
