import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const API_BASE_URL = environment.apiBaseUrl;

@Pipe({
  name: 'secureImage',
})
export class SecureImagePipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  transform(url): Observable<SafeUrl> {
    if (!url) {
      return from(['']);
    }

    let transformedUrl = url;
    if (transformedUrl?.indexOf('s3.amazonaws') > -1) {
      return from([transformedUrl]);
    }
    const devUrl = `https://api.dev.vibeiq.com/dev/api`;
    const prodUrl = `https://api.vibeiq.com/prod/api`;

    if (transformedUrl.includes(devUrl)) {
      transformedUrl = transformedUrl.replace(devUrl, API_BASE_URL);
    }
    if (transformedUrl.includes(prodUrl)) {
      transformedUrl = transformedUrl.replace(prodUrl, API_BASE_URL);
    }

    transformedUrl += `?v1.1`;
    // console.log('transformedUrl:  ', transformedUrl);
    return this.http
      .get(transformedUrl, { responseType: 'blob' })
      .pipe(map((val) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
  }
}
