<div class="grid-header">
  <div class="checkbox-cell" *ngIf="side === 'left'">
    <mat-checkbox data-test="select-all-checkbox" (change)="selectAll()" [checked]="allSelected"></mat-checkbox>
  </div>
  <div class="validation-cell" *ngIf="side === 'left'">
    <app-column-header-menu
      *ngIf="(isOrgAdmin && (currentView$ | async)?.admin) || !(currentView$ | async)?.admin"
      [property]="alertProperty"
      [actions]="['filter']"
    ></app-column-header-menu>
    <!-- <mat-checkbox class="example-margin"></mat-checkbox> -->
  </div>
  <app-column-header
    [style.width.px]="property.width || 150"
    class="header-cell"
    *ngFor="let property of enabledProperties; last as isLast"
    [ngClass]="{ last: isLast }"
    [property]="property"
  >
  </app-column-header>
</div>
