import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { debounceTime, tap } from 'rxjs/operators';
import { Plan } from 'src/app/plans/plans-store/plans.state';
import { PlansActions, RootStoreState } from 'src/app/root-store';

@Component({
  selector: 'app-rename-plan',
  templateUrl: './rename-plan.component.html',
  styleUrls: ['./rename-plan.component.scss'],
})
export class RenamePlanComponent implements OnInit {
  public nameInput: UntypedFormControl;
  @Input() plan: Plan;
  @Output() complete = new EventEmitter();
  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.nameInput = new UntypedFormControl(this.plan.name);
  }
  save() {
    this.store.dispatch(PlansActions.updatePlan({ id: this.plan.id, changes: { name: this.nameInput.value } }));
    this.complete.emit();
  }
}
