import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ViewDefinition } from '@contrail/client-views';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';

@Component({
  selector: 'app-view-modal',
  templateUrl: './view-modal.component.html',
  styleUrls: ['./view-modal.component.scss'],
})
export class ViewModalComponent implements OnInit {
  public formMode = 'UPDATE';
  public currentView: ViewDefinition;
  public modalTitle = 'View Configuration';
  constructor(
    public dialogRef: MatDialogRef<ViewModalComponent>,
    private store: Store<RootStoreState.State>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data?.currentView) {
      this.currentView = data.currentView;
      this.formMode = data.formMode || 'UPDATE';
    }

    if (this.formMode === 'COPY') {
      this.modalTitle = 'Copy View';
    }
  }
  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }
}
