import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { combineLatest, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';

@Component({
  selector: 'app-focused-item-family-header',
  templateUrl: './focused-item-family-header.component.html',
  styleUrls: ['./focused-item-family-header.component.scss'],
})
export class FocusedItemFamilyHeaderComponent implements OnInit {
  focusedFamilyItem$: Observable<any>;
  itemFamilies: any[];
  currentItemFamilyIndex = 0;

  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.focusedFamilyItem$ = this.store.select(CollectionManagerSelectors.focusedFamilyItem);
    combineLatest([this.focusedFamilyItem$, this.store.select(CollectionManagerSelectors.filteredData)])
      .pipe(
        take(1),
        tap(([family, data]) => {
          this.itemFamilies = Object.values(
            data.reduce((itemMap, item) => {
              if (item.itemFamilyId && !itemMap[item.itemFamilyId]) {
                itemMap[item.itemFamilyId] = item.itemFamily;
              }
              return itemMap;
            }, {}),
          );
          this.currentItemFamilyIndex = this.itemFamilies.findIndex((item) => item.itemFamilyId === family.id);
        }),
      )
      .subscribe();
  }

  clear() {
    this.store.dispatch(CollectionManagerActions.setFocusedItemFamily({ itemFamily: null }));
  }

  prev() {
    this.currentItemFamilyIndex =
      this.currentItemFamilyIndex === 0 ? this.itemFamilies.length - 1 : this.currentItemFamilyIndex - 1;
    this.setFocusedFamily();
  }

  next() {
    this.currentItemFamilyIndex =
      this.currentItemFamilyIndex + 1 === this.itemFamilies.length ? 0 : this.currentItemFamilyIndex + 1;
    this.setFocusedFamily();
  }

  setFocusedFamily() {
    const nextFamily = this.itemFamilies[this.currentItemFamilyIndex];
    if (nextFamily) {
      this.store.dispatch(CollectionManagerActions.setFocusedItemFamily({ itemFamily: nextFamily }));
    }
  }
}
