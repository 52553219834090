import { Injectable } from '@angular/core';
import { API_VERSION, Entities } from '@contrail/sdk';
import { Assortment } from './assortments-store/assortments.state';

@Injectable({
  providedIn: 'root',
})
export class AssortmentsService {
  constructor() {}

  public static async getAssortment(id: string, relations?: Array<string>) {
    let assortment: any;
    const additionalRelations = relations ? relations : [];
    const params: any = {
      entityName: 'assortment',
      id,
      relations: [
        'assortmentItems',
        'assortmentItems.item',
        'assortmentItems.projectItem',
        ...additionalRelations,
        // 'assortmentItems.item.primaryViewable',
        // 'assortmentItems.item.primaryViewable.primaryFile,',
        // 'assortmentItems.item.primaryViewable.smallViewable',
      ],
    };
    // params.cacheMode = 'USE';
    assortment = await new Entities().get(params);
    if (assortment.itemsDownloadURL) {
      const response = await fetch(assortment.itemsDownloadURL);
      const assortmentItems = await response.json();
      assortment.assortmentItems = assortmentItems;
    }
    return assortment;
  }

  public async getAllAssortmentsBatched(workspaceId: string) {
    const initialPaginatedAssortments = await this.getAssortmentsPaginated(workspaceId);
    const assortments = [...initialPaginatedAssortments.results];
    let assortmentsNextPageKey = initialPaginatedAssortments.nextPageKey;
    while (assortmentsNextPageKey) {
      const morePaginatedAssortments = await this.getAssortmentsPaginated(workspaceId, assortmentsNextPageKey);
      assortmentsNextPageKey = morePaginatedAssortments.nextPageKey;
      assortments.push(...morePaginatedAssortments.results);
    }

    const sortedAssortments = assortments.sort((a1, a2) => (a1.name?.toUpperCase() > a2.name?.toUpperCase() ? 1 : -1));
    return sortedAssortments;
  }

  public async getAssortmentsPaginated(workspaceId: string, nextPageKey?: string) {
    const numberOfResultsPerPage = 75;
    const criteria = { rootWorkspaceId: workspaceId, isArchived: false };
    const data = await new Entities().get({
      entityName: 'assortment',
      criteria,
      relations: ['sourceAssortments'],
      take: numberOfResultsPerPage,
      apiVersion: API_VERSION.V2,
      paginate: true,
      nextPageKey,
    });

    const newNextPageKey = data.results.length < numberOfResultsPerPage ? undefined : data.nextPageKey;

    const filteredResults = data.results?.length
      ? data.results.filter((a) => a.name?.indexOf('Backing') < 0).filter((a) => a.assortmentType !== 'BACKING')
      : [];

    return { results: filteredResults, nextPageKey: newNextPageKey };
  }

  public async getAssortmentById(id: string) {
    return new Entities().get({ entityName: 'assortment', id });
  }

  public async createAssortment(assortment: Assortment) {
    return new Entities().create({ entityName: 'assortment', object: assortment });
  }
  public async deleteAssortment(assortment: Assortment) {
    await new Entities().delete({ entityName: 'assortment', id: assortment.id });
    return assortment;
  }
  public async updateAssortment(id: string, changes: Assortment) {
    return new Entities().update({ entityName: 'assortment', id, object: changes });
  }

  public async addItemsToAssortment(assortmentId, itemIds) {
    const options = {
      entityName: 'assortment',
      id: assortmentId,
      relation: 'items',
      object: { itemIds },
    };

    return new Entities().create(options);
  }
  public async removeItemsFromAssortment(assortmentId, itemIds) {
    const removedItemIds: Array<any> = [];
    for (const itemId of itemIds) {
      const options = {
        entityName: 'assortment',
        id: assortmentId,
        relation: 'items',
        relationId: itemId,
      };
      try {
        const result = await new Entities().delete(options);
        removedItemIds.push(result.deletedObjectId);
      } catch (e) {
        console.log('Could not remove item from assortment: ', e);
      }
    }
    console.log('removedItemIds:  ', removedItemIds);
    return removedItemIds;
  }
}
