<div class="empty-state" *ngIf="!value && selected">
  <div class="add-button"><mat-icon>add</mat-icon></div>
</div>
<app-object-reference-chip
  [object]="value"
  [objectProperties]="objectProperties"
  maxDisplayLength="16"
  *ngIf="value"
  [clickable]="isClickable"
  (clicked)="handleClick()"
  [allowDisplayTextShortening]="false"
>
</app-object-reference-chip>
