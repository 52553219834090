import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { assortmentsReducers } from './assortments.reducers';
import { EffectsModule } from '@ngrx/effects';
import { AssortmentsEffects } from './assortments.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('assortments', assortmentsReducers),
    EffectsModule.forFeature([AssortmentsEffects]),
  ],
})
export class AssortmentsStoreModule {}
