import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { DataCellEditBaseComponent } from '../data-cell-edit-base-component';
import { NumberFormat, PropertyType } from '@contrail/types';
@Component({
  selector: 'app-data-cell-edit-currency',
  template: `<input
    appLimitFractionDigits
    [maximumFractionDigits]="precision"
    [attr.data-test]="'input-' + property.slug"
    #inputField
    (blur)="handleChange()"
    [(ngModel)]="value"
  />`,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        justify-content: flex-end;
        text-align: right;
      }
      input {
        border: none;
        font-size: 12px;
        line-height: normal;
        padding: 0px;
        width: 95%;
        justify-content: flex-end;
        text-align: right;
      }
    `,
  ],
})
export class DataCellEditCurrencyComponent extends DataCellEditBaseComponent implements AfterViewInit {
  @ViewChild('inputField') input: ElementRef;
  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.precision = this.property?.propertyDefinition?.numberFormat?.precision;

    const shouldRoundValue = Boolean(typeof this.precision === 'number' && (!!this.value || this.value === 0));
    this.value = shouldRoundValue ? this.value.toFixed(this.precision) : this.value;

    this.focusOnCell.emit(this.input?.nativeElement);
  }

  handleChange() {
    this.value = parseFloat(this.value);
    if (isNaN(this.value)) {
      this.value = null;
    }
    super.handleChange();
  }
}
