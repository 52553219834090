import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export enum ErrorActionType {
  UPDATE_ITEMS = 'update_items',
  UPDATE_PROJECT_ITEMS = 'update_project_items',
  UPDATE_PLACEHOLDERS = 'update_placeholders',
  CREATE_PLACEHOLDERS = 'create_placeholders',
}

export interface AsyncError {
  id?: string;
  error: any;
  errorType: ErrorActionType;
  undoActionUuid?: string;
  selectedCell?: {
    rowId: string;
    propertySlug: string;
  };
}

export interface AsyncErrorsState extends EntityState<AsyncError> {}

export interface State {
  asyncErrors: AsyncErrorsState;
}

export const asyncErrorsEntityAdapter: EntityAdapter<AsyncError> = createEntityAdapter<AsyncError>({});

export const asyncErrorsInitialState = {
  asyncErrors: asyncErrorsEntityAdapter.getInitialState({}),
};
