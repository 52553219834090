import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ItemChooserModule } from 'src/app/plan-item-chooser/plan-item-chooser.module';
import { ComponentsModule } from '../../common/components/components.module';
import { SidePanelModule } from '../side-panel/side-panel.module';
import { AggregateRowComponent } from './aggregate-row/aggregate-row.component';
import { ColumnAggregateComponent } from './aggregate-row/column-aggregate/column-aggregate.component';
import { CollectionCommentsHandler } from './collection-comments-handler';
import { DataCellEditBooleanComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-boolean/data-cell-edit-boolean.component';
import { DataCellEditCurrencyComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-currency/data-cell-edit-currency.component';
import { DataCellEditDateComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-date/data-cell-edit-date.component';
import { DataCellEditNumberComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-number/data-cell-edit-number.component';
import { DataCellEditObjectReferenceComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-object-reference/data-cell-edit-object-reference.component';
import { DataCellEditPercentageComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-percentage/data-cell-edit-percentage.component';
import { DataCellEditTextComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-text/data-cell-edit-text.component';
import { DataCellEditComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit.component';
import { DataCellViewImageComponent } from './data-rows/data-cell/data-cell-view/data-cell-view-image/data-cell-view-image.component';
import { DataCellViewObjectReferenceComponent } from './data-rows/data-cell/data-cell-view/data-cell-view-object-reference/data-cell-view-object-reference.component';
import { DataCellViewComponent } from './data-rows/data-cell/data-cell-view/data-cell-view.component';
import { DataCellComponent } from './data-rows/data-cell/data-cell.component';
import { DataRowValidationComponent } from './data-rows/data-row/data-row-validation/data-row-validation.component';
import { DataRowComponent } from './data-rows/data-row/data-row.component';
import { DataRowsComponent } from './data-rows/data-rows.component';
import { GridViewComponent } from './grid-view.component';
import { ColumnHeaderComponent } from './header-row/column-header/column-header.component';
import { HeaderRowComponent } from './header-row/header-row.component';
import { RemoteEditorHandler } from './remote-editor-handler';
import { HorizontalScrollerComponent } from './scroll-bars/horizontal-scroller/horizontal-scroller.component';
import { VerticalScrollerComponent } from './scroll-bars/vertical-scroller/vertical-scroller.component';
import { ColumnDropPreviewComponent } from './header-row/column-drop-preview/column-drop-preview.component';
import { DataCellViewFormulaComponent } from './data-rows/data-cell/data-cell-view/data-cell-view-formla/data-cell-view-formula.component';
import { DataCellEditMultiSelectComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-multi-select/data-cell-edit-multi-select.component';
import { DataCellEditSelectComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-select/data-cell-edit-select.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ColumnHeaderMenuComponent } from './header-row/column-header-menu/column-header-menu.component';
import { CreatePlaceholdersComponent } from './create-placeholders/create-placeholders.component';
import { FillCellHandleComponent } from './data-rows/fill-cell/fill-cell-handle-component';
import { DataCellEditTextAreaComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-textarea/data-cell-edit-text-area.component';
import { DataCellViewTextAreaComponent } from './data-rows/data-cell/data-cell-view/data-cell-view-text-area/data-cell-view-text-area.component';
import { SpreadValueComponent } from './spread/spread-value-component/spread-value-component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { SpreadValueMenuComponent } from './spread/spread-menu/spread-value-menu-component';
import { PipesModule } from '@common/pipes/pipes.module';
import { DataCellEditItemObjectReferenceComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-object-reference/data-cell-edit-item-object-reference/data-cell-edit-item-object-reference.component';
import { ObjectReferenceModule } from '@common/object-reference/object-reference.module';
import { EntityDetailsModule } from '@common/entity-details/entity-details.module';
import { DataCellViewTypeReferenceComponent } from './data-rows/data-cell/data-cell-view/data-cell-view-type-reference/data-cell-view-type-reference.component';
import { DataCellEditTypeReferenceComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-type-reference/data-cell-edit-type-reference.component';
import { DropDownListComponent } from './data-rows/data-cell/data-cell-edit/components/drop-down-list.component';
import { DataCellViewSizeRangeComponent } from './data-rows/data-cell/data-cell-view/data-cell-view-size-range/data-cell-view-size-range.component';
import { DataCellEditSizeRangeComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-size-range/data-cell-edit-size-range.component';
import { SizeRangeEditorModule } from '@components/size-range-editor/size-range-editor.module';
import { DataCellEditUserListComponent } from './data-rows/data-cell/data-cell-edit/data-cell-edit-user/data-cell-edit-user-list.component';
import { DirectivesModule } from '@common/directives/directives.module';

@NgModule({
  declarations: [
    GridViewComponent,
    DataCellComponent,
    ColumnHeaderComponent,
    ColumnAggregateComponent,
    DataRowsComponent,
    AggregateRowComponent,
    HeaderRowComponent,
    DataRowComponent,
    DataCellEditComponent,
    DataCellViewComponent,
    DataCellViewSizeRangeComponent,
    DataCellEditTextComponent,
    DataCellEditNumberComponent,
    DataCellEditCurrencyComponent,
    DataCellEditPercentageComponent,
    DataCellEditSelectComponent,
    DataCellEditMultiSelectComponent,
    DataCellEditBooleanComponent,
    DataCellEditDateComponent,
    DataCellEditSizeRangeComponent,
    DataCellViewObjectReferenceComponent,
    DataCellEditObjectReferenceComponent,
    HorizontalScrollerComponent,
    VerticalScrollerComponent,
    DataRowValidationComponent,
    DataCellViewImageComponent,
    DataCellViewFormulaComponent,
    DataCellEditTypeReferenceComponent,
    ColumnDropPreviewComponent,
    ColumnHeaderMenuComponent,
    CreatePlaceholdersComponent,
    FillCellHandleComponent,
    DataCellEditTextAreaComponent,
    DataCellViewTextAreaComponent,
    SpreadValueComponent,
    SpreadValueMenuComponent,
    DataCellEditItemObjectReferenceComponent,
    DataCellViewTypeReferenceComponent,
    DropDownListComponent,
    DataCellEditUserListComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    DragDropModule,
    MatCheckboxModule,
    ItemChooserModule,
    SidePanelModule,
    MatMenuModule,
    MatRadioModule,
    PipesModule,
    ObjectReferenceModule,
    EntityDetailsModule,
    SizeRangeEditorModule,
    DirectivesModule,
  ],
  exports: [GridViewComponent, CreatePlaceholdersComponent, SpreadValueComponent, SpreadValueMenuComponent],
})
export class GridViewModule {
  constructor(
    private remoteEditorHandler: RemoteEditorHandler,
    private commentHandler: CollectionCommentsHandler,
  ) {}
}
