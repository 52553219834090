import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { SelectorCell } from '../selectors/grid-selector.service';
import { GridViewManager } from '../grid-view.manager';
import { DataCellComponent } from '../data-rows/data-cell/data-cell.component';
import { Types } from '@contrail/sdk';
import { PropertyType, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { ItemUpdateService } from '../../items/item-update-service';
import { ProjectItemUpdateService } from '../../project-items/project-item-update-service';

@Injectable({
  providedIn: 'root',
})
export class OverrideOptionService {
  isInOverrideMode = false;
  selectedCells: SelectorCell[];
  selectedOverrideOptionCells: SelectorCell[];

  constructor(
    private store: Store<RootStoreState.State>,
    private gridViewManager: GridViewManager,
    private itemUpdateService: ItemUpdateService,
    private projectItemUpdateService: ProjectItemUpdateService,
  ) {
    this.store
      .select(CollectionManagerSelectors.selectorCells)
      .subscribe((selectedCells) => (this.selectedCells = selectedCells));
    this.store.select(CollectionManagerSelectors.overrideOptionCells).subscribe((overrideOptionCells) => {
      this.selectedOverrideOptionCells = overrideOptionCells;
      this.isInOverrideMode = this.selectedOverrideOptionCells.length > 0;
    });
  }

  public async setOverrideOptionCells(clearAll = false) {
    if (this.selectedOverrideOptionCells.length > 0 || clearAll) {
      setTimeout(() => {
        this.store.dispatch(CollectionManagerActions.setOverrideOptionCells({ overrideOptionCells: [] }));
      }, 5);
    } else {
      if (this.selectedCells.length === 0 && !this.gridViewManager.selectedCell) {
        return;
      }
      const selectedCells =
        this.selectedCells.length > 0
          ? this.selectedCells
          : [
              {
                rowId: this.gridViewManager.selectedCell.rowId,
                columnId: this.gridViewManager.selectedCell.propertySlug,
              },
            ];
      const changes = await this.generateChanges(selectedCells);
      if (changes.nonOverriddenCells.length === 0) {
        // Restore the overridden values to the family values
        if (changes.itemRestore.length > 0) {
          this.itemUpdateService.batchUpdateItems({
            changes: changes.itemRestoreChanges,
            placeholderUpdates: { updates: changes.itemRestore },
          });
        }
        if (changes.projectItemRestore.length > 0) {
          const projectItemChangesToRestore = changes.projectItemRestoreChanges.map((projectItemChange) => {
            const itemId = projectItemChange.id.split(':')[1];
            return { id: itemId, changes: projectItemChange.changes };
          });

          this.projectItemUpdateService.batchUpdateProjectItems({
            changes: projectItemChangesToRestore,
            placeholderUpdates: { updates: changes.projectItemRestore },
          });
        }
      } else {
        this.store.dispatch(
          CollectionManagerActions.setOverrideOptionCells({ overrideOptionCells: changes.overridableCells }),
        );
      }
    }
  }

  private async generateChanges(selectedCells: any[]) {
    const overridableCells = [];
    const nonOverriddenCells = [];
    const itemRestore = [];
    const itemRestoreChanges = [];
    const projectItemRestore = [];
    const projectItemRestoreChanges = [];
    for (let i = 0; i < selectedCells.length; i++) {
      const cell = this.gridViewManager.getCell(selectedCells[i].rowId, selectedCells[i].columnId);
      const data = cell.data;
      const prop = await this.getProperty(selectedCells[i]);
      if (prop?.propertyLevel === 'overridable') {
        if (prop.scope === 'item' && this.isDataOverridden(prop, data.itemFamily, data.itemOption)) {
          if (cell.editable) {
            this.accumulateRestoreChanges(cell, data, 'item', prop, itemRestore, itemRestoreChanges);
          }
        } else if (
          prop.scope === 'project-item' &&
          this.isDataOverridden(prop, data.itemFamily.projectItem, data.itemOption.projectItem)
        ) {
          if (cell.editable) {
            this.accumulateRestoreChanges(
              cell,
              data,
              'project-item',
              prop,
              projectItemRestore,
              projectItemRestoreChanges,
            );
          }
        } else {
          nonOverriddenCells.push(selectedCells[i]);
        }
        overridableCells.push(selectedCells[i]);
      }
    }
    return {
      itemRestore,
      itemRestoreChanges,
      projectItemRestore,
      projectItemRestoreChanges,
      nonOverriddenCells,
      overridableCells,
    };
  }

  private accumulateRestoreChanges(
    cell: DataCellComponent,
    data: any,
    scope: string,
    prop: any,
    itemRestoreUpdates: any[],
    itemRestoreChanges: any[],
  ) {
    const itemFamily = scope === 'item' ? data.itemFamily : data.itemFamily.projectItem;
    const itemOption = scope === 'item' ? data.itemOption : data.itemOption.projectItem;
    const updates = {};
    const changes = {};
    updates[cell.propertySlug] = itemFamily[cell.propertySlug];
    if (prop.propertyType === PropertyType.ObjectReference) {
      changes[cell.propertySlug + 'Id'] = itemFamily[cell.propertySlug + 'Id'];
      updates[cell.propertySlug + 'Id'] = itemFamily[cell.propertySlug + 'Id'];
    } else {
      changes[cell.propertySlug] = itemFamily[cell.propertySlug];
    }

    let existingUpdates = itemRestoreUpdates.find((changes) => changes.item.id === data.itemOption.id);
    if (existingUpdates) {
      existingUpdates.changes = Object.assign(existingUpdates.changes, updates);
    } else {
      itemRestoreUpdates.push({ item: data.itemOption, changes: ObjectUtil.cloneDeep(updates) });
    }

    let existingChanges = itemRestoreChanges.find((changes) => changes.id === itemOption.id);
    if (existingChanges) {
      existingChanges.changes = Object.assign(existingChanges.changes, changes);
    } else {
      itemRestoreChanges.push({ id: itemOption.id, changes: ObjectUtil.cloneDeep(changes) });
    }
  }

  private async getProperty(cell: any): Promise<any> {
    let property: any;
    const propSlug = cell.columnId || cell.propertySlug;
    const dataCell: DataCellComponent = this.gridViewManager.getCell(cell.rowId, propSlug);
    const scope = dataCell.property.scope;
    if (dataCell.data.itemFamily?.typeId && dataCell.data.itemOption) {
      const itemType = await new Types().getType({ id: dataCell.data.itemFamily?.typeId });
      property = itemType.typeProperties.find((prop) => prop.slug === propSlug);
      if (!property && dataCell.data.itemFamily.projectItem) {
        const projectItemType = await new Types().getType({ id: dataCell.data.itemFamily.projectItem.typeId });
        property = projectItemType.typeProperties.find((prop) => prop.slug === propSlug);
      }
    }
    if (property) {
      property.scope = scope;
    }
    return property;
  }

  public isDataOverridden(property: TypeProperty, familyData: any, optionData: any): boolean {
    if (!optionData || !familyData) {
      return false;
    }

    const doesOptionValueMatchFamily = ObjectUtil.areItemPropertyValuesEqual(property, familyData, optionData);
    return !doesOptionValueMatchFamily;
  }

  public isOverrideChangesOnFamily(typeProperty: TypeProperty, entity: any, scope: string) {
    const familyObject = scope === 'item' ? entity.itemFamily : entity.itemFamily?.projectItem;
    const optionObject = scope === 'item' ? entity.itemOption : entity.itemOption?.projectItem;

    return (
      typeProperty.propertyLevel === 'overridable' &&
      !this.isInOverrideMode &&
      ((entity.itemFamily && !entity.itemOption) || !this.isDataOverridden(typeProperty, familyObject, optionObject))
    );
  }
}
