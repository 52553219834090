import { ItemHistoryEvent } from './item-history-event';

export class SourceChangeHistoryHelper {
  public static hasMeaningfulChange(historyEvent: ItemHistoryEvent): boolean {
    if (['item', 'project-item'].includes(historyEvent.changeType)) {
      if (!historyEvent.changes) {
        return false;
      }
      if (historyEvent.changes?.smallViewableDownloadUrl) {
        return true;
      }
      delete historyEvent.changes?.updatedOn;
      delete historyEvent.changes?.updatedById;
      delete historyEvent.changes?.largeViewableDownloadUrl;
      delete historyEvent.changes?.mediumLargeViewableDownloadUrl;
      delete historyEvent.changes?.mediumViewableDownloadUrl;
      delete historyEvent.changes?.smallViewableDownloadUrl;
      delete historyEvent.changes?.tinyViewableDownloadUrl;
      delete historyEvent.changes?.primaryFileUrl;
      delete historyEvent.changes.primaryViewableId;
      delete historyEvent.changes?.itemFamilyId; // This should really never change!
      delete historyEvent.changes?.itemOptionId; // This should really never change!

      const keys = Object.keys(historyEvent.changes);
      for (let key of keys) {
        // Resolves issues with 'roles.xxx' which needs to be cleaned up in
        // the history service.
        if (key.indexOf('.') > -1) {
          delete historyEvent.changes[key];
          continue;
        }

        const newValue = historyEvent.changes[key].newValue;
        const oldValue = historyEvent.changes[key].oldValue;

        const newValueMeaningful = newValue && ('' + newValue).length > 0;
        const oldValueMeaningful = oldValue && ('' + oldValue).length > 0;
        if (!newValueMeaningful && !oldValueMeaningful) {
          delete historyEvent.changes[key];
        }
      }
      return Object.keys(historyEvent.changes).length > 0;
    }
    return true;
  }
}
