import {
  ChangeDetectorRef,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Component } from '@angular/core';
import { SizeRangeHelper } from '@common/size-range/size-range-helper';
import { SizeRange } from '@contrail/types';
import { DataCellViewBaseComponent } from '../data-cell-view-base-component';

@Component({
  selector: 'app-data-cell-view-size-range',
  template: `
    <div class="h-full flex overflow-hidden w-[calc(100%-12px)]" (click)="togglePanel()">
      <div class="size-chip" *ngFor="let size of sizes">
        {{ size }}
      </div>
      <span class="absolute bottom-0 right-1" *ngIf="showEllipsis">...</span>
    </div>
    <div #sizeRangePopup class="panel" *ngIf="selected && showPanel" id="sizeRangePopup">
      <app-size-range-editor [sizeRange]="value" [editMode]="false"> </app-size-range-editor>
    </div>
  `,
  styles: [
    `
      :host {
        @apply w-full;
      }
      .panel {
        @apply flex flex-col justify-between fixed z-[300] mt-[24px] p-[15px] shadow-[0_10px_22px_rgba(0,0,0,0.15)] bg-white rounded-[8px];
      }
      .size-chip {
        @apply flex items-center rounded-[3px] px-[5px] text-[0.9em] m-[2px] bg-[#ebebeb];
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DataCellViewSizeRangeComponent extends DataCellViewBaseComponent implements OnChanges {
  @ViewChild('sizeRangePopup') sizeRangeElem: ElementRef;
  @Input('editable') editable: boolean;
  display = '';
  showPanel = false;
  sizeRange: SizeRange;
  showEllipsis = false;
  sizes: Array<string> = [];

  constructor(
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.value || changes.property) {
      const allSizes = SizeRangeHelper.getSizeRangeSizesList(this.value);
      this.showEllipsis = false;
      let totalLength = 0;
      let maxSizeCount = 0;
      const maxWidth = (this.property.width - 11) * 0.95;
      allSizes.forEach((size) => {
        totalLength += size.length * 5 + 14;
        if (totalLength < maxWidth) {
          maxSizeCount++;
        }
      });

      if (allSizes.length > maxSizeCount) {
        this.sizes = allSizes.slice(0, maxSizeCount + 1);
        this.showEllipsis = true;
      } else {
        this.showEllipsis = false;
        this.sizes = allSizes;
      }
    }
  }

  togglePanel() {
    if (!this.value || this.editable) {
      // only shows the popup if the property is uneditable
      return;
    }
    this.showPanel = !this.showPanel;
    if (this.showPanel) {
      setTimeout(() => {
        this.setPosition();
      }, 1);
    }
  }

  setPosition() {
    if (!this.sizeRangeElem) {
      return;
    }
    const panelHeight = this.sizeRangeElem.nativeElement?.getBoundingClientRect().height || 50;
    const pageY = this.elementRef.nativeElement.getBoundingClientRect().y;
    const chooserContainerHeight = 450;
    const singleRowHeight = 30;
    let panelTopPosition = this.elementRef.nativeElement.getBoundingClientRect().top;
    const maxDim =
      window.innerHeight * 0.4 > chooserContainerHeight ? chooserContainerHeight : window.innerHeight * 0.4;
    const distanceFromBottom = (100 * (window.innerHeight - maxDim - pageY)) / window.innerHeight;
    if (distanceFromBottom < 12) {
      panelTopPosition = panelTopPosition - panelHeight - singleRowHeight;
    }
    this.sizeRangeElem.nativeElement.style.top = `${panelTopPosition}px`;
    this.sizeRangeElem.nativeElement.style.left = `${this.elementRef.nativeElement.getBoundingClientRect().left + 170}px`;
  }
}
