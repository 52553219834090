import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppExtensionModalLauncherComponent } from './app-extension-modal-launcher/app-extension-modal-launcher.component';
import { Entities } from '@contrail/sdk';
import { AppExtension } from '@contrail/entity-types';
import { AppExtensionMessageHandler } from '@contrail/extensions-sdk';

@Injectable({
  providedIn: 'root',
})
export class AppExtensionService {
  constructor(private matDialog: MatDialog) {}

  public async getExtensions(): Promise<Array<AppExtension>> {
    return (await new Entities().get({ entityName: 'app-extension' })).sort((a1, a2) => (a1.name > a2.name ? 1 : -1));
  }

  launchExtensionModal(app: AppExtension, messageHandler: AppExtensionMessageHandler, context: any = {}) {
    const config = {
      data: { app, messageHandler, context },
      panelClass: [`no-padding`, `item-details-modal`],
      maxWidth: app.modalDimensions?.maxWidth || '95vw',
      width: app.modalDimensions?.width || '1350px',
      height: app.modalDimensions?.height || '800px',
      autoFocus: true,
      context,
    };
    const dialogRef = this.matDialog.open(AppExtensionModalLauncherComponent, config);
    dialogRef.componentInstance.closeWidget.subscribe(() => dialogRef.close());
  }
}
