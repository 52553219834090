import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-column-drop-preview',
  templateUrl: './column-drop-preview.component.html',
  styleUrls: ['./column-drop-preview.component.scss'],
})
export class ColumnDropPreviewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
