import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ViewFormComponent } from './view-form/view-form.component';
import { ViewModalComponent } from './view-modal/view-modal.component';
@Injectable({
  providedIn: 'root',
})
export class ConfigureViewDefModalLauncher {
  constructor(public dialog: MatDialog) {}

  openModal(params): void {
    const dialogRef = this.dialog.open(ViewModalComponent, {
      width: '350px',
      //height: '320px',
      disableClose: false,
      autoFocus: true,
      panelClass: 'full-screen-modal',
      data: params,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
