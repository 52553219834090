<div cdkDropList class="flex flex-col space-y-[5px]" (cdkDropListDropped)="drop($event)">
  <ng-template ngFor let-property [ngForOf]="selectedProperties">
    <div
      class="list-value h-[26px] bg-gray-50 flex flex-row !box-border items-center justify-between text-xs rounded-[5px] px-[7px] py-1.5"
      cdkDrag
    >
      <div class="flex items-center w-full text-black/60">
        <mat-icon class="cursor-move mr-2.5" cdkDragHandle>drag_handle</mat-icon>
        {{ property.label }}
        <div class="flex items-center justify-end grow">
          <div>
            <mat-select
              *ngIf="!sortable"
              [(value)]="property.aggregateFunction"
              (selectionChange)="selectFunction($event, property)"
              matTooltip="Function"
              matTooltipShowDelay="2000"
            >
              <mat-option *ngFor="let functionType of functionTypes" [value]="functionType">
                {{ functionType }}
              </mat-option>
            </mat-select>
          </div>
          <mat-icon
            class="cursor-pointer !flex items-center justify-end !h-[15px] !w-[15px] !text-[15px] mr-0"
            *ngIf="sortable"
            matTooltip="Sort"
            svgIcon="sort"
            [matMenuTriggerFor]="sortMenu"
            matTooltipShowDelay="2000"
            [matMenuTriggerData]="{ property }"
          ></mat-icon>
          <mat-icon
            class="cursor-pointer !flex items-center justify-end !text-[15px] mr-0"
            matTooltip="Remove"
            matTooltipShowDelay="2000"
            (click)="removeProperty(property)"
            >delete</mat-icon
          >
        </div>
      </div>
    </div>
  </ng-template>
  <div class="h-[26px] bg-gray-50 flex flex-row items-center justify-between text-xs rounded-[5px] px-2">
    <form class="flex items-center w-full h-full">
      <mat-icon class="text-black24 !w-6 !h-6 !text-2xl !leading-6 cursor-pointer" (click)="openPanel($event)"
        >add_box</mat-icon
      >
      <input
        #propertyInput
        type="text"
        [formControl]="control"
        placeholder="Add property"
        [matAutocomplete]="auto"
        size="30"
      />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addProperty($event.option.value)">
        <mat-option *ngFor="let property of filteredProperties | async" [value]="property">
          {{ property.label }}
        </mat-option>
      </mat-autocomplete>
    </form>
  </div>
</div>

<mat-menu class="column-header-menu" #sortMenu="matMenu" [hasBackdrop]="true">
  <ng-template matMenuContent let-property="property">
    <div>
      <button mat-menu-item (click)="sort(property, true)">
        <div class="flex">
          <div class="min-w-[22px]">
            <mat-icon *ngIf="isSortDirectionSelected(property, true)">check</mat-icon>
          </div>
          <span>Ascending</span>
        </div>
      </button>
      <button mat-menu-item (click)="sort(property)">
        <div class="flex">
          <div class="min-w-[22px]">
            <mat-icon *ngIf="isSortDirectionSelected(property)">check</mat-icon>
          </div>
          <span>Descending</span>
        </div>
      </button>
    </div>
  </ng-template>
</mat-menu>
