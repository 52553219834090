<div class="grid-totals">
  <div class="checkbox-cell" *ngIf="side === 'left'"></div>
  <div class="validation-cell" *ngIf="side === 'left'"></div>
  <app-column-aggregate
    [style.width.px]="property.width || 150"
    class="header-cell"
    *ngFor="let property of properties; last as isLast"
    [ngClass]="{ last: isLast }"
    [property]="property"
  >
  </app-column-aggregate>
</div>
