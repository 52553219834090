import { Types } from '@contrail/sdk';
import {
  TypeConstraintsHelper,
  ValidationError,
  ValidationErrorType,
  validateSizeRangeAgainstTemplate,
} from '@contrail/type-validation';
import { PropertyType, Type, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import {
  CollectionStatusMessage,
  CollectionStatusMessageTypes,
} from '@common/collection-status-message/collection-status-message';
import { Plan } from 'src/app/plans/plans-store/plans.state';
import { ValidatorFunctionProcessor } from './validator-function-processor';
import { EntityValidationMessages, ValidationErrorMessage } from './collection-status.interfaces';

const INVALID_VALUE_SUFFIX = '_invalid_value';
const VALIDATION_ERROR = '_validation_error';
const DROPPED_REASON_PROP = 'droppedReason';

interface ValidateEntitiesOptions {
  entities: any[];
  propertiesToValidate: TypeProperty[];
  planContext: any;
  typeMap: { [key: string]: Type };
  existingMessages?: CollectionStatusMessage[];
}

export class CollectionStatusHelper {
  public static async validateAllPropertiesOfEntities(
    plan: Plan,
    entities: any[],
    typeDefinitions: { [key: string]: Type },
  ): Promise<EntityValidationMessages> {
    const planContext = { assortment: plan?.targetAssortment };
    const typeMap = await CollectionStatusHelper.buildTypeMap(typeDefinitions);
    const propertiesToValidate = CollectionStatusHelper.getPropertiesToValidate(typeMap);
    const validationMessages = await CollectionStatusHelper.validateEntitiesAndGetValidationMessages({
      entities,
      propertiesToValidate,
      planContext,
      typeMap,
    });

    return validationMessages;
  }

  public static getPropertiesToValidate(typeMap: any): TypeProperty[] {
    const planPlaceholderType = typeMap['plan-placeholder'];
    const propertiesWithOptionSetHierarchiesToValidate =
      planPlaceholderType.typeOptionSetHierarchyLinks
        ?.map((hierarchy) => hierarchy.hierarchyTypePropertyIds.slice(1))
        ?.reduce((props, x) => props.concat(x), []) || [];

    const propertiesWithRuleSetsToValidate = [];
    planPlaceholderType.typeRuleSets?.forEach((typeRuleSet) => {
      typeRuleSet.ruleSet.forEach((ruleSet) => {
        ruleSet.rules.forEach((rule) => {
          propertiesWithRuleSetsToValidate.push(rule.slug);
        });
      });
    });

    const validatePropertyCandidates = [
      ...new Set(propertiesWithOptionSetHierarchiesToValidate.concat(propertiesWithRuleSetsToValidate)),
    ];
    const propertiesToValidate = planPlaceholderType.typeProperties.filter(
      (property) =>
        validatePropertyCandidates.includes(property.id) ||
        validatePropertyCandidates.includes(property.slug) ||
        property.validationFunction ||
        property.propertyType === PropertyType.SizeRange,
    );

    return propertiesToValidate;
  }

  public static async validateEntitiesAndGetValidationMessages(
    options: ValidateEntitiesOptions,
  ): Promise<EntityValidationMessages> {
    const { entities, propertiesToValidate, planContext, typeMap, existingMessages = [] } = options;
    const validationMessages: EntityValidationMessages = {
      newMessages: [],
      updatedMessages: [],
      removedMessageIds: [],
      errorMessages: [],
    };

    if (!propertiesToValidate) {
      return validationMessages;
    }

    const duplicateOptionValidationMessages = CollectionStatusHelper.buildDuplicateOptionWarningMessages(entities);
    validationMessages.newMessages.push(...duplicateOptionValidationMessages);

    const chunks = this.breakIntoChunks(entities, 1000);
    for (const chunk of chunks) {
      const promises: any[] = [];
      for (const entity of chunk) {
        for (const property of propertiesToValidate) {
          if (property.validationFunction) {
            promises.push(
              CollectionStatusHelper.checkPropertyValidationFunction(
                entity,
                property,
                planContext,
                validationMessages,
                existingMessages,
              ),
            );
          }

          promises.push(
            CollectionStatusHelper.checkPropertyValueValidity(
              entity,
              property,
              typeMap,
              validationMessages,
              existingMessages,
            ),
          );
        }
      }
      await Promise.all(promises);
    }

    return validationMessages;
  }

  private static async checkPropertyValidationFunction(
    entity: any,
    property: TypeProperty,
    planContext: any,
    validationMessages: EntityValidationMessages,
    existingMessages: CollectionStatusMessage[],
  ) {
    const validationAlerts = await ValidatorFunctionProcessor.processValidatorFunction(
      property.validationFunction,
      entity,
      planContext,
    );
    const validationErrors = validationAlerts?.filter((error) => error.type === ValidationErrorType.Error);
    if (validationErrors?.length > 0) {
      CollectionStatusHelper.setErrorMessages(validationErrors, property, entity, validationMessages);
      return;
    }

    const id = `${entity.id}_${property.slug}${VALIDATION_ERROR}}`;
    CollectionStatusHelper.setWarningMessages(
      validationAlerts,
      id,
      property,
      entity,
      validationMessages,
      existingMessages,
    );
  }

  /**
   * Validates a property value based on TypeConstraingHelper for an entity and udpates the set of mutatable
   * message arrays.
   * @param entity
   * @param property
   * @param typeMap
   * @param validationMessages Mutatable set of messages to build
   * @param existingMessages Existing warning messages on the Plan
   */
  private static async checkPropertyValueValidity(
    entity: any,
    property: TypeProperty,
    typeMap: any,
    validationMessages: EntityValidationMessages,
    existingMessages: CollectionStatusMessage[],
  ) {
    const entityType = await CollectionStatusHelper.getTypeOfEntity(entity, typeMap);

    const illegalValueValidationErrors: ValidationError[] = await TypeConstraintsHelper.isValueLegalForEntity(
      entityType,
      property,
      entity,
      entity[property.slug],
    );
    const id = `${entity.id}_${property.slug}${INVALID_VALUE_SUFFIX}`;

    if (property.propertyType === PropertyType.SizeRange) {
      const sizeRangeValidationErrors: ValidationError[] = validateSizeRangeAgainstTemplate(entity, property);
      if (sizeRangeValidationErrors.length > 0) {
        illegalValueValidationErrors.push(...sizeRangeValidationErrors);
      }
    }

    CollectionStatusHelper.setWarningMessages(
      illegalValueValidationErrors,
      id,
      property,
      entity,
      validationMessages,
      existingMessages,
    );
  }

  /**
   * Assesses all validation warnings for an entity / property and determines if
   * the message is new, needs to be updated, or should be removed.
   * @param validationWarnings New set of validation warnings for the property/entity
   * @param id Message id that may be updated/created/removed
   * @param property Property being validated
   * @param entity Entity being validated
   * @param validationMessages Mutable set of messages to build
   * @param existingMessages Existing warning messages on the Plan
   */
  private static setWarningMessages(
    validationWarnings: ValidationError[] = [],
    id: string,
    property: TypeProperty,
    entity: any,
    validationMessages: EntityValidationMessages,
    existingMessages: CollectionStatusMessage[],
  ) {
    if (!entity.isDropped || property.slug === DROPPED_REASON_PROP) {
      for (const warning of validationWarnings) {
        const messageObj: CollectionStatusMessage = {
          id,
          type: CollectionStatusMessageTypes.WARNING,
          message: `${property.label}: ${warning.message}`,
          collectionElementId: entity.id,
          propertySlug: property.slug,
          entityName: CollectionStatusHelper.getEntityNameForDisplay(entity),
        };

        const existingMessage = existingMessages.find((message) => message.id === id);
        if (existingMessage) {
          validationMessages.updatedMessages.push({
            id,
            changes: { message: messageObj.message },
          });
        } else {
          validationMessages.newMessages.push(messageObj);
        }
      }
    }

    if (
      (!validationWarnings?.length || (entity.isDropped && property.slug !== DROPPED_REASON_PROP)) &&
      existingMessages.findIndex((message) => message.id === id) > -1
    ) {
      validationMessages.removedMessageIds.push(id);
    }
  }

  private static getEntityNameForDisplay(entity): string {
    if (entity?.itemOption) {
      return `${entity.itemOption.name} / ${entity.itemOption.optionName}`;
    }

    if (entity?.itemFamily) {
      return entity.itemFamily.name;
    }

    return '';
  }

  /**
   * Builds and adds all validation errors for an entity.
   * @param validationErrors New set of validation errors for the property/entity
   * @param property Property being validated
   * @param entity Entity being validated
   * @param validationMessages Mutatable set of messages to build
   */
  private static setErrorMessages(
    validationErrors: ValidationError[],
    property: TypeProperty,
    entity: any,
    validationMessages: EntityValidationMessages,
  ) {
    for (const error of validationErrors) {
      const messageObj: ValidationErrorMessage = {
        type: ValidationErrorType.Error,
        message: `${error.message}`,
        collectionElementId: entity.id,
        propertySlug: property.slug,
      };

      validationMessages.errorMessages.push(messageObj);
    }
  }

  private static async buildTypeMap(typeDefinitions: any): Promise<{ [key: string]: Type }> {
    const typeMap: any = {};
    if (typeDefinitions) {
      for (const typeName in typeDefinitions) {
        typeMap[typeDefinitions[typeName].id] = ObjectUtil.cloneDeep(typeDefinitions[typeName]);
      }
    }

    if (typeDefinitions && typeDefinitions['plan-placeholder']) {
      typeMap['plan-placeholder'] = ObjectUtil.cloneDeep(typeDefinitions['plan-placeholder']);
    } else {
      typeMap['plan-placeholder'] = await new Types().getByRootAndPath({
        root: 'plan-placeholder',
        path: 'plan-placeholder',
      });
    }

    return typeMap;
  }

  private static async getTypeOfEntity(entity: any, typeMap: any): Promise<Type> {
    if (entity.typeId) {
      const type = typeMap[entity.typeId];
      if (!type) {
        return await new Types().getType({ id: entity.typeId });
      }

      return type;
    }

    return typeMap['plan-placeholder'];
  }

  private static buildDuplicateOptionWarningMessages(entities: any[]): CollectionStatusMessage[] {
    const uniqueItemOptionIds = new Set<string>();
    const warningMessages: CollectionStatusMessage[] = [];

    for (const entity of entities) {
      if (entity.isDropped || !entity.itemOptionId) {
        continue;
      }

      if (uniqueItemOptionIds.has(entity.itemOptionId)) {
        warningMessages.push({
          id: `${entity.id}_duplicated_option`,
          type: CollectionStatusMessageTypes.WARNING,
          message: `Item '${entity.itemOption.name?.trim()} / ${entity.itemOption.optionName?.trim()}' appears more than once in the plan.`,
          collectionElementId: entity.id,
          entityName: `${entity.itemOption.name} / ${entity.itemOption.optionName}`,
        });
      } else {
        uniqueItemOptionIds.add(entity.itemOptionId);
      }
    }

    return warningMessages;
  }

  private static breakIntoChunks(entities: any[], chunkSize: number): any[][] {
    const chunks = [];
    for (let i = 0; i < entities.length; i += chunkSize) {
      chunks.push(entities.slice(i, i + chunkSize));
    }

    return chunks;
  }
}
