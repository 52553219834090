<div>
  <div
    class="view-actions-list"
    *ngIf="!currentView.admin || (currentView.admin && (role$ | async) === 'ADMIN')"
    (click)="handleAction('rename')"
    data-test="rename-view"
  >
    <mat-icon>edit</mat-icon>
    Rename
  </div>
  <div class="view-actions-list" (click)="handleAction('copy')" data-test="copy-view">
    <mat-icon>content_copy</mat-icon>
    Duplicate
  </div>
  <div
    class="view-actions-list"
    (click)="handleAction('delete')"
    data-test="delete-view"
    *ngIf="(allViews$ | async).length > 1 && (!currentView.admin || (currentView.admin && (role$ | async) === 'ADMIN'))"
  >
    <mat-icon>delete_forever</mat-icon>
    Delete
  </div>
  <div
    class="disabled view-actions-list"
    *ngIf="(allViews$ | async).length <= 1"
    matTooltip="You can not delete the last available view."
    data-test="delete-view-disabled"
  >
    <mat-icon>delete_forever</mat-icon>
    Delete
  </div>
  <div
    class="view-actions-list"
    (click)="handleAction('make_template')"
    *ngIf="(role$ | async) === 'ADMIN'"
    data-test="make_template"
  >
    <mat-icon>keyboard_double_arrow_right</mat-icon>
    Add as Template
  </div>
  <div
    class="view-actions-list"
    *ngIf="!currentView.admin || (currentView.admin && (role$ | async) === 'ADMIN')"
    (click)="handleAction('configure')"
    data-test="configure-view"
  >
    <mat-icon>settings</mat-icon>
    Settings
  </div>
</div>
