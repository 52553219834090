import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SubTypesHelper, Types } from '@contrail/sdk';
import { TypeConstraintsHelper } from '@contrail/type-validation';
import { PropertyType, TypePropertyOption } from '@contrail/types';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';

@Component({
  selector: 'app-type-property-form-field-select',
  template: `
    <mat-form-field
      [appearance]="appearance"
      *ngIf="formControl"
      [floatLabel]="propertyFormConfiguration?.isFilter ? 'never' : 'always'"
    >
      <mat-label>{{ propertyFormConfiguration.typeProperty.label }}</mat-label>
      <mat-select [formControl]="formControl">
        <mat-option></mat-option>
        <ng-container *ngFor="let option of options">
          <mat-option
            *ngIf="!isInvalidOption(option) || isInvalidOptionButInValue(option)"
            [attr.data-test]="'property-form-option-' + option.value"
            [disabled]="isInvalidOption(option)"
            [value]="option"
          >
            {{ option.display }}
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="formControl.hasError('error')">{{ formControl.getError('error') }}</mat-error>
      <app-type-property-form-validation-error
        *ngIf="formControl.hasError('error') && formControl.disabled"
        [errorMessage]="formControl.getError('error')"
      >
      </app-type-property-form-validation-error>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      .select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 20px;
        cursor: pointer;
        min-width: 80px;
      }
      mat-form-field {
        width: 100%;
      }
    `,
  ],
})
export class TypePropertyFormFieldSelectComponent extends TypePropertyFormFieldBaseComponent {
  public options: Array<TypePropertyOption>;
  private validOptionValues: Set<string>;

  async initFormControl() {
    if (!this.propertyFormConfiguration?.typeProperty) {
      return;
    }

    this.options = await this.getAllOptions();
    this.validOptionValues = await this.getValidOptionValues();
    const option = this.options.find((opt) => opt.value === this.value);
    const disabled = await this.isDisabled();
    const isValueVisible = await this.isValueVisible();

    this.formControl = new UntypedFormControl({ value: isValueVisible ? option : null, disabled });
    this.formControl.valueChanges.subscribe((opt) => {
      if (!opt) {
        this.value = null;
      } else {
        this.value = opt.value;
      }
      this.handleChange();
    });
  }

  isInvalidOption(selectedOption) {
    return !this.validOptionValues.has(selectedOption.value);
  }

  isInvalidOptionButInValue(selectedOption) {
    return !this.validOptionValues.has(selectedOption.value) && this.value === selectedOption.value;
  }

  private async getAllOptions(): Promise<TypePropertyOption[]> {
    const typeProperty = this.propertyFormConfiguration.typeProperty;
    if (!typeProperty) {
      return [];
    }

    const isTypeProperty = Boolean(
      typeProperty.propertyType === PropertyType.TypeReference && typeProperty.referencedTypePath,
    );

    if (isTypeProperty) {
      return await this.getTypeOptions(typeProperty.referencedTypePath);
    }

    return typeProperty.options ?? [];
  }

  private async getValidOptionValues(): Promise<Set<string>> {
    const validOptions = (await this.getValidOptions()).filter((option) => !option.disabled);
    return new Set(validOptions.map((option) => option.value));
  }

  private async getValidOptions(): Promise<Array<TypePropertyOption>> {
    if (
      !this.entity?.typeId ||
      this.propertyFormConfiguration.typeProperty?.propertyType === PropertyType.TypeReference
    ) {
      return this.options;
    }
    const type = await new Types().getType({ id: this.entity.typeId });
    const eligibleOptions = await TypeConstraintsHelper.getValidOptionSets(
      type,
      this.propertyFormConfiguration.typeProperty,
      this.entity,
    );
    return eligibleOptions;
  }

  async getTypeOptions(typePath: string): Promise<Array<TypePropertyOption>> {
    const typesList = await SubTypesHelper.getTypeListFromPath(typePath);
    const options = typesList.map((type) => {
      return {
        display: type.pathLabel ?? type.label,
        value: type.id,
      };
    });
    return options;
  }
}
