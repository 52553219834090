<div class="header">
  <div class="title">Summary</div>
  <div (click)="close.emit()" class="cursor-pointer flex-center"><mat-icon>close</mat-icon></div>
</div>

<div class="filter-toggle" *ngIf="filterDef?.filterCriteria?.propertyCriteria.length > 0">
  <mat-slide-toggle
    color="primary"
    data-test="app-summary-view-filter-data-toggle"
    [checked]="filterData"
    (change)="toggleFilterData($event)"
    >Filtered only</mat-slide-toggle
  >
</div>
<div class="summary-chart" *ngIf="chartData?.length">
  <google-chart class="chart" [type]="chartType" [data]="chartData" [options]="chartOptions" dynamicResize="true">
    >
  </google-chart>
  <div class="chart-title">Count by {{ aggregateGroupProperty.label }}</div>
</div>
<div class="metrics">
  <div class="placeholders">
    <app-summary-metric-panel label="Total" [value]="count"></app-summary-metric-panel>
    <app-summary-metric-panel label="Placeholders" [value]="count - itemCount"></app-summary-metric-panel>
    <app-summary-metric-panel label="Assigned" [value]="itemCount"></app-summary-metric-panel>
  </div>
  <app-summary-metric-panel
    *ngFor="let property of aggregateProperties"
    [typeProperty]="property"
    [allowGoal]="editorMode === 'EDIT'"
    [label]="property.label"
    [goalValue]="getGoalValue(property.slug)"
    [filterActive]="filterDef?.filterCriteria?.propertyCriteria.length > 0 && filterData"
    [value]="aggregates[property.slug]"
  >
  </app-summary-metric-panel>
</div>
