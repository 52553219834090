import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CollectionDataEntity } from '../../collection-manager.service';

export interface CollectionElementsState extends EntityState<CollectionDataEntity> {
  filteredEntityIds: Set<string>;
}
export const collectionElementEntityAdapter: EntityAdapter<CollectionDataEntity> =
  createEntityAdapter<CollectionDataEntity>({
    sortComparer: false,
  });

export const collectionElementInitialState = collectionElementEntityAdapter.getInitialState({
  filteredEntityIds: null,
});
