import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-content-card',
  template: `
    <div class="wrapper flex items-center justify-between h-[58px] w-full text-sm">
      <div class="card-drag">
        <span class="drag-index">{{ itemIndex + 1 }}</span>
        <!-- <mat-icon cdkDragHandle class=" drag-handler">drag_handle</mat-icon> -->
      </div>

      <div class="content-info flex items-center cursor-pointer">
        <app-content-thumbnail [content]="item"></app-content-thumbnail>
        <div class="w-32 text-caption">
          <p class="text-black80" [matTooltip]="item?.primaryFile?.fileName" matTooltipPosition="above">
            {{ fileName | shorten: 15 }}
          </p>
          <p class="text-grey">{{ size | number }} KB</p>
        </div>
      </div>

      <div class="actions flex items-center justify-end ml-auto mr-1">
        <mat-icon *ngIf="primaryViewableId === item.id" class="text-primary">star</mat-icon>
        <button
          *ngIf="primaryViewableId !== item.id && editable"
          matTooltip="Mark as primary"
          mat-button
          class="btn-32"
        >
          <mat-icon
            class="text-primary"
            (click)="setAsPrimaryView($event)"
            [attr.data-test]="'primary-mark-button-' + item.id"
            >star_outline</mat-icon
          >
        </button>
        <!-- <mat-icon class="cursor-pointer" (click)="$event.stopPropagation()" [matMenuTriggerFor]="optionMenu">more_vert</mat-icon> -->
        <mat-icon *ngIf="editable" class="cursor-pointer" [matMenuTriggerFor]="optionMenu">more_vert</mat-icon>
        <mat-menu #optionMenu="matMenu" xPosition="before" class="menu-sm">
          <button mat-menu-item (click)="setAsPrimaryView($event)">Mark as primary</button>
          <button mat-menu-item (click)="remove($event)">Remove</button>
        </mat-menu>
      </div>
    </div>
  `,
  styles: [
    `
      .card-drag {
        @apply flex justify-center items-center relative h-full w-9;
        .drag-handler {
          @apply cursor-move text-black60 ml-1;
        }
        .drag-index {
          @apply absolute top-1 left-1 text-gray-500;
        }
      }
    `,
  ],
})
export class ContentCardComponent implements OnInit {
  // optionPreviewClass = 'option';
  @Input() item: any;
  @Input() itemIndex: number;
  @Input() primaryViewableId;
  @Input() editable: boolean = true;
  @Output() updateContent = new EventEmitter();

  fileName: string;
  extension: string;
  size: number = 0;
  public isImage = false;
  public is3d = false;
  public noPreview = false;

  constructor() {}

  ngOnInit(): void {
    if (this.item) {
      this.size = Math.round(this.item?.primaryFile?.size / 1000);
      this.fileName = this.item?.primaryFile?.fileName;
      this.setIsImage();
    }
  }

  setIsImage() {
    this.isImage =
      this.item?.primaryFile?.contentType?.indexOf('image') > -1 ||
      this.item?.mediumViewable?.contentType?.indexOf('image') > -1;
    this.is3d = this.item?.primaryFile?.contentType?.indexOf('gltf-binary') > -1;

    this.noPreview = !this.is3d && !this.isImage;
    if (this.noPreview) {
      this.extension = this.fileName?.split('.')?.pop();
    }
  }

  setAsPrimaryView(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.updateContent.emit({ item: this.item, primary: true });
  }
  remove(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.updateContent.emit({ item: this.item, remove: true });
  }
}
