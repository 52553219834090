import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { CreateItemComponent } from './create-item/create-item.component';
import { CreateItemModalComponent } from './create-item-modal/create-item-modal.component';
import { ItemPropertiesFormComponent } from './item-properties-form/item-properties-form.component';
import { ItemFamilyComponent } from './item-details/item-family/item-family.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { ItemTypeSelectorComponent } from './item-details/item-type-selector/item-type-selector.component';
import { TypesModule } from '../types/types.module';
import { ItemDetailsModalComponent } from './item-details-modal/item-details-modal.component';
import { ContextMenuModule } from '../components/context-menu/context-menu.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ItemDetailsHeaderComponent } from './item-details/item-details-header/item-details-header.component';
import { ItemDetailsMetaComponent } from './item-details/item-details-meta/item-details-meta.component';
import { ItemDetailsStatusComponent } from './item-details/item-details-status/item-details-status.component';
import { ComponentsModule } from '../components/components.module';
import { ItemTestComponent } from './item-test/item-test.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { PipesModule } from '@common/pipes/pipes.module';

import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ContentModule } from '@common/content/content.module';
import { ItemDocumentsListComponent } from './item-details/item-documents-list/item-documents-list.component';
import { ListModule } from '@components/list/list.module';

@NgModule({
  declarations: [
    CreateItemComponent,
    CreateItemModalComponent,
    ItemPropertiesFormComponent,
    ItemFamilyComponent,
    ItemDetailsComponent,
    ItemTypeSelectorComponent,
    ItemDetailsModalComponent,
    ItemDetailsHeaderComponent,
    ItemDetailsMetaComponent,
    ItemDetailsStatusComponent,
    ItemTestComponent,
    ItemDocumentsListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatMenuModule,
    MatSlideToggleModule,
    TypesModule,
    ComponentsModule,
    ContextMenuModule,
    PipesModule,
    ContentModule,
    ListModule,
  ],
  exports: [CreateItemModalComponent, CreateItemComponent, ItemDetailsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ItemsModule {}
