import { ViewDefinition } from '@contrail/client-views';
import { PropertyType } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SortObjects } from '../sort/sort-objects';
import { PivotHeaderColumnResizeHelper } from './header-row/pivot-header-column-resize-helper';
import { AggregateRowEntity, PivotAggregateHelper } from './pivot-aggregate-helper';

export class PivotGridManager {
  private columnResizeHelper: PivotHeaderColumnResizeHelper;
  rawData: Array<any> = []; // stores filteredData from CollectionManagerSelectors
  pivotData: Array<any>; // calculated pivotData for display
  originalPivotData: Array<any>; // original calculated pivotData for reference
  viewDefinition: ViewDefinition; // currently selected view definition
  expandedRows: Array<any> = []; // currently expanded rows so that expanded rows can be maintained after sorting
  private groupingProperties: any[];
  private pivotDataSubject: Subject<Array<any>> = new BehaviorSubject(null);
  public pivotData$: Observable<Array<any>> = this.pivotDataSubject.asObservable();
  private viewDefinitionSubject: Subject<ViewDefinition> = new BehaviorSubject(null); // used to emit updated viewDefinition
  public viewDefinition$: Observable<ViewDefinition> = this.viewDefinitionSubject.asObservable();

  constructor() {
    this.columnResizeHelper = new PivotHeaderColumnResizeHelper();
  }

  calculatePivotData(data, viewDefinition: ViewDefinition, keepExpandedRows = false) {
    this.rawData = data;
    this.viewDefinition = viewDefinition;
    if (viewDefinition.properties.length > 0) {
      this.groupingProperties = this.viewDefinition.properties.filter(
        (property) => property.frozen && property.propertyDefinition,
      );
      const enabledProps = viewDefinition.properties.filter(
        (property) => property.enabled && property.propertyDefinition,
      );

      // 'frozen' indicates that the property is a grouping property in the view definition.
      const rowProperties = enabledProps.filter((property) => property.frozen);
      const columnProperties = enabledProps.filter((property) => !property.frozen);
      const groupProperties = rowProperties
        .filter((prop) => prop.propertyDefinition)
        .map((property) => {
          return {
            typeProperty: {
              slug: property.propertyDefinition.slug,
              label: property.propertyDefinition.label,
              propertyType: property.propertyDefinition.propertyType,
              options: property.propertyDefinition.options,
            },
            indexPrefix: null,
          };
        });

      const aggregateProperties = columnProperties.map((property) => {
        return {
          typeProperty: {
            slug: property.propertyDefinition?.slug || 'count',
            label: property.propertyDefinition?.label || 'Count',
            propertyType: property.propertyDefinition?.propertyType || PropertyType.Number,
            aggregateFunction: property.aggregateFunction || 'SUM',
          },
          indexPrefix: null,
        };
      });
      const aggregateRowEntity: AggregateRowEntity = { id: '0', propertySlug: 'root' };
      if (groupProperties.length > 0 && aggregateProperties.length > 0) {
        PivotAggregateHelper.computeAggregates(aggregateRowEntity, data, groupProperties, aggregateProperties);
      }

      if (aggregateRowEntity.children) {
        this.pivotData = aggregateRowEntity.children;
        this.pivotData.forEach((row) => {
          row.topRow = true;
        });
        if (viewDefinition?.sorts?.length > 0) {
          SortObjects.sort(this.pivotData, ObjectUtil.cloneDeep(viewDefinition.sorts));
        }
        this.originalPivotData = ObjectUtil.cloneDeep(this.pivotData);
        const updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
        const clonedExpandedRows = ObjectUtil.cloneDeep(this.expandedRows);
        this.expandedRows = [];
        // keep expanded rows expanded
        if (keepExpandedRows) {
          clonedExpandedRows.forEach((expandedRow) => {
            const row = updatedPivotData.find((pivotrow) => pivotrow.id === expandedRow.id);
            if (row) {
              this.expandRow(row, updatedPivotData);
            }
          });
        }
        this.pivotData = ObjectUtil.cloneDeep(updatedPivotData);
        this.pivotDataSubject.next(ObjectUtil.cloneDeep(updatedPivotData));
      }
    } else {
      const view = {
        label: 'View Options',
        slug: 'pivotViewOptions',
        icon: '',
      };
    }
  }

  public expandSingleRow(rowData: any) {
    const updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    this.expandRow(rowData, updatedPivotData);
    this.pivotData = updatedPivotData;
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(updatedPivotData));
  }

  public collapseSingleRow(rowData: any) {
    const updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    this.collapseRow(rowData, updatedPivotData);
    this.pivotData = updatedPivotData;
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(updatedPivotData));
  }

  public toggleAllRows(changes: any) {
    let updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    if (changes.eventType === 'expand') {
      this.expandRows(updatedPivotData, this.pivotData, this.groupingProperties.length - 2);
    } else {
      updatedPivotData = ObjectUtil.cloneDeep(this.originalPivotData);
      this.expandedRows = [];
    }
    this.pivotData = updatedPivotData;
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(updatedPivotData));
  }

  private expandRows(updatedPivotData, rows, level) {
    rows.forEach((rowData) => {
      if (rowData.level <= level) {
        this.expandRow(rowData, updatedPivotData);
        if (rowData.children) {
          this.expandRows(updatedPivotData, rowData.children, level);
        }
      }
    });
  }

  public expandRowsAtLevel(level) {
    let updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    this.expandRows(updatedPivotData, this.pivotData, level);
    this.pivotData = updatedPivotData;
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(updatedPivotData));
  }

  public collapseRowsAtLevel(level) {
    const updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    const clonedExpandedRows = ObjectUtil.cloneDeep(this.expandedRows);
    clonedExpandedRows.forEach((expandedRow, i) => {
      if (expandedRow.level === level) {
        this.collapseRow(expandedRow, updatedPivotData);
      }
    });
    this.pivotData = updatedPivotData;
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(updatedPivotData));
  }

  private expandRow(rowData, updatedPivotData) {
    const groupIndex = this.groupingProperties.findIndex(
      (groupProperty) => groupProperty.slug === rowData.propertySlug,
    );
    const rowIndex = updatedPivotData.findIndex((row) => row.id === rowData.id);
    if (!updatedPivotData[rowIndex].expanded && this.groupingProperties[groupIndex + 1]) {
      this.expandedRows.push(ObjectUtil.cloneDeep(rowData));
      updatedPivotData[rowIndex].expanded = true;
      const sortIndex = this.viewDefinition.sorts?.findIndex(
        (sort) => sort.propertySlug === this.groupingProperties[groupIndex + 1].slug,
      );
      const children = ObjectUtil.cloneDeep(rowData.children);
      if (sortIndex > -1) {
        SortObjects.sort(children, ObjectUtil.cloneDeep(this.viewDefinition.sorts));
      }
      updatedPivotData.splice(rowIndex + 1, 0, ...children);
    }
  }

  private collapseRow(rowData, updatedPivotData) {
    this.expandedRows.splice(
      this.expandedRows.findIndex((expandedRow) => expandedRow.id === rowData.id),
      1,
    );
    const rowIndex = updatedPivotData.findIndex((row) => row.id === rowData.id);
    updatedPivotData[rowIndex].expanded = false;
    rowData.children.forEach((child) => {
      this.removeChildRows(updatedPivotData, child);
    });
  }

  private removeChildRows(updatedPivotData: any, row: any) {
    const clonedRow = ObjectUtil.cloneDeep(row);
    const rowIndex = updatedPivotData.findIndex((data) => data.id === row.id);
    if (rowIndex > -1) {
      updatedPivotData.splice(rowIndex, 1);
      clonedRow.children?.forEach((child) => {
        this.removeChildRows(updatedPivotData, child);
      });
    }
  }

  public sortPivotData(viewDefinition: ViewDefinition) {
    const updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    updatedPivotData
      .filter((rowData) => rowData.propertySlug === this.groupingProperties[0].slug)
      .forEach((rowData) => {
        const rowIndex = updatedPivotData.findIndex((row) => row.id === rowData.id);
        updatedPivotData[rowIndex].expanded = false;
        rowData.children?.forEach((child) => {
          this.removeChildRows(updatedPivotData, child);
        });
      });
    SortObjects.sort(updatedPivotData, ObjectUtil.cloneDeep(viewDefinition.sorts));
    this.groupingProperties.forEach((groupProperty, i) => {
      this.expandedRows
        .filter((expandedRow) => expandedRow.propertySlug === groupProperty.slug)
        .forEach((expandedRow) => {
          const rowIndex = updatedPivotData.findIndex((row) => row.id === expandedRow.id);
          updatedPivotData[rowIndex].expanded = true;
          const children = ObjectUtil.cloneDeep(updatedPivotData[rowIndex].children);
          const sortIndex = viewDefinition.sorts.findIndex(
            (sort) => sort.propertySlug === this.groupingProperties[i + 1].slug,
          );
          if (sortIndex > -1) {
            SortObjects.sort(children, [ObjectUtil.cloneDeep(viewDefinition.sorts)[sortIndex]]);
          }
          updatedPivotData.splice(rowIndex + 1, 0, ...children);
        });
    });

    this.pivotData = updatedPivotData;
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(this.pivotData));
  }

  public arePropertiesEqual(selectedViewDefinition: ViewDefinition, viewDefinition: ViewDefinition): boolean {
    if (!selectedViewDefinition) {
      return false;
    }
    let sameProperties = true;
    // look for grouping column differences first
    const selectedViewDefinitionProps = viewDefinition.properties.filter(
      (property) => property.frozen && property.propertyDefinition,
    );
    if (this.groupingProperties.length !== selectedViewDefinitionProps.length) {
      sameProperties = false;
    } else {
      selectedViewDefinitionProps.forEach((property, i) => {
        if (this.groupingProperties[i].slug !== property.slug) {
          sameProperties = false;
        }
      });
    }

    // look for value column differences
    viewDefinition.properties.forEach((property) => {
      if (selectedViewDefinition.properties.findIndex((prop) => prop.slug === property.slug) === -1) {
        sameProperties = false;
      }
    });
    return sameProperties;
  }

  public areSortsEqual(selectedViewDefinition: ViewDefinition, viewDefinition: ViewDefinition): boolean {
    if (!selectedViewDefinition || selectedViewDefinition.sorts?.length !== viewDefinition.sorts?.length) {
      return false;
    }
    let sameSorts = true;
    selectedViewDefinition.sorts?.forEach((sort, i) => {
      if (ObjectUtil.compareDeep(sort, viewDefinition.sorts[i], '').length > 0) {
        sameSorts = false;
      }
    });
    return sameSorts;
  }

  public areAggregateFunctionsEqual(selectedViewDefinition: ViewDefinition, viewDefinition: ViewDefinition): boolean {
    let sameProperties = true;
    selectedViewDefinition.properties.forEach((property, i) => {
      if (property.aggregateFunction !== viewDefinition.properties[i].aggregateFunction) {
        sameProperties = false;
      }
    });
    return sameProperties;
  }

  public isDataEqual(data: Array<any>, data2: Array<any>) {
    return (
      data
        ?.map((row) => row.id)
        .sort()
        .toString() ===
      data2
        ?.map((row) => row.id)
        .sort()
        .toString()
    );
  }

  calculateWidth(viewDefinition: ViewDefinition) {
    let leftSideColumnWidth = 0;
    let rightSideColumnWidth = 0;
    const leftSideColumns = viewDefinition.properties.filter(
      (property) => property.enabled && property.frozen && property.propertyDefinition,
    );
    if (leftSideColumns.length > 0) {
      leftSideColumnWidth = leftSideColumns[0].width || leftSideColumns.length * 100; // use the first prop's width if exists
    }
    viewDefinition.properties
      .filter((property) => property.enabled && !property.frozen && property.propertyDefinition)
      .forEach((prop) => {
        rightSideColumnWidth += prop.width || 150;
      });
    return { leftSideColumnWidth, rightSideColumnWidth };
  }

  async handleResizeColumn(columnChanges: any) {
    switch (columnChanges.eventType) {
      case 'dragStart':
        this.columnResizeHelper.handleColumnResizeDragStart(columnChanges.property);
        break;
      case 'dragMove':
        this.columnResizeHelper.handleColumnResizeDragMove(columnChanges.event);
        break;
      case 'dragEnd':
        this.viewDefinition = await this.columnResizeHelper.handleColumnResizeDragDrop(
          ObjectUtil.cloneDeep(this.viewDefinition),
        );
        this.viewDefinitionSubject.next(ObjectUtil.cloneDeep(this.viewDefinition));
        break;
    }
  }
}
