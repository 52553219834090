import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyType } from '@contrail/types';
import { FilterConditionType, FilterConditionTypePropertyTypeMap } from '@contrail/filters';
import { FilterCriteria } from '@contrail/filters';
import { FilterDefinition } from 'src/app/common/types/filters/filter-definition';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent implements OnInit {
  @Input() filterDefinition: FilterDefinition;
  @Input() equalsOnly = false;
  @Input() hidePropertyTypes = [PropertyType.Image];
  @Input() sourceValues: Array<any>;
  @Input() propertyTypeDefaultFilterConditions: any;
  @Output() filterCriteriaChange: EventEmitter<FilterCriteria> = new EventEmitter();
  autoFilter = false;

  constructor() {}

  ngOnInit(): void {
    if (!this.filterDefinition) {
      return;
    }
    if (this.hidePropertyTypes.length) {
      this.hidePropertyTypes.forEach((propertyType) => {
        const filterPropertyDefinitions = this.filterDefinition.filterPropertyDefinitions.filter(
          (prop) => prop.propertyType !== propertyType,
        );
        this.filterDefinition = { filterCriteria: this.filterDefinition.filterCriteria, filterPropertyDefinitions };
      });
    }
  }

  addCriteria() {
    const firstProperty = this.filterDefinition.filterPropertyDefinitions[0];
    console.log(`firstProperty ${JSON.stringify(firstProperty)}`);
    const filterConditionType = FilterConditionTypePropertyTypeMap.get(firstProperty.propertyType)[0];
    this.filterDefinition.filterCriteria = this.filterDefinition.filterCriteria || { propertyCriteria: [] };
    this.filterDefinition.filterCriteria.propertyCriteria.push({
      filterPropertyDefinition: null,
      filterConditionType: null,
    });
  }

  removeCriteria(criteria) {
    const index = this.filterDefinition.filterCriteria.propertyCriteria.findIndex((crit) => crit === criteria);
    this.filterDefinition.filterCriteria.propertyCriteria.splice(index, 1);
    if (this.filterDefinition.filterCriteria.propertyCriteria.length === 0) {
      this.applyFilter(true);
    } else {
      this.applyFilter(false);
    }
  }

  handleCriteriaChange(criteria) {
    this.applyFilter(false);
  }

  emitUpdatedCriteria() {
    this.filterCriteriaChange.emit(this.filterDefinition.filterCriteria);
  }

  applyFilter(skipCheck) {
    if (this.autoFilter || skipCheck) {
      this.emitUpdatedCriteria();
    }
  }

  clearAllFilterProperties() {
    this.filterDefinition.filterCriteria.propertyCriteria = [];
    this.applyFilter(true);
  }

  isFilterActive() {
    return false;
  }

  toggleAutoFilter(event) {
    this.autoFilter = !this.autoFilter;
  }

  isApplyActive() {
    if (
      !this.autoFilter &&
      this.filterDefinition.filterCriteria.propertyCriteria.length > 0 &&
      this.filterDefinition.filterCriteria.propertyCriteria.findIndex((filter) => {
        return (
          ![FilterConditionType.IS_EMPTY, FilterConditionType.IS_NOT_EMPTY].includes(filter.filterConditionType) &&
          (filter.filterPropertyDefinition === null || filter.criteriaValue === null)
        ); // not property or criteria value
      }) === -1
    ) {
      return true;
    }
    return false;
  }
}
