<app-type-property-form
  *ngIf="propertyConfigurations"
  [propertyConfigurations]="propertyConfigurations"
  [errors]="errors"
  (changes)="handleFormChanges($event)"
  [values]="values"
>
</app-type-property-form>
<div class="flex justify-end">
  <button mat-flat-button color="primary" [disabled]="!isCreateValid" (click)="save()">Save</button>
</div>
