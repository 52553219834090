import { State } from './plans.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as PlansActions from './plans.actions';
import { plansEntityAdapter } from './plans.state';
import { plansInitialState } from './plans.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    plans: plansEntityAdapter.setAll(data, state.plans),
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    plans: plansEntityAdapter.removeAll(state.plans),
  };
};
const add = (state: State, { plan }) => {
  return {
    ...state,
    plans: plansEntityAdapter.addOne(plan, state.plans),
  };
};
const update = (state: State, { id, changes }) => {
  let currentPlan = state.currentPlan;
  if (state.currentPlan?.id === id) {
    currentPlan = { ...state.currentPlan, ...changes };
  }
  return {
    ...state,
    plans: plansEntityAdapter.updateOne({ id, changes }, state.plans),
    currentPlan,
  };
};
const remove = (state: State, { plan }) => {
  return {
    ...state,
    plans: plansEntityAdapter.removeOne(plan.id, state.plans),
  };
};
const setCurrentPlan = (state: State, { plan }) => {
  return {
    ...state,
    currentPlan: plan,
  };
};
const clearCurrentPlan = (state: State) => {
  return {
    ...state,
    currentPlan: null,
  };
};
const setPlansLoaded = (state: State, { loaded }) => {
  return {
    ...state,
    plansLoaded: loaded,
  };
};
const setCurrentPlanGoals = (state: State, { planGoals }) => {
  return {
    ...state,
    currentPlan: { ...state.currentPlan, planGoals },
  };
};
const setEditorMode = (state: State, { editorMode }) => {
  return {
    ...state,
    editorMode,
  };
};

const setPlanRowOrder = (state: State, { planRowOrder }) => {
  return {
    ...state,
    planRowOrder,
  };
};

const syncPlanRowOrder = (state: State, { planRowOrder }) => {
  return {
    ...state,
    planRowOrder,
  };
};

export const plansReducers = createReducer(
  plansInitialState,
  on(PlansActions.loadPlansSuccess, setData),
  on(PlansActions.createPlanSuccess, add),
  on(PlansActions.copyPlanSuccess, add),
  on(PlansActions.deletePlanSuccess, remove),
  on(PlansActions.updatePlanSuccess, update),
  on(PlansActions.loadCurrentPlanSuccess, setCurrentPlan),
  on(PlansActions.clearCurrentPlan, clearCurrentPlan),
  on(PlansActions.setPlansLoaded, setPlansLoaded),
  on(PlansActions.setCurrentPlanGoals, setCurrentPlanGoals),
  on(PlansActions.setEditorMode, setEditorMode),
  on(PlansActions.setPlanRowOrder, setPlanRowOrder),
  on(PlansActions.syncPlanRowOrder, syncPlanRowOrder),
);

export function reducer(state: State | undefined, action: Action): any {
  return plansReducers(state, action);
}
