import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { CommentsActions, CommentsSelectors } from '../comments-store';
import { Comment } from '../comments.service';

@Component({
  selector: 'app-header-comments',
  templateUrl: './header-comments.component.html',
  styleUrls: ['./header-comments.component.scss'],
})
export class HeaderCommentsComponent {
  @Input() allowToggleSideBar = true;
  @Output() commentCountClicked = new EventEmitter();

  comments$: Observable<Array<Comment>>;

  constructor(private store: Store<RootStoreState.State>) {
    this.comments$ = this.store
      .select(CommentsSelectors.selectContextComments)
      .pipe(map((comments) => comments.filter((comment: Comment) => comment.status === 'open')));
  }

  toggleSideBar() {
    if (this.allowToggleSideBar) {
      this.store.dispatch(CommentsActions.toggleCommentsSideBar());
    }
    this.commentCountClicked.emit();
  }
}
