import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { Types } from '@contrail/sdk';
import { PropertyType, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { DataGroup } from './data-group';
import { ItemUpdateService } from '../items/item-update-service';

@Injectable({
  providedIn: 'root',
})
export class DataGroupHelper {
  private typeMap;
  constructor(
    public store: Store<RootStoreState.State>,
    private itemUpdateService: ItemUpdateService,
  ) {
    this.store.select(CollectionManagerSelectors.typeDefinitions).subscribe((typeMap) => {
      this.typeMap = typeMap;
    });
  }
  public async handleDrop(event: CdkDragDrop<string[]>, collection: any, properties: Array<TypeProperty>) {
    console.log('DataGroupHelper: handleDrop: ');
    const data = event.container.data;
    const group: DataGroup = this.getGroupFromData(data, collection);
    const obj: any = event.previousContainer.data[event.previousIndex];
    const changes = {};
    if (properties[0]) {
      if (properties[0].propertyType === PropertyType.ObjectReference) {
        changes[properties[0].slug + 'Id'] = group.dim1.value?.id || null;
      }
      changes[properties[0].slug] = group.dim1.value;
    }
    if (properties[1]) {
      if (properties[1].propertyType === PropertyType.ObjectReference) {
        changes[properties[1].slug + 'Id'] = group.dim2.value?.id || null;
      }
      changes[properties[1].slug] = group.dim2.value;
    }

    // DETERMINE IF THIS IS A CHANGE TO AN ITEM SCOPED PROPERTY...
    // CHECK IF THIS CHANGE IS TO AN ITEM.... (POSSIBLY MOVE TO A SEPERATE CLASS?)
    let item = obj.itemFamily;
    const dim1 = group.dim1;
    const dim1Slug = group.dim1.slug;
    if (item && group.dim1.scope === 'item') {
      const itemType = await new Types().getType({ id: item.typeId });
      const propertyDef = itemType.typeProperties.find((prop) => prop.slug === dim1Slug);
      if (dim1Slug === 'optionName' || propertyDef.propertyLevel === 'option') {
        item = obj.itemOption;
      }
    }
    if (item && dim1.scope === 'item') {
      // WE JUST EDITED AN ITEM PROPERTY.  ASSUME THAT IT WAS ALLOWED (ACCESS CHECK PREVIOUS)
      item = ObjectUtil.cloneDeep(item);
      // THIS APPLYS CHANGES TO ALL IMPACTED PLACEHOLDERS AND PERSISTS THE ITEM
      this.itemUpdateService.batchUpdateItems({
        changes: [{ id: item.id, changes }],
        placeholderUpdates: {
          updates: [{ item, changes }],
        },
      });
    } else {
      this.store.dispatch(CollectionManagerActions.updateCollectionDataEntity({ id: obj.id, changes }));
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }
  private getGroupFromData(data, collection): DataGroup {
    for (const row of collection) {
      if (row.data) {
        // ONE DIM
        if (row.data === data) {
          return row;
        }
      } else {
        // TWO DIM
        for (const group of row.set) {
          if (group.data === data) {
            return group;
          }
        }
      }
    }
  }
  getValue(propertySlug: string, data) {
    return data[propertySlug];
  }

  getProperty(root, slug) {
    const type = this.typeMap[root];
    if (type?.typeProperties) {
      return type.typeProperties.filter((prop) => prop.slug === slug)[0];
    }
  }

  getPropertyScope(propertySlug: String) {
    const itemProperty = this.typeMap['item'].typeProperties.find((prop) => prop.slug === propertySlug);
    if (itemProperty) {
      return 'item';
    }
    const assortmentProperty = this.typeMap['assortment-item'].typeProperties.find(
      (prop) => prop.slug === propertySlug,
    );
    if (assortmentProperty) {
      return 'assortment-item';
    }
    const projectItemProperty = this.typeMap['project-item'].typeProperties.find((prop) => prop.slug === propertySlug);
    if (projectItemProperty) {
      return 'project-item';
    }
    return 'plan-placeholder';
  }
}
