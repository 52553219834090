import { Component, OnInit, OnChanges, Input, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import {
  TypePropertyFormComponent,
  TypePropertyFormConfiguration,
} from '@common/types/forms/type-property-form/type-property-form.component';
import { AccessManagedType, Entities, Types } from '@contrail/sdk';
import { EntityFormHelper } from '../entity-form-helper';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-create-entity',
  templateUrl: './create-entity.component.html',
  styleUrls: ['./create-entity.component.scss'],
})
export class CreateEntityComponent implements OnInit, OnChanges {
  @Input() entityType: string;
  @Output() createComplete = new EventEmitter();
  public type: AccessManagedType;
  public propertyConfigurations: Array<TypePropertyFormConfiguration>;
  public values: any = {};
  public errors = {};
  public isCreateValid = false;
  @ViewChild(TypePropertyFormComponent) form: TypePropertyFormComponent;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {}

  async ngOnChanges() {
    if (!this.entityType) {
      return;
    }
    this.type = await new Types().getType({ path: this.entityType });
    this.values.typeId = this.type.id;
    const propertyConfigurations = [];
    const nameProperty = this.type.typeProperties.find((prop) => prop.slug === 'name');
    if (nameProperty) {
      propertyConfigurations.push({ typeProperty: nameProperty, editable: true });
    }
    let remaining: any = this.type.typeProperties
      .filter((prop) => !['name', 'updatedOn', 'createdOn'].includes(prop.slug))
      .map((prop) => {
        return { typeProperty: prop, editable: true };
      })
      .sort((t1, t2) => (t1.typeProperty?.label > t2.typeProperty?.label ? 1 : -1));
    propertyConfigurations.push(...remaining);
    this.propertyConfigurations = propertyConfigurations;
    this.changeDetectorRef.markForCheck();
  }

  public async handleFormChanges($event) {
    this.errors = {};
    this.changeDetectorRef.detectChanges();
    this.errors = await EntityFormHelper.getValidationErrors(this.form, this.type);
    this.isCreateValid = Object.keys(this.errors).length === 0;
    this.changeDetectorRef.detectChanges();
  }

  public async save() {
    this.errors = await EntityFormHelper.getValidationErrors(this.form, this.type);
    this.changeDetectorRef.detectChanges();
    this.isCreateValid = Object.keys(this.errors).length === 0;
    const errorCount = Object.keys(this.errors).length;
    if (!this.isCreateValid) {
      return;
    }

    const data: any = EntityFormHelper.compileEntityValues(this.form);

    // Create the entity

    const typePath = this.type.typePath;
    let entityType = typePath;
    if (typePath.indexOf(':') > 0) {
      entityType = typePath.substring(0, typePath.indexOf(':'));
    }

    // set type id on new object.
    data.typeId = this.type.id;

    console.log('creating new entity: ', data);
    let existingEntity;
    if (data.name) {
      const matchingEntities = await new Entities().get({
        entityName: entityType,
        criteria: { name: data.name, typeId: data.typeId },
      });
      existingEntity = matchingEntities.find((entity) => entity.name?.toLowerCase() === data.name.toLowerCase());
    }

    if (existingEntity) {
      this.snackBar.open('An entity with the same name already exists.', '', { duration: 5000 });
      return;
    }

    const newEntity = await new Entities().create({
      entityName: entityType,
      object: data,
    });

    this.createComplete.emit(newEntity);
  }
}
