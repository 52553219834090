import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { SessionMessage, SessionMessageHandler } from '../../common/web-socket/session-message';
import { WebSocketMessageDispatcher } from '../../common/web-socket/web-socket-message-dispatcher';
import { CollectionManagerActions } from '../collection-manager-store';

@Injectable({
  providedIn: 'root',
})
export class CollectionManagerRemoteSessionMessageHandler implements SessionMessageHandler {
  public someProp = false;
  constructor(
    private store: Store<RootStoreState.State>,
    private websocketMessageDispatcher: WebSocketMessageDispatcher,
  ) {
    this.init();
  }

  private init() {
    this.websocketMessageDispatcher.registerHandler('SELECT_ENTITY_PROPERTY', this);
  }

  public handleMessage(message: SessionMessage) {
    switch (message.event?.eventType) {
      case 'SELECT_ENTITY_PROPERTY': {
        const data = message.event.data;
        this.store.dispatch(
          CollectionManagerActions.updateRemoteSelectedEntityProperties({
            clientId: message.context.user.clientId,
            entityId: data.entityId,
            propertySlug: data.propertySlug,
            user: message.context.user,
          }),
        );
        break;
      }
    }
  }
}
