import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import {
  CollectionManagerActions,
  CollectionManagerSelectors,
} from 'src/app/collection-manager/collection-manager-store';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { RootStoreState } from 'src/app/root-store';
import { DataCellComponent } from '../data-cell/data-cell.component';
import { PlaceholderItemAssignmentService } from 'src/app/collection-manager/placeholders/placeholder-item-assignment-service';
import { GridViewManager } from '../../grid-view.manager';
import { RowDragHandler } from '../../row-drag/row-drag-handler';
import { map, take } from 'rxjs/operators';
import { GridSelectorService, SelectorCell } from '../../selectors/grid-selector.service';
import { CollectionStatusMessage } from 'src/app/collection-manager/collection-manager-store/collection-status-messages/collection-status-messages.state';
import { ImageAssignmentInteractionService } from 'src/app/collection-manager/image-assignment/image-assignment-interaction.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-data-row',
  templateUrl: './data-row.component.html',
  styleUrls: ['./data-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataRowComponent implements AfterViewInit, OnInit, OnChanges {
  constructor(
    private store: Store<RootStoreState.State>,
    private placeholderItemService: PlaceholderItemAssignmentService,
    private zone: NgZone,
    private eRef: ElementRef,
    private gridViewManager: GridViewManager,
    private gridSelectorService: GridSelectorService,
    private imageAssignmentInteractionService: ImageAssignmentInteractionService,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
  ) {}

  @Input() dataRow: any;
  @Input() properties: Array<ViewPropertyConfiguration>;
  @ViewChildren(DataCellComponent) cellQuery: QueryList<DataCellComponent>;
  @ViewChild('dataRowElement') dataRowElem: ElementRef;
  @ViewChild('rowDragHandle') rowDragHandleElem: ElementRef; // The cell that acts as the row drag handle
  @Input() side: string;
  @Input() rowNumber: number;
  @Input() realRowNumber: number;
  @Input() editorMode: string;
  public rowId: string;
  public cells: Array<DataCellComponent> = [];
  public isSelected$: Observable<boolean>;
  public isHovered$: Observable<boolean>;
  public isHighlighted$: Observable<boolean>;
  public isCopied$: Observable<boolean>;
  public copied = false;
  public rowHeight = 24;
  public selectedIds: Array<string>;
  public rowDragHandler;
  private shiftKey = false;
  public isStatusMessageElementSelected$: Observable<boolean>;
  public selectedRangeCells$: Observable<any[]>;
  public selectedFillCells$: Observable<any[]>;
  public selectedCutCells$: Observable<any[]>;
  public searchReplaceCells$: Observable<SelectorCell[]>;
  public overrideOptionCells$: Observable<SelectorCell[]>;
  public remoteSelectedProperties$: Observable<any[]>;
  public collectionStatusMessages$: Observable<any[]>;
  public activeSearchReplaceCell$: any;
  public selectedCellRow$: any;
  public isRowDropped = false;
  initTime;
  rightPropertiesViewPortWidth: number;
  columnDisplayFromIndex = 0;
  columnDisplayToIndex = 10;

  public ngAfterViewInit(): void {
    this.setCells(this.cellQuery);
    this.cellQuery.changes.subscribe((cellQuery: QueryList<DataCellComponent>) => {
      this.setCells(cellQuery);
    });
    this.rowDragHandler = new RowDragHandler(this.store, this.renderer, this.gridViewManager, this);
  }
  ngOnInit() {
    this.subscribe();
    this.zone.runOutsideAngular(() => {
      this.eRef.nativeElement.addEventListener('dragover', this.onDragOver.bind(this));
    });
  }
  ngOnChanges() {
    if (this.dataRow) {
      this.rowId = this.dataRow.id;
    }
    this.isRowDropped = this.dataRow.isDropped === true;
  }

  subscribe() {
    this.selectedRangeCells$ = this.store.pipe(
      select(CollectionManagerSelectors.selectorCells),
      map((selectorCells) => {
        return selectorCells.filter((cell) => cell.rowId === this.rowId).map((cell) => cell.columnId);
      }),
    );

    this.selectedFillCells$ = this.store.pipe(
      select(CollectionManagerSelectors.fillCells),
      map((fillCells) => {
        return fillCells.filter((cell) => cell.rowId === this.rowId).map((cell) => cell.columnId);
      }),
    );

    this.selectedCutCells$ = this.store.pipe(
      select(CollectionManagerSelectors.cutCells),
      map((cutCells) => {
        return cutCells.filter((cell) => cell.rowId === this.rowId).map((cell) => cell.columnId);
      }),
    );

    this.searchReplaceCells$ = this.store.pipe(
      select(CollectionManagerSelectors.searchReplaceCells),
      map((searchReplaceCells) => {
        return searchReplaceCells.filter((cell) => cell.rowId === this.rowId).map((cell) => cell.columnId);
      }),
    );

    this.overrideOptionCells$ = this.store.pipe(
      select(CollectionManagerSelectors.overrideOptionCells),
      map((overrideOptionCells) => {
        return overrideOptionCells.filter((cell) => cell.rowId === this.rowId).map((cell) => cell.columnId);
      }),
    );

    this.collectionStatusMessages$ = this.store.pipe(
      select(CollectionManagerSelectors.collectionStatusMessages),
      map((collectionStatusMessages) => {
        return collectionStatusMessages.filter((mes) => mes['collectionElementId'] === this.rowId);
      }),
    );

    this.remoteSelectedProperties$ = this.store.pipe(
      select(CollectionManagerSelectors.remoteSelectedProperties),
      map((remoteSelectedProperties) => {
        const remoteCellIds = [];
        for (const [key, value] of remoteSelectedProperties) {
          if (this.rowId === value.entityId) {
            remoteCellIds.push(value);
          }
        }
        return remoteCellIds;
      }),
    );

    this.activeSearchReplaceCell$ = this.store.pipe(
      select(CollectionManagerSelectors.activeSearchReplaceCell),
      map((activeSearchReplaceCell) => {
        if (activeSearchReplaceCell?.rowId === this.rowId) {
          return activeSearchReplaceCell;
        }
        return null;
      }),
    );

    this.selectedCellRow$ = this.store.pipe(
      select(CollectionManagerSelectors.selectedProperty),
      map((selectedProperty) => {
        if (selectedProperty?.entityId === this.rowId) {
          return selectedProperty;
        }
        return null;
      }),
    );

    this.isSelected$ = this.store.pipe(
      select(CollectionManagerSelectors.selectedEntityIds),
      map((selectedEntityIds) => selectedEntityIds.includes(this.rowId)),
    );

    this.isHovered$ = this.store.pipe(
      select(CollectionManagerSelectors.hoveredEntityId),
      map((hoveredEntityId) => hoveredEntityId === this.rowId),
    );

    this.isHighlighted$ = combineLatest([this.isSelected$, this.isHovered$]).pipe(
      map((rowStatuses) => rowStatuses[0] || rowStatuses[1]),
    );

    this.isCopied$ = this.store.pipe(
      select(CollectionManagerSelectors.copiedRows),
      map((copiedRows) => copiedRows.includes(this.rowId)),
    );

    this.isStatusMessageElementSelected$ = this.store.pipe(
      select(CollectionManagerSelectors.statusMessageElement),
      map((statusMessageElement) => statusMessageElement === this.rowId),
    );
  }

  async toggleSelected() {
    const isSelected = await this.isSelected$.pipe(take(1)).toPromise();
    if (!isSelected) {
      this.gridViewManager.removeSelectedCell();
      this.store.dispatch(CollectionManagerActions.addSelectedEntityId({ id: this.rowId }));
      if (this.shiftKey) {
        this.gridSelectorService.handleSelectRowRange();
        this.shiftKey = false;
      }
    } else {
      this.store.dispatch(CollectionManagerActions.removeSelectedEntityId({ id: this.rowId }));
    }
  }

  setCells(cellQuery: QueryList<DataCellComponent>) {
    this.cells = cellQuery.toArray();
  }

  trackByFn(index, property: ViewPropertyConfiguration) {
    return property.slug;
  }

  @HostListener('mouseenter', ['$event']) public mouseEnter(evt) {
    this.store.dispatch(CollectionManagerActions.setHoveredEntityId({ id: this.rowId }));
  }
  public onDragOver(evt) {
    evt.preventDefault();
    const group = this.eRef.nativeElement.querySelector('.grid-row');
    group.classList.add('hover');
    evt.stopPropagation();
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    const listItem = this.eRef.nativeElement.querySelector('.grid-row');
    listItem.classList.remove('hover');
    evt.stopPropagation();
  }
  @HostListener('drop', ['$event']) async stdDrop(evt) {
    evt.preventDefault();
    const listItemDiv = this.eRef.nativeElement.querySelector('.grid-row');
    listItemDiv.classList.remove('hover');
    if (evt.dataTransfer.files.length > 0) {
      if (this.editorMode !== 'VIEW') {
        if (!this.dataRow.itemFamily) {
          this.snackBar.open('Image can only be assigned to an item.', '', { duration: 4000 });
        } else {
          if (evt.dataTransfer.files.length !== 1) {
            this.snackBar.open('Please add one image only.', '', { duration: 4000 });
          } else {
            this.imageAssignmentInteractionService.handleImageItemAssignmentFromFiles(
              this.dataRow,
              evt.dataTransfer.files,
            );
          }
        }
      }
    } else {
      const entityString = evt.dataTransfer.getData('entity');
      const entity = JSON.parse(entityString);
      if (entity) {
        this.placeholderItemService.assignItemsToPlaceholders(
          [],
          [],
          [{ placeholder: this.dataRow, itemData: entity }],
        );
        //this.placeholderItemService.setItemOptionOnPlaceholder(this.dataRow, entity);
      }
    }
    evt.stopPropagation();
  }

  checkboxClicked(event) {
    // stop the parent div from getting the mousedown event
    if (event.shiftKey) {
      this.shiftKey = true;
    }
    event.stopPropagation();
  }
}
