import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-modal-details',
  template: `
    <div class="w-full h-full flex">
      <div class="content-view flex-center border-r">
        <app-content-preview [content]="content" [size]="size" class="w-full h-full flex-center"></app-content-preview>
      </div>
      <div class="content-info w-[340px]">
        <app-content-info-panel [content]="content" [downloadBtn]="true" (downloadContent)="download()">
        </app-content-info-panel>
      </div>
    </div>
  `,
  styles: [
    `
      .content-view {
        width: calc(100% - 340px);
      }
    `,
  ],
})
export class ContentModalDetailsComponent implements OnInit {
  @Input() content;
  @Input() contentType;
  size = '678px';

  constructor() {}

  ngOnInit(): void {
    if (this.contentType !== 'is3d') {
      this.size = '95%';
    }
  }

  download() {
    const fileUrl = this.content?.primaryFile?.downloadUrl;
    window.open(fileUrl, '_blank');
  }
}
