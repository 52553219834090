import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { PlansSelectors, RootStoreState } from '@rootstore';
import { take, tap } from 'rxjs';
import { CollectionManagerSelectors, CollectionManagerActions } from '../collection-manager-store';
import { Types } from '@contrail/sdk';
import { CollectionElementActionValidator } from '../collection-element-validator/collection-element-action-validator';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';

@Injectable({
  providedIn: 'root',
})
export class PlaceholderDropService {
  private editorMode: string = EditorMode.VIEW;
  hasIsDroppedFlag = false;
  constructor(
    private store: Store<RootStoreState.State>,
    private collectionElementActionValidator: CollectionElementActionValidator,
    private snackBar: MatSnackBar,
  ) {
    this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
    this.init();
  }

  async init() {
    const phType = await new Types().getType({ root: 'plan-placeholder' });
    const isDroppedProperty = phType.typeProperties.find((p) => p.slug === 'isDropped');
    if (isDroppedProperty) {
      this.hasIsDroppedFlag = true;
    }
  }

  toggleDropPlaceholders(ids: string[]) {
    if (!this.hasIsDroppedFlag || this.editorMode !== EditorMode.EDIT) {
      return;
    }
    let placeholders: any[];
    this.store
      .select(CollectionManagerSelectors.displayedData)
      .pipe(
        take(1),
        tap((data) => {
          placeholders = ObjectUtil.cloneDeep(data.filter((obj) => ids.includes(obj.id)));
        }),
      )
      .subscribe();
    console.log('Dropping: ', placeholders);

    if (!placeholders?.length) {
      return;
    }

    const changes = [];
    let errorMessage: string;
    for (let ph of placeholders) {
      if (!ph.isDropped) {
        const dropCheck = this.collectionElementActionValidator.isDroppable(ph);
        if (!dropCheck.isValid) {
          errorMessage = dropCheck.reason;
          continue;
        }
      }

      changes.push({
        id: ph.id,
        changes: {
          isDropped: !ph.isDropped,
        },
      });
    }

    if (errorMessage) {
      this.snackBar.open(errorMessage, '', { duration: 5000 });
    }

    this.store.dispatch(
      CollectionManagerActions.updateCollectionDataEntities({
        entities: changes,
      }),
    );
    setTimeout(() => {
      this.store.dispatch(CollectionManagerActions.clearSelectedEntityIds());
    }, 1);
  }
}
