import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { AssortmentHistoryComponent } from 'src/app/common/assortment-history/assortment-history.component';
import { Plan } from '../plans-store/plans.state';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import { Observable, firstValueFrom } from 'rxjs';
import { Entities } from '@contrail/sdk';
import { WorkspaceEntitiesHelperService } from '@common/workspaces/workspace-entities-helper.service';

@Component({
  selector: 'app-plan-configuration',
  templateUrl: './plan-configuration.component.html',
  styleUrls: ['./plan-configuration.component.scss'],
})
export class PlanConfigurationComponent implements OnInit, OnChanges {
  @Input() plan: Plan;
  public sourceAssortment: any;

  public selectedWorkspace$: Observable<Workspace>;

  public locationLoading = true;
  folders = [];

  planLocation: any = null;

  constructor(
    private store: Store<RootStoreState.State>,
    private matDialog: MatDialog,
    private folderEntityService: WorkspaceEntitiesHelperService,
  ) {
    this.selectedWorkspace$ = this.store.select(WorkspacesSelectors.currentWorkspace);
  }

  async ngOnInit() {
    this.locationLoading = true;
    const ws = this.plan?.workspace;

    if (ws?.workspaceType !== 'PROJECT') {
      this.folders.unshift({ id: ws?.id, name: ws?.name, parentId: ws?.parentId });
      // const folder = { id: ws?.id, parentId: ws?.parentId, name: ws?.name }

      const rootId = ws?.rootWorkspaceId;
      let parentId = ws?.parentId;

      for (let idx = 0; idx < 20; idx++) {
        const parent = await this.getParentFolder(parentId);

        if (parent?.id === rootId) {
          break;
        } else {
          parentId = parent?.parentId;
          this.folders.unshift({ id: parent?.id, name: parent?.name, parentId: parent?.parentId });
        }
      }
      this.locationLoading = false;
    }

    this.planLocation = null;
    if (this.folders?.length) {
      let currentWorkspace = await firstValueFrom(this.selectedWorkspace$);
      this.planLocation = {
        ...currentWorkspace,
        name: 'Documents',
      };
      delete this.planLocation?.workspacePrincipals;
      this.folders.forEach((node) => {
        node.parent = this.planLocation;
        this.planLocation = node;
      });
    }
    this.folderEntityService.entityLocationFolderView$.next(this.planLocation);
  }

  private async getParentFolder(id) {
    return await new Entities().get({ entityName: 'workspace', id });
  }

  ngOnChanges(): void {
    if (this.plan?.targetAssortment?.sourceAssortments?.length) {
      this.sourceAssortment = this.plan.targetAssortment?.sourceAssortments[0];
    }
  }
  viewHistory() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '1200px';
    dialogConfig.data = { assortmentId: this.sourceAssortment.id };
    dialogConfig.panelClass = 'no-padding'; // _dialog.scss

    const dialogRef = this.matDialog.open(AssortmentHistoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  getActorName(actor) {
    if (actor?.firstName) {
      return actor?.firstName + ' ' + actor?.lastName;
    }
    return actor?.email;
  }
}
