<app-modal data-test="publish-plan-modal" [title]="'Publish: ' + (currentPlan$ | async)?.name" [dialogRef]="dialogRef">
  <ng-container *ngIf="isLoadingAlerts$ | async">
    <div class="invalid p-4 bg-neutral-50 rounded-lg">
      <mat-icon class="icon">warning</mat-icon>
      <div class="content text-sm">This plan can not be published while alerts are loading.</div>
    </div>
    <div class="actions">
      <button mat-flat-button color="primary" (click)="cancel()">Ok</button>
    </div>
  </ng-container>

  <ng-container *ngIf="haveAlertsFailedToLoad$ | async">
    <div class="invalid p-4 bg-neutral-50 rounded-lg">
      <mat-icon class="icon">warning</mat-icon>
      <div class="content text-sm">This plan can not be published because alerts failed to load.</div>
    </div>
    <div class="actions">
      <button mat-flat-button color="primary" (click)="cancel()">Ok</button>
    </div>
  </ng-container>

  <ng-container *ngIf="!(isLoadingAlerts$ | async) && !(haveAlertsFailedToLoad$ | async)">
    <div *ngIf="['PENDING', 'HAS_CHANGES'].includes(status)">
      <app-publish-summary [diff]="diff" [plan]="currentPlan$ | async"> </app-publish-summary>
    </div>

    <div class="mb-[10px]" *ngIf="status === 'NO_CHANGES'">
      <div class="flex text-green-700 text-lg">
        <div class="h-[50px] w-[50px] mr-[20px]">
          <mat-icon class="text-[50px]">done</mat-icon>
        </div>
        <div>Your assortment is up to date with this plan. However, you can still publish, if desired.</div>
      </div>
    </div>
    <div class="explaination p-4 bg-neutral-50 rounded-lg" *ngIf="!['INVALID', 'PENDING'].includes(status)">
      <div class="content text-sm">
        Publishing a plan updates its underlying assortment with adds, drops, and assortment item changes, so that all
        of those changes can be reflected in other apps. Item and project item changes are automatically propagated
        throughout the system. Every Publish is captured and can be viewed on the Assortment History page.
      </div>
    </div>

    <div class="invalid p-4 bg-neutral-50 rounded-lg" *ngIf="status === 'INVALID'">
      <mat-icon class="icon">warning</mat-icon>
      <div class="content text-sm">
        This plan can not be published because it has one or more validation errors. Please address these errors first.
      </div>
    </div>
    <div class="mt-5" *ngIf="['NO_CHANGES', 'HAS_CHANGES'].includes(status)">
      <mat-form-field style="width: 100%" appearance="fill" class="!mb-2">
        <mat-label>Version Name (Optional)</mat-label>
        <input matInput [formControl]="nameFormControl" />
      </mat-form-field>
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Description (Optional)</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          placeholder=""
          [formControl]="commentsFormControl"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="progress" *ngIf="status === 'PUBLISHING'">
      <mat-spinner [diameter]="60"></mat-spinner>
      <div>Publishing Plan</div>
    </div>

    <div class="progress complete" *ngIf="status === 'COMPLETE'">
      <mat-icon>done</mat-icon>
      <div>Plan published successfully.</div>
    </div>
    <div class="progress failed" *ngIf="status === 'FAILURE'">
      <mat-icon>priority_high</mat-icon>
      <div>Plan publish failed.</div>
    </div>
    <div class="actions" *ngIf="['NO_CHANGES', 'HAS_CHANGES'].includes(status)">
      <button mat-flat-button (click)="cancel()">Cancel</button>
      <button mat-flat-button color="primary" (click)="confirm()">Publish</button>
    </div>
    <div class="actions" *ngIf="['COMPLETE', 'INVALID'].includes(status)">
      <button mat-flat-button color="primary" (click)="cancel()">Ok</button>
    </div>
  </ng-container>
</app-modal>
