import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { AuthSelectors } from '@common/auth/auth-store';
import { ViewDefinition } from '@contrail/client-views';
import { PropertyType, Type, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { filter, take, tap, map } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { environment } from 'src/environments/environment';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';

export interface ViewType {
  icon: string;
  slug: string;
  label: string;
}
export interface ViewDimensionOption {
  value: string;
  label: string;
  propertyType: string;
}

const EXCLUDED_PROPERTIES = ['createdOn', 'updatedOn', 'name', 'optionName'];

@Component({
  selector: 'app-view-form',
  templateUrl: './view-form.component.html',
  styleUrls: ['./view-form.component.scss'],
})
export class ViewFormComponent implements OnInit {
  @Input() newViewSortOrder = 0;
  constructor(private store: Store<RootStoreState.State>) {}

  @Output() cancel = new EventEmitter();
  @Output() done = new EventEmitter();
  @Input() currentView: ViewDefinition;
  @Input() fromTemplate = false;
  @Input() formMode = 'CREATE';

  public dimension1;
  public dimension2;
  public selectedViewType: ViewType = null;
  public nameFormControl = new UntypedFormControl(null, Validators.required);
  public dimension1FormControl = new UntypedFormControl(null, Validators.required);
  public dimension1Options: Array<ViewDimensionOption>;
  public dimension2Options: Array<ViewDimensionOption>;
  public allDimensionOptions: Array<ViewDimensionOption>;
  public dimension2FormControl = new UntypedFormControl(null, Validators.required);
  public isAlreadySubmitted = false;
  public privateFormControl = new FormControl();
  public adminFormControl = new FormControl();
  isOrgAdmin = false;

  public viewTypes: Array<ViewType> = [
    { icon: 'view_comfy', slug: 'grid', label: 'Sheet' },
    { icon: 'view_column', slug: 'kanban', label: 'Kanban' },

    // { icon: 'grid_view', slug: 'matrix', label: 'Matrix' }
  ];

  ngOnInit(): void {
    this.store
      .select(CollectionManagerSelectors.typeDefinitions)
      .pipe(
        filter((typeDefs) => typeDefs !== null),
        tap((typeDefs) => {
          this.getDimensionValuesFromTypes(typeDefs);
        }),
        take(1),
      )
      .subscribe();

    this.store
      .select(AuthSelectors.selectAuthContext)
      .pipe(
        take(1),
        tap((authContext) => {
          const showBeta = authContext?.currentOrg?.orgSlug === 'demo' || !environment.production;
          this.isOrgAdmin = authContext.currentOrg.role === 'ADMIN';
          this.viewTypes.push({ icon: 'pivot_table_chart', slug: 'pivot', label: 'Pivot' });
        }),
      )
      .subscribe();

    if (this.currentView?.id) {
      this.selectedViewType = this.viewTypes.filter((view) => view.slug === this.currentView.viewType)[0];
      this.nameFormControl.setValue(this.currentView.label);
      this.adminFormControl.setValue(this.currentView.admin);
      if (this.currentView.admin && this.isOrgAdmin) {
        this.privateFormControl.setValue(false);
        this.privateFormControl.disable();
      }
    }

    this.adminFormControl.valueChanges.subscribe((changes) => {
      if (changes === true) {
        this.privateFormControl.setValue(false);
        this.privateFormControl.disable();
      } else {
        this.privateFormControl.enable();
      }
    });
  }

  /** Extracts the list of viable 'dimension' properties for use in kanban / matrix from the current type set  */
  getDimensionValuesFromTypes(typeMap: any) {
    const typeRootSlugs = Object.keys(typeMap); // LIST OF TYPE SLUGS
    const propertyMap = {}; // MAP OF AVAILABLE PROPERTIES

    // Creates a map of all available properties, mapped by type root slug and property slug
    const dimensionPropertyTypes: Array<ViewDimensionOption> = [];
    const type: Type = typeMap['plan-placeholder'];
    type.typeProperties
      .filter(
        (prop: TypeProperty) =>
          [PropertyType.SingleSelect, PropertyType.String, PropertyType.Date, PropertyType.ObjectReference].includes(
            prop.propertyType,
          ) && !EXCLUDED_PROPERTIES.includes(prop.slug),
      )
      .forEach((prop) => {
        if (this.currentView?.dimensions?.length) {
          if (`plan-placeholder:${prop.slug}` === this.currentView.dimensions[0]) {
            this.dimension1 = {
              value: 'plan-placeholder:' + prop.slug,
              label: prop.label,
              propertyType: prop.propertyType,
            };
            dimensionPropertyTypes.push(this.dimension1);
          } else if (
            this.currentView.dimensions.length > 1 &&
            `plan-placeholder:${prop.slug}` === this.currentView.dimensions[1]
          ) {
            this.dimension2 = {
              value: 'plan-placeholder:' + prop.slug,
              label: prop.label,
              propertyType: prop.propertyType,
            };
            dimensionPropertyTypes.push(this.dimension2);
          }
        }
        if (
          dimensionPropertyTypes.findIndex((propertyType) => propertyType.value === 'plan-placeholder:' + prop.slug) ===
          -1
        ) {
          dimensionPropertyTypes.push({
            value: 'plan-placeholder:' + prop.slug,
            label: prop.label,
            propertyType: prop.propertyType,
          });
        }
      });
    this.allDimensionOptions = dimensionPropertyTypes.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    this.dimension1Options = this.allDimensionOptions;
    this.dimension2Options = this.allDimensionOptions;

    this.dimension1FormControl?.setValue(this.dimension1 || null, { onlySelf: true });
    this.dimension2FormControl?.setValue(this.dimension2 || null, { onlySelf: true });
  }

  selectViewType(viewType: ViewType) {
    this.selectedViewType = viewType;
  }

  handleCancel() {
    console.log('ViewFormComponent: handleCancel ');
    this.cancel.emit();
  }

  save() {
    if (!this.isValid()) {
      return;
    }
    this.isAlreadySubmitted = true;

    const viewData: any = {
      label: this.nameFormControl.value,
      properties: this.currentView?.properties || [],
      filterCriteria: this.currentView?.filterCriteria || null,
      sorts: this.currentView?.sorts || null,
      viewType: this.selectedViewType.slug,
      applicationViewSlug: this.currentView?.applicationViewSlug || 'plan:plan_editor',
    };
    if (['matrix', 'kanban'].includes(this.selectedViewType.slug)) {
      viewData.dimensions = this.getViewDimensionsFromForm();
    }
    viewData.sortOrder = this.newViewSortOrder;

    if (this.formMode === 'CREATE') {
      this.create(viewData);
    } else if (this.formMode === 'UPDATE') {
      this.update(viewData);
    } else if (this.formMode === 'COPY') {
      this.copy(viewData);
    }
    this.done.emit();
  }

  update(viewData) {
    // remove unnecessary props
    delete viewData['viewType'];
    delete viewData['applicationViewSlug'];
    delete viewData['id'];
    const params = { id: this.currentView.id, changes: viewData };
    this.store.dispatch(CollectionManagerActions.updateViewDefinition(params));
  }

  create(viewData) {
    const isPrivate = this.privateFormControl.value;
    if (isPrivate) {
      viewData.private = isPrivate;
    }
    const isAdmin = this.adminFormControl.value;
    if (isAdmin) {
      viewData.admin = isAdmin;
    }
    this.store.dispatch(CollectionManagerActions.createViewDefinitions({ viewDefinitions: [viewData] }));
  }

  copy(sourceViewData) {
    const viewData = ObjectUtil.cloneDeep(sourceViewData);
    delete viewData.id;
    const isPrivate = this.privateFormControl.value;
    if (isPrivate) {
      viewData.private = isPrivate;
    }
    const isAdmin = this.adminFormControl.value;
    if (isAdmin && this.isOrgAdmin) {
      viewData.admin = isAdmin;
    }
    this.store.dispatch(CollectionManagerActions.createViewDefinitions({ viewDefinitions: [viewData] }));
  }

  getViewDimensionsFromForm() {
    const dimensions = [];
    if (this.dimension1FormControl.value) {
      dimensions.push(this.dimension1FormControl.value.value);
    }
    if (this.selectedViewType.slug === 'matrix' && this.dimension1FormControl.value) {
      dimensions.push(this.dimension2FormControl.value.value);
    }
    return dimensions;
  }

  togglePrivate($event) {}

  isValid() {
    if (!this.nameFormControl.valid) {
      return false;
    }
    if (['matrix', 'kanban'].includes(this.selectedViewType.slug)) {
      if (!this.dimension1FormControl.value) {
        return false;
      }
      if (['matrix'].includes(this.selectedViewType.slug)) {
        if (!this.dimension2FormControl.value) {
          return false;
        }
        if (this.dimension1FormControl.value?.value === this.dimension2FormControl.value?.value) {
          return false;
        }
      }
    }
    return true;
  }

  /** Creates new views from templates.
   */
  createFromTemplates(templates: Array<ViewDefinition>) {
    const copies = ObjectUtil.cloneDeep(templates);
    copies.forEach((newView) => {
      delete newView.id;
      delete newView.createdOn;
      delete newView.updatedOn;
      delete newView.createdById;
      delete newView.updatedById;
      delete newView.orgId;
      newView.sortOrder = this.newViewSortOrder++;
    });

    this.store.dispatch(CollectionManagerActions.createViewDefinitions({ viewDefinitions: copies }));
    this.done.emit();
  }

  getDimensionOptionIcon(propertyType: string) {
    switch (propertyType) {
      case 'string':
        return 'title';
      case 'date':
        return 'today';
      default:
        return 'arrow_drop_down_circle';
    }
  }
}
