<div [ngClass]="filterActive ? 'filter active' : 'filter'">
  <app-menu
    #filterMenu
    data-test="filter-menu-button"
    [disabled]="disabled"
    [toolTip]="toolTip"
    [name]="label"
    icon="filter_list"
    [highlight]="filterActive"
  >
    <app-filter-panel
      data-test="filter-panel"
      [filterDefinition]="selectedFilterDefinition$ | async"
      [hidePropertyTypes]="[]"
      [sourceValues]="allPlaceholders$ | async"
      [propertyTypeDefaultFilterConditions]="propertyTypeDefaultFilterConditions"
      (filterCriteriaChange)="setFilterCriteria($event)"
    ></app-filter-panel>
  </app-menu>
  <mat-icon
    *ngIf="!disabled && filterActive"
    data-test="filter-remove-button"
    (click)="removeFilters($event)"
    class="remove-icon"
    color="primary"
    svgIcon="remove-icon"
  ></mat-icon>
</div>
