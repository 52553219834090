<div class="labels">
  <div class="value chart">Product Mix (Collection)</div>
  <div class="value">Placeholder Count</div>
  <div class="value">Products</div>
  <div class="value">Average Retail Price</div>
  <div class="value">Total Volume</div>
  <div class="value">Total Revenue</div>
  <div class="value">Total Margin</div>
  <div class="action"></div>
</div>
<app-plan-column *ngFor="let plan of plans" [plan]="plan" [compareToPlan]="plans[0]"> </app-plan-column>
