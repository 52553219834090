import { ObjectUtil } from '@contrail/util';
import { createSelector } from '@ngrx/store';
import { selectCurrentView } from './collection-views/collection-views.selectors';
import { RootStoreState } from 'src/app/root-store';

export const frozenColumns = (state: RootStoreState.State) => state.collectionManager.frozenColumns;
export const typeDefinitions = (state: RootStoreState.State) => state.collectionManager.typeDefinitions;
export const gridTotalWidth = (state: RootStoreState.State) => state.collectionManager.gridTotalWidth;
export const scrollHorizontalPercentage = (state: RootStoreState.State) =>
  state.collectionManager.scrollHorizontalPercentage;
export const gridViewColumnWidth = (state: RootStoreState.State) => state.collectionManager.gridViewColumnWidth;
export const sidePanelWidth = (state: RootStoreState.State) => state.collectionManager.sidePanelWidth;

export const currentViewProperties = createSelector(selectCurrentView, typeDefinitions, (view: any, typeMap) => {
  if (!view) return [];

  // MARK ANY PROPERTY THAT HAS ITEM SCOPE, USED TO CONTROL EDITS
  const itemProps = typeMap ? typeMap['item'].typeProperties : [];
  const projectItemProps = typeMap ? typeMap['project-item'].typeProperties : [];
  const properties = [];
  view.properties.forEach((prop) => {
    const newProp = ObjectUtil.cloneDeep(prop);
    let mappedProperty = itemProps.find((prop) => prop.slug === newProp.slug);
    if (mappedProperty) {
      newProp.scope = 'item';
      newProp.propertyLevel = mappedProperty.propertyLevel;
    } else {
      mappedProperty = projectItemProps.find((prop) => prop.slug === newProp.slug);
      if (mappedProperty) {
        newProp.scope = 'project-item';
        newProp.propertyLevel = mappedProperty.propertyLevel;
      }
    }
    if (prop.propertyDefinition) {
      // Deleted property should not be included
      properties.push(newProp);
    }
  });
  return properties;
});

export const leftProperties = createSelector(currentViewProperties, (properties: any) => {
  return properties.filter((prop) => prop.enabled && prop.frozen);
});

export const rightProperties = createSelector(currentViewProperties, (properties: any) => {
  return properties.filter((prop) => prop.enabled && !prop.frozen);
});

export const rightPropertiesTotalColumnWidth = createSelector(rightProperties, (properties) => {
  let width = 0;
  properties.forEach((prop) => {
    width += prop.width || 150;
  });
  return width;
});

export const leftPropertiesTotalColumnWidth = createSelector(leftProperties, (properties: any) => {
  let leftWidth = 0;
  properties.forEach((prop) => {
    leftWidth += prop.width || 150;
  });
  const checkBoxCellWidth = 50;
  const validationBoxCellWidth = 30;
  return leftWidth + checkBoxCellWidth + validationBoxCellWidth;
});

export const totalColumnWidth = createSelector<RootStoreState.State, [number, number], number>(
  leftPropertiesTotalColumnWidth,
  rightPropertiesTotalColumnWidth,
  (leftPropertiesTotalColumnWidth: number, rightPropertiesTotalColumnWidth: number) => {
    return leftPropertiesTotalColumnWidth + rightPropertiesTotalColumnWidth;
  },
);

export const rightPropertiesViewPortWidth = createSelector<RootStoreState.State, [number, number, number], number>(
  gridTotalWidth,
  leftPropertiesTotalColumnWidth,
  sidePanelWidth,
  (gridWindowTotalWidth: number, leftSideColumnWidth: number, panelWidth: number) => {
    // console.log(gridWindowTotalWidth, leftSideColumnWidth);
    return gridWindowTotalWidth - leftSideColumnWidth - panelWidth;
  },
);

export const totalGridColumnCount = createSelector<RootStoreState.State, [Array<any>, Array<any>], number>(
  rightProperties,
  leftProperties,
  (rightProperties: Array<any>, leftProperties: Array<any>) => {
    return rightProperties.length + leftProperties.length;
  },
);

// helps deciding if the scroller is needed
export const scrollableWidth = createSelector(
  totalColumnWidth,
  rightPropertiesViewPortWidth,
  (totalWidth: number, viewPort: number) => {
    if (totalWidth > 0 && viewPort > 0) {
      return totalWidth - viewPort;
    }
    return 0;
  },
);
// concept similar to Verical scroll
export const scrollColumnIndexStart = createSelector(
  scrollHorizontalPercentage,
  gridViewColumnWidth,
  rightPropertiesTotalColumnWidth,
  rightPropertiesViewPortWidth,
  totalColumnWidth,
  (scrollHorizontalPercentage, gridViewColWidth, rightPropertiesWidth, colViewPortWidth, totalColumnWidth) => {
    if (
      !scrollHorizontalPercentage ||
      !colViewPortWidth ||
      !gridViewColWidth ||
      !totalColumnWidth ||
      rightPropertiesWidth <= colViewPortWidth
    ) {
      return 0;
    }
    const hiddenHorizontalWidth = rightPropertiesWidth - colViewPortWidth;
    const hiddenColumnCount = hiddenHorizontalWidth / gridViewColWidth;
    let index = 0;
    index = hiddenColumnCount * scrollHorizontalPercentage;
    index = Math.round(index) + 2;
    return index;
  },
);

export const scrollColumnMarginOffset = createSelector(
  scrollColumnIndexStart,
  gridViewColumnWidth,
  (columnIndex, columnWidth) => {
    let offSet = 0;
    if (columnIndex > 0) {
      offSet = columnIndex * columnWidth;
    }
    return offSet;
  },
);
