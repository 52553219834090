import * as CollectionManagerActions from './collection-manager.actions';
import * as CollectionManagerSelectors from './collection-manager.selectors';
import * as CollectionManagerStoreState from './collection-manager.state';
import * as CollectionElementActions from './collection-elements/collection-elements.actions';
import * as CollectionElementSelectors from './collection-elements/collection-element.selectors';
import * as CollectionViewsActions from './collection-views/collection-views.actions';
import * as CollectionViewsSelectors from './collection-views/collection-views.selectors';
import * as CollectionStatusMessagesActions from './collection-status-messages/collection-status-messages.actions';
import * as CollectionStatusMessagesSelectors from './collection-status-messages/collection-status-messages.selectors';

export { CollectionManagerStoreModule } from './collection-manager.module';

const actions = {
  ...CollectionManagerActions,
  ...CollectionElementActions,
  ...CollectionViewsActions,
  ...CollectionStatusMessagesActions,
};
const selectors = {
  ...CollectionManagerSelectors,
  ...CollectionElementSelectors,
  ...CollectionViewsSelectors,
  ...CollectionStatusMessagesSelectors,
};
export {
  actions as CollectionManagerActions,
  selectors as CollectionManagerSelectors,
  CollectionManagerStoreState as CollectionManagerStoreState,
};
