<div class="header">
  <div class="title">View Options</div>
  <div (click)="close.emit()" class="cursor-pointer flex-center">
    <mat-icon>close</mat-icon>
  </div>
</div>

<div class="accordion">
  <mat-expansion-panel class="mat-elevation-z0" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title class="text-sm font-normal"> Details </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <div class="flex items-center pt-[10px] justify-between" *ngIf="!editName">
        <div>{{ viewDefinition?.label }}</div>
        <button data-test="edit-view-name-icon" [matTooltip]="'Edit Name'" class="btn-32 !mr-2" mat-button>
          <mat-icon (click)="startEditName()">edit</mat-icon>
        </button>
      </div>
      <mat-form-field *ngIf="editName">
        <input matInput #inputField [formControl]="nameInput" (blur)="saveName()" />
      </mat-form-field>
    </div>
  </mat-expansion-panel>
  <mat-divider></mat-divider>
  <mat-expansion-panel class="mat-elevation-z0" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title class="text-sm font-normal"> Row Grouping </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <app-pivot-view-property-selector
        *ngIf="rowGroupingProperties?.length > 0"
        [properties]="rowGroupingProperties"
        [currentProperties]="currentRowGroupingProperties"
        (propertiesChangedEvent)="updateProperties($event, true)"
        (propertiesSortEvent)="updateSortProperties($event)"
        [sortable]="true"
        [currentSorts]="viewDefinition.sorts"
      >
      </app-pivot-view-property-selector>
    </div>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <mat-expansion-panel class="mat-elevation-z0" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title class="text-sm font-normal"> Values </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <app-pivot-view-property-selector
        *ngIf="columnProperties?.length > 0"
        [properties]="columnProperties"
        [currentProperties]="currentColumnProperties"
        (propertiesChangedEvent)="updateProperties($event, false)"
        (propertiesAggregateFunctionEvent)="updatePropertyAggregateFunction($event)"
      >
      </app-pivot-view-property-selector>
    </div>
  </mat-expansion-panel>
</div>
