import { Component, OnInit, Input, Inject } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Plan } from '../../plans-store/plans.state';

@Component({
  selector: 'app-compare-plan-modal',
  templateUrl: './compare-plan-modal.component.html',
  styleUrls: ['./compare-plan-modal.component.scss'],
})
export class ComparePlanModalComponent implements OnInit {
  public plans: Array<Plan>;
  constructor(
    public dialogRef: MatDialogRef<ComparePlanModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.plans = data.plans;
  }

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }
}
