import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { CollectionManagerActions } from 'src/app/collection-manager/collection-manager-store';
import { CollectionDataEntity } from 'src/app/collection-manager/collection-manager.service';
import { PlanEntityReferenceHelper } from 'src/app/collection-manager/entity-references/entity-reference-helper';
import { DataCellEditBaseComponent } from '../data-cell-edit-base-component';
import { AccessManagedType, Types } from '@contrail/sdk';

@Component({
  selector: 'app-data-cell-edit-object-reference',
  templateUrl: './data-cell-edit-object-reference.component.html',
  styleUrls: ['./data-cell-edit-object-reference.component.scss'],
})
export class DataCellEditObjectReferenceComponent
  extends DataCellEditBaseComponent
  implements OnChanges, AfterViewInit
{
  public panelTopPosition = 0;
  public hideChooser = true;
  public createMode = false;
  public canCreate = false;
  public isInfiniteScrollEnabled = false;
  @ViewChild('chooser', { static: false }) chooser: ElementRef;

  constructor(
    private objectReferenceHelper: PlanEntityReferenceHelper,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<RootStoreState.State>,
    private elementRef: ElementRef,
  ) {
    super();
  }

  async ngAfterViewInit() {
    if (this.property?.propertyDefinition?.referencedTypeRootSlug) {
      const objRootTypesWithSupportForPagination = ['custom-entity', 'color', 'size-range-template'];
      this.isInfiniteScrollEnabled = objRootTypesWithSupportForPagination.includes(
        this.property.propertyDefinition.referencedTypeRootSlug,
      );
    }
  }

  async ngOnChanges() {
    this.setPosition();
    if (this.property?.propertyDefinition?.referencedTypePath) {
      const type: AccessManagedType = await new Types().getType({
        path: this.property.propertyDefinition.referencedTypePath,
      });
      this.canCreate = type.canCreate;
    }
  }

  async select(entity) {
    this.value = entity.id;
    this.handleChange();
  }

  setPosition() {
    this.panelTopPosition = this.elementRef.nativeElement.getBoundingClientRect().top;
    const pageY = this.elementRef.nativeElement.getBoundingClientRect().y;
    const chooserContainerHeight = 450;
    const singleRowHeight = 30;
    const maxDim =
      window.innerHeight * 0.4 > chooserContainerHeight ? chooserContainerHeight : window.innerHeight * 0.4;
    const distanceFromBottom = (100 * (window.innerHeight - maxDim - pageY)) / window.innerHeight;

    let newPanelPosition = pageY - chooserContainerHeight - singleRowHeight;

    if (distanceFromBottom < 12) {
      // pushes the chooser above the selected cell which cuts a part of the chooser so pushing it down by 2 rows
      if (distanceFromBottom > 5) {
        newPanelPosition += 2 * singleRowHeight;
      }
      this.panelTopPosition = newPanelPosition;
    }
  }
  clearValue() {
    this.value = null;
    const changes: CollectionDataEntity = {};
    changes[this.property.slug] = null;
    changes[this.property.slug + 'Id'] = null;
    this.handleChange();
  }

  handleClick() {
    const entityRoot = this.property.propertyDefinition.referencedTypeRootSlug || 'item';
    this.objectReferenceHelper.viewDetails(
      this.data,
      entityRoot,
      this.property.propertyDefinition,
      this.value,
      this.accessLevel,
    );
  }

  handleClose() {
    this.hideChooser = true;
  }

  handleEntitySelected(entity) {
    this.value = entity;
    this.hideChooser = true;

    const changes: CollectionDataEntity = {};
    changes[this.property.slug] = entity;
    changes[this.property.slug + 'Id'] = entity.id;
    this.handleChange();
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleAddItemClick(event: KeyboardEvent = null) {
    if (!this.value?.id) {
      this.hideChooser = false;
      this.changeDetectorRef.detectChanges();
      const currentChooserRightPosition = this.chooser.nativeElement.getBoundingClientRect().right;
      if (currentChooserRightPosition > window.innerWidth) {
        this.chooser.nativeElement.style.setProperty('right', '20px');
      }
    }
  }

  new() {
    this.createMode = true;
    this.changeDetectorRef.markForCheck();
  }

  handleCreateComplete(entity) {
    console.log('entity created: ', entity);
    this.handleEntitySelected(entity);
  }
}
