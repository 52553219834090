import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PivotGridService {
  private showGoalsSubject = new BehaviorSubject(false);
  public showGoals$: Observable<boolean> = this.showGoalsSubject.asObservable();

  public setShowGoals(showGoals: boolean) {
    this.showGoalsSubject.next(showGoals);
  }
}
