import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { PropertyValueFormatter } from '@contrail/types';
import { CollectionDataEntity } from 'src/app/collection-manager/collection-manager.service';

@Component({ template: '' })
export class DataCellEditBaseComponent {
  @Input() property: ViewPropertyConfiguration;
  @Input() value: any;
  @Input() display: any;
  @Input() data: CollectionDataEntity;
  @Input() accessLevel = 'EDIT';

  @Output() valueChange = new EventEmitter<{ value }>();
  @Output() endEditChange = new EventEmitter();
  @Output() focusOnCell = new EventEmitter<any>();

  formatter = new PropertyValueFormatter();
  precision: number;

  constructor() {}
  handleChange() {
    console.log('1) DataCellEditBaseComponent: handleChange: ', this.value);
    this.valueChange.emit({ value: this.value });
  }

  handleFocusOnCell(htmlElement) {
    this.focusOnCell.emit(htmlElement);
  }

  public completeEdit() {
    this.handleChange();
  }

  public endEdit() {
    this.endEditChange.emit();
  }
}
