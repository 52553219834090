<div
  #dataRowElement
  [attr.data-test]="'row-' + side + '-' + rowId"
  class="grid-row"
  [style.top.px]="rowNumber * rowHeight"
  [style.height.px]="rowHeight"
  class="{{ hoveredRowId === rowId ? 'highlighted' : '' }}"
>
  <ng-container *ngIf="side !== 'left'">
    <div
      app-pivot-data-cell
      [attr.data-test]="'cell-' + property.slug + '-' + rowId"
      [style.width.px]="property.width || 150"
      class="row-cell"
      [property]="property"
      [rowGoals]="rowGoals"
      [showGoals]="showGoals"
      [data]="dataRow"
      [side]="side"
      *ngFor="let property of properties; trackBy: trackByFn; last as isLast; let i = index"
      (rowAction)="onRowAction($event)"
    ></div>
  </ng-container>

  <ng-container *ngIf="side == 'left'">
    <div
      app-pivot-data-cell
      (contextmenu)="handleContextMenu($event)"
      [attr.data-test]="'cell-row-grouping' + '-' + rowId"
      [style.width.px]="totalWidth"
      class="row-cell"
      [rowGoals]="rowGoals"
      [showGoals]="showGoals"
      [properties]="properties"
      [data]="dataRow"
      [side]="side"
      (rowAction)="onRowAction($event)"
    ></div>
  </ng-container>
</div>
