import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions } from '../collection-manager-store';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';

export interface SidePanelView {
  label: string;
  slug: string;
  icon: string;
  isSVGIcon?: boolean;
  targetSourceType?: ChooserSourceOptionTypes;
  title?: string;
  hideSource?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class SidePanelService {
  private currentViewSubject: Subject<SidePanelView> = new BehaviorSubject(null);
  public currentView: Observable<SidePanelView> = this.currentViewSubject.asObservable();
  public sidePanelWidth = 339;
  constructor(private store: Store<RootStoreState.State>) {}

  public setCurrentView(view: SidePanelView) {
    const panelWidth = view ? this.sidePanelWidth : 1;
    this.store.dispatch(CollectionManagerActions.setSidePanelWidth({ sidePanelWidth: panelWidth }));
    this.currentViewSubject.next(view);
    // remove any highlighted row for status message
    this.store.dispatch(CollectionManagerActions.setStatusMessageElement(null));
  }
}
