import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './common/auth/auth.guard';
import { LoginComponent } from './common/auth/login/login.component';
import { MainComponent } from './main/main.component';
import { NoOrgsRegisteredComponent } from './common/auth/no-orgs-registered/no-orgs-registered.component';
import { SelectOrgComponent } from './main/select-org/select-org.component';
import { CollectionManagerComponent } from './collection-manager/collection-manager.component';
import { PlansHomeComponent } from './plans/plans-home/plans-home.component';
import { UserOrgResolver } from './common/auth/user-org.resolver';
import { DefaultOrgResolver } from './common/auth/default-org.resolver';
import { CreateItemComponent } from './common/items/create-item/create-item.component';
import { PlanEditorModeResolver } from './plans/plan-editor-mode-resolver';
import { ItemTestComponent } from './common/items/item-test/item-test.component';
import { DevelopmentComponent } from './development/development.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    resolve: { defaultOrg: DefaultOrgResolver },
    children: [
      { path: '', component: SelectOrgComponent },
      {
        path: 'org/:org',
        resolve: { user: UserOrgResolver },
        children: [
          { path: '', redirectTo: 'home', pathMatch: 'full' },
          { path: 'home', component: PlansHomeComponent },
          {
            path: 'plan/:planId',
            resolve: { editorMode: PlanEditorModeResolver },
            component: CollectionManagerComponent,
          },
          { path: 'item', component: CreateItemComponent },
          { path: 'item/:itemId', component: ItemTestComponent },
          { path: 'development', component: DevelopmentComponent },
        ],
      },
    ],
  },

  { path: 'login', component: LoginComponent },
  { path: 'no-org-found', component: NoOrgsRegisteredComponent },
  { path: 'home', redirectTo: 'showcases', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
