import { Injectable } from '@angular/core';
import { ViewDefinition } from '@contrail/client-views';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { HeaderColumnReorderService } from './header-column-reorder.service';
@Injectable({
  providedIn: 'root',
})
export class HeaderColumnFreezeService {
  private currentView: ViewDefinition;

  constructor(
    private store: Store<RootStoreState.State>,
    private headerReorderService: HeaderColumnReorderService,
  ) {
    this.store.select(CollectionManagerSelectors.selectCurrentView).subscribe((view) => {
      this.currentView = ObjectUtil.cloneDeep(view);
    });
  }

  public freezeColumn(slug) {
    const currentIndex = this.currentView.properties.findIndex((prop) => prop.propertyDefinition.slug === slug);
    const lastFrozenColumnIndex = this.currentView.properties.filter((prop) => prop.frozen).length;
    this.currentView.properties[currentIndex].frozen = true;
    // When freezing a column, insert it next to the last frozen column
    this.headerReorderService.arrayMove(this.currentView.properties, currentIndex, lastFrozenColumnIndex);
    this.store.dispatch(
      CollectionManagerActions.updateViewDefinition({
        id: this.currentView.id,
        changes: { properties: this.currentView.properties },
      }),
    );
  }

  public unfreezeColumn(slug) {
    const currentIndex = this.currentView.properties.findIndex((prop) => prop.propertyDefinition.slug === slug);
    const firstRegularColumnIndex = this.currentView.properties.findIndex((prop) => !prop.frozen) - 1;
    this.currentView.properties[currentIndex].frozen = false;
    // When unfreezing a column, insert it before to the first unfrozen column
    this.headerReorderService.arrayMove(this.currentView.properties, currentIndex, firstRegularColumnIndex);
    this.store.dispatch(
      CollectionManagerActions.updateViewDefinition({
        id: this.currentView.id,
        changes: { properties: this.currentView.properties },
      }),
    );
  }
}
