import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { FormulaProcessor, Type } from '@contrail/types';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { take, tap } from 'rxjs/operators';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ProjectGoalSet {
  id?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectGoalsService {
  private currentProjectId;
  private projectGoalSetSubject = new BehaviorSubject(null);
  public projectGoalSets$: Observable<Array<ProjectGoalSet>> = this.projectGoalSetSubject.asObservable();

  constructor(private store: Store<RootStoreState.State>) {
    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(
        tap((ws) => {
          if (!ws) {
            return;
          }
          this.currentProjectId = ws.projectId;
          this.loadProjectGoals();
        }),
      )
      .subscribe();
  }
  private async loadProjectGoals() {
    const goalSets = await new Entities().get({
      entityName: 'project-goal-set',
      criteria: { projectId: this.currentProjectId },
    });
    this.projectGoalSetSubject.next(goalSets);
  }
}
