<div class="main">
  <div class="left">
    <div class="header">Plan 1</div>
    <div class="body"></div>
  </div>
  <div class="right">
    <div class="header">Assortment 1</div>
    <div class="body"></div>
  </div>
</div>
