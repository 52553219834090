import { Component, OnInit } from '@angular/core';
import { PlanEntityReferenceHelper } from 'src/app/collection-manager/entity-references/entity-reference-helper';
import { DataCellViewBaseComponent } from '../data-cell-view-base-component';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { CollectionManagerSelectors } from 'src/app/collection-manager/collection-manager-store';

@Component({
  selector: 'app-data-cell-view-object-reference',
  templateUrl: './data-cell-view-object-reference.component.html',
  styleUrls: ['./data-cell-view-object-reference.component.scss'],
})
export class DataCellViewObjectReferenceComponent extends DataCellViewBaseComponent implements OnInit {
  public objectProperties: any;
  public isClickable: boolean;
  constructor(
    private store: Store<RootStoreState.State>,
    private objectReferenceHelper: PlanEntityReferenceHelper,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isClickable = this.accessLevel === 'EDIT';
    const isObjectAnItem = ['itemFamily', 'itemOption'].includes(this.property.slug);
    if (isObjectAnItem) {
      this.store.select(CollectionManagerSelectors.typeDefinitions).subscribe((typeMap) => {
        this.objectProperties = typeMap?.item?.typeProperties;
      });
    }
  }

  handleClick() {
    if (this.selected) {
      const entityRoot = this.property.propertyDefinition.referencedTypeRootSlug || 'item';
      this.objectReferenceHelper.viewDetails(
        this.data,
        entityRoot,
        this.property.propertyDefinition,
        this.value,
        this.accessLevel,
      );
    }
  }
}
