import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TypeProperty } from '@contrail/types';
import { TypePropertyFormFieldComponent } from 'src/app/common/types/forms/type-property-form-field/type-property-form-field.component';
import { TypePropertyFormConfiguration } from 'src/app/common/types/forms/type-property-form/type-property-form.component';

@Component({
  selector: 'app-comment-form-suggestion',
  template: `
    <app-type-property-form-field
      #formField
      (valueChange)="handleChange($event)"
      [propertyFormConfiguration]="propertyFormConfiguration"
      [value]="value"
      hint="Suggested Value"
    ></app-type-property-form-field>
  `,
  styles: [``],
})
export class CommentFormSuggestionComponent implements OnChanges {
  @Input() property: TypeProperty;
  @Input() value;
  @Output() valueChange = new EventEmitter<{ value }>();
  @ViewChild('formField') formElement: TypePropertyFormFieldComponent;
  public propertyFormConfiguration: TypePropertyFormConfiguration;
  public startingValue;
  ngOnChanges(changes: SimpleChanges) {
    if (changes.property) {
      this.formElement?.clearValue();
    }
    this.propertyFormConfiguration = { typeProperty: this.property };
  }

  handleChange(value) {
    console.log('CommentFormSuggestionComponent: handleChange: ', value);
    this.valueChange.emit(value);
  }
}
