import { Store } from '@ngrx/store';
import { PlansActions, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions } from '../../collection-manager-store';
import { PlaceholderItemUpdateService } from '../../placeholders/placeholder-item-update-service';
import { UndoRedoDetails } from '../undo-redo-objects';
import { BasicUndoRedoHandler } from './basic-undo-redo.handler';

export class UpdateRowOrderUndoRedoHandler implements BasicUndoRedoHandler {
  constructor(
    public undoRedoDetails: UndoRedoDetails,
    public store: Store<RootStoreState.State>,
  ) {}

  undoActions(): void {
    const rowIds = this.undoRedoDetails.changesList[0].undoChanges;
    rowIds.sort((a, b) => (a.index > b.index ? 1 : -1)); //make sure the row order is sorted by its index
    this.store.dispatch(PlansActions.updatePlanRowOrder({ rowIds }));
  }

  redoActions(): void {
    const redoDetails = this.undoRedoDetails.changesList[0].changes;
    redoDetails.skipRecordingUndoRedo = true; // do not record this as undoredo because this action is already in the undoredo stack
    this.store.dispatch(PlansActions.updatePlanRowOrder(redoDetails));
  }
}
