const DROPPED_FILTER = {
  'converse-pilot-1': {
    property: 'colorwayStatus',
    value: ['r1dropped', 'r2dropped', 'r3dropped', 'r4dropped'],
  },
  'btp-zone-1': {
    property: 'colorwayStatus',
    value: ['r1dropped', 'r2dropped', 'r3dropped', 'r4dropped'],
  },
};

export class FilterUtil {
  static filterDroppedItems(data, orgSlug) {
    return data?.filter((item) => !this.isDropped(item, orgSlug));
  }

  static isDropped(item, orgSlug = '') {
    const filterDef = DROPPED_FILTER[orgSlug] || {};

    if (!filterDef?.property || !filterDef?.value) {
      return false;
    }
    const status = item?.properties[filterDef?.property] || '';
    return filterDef?.value.includes(status.toLowerCase());
  }
}

export const SortByOptions = [
  { label: 'Name', property: 'name' },
  { label: 'Date Created', property: 'createdOn' },
  { label: 'Date Modified', property: 'updatedOn' },
  { label: 'Creator', property: 'createdById' },
];
