import { TypeProperty } from '@contrail/types';

export class ValidatorFunctionProcessor {
  static async processValidatorFunction(functionString = '', object, context = {}) {
    let value;
    try {
      const validatorFunction = this.getFunction(functionString);
      value = await validatorFunction(object, context);
    } catch (e) {
      console.error(e);
      console.error('ValidatorFunctionProcessor: error processing function: ', functionString);
    }
    return value;
  }

  private static getFunction(functionString) {
    try {
      const AsyncFunction = Object.getPrototypeOf(async () => {}).constructor;
      const executionFunction = new AsyncFunction('obj', 'context', functionString);
      return executionFunction;
    } catch (e) {
      console.error(e);
      throw new Error('FormulaFunctionProcessor: Failed to parse the execution string.');
    }
  }
}
