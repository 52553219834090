import { Injectable } from '@angular/core';
import { GridViewManager } from '../grid-view.manager';
import { RootStoreState } from 'src/app/root-store';
import { Store } from '@ngrx/store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { SelectorCell } from '../selectors/grid-selector.service';
import { GridSelectorActionHandler } from '../selectors/grid-selector-action-handler';
import { FocusedItemFamilyService } from '../../focus-item/focused-item-family.service';

const WEIGHT_PROP_SUFFIX = 'Weight';
@Injectable({
  providedIn: 'root',
})
export class SpreadValueHandler {
  data: any;
  selectorCells: Array<SelectorCell>;
  selectedRows: Array<any>;
  viewProperties: Array<any>;
  currentViewProperties: Array<any>;
  cutCells: Array<SelectorCell>;
  focusedFamilyItem: any;

  constructor(
    private store: Store<RootStoreState.State>,
    private gridViewManager: GridViewManager,
    private gridSelectorActionHandler: GridSelectorActionHandler,
    private focusedItemFamilyService: FocusedItemFamilyService,
  ) {
    this.store
      .select(CollectionManagerSelectors.selectorCells)
      .subscribe((selectorCells) => (this.selectorCells = selectorCells));
    this.store
      .select(CollectionManagerSelectors.currentViewProperties)
      .subscribe((props) => (this.viewProperties = props));
    this.store
      .select(CollectionManagerSelectors.focusedFamilyItem)
      .subscribe((focusedFamilyItem) => (this.focusedFamilyItem = focusedFamilyItem));
  }

  handleSpreadValue(openPanel = false) {
    const selectedCell = this.gridViewManager.selectedCell;
    if (selectedCell) {
      const selectedCellLocation = selectedCell.getBoundingRectangle();
      let position = { x: 300, y: 300 };
      if (selectedCellLocation) {
        position = {
          x: selectedCellLocation.x + selectedCellLocation.width,
          y: selectedCellLocation.y,
        };
        if (position.x + 300 > window.innerWidth) {
          position.x = selectedCellLocation.x - 300;
        }
        if (position.y + 300 > window.innerHeight) {
          position.y = selectedCellLocation.y - 300;
        }
      }
      if (this.focusedFamilyItem) {
        if (openPanel) {
          // show panel in focus mode when openPanel is true
          this.store.dispatch(CollectionManagerActions.setSpreadValueInfo({ spreadValueInfo: position }));
        } else {
          const weightPropertySlug = this.getWeightProperty()?.slug;
          let weightTotal = 0;
          if (weightPropertySlug) {
            weightTotal = this.getWeightTotal(weightPropertySlug);
          }
          this.calculateSpread(this.getSpreadTotal(), !weightPropertySlug, weightPropertySlug, weightTotal); // if weighted, calculate by weight
        }
      } else {
        this.store.dispatch(CollectionManagerActions.setSpreadValueInfo({ spreadValueInfo: position }));
      }
    }
  }

  calculateSpread(spreadTotal, even = true, weightPropertySlug, weightTotal) {
    let rowIds;
    if (this.selectorCells.length > 0) {
      rowIds = this.selectorCells.map((selectorCell) => selectorCell.rowId);
    } else {
      rowIds = this.focusedItemFamilyService.getFocusedItemFamilyPlaceholderIds();
    }
    let spreadValues;
    if (!even && weightTotal) {
      // spread by weight
      spreadValues = this.setSpreadByWeight(spreadTotal, weightTotal, weightPropertySlug);
    } else {
      // spread evenly
      const spreadValue = Math.round(spreadTotal / rowIds.length);
      spreadValues = rowIds.map((rowId) => spreadValue + '');
    }
    this.gridSelectorActionHandler.handleSpreadValues(spreadValues, this.getTargetPropertySlug(), rowIds);
  }

  getSpreadTotal() {
    return this.calculateTotal(this.getTargetPropertySlug());
  }

  getWeightTotal(weightProperty) {
    return this.calculateTotal(weightProperty);
  }

  private setSpreadByWeight(spreadTotal, weightTotal, weightProperty) {
    const spreadValues = [];

    let rowIds;
    if (this.selectorCells.length > 0) {
      rowIds = this.selectorCells.map((selectorCell) => selectorCell.rowId);
    } else {
      rowIds = this.focusedItemFamilyService.getFocusedItemFamilyPlaceholderIds();
    }
    rowIds.forEach((rowId) => {
      const weight = this.gridViewManager.getCell(rowId, weightProperty).getValue() || 0;
      const calculatedValue = Math.round((spreadTotal * weight) / weightTotal) + '';
      spreadValues.push(calculatedValue);
    });
    return spreadValues;
  }

  getWeightProperty() {
    if (this.gridViewManager.selectedCell) {
      let weightPropertySlug;
      const propertySlug = this.gridViewManager.selectedCell.propertySlug;
      if (propertySlug.endsWith(WEIGHT_PROP_SUFFIX)) {
        weightPropertySlug = propertySlug;
      } else {
        weightPropertySlug = propertySlug + WEIGHT_PROP_SUFFIX;
      }
      const index = this.viewProperties.findIndex((viewProperty) => viewProperty.slug === weightPropertySlug);
      if (index > -1) {
        return this.viewProperties[index];
      }
    }
    return null;
  }

  getTargetPropertySlug() {
    let propertySlug = this.gridViewManager.selectedCell.propertySlug;
    if (propertySlug.endsWith(WEIGHT_PROP_SUFFIX)) {
      propertySlug = propertySlug.substring(0, propertySlug.indexOf(WEIGHT_PROP_SUFFIX));
    }
    return propertySlug;
  }

  isSpreadActionAllowed() {
    // only allow spread function in focus mode with a selected cell or when multiple cells are selected
    if (
      (!this.focusedFamilyItem && !this.selectorCells?.length) ||
      (this.focusedFamilyItem && !this.gridViewManager.selectedCell)
    ) {
      return false;
    } else {
      if (this.selectorCells?.length) {
        const uniqueColumnIds = this.selectorCells
          .map((selectorCell) => selectorCell.columnId)
          .filter((x, i, a) => a.indexOf(x) === i);
        if (uniqueColumnIds.length > 1) {
          // only one column can be selected
          return false;
        }
        const propIndex = this.viewProperties.findIndex((prop) => prop.slug === this.selectorCells[0].columnId);
        if (propIndex === -1 || this.viewProperties[propIndex].propertyDefinition.propertyType !== 'number') {
          return false;
        }
      } else if (this.focusedFamilyItem && this.gridViewManager.selectedCell) {
        const propIndex = this.viewProperties.findIndex(
          (prop) => prop.slug === this.gridViewManager.selectedCell.propertySlug,
        );
        if (propIndex === -1 || this.viewProperties[propIndex].propertyDefinition.propertyType !== 'number') {
          return false;
        }
      }
    }
    return true;
  }

  private calculateTotal(columnId) {
    let rowIds;
    if (this.selectorCells.length > 0) {
      rowIds = this.selectorCells.map((selectorCell) => selectorCell.rowId);
    } else {
      rowIds = this.focusedItemFamilyService.getFocusedItemFamilyPlaceholderIds();
    }
    const values = rowIds.map((rowId) => {
      let value = this.gridViewManager.getCell(rowId, columnId).getValue();
      if (!value || isNaN(value)) {
        value = 0;
      } else {
        if (typeof value !== 'number') {
          value = parseFloat(value);
        }
      }
      return value;
    });
    const spreadTotal = values.reduce((p, c) => p + c);
    return spreadTotal;
  }
}
