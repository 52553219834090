import { Types } from '@contrail/sdk';
import { FormulaProcessor, isReferencePropertyType, PropertyType, Type, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { FormulaFunctionProcessor } from '@contrail/types';
import { OrgConfig } from 'src/app/common/auth/auth.service';
import { CollectionDataEntity } from '../collection-manager.service';

export class PlaceholderUtil {
  public static async postProcessLoadedPlaceholders(
    placeholders: Array<any>,
    typeMap: any,
    assortment = null,
    orgConfig: OrgConfig,
  ) {
    const matchedSlugs = this.getMappedProperties(typeMap).map((prop) => prop.slug);

    const planPlaceholderType = await new Types().getType({ root: 'plan-placeholder', path: 'plan-placeholder' });
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
    const itemType = await new Types().getType({ root: 'item', path: 'item' });

    const itemPropertyMap = this.getPropertyMap(itemType);
    const projectItemPropertyMap = this.getPropertyMap(projectItemType);

    const context: any = {};
    if (assortment) {
      context.assortment = assortment;
    }

    const promises = [];
    for (const ph of placeholders) {
      promises.push(
        this.postProcessLoadedPlaceholder(
          assortment,
          ObjectUtil.cloneDeep(ph),
          planPlaceholderType,
          matchedSlugs,
          itemPropertyMap,
          projectItemPropertyMap,
          orgConfig,
        ),
      );
    }
    const newPlaceholders = await Promise.all(promises);
    return newPlaceholders;
  }

  public static async postProcessLoadedPlaceholder(
    assortment: any,
    ph: any,
    planPlaceholderType: Type,
    matchedSlugs: any[],
    itemPropertyMap: any,
    projectItemPropertyMap: any,
    orgConfig: OrgConfig,
  ) {
    const processed = await PlaceholderUtil.prepPlaceholderData(
      ObjectUtil.cloneDeep(ph),
      matchedSlugs,
      itemPropertyMap,
      projectItemPropertyMap,
      orgConfig,
    );
    /** Calculate fomulas.  */
    FormulaProcessor.processFormulasForEntity(processed, planPlaceholderType.typeProperties);
    await FormulaFunctionProcessor.processFormulaFunctionsForEntity(processed, planPlaceholderType.typeProperties, {
      assortment,
      previousObj: processed,
    });
    return processed;
  }

  public static async postProcessPlaceholder(placeholder: any, typeMap: any, assortment = null, orgConfig: OrgConfig) {
    const planPlaceholderType = await new Types().getType({ root: 'plan-placeholder', path: 'plan-placeholder' });
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
    const itemType = await new Types().getType({ root: 'item', path: 'item' });
    const itemPropertyMap = this.getPropertyMap(itemType);
    const projectItemPropertyMap = this.getPropertyMap(projectItemType);

    const matchedSlugs = this.getMappedProperties(typeMap).map((prop) => prop.slug);
    const processed = await this.prepPlaceholderData(
      ObjectUtil.cloneDeep(placeholder),
      matchedSlugs,
      itemPropertyMap,
      projectItemPropertyMap,
      orgConfig,
    );
    FormulaProcessor.processFormulasForEntity(processed, planPlaceholderType.typeProperties);
    await FormulaFunctionProcessor.processFormulaFunctionsForEntity(processed, planPlaceholderType.typeProperties, {
      assortment,
      previousObj: processed,
    });
    return processed;
  }

  public static getPropertyMap(type: Type) {
    const map = {};
    for (const prop of type.typeProperties) {
      map[prop.slug] = prop;
    }
    return map;
  }

  public static getMappedProperties(typeMap: any) {
    const matchedSlugs = [];
    const placeholderType: Type = typeMap['plan-placeholder'];
    const itemType: Type = typeMap['item'];
    const projectItemType: Type = typeMap['project-item'];
    if (placeholderType.typeProperties && itemType.typeProperties) {
      placeholderType.typeProperties.forEach((pp) => {
        const match = itemType.typeProperties.find((ip) => pp.slug === ip.slug);
        if (match && !['createdOn', 'createdBy', 'updatedOn', 'updatedBy'].includes(pp.slug)) {
          matchedSlugs.push(match);
        }
      });
    }
    if (placeholderType.typeProperties && projectItemType.typeProperties) {
      placeholderType.typeProperties.forEach((pp) => {
        const match = projectItemType.typeProperties.find((ip) => pp.slug === ip.slug);
        if (match && !['createdOn', 'createdBy', 'updatedOn', 'updatedBy'].includes(pp.slug)) {
          matchedSlugs.push(match);
        }
      });
    }

    return matchedSlugs;
  }

  /** This function is a little tired, and seems to be getting copied around. */
  public static async prepPlaceholderData(
    ph,
    mappedProperties: Array<string> = [],
    itemPropertyMap,
    projectItemPropertyMap,
    orgConfig: OrgConfig,
  ) {
    this.setThumbnailOfPlaceholderData(ph, orgConfig);

    if (ph.itemOption || ph.itemFamily) {
      const itemOption = ph.itemOption;
      const itemFamily = ph.itemFamily;

      // Handle itemType property, which is special handling for managing the item type.
      if (itemFamily) {
        ph.itemTypeId = itemFamily.typeId;
      }

      for (const propSlug of mappedProperties) {
        const itemProperty = itemPropertyMap[propSlug];
        if (itemProperty) {
          if (itemOption) {
            ph[propSlug] = itemOption[propSlug];
          } else if (itemFamily && propSlug !== 'optionName' && itemProperty?.propertyLevel !== 'option') {
            ph[propSlug] = itemFamily[propSlug];
          }
        }

        const projectItemProperty = projectItemPropertyMap[propSlug];
        if (projectItemProperty) {
          if (itemOption?.projectItem && ['option', 'overridable', 'all'].includes(projectItemProperty.propertyLevel)) {
            ph[propSlug] = itemOption.projectItem[propSlug];
          } else if (
            itemFamily?.projectItem &&
            ['family', 'overridable', 'all'].includes(projectItemProperty.propertyLevel)
          ) {
            ph[propSlug] = itemFamily.projectItem[propSlug];
          }
        }
      }
    }
    return ph;
  }

  static setThumbnailOfPlaceholderData(ph, orgConfig: OrgConfig): void {
    if (!ph.itemOption && !ph.itemFamily) return;
    const urlsToSet = this.getUrlsToSetForThumbnail(ph.itemOption, ph.itemFamily, orgConfig);

    ph.thumbnail = urlsToSet.thumbnail;
    ph.thumbnailPreview = urlsToSet.thumbnailPreview;
  }

  public static getUrlsToSetForThumbnail(
    itemOption,
    itemFamily,
    orgConfig: OrgConfig,
  ): { thumbnail: string; thumbnailPreview: string } {
    if (!itemOption && !itemFamily) return;

    const shouldShowFamilyThumbnail = Boolean(
      (itemOption &&
        orgConfig.showFamilyThumbnailInPlanWhenNoOptionThumbnail &&
        !itemOption.smallViewableDownloadUrl) ||
        (!itemOption && itemFamily),
    );

    let thumbnail = null;
    let thumbnailPreview = null;

    if (shouldShowFamilyThumbnail) {
      thumbnail = itemFamily?.smallViewableDownloadUrl;
      thumbnailPreview = itemFamily?.mediumViewableDownloadUrl;
    } else {
      thumbnail = itemOption?.smallViewableDownloadUrl;
      thumbnailPreview = itemOption?.mediumViewableDownloadUrl;
    }

    if (!thumbnail) {
      return { thumbnail: null, thumbnailPreview: null };
    }

    return { thumbnail, thumbnailPreview };
  }

  /**
   * Clears item and project-item property values on a placeholder depending on levels
   * @param placeholder
   * @param itemOptionRemoval
   * @returns
   */
  public static async clearMappedPropertiesOnPlaceholder(placeholder: any, itemOptionRemoval = false): Promise<any> {
    const phType: any = await new Types().getType({ root: 'plan-placeholder', path: 'plan-placeholder' });
    const itemType: any = await new Types().getType({ root: 'item', path: 'item' });
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
    let itemProperties = itemType.typeProperties;
    let projectItemProperties = projectItemType.typeProperties;
    const entityChanges = {};
    if (itemOptionRemoval) {
      // If placeholder contains an item-option, look for properties that are either 'all', 'option' or 'overridable' level.
      const levels = ['all', 'option', 'overridable'];
      itemProperties = itemType.typeProperties.filter((prop) => levels.includes(prop.propertyLevel));
      projectItemProperties = projectItemType.typeProperties.filter((prop) => levels.includes(prop.propertyLevel));
      entityChanges['thumbnail'] = placeholder.itemFamily?.smallViewableDownloadUrl; // use thumbnail on itemFamily if item-option is removed.
      entityChanges['optionName'] = null; // clears out optionName
    } else {
      entityChanges['thumbnail'] = null;
    }

    phType.typeProperties.forEach((prop) => {
      let isProjectItemProperty = false;
      if (['createdOn', 'updatedOn'].includes(prop.slug)) {
        return;
      }
      let property: TypeProperty = itemProperties.find((p) => p.slug === prop.slug);
      if (!property) {
        property = projectItemProperties.find((p) => p.slug === prop.slug);
        isProjectItemProperty = property ? true : false;
        console.log(prop.slug, isProjectItemProperty);
      }
      if (!property || placeholder[prop.slug] === undefined || placeholder[prop.slug] === null) {
        return;
      }
      // When an item-option is removed, use value from item-family if the property level is either all or overridable
      if (itemOptionRemoval && ['all', 'overridable'].includes(property.propertyLevel)) {
        entityChanges[prop.slug] = isProjectItemProperty
          ? placeholder.itemFamily.projectItem[prop.slug]
          : placeholder.itemFamily[prop.slug];
        if (property.propertyType === PropertyType.ObjectReference) {
          entityChanges[prop.slug + 'Id'] = isProjectItemProperty
            ? placeholder.itemFamily.projectItem[prop.slug + 'Id']
            : placeholder.itemFamily[prop.slug + 'Id'];
        }
      } else {
        // When an item-option is removed, clear all values from the 'option' level.
        entityChanges[prop.slug] = null;
        if (property.propertyType === PropertyType.ObjectReference) {
          entityChanges[prop.slug + 'Id'] = null;
        }
      }
    });
    return entityChanges;
  }

  public static async deleteMappedPropertiesFromPlaceholder(placeholder: CollectionDataEntity) {
    const propertySlugsToSkipRemovalOf = ['id', 'specifiedId', 'itemFamilyId', 'itemOptionId', 'itemTypeId'];
    const itemType = await new Types().getType({ root: 'item', path: 'item' });
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });

    const itemProperties = itemType.typeProperties;
    const projectItemProperties = projectItemType.typeProperties;
    const allProperties = [...itemProperties, ...projectItemProperties];

    for (const property of allProperties) {
      if (isReferencePropertyType(property.propertyType)) {
        const slug = property.slug + 'Id';
        if (!propertySlugsToSkipRemovalOf.includes(slug)) {
          delete placeholder[slug];
        }
      }

      if (!propertySlugsToSkipRemovalOf.includes(property.slug)) {
        delete placeholder[property.slug];
      }
    }
  }

  public static removeRestrictedPropertiesOnPlaceholder(placeholder: any, typeMap: any): any {
    const restrictedPlaceholderPropertySlugs = typeMap['plan-placeholder']?.restrictedTypePropertySlugs;
    const restrictedItemPropertySlugs = typeMap['item']?.restrictedTypePropertySlugs;
    const restrictedProjectItemPropertySlugs = typeMap['project-item']?.restrictedTypePropertySlugs;

    if (restrictedPlaceholderPropertySlugs?.length) {
      for (const propSlug of restrictedPlaceholderPropertySlugs) {
        delete placeholder[propSlug];
      }
    }

    if (restrictedItemPropertySlugs?.length) {
      for (const propSlug of restrictedItemPropertySlugs) {
        if (placeholder?.itemFamily) delete placeholder.itemFamily[propSlug];
        if (placeholder?.itemOption) delete placeholder.itemOption[propSlug];
      }
    }

    if (restrictedProjectItemPropertySlugs?.length) {
      for (const propSlug of restrictedProjectItemPropertySlugs) {
        if (placeholder?.itemFamily?.projectItem) delete placeholder.itemFamily.projectItem[propSlug];
        if (placeholder?.itemOption?.projectItem) delete placeholder.itemOption.projectItem[propSlug];
      }
    }

    return placeholder;
  }
}
