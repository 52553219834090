import { Injectable } from '@angular/core';
import { TypeConstraintsHelper } from '@contrail/type-validation';
import { Types } from '@contrail/sdk';
import { PropertyType, Type, TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../collection-manager-store';
import { SizeRangeHelper } from '@common/size-range/size-range-helper';
import { ObjectUtil } from '@contrail/util';

export interface CollectionElementEditCheck {
  editable: boolean;
  reason?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CollectionElementValidator {
  private editorMode: string;
  constructor(private store: Store<RootStoreState.State>) {
    this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
  }
  public async isEditableFromId(
    collectionElementId: string,
    propertySlug: string,
  ): Promise<CollectionElementEditCheck> {
    let element: any;
    this.store
      .select(CollectionManagerSelectors.selectCollectionElement(collectionElementId))
      .pipe(
        take(1),
        tap((el) => {
          element = el;
        }),
      )
      .subscribe();
    return this.isEditableForPropertySlug(element, propertySlug);
  }

  public async isEditableForPropertySlug(
    collectionElement: any,
    propertySlug: string,
  ): Promise<CollectionElementEditCheck> {
    let viewProperty;
    this.store
      .select(CollectionManagerSelectors.currentViewProperties)
      .pipe(
        take(1),
        tap((viewProperties) => {
          viewProperty = viewProperties.find((p) => p.slug === propertySlug);
        }),
      )
      .subscribe();
    return this.isEditableForProperty(collectionElement, viewProperty);
  }

  /** Looks at many rules to determine if a cell is editable or not.
   * This function is getting rather expensive, but relative to other costs in the plan, its small.
   */
  public async isEditableForProperty(collectionElement: any, viewProperty: any): Promise<CollectionElementEditCheck> {
    if (!collectionElement) {
      return {
        editable: false,
      };
    }
    if (!viewProperty) {
      return {
        editable: false,
      };
    }
    if (['VIEW'].includes(this.editorMode)) {
      return {
        reason: 'The plan is in view only mode.',
        editable: false,
      };
    }
    if (['COMMENT'].includes(this.editorMode)) {
      return {
        reason: 'The plan is in comment only mode.',
        editable: false,
      };
    }

    if (viewProperty.propertyDefinition?.canUpdate === false) {
      return {
        reason: 'You do not have access to edit this property.',
        editable: false,
      };
    }

    if (viewProperty.propertyDefinition?.propertyType === PropertyType.Image) {
      return {
        reason: 'Images are not editable.',
        editable: false,
      };
    }

    if (viewProperty.propertyDefinition?.editable === false) {
      return {
        reason: 'This property is not editable.',
        editable: false,
      };
    }

    if ([PropertyType.Formula].includes(viewProperty.propertyDefinition?.propertyType)) {
      return {
        reason: 'This value is calculated with a formula.',
        editable: false,
      };
    }

    // SHOULD BE BASED ON A PROPERTY SETTING (Editable === false)
    if (['sequence'].includes(viewProperty.propertyDefinition?.propertyType)) {
      return {
        reason: "Property of type 'sequence' is not editable.",
        editable: false,
      };
    }

    // SHOULD BE BASED ON A PROPERTY SETTING (Editable === false)
    if (['createdOn', 'updatedOn', 'itemNumber'].includes(viewProperty.slug)) {
      return {
        reason: 'Property is not editable.',
        editable: false,
      };
    }

    // Size Range property value can only be set if size range template is set
    if (viewProperty.propertyDefinition?.propertyType === PropertyType.SizeRange) {
      if (!SizeRangeHelper.getConstrainingSizeRange(collectionElement)) {
        return {
          reason: 'Property is editable with a size range template.',
          editable: false,
        };
      }
    }

    /** Leverage  */
    const planPlaceholderType = await new Types().getType({ path: 'plan-placeholder' });
    let validationData = ObjectUtil.cloneDeep(collectionElement);
    const type: Type = planPlaceholderType;
    let typeProperty: TypeProperty;
    if (viewProperty.scope === 'item' && collectionElement.itemFamily) {
      const itemType = await new Types().getType({ id: collectionElement.itemFamily.typeId });
      typeProperty = itemType.typeProperties.find((p) => p.slug === viewProperty.slug);
    } else if (viewProperty.scope === 'project-item' && collectionElement.itemFamily?.projectItem) {
      const projectItemType = await new Types().getType({ id: collectionElement.itemFamily.projectItem.typeId });
      typeProperty = projectItemType.typeProperties.find((p) => p.slug === viewProperty.slug);
    } else {
      typeProperty = type.typeProperties.find((p) => p.slug === viewProperty.slug);
    }
    let constraintCheck;
    if (collectionElement.itemFamily) {
      // if this placeholder has item(s) assigned to it.
      if (
        (['overridable', 'all', 'option'].includes(typeProperty?.propertyLevel) ||
          viewProperty?.slug === 'optionName') &&
        validationData.itemOption
      ) {
        delete validationData.itemFamily;
      } else {
        delete validationData.itemOption;
      }
    }

    constraintCheck = await TypeConstraintsHelper.isPropertyEditable(
      type,
      viewProperty.propertyDefinition,
      validationData,
    );

    if (!constraintCheck.editable) {
      return {
        editable: false,
        reason: TypeConstraintsHelper.getMessageForReasonCode(constraintCheck.reasonCode),
      };
    }

    return {
      editable: true,
    };
  }

  public async isPasteableForPropertySlug(collectionElement: any, propertySlug: string): Promise<boolean> {
    let viewProperty;
    this.store
      .select(CollectionManagerSelectors.currentViewProperties)
      .pipe(
        take(1),
        tap((viewProperties) => {
          viewProperty = viewProperties.find((p) => p.slug === propertySlug);
        }),
      )
      .subscribe();
    return propertySlug === 'itemFamilyId' || this.isPasteableForProperty(collectionElement, viewProperty);
  }

  // Used to determine whether a cell is pasteable in the context of row copy/paste. Do not use if not copy/paste row.
  public async isPasteableForProperty(collectionElement: any, viewProperty: any): Promise<boolean> {
    if (!collectionElement) {
      return false;
    }
    if (!viewProperty) {
      return false;
    }

    if (['sequence'].includes(viewProperty.propertyDefinition.propertyType)) {
      return false;
    }

    if (['createdOn', 'updatedOn', 'optionName', 'itemOption', 'thumbnail'].includes(viewProperty.slug)) {
      return false;
    }

    let itemType: Type;
    let typeProperty: TypeProperty;

    if (viewProperty.scope === 'item' && collectionElement.itemFamily) {
      itemType = await new Types().getType({ id: collectionElement.itemFamily.typeId });
      typeProperty = itemType.typeProperties.find((p) => p.slug === viewProperty.slug);
    }
    if (viewProperty.scope === 'item' && ['all', 'option'].includes(typeProperty?.propertyLevel)) {
      return false;
    }
    return true;
  }
}
