import { Component, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { CollectionManagerService } from '../collection-manager.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DataGroup } from '../data-group/data-group';
import { DataGroupHelper } from '../data-group/data-group-helper';
import { Store } from '@ngrx/store';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../collection-manager-store';
import { ViewDefinition, ViewPropertyConfiguration } from '@contrail/client-views';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { PropertyType, PropertyValueFormatter } from '@contrail/types';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
@Component({
  selector: 'app-kanban-view',
  templateUrl: './kanban-view.component.html',
  styleUrls: ['./kanban-view.component.scss'],
})
export class KanbanViewComponent implements OnInit {
  constructor(
    private collectionManagerService: CollectionManagerService,
    private store: Store<RootStoreState.State>,
    private dataGroupHelper: DataGroupHelper,
    private snackBar: MatSnackBar,
  ) {
    this.subscribe();
  }

  public data: any;
  public currentView: ViewDefinition;
  public groupingProperty;
  public groups: Array<DataGroup> = [];
  public dataGroups: Map<string, Array<any>> = new Map();
  public properties: Array<ViewPropertyConfiguration>;
  private dataSub: Subscription;
  private editorModeSub: Subscription;
  public editorMode = 'VIEW';
  private currentViewSub: Subscription;
  public hideEmptyGroups = false;
  private formatter = new PropertyValueFormatter();
  private typeMap: any;

  ngOnInit() {
    this.subscribe();

    this.store.select(CollectionManagerSelectors.typeDefinitions).subscribe((typeMap) => {
      this.typeMap = typeMap;
    });
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {
    combineLatest([
      this.store.select(CollectionManagerSelectors.displayedData),
      this.store.select(CollectionManagerSelectors.selectCurrentView),
      this.store.select(CollectionManagerSelectors.hideEmptyGroups),
    ])
      .pipe(
        debounceTime(500),
        filter(([data, currentView, hideEmptyGroups]) => !!data && !!currentView),
        tap(([data, currentView, hideEmptyGroups]) => {
          this.currentView = currentView;
          this.data = data;
          this.hideEmptyGroups = hideEmptyGroups;
          this.initKanban();
        }),
      )
      .subscribe();
    this.editorModeSub = this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
  }
  unsubscribe() {
    this.dataSub?.unsubscribe();
    this.editorModeSub.unsubscribe();
    this.currentViewSub?.unsubscribe();
  }

  initKanban() {
    if (!this.currentView?.dimensions?.[0] || !this.data) {
      return;
    }
    this.groups = [];
    const dimension = this.currentView.dimensions[0];
    const parts = dimension.split(':');
    const propertySlug = parts[1];
    const restrictedProperties: string[] = this.typeMap['plan-placeholder']?.restrictedTypePropertySlugs || [];
    if (propertySlug && restrictedProperties.includes(propertySlug)) {
      if (!this.snackBar._openedSnackBarRef) {
        this.snackBar.open('View cannot be displayed due to restricted access.', '', { duration: 5000 });
      }
      return;
    }

    this.groupingProperty = this.dataGroupHelper.getProperty('plan-placeholder', propertySlug);
    const propertyScope = this.dataGroupHelper.getPropertyScope(propertySlug);
    let data = this.data.filter((obj) => obj[this.groupingProperty.slug] === null || !obj[this.groupingProperty.slug]);
    if (data?.length || !this.hideEmptyGroups) {
      this.groups.push({
        dim1: {
          label: '(Empty)',
          value: null,
          scope: propertyScope,
          slug: this.groupingProperty.slug,
          propertyDefinition: this.groupingProperty,
        },
        data,
      });
    }

    if ([PropertyType.String, PropertyType.Date].includes(this.groupingProperty.propertyType)) {
      const allGroups = this.groupBy(this.data, this.groupingProperty.slug);
      const groupKeys = Object.keys(allGroups);
      this.sortGroups(groupKeys);
      groupKeys.forEach((groupValue) => {
        const groupDisplay = this.formatter.formatValue(groupValue, this.groupingProperty.propertyType);
        this.groups.push({
          dim1: {
            label: groupDisplay,
            value: groupValue,
            scope: propertyScope,
            slug: this.groupingProperty.slug,
            propertyDefinition: this.groupingProperty,
          },
          data: allGroups[groupValue],
        });
      });
    } else if (this.groupingProperty.propertyType === PropertyType.ObjectReference) {
      console.log(
        this.data.filter((obj) => obj[this.groupingProperty.slug] && obj[this.groupingProperty.slug] !== null),
      );
      const uniqueEntities = [
        ...new Set(
          this.data
            .filter((obj) => obj[this.groupingProperty.slug] && obj[this.groupingProperty.slug] !== null)
            .map((obj) => obj[this.groupingProperty.slug]?.id),
        ),
      ];
      console.log(uniqueEntities);
      uniqueEntities.forEach((entityId) => {
        data = this.data.filter((obj) => obj[this.groupingProperty.slug]?.id === entityId);
        if (data?.length || !this.hideEmptyGroups) {
          if (data[0]) {
            this.groups.push({
              dim1: {
                label: data[0][this.groupingProperty.slug]?.name,
                value: data[0][this.groupingProperty.slug],
                scope: propertyScope,
                slug: this.groupingProperty.slug,
                propertyDefinition: this.groupingProperty,
              },
              data,
            });
          }
        }
      });
    } else {
      this.groupingProperty.options.forEach((option) => {
        data = this.data.filter((obj) => obj[this.groupingProperty.slug] === option.value);
        if (data?.length || !this.hideEmptyGroups) {
          this.groups.push({
            dim1: {
              label: option.display,
              value: option.value,
              scope: propertyScope,
              slug: this.groupingProperty.slug,
              propertyDefinition: this.groupingProperty,
            },
            data,
            // SORT...
          });
        }
      });
    }
    this.properties = this.currentView.properties.filter((prop) => prop.enabled && prop.propertyDefinition);
  }

  drop(event: CdkDragDrop<string[]>) {
    this.dataGroupHelper.handleDrop(event, this.groups, [this.groupingProperty]);
  }

  groupTrackByFn(index, group: any) {
    return group.dim1.value;
  }

  groupBy(data, key) {
    return data.reduce((item, x) => {
      if (x[key]) {
        // only includes values that are not empty
        (item[x[key] || ''] = item[x[key]] || []).push(x);
      }
      return item;
    }, {});
  }

  sortGroups(groups) {
    groups.sort((val1, val2) => {
      let comp = 0;
      comp = this.compareKeys(val1, val2);
      if (comp !== 0) {
        return comp;
      }
      return 0;
    });
  }

  compareKeys(val1: any, val2: any) {
    let comp = 0;
    if (val1.localeCompare) {
      comp = val1.localeCompare(val2);
    } else {
      comp = val1 - val2;
    }
    return comp;
  }
}
