import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Types } from '@contrail/sdk';
import { PropertyType, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { State, Store } from '@ngrx/store';
import { nanoid } from 'nanoid';
import { tap, take } from 'rxjs/operators';
import { ConfirmationBoxService } from 'src/app/common/components/confirmation-box/confirmation-box';
import { FilterConditionType } from '@contrail/filters';
import { FilterDefinition } from 'src/app/common/types/filters/filter-definition';
import { TypePropertyUserListService } from '@common/user-list/user-list.service';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { PlaceholderItemAssignmentService } from '../placeholders/placeholder-item-assignment-service';
import { CollectionElementActionValidator } from '../collection-element-validator/collection-element-action-validator';
import { GridSelectorActionUtil } from './selectors/grid-selector-action-util';
import { OverrideOptionService } from './override-option/override-option-service';
import { CollectionElementValidator } from '../collection-element-validator/collection-element-validator';

@Injectable({
  providedIn: 'root',
})
export class GridRowService {
  private currentFilter: FilterDefinition;
  private editorMode = 'VIEW';
  private sorts;
  private selectedRows;
  private allData;
  private planRowOrder;
  private focusedFamilyItem: any;
  private defaultTypeId: any;
  private currentAssortment: any;
  private currentView: any;
  private gridSelectorActionUtil: GridSelectorActionUtil;

  constructor(
    private store: Store<RootStoreState.State>,
    private confirmationBoxService: ConfirmationBoxService,
    private collectionElementActionValidator: CollectionElementActionValidator,
    private phItemAssignmentService: PlaceholderItemAssignmentService,
    private snackBar: MatSnackBar,
    private collectionElementValidator: CollectionElementValidator,
    private overrideOptionService: OverrideOptionService,
    private userListService: TypePropertyUserListService,
  ) {
    this.store.select(CollectionManagerSelectors.filterDefinition).subscribe((fd) => {
      this.currentFilter = fd;
    });
    this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
    this.store.select(CollectionManagerSelectors.selectCurrentView).subscribe((currentView) => {
      this.sorts = ObjectUtil.cloneDeep(currentView?.sorts) || [];
      this.currentView = currentView;
    });
    this.store
      .select(CollectionManagerSelectors.selectedEntityIds)
      .subscribe((selectedRows) => (this.selectedRows = selectedRows));
    this.store.select(CollectionManagerSelectors.allData).subscribe((allData) => (this.allData = allData));
    this.store.select(PlansSelectors.planRowOrder).subscribe((planRowOrder) => (this.planRowOrder = planRowOrder));
    this.store
      .select(CollectionManagerSelectors.focusedFamilyItem)
      .subscribe((focusedFamilyItem) => (this.focusedFamilyItem = focusedFamilyItem));
    this.determineDefaultTypeId();

    this.gridSelectorActionUtil = new GridSelectorActionUtil(
      this.store,
      this.collectionElementValidator,
      this.collectionElementActionValidator,
      this.phItemAssignmentService,
      this.overrideOptionService,
      this.userListService,
      this.snackBar,
    );
  }

  private determineDefaultTypeId() {
    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(
        take(1),
        tap((plan: any) => {
          this.currentAssortment = plan?.targetAssortment;
          let assortmentTypeId = plan?.targetAssortment.itemTypeId;
          if (assortmentTypeId) {
            this.defaultTypeId = assortmentTypeId;
          }
        }),
      )
      .subscribe();
  }

  /** Adds rows to the grid. */
  public async addRowToCollection(count: number = 1, rowIds?: Array<string>, position?: string) {
    if (this.editorMode !== 'EDIT') {
      return;
    }

    console.log('currentFilter: ', this.currentFilter);

    const newPhs = [];
    let targetRowIndex = null;
    let rowOrder = null;
    if (this.sorts.length === 0 && rowIds?.length > 0 && position) {
      // inserting row at a specific row position
      const clonedRowIds = ObjectUtil.cloneDeep(rowIds);
      // sort the rows based on their index
      clonedRowIds.sort((a, b) =>
        this.allData.findIndex((row) => row.id === a) > this.allData.findIndex((row) => row.id === b) ? 1 : -1,
      );
      rowOrder = [];
      for (let index = 0; index < clonedRowIds.length; index++) {
        const rowId = clonedRowIds[index];
        const rowIndex = this.allData.findIndex((row) => row.id === rowId);
        const movedRowIndex = position === 'above' ? rowIndex + index + 1 : rowIndex + index;
        rowOrder.push({ id: rowId, index: movedRowIndex }); // move the original row since it has moved due to insert
        let entity: any = { id: nanoid(16) };
        if (this.focusedFamilyItem) {
          const additions = await this.phItemAssignmentService.setItemFamilyOnPlaceholder(
            entity,
            this.focusedFamilyItem,
            true,
            true,
          );
          entity = Object.assign(entity, additions);
        }
        await this.applyDefaultValues(entity, this.currentFilter);
        newPhs.push(entity);
        const newRowIndex = position === 'above' ? movedRowIndex - 1 : movedRowIndex + 1;
        rowOrder.push({ id: entity.id, index: newRowIndex }); // insert the new row at the proper index
      }
      rowOrder.sort((a, b) => (a.index > b.index ? 1 : -1));
      if (this.selectedRows.length > 0) {
        this.store.dispatch(CollectionManagerActions.removeSelectedEntityIds({ ids: rowIds }));
      }
    } else {
      // adding rows to the end of grid
      targetRowIndex = this.allData.length;
      for (let i = 0; i < count; i++) {
        let entity = {};
        if (this.focusedFamilyItem) {
          const additions = await this.phItemAssignmentService.setItemFamilyOnPlaceholder(
            entity,
            this.focusedFamilyItem,
            true,
            true,
          );
          entity = Object.assign(entity, additions);
        }
        await this.applyDefaultValues(entity, this.currentFilter);
        newPhs.push(entity);
      }
    }
    this.store.dispatch(
      CollectionManagerActions.createCollectionDataEntities({ entities: newPhs, targetRowIndex, rowOrder }),
    );
  }

  public async deleteRowFromCollection(ids) {
    if (this.editorMode !== 'EDIT') return;

    const action = await this.confirmationBoxService.open(
      `Delete confirmation`,
      `Are you sure you want to delete the selected row(s) from the plan?`,
      'Cancel',
      'Delete',
    );
    if (!action) return;

    let errorMessage: string;
    const idsOfPlaceholdersToDelete = [];
    const placeholders = this.getPlaceholdersByIds(ids);
    for (const placeholder of placeholders) {
      const deleteCheck = this.collectionElementActionValidator.isDeletable(placeholder);
      if (!deleteCheck.isValid) {
        errorMessage = deleteCheck.reason;
        continue;
      }

      idsOfPlaceholdersToDelete.push(placeholder.id);
    }

    if (errorMessage) {
      this.snackBar.open(errorMessage, '', { duration: 5000 });
    }

    if (idsOfPlaceholdersToDelete.length > 0) {
      this.store.dispatch(CollectionManagerActions.deleteCollectionDataEntities({ ids: idsOfPlaceholdersToDelete }));
    }
  }

  private getPlaceholdersByIds(ids: string[]) {
    let placeholders: any[];
    this.store
      .select(CollectionManagerSelectors.displayedData)
      .pipe(
        take(1),
        tap((data) => {
          placeholders = ObjectUtil.cloneDeep(data.filter((obj) => ids.includes(obj.id)));
        }),
      )
      .subscribe();
    return placeholders;
  }

  /** Populates values on a new entity based on the current set of filters,
   * and other defaults, such as defaults on the current assortment.
   */
  private async applyDefaultValues(entity, filter: FilterDefinition) {
    console.log('applyDefaultValues: ', entity, filter);

    for (const propDef of filter?.filterCriteria?.propertyCriteria) {
      const property: TypeProperty = propDef.filterPropertyDefinition as TypeProperty;
      if (propDef.filterConditionType === FilterConditionType.EQUALS) {
        const viewProp = this.currentView.properties.find((prop) => prop.slug === property.slug);
        if (property.slug === 'itemOption') {
          continue;
        }

        if (viewProp?.propertyDefinition?.editable) {
          // only set filter value if the property is editable
          const isReference = [
            PropertyType.ObjectReference,
            PropertyType.TypeReference,
            PropertyType.UserList,
          ].includes(viewProp.propertyDefinition.propertyType);
          if (isReference) {
            const value = await this.getReferenceValue(viewProp.propertyDefinition, propDef.criteriaValue);
            entity[property.slug] = value;
            entity[`${property.slug}Id`] = value.id;
          } else {
            entity[property.slug] = propDef.criteriaValue;
          }
        }
      }
    }
    console.log('applyDefaultValues: ', entity);

    // Default to the item type for the assortment
    // This should only really happen if the assortments type is
    // a leaf node.
    if (this.defaultTypeId) {
      entity.itemTypeId = this.defaultTypeId;
    }

    // Default values from assortment if they match on property key
    if (this.currentAssortment) {
      console.log('currentAssortment: ', this.currentAssortment);
      const assortmentType = await new Types().getType({ id: this.currentAssortment.typeId });
      const entityType = await new Types().getType({ path: 'plan-placeholder' });
      for (let prop of assortmentType.typeProperties) {
        const entityProp = entityType.typeProperties.find(
          (p) => p.slug === prop.slug && p.propertyType === prop.propertyType && prop.slug !== 'name',
        );
        if (!entityProp || !this.currentAssortment[entityProp.slug]) {
          continue;
        }
        if ([PropertyType.ObjectReference, PropertyType.TypeReference].includes(entityProp.propertyType)) {
          let slug = prop.slug + 'Id';
          entity[slug] = this.currentAssortment[slug];
        }
        entity[entityProp.slug] = this.currentAssortment[entityProp.slug];
      }
    }
  }

  public getRow(rowId: any) {
    const index = this.allData.findIndex((row) => row.id === rowId);
    const rowIndex = this.planRowOrder.rowOrder.indexOf(rowId); // should use the rowIndex from plan's row order
    if (index > -1) {
      return { rowIndex, data: this.allData[index] };
    }
    return null;
  }

  private async getReferenceValue(property: TypeProperty, value: any) {
    if (property.propertyType === PropertyType.ObjectReference) {
      return await this.gridSelectorActionUtil.parseObjRefValue(this.allData, value, property);
    } else if (property.propertyType === PropertyType.TypeReference) {
      return await this.gridSelectorActionUtil.parseTypeReferenceValue(this.allData, value);
    } else if (property.propertyType === PropertyType.UserList) {
      return await this.gridSelectorActionUtil.parseUserList(this.allData, value, property);
    }
  }
}
