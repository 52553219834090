import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';
import { Type } from '@contrail/types';
import { Plan } from 'src/app/plans/plans-store/plans.state';
import { ContrailConfig } from '@contrail/sdk';

export interface EntityValidationMessages {
  newMessages: CollectionStatusMessage[];
  updatedMessages: { id: string; changes: { message: string } }[];
  errorMessages?: ValidationErrorMessage[];
  removedMessageIds: string[];
}

export interface ValidationErrorMessage {
  type: string;
  message: string;
  collectionElementId: string;
  propertySlug: string;
}

export interface ValidationWorkerData {
  plan: Plan;
  collectionEntities: any[];
  typeDefinitions: { [key: string]: Type };
  authConfig: ContrailConfig;
}

export enum CollectionAlertTypes {
  WARNING = 'warning',
  DROPPED = 'dropped',
  NOT_IN_SOURCE_ASSORTMENT = 'notInSourceAssortment',
}
