import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Content, Entities, EntityReference } from '@contrail/sdk';
import { BehaviorSubject, Subject } from 'rxjs';
import { ContentService } from './content.service';
import { OrgMembership } from '@common/auth/auth.service';
import { AuthSelectors } from '@common/auth/auth-store';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';

@Injectable({
  providedIn: 'root',
})
export class ContentHolderService {
  private contentLoadingSubject = new BehaviorSubject(false);
  public contentLoading$ = this.contentLoadingSubject.asObservable();

  private contentUploadingSubject = new BehaviorSubject(false);
  public contentUploading$ = this.contentUploadingSubject.asObservable();

  public contentHolderContentObject = null;
  public contentHolderContentSubject = new BehaviorSubject([]);
  public contentHolderContent$ = this.contentHolderContentSubject.asObservable();

  private selectedContentObject;
  private selectedContentSubject = new BehaviorSubject(null);
  public selectedContent$ = this.selectedContentSubject.asObservable();

  private primaryViewableChangedSubject = new Subject();
  public primaryViewableChanged = this.primaryViewableChangedSubject.asObservable();

  private contentHolderReference: string;
  private contentHolder;

  private currentOrg: OrgMembership;

  constructor(
    private snackBar: MatSnackBar,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => {
      this.currentOrg = authContext?.currentOrg;
    });
  }

  public async loadContentHolder(contentHolder: any, entityReference: string) {
    this.contentHolderReference = entityReference;
    this.contentHolder = contentHolder;
    await this.loadContent();
  }

  public selectContent(content) {
    this.selectedContentObject = content;
    this.selectedContentSubject.next(this.selectedContentObject);
  }

  private async loadContent() {
    this.contentHolderContentObject = [];
    this.contentHolderContentSubject.next(this.contentHolderContentObject);
    this.contentLoadingSubject.next(true);
    const content = await ContentService.getContentForContentHolder(this.contentHolderReference);

    if (content?.length) {
      const idx = content.map((c) => c.id).indexOf(this.contentHolder?.primaryViewableId);
      this.selectedContentObject = idx === -1 ? content[0] : content[idx];
      this.selectedContentSubject.next(this.selectedContentObject);
    }
    this.contentHolderContentObject = content;
    this.contentHolderContentSubject.next(content);
    this.contentLoadingSubject.next(false);
  }

  async addContentToContentHolder(files: FileList, assignmentOption?: any) {
    const entityPolicyIds = await this.getContentPolicies(this.contentHolder);

    this.contentUploadingSubject.next(true);
    const firstTimeUpload = this.contentHolderContentObject.length === 0;
    let lastContentCreated;
    const asyncViewableGeneratedContent = [];
    try {
      for (const file of Array.from(files)) {
        console.log('uploading file: ', file);
        const content = await new Content().create({
          contentHolderReference: this.contentHolderReference,
          file,
          entityPolicyIds,
        });
        // content = new Entities().get({ entityName: 'content',
        //   id: content.id,
        //   relations: ['largeViewable', 'mediumViewable', 'primaryFile']
        // });
        if (ContentService.isNonViewableImage(file.name)) {
          asyncViewableGeneratedContent.push(content);
        }

        content.mediumViewable = {
          fileUrl: content.primaryFile?.fileUrl,
          downloadUrl: content.primaryFile?.downloadUrl,
        };
        content.largeViewable = {
          fileUrl: content.primaryFile?.fileUrl,
          downloadUrl: content.primaryFile?.downloadUrl,
        };
        lastContentCreated = content;

        this.contentHolderContentObject.push(content);
        this.contentHolderContentSubject.next(this.contentHolderContentObject);
      }
    } catch (error) {
      console.log('Error uploading content: ', JSON.stringify(error));
      const msg = 'Error uploading content.';
      this.snackBar.open(msg, '', { duration: 2000 });
    } finally {
      this.contentUploadingSubject.next(false);
      if (lastContentCreated) {
        this.selectedContentObject = lastContentCreated;
        this.selectedContentSubject.next(this.selectedContentObject);
      }
    }

    if (
      firstTimeUpload ||
      ['assignFirstContent', 'replacePrimary', 'replacePrimaryAndDeleteCurrent'].includes(assignmentOption?.action)
    ) {
      // Timing issue here.. if the viewables have not been generated yet.
      setTimeout(() => {
        this.makePrimaryViewable(this.contentHolderContentObject[this.contentHolderContentObject.length - 1]);
        if (assignmentOption?.action === 'replacePrimaryAndDeleteCurrent') {
          const priorPrimaryViewable = this.contentHolderContentObject.find(
            (contentObject) => contentObject.id === assignmentOption.priorPrimaryViewableId,
          );
          if (priorPrimaryViewable) {
            this.deleteContent(priorPrimaryViewable);
          }
        }
      }, 2000);
    }

    if (asyncViewableGeneratedContent.length > 0) {
      asyncViewableGeneratedContent.forEach((con) => {
        setTimeout(() => {
          this.loadContentImages(con, 1); // TODO: @Brian | do we need loadContentImages() ???
        }, 1000);
      });
    }
  }

  public async deleteContent(content) {
    this.contentHolderContentObject = this.contentHolderContentObject.filter((c) => c.id !== content.id);
    this.contentHolderContentSubject.next(this.contentHolderContentObject);
    if (this.contentHolderContentObject.length) {
      this.selectContent(this.contentHolderContentObject[0]);
    }

    // Delete needs to happen before other changes because later there can be request to this
    // content which should return nothing since it's being deleted.
    await new Entities().delete({ entityName: 'content', id: content.id });

    if (this.isPrimaryViewable(content)) {
      if (this.contentHolderContentObject.length > 0) {
        this.makePrimaryViewable(this.contentHolderContentObject[0]);
      } else {
        const entityRef = new EntityReference(this.contentHolderReference);
        await new Entities().update({
          entityName: entityRef.entityType,
          id: entityRef.id,
          object: {
            primaryViewableId: null,
            largeViewableDownloadUrl: null,
            mediumViewableDownloadUrl: null,
            mediumLargeViewableDownloadUrl: null,
            smallViewableDownloadUrl: null,
            tinyViewableDownloadUrl: null,
            primaryFileUrl: null,
          },
        });
        this.primaryViewableChangedSubject.next(null);
      }
    }
  }

  public async makePrimaryViewable(content) {
    if (!content?.id) {
      return;
    }

    this.contentHolder.primaryViewableId = content.id;
    this.selectedContentObject = content;
    this.selectedContentSubject.next(this.selectedContentObject);
    const entityRef = new EntityReference(this.contentHolderReference);
    this.primaryViewableChangedSubject.next(content);
    await new Entities().update({
      entityName: entityRef.entityType,
      id: entityRef.id,
      object: {
        primaryViewableId: content.id,
        largeViewableDownloadUrl: content.primaryFileUrl,
        mediumViewableDownloadUrl: content.primaryFileUrl,
        smallViewableDownloadUrl: content.primaryFileUrl,
      },
    });
  }

  /** Polls for updated viewable on a non image content upload  */
  private async loadContentImages(con: any, count: number) {
    const content = await new Entities().get({
      entityName: 'content',
      id: con.id,
      relations: ['largeViewable', 'mediumViewable', 'primaryFile'],
    });
    if (content?.largeViewableId) {
      const ind = this.contentHolderContentObject.findIndex((ele) => ele.id === con.id);
      this.contentHolderContentObject.splice(ind, 1, content);
      this.contentHolderContentObject = [...this.contentHolderContentObject];
      this.contentHolderContentSubject.next(this.contentHolderContentObject);

      if (content.id === this.selectedContentObject.id) {
        this.selectedContentObject = content;
        this.selectedContentSubject.next(this.selectedContentObject);
      }
      if (this.isPrimaryViewable(content)) {
        this.primaryViewableChangedSubject.next(content);
      }
    } else if (count < 30) {
      setTimeout(() => {
        this.loadContentImages(con, ++count);
      }, 1000);
    }
  }

  public isPrimaryViewable(content) {
    if (this.contentHolder.primaryViewableId === content.id) {
      return true;
    }
  }

  public async getContentPolicies(owner) {
    if (owner && this.currentOrg) {
      return await this.getContentPoliciesForContentOwner(owner, this.currentOrg); //TODO: replace with Action Validators when implemented
    }
    return [];
  }

  private async getContentPoliciesForContentOwner(owner, org: OrgMembership) {
    if (!this.isValidNewBalanceOrganization(org)) {
      return [];
    }

    if (owner?.nbHSPSecurityStatus === 'lock') {
      return this.getPoliciesByOrgSlug(org.orgSlug);
    }

    return [];
  }

  private getPoliciesByOrgSlug(orgSlug: string) {
    switch (orgSlug) {
      case 'new-balance-plm-sandbox-1':
        return ['NVxNUbj-v7vYzHhr'];
      case 'nb-reimagine-dev-1':
        return ['mr24aAM0_uIZmmba'];
      case 'nb-reimagine-dev-2':
        return ['PcYXgmQitXZc-0bF'];
      case 'nb-reimagine-training':
        return ['7uIcgcIx3rgFm7xa'];
      case 'nb-reimagine-prod':
        return ['yrLoNY5V4Kq3tk5X'];
      case 'nb-reimagine-qa-1':
        return ['-YZqQgeYt8f_elri'];
      case 'lauren-zone-3':
        return ['t4xXULRuftdOnI-E'];
      default:
        return [];
    }
  }

  private isValidNewBalanceOrganization(org: OrgMembership): boolean {
    const validNewBalanceOrgSlugs = [
      'demo',
      'lauren-zone-3',
      'new-balance-plm-sandbox-1',
      'nb-reimagine-dev-1',
      'nb-reimagine-dev-2',
      'nb-reimagine-training',
      'nb-reimagine-prod',
      'nb-reimagine-qa-1',
    ];
    return validNewBalanceOrgSlugs.includes(org.orgSlug);
  }
}
