import { CommentsActions } from 'src/app/common/comments/comments-store';
import { SelectedCellInfo } from '../context-menu.component';
import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { AddCommentsService } from '../../grid-view/add-comments-service';

export class CommentContextMenuAction extends ContextMenuAction {
  private selectedRows = [];
  constructor(
    protected store: Store<RootStoreState.State>,
    private addCommentsService: AddCommentsService,
  ) {
    super(store);
    this.svgActionIcon = 'comment-icon';
    this.actionLabel = 'Comment on cell';
    this.shortCutKey = 'Ctrl-M';
    this.testRef = 'app-menu-comment-action';

    this.store
      .select(CollectionManagerSelectors.selectedEntityIds)
      .subscribe((selectedRows) => (this.selectedRows = selectedRows));
  }
  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    this.addCommentsService.addComments();
  }

  canInvoke(editorMode: string, ids: Array<string>, selectedCellInfo: SelectedCellInfo = null) {
    if (editorMode === 'VIEW') {
      return false;
    }
    if (ids?.length !== 1) {
      return false;
    }
    if (!selectedCellInfo) {
      return false;
    }
    if (this.selectedRows.length > 0) {
      return false;
    }
    return true;
  }
}
