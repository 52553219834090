import { ElementRef, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';

export class GridViewScrollHandler {
  private horizontalScrollPercentage = 0;
  private verticalScrollPercentage = 0;
  private totalRowHeight = 0;
  private gridViewRowHeight = 0;
  private scrollableHeight = 0;

  private rightSideWidthNeeded = 0;
  private scrollableWidth = 0;
  private gridViewColumnWidth = 0;

  private subscriptions: Array<Subscription> = [];

  constructor(
    private store: Store<RootStoreState.State>,
    private renderer: Renderer2,
    private elem: ElementRef,
  ) {
    this.subscriptions.push(
      this.store.select(CollectionManagerSelectors.scrollVerticalPercentage).subscribe((percentage) => {
        this.verticalScrollPercentage = percentage;
      }),
    );

    this.subscriptions.push(
      this.store.select(CollectionManagerSelectors.scrollHorizontalPercentage).subscribe((hPercentage) => {
        this.horizontalScrollPercentage = hPercentage;
      }),
    );

    this.subscriptions.push(
      this.store.select(CollectionManagerSelectors.totalRowHeight).subscribe((totalRowHeight) => {
        this.totalRowHeight = totalRowHeight;
      }),
    );

    this.subscriptions.push(
      this.store.select(CollectionManagerSelectors.gridViewRowHeight).subscribe((gridViewRowHeight) => {
        this.gridViewRowHeight = gridViewRowHeight;
      }),
    );

    this.subscriptions.push(
      this.store.select(CollectionManagerSelectors.scrollableHeight).subscribe((scrollableHeight) => {
        this.scrollableHeight = scrollableHeight;
      }),
    );
    this.subscriptions.push(
      this.store.select(CollectionManagerSelectors.scrollRowMarginOffset).subscribe((margin) => {
        this.setMarginForElements(
          -1 * margin,
          elem.nativeElement.querySelectorAll('.vertically-scrollable'),
          'margin-top',
        );
      }),
    );

    this.subscriptions.push(
      this.store
        .select(CollectionManagerSelectors.rightPropertiesTotalColumnWidth)
        .subscribe((rightSideWidthNeeded) => {
          this.rightSideWidthNeeded = rightSideWidthNeeded;
        }),
    );

    this.subscriptions.push(
      this.store.select(CollectionManagerSelectors.scrollableWidth).subscribe((scrollableWidth) => {
        this.scrollableWidth = scrollableWidth;
      }),
    );
    this.subscriptions.push(
      this.store.select(CollectionManagerSelectors.scrollColumnMarginOffset).subscribe((margin) => {
        this.setMarginForElements(
          -margin,
          elem.nativeElement.querySelectorAll('.horizontally-scrollable'),
          'margin-left',
        );
      }),
    );

    this.subscriptions.push(
      this.store.select(CollectionManagerSelectors.gridViewColumnWidth).subscribe((gridViewColumnWidth) => {
        this.gridViewColumnWidth = gridViewColumnWidth;
      }),
    );
  }

  /** Handles a scroll event (mouse wheel). Triggers vertical or horizontal scrolling based
   * one what key modifiers are present.
   */
  public handleScrollEvent(event) {
    const path = event?.path || [];
    if (
      path.filter((ele) => {
        return ele.localName === 'app-side-panel' || ele.localName === 'app-menu' || ele.localName === 'app-chooser';
      }).length
    ) {
      return;
    }

    event.preventDefault();
    let horizontal = false;
    if (event.ctrlKey || event.altKey || event.shiftKey || Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
      horizontal = true;
    }
    if (horizontal) {
      const amount = event.deltaX || event.deltaY;
      this.scrollHorizontal(-amount, this.elem.nativeElement.querySelectorAll('.horizontally-scrollable'));
    } else {
      const amount = event.deltaY;
      this.scrollVertical(-amount, this.elem.nativeElement.querySelectorAll('.vertically-scrollable'));
    }
  }
  /** 'Scrolls a set of elements by a given delta distance.
   * Scrollign is actualy just offsetting the margin of the element correctly within a fixed view port.
   */
  private scrollVertical(delta, elements) {
    if (this.scrollableHeight <= 0) {
      return;
    }
    let multiplier = -1;
    if (delta < 0) {
      multiplier = 1;
    }
    const oneRowPercentage = this.gridViewRowHeight / this.scrollableHeight;
    // console.log('oneRowPercentage: ', oneRowPercentage, ' this.totalRowHeight ', this.totalRowHeight,  ' gridViewRowHeight ', this.gridViewRowHeight);
    let newPercentage = this.verticalScrollPercentage + oneRowPercentage * multiplier;
    newPercentage = newPercentage < 0 ? 0 : newPercentage;
    newPercentage = newPercentage > 1 ? 1 : newPercentage;
    //console.log(`scrollVertical :  delta ${delta} new percentage ${newPercentage}`);
    this.store.dispatch(
      CollectionManagerActions.setScrollVerticalPercentage({
        percentage: newPercentage,
      }),
    );
  }
  private scrollHorizontal(delta, elements, fromStore = false) {
    if (this.scrollableWidth <= 0) {
      return;
    }
    let multiplier = -1;
    if (delta < 0) {
      multiplier = 1;
    }
    const oneColumnPercentage = this.gridViewColumnWidth / this.rightSideWidthNeeded;
    let newPercentage = this.horizontalScrollPercentage + oneColumnPercentage * multiplier;

    const isUserScrollingLeft = newPercentage < this.horizontalScrollPercentage;
    if (isUserScrollingLeft && newPercentage < 0.005) {
      newPercentage = 0;
    }

    if (newPercentage >= 0 && newPercentage <= 1 && newPercentage !== this.horizontalScrollPercentage) {
      this.store.dispatch(
        CollectionManagerActions.setScrollHorizontalPercentage({
          percentage: newPercentage,
        }),
      );
    }
  }

  private setMarginForElements(marginVal, elements, marginType = 'margin-top') {
    elements.forEach((el) => {
      this.setMarginForElement(marginVal, el, marginType);
    });
  }
  private setMarginForElement(marginVal, element, marginType = 'margin-top') {
    this.renderer.setStyle(element, marginType, `${marginVal}px`);
  }

  unsubscribe() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
