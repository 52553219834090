import { createSelector } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionDataEntity } from '../collection-manager.service';
import {
  selectCollectionData,
  selectFilteredCollectionElements,
} from './collection-elements/collection-element.selectors';

export const hoveredEntityId = (state: RootStoreState.State) => state.collectionManager.hoveredEntityId;
export const selectedEntityIds = (state: RootStoreState.State) => state.collectionManager.selectedEntityIds;
export const remoteSelectedProperties = (state: RootStoreState.State) =>
  state.collectionManager.remoteSelectedProperties;
export const selectedProperty = (state: RootStoreState.State) => state.collectionManager.selectedProperty;
export const selectedElementLocation = (state: RootStoreState.State) => state.collectionManager.selectedElementLocation;
export const typeDefinitions = (state: RootStoreState.State) => state.collectionManager.typeDefinitions;
export const scrollVerticalPercentage = (state: RootStoreState.State) =>
  state.collectionManager.scrollVerticalPercentage;
export const gridViewRowHeight = (state: RootStoreState.State) => state.collectionManager.gridViewRowHeight;
export const sorts = (state: RootStoreState.State) => state.collectionManager.sorts;
export const filterDefinition = (state: RootStoreState.State) => state.collectionManager.filterDefinition;
export const data = (state: RootStoreState.State) => state.collectionManager.sorts;
export const gridTotalHeight = (state: RootStoreState.State) => state.collectionManager.gridTotalHeight;
export const collectionElementsLoaded = (state: RootStoreState.State) =>
  state.collectionManager.collectionElementsLoaded;
export const hideEmptyGroups = (state: RootStoreState.State) => state.collectionManager.hideEmptyGroups;
export const editorMode = (state: RootStoreState.State) => state.collectionManager.editorMode;
export const selectorActive = (state: RootStoreState.State) => state.collectionManager.selectorActive;
export const selectorKeyActive = (state: RootStoreState.State) => state.collectionManager.selectorKeyActive;
export const selectorCells = (state: RootStoreState.State) => state.collectionManager.selectorCells;
export const dragActiveRow = (state: RootStoreState.State) => state.collectionManager.dragActiveRow;
export const planRowOrder = (state: RootStoreState.State) => state.plans.planRowOrder;
export const cutCells = (state: RootStoreState.State) => state.collectionManager.cutCells;
export const copiedRows = (state: RootStoreState.State) => state.collectionManager.copiedRows;
export const anchorRowSelectorId = (state: RootStoreState.State) => state.collectionManager.anchorRowSelectorId;
export const searchReplaceCells = (state: RootStoreState.State) => state.collectionManager.searchReplaceCells;
export const activeSearchReplaceCell = (state: RootStoreState.State) => state.collectionManager.activeSearchReplaceCell;
export const fillAnchorCell = (state: RootStoreState.State) => state.collectionManager.fillAnchorCell;
export const fillCells = (state: RootStoreState.State) => state.collectionManager.fillCells;
export const currentViewDefinition = (state: RootStoreState.State) => state.collectionManager.currentViewDefinition;
export const sidePanelWidth = (state: RootStoreState.State) => state.collectionManager.sidePanelWidth;
export const statusMessageElement = (state: RootStoreState.State) => state.collectionManager.statusMessageElement;
export const focusedFamilyItem = (state: RootStoreState.State) => state.collectionManager.focusedItemFamily;
export const spreadValueInfo = (state: RootStoreState.State) => state.collectionManager.spreadValueInfo;
export const contextMenuActive = (state: RootStoreState.State) => state.collectionManager.contextMenuActive;
export const overrideOptionCells = (state: RootStoreState.State) => state.collectionManager.overrideOptionCells;

export * from './collection-manager-scroll-selectors';

export const allData = createSelector<RootStoreState.State, [Array<CollectionDataEntity>], Array<CollectionDataEntity>>(
  selectCollectionData,
  (dataSet: Array<CollectionDataEntity>) => {
    return dataSet;
  },
);

/** Data with filtered applied (but not focused item) */
export const filteredData = createSelector<
  RootStoreState.State,
  [Array<CollectionDataEntity>],
  Array<CollectionDataEntity>
>(selectFilteredCollectionElements, (dataSet: Array<CollectionDataEntity>) => {
  return dataSet;
});

export const displayedData = createSelector<
  RootStoreState.State,
  [Array<CollectionDataEntity>, any],
  Array<CollectionDataEntity>
>(filteredData, focusedFamilyItem, (dataSet: Array<CollectionDataEntity>, itemFamily) => {
  let filtered = dataSet;
  if (itemFamily) {
    filtered = filtered.filter((obj) => obj.itemFamilyId === itemFamily.id);
  }
  return filtered;
});

/** Size of the view port that is showing rows */
export const rowViewPortHeight = createSelector(gridTotalHeight, (totalHeight: number) => {
  const pageHeaderHeight = document.getElementsByTagName('app-header')[0]?.getBoundingClientRect().height || 62;
  const planHeaderBarHeight = document.getElementsByTagName('app-header-bar')[0]?.getBoundingClientRect().height || 50;
  const columnHeaderRowHeight =
    document.getElementsByTagName('app-header-row')[0]?.getBoundingClientRect().height || 27;
  const aggregateRowHeight =
    document.getElementsByTagName('app-aggregate-row')[0]?.getBoundingClientRect().height || 27;
  const scrollBarHeight =
    document.getElementsByTagName('app-horizontal-scroller')[0]?.getBoundingClientRect()?.height || 0;
  console.log(pageHeaderHeight, planHeaderBarHeight, columnHeaderRowHeight, aggregateRowHeight, scrollBarHeight);
  const fudgeFactor = 18; // outlines? margin?
  return (
    totalHeight -
    (pageHeaderHeight +
      planHeaderBarHeight +
      aggregateRowHeight +
      columnHeaderRowHeight +
      scrollBarHeight +
      fudgeFactor)
  );
});

/** Sum of total row count * row height. */
export const totalRowHeight = createSelector(
  displayedData,
  gridViewRowHeight,
  (dataSet: Array<CollectionDataEntity>, height: number) => {
    return (dataSet.length || 0) * height;
  },
);

/** The total amount of distance that can actually be scrolled.
 * We don't need to scroll the entire 'row height' as the last X rows
 * will be seen. If you scrolled all the way, you would move all rows out of the view port.
 */
export const scrollableHeight = createSelector(totalRowHeight, rowViewPortHeight, (total: number, viewPort: number) => {
  if (total > 0 && viewPort > 0) {
    return total - viewPort;
  }
  return 0;
});

/** Should answer the question, which row is at the top of the viewport.
 * This is somewhat complex...
 * - subtract the height of the rows that consume one view port (view port height / row height = 'bottom row height')
 * This equals 'hidden row count'
 */
export const scrollRowIndexStart = createSelector(
  scrollVerticalPercentage,
  rowViewPortHeight,
  gridViewRowHeight,
  totalRowHeight,
  (scrollVerticalPercentage, rowViewPortHeight, gridViewRowHeight, totalRowHeight) => {
    if (!scrollVerticalPercentage || !rowViewPortHeight || !gridViewRowHeight || !totalRowHeight) {
      return 0;
    }
    const hiddenVerticalHeight = totalRowHeight - rowViewPortHeight;
    const hiddenRowCount = hiddenVerticalHeight / gridViewRowHeight;
    let index = 0;
    index = (hiddenRowCount + 2) * scrollVerticalPercentage; // added 2 so that the last row is not half-hidden.
    index = Math.round(index);
    //console.log('===> scrollRowIndexStart: ', totalRowHeight, rowViewPortHeight, gridViewRowHeight, hiddenRowCount, scrollVerticalPercentage,  index);
    return index;
  },
);

/** Should answer the question: How far do I adjust the margin to get the row at the top of the view port.
 * This should be straight forward once we know what row index to use.  Row height * index.
 */
export const scrollRowMarginOffset = createSelector(scrollRowIndexStart, gridViewRowHeight, (rowIndex, rowHeight) => {
  let offSet = 0;
  offSet = rowIndex * rowHeight;
  return offSet;
});

/** How many rows should be rendered into the viewport? */
export const renderedRowCount = createSelector(rowViewPortHeight, gridViewRowHeight, (viewPortHeight, rowHeight) => {
  const count = Math.ceil(viewPortHeight / rowHeight);
  return count;
});
