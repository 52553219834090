import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, filter, take } from 'rxjs';
import { ViewDefinition } from '@contrail/client-views';
import { PlansActions, PlansSelectors } from '../plans/plans-store';
import { RootStoreState } from '../root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from './collection-manager-store';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { Intercom } from '@common/analytics/ng-intercom';
import { ClipboardActions } from '@common/clipboard/clipboard-store';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';

@Component({
  selector: 'app-collection-manager',
  templateUrl: './collection-manager.component.html',
  styleUrls: ['./collection-manager.component.scss'],
})
export class CollectionManagerComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<RootStoreState.State>,
    private activatedRoute: ActivatedRoute,
    private intercom: Intercom,
  ) {
    this.currentView$ = this.store.select(CollectionManagerSelectors.selectCurrentView);
    this.intercom.update({
      // hide the intercom
      hide_default_launcher: true,
    });
  }
  public planId: string;
  public currentView$: Observable<ViewDefinition>;
  private editorMode = 'VIEW';

  @ViewChild(ContextMenuComponent) contextMenu: ContextMenuComponent;

  private routeSub: Subscription;
  private editorModeSub: Subscription;
  sidePanelWidth$: Observable<number>;

  ngOnInit() {
    this.store.dispatch(CollectionManagerActions.loadTypeDefinitions());
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe() {
    this.routeSub = this.activatedRoute.params.subscribe((params) => {
      if (params.planId && this.planId !== params.planId) {
        this.planId = params.planId;
        this.store.dispatch(PlansActions.loadCurrentPlan({ id: this.planId }));
      }
    });

    this.store.dispatch(ClipboardActions.loadClipboardItems());

    this.editorModeSub = this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));

    this.sidePanelWidth$ = this.store.select(CollectionManagerSelectors.sidePanelWidth);
  }
  unsubscribe() {
    this.routeSub.unsubscribe();
    this.editorModeSub.unsubscribe();
  }

  onContextMenu($event) {
    this.contextMenu?.onContextMenu($event);
  }
}
