import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { PlansActions, PlansSelectors, RootStoreState } from 'src/app/root-store';
import { Plan } from '../plans-store/plans.state';
import { PlansService } from '../plans.service';

@Component({
  selector: 'app-plan-alternates',
  templateUrl: './plan-alternates.component.html',
  styleUrls: ['./plan-alternates.component.scss'],
})
export class PlanAlternatesComponent implements OnInit {
  public targetPlan: Plan;
  public alternatePlans$: Observable<Array<Plan>>;
  constructor(
    private store: Store<RootStoreState.State>,
    public dialogRef: MatDialogRef<PlanAlternatesComponent>,
    private planService: PlansService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.targetPlan = data.targetPlan;
  }

  ngOnInit() {
    this.alternatePlans$ = from(
      new Entities().get({
        entityName: 'plan',
        criteria: { targetAssortmentId: this.targetPlan.targetAssortmentId, isTrashed: false },
        relations: ['planSummary'],
      }),
    ).pipe(
      map((plans) => {
        return plans.filter((plan) => {
          return plan.id !== this.targetPlan.id && plan.targetAssortmentId === this.targetPlan.targetAssortmentId;
        });
      }),
      map((plans) => {
        return [this.targetPlan, ...plans];
      }),
    );
  }

  async createNew() {
    const plan: any = await this.planService.copy(this.targetPlan.name + ' Copy', this.targetPlan.id);
    this.store.dispatch(PlansActions.loadCurrentPlan({ id: plan.id }));
    this.dialogRef.close();
  }
}
