<div class="title">Plan Configuration</div>
<div data-test="plan-configuration-panel" class="details">
  <div class="property"><span class="label">Project:</span> {{ (selectedWorkspace$ | async)?.name }}</div>
  <div class="property" *ngIf="!locationLoading">
    <span class="label">Location:</span>
    <ng-container *ngFor="let f of folders; index as i">
      {{ f?.name }} <ng-container *ngIf="folders.length !== i + 1"> / </ng-container>
    </ng-container>
  </div>
  <div class="property"><span class="label">Target Assortment:</span> {{ plan?.targetAssortment?.name }}</div>

  <div class="property">
    <span class="label">Source Assortment:</span> {{ sourceAssortment?.name }}
    <mat-icon (click)="viewHistory()" [matTooltip]="'Assortment History'" *ngIf="sourceAssortment">history</mat-icon>
  </div>

  <div style="border-bottom: solid 1px darkgrey; height: 5px; margin: 6px 0px 12px 0px"></div>
  <table>
    <tr>
      <td>
        <span class="label">Created:</span>
      </td>
      <td>
        <div class="timestamp">{{ plan.createdOn | date: 'medium' }}</div>
        <div class="actor">{{ getActorName(plan?.createdBy) }}</div>
      </td>
    </tr>
    <tr>
      <td>
        <span class="label">Updated:</span>
      </td>
      <td>
        <div class="timestamp">{{ plan.updatedOn | date: 'medium' }}</div>
        <div class="actor">{{ getActorName(plan?.updatedBy) }}</div>
      </td>
    </tr>
  </table>
</div>
