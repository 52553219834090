import { Injectable } from '@angular/core';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { Store } from '@ngrx/store';
import { debounceTime, switchMap, take, tap } from 'rxjs/operators';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { combineLatest, Observable } from 'rxjs';
import { Types } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class FocusedItemFamilyService {
  data: any;
  selectedRows: Array<any>;
  viewProperties: Array<any>;
  currentViewProperties: Array<any>;
  focusedItemSearchCriteria$: Observable<any>;

  constructor(private store: Store<RootStoreState.State>) {
    this.setupBaseCriteria();
  }

  isFocusedItemFamilyAllowed(id) {
    let family;
    this.store
      .select(CollectionManagerSelectors.selectCollectionElement(id))
      .pipe(
        take(1),
        tap((row) => {
          family = row?.itemFamily;
        }),
      )
      .subscribe();
    if (!family) {
      return false;
    }
    return true;
  }

  handleFocusedItemFamily(id) {
    let family;
    let currentFocusedFamilyItem = null;
    combineLatest([
      this.store.select(CollectionManagerSelectors.focusedFamilyItem),
      this.store.select(CollectionManagerSelectors.selectCollectionElement(id)),
    ])
      .pipe(
        take(1),
        tap(([focusedFamilyItem, row]) => {
          currentFocusedFamilyItem = focusedFamilyItem;
          family = row?.itemFamily;
        }),
      )
      .subscribe();
    if (currentFocusedFamilyItem) {
      this.store.dispatch(CollectionManagerActions.setFocusedItemFamily({ itemFamily: null })); // toggle off
    } else {
      this.store.dispatch(CollectionManagerActions.setFocusedItemFamily({ itemFamily: family }));
    }
  }

  getFocusedItemFamilyPlaceholderIds() {
    let focusedItemFamilyData;
    combineLatest([
      this.store.select(CollectionManagerSelectors.focusedFamilyItem),
      this.store.select(CollectionManagerSelectors.filteredData),
    ])
      .pipe(
        take(1),
        tap(([focusedFamilyItem, data]) => {
          if (focusedFamilyItem) {
            focusedItemFamilyData = data
              .filter((row) => row.itemFamilyId === focusedFamilyItem.id)
              .map((row) => row.id);
          }
        }),
      )
      .subscribe();
    return focusedItemFamilyData;
  }

  private setupBaseCriteria() {
    this.focusedItemSearchCriteria$ = combineLatest([
      this.store.select(CollectionManagerSelectors.selectedEntityIds),
      this.store.select(PlansSelectors.currentPlan),
      this.store.select(CollectionManagerSelectors.focusedFamilyItem),
      this.store.select(CollectionManagerSelectors.displayedData),
    ]).pipe(
      debounceTime(1000),
      switchMap(
        async ([selectedEntityIds, currentPlan, focusedItemFamily, displayedData]: [
          Array<string>,
          any,
          any,
          Array<any>,
        ]) => {
          let newBaseCriteria: any = { roles: 'option', isPrimary: true };
          if (focusedItemFamily) {
            newBaseCriteria = {
              itemFamilyId: focusedItemFamily.id,
              roles: 'option',
              isPrimary: true,
            };
          } else {
            if (selectedEntityIds.length === 1) {
              // remove this check when query can handle multiple itemFamilyIds
              const filteredEntities = displayedData
                .filter((entity) => entity.itemFamilyId && selectedEntityIds.includes(entity.id))
                .map((entity) => entity.itemFamilyId);
              if (filteredEntities.length > 0) {
                newBaseCriteria = {
                  itemFamilyId: filteredEntities.join(','),
                  roles: 'option',
                  isPrimary: true,
                };
              }
            }
          }

          return newBaseCriteria;
        },
      ),
    );
  }
}
