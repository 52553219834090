import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-search-replace',
  templateUrl: './search-replace.component.html',
  styleUrls: ['./search-replace.component.scss'],
  animations: [
    trigger('focusInOut', [
      state(
        'in',
        style({
          width: '400px',
        }),
      ),
      state(
        'out',
        style({
          width: '200px',
        }),
      ),
      transition('in => out', animate('300ms ease-in-out')),
      transition('out => in', animate('200ms ease-in-out')),
    ]),
  ],
})
export class SearchReplaceComponent implements AfterViewInit {
  @ViewChild('inputField') inputField: ElementRef;
  @Input() searchReplaceCells: Array<any>;
  @Output() searchHandler = new EventEmitter<string>();
  @Output() setActiveCell = new EventEmitter<any>();
  @Output() closePanel = new EventEmitter<boolean>();
  @Output() openPanel = new EventEmitter<boolean>();

  searchInput = new UntypedFormControl();
  private sub: Subscription;
  public currentCellIndex = 0;
  focus: 'in' | 'out' = 'out';
  focusing = true; // 200ms animation delay

  constructor() {}

  ngAfterViewInit(): void {
    this.sub = this.searchInput.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((text: string) => {
          this.currentCellIndex = 0;
          this.searchHandler.emit(text);
        }),
      )
      .subscribe();
    this.inputField.nativeElement.focus();
  }

  close() {
    this.closePanel.emit();
    this.searchInput.setValue('');
    this.currentCellIndex = 0;
    setTimeout(() => {
      this.focusing = true;
    }, 250);
    this.focus = 'out';
  }

  previousCell() {
    this.currentCellIndex === 0
      ? (this.currentCellIndex = this.searchReplaceCells.length - 1)
      : this.currentCellIndex--;
    this.setActiveCell.emit(this.searchReplaceCells[this.currentCellIndex]);
  }

  nextCell() {
    this.currentCellIndex === this.searchReplaceCells.length - 1
      ? (this.currentCellIndex = 0)
      : this.currentCellIndex++;
    this.setActiveCell.emit(this.searchReplaceCells[this.currentCellIndex]);
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent = null) {
    if (this.focus === 'in') {
      this.nextCell();
      event.stopPropagation();
    }
  }

  onBlur() {
    if (!this.searchInput.value) {
      this.close();
    }
  }

  onFocus() {
    this.focus = 'in';
    setTimeout(() => {
      this.focusing = false;
    }, 250);
    this.openPanel.emit();
  }

  toggleFocus() {
    if (this.focus === 'in') {
      this.close();
    } else {
      this.inputField.nativeElement.focus();
    }
  }
}
