import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
  OnDestroy,
  AfterViewInit,
  OnChanges,
  ElementRef,
} from '@angular/core';
import { SearchBarComponent } from '@common/components/search-bar/search-bar.component';
import { SortDefinition, SortDirection } from '@common/components/sort/sort-definition';
import { ChooserFilterConfig } from '@common/item-data-chooser/chooser-sources/item-data-chooser-data-source';
import { FilterCriteria } from '@contrail/filters';
import { FilterDefinition, FilterPropertyDefinition } from '@common/types/filters/filter-definition';
import { Types } from '@contrail/sdk';
import { PropertyType, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { Observable, BehaviorSubject, Subscription, Subject, combineLatest } from 'rxjs';
import { tap, startWith, distinctUntilChanged } from 'rxjs/operators';
import { ChooserDataSource } from './chooser-source/chooser-data-source';
import { LibraryChooserDataSource } from './chooser-source/library-chooser-data-source';
import { ChooserSourceOption } from './chooser-source/source-option';
import { LibraryChooserPaginatedDataSource } from './chooser-source/library-chooser-paginated-data-source';

const DEFAULT_SORTS: SortDefinition[] = [
  { direction: SortDirection.ASCENDING, propertySlug: 'name', propertyType: PropertyType.String },
];

@Component({
  selector: 'app-chooser',
  templateUrl: './chooser.component.html',
  styleUrls: ['./chooser.component.scss'],
})
export class ChooserComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() context: { [key: string]: any }; // The context is the contextual object for which you are seaching within (a PPH for example)
  @Input() resultsHeight = '300px';
  @Input() draggable = true;
  @Input() existingIds: Set<string>;
  @Input() showSearchBox = true;
  @Input() showAllControl = true;
  @Input() showCount = true;
  @Input() showHeader = true;
  @Input() showFilter = true;
  @Input() allowAddMultipleEntities = false;
  @Input() criteria: any = {};
  @Input() QUICK_SEARCH_OPTIONS = 'name';
  @Input() filterTemplateName = '';
  @Input() viewTemplateName = '';
  @Input() allowAddEntity = false;
  @Input() allowAddDuplicate = true;
  @Input() allowCreateNew = false;
  @Input() enableInfiniteScroll = false;

  @Input() entityType: string;
  @Input() typePath: string;

  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @ViewChild(VirtualScrollerComponent) virtualScroller: VirtualScrollerComponent;

  private dataSub: Subscription;
  public data: Array<any>;
  public filteredResults$ = new Subject<Array<any>>();
  public filterDefinition: FilterDefinition;
  private filterDefinitionSubject: BehaviorSubject<FilterDefinition> = new BehaviorSubject(null);
  private sortConfigSubject: BehaviorSubject<SortDefinition[]> = new BehaviorSubject(null);
  public dateFilter: FilterDefinition;
  private dateFilterSubject: BehaviorSubject<FilterDefinition> = new BehaviorSubject(null);
  public count = -1;
  public showAllSubject = new BehaviorSubject(true);
  public existingItemIdsSubject = new BehaviorSubject(new Set());
  public showSelectSource = false;
  public sourceAssortment: any;
  public dataSource: any;
  public dataSourceType: string;
  private chooserSourceSubscription: Subscription;
  private filterConfigSubject: BehaviorSubject<ChooserFilterConfig> = new BehaviorSubject(null);
  public selectedItems: any[] = [];
  public selectAll = false;
  public numberOfEligibleResults = 0;
  public sortProperties: Array<SortDefinition>;
  public currentSorts: Array<SortDefinition> = [];
  public showAll = true;
  public dateFilterAttribute = {
    label: 'Recently added',
    attribute: 'createdOn',
  };
  public chooserSourceOption$: BehaviorSubject<ChooserSourceOption> = new BehaviorSubject(null);
  public searchBarSubscription: Subscription;
  activeEntityId: string;

  @Output() entitiesSelected = new EventEmitter();
  @Output() close = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.dataSub.unsubscribe();
    this.chooserSourceSubscription.unsubscribe();
    this.searchBarSubscription.unsubscribe();
  }

  ngOnChanges(changes: any) {
    this.existingItemIdsSubject.next(this.existingIds);
    if (changes.criteria) {
      if (ObjectUtil.compareDeep(changes.criteria.currentValue, changes.criteria.previousValue, '').length > 0) {
        this.initFilterObservable();
      }
    }
  }

  ngAfterViewInit(): void {
    this.initResultsObservable();
    this.chooserSourceOption$.next({
      sourceType: 'LIBRARY',
      name: 'Library',
    });
    this.initFilterObservable();
    this.searchBar.focus();
  }

  @HostListener('document:keydown', ['$event'])
  keyEvent(keyEvent: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp'].includes(keyEvent.code)) {
      keyEvent.stopPropagation();
      keyEvent.preventDefault();
      let index = -1;
      if (this.data?.length === 0) {
        return;
      }
      if (!this.activeEntityId) {
        this.activeEntityId = this.data[0].id;
      } else {
        index = this.data.findIndex((item) => item.id === this.activeEntityId);
      }
      keyEvent.code === 'ArrowDown' ? index++ : index--;

      if (this.data[index]) {
        this.searchBar.blur();
        this.activeEntityId = this.data[index].id;
        this.virtualScroller.scrollToIndex(index);
      } else {
        this.virtualScroller.scrollToIndex(0);
        this.searchBar.focus();
        this.activeEntityId = null;
      }
    } else if (keyEvent.code === 'Enter' && this.activeEntityId) {
      keyEvent.stopPropagation();
      const selectedEntity = this.data.find((entity) => entity.id === this.activeEntityId);
      this.handleEntityClicked(selectedEntity);
    } else if (keyEvent.code === 'Escape') {
      this.handleClose();
    }
  }

  @HostListener('mousewheel', ['$event']) // for window scroll events
  onMouseWheel(event) {
    event.stopPropagation();
  }

  /**
   * Sets up filter definition & sort properties needed for the UI components
   * @param sourceType
   */
  async initFilterDefinition(sourceType: string) {
    this.dataSourceType = sourceType;
    let filterProperties: Array<TypeProperty> = [];
    if (this.entityType) {
      const type = await new Types().getType({ path: this.entityType, root: this.entityType });
      filterProperties = [...type?.typeProperties];
    }

    filterProperties = filterProperties.sort((p1, p2) => (p1.label < p2.label ? -1 : 1));
    this.filterDefinition = {
      filterPropertyDefinitions: filterProperties as Array<FilterPropertyDefinition>,
      filterCriteria: {
        propertyCriteria: [],
      },
    };
    this.sortProperties = filterProperties.map((property) => {
      return {
        propertySlug: property.slug,
        propertyLabel: property.label,
        propertyType: property.propertyType,
        direction: SortDirection.ASCENDING,
      };
    });
  }

  initResultsObservable(): void {
    /**
     * Respond to changes in chooser source.  Create a new data source based on the selection.
     * Each data source is responsible for fetching & filtering data as appropriate based
     * on changes in the choosers 'filter config', which includes the search term and filter definition.
     */
    this.chooserSourceSubscription = this.chooserSourceOption$.subscribe((chooserSourceOption) => {
      console.log('ChooserComponent: chooserSourceOption change: ', chooserSourceOption);
      if (!chooserSourceOption) {
        return;
      }

      this.dataSource?.cleanUp();
      this.dataSub?.unsubscribe();
      delete this.dataSource;
      if (chooserSourceOption.sourceType === 'LIBRARY') {
        console.log('ChooserComponent: creating LibraryDataSource');
        if (this.enableInfiniteScroll) {
          this.dataSource = new LibraryChooserPaginatedDataSource(
            this.entityType,
            this.typePath,
            this.filterConfigSubject,
            this.sortConfigSubject,
            this.existingItemIdsSubject,
            this.showAllSubject,
            this.context,
          );
        } else {
          this.dataSource = new LibraryChooserDataSource(
            this.entityType,
            this.typePath,
            this.filterConfigSubject,
            this.sortConfigSubject,
            this.existingItemIdsSubject,
            this.showAllSubject,
            this.context,
          );
        }
      }
      if (this.dataSource) {
        this.dataSub = this.dataSource.results$.subscribe((results) => {
          this.data = results;
          this.numberOfEligibleResults = this.data.filter((item) => !this.testExisting(item)).length;
          this.selectedItems = [];
          this.activeEntityId = null;
        });
      }
      this.initFilterDefinition(chooserSourceOption.sourceType);
      this.sortData();
    });
  }

  handleScrollEnd(event) {
    const isScrollAtEndOfContent =
      this.data?.length > 0 && event.endIndex > 0 && event.endIndex === this.data.length - 1;
    if (this.enableInfiniteScroll && isScrollAtEndOfContent) {
      this.dataSource.getMorePaginatedResults();
    }
  }

  initFilterObservable() {
    if (this.searchBar) {
      this.searchBarSubscription = combineLatest([
        this.searchBar.valueChange.pipe(startWith(''), distinctUntilChanged()),
        this.filterDefinitionSubject.asObservable(),
      ])
        .pipe(
          tap(async ([searchTerm, filterDefinition]) => {
            const filterConfig = {
              searchTerm,
              filterDefinition,
              baseCriteria: this.criteria,
            };
            console.log('Chooser.... filterChange: ', filterConfig, this.dataSource);
            this.selectedItems = [];
            this.filterConfigSubject.next(filterConfig);
          }),
        )
        .subscribe();
    }
  }

  testExisting(entity) {
    if (this.existingIds) {
      return this.existingIds.has(entity.id);
    }
  }
  handleEntityClicked(entity) {
    this.entitiesSelected.emit([entity]);
  }

  handleClose() {
    this.close.emit();
  }

  sortData() {
    const sorts = this.currentSorts.length > 0 ? this.currentSorts : DEFAULT_SORTS;
    this.sortConfigSubject.next(sorts);
  }

  trackByFn(index, entity: any) {
    return entity.id;
  }

  toggleSourceSourceSelector(val) {
    this.searchBarSubscription.unsubscribe();
    this.showSelectSource = val;
    setTimeout(() => {
      this.initFilterObservable();
    }, 1);
  }

  setFilterCriteria(filterCriteria: FilterCriteria) {
    if (filterCriteria) {
      this.filterDefinition.filterCriteria = filterCriteria;
      this.filterDefinitionSubject.next(this.filterDefinition);
    }
  }
  clearFilters() {
    this.filterDefinition.filterCriteria.propertyCriteria = [];
    this.filterDefinitionSubject.next(this.filterDefinition);
    this.searchBar.clear();
  }

  performSort(event) {
    this.currentSorts = event.sorts;
    this.sortData();
  }
  handleToggleSelectAll() {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.selectedItems = [];
      this.data.forEach((item) => {
        if (!this.testExisting(item)) {
          this.selectedItems.push(ObjectUtil.cloneDeep(item));
        }
      });
    } else {
      this.selectedItems = [];
    }
  }
  toggleShowAll(event) {
    this.showAll = !event.checked;
    this.showAllSubject.next(!event.checked);
  }
  dateFilterChanged(dateFilter) {
    this.dateFilter = dateFilter;
    this.dateFilterSubject.next(dateFilter);
  }

  handleCreateNew() {
    console.log('Creating new...');
  }
}
