<div class="flex items-center h-9 relative non-selectable" [@focusInOut]="focus">
  <span class="material-icons absolute left-2 pointer-events-none text-black24">search</span>
  <input
    class="!w-full !h-full bg-neutral-50 rounded-3xl px-10"
    [ngClass]="{ 'border bg-white': focus === 'in' }"
    data-test="app-search-replace-input"
    placeholder="Search in plan"
    (focus)="onFocus()"
    (blur)="onBlur()"
    #inputField
    [formControl]="searchInput"
  />

  <div class="flex items-center justify-end absolute right-2 min-w-[40px] h-10 cursor-pointer z-50">
    <span
      *ngIf="searchInput.value"
      data-test="app-search-replace-previous-button"
      (click)="previousCell()"
      class="select-none material-icons text-black24 hover:text-black60"
    >
      expand_less
    </span>
    <span *ngIf="searchInput.value" data-test="app-search-replace-count-detail" class="text-[10px] text-black54">
      {{ searchReplaceCells.length ? currentCellIndex + 1 : 0 }} of {{ searchReplaceCells.length }}
    </span>
    <span
      *ngIf="searchInput.value"
      data-test="app-search-replace-next-button"
      (click)="nextCell()"
      class="select-none material-icons text-black24 hover:text-black60"
    >
      expand_more
    </span>
    <span
      *ngIf="searchInput.value || focus === 'in'"
      data-test="app-search-replace-close-button"
      (click)="close()"
      class="material-icons text-black24 hover:text-black60"
    >
      close
    </span>
  </div>
</div>
