import { Injectable } from '@angular/core';
import { PropertyType } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { FilterConditionType, FilterConditionTypePropertyTypeMap } from '@contrail/filters';
import { FilterPropertyDefinition, FilterDefinition } from '@common/types/filters/filter-definition';
import { map, take, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { ViewDefinition } from '@contrail/client-views';
import { SizeRangeHelper } from '@common/size-range/size-range-helper';
import { CollectionManagerViewService } from '../collection-manager-view.service';
import { AuthSelectors } from '@common/auth/auth-store';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  currentView: ViewDefinition;
  currentViewProperties: any[];
  selectorCells: any[];
  selectedEntityIds: any[];
  propertyTypeDefaultFilterConditions: any = {};

  constructor(
    private store: Store<RootStoreState.State>,
    private viewService: CollectionManagerViewService,
  ) {
    this.store
      .select(CollectionManagerSelectors.selectCurrentView)
      .subscribe((currentView) => (this.currentView = currentView));
    this.store.select(CollectionManagerSelectors.currentViewProperties).subscribe((currentViewProperties) => {
      this.currentViewProperties = currentViewProperties;
    });
    this.store
      .select(CollectionManagerSelectors.selectorCells)
      .subscribe((selectorCells) => (this.selectorCells = selectorCells));
    this.store
      .select(CollectionManagerSelectors.selectedEntityIds)
      .subscribe((selectedEntityIds) => (this.selectedEntityIds = selectedEntityIds));
    this.store.select(AuthSelectors.currentOrg).subscribe((org) => {
      if (org.orgConfig?.propertyTypeDefaultFilterConditions) {
        this.propertyTypeDefaultFilterConditions = org.orgConfig.propertyTypeDefaultFilterConditions;
      }
    });
  }

  addFilter(propertySlug: string) {
    const property = this.currentViewProperties?.find((viewProperty) => viewProperty.slug === propertySlug) || {
      propertyDefinition: this.viewService.alertProperty,
    };
    const propType = property.propertyDefinition?.propertyType;
    const propertyFilterConditionTypeMap = FilterConditionTypePropertyTypeMap.get(propType);
    let filterConditionType: FilterConditionType = propertyFilterConditionTypeMap
      ? propertyFilterConditionTypeMap[0]
      : FilterConditionType.EQUALS;
    if (this.propertyTypeDefaultFilterConditions[propType]) {
      filterConditionType = this.propertyTypeDefaultFilterConditions[propType];
    }
    const filterPropertyDefinition: FilterPropertyDefinition = ObjectUtil.cloneDeep(property.propertyDefinition);
    this.store
      .select(CollectionManagerSelectors.filterDefinition)
      .pipe(
        take(1),
        tap((currentfilterDefinition) => {
          const newDefinition: FilterDefinition = ObjectUtil.cloneDeep(currentfilterDefinition);
          newDefinition.filterCriteria.propertyCriteria.push({ filterPropertyDefinition, filterConditionType });
          this.store.dispatch(CollectionManagerActions.setFilterDefinition({ filterDefinition: newDefinition }));
          this.store.dispatch(CollectionManagerActions.setScrollVerticalPercentage({ percentage: 0 }));
        }),
      )
      .subscribe();
  }

  applyQuickFilter(propertySlug: string, value?: any, conditionType?: FilterConditionType) {
    const property = this.currentViewProperties.find((viewProperty) => viewProperty.slug === propertySlug);
    const propType = property.propertyDefinition?.propertyType;
    const propertyFilterConditionTypeMap = FilterConditionTypePropertyTypeMap.get(propType);
    let filterConditionType: FilterConditionType = propertyFilterConditionTypeMap
      ? propertyFilterConditionTypeMap[0]
      : FilterConditionType.EQUALS;
    let criteriaValue;
    switch (propType) {
      case 'boolean':
        criteriaValue = value ? true : false;
        break;
      case 'size_range':
        criteriaValue = value ? SizeRangeHelper.getSizeRangeSizesList(value) : value;
        break;
      default:
        filterConditionType = value ? FilterConditionType.EQUALS : FilterConditionType.IS_EMPTY;
        criteriaValue = value;
    }
    if (conditionType) {
      filterConditionType = conditionType;
    }
    const filterPropertyDefinition: FilterPropertyDefinition = ObjectUtil.cloneDeep(property.propertyDefinition);
    this.store
      .select(CollectionManagerSelectors.filterDefinition)
      .pipe(
        take(1),
        tap((currentfilterDefinition) => {
          const newDefinition: FilterDefinition = ObjectUtil.cloneDeep(currentfilterDefinition);
          if (
            [PropertyType.ObjectReference, PropertyType.ObjectReferenceList, PropertyType.UserList].includes(
              filterPropertyDefinition?.propertyType,
            )
          ) {
            filterPropertyDefinition.referencedObjName =
              filterPropertyDefinition.slug === 'itemOption' ? 'optionName' : 'name';
            if (criteriaValue) {
              if ([FilterConditionType.IS_ANY_OF, FilterConditionType.IS_NONE_OF].includes(filterConditionType)) {
                criteriaValue = [criteriaValue.id];
              } else {
                if (PropertyType.UserList === filterPropertyDefinition.propertyType) {
                  criteriaValue = criteriaValue['email'];
                } else {
                  criteriaValue =
                    criteriaValue[filterPropertyDefinition.referencedObjName || filterPropertyDefinition.slug];
                }
              }
            }
          }
          newDefinition.filterCriteria.propertyCriteria.push({
            filterPropertyDefinition,
            filterConditionType,
            criteriaValue,
          });
          this.store.dispatch(CollectionManagerActions.setFilterDefinition({ filterDefinition: newDefinition }));
          this.store.dispatch(CollectionManagerActions.setScrollVerticalPercentage({ percentage: 0 }));
          this.store.dispatch(
            CollectionManagerActions.updateViewDefinition({
              id: this.currentView.id,
              changes: { filterCriteria: newDefinition.filterCriteria },
            }),
          );
        }),
      )
      .subscribe();
  }

  isQuickFilterAllowed(propertySlug: any) {
    const currentProperty = this.currentViewProperties.find((property) => property.slug === propertySlug);
    if (currentProperty?.propertyDefinition?.propertyType === 'image') {
      // do not show for image property
      return false;
    }
    if (this.selectedEntityIds?.length > 0) {
      // do not show if rows are selected
      return false;
    }

    if (this.selectorCells?.length > 0) {
      // do not show if multiple cells are selected
      return false;
    }

    return true;
  }

  isPropertyFiltered(propertySlug: string) {
    return this.store.select(CollectionManagerSelectors.filterDefinition).pipe(
      map((filterDefinition) => {
        return (
          filterDefinition?.filterCriteria.propertyCriteria.findIndex(
            (propertyCriteria) =>
              propertyCriteria?.filterPropertyDefinition?.slug &&
              propertyCriteria.filterPropertyDefinition.slug === propertySlug,
          ) > -1
        );
      }),
    );
  }

  removeAllFilters() {
    this.store
      .select(CollectionManagerSelectors.filterDefinition)
      .pipe(
        take(1),
        tap((currentfilterDefinition) => {
          const newDefinition: FilterDefinition = ObjectUtil.cloneDeep(currentfilterDefinition);
          newDefinition.filterCriteria.propertyCriteria = [];
          this.store.dispatch(CollectionManagerActions.setFilterDefinition({ filterDefinition: newDefinition }));
          this.store.dispatch(CollectionManagerActions.setScrollVerticalPercentage({ percentage: 0 }));
          this.store.dispatch(
            CollectionManagerActions.updateViewDefinition({
              id: this.currentView.id,
              changes: { filterCriteria: { propertyCriteria: [] } },
            }),
          );
        }),
      )
      .subscribe();
  }

  removeFilter(propertySlug: string) {
    this.store
      .select(CollectionManagerSelectors.filterDefinition)
      .pipe(
        take(1),
        tap((currentfilterDefinition) => {
          const newDefinition: FilterDefinition = ObjectUtil.cloneDeep(currentfilterDefinition);
          const criteriaIndex = newDefinition.filterCriteria.propertyCriteria.findIndex(
            (propertyCriteria) => propertyCriteria.filterPropertyDefinition.slug === propertySlug,
          );
          newDefinition.filterCriteria.propertyCriteria.splice(criteriaIndex, 1);
          this.store.dispatch(CollectionManagerActions.setFilterDefinition({ filterDefinition: newDefinition }));
          this.store.dispatch(CollectionManagerActions.setScrollVerticalPercentage({ percentage: 0 }));
          this.store.dispatch(
            CollectionManagerActions.updateViewDefinition({
              id: this.currentView.id,
              changes: { filterCriteria: newDefinition.filterCriteria },
            }),
          );
        }),
      )
      .subscribe();
  }
}
