import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { buffer, debounceTime } from 'rxjs/operators';
import { RootStoreState } from '../root-store';
import { Plan, PlanRowOrder } from './plans-store/plans.state';

export interface PlanRowOrderChanges {
  id: string;
  changes: any;
}

const DEBOUNCE_TIME = 1000;

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  // Setting ReplaySubject with a buffer size of 1 will make it behave like BehaviorSubject without the initialized
  // "null" value.  No value is emitted until the first next function is called.
  private updateRequestSubject: ReplaySubject<PlanRowOrderChanges> = new ReplaySubject(1);

  constructor(private store: Store<RootStoreState.State>) {
    this.debounceUpdatePlanRowOrder();
  }

  public async getPlans(workspaceId?: string) {
    return new Entities().get({
      entityName: 'plan',
      criteria: { rootWorkspaceId: workspaceId, isArchived: false, isTrashed: false },
      relations: ['updatedBy', 'createdBy', 'targetAssortment'],
    });
  }
  public async getPlanById(id: string) {
    console.log('getPlanById', id);
    return new Entities().get({
      entityName: 'plan',
      id,
      relations: [
        'updatedBy',
        'targetAssortment',
        'targetAssortment.sourceAssortments',
        'planSummary',
        'workspace',
        'planGoals',
        'planRowOrder',
      ],
    });
  }
  public async createPlan(plan: Plan) {
    return new Entities().create({ entityName: 'plan', object: plan });
  }
  public async deletePlan(plan: Plan) {
    await new Entities().delete({ entityName: 'plan', id: plan.id });
    return plan;
  }
  public async deletePlanById(id: string) {
    await new Entities().delete({ entityName: 'plan', id });
  }
  public async updatePlan(id: string, changes: Plan) {
    return new Entities().update({ entityName: 'plan', id, object: changes });
  }

  async copy(name, id): Promise<Plan> {
    return new Entities().create({ entityName: 'plan', object: { name }, id, relation: 'copy' });
  }

  public async asyncCopyPlan(name, id) {
    return await new Entities().create({
      entityName: 'plan',
      object: { name, planId: id },
      relation: 'copy',
      suffix: 'async',
    });
  }

  public async createPlanRowOrder(planRowOrder: PlanRowOrder) {
    return new Entities().create({ entityName: 'plan-row-order', object: planRowOrder });
  }

  public async updatePlanRowOrder(id: string, changes: any) {
    return new Entities().update({ entityName: 'plan-row-order', id, object: changes });
  }

  public async updatePlanRowOrderWithDebounce(id: string, changes: any) {
    this.updateRequestSubject.next({ id, changes });
  }

  private async combineUpdatePlanRowOrderRequests(planRowOrderChanges: Array<PlanRowOrderChanges>) {
    // Combine requests for multiple rows
    let allRowIds = [];
    planRowOrderChanges.forEach((rowOrderChange) => {
      allRowIds = allRowIds.concat(rowOrderChange.changes.rowIds);
    });
    const planRowOrderId = planRowOrderChanges[0].id;
    const targetRowIndex = planRowOrderChanges[0].changes.targetRowIndex;
    return await this.updatePlanRowOrder(planRowOrderId, { rowIds: allRowIds, targetRowIndex });
  }

  private async debounceUpdatePlanRowOrder() {
    // Observable that only emits a value if no additional value is added for DEBOUNCE_TIME
    const debounceAndAddRowChanges$ = this.updateRequestSubject.pipe(debounceTime(DEBOUNCE_TIME));
    // Observable which buffers any emitted values until debounceAndAddRowChanges$ emits a value and closes the buffer
    const addRowChanges$ = this.updateRequestSubject.pipe(buffer(debounceAndAddRowChanges$));

    addRowChanges$.subscribe((rowOrderChanges) => {
      this.combineUpdatePlanRowOrderRequests(rowOrderChanges);
    });
  }
}
