import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { AuthSelectors } from '@common/auth/auth-store';
import { TypeFormChange } from '@common/types/forms/type-form-change';
import { Entities, Types } from '@contrail/sdk';
import { PropertyType, Type, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { take, tap } from 'rxjs/operators';
import { EntityPropertiesFormComponent } from '../entity-properties-form/entity-properties-form.component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { EntityFormHelper } from '../entity-form-helper';

@Component({
  selector: 'app-entity-details',
  templateUrl: './entity-details.component.html',
  styleUrls: ['./entity-details.component.scss'],
})
export class EntityDetailsComponent implements OnChanges, AfterViewInit {
  @ViewChildren(EntityPropertiesFormComponent) entityFormQuery: QueryList<EntityPropertiesFormComponent>;
  private propertyForm: EntityPropertiesFormComponent;

  @Input() entity: any;
  @Input() entityReference: string;
  @Output() cancelView = new EventEmitter();
  @Output() updated = new EventEmitter();
  @Input() accessLevel = 'EDIT';
  private objRefProperties: Array<TypeProperty>;
  private originalEntity;
  public errors: any = {};
  public type: Type;
  public hasStatus: boolean = false;
  public statusClass: string = '';
  public statusValue: string = '';

  public content: any;
  public contentEditable = true;
  public navOptions: Array<any> = [
    { label: 'Details', slug: 'details' },
    // { label: 'Content', slug: 'content' },
    // { label: 'History', slug: 'history' },
    // { label: 'Feedback', slug: 'feedback' },
  ];
  public currentNavOption: any = this.navOptions[0];
  public changes: any = {};
  public changesForUpdate: any = {};
  constructor(
    private ref: ChangeDetectorRef,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) {}

  async ngOnChanges() {
    if (this.entityReference) {
      await this.loadEntity(this.entityReference);
    }
  }
  async loadEntity(entityReference: string) {
    this.errors = {};
    this.entity = await new Entities().get({ reference: entityReference, relations: ['updatedBy', 'createdBy'] });
    this.originalEntity = ObjectUtil.cloneDeep(this.entity);
    this.entity.name = this.entity.name ?? this.entity.fileName;

    this.type = await new Types().getType({ id: this.entity.typeId });
    this.objRefProperties = this.type.typeProperties.filter((property) =>
      [PropertyType.ObjectReference, PropertyType.ObjectReferenceList, PropertyType.UserList].includes(
        property.propertyType,
      ),
    );
    this.errors = await EntityFormHelper.validatePropertyValues(this.type, this.entity, null);
    if (Object.keys(this.errors).length > 0) {
      this.snackBar.open('There are errors in this item. Please fix the errors.', '', { duration: 4000 });
    }

    const statusProperty = this.type.typeProperties.find((p) => p?.slug === 'colorStatus' || p?.slug === 'assetStatus'); //TODO: check the admin app types
    this.hasStatus = statusProperty ? true : false;
    const status = this.entity['colorStatus'] || this.entity['assetStatus']; //TODO: Admin app types
    if (statusProperty) {
      if (status === 'released') {
        this.statusClass = 'bg-success-dark';
        this.statusValue = 'Released';
      } else if (status === 'development') {
        this.statusClass = 'bg-accent';
        this.statusValue = 'Development';
      } else {
        // 'concept'
        this.statusClass = 'bg-warn-light';
        this.statusValue = 'Concept';
      }
    }
  }

  ngAfterViewInit() {
    if (this.entityFormQuery.toArray().length) {
      this.subscribeToForm(this.entityFormQuery.toArray()[0]);
    }
    this.entityFormQuery.changes.subscribe((formQuery: QueryList<EntityPropertiesFormComponent>) => {
      const results = formQuery.toArray();
      if (results.length > 0) {
        this.subscribeToForm(results[0]);
      }
    });

    this.store
      .select(AuthSelectors.selectAuthContext)
      .pipe(
        take(1),
        tap((context) => {
          // Short term rule for converse. TODO
          // This could be later replaced with something that checks for
          // editability of a 'relationship' property definition on the item type.
          if (context.currentOrg.orgSlug.indexOf('converse') > -1) {
            this.contentEditable = false;
          }
        }),
      )
      .subscribe();
  }
  private subscribeToForm(form: EntityPropertiesFormComponent) {
    this.propertyForm = form;
    this.propertyForm.changes.subscribe((formChanges) => this.updateEntityValues(formChanges));
  }
  cancel() {
    this.cancelView.emit();
  }

  isValid() {}
  async updateEntityValues(formChange: TypeFormChange) {
    console.log('EntityDetailComponent: updateEntityValues: ', formChange);
    const changesForUpdate = {};
    if (this.objRefProperties.find((prop) => prop.slug === formChange.propertySlug)) {
      changesForUpdate[formChange.propertySlug + 'Id'] = formChange.value.id || null;
      // changesForUpdate[formChange.propertySlug] = formChange.value.id ? formChange.value : null;
    } else {
      changesForUpdate[formChange.propertySlug] = formChange.value;
    }
    const changes = {};
    // object-reference property
    changes[formChange.propertySlug] = formChange.value;
    if (
      formChange.value &&
      typeof formChange.value === 'object' &&
      formChange.value.hasOwnProperty(formChange.propertySlug + 'Id')
    ) {
      changes[formChange.propertySlug + 'Id'] = formChange.value[formChange.propertySlug + 'Id'];
    }
    // APPLY CHANGES LOCALLY
    this.entity = Object.assign({}, this.entity, changes);
    const entityIsDifferent =
      ObjectUtil.compareDeep(ObjectUtil.cloneDeep(this.entity), this.originalEntity, '').length > 0;

    this.changes = Object.assign(this.changes, changes);
    this.changesForUpdate = Object.assign(this.changesForUpdate, changesForUpdate);
    this.errors = await EntityFormHelper.validatePropertyValues(this.type, this.entity, null);

    if (Object.keys(this.errors).length === 0 || Object.values(this.errors).every((x) => x === null)) {
      if (!entityIsDifferent) {
        // do not update is the updated item is the same as the original.
        return;
      }
      this.entity.updatedOn = new Date();

      // EMIT CHANGES TO THE SOURCE APP
      const emittedChanges = ObjectUtil.cloneDeep(this.changes); // to void mutation...
      this.updated.emit({ entity: this.entity, changes: emittedChanges });
      // PERSIST THE CHANGES
      console.log('updating entity: ', JSON.stringify(changes));
      new Entities()
        .update({ reference: this.entityReference, object: changes })
        .then((res) => {
          this.snackBar.open('Entity was updated successfully.', '', { duration: 4000 });
        })
        .catch((err) => {
          console.error('typeProperty value - updating failed', err);
        });
    } else {
      this.snackBar.open('Entity was not updated because of errors. Please fix errors to continue.', '', {
        duration: 4000,
      });
    }
  }

  handlePrimaryViewableChange(content) {
    console.log('HandlePrimaryViewableChange-----------');
    let changes;
    if (content) {
      changes = {
        mediumViewableDownloadUrl: content.mediumViewable.fileUrl, // downloadUrl
        smallViewableDownloadUrl: content.mediumViewable?.fileUrl, // downloadUrl
      };
    } else {
      changes = {
        mediumViewableDownloadUrl: null,
        smallViewableDownloadUrl: null,
      };
    }
    Object.assign(this.entity, changes);
    this.updated.emit({ object: this.entity, changes });
  }

  selectNavOption(navOption: any) {
    this.currentNavOption = navOption;
  }

  async downloadFile() {
    let transformedUrl = this.entity?.largeViewableDownloadUrl || this.entity?.mediumViewableDownloadUrl;
    if (transformedUrl?.indexOf('s3.amazonaws') > -1) {
      window.open(transformedUrl, '_blank');
      return;
    }

    const devUrl = `https://api.dev.vibeiq.com/dev/api`;
    const prodUrl = `https://api.vibeiq.com/prod/api`;
    const API_BASE_URL = environment.apiBaseUrl;

    if (transformedUrl.includes(devUrl)) {
      transformedUrl = transformedUrl.replace(devUrl, API_BASE_URL);
    }
    if (transformedUrl.includes(prodUrl)) {
      transformedUrl = transformedUrl.replace(prodUrl, API_BASE_URL);
    }
    transformedUrl += `?v1.1`;
    const blob = await firstValueFrom(this.http.get(transformedUrl, { responseType: 'blob' }));
    const fileName = `download.png`; //TODO: @Brian: Users can edit fileName and we can lose file extension.
    const file = new File([blob], fileName, { type: 'image/png' });
    let fileUrl = window.URL.createObjectURL(file);

    const link = document.createElement('a');
    link.download = fileName;
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  }
}
