<div class="search-bar mb-2">
  <app-search-bar class="w-52" data-test="searchBar" [placeholder]="'Search'"></app-search-bar>
</div>

<div class="view-list" cdkDropList (cdkDropListDropped)="listDrop($event)">
  <div
    class="view"
    *ngFor="let view of filteredViews$ | async"
    (click)="setCurrentView(view)"
    cdkDrag
    cdkDragLockAxis="y"
    cdkDragBoundary=".view-list"
    data-test="views-list"
    [cdkDragDisabled]="!allowViewsReorder"
  >
    <app-view-list-item [active]="currentView?.id === view.id" [viewDefinition]="view"></app-view-list-item>
    <div class="drag-handle" cdkDragHandle data-test="drag-drop-handler" *ngIf="allowViewsReorder">
      <mat-icon>drag_handle</mat-icon>
    </div>
  </div>
</div>
