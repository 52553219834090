import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { WebSocketService } from '../../web-socket/web-socket.service';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private webSocketService: WebSocketService,
    private authService: AuthService,
  ) {}
}
