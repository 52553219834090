<div *ngIf="!fromTemplate">
  <div class="view-type-list" *ngIf="!selectedViewType">
    <div class="instructions" data-test="select-view-type-label">Select a view type.</div>
    <div
      class="view-type"
      *ngFor="let viewType of viewTypes"
      (click)="selectViewType(viewType)"
      data-test="view-type-list-options"
    >
      <mat-icon class="material-icons-outlined" svgIcon="{{ viewType.slug }}_inactive"></mat-icon>{{ viewType.label }}
    </div>
  </div>
  <div *ngIf="selectedViewType" class="view-config">
    <mat-form-field class="mr-[10px]">
      <mat-label>View Name</mat-label>
      <input [formControl]="nameFormControl" matInput data-test="new-view-name" />
    </mat-form-field>
    <mat-form-field *ngIf="['kanban', 'matrix'].includes(selectedViewType.slug)">
      <mat-label data-test="dim1-label">Dimension 1</mat-label>
      <mat-select [formControl]="dimension1FormControl" data-test="dim1-options">
        <mat-select-trigger>
          {{ dimension1FormControl.value?.label }}
        </mat-select-trigger>
        <mat-option></mat-option>
        <mat-option *ngFor="let option of dimension1Options" [value]="option">
          <mat-icon class="text-[20px]">{{ getDimensionOptionIcon(option.propertyType) }}</mat-icon>
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="mr-[10px]" *ngIf="['matrix'].includes(selectedViewType.slug)">
      <mat-label data-test="dim2-label">Dimension 2</mat-label>
      <mat-select [formControl]="dimension2FormControl" data-test="dim2-options">
        <mat-select-trigger>
          {{ dimension1FormControl.value?.label }}
        </mat-select-trigger>
        <mat-option></mat-option>
        <mat-option *ngFor="let option of dimension2Options" [value]="option">
          <mat-icon class="text-[20px]">{{ getDimensionOptionIcon(option.propertyType) }}</mat-icon>
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="formMode !== 'UPDATE'">
      <mat-slide-toggle
        color="primary"
        labelPosition="before"
        data-test="toggle-private-view"
        [formControl]="privateFormControl"
      >
        Private View
      </mat-slide-toggle>
      <div style="max-width: 300px" class="text-xs text-gray-500">
        Private views are only visible by you, and are not shared with other plan users.
      </div>
    </div>

    <div *ngIf="formMode !== 'UPDATE' && isOrgAdmin" class="mt-[5px]">
      <mat-slide-toggle
        color="primary"
        labelPosition="before"
        data-test="toggle-private-view"
        [formControl]="adminFormControl"
      >
        Admin View
      </mat-slide-toggle>
      <div style="max-width: 300px" class="text-xs text-gray-500">
        Admin views are visible to everyone but can only be edited by org admins.
      </div>
    </div>
    <div class="flex items-center" *ngIf="formMode === 'UPDATE' && currentView.private">
      Private View
      <mat-icon
        style="height: 14px; width: 14px; line-height: 14px; font-size: 14px"
        class="ml-2 material-icons-outlined"
        >lock</mat-icon
      >
    </div>
    <div class="flex items-center" *ngIf="formMode === 'UPDATE' && isOrgAdmin">
      Admin View
      <mat-icon
        style="height: 14px; width: 14px; line-height: 14px; font-size: 14px"
        class="ml-2 material-icons-outlined"
        >group</mat-icon
      >
    </div>
  </div>
  <div
    class="error"
    data-test="same-dimension-error"
    *ngIf="this.dimension1FormControl.value && this.dimension1FormControl.value === this.dimension2FormControl.value"
  >
    You must specify different values for the matrix dimensions.
  </div>
  <div class="actions">
    <div class="link_disabled" *ngIf="isAlreadySubmitted" data-test="cancel-button-disabled">Cancel</div>
    <div
      class="cursor-pointer text-accent"
      (click)="handleCancel()"
      *ngIf="!isAlreadySubmitted"
      data-test="cancel-button"
    >
      Cancel
    </div>
    <button
      class="small-button"
      [disabled]="!isValid() || isAlreadySubmitted"
      *ngIf="selectedViewType"
      mat-raised-button
      (click)="save()"
      color="primary"
      data-test="submit-button"
    >
      Ok
    </button>
  </div>
</div>

<!-- Pick View Templates -->
<div *ngIf="fromTemplate">
  <app-view-template-list
    (templatesSelected)="createFromTemplates($event)"
    (cancel)="handleCancel()"
  ></app-view-template-list>
</div>
