import { Type, TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { SelectedCellInfo } from '../context-menu.component';

export abstract class ContextMenuAction {
  public actionLabel = '';
  public actionIcon = '';
  public svgActionIcon = '';
  public shortCutKey = '';
  public iconClass = '';
  public testRef = '';
  public childActions: any[];

  constructor(protected store: Store<RootStoreState.State>) {}
  canInvoke(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo = null,
    isInFocusMode: boolean = false,
  ): boolean {
    return true;
  }
  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo = null,
    selectedCellLocation: DOMRect = null,
  ) {}
}
