import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { TypesModule } from '@common/types/types.module';
import { ContentModule } from '@common/content/content.module';
import { PipesModule } from '@common/pipes/pipes.module';
import { ListModule } from '@components/list/list.module';
import { EntityModalComponent } from './entity-modal/entity-modal.component';
import { EntityDetailsHeaderComponent } from './entity-details/entity-details-header/entity-details-header.component';
import { EntityPropertiesFormComponent } from './entity-properties-form/entity-properties-form.component';
import { EntityDetailsComponent } from './entity-details/entity-details.component';
import { EntityDetailsSidePanelComponent } from './entity-details-side-panel/entity-details-side-panel.component';
import { EntityDetailsSidePanelHeaderComponent } from './entity-details-side-panel/entity-details-side-panel-header.component';
import { EntityDetailsSidePanelVisualizationComponent } from './entity-details-side-panel/entity-details-side-panel-visualization/entity-details-side-panel-visualization.component';
import { ComponentsModule } from '@common/components/components.module';
import { EntityDetailsSidePanelColorpickerComponent } from './entity-details-side-panel/entity-details-side-panel-colorpicker/entity-details-side-panel-colorpicker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CreateEntityComponent } from './create-entity/create-entity.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { EntityHistoryComponent } from './entity-history/entity-history.component';
import { EntityHistoryListComponent } from './entity-history/entity-history-list/entity-history-list.component';

@NgModule({
  declarations: [
    EntityModalComponent,
    EntityDetailsComponent,
    EntityDetailsHeaderComponent,
    EntityPropertiesFormComponent,
    EntityDetailsSidePanelComponent,
    EntityDetailsSidePanelHeaderComponent,
    EntityDetailsSidePanelVisualizationComponent,
    EntityDetailsSidePanelColorpickerComponent,
    CreateEntityComponent,
    EntityHistoryComponent,
    EntityHistoryListComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    PipesModule,
    TypesModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    ContentModule,
    ColorPickerModule,
    PipesModule,
    ListModule,
  ],
  exports: [
    EntityModalComponent,
    EntityDetailsSidePanelComponent,
    EntityDetailsComponent,
    EntityPropertiesFormComponent,
    CreateEntityComponent,
    EntityHistoryComponent,
    EntityHistoryListComponent,
  ],
})
export class EntityDetailsModule {}
