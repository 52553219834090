import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { SelectedCellInfo } from '../context-menu.component';
import { PlaceholderClipboardService } from '../../placeholders/placeholder-clipboard-service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';

export class AddToClipboardMenuAction extends ContextMenuAction {
  constructor(
    protected store: Store<RootStoreState.State>,
    protected placeholderClipboardService: PlaceholderClipboardService,
  ) {
    super(store);
    this.svgActionIcon = 'clipboard';
    this.actionLabel = 'Add to clipboard';
    this.iconClass = 'material-icons-outlined mb-[4px]';
    this.testRef = 'app-menu-add-to-clipboard-action';
  }

  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    this.placeholderClipboardService.addPlaceholdersToClipboard(ids);
  }

  canInvoke(editorMode: string, ids: Array<string>, selectedCellInfo: SelectedCellInfo = null): boolean {
    if (editorMode !== EditorMode.EDIT) {
      return false;
    }

    return true;
  }
}
