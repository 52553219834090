import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NumberFormat } from '@contrail/types';
import { ListColumnDefinition } from 'src/app/common/components/list/list-column-definition';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { environment } from 'src/environments/environment';
import { Plan } from '../plans-store/plans.state';
import { PlansService } from '../plans.service';

@Component({
  selector: 'app-plans-list',
  templateUrl: './plans-list.component.html',
  styleUrls: ['./plans-list.component.scss'],
})
export class PlansListComponent implements OnInit {
  @Input() plans: Array<Plan>;
  @Output() contextMenu = new EventEmitter();
  public columnDefinitions: Array<ListColumnDefinition>;
  constructor(private routingService: RoutingService) {
    this.columnDefinitions = [
      {
        constantValue: 'assets/images/plan_icon.svg',
        label: '',
        alignment: 'left',
        propertyType: 'icon',
        width: 20,
      },
      { index: 'name', label: 'Name', alignment: 'left', propertyType: 'text' },
      {
        index: 'count',
        label: 'Count',
        alignment: 'right',
        propertyType: 'number',
      },
      {
        index: 'targetVolume',
        label: 'Volume',
        alignment: 'right',
        propertyType: 'number',
      },
      {
        index: 'totalRevenue',
        label: 'Revenue',
        alignment: 'right',
        propertyType: 'currency',
        numberFormat: { currencyCode: 'USD', format: NumberFormat.Currency, decimalPlaces: 0 },
      },
      {
        index: 'updatedBy.email',
        label: 'Updated By',
        alignment: 'right',
        propertyType: 'string',
      },
      {
        index: 'updatedOn',
        label: 'Updated',
        alignment: 'right',
        propertyType: 'date',
      },
      {
        index: 'createdBy.email',
        label: 'Created By',
        alignment: 'right',
        propertyType: 'string',
      },
      {
        index: 'createdOn',
        label: 'Created',
        alignment: 'right',
        propertyType: 'date',
      },
    ];
  }

  ngOnInit(): void {}

  goToPlan(plan) {
    this.routingService.go(`plan/${plan.id}`);
  }
  handleContextMenu($event) {
    console.log('plans list: handleContextMenu: ', $event);
    this.contextMenu.emit($event);
  }
}
