import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { ImageAssignmentOptionsService } from './image-assignment-options.component';
import { ContentHolderService } from '@common/content/content-holder.service';
import { ObjectUtil } from '@contrail/util';
import { PlaceholderItemUpdateService } from '../placeholders/placeholder-item-update-service';
import { CollectionManagerActions } from '../collection-manager-store';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';

@Injectable({
  providedIn: 'root',
})
export class ImageAssignmentInteractionService {
  contentHolder: any;
  constructor(
    private imageAssignmentOptionsService: ImageAssignmentOptionsService,
    private contentHolderService: ContentHolderService,
    private placeholderItemUpdateService: PlaceholderItemUpdateService,
    private store: Store<RootStoreState.State>,
  ) {
    this.contentHolderService.primaryViewableChanged.subscribe((content) => {
      if (content && !document.getElementById('zoomImageCanvas')) {
        // do not update the placeholder if the item details modal is visible
        this.handlePrimaryViewableChange(content);
      }
    });
  }

  public async handleImageItemAssignmentFromFiles(placeholder: any, files: FileList) {
    const contentHolder = placeholder.itemOption || placeholder.itemFamily;
    this.contentHolder = ObjectUtil.cloneDeep(contentHolder);
    const assignmentOption = await this.imageAssignmentOptionsService.open(contentHolder);
    if (!assignmentOption) {
      return;
    }
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
    await this.contentHolderService.loadContentHolder(
      ObjectUtil.cloneDeep(this.contentHolder),
      `item:${contentHolder.id}`,
    );
    await this.contentHolderService.addContentToContentHolder(files, assignmentOption);
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
  }

  async handlePrimaryViewableChange(content) {
    let changes = {
      largeViewableDownloadUrl:
        (content?.contentType === 'image/svg+xml' && content?.primaryFile?.fileUrl) || content?.largeViewable?.fileUrl,
      mediumViewableDownloadUrl: content.mediumViewable.fileUrl,
      smallViewableDownloadUrl: content.mediumViewable?.fileUrl,
    };
    Object.assign(this.contentHolder, changes);
    const { placeholderChanges } = await this.placeholderItemUpdateService.processItemUpdateAndGetChangesToApply(
      this.contentHolder,
      changes,
      false,
      null,
      true,
    );
    this.store.dispatch(
      CollectionManagerActions.batchApplyCollectionEntityChanges({ changes: placeholderChanges, broadcast: true }),
    );
  }
}
