<div class="create-placeholder">
  <button
    [ngClass]="error ? 'add-button disabled' : 'add-button'"
    data-test="add-placeholder-button"
    mat-icon-button
    color="primary"
    disableRipple="true"
    [disabled]="error === true"
    (click)="handleClick()"
  >
    <mat-icon>add</mat-icon>
  </button>
  <div [ngClass]="error === true ? 'input error' : 'input'">
    <input
      data-test="add-placeholder-input"
      (focus)="toggleFocus()"
      (blur)="toggleFocus()"
      type="number"
      matInput
      [formControl]="count"
    />
  </div>
</div>
