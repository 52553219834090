import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { SelectedCellInfo } from '../context-menu.component';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { PlanRowOrder } from 'src/app/plans/plans-store/plans.state';
import { GridRowService } from '../../grid-view/grid-row-service';
import { ObjectUtil } from '@contrail/util';

export class InsertRowMenuAction extends ContextMenuAction {
  planRowOrder: PlanRowOrder;
  sorts: [];
  constructor(
    protected store: Store<RootStoreState.State>,
    private addRowService: GridRowService,
    private position: string,
  ) {
    super(store);
    this.subscribe();
    this.testRef = position === 'above' ? 'app-menu-insert-row-above-action' : 'app-menu-insert-row-below-action';
    this.actionIcon = position === 'above' ? 'arrow_upward' : 'arrow_downward';
    this.actionLabel = position === 'above' ? 'Insert row above' : 'Insert row below';
    this.shortCutKey = position === 'below' ? 'Shift-Enter' : '';
  }

  subscribe() {
    this.store.select(PlansSelectors.planRowOrder).subscribe((planRowOrder) => {
      this.planRowOrder = planRowOrder;
    });
    this.store
      .select(CollectionManagerSelectors.selectCurrentView)
      .subscribe((currentView) => (this.sorts = ObjectUtil.cloneDeep(currentView?.sorts) || []));
  }

  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    this.addRowService.addRowToCollection(1, ids, this.position);
  }

  canInvoke(editorMode: string, ids: Array<string>, selectedCellInfo: SelectedCellInfo = null) {
    if (this.sorts.length > 0) {
      return false;
    }
    if (editorMode !== 'EDIT') {
      return false;
    }

    return true;
  }
}
