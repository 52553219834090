<div class="side-panel" [style.display]="showSidePanel ? 'block' : 'none'">
  <div class="content">
    <app-summary-view *ngIf="currentView?.slug === 'summary'" (close)="handleClose()"></app-summary-view>
    <app-context-comments-list
      *ngIf="currentView?.slug === 'comments'"
      [accessLevel]="editorMode"
      [contextReferenceTypesObservable]="contextReferenceTypes$"
    >
      <div class="flex-center cursor-pointer" (click)="handleClose()"><mat-icon>close</mat-icon></div>
    </app-context-comments-list>
    <app-status-messages-list
      data-test="status-messages-panel"
      *ngIf="currentView?.slug === 'messages'"
      [messages]="messages$ | async"
      [showWarningToggle]="false"
      (onClose)="handleClose()"
      (onMessageClick)="handleMessageClick($event)"
    >
    </app-status-messages-list>
    <app-plan-item-chooser
      [arrowKeyNavigationEnabled]="false"
      [criteria]="baseCriteria"
      [style.display]="currentView?.slug !== 'addItem' ? 'none' : 'block'"
      *ngIf="chooserLoaded"
      [allowAddEntity]="true"
      [allowAddMultipleEntities]="true"
      (close)="handleClose()"
      [showHeader]="true"
      [showFilter]="true"
      [showAllControl]="true"
      [targetSourceType]="currentView?.targetSourceType"
      [hideSource]="currentView?.hideSource"
      [title]="currentView?.title"
    >
    </app-plan-item-chooser>
    <app-pivot-view-configurator
      *ngIf="currentView?.slug === 'pivotViewOptions' && currentViewDefinition"
      (close)="handleClose()"
      [typeDefinition]="typeDefinition"
      [viewDefinition]="currentViewDefinition"
      (viewDefinitionUpdated)="handleViewDefinitionUpdated($event)"
    ></app-pivot-view-configurator>
    <app-document-history
      *ngIf="currentView?.slug === 'versionHistory'"
      (panelClosed)="handleClose()"
      [document]="currentPlan"
      [documentReference]="'plan:' + currentPlan.id"
      [historyType]="currentView?.slug"
      [clearEntitySnapshotWhenClosed]="false"
      [editorMode]="editorMode"
    >
    </app-document-history>
  </div>
</div>
