<div
  [style.height.px]="rowViewPortHeight$ | async"
  [style.width.px]="sideWidth"
  class="grid-rows-wrapper"
  [ngClass]="{ left: side === 'left', right: side === 'right' }"
>
  <div class="grid-rows vertically-scrollable">
    <app-data-row
      [editorMode]="editorMode"
      [dataRow]="dataRow.data"
      [properties]="properties"
      [side]="side"
      [rowNumber]="dataRow.rowIndex"
      [realRowNumber]="dataRow.realRowNumber"
      *ngFor="let dataRow of visibleDataRows; trackBy: rowTrackByFn"
    >
    </app-data-row>
  </div>
  <app-vertical-scroller [height]="rowViewPortHeight$ | async" *ngIf="side === 'right'"></app-vertical-scroller>
</div>
