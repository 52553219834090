import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Entities, EntityReference } from '@contrail/sdk';
import { BehaviorSubject, combineLatest, from, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { SourceChangeHistoryHelper } from '../source-change-history-helper';

@Component({
  selector: 'app-source-change-history-panel',
  templateUrl: './source-change-history-panel.component.html',
  styleUrls: ['./source-change-history-panel.component.scss'],
})
export class SourceChangeHistoryPanelComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() ownerReference: string;
  public history$ = new BehaviorSubject(null);
  public historyObservable$;
  public loadingSubject: Subject<boolean> = new BehaviorSubject(true);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();
  public filterSubject = new BehaviorSubject(null);
  constructor() {}
  ngOnInit() {
    if (this.ownerReference && !this.historyObservable$) {
      const entityReference = new EntityReference(this.ownerReference);
      const dateFilterSubject = new BehaviorSubject(null);
      const typeFilterSubject = new BehaviorSubject(null);
      const dateFilterObservable = dateFilterSubject.pipe(distinctUntilChanged());
      const typeFilterObservable = typeFilterSubject.pipe(distinctUntilChanged());

      const filterSub = this.filterSubject.subscribe((filter) => {
        if (!filter) {
          return;
        }
        typeFilterSubject.next(filter.type);
        dateFilterSubject.next(filter.dateRange);
      });

      const apiHistoryData$ = combineLatest([dateFilterObservable]).pipe(
        switchMap(async ([dateRange]) => {
          this.loadingSubject.next(true);
          let history = await this.getHistory(entityReference.id, dateRange);
          this.loadingSubject.next(false);
          return history;
        }),
      );

      this.historyObservable$ = combineLatest([apiHistoryData$, typeFilterObservable])
        .pipe(
          map(async ([history, typeFilter]) => {
            this.loadingSubject.next(true);
            history = this.filterMeaninglessChanges(history);
            history = this.filterHistoryByType(history, typeFilter);
            this.loadingSubject.next(false);
            history = history.sort((h1, h2) => (h1.date < h2.date ? 1 : -1));
            this.history$.next(history);
          }),
        )
        .subscribe();
    }
  }

  /** Dealing with short comings in the current
   * entity history service that may be logging a change with just an updatedById
   * or updatedOn timestamp.
   */
  private filterMeaninglessChanges(history) {
    return history.filter((h) => SourceChangeHistoryHelper.hasMeaningfulChange(h));
  }
  private filterHistoryByType(history, changeType) {
    if (!changeType?.length) {
      return history;
    }
    return history.filter((h) => {
      if (changeType === 'ADDS') {
        return ['assortment-addition'].includes(h.changeType);
      }
      if (changeType === 'DROPS') {
        return ['assortment-delete'].includes(h.changeType);
      }
      if (changeType === 'CHANGES') {
        return ['item', 'project-item'].includes(h.changeType);
      }
      return history;
    });
  }

  /** Builds request to API to fetch source history for document. */
  private async getHistory(id, dateRangeFilter) {
    const criteria: any = {};
    if (dateRangeFilter) {
      const startDate = dateRangeFilter?.filterCriteria?.propertyCriteria.find(
        (c) => c.filterConditionType === 'greater_than_or_equal',
      )?.criteriaValue;
      const endDate = dateRangeFilter?.filterCriteria?.propertyCriteria.find(
        (c) => c.filterConditionType === 'less_than_or_equal',
      )?.criteriaValue;
      if (startDate) {
        criteria.startDate = startDate;
      }
      if (endDate) {
        criteria.endDate = endDate;
      }
    } else {
      let now = new Date();
      const numWeeks = 1;
      let startDate = now.setDate(now.getDate() - numWeeks * 7);
      criteria.startDate = this.getIsoDatePart(startDate);
    }
    const entityReference = new EntityReference(this.ownerReference);
    return new Entities().get({
      entityName: entityReference.entityType,
      id,
      relation: 'source-history',
      criteria,
    });
  }

  public handleFilterChange(filter) {
    this.filterSubject.next(filter);
  }

  private getIsoDatePart(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }
}
