import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { DataCellEditBaseComponent } from '../data-cell-edit-base-component';
@Component({
  selector: 'app-data-cell-edit-boolean',
  template: `<input #inputField (change)="handleChange()" [(ngModel)]="value" type="checkbox" />`,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        justify-content: flex-end;
        text-align: right;
      }
      input {
        border: none;
        font-size: 12px;
        line-height: normal;
        padding: 0px;
        width: 95%;
        justify-content: flex-end;
        text-align: right;
      }
    `,
  ],
})
export class DataCellEditBooleanComponent extends DataCellEditBaseComponent implements AfterViewInit {
  @ViewChild('inputField') input: ElementRef;
  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.input?.nativeElement.focus();
  }

  handleChange() {
    this.value = this.value;
    super.handleChange();
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent = null) {
    this.value = !this.value;
    event.stopPropagation();
  }
}
