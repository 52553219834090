import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatGridTileHeaderCssMatStyler } from '@angular/material/grid-list';
import { escapeRegExp } from 'lodash';
import { ViewDefinition } from '@contrail/client-views';
import { Entities } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@common/auth/auth-store';
import { ConfirmationBoxService } from '@common/components/confirmation-box/confirmation-box';
import { SearchBarComponent } from '@common/components/search-bar/search-bar.component';
import { RootStoreState } from '@rootstore';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { ViewManagerService } from '@common/views/view-manager.service';

@Component({
  selector: 'app-view-template-list',
  templateUrl: './view-template-list.component.html',
  styleUrls: ['./view-template-list.component.scss'],
})
export class ViewTemplateListComponent implements AfterViewInit {
  constructor(
    private store: Store<RootStoreState.State>,
    private confirmationBoxService: ConfirmationBoxService,
    private viewsService: ViewManagerService,
  ) {}
  public filteredTemplates$: Observable<Array<ViewDefinition>>;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @Output() templatesSelected = new EventEmitter();
  @Output() cancel = new EventEmitter();
  public selectedViewDefs = new Set();
  public loading$ = new BehaviorSubject(false);
  public role$: Observable<any>;

  ngAfterViewInit(): void {
    this.loadTemplates();
    this.role$ = this.store.select(AuthSelectors.selectAuthContext).pipe(map((ctx) => ctx?.currentOrg?.role));
  }

  async loadTemplates() {
    this.loading$.next(true);
    const templates = from(
      (async () => {
        return await this.viewsService.getViewDefinitions({ applicationViewSlug: 'plan:plan_editor' });
      })(),
    );

    this.filteredTemplates$ = combineLatest([this.searchBar?.valueChange.pipe(startWith('')), templates]).pipe(
      switchMap(async ([searchTerm, allTemplates]) => {
        this.loading$.next(false);
        const searchOptions = ['label'];
        const filtered = allTemplates.filter((prop) => {
          return (
            !searchTerm?.length ||
            searchOptions.some((key) =>
              new RegExp(escapeRegExp(searchTerm), 'gi').test(ObjectUtil.getByPath(prop, key.trim())),
            )
          );
        });
        filtered.sort((t1, t2) => (t1.label > t2.label ? 1 : -1));
        return filtered;
      }),
    );
  }

  isSelected(id) {
    return this.selectedViewDefs.has(id);
  }
  toggle(viewDef: ViewDefinition) {
    if (this.selectedViewDefs.has(viewDef)) {
      this.selectedViewDefs.delete(viewDef);
    } else {
      this.selectedViewDefs.add(viewDef);
    }
    console.log('selectedIds.size: ', this.selectedViewDefs.size);
  }

  submit() {
    this.templatesSelected.emit(Array.from(this.selectedViewDefs));
  }
  handleCancel() {
    this.cancel.emit();
  }

  async deleteTemplate(viewDef) {
    const confirm = await this.confirmationBoxService.open(`Confirm`, `Delete this template from the library?`);
    if (confirm) {
      await new Entities().delete({ entityName: 'view-definition', id: viewDef.id });
    }
    this.loadTemplates();
  }
}
