<app-modal [title]="'Option already exists'" [dialogRef]="dialogRef">
  <div *ngIf="placeholders.length">
    <div class="invalid p-4">
      <mat-icon class="icon">warning</mat-icon>
      <div class="content text-sm">
        The following option(s) did not get promoted because they already exist, but have not been added to your Plan.
        Select the existing options you want to add to your Plan and click OK, or click Cancel to return to your Plan
        without adding the existing option(s).
      </div>
    </div>
    <div
      [ngClass]="placeholders.length > 5 ? 'h-[150px] overflow-y-scroll' : ''"
      class="p-[10px]"
      *ngIf="placeholders.length"
    >
      <div class="flex font-bold" *ngIf="placeholders.length > 1">
        <mat-checkbox
          class="ml-2 mr-3"
          [checked]="phsForOptionAssignment.length === placeholders.length"
          (change)="selectAll()"
        ></mat-checkbox>
        Select all
      </div>
      <div *ngFor="let ph of placeholders" class="flex">
        <mat-checkbox
          class="ml-2 mr-3"
          [checked]="phsForOptionAssignment.includes(ph.id)"
          (change)="toggle(ph)"
        ></mat-checkbox>
        {{ ph.name }} - {{ ph.optionName }}
      </div>
    </div>
  </div>

  <div
    [ngClass]="placeholders.length ? 'mt-[20px]' : ''"
    class="p-[10px]"
    *ngIf="placeholdersWithOptionAlreadyInPlan.length"
  >
    <div class="invalid p-4" *ngIf="placeholders.length === 0">
      <mat-icon class="icon">warning</mat-icon>
      <div class="content text-sm">
        The following option(s) did not get promoted because they already exist and are already in your plan.
      </div>
    </div>
    The following option(s) did not get promoted because they already exist and are already in your plan.
    <ul class="list-disc pl-[20px]">
      <li *ngFor="let ph of placeholdersWithOptionAlreadyInPlan">
        <span>{{ ph.name }} - {{ ph.optionName }}</span>
      </li>
    </ul>
  </div>
  <div class="actions" *ngIf="placeholders.length">
    <button mat-flat-button (click)="closeModal()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="phsForOptionAssignment.length === 0" (click)="assignOptions()">
      OK
    </button>
  </div>
</app-modal>
