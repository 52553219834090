<div [style.height.px]="rowContainerHeight" class="grid-rows-wrapper" [ngClass]="{ left: side === 'left' }">
  <div class="grid-rows">
    <app-pivot-data-row
      [dataRow]="dataRow.data"
      [properties]="properties"
      [groupingProperties]="groupingProperties"
      [side]="side"
      [goals]="goals"
      [showGoals]="showGoals"
      [rowNumber]="i"
      (rowAction)="onRowAction($event)"
      (onContextMenu)="handleContextMenu($event)"
      (hoverRow)="onHoverRow($event)"
      [hoveredRowId]="hoveredRowId"
      *ngFor="let dataRow of visibleDataRows; trackBy: rowTrackByFn; let i = index"
    >
    </app-pivot-data-row>
  </div>
</div>
