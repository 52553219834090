import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevelopmentComponent } from './development.component';
import { EntityDetailsModule } from '@common/entity-details/entity-details.module';

@NgModule({
  declarations: [DevelopmentComponent],
  imports: [CommonModule, EntityDetailsModule],
  exports: [DevelopmentComponent],
})
export class DevelopmentModule {}
