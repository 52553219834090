import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface CollectionStatusMessage {
  id: string;
  type: string;
  message: string;
  collectionElementId: string;
  propertySlug?: string;
}

export interface CollectionStatusMessagesState extends EntityState<CollectionStatusMessage> {}
export const collectionStatusEntityAdapter: EntityAdapter<CollectionStatusMessage> =
  createEntityAdapter<CollectionStatusMessage>({});

export const collectionStatusMessagesInitialState = collectionStatusEntityAdapter.getInitialState({});
