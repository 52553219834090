import { TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { FilterDefinition, FilterPropertyDefinition } from 'src/app/common/types/filters/filter-definition';

export class FilterDefinitionBuilder {
  public static buildFilterDefinition(typeProperties: Array<TypeProperty>): FilterDefinition {
    const props = [...typeProperties] as Array<FilterPropertyDefinition>;
    props.sort((p1, p2) => (p1.label > p2.label ? 1 : -1));
    const filterDefinition: FilterDefinition = {
      filterPropertyDefinitions: [...props],
      filterCriteria: {
        propertyCriteria: [],
      },
    };
    return ObjectUtil.cloneDeep(filterDefinition);
  }
}
