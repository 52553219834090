import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../../collection-manager-store';
import { SpreadValueHandler } from '../spread-value-handler';

@Component({
  selector: 'app-spread-value',
  templateUrl: './spread-value.component.html',
  styleUrls: ['./spread-value.component.scss'],
})
export class SpreadValueComponent implements OnInit, OnDestroy {
  @ViewChild('modal') modalElement: ElementRef;
  @ViewChild('newtotal') newTotalInput: ElementRef;
  @ViewChild('applyspread') applySpreadButton: MatButton;

  private validUXNavElements = ['mat-button', 'mat-option', 'mat-menu', 'mat-calendar'];
  spreadValueInfoSub: Subscription;
  keyEventSub: Subscription;
  spreadValueInfo: any;
  spreadValueInfo$: Observable<any>;
  weightProperty;
  spreadTotal = 0;
  weightTotal = 0;
  currentTotal = new UntypedFormControl({ value: 0, disabled: true });
  spreadType = new UntypedFormControl('even');
  newTotal = new UntypedFormControl('');

  constructor(
    private store: Store<RootStoreState.State>,
    private spreadValueHandler: SpreadValueHandler,
    private elementRef: ElementRef,
  ) {
    this.spreadValueInfo$ = store.select(CollectionManagerSelectors.spreadValueInfo);
    this.spreadValueInfoSub = this.spreadValueInfo$.subscribe((spreadValueInfo) => {
      setTimeout(() => {
        this.keyEventSub = fromEvent(this.modalElement?.nativeElement, 'keydown').subscribe((event) => {
          this.handleKeyEvents(event);
        });
        this.spreadValueInfo = spreadValueInfo;
        if (spreadValueInfo) {
          this.spreadTotal = this.spreadValueHandler.getSpreadTotal();
          this.currentTotal.setValue(this.spreadTotal);
          this.weightProperty = this.spreadValueHandler.getWeightProperty();
          if (this.weightProperty) {
            this.weightTotal = this.spreadValueHandler.getWeightTotal(this.weightProperty.slug);
          }
          this.applySpreadButton.focus();
        }
      }, 200);
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.spreadValueInfoSub.unsubscribe();
    this.keyEventSub.unsubscribe();
  }

  applySpreadValue() {
    let totalValue = this.spreadTotal;
    if (this.newTotal.value) {
      totalValue = this.newTotal.value;
    }
    this.spreadValueHandler.calculateSpread(
      totalValue,
      this.spreadType.value === 'even',
      this.weightProperty?.slug,
      this.weightTotal,
    );
    this.close();
  }

  @HostListener('document:click', ['$event'])
  click(event) {
    this.checkClose(event);
  }

  @HostListener('document:contextmenu', ['$event'])
  contextmenu(event) {
    this.checkClose(event);
  }

  checkClose(event) {
    const src = event.srcElement;
    const validNavs = this.validUXNavElements.filter((ele) => src.getAttribute('class')?.indexOf(ele) > -1);
    if (validNavs?.length) {
      return;
    }
    if (this.spreadValueInfo && !this.elementRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  close() {
    this.newTotal.setValue(null);
    this.store.dispatch(CollectionManagerActions.setSpreadValueInfo({ spreadValueInfo: null }));
    this.store.dispatch(CollectionManagerActions.setSelectorCells({ selectorCells: [] }));
    this.spreadType.setValue('even');
  }

  handleKeyEvents(event) {
    const keyEvent = event as KeyboardEvent;
    if (keyEvent.key === 'Tab') {
      if (keyEvent.target['id'] === 'applySpread' && !keyEvent.shiftKey) {
        // shift+tab
        keyEvent.preventDefault();
        this.newTotalInput.nativeElement.focus();
      } else if (keyEvent.target['id'] === 'newTotal' && keyEvent.shiftKey) {
        // tab
        keyEvent.preventDefault();
        this.applySpreadButton.focus();
      }
    } else if (keyEvent.key === 'Enter' && keyEvent.target['id'] !== 'cancelSpread') {
      this.applySpreadValue();
    }
  }
}
