import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-empty-placeholder',
  templateUrl: './empty-placeholder.component.html',
  styleUrls: ['./empty-placeholder.component.scss'],
})
export class EmptyPlaceholderComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() footer: string;
  @Input() actionLabel: string;

  @Output() private triggerAction = new EventEmitter();

  public reducedWidth;

  private breakpoint = 550;
  constructor(private elRef: ElementRef) {}
  ngOnInit(): void {
    this.showReducedPlaceholder();
  }

  triggerEvent() {
    this.triggerAction.emit(true);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showReducedPlaceholder();
  }

  showReducedPlaceholder() {
    const parentEle = this.elRef?.nativeElement?.parentNode;
    const parentWidth = parentEle?.offsetWidth || 0;
    if (parentWidth < this.breakpoint) {
      this.reducedWidth = true;
    }
  }
}
