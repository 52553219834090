import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UndoRedoActions } from './undo-redo-store';
import { finalize, last, map, take } from 'rxjs/operators';
import {
  selectLatestRedoAction,
  selectLatestUndoAction,
  selectUndoActions,
} from './undo-redo-store/undo-redo.selectors';
import { Observable } from 'rxjs';
import { nanoid } from 'nanoid';
import { filter } from 'cypress/types/bluebird';

@Injectable({
  providedIn: 'root',
})
export class UndoRedoService {
  constructor(private store: Store) {}

  addUndo(changeDefinition: any): any {
    const undoAction = { changeDefinition: { ...changeDefinition, uuid: nanoid(16) } };
    this.store.dispatch(UndoRedoActions.removeAllRedos());
    this.store.dispatch(UndoRedoActions.addUndo(undoAction));

    return undoAction.changeDefinition;
  }

  getNextUndo(): Observable<any> {
    return this.store.pipe(
      select(selectLatestUndoAction),
      map((undoAction) => {
        return undoAction;
      }),
      take(1),
      finalize(() => {
        return this.store.dispatch(UndoRedoActions.moveLatestUndoToRedo());
      }),
    );
  }

  getNextRedo(): Observable<any> {
    return this.store.pipe(
      select(selectLatestRedoAction),
      map((redoAction) => {
        return redoAction;
      }),
      take(1),
      finalize(() => {
        return this.store.dispatch(UndoRedoActions.moveLatestRedoToUndo());
      }),
    );
  }

  getUndoByUuid(uuid: string): Observable<any> {
    return this.store.pipe(
      select(selectUndoActions),
      map((undoActions) => {
        return undoActions.find((undoAction) => undoAction.uuid === uuid);
      }),
      take(1),
    );
  }
}
