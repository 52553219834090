<div class="card">
  <div class="cardHeader">
    <div class="icon">
      <span class="add" *ngIf="historyEvent.changeType === 'assortment-addition'"><mat-icon>add</mat-icon></span>
      <span class="drop" *ngIf="historyEvent.changeType === 'assortment-delete'"
        ><mat-icon>remove_circle_outline</mat-icon></span
      >
      <span class="modify" *ngIf="['item', 'project-item'].includes(historyEvent.changeType)"
        ><mat-icon>change_history</mat-icon></span
      >
    </div>
    <div class="date">{{ historyEvent.date | date: 'short' }}</div>
  </div>

  <div class="description" *ngIf="historyEvent.changeType === 'assortment-delete'">
    <span class="font-bold">
      {{ historyEvent.itemName }}
      <span *ngIf="historyEvent.itemOptionName">({{ historyEvent.itemOptionName }})</span>
    </span>
    was removed.
  </div>
  <div class="description" *ngIf="historyEvent.changeType === 'assortment-addition'">
    <div class="mb-3">
      {{ historyEvent.count }}
      <span *ngIf="historyEvent.count > 1"> items were </span>
      <span *ngIf="historyEvent.count === 1"> item was </span>
      added.
    </div>
    <div *ngFor="let item of historyEvent.items">
      <span class="font-bold">
        {{ item.name }}
        <span *ngIf="item.optionName">({{ item.optionName }})</span>
      </span>
    </div>
  </div>
  <div class="description" *ngIf="['item', 'project-item'].includes(historyEvent.changeType)">
    <span class="font-bold">{{ historyEvent.itemName }}</span>
    <span class="font-bold" *ngIf="historyEvent.itemOptionName">
      {{ historyEvent.itemOptionName }}
    </span>
    was modified.
    <div class="primary-view-change flex flex-wrap items-center" *ngIf="historyEvent.changes?.smallViewableDownloadUrl">
      <span class="w-full underline mb-1 mt-2">Primary content</span>

      <app-source-change-history-image
        [value]="historyEvent.changes?.smallViewableDownloadUrl?.oldValue"
      ></app-source-change-history-image>
      <mat-icon class="mx-2">chevron_right</mat-icon>
      <app-source-change-history-image
        [value]="historyEvent.changes?.smallViewableDownloadUrl?.newValue"
        [new]="true"
      ></app-source-change-history-image>
    </div>
    <div class="mt-3">
      <app-source-change-history-event-change-details
        style="display: block"
        class="mb-2"
        *ngFor="let change of changes"
        [change]="change"
      >
      </app-source-change-history-event-change-details>
    </div>
  </div>
</div>
