import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { GridViewKeyHandler } from './grid-view-key-handler';
import { GridViewScrollHandler } from './scroll-bars/grid-view-scroll-handler';
import { ViewDefinition } from '@contrail/client-views';
import { CollectionDataEntity } from '../collection-manager.service';
import { GridRowService } from './grid-row-service';
import { ClipboardCopyPasteHandler } from './copy-paste/clipboard-copy-paste-handler';
import { RowDragPreviewHandler } from './row-drag/row-drag-preview-handler';
import { GridNavigationHandler } from './grid-navigation-handler';
import { SearchReplaceService } from './search-replace.service';
import { GridCommentService } from './grid-comment-service';

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss'],
})
export class GridViewComponent implements OnInit, OnDestroy {
  @ViewChild('planGrid') elementView: ElementRef;
  //  This is the drag preview div that shows the number of rows selected for dragging
  @ViewChild('rowDragPreview') rowDragPreview: ElementRef;

  constructor(
    private store: Store<RootStoreState.State>,
    private keyHandler: GridViewKeyHandler,
    private navigationHandler: GridNavigationHandler,
    private copyPasteHandler: ClipboardCopyPasteHandler,
    private elem: ElementRef,
    private addRowService: GridRowService,
    private searchReplaceService: SearchReplaceService,
    private commentService: GridCommentService,
    private renderer: Renderer2,
  ) {
    this.scrollHandler = new GridViewScrollHandler(this.store, this.renderer, this.elem);
    this.editorModeSub = this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
    this.sidePanelWidthSub = this.store
      .select(CollectionManagerSelectors.sidePanelWidth)
      .subscribe((sidePanelWidth) => (this.sidePanelWidth = sidePanelWidth));
  }

  public allData$: Observable<Array<CollectionDataEntity>> = this.store.select(CollectionManagerSelectors.allData);
  public data$: Observable<Array<CollectionDataEntity>> = this.store.select(CollectionManagerSelectors.displayedData);
  public leftSideColumnWidth$ = this.store.select(CollectionManagerSelectors.leftPropertiesTotalColumnWidth);
  public searchReplaceCells$ = this.store.select(CollectionManagerSelectors.searchReplaceCells);
  public viewDefinition: ViewDefinition;
  public leftProperties$ = this.store.select(CollectionManagerSelectors.leftProperties);
  public rightProperties$ = this.store.select(CollectionManagerSelectors.rightProperties);
  private scrollHandler: GridViewScrollHandler;
  private rowDragPreviewHandler: RowDragPreviewHandler;
  private editorModeSub: Subscription;
  public editorMode: string;
  private sidePanelWidthSub: Subscription;
  private sidePanelWidth = 0;
  public overrideOptionCells$ = this.store.select(CollectionManagerSelectors.overrideOptionCells);

  ngOnInit() {
    setTimeout(() => {
      this.setWindowDimensions();
      this.rowDragPreviewHandler = new RowDragPreviewHandler(
        this.store,
        this.navigationHandler,
        this.elem,
        this.rowDragPreview,
      );
    }, 500);
  }
  ngOnDestroy() {
    this.unsubscribe();
    this.searchReplaceService.resetSearchReplace();
  }

  setWindowDimensions() {
    this.store.dispatch(
      CollectionManagerActions.setGridTotalWidth({ gridTotalWidth: window.innerWidth - this.sidePanelWidth }),
    );
    this.store.dispatch(CollectionManagerActions.setGridTotalHeight({ gridTotalHeight: window.innerHeight }));
  }

  @HostListener('mousewheel', ['$event']) // for window scroll events
  onMouseWheel(event) {
    this.scrollHandler.handleScrollEvent(event);
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(keyEvent: KeyboardEvent) {
    this.keyHandler.handleKeyPress(keyEvent);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setWindowDimensions();
  }

  @HostListener('document:paste', ['$event'])
  onPaste(event) {
    this.copyPasteHandler.handleDocumentPasteEvent(event);
  }

  unsubscribe() {
    console.log('grid-view: destroy');
    this.scrollHandler.unsubscribe();
    this.editorModeSub.unsubscribe();
    this.sidePanelWidthSub.unsubscribe();
  }

  addNewPlaceholders(count) {
    this.addRowService.addRowToCollection(count);
  }
}
