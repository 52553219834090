<div class="status" (click)="showAlertMessage()">
  <button class="menu-button" mat-button>
    <mat-icon
      *ngIf="shouldShowIconWithBadges"
      matBadgeSize="small"
      [matBadge]="messages?.length"
      svgIcon="notification_icon"
    ></mat-icon>
    <mat-icon *ngIf="!shouldShowIconWithBadges" svgIcon="notification_icon"> </mat-icon>
    <ng-container *ngIf="isLoading">
      <mat-progress-spinner class="spinner" diameter="12" mode="indeterminate"></mat-progress-spinner>
    </ng-container>
    <ng-container *ngIf="hasFailedToLoad">
      <mat-icon class="failure-badge">close</mat-icon>
    </ng-container>
  </button>
</div>
