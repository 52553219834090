import { PropertyType, PropertyValueFormatter } from '@contrail/types';
import { SortDefinition, SortDirection } from './sort-definition';

export class SortObjects {
  public static sort(array: Array<any>, sorts: Array<SortDefinition>, rowOrder?: Array<string>) {
    let results: Array<any> = array;

    if ((!sorts || sorts.length === 0) && rowOrder) {
      // sort by planRowOrder if no explicit sorts are added
      const sortedArray = [];
      // Find row orders that could be missing
      let missingRowOrders: string[] = [];
      if (rowOrder.length !== results.length) {
        missingRowOrders = results.map((ph) => ph.id).filter((x) => !rowOrder.includes(x));
      }

      rowOrder.forEach((rowId) => {
        // doing this means that if a placeholder is not in the planRoworder, it will be omitted.
        const obj = results.find((row) => row.id === rowId);
        if (obj) {
          sortedArray.push(obj);
        }
      });
      // Add the missing row orders back into the sorted placeholders.
      missingRowOrders.forEach((id) => {
        const obj = results.find((row) => row.id === id);
        if (obj) {
          sortedArray.push(obj);
        }
      });

      return sortedArray;
    }

    results = results.sort((obj1, obj2) => {
      let comp = 0;
      for (const sort of sorts) {
        comp = this.compareObjects(obj1, obj2, sort.propertySlug, sort.propertyType, sort.direction);
        if (comp !== 0) {
          return comp;
        }
      }
      return comp;
    });
    return results;
  }

  public static compareObjectIds(obj1, obj2, rowOrder: Array<string>) {
    return rowOrder.findIndex((order) => order === obj1.id) - rowOrder.findIndex((order) => order === obj2.id);
  }

  public static compareObjects(obj1, obj2, key, propertyType, direction: SortDirection = SortDirection.DESCENDING) {
    let valA = obj1[key] || '';
    let valB = obj2[key] || '';

    if (propertyType === PropertyType.ObjectReference || propertyType === PropertyType.ObjectReferenceList) {
      if (key === 'itemOption') {
        valA = valA?.optionName || valA?.name || '';
        valB = valB?.optionName || valB?.name || '';
      } else {
        valA = valA?.name || '';
        valB = valB?.name || '';
      }
    }

    if (propertyType === PropertyType.SizeRange) {
      const formatter = new PropertyValueFormatter();
      valA = formatter.formatValue(valA, PropertyType.SizeRange);
      valB = formatter.formatValue(valB, PropertyType.SizeRange);
    }
    if (propertyType === PropertyType.UserList) {
      valA = valA?.email || '';
      valB = valB?.email || '';
    }
    if (Array.isArray(valA)) {
      valA = valA.join();
    }
    if (Array.isArray(valB)) {
      valB = valB.join();
    }

    let comp = 0;
    if (valA.localeCompare) {
      comp = valA.localeCompare(valB);
    } else {
      comp = valA - valB;
    }

    if (direction === SortDirection.DESCENDING) {
      comp = -1 * comp;
    }
    return comp;
  }
}
