import { Injectable } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { Types } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { PlansActionTypes } from 'src/app/plans/plans-store/plans.actions';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions } from '.';
import { CollectionManagerActionTypes } from './collection-manager.actions';
import { CollectionViewsActionTypes } from './collection-views/collection-views.actions';

@Injectable()
export class CollectionManagerEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private webSocketService: WebSocketService,
  ) {}

  selectEntityProperty$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionManagerActionTypes.SET_SELECTED_ENTITY_PROPERTY),
        tap((action: any) => {
          this.webSocketService.sendSessionEvent({
            eventType: 'SELECT_ENTITY_PROPERTY',
            data: { entityId: action.entityId, propertySlug: action.propertySlug },
          });
        }),
      ),
    { dispatch: false },
  );

  /** Updates selected ids state after rows are deleted. */
  clearSelectedWhenDeleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.deleteCollectionDataEntitiesSuccess),
        tap((action: any) => {
          this.store.dispatch(CollectionManagerActions.removeSelectedEntityIds({ ids: action.ids }));
        }),
      ),
    { dispatch: false },
  );

  setCurrentView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionViewsActionTypes.SET_CURRENT_VIEW_DEFINITION),
        tap((action: any) => {
          this.store.dispatch(CollectionManagerActions.setScrollHorizontalPercentage({ percentage: 0 }));
          this.store.dispatch(CollectionManagerActions.setScrollVerticalPercentage({ percentage: 0 }));
          this.store.dispatch(CollectionManagerActions.resetSearchReplace());
        }),
      ),
    { dispatch: false },
  );

  setCurrentPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActionTypes.LOAD_CURRENT_PLAN),
        tap((action: any) => {
          this.store.dispatch(CollectionManagerActions.clearCollectionManager());
        }),
      ),
    { dispatch: false },
  );

  setSorts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActionTypes.SET_SORTS),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          const currentView = ObjectUtil.cloneDeep(store.collectionManager.currentViewDefinition);
          console.log('Updating sorts on plan: ', action.sorts);
          this.store.dispatch(
            CollectionManagerActions.updateViewDefinition({ id: currentView.id, changes: { sorts: action.sorts } }),
          );
        }),
      ),
    { dispatch: false },
  );

  loadTypeDefinitions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActionTypes.LOAD_TYPE_DEFINITIONS),
        tap(async (action: any) => {
          const typeDefinitions: { [key: string]: Type } = {};
          const [planPlaceholderType, itemType, assortmentItemType, projectItemType] = await Promise.all([
            new Types().getType({ root: 'plan-placeholder', path: 'plan-placeholder' }),
            new Types().getType({ root: 'item', path: 'item' }),
            new Types().getType({ root: 'assortment-item', path: 'assortment-item' }),
            new Types().getType({ root: 'project-item', path: 'project-item' }),
          ]);

          typeDefinitions['plan-placeholder'] = planPlaceholderType;
          typeDefinitions['item'] = itemType;
          typeDefinitions['assortment-item'] = assortmentItemType;
          typeDefinitions['project-item'] = projectItemType;

          this.store.dispatch(CollectionManagerActions.setTypeDefinitions({ typeDefinitions }));
        }),
      ),
    { dispatch: false },
  );
}
