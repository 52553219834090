<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu class="context-menu" #contextMenu="matMenu" [hasBackdrop]="false">
  <button mat-menu-item (click)="expandSameLevelRows($event)">
    <div class="action-button">
      <mat-icon>add_box</mat-icon>
      <span>Expand all in {{ propLabel }}</span>
    </div>
  </button>
  <button mat-menu-item (click)="collapseSameLevelRows($event)">
    <div class="action-button">
      <mat-icon>indeterminate_check_box</mat-icon>
      <span>Collapse all in {{ propLabel }}</span>
    </div>
  </button>
</mat-menu>
