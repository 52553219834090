import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { CollectionManagerSelectors } from 'src/app/collection-manager/collection-manager-store';
import { RootStoreState } from 'src/app/root-store';
import { GridSelectorService } from '../../selectors/grid-selector.service';

@Component({
  selector: 'app-fill-cell-handle',
  template: `
    <div
      data-test="app-fill-cell-handle"
      class="fill-handle"
      *ngIf="(cellSelected && !(fillAnchorCell$ | async)) || (isFillAnchorCell$ | async)"
      (dblclick)="handleFillRow($event)"
      (click)="stopPropagationForFill($event)"
      (mousedown)="fillHandleActivated($event)"
    ></div>
  `,
  styles: [
    `
      .fill-handle {
        cursor: crosshair;
        pointer-events: auto;
        background-color: white;
        border: 1px solid #2d7ff9;
        margin-left: -2px;
        margin-top: -4px;
        position: absolute;
        width: 5px;
        height: 5px;
        right: -2px;
        bottom: -4px;
        border-radius: 2px;
        z-index: 10;
      }
    `,
  ],
})
export class FillCellHandleComponent {
  @Input() rowId: string;
  @Input() propertySlug: string;
  @Input() cellSelected: boolean;

  fillAnchorCell$: any; // check to see if the fillAnchorCell exists
  isFillAnchorCell$: any; // check to see if the current cell is the fillAnchorCell.

  constructor(
    private store: Store<RootStoreState.State>,
    private gridSelectorService: GridSelectorService,
  ) {
    this.fillAnchorCell$ = this.store.pipe(
      select(CollectionManagerSelectors.fillAnchorCell),
      map((fillAnchorCell) => fillAnchorCell),
    );

    this.isFillAnchorCell$ = this.store.pipe(
      select(CollectionManagerSelectors.fillAnchorCell),
      map((fillAnchorCell) =>
        fillAnchorCell && fillAnchorCell.rowId === this.rowId && fillAnchorCell.columnId === this.propertySlug
          ? true
          : false,
      ),
    );
  }

  fillHandleActivated(event) {
    event.stopPropagation();
    this.gridSelectorService.setFillHandleActive();
  }

  handleFillRow(event) {
    event.stopPropagation();
    this.gridSelectorService.handleFillRowCells();
  }

  stopPropagationForFill(event) {
    // stop event from reaching the DataCellComponent
    event.stopPropagation();
  }
}
