import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ObjectUtil } from '@contrail/util';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pivot-view-property-selector',
  templateUrl: './pivot-view-property-selector.component.html',
  styleUrls: ['./pivot-view-property-selector.component.scss'],
})
export class PivotViewPropertySelectorComponent implements OnInit {
  functionTypes: string[] = ['AVG', 'MAX', 'MIN', 'SUM'];
  control = new UntypedFormControl();

  filteredProperties: Observable<any[]>;
  selectedProperties: any[] = [];
  @Input() properties: any[];
  @Input() currentProperties: any[];
  @Input() sortable = false;
  @Input() currentSorts: any[];
  @ViewChild('propertyInput') propertyInputElement: ElementRef;
  @Output() propertiesChangedEvent = new EventEmitter();
  @Output() propertiesSortEvent = new EventEmitter();
  @Output() propertiesAggregateFunctionEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.selectedProperties = ObjectUtil.cloneDeep(this.currentProperties);
    this.setFilteredProperties();
    this.control.valueChanges.forEach((value) => {
      this.filteredProperties = new Observable((observer) => {
        observer.next(this._filter(value));
        observer.complete();
      });
    });
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.properties.filter((property) => this._normalizeValue(property.label).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedProperties, event.previousIndex, event.currentIndex);
    this.updateProperties();
  }

  addProperty(property) {
    this.selectedProperties.push(property);
    this.updateProperties();
    this.control.setValue('');
    this.propertyInputElement.nativeElement.blur();
    this.setFilteredProperties();
  }

  removeProperty(property) {
    const index = this.selectedProperties.findIndex((selectedProperty) => selectedProperty.value === property.value);
    this.selectedProperties.splice(index, 1);
    this.setFilteredProperties();
    this.updateProperties();
  }

  private updateProperties() {
    this.propertiesChangedEvent.emit(this.selectedProperties);
  }

  private setFilteredProperties() {
    this.filteredProperties = new Observable((observer) => {
      observer.next(
        this.properties
          .filter(
            (property) =>
              !this.selectedProperties.map((selectedProperty) => selectedProperty.value).includes(property.value),
          )
          .sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            } else if (a.label > b.label) {
              return 1;
            } else {
              return 0;
            }
          }),
      );
      observer.complete();
    });
  }

  sort(property: any, ascending = false) {
    this.propertiesSortEvent.emit({ property, direction: ascending ? 'ASC' : 'DESC' });
  }

  isSortDirectionSelected(property: any, ascending = false) {
    const sort = this.currentSorts?.find((currentSort) => currentSort.propertySlug === property.value);
    if (sort) {
      if (sort.direction === 'ASC' && ascending) {
        return true;
      } else if (sort.direction === 'DESC' && !ascending) {
        return true;
      }
    }
    return false;
  }

  selectFunction(event, property) {
    this.propertiesAggregateFunctionEvent.emit({ property, functionType: event.value });
    const prop = this.selectedProperties.find((selectedProperty) => selectedProperty.value === property.value);
    prop.aggregateFunction = event.value;
  }

  public openPanel(e) {
    e.stopPropagation();
    this.propertyInputElement.nativeElement.focus();
  }
}
