import { API_VERSION, Entities, SortOrderOptions } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { SortDefinition, SortDirection } from '../../components/sort/sort-definition';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { ItemData } from '../../item-data/item-data';
import { FilterHelper } from '../../types/filters/filter-helper';
import { ChooserFilterConfig, ItemDataChooserDataSource } from './item-data-chooser-data-source';

export class ItemLibraryChooserDataSource extends ItemDataChooserDataSource {
  protected moreResultsLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public moreResultsLoading$: Observable<boolean> = this.moreResultsLoadingSubject.asObservable();

  constructor(
    protected store: Store<RootStoreState.State>,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    protected projectId?: string,
    protected shouldUsePagination: boolean = false,
  ) {
    super(store, filterConfigSubject, sortConfigSubject, existingItemIdsSubject, showAllSubject, null);
    this.initFilteredDataObservable();
    this.initResultsObservable();
  }

  public isFirstPageOfItems: boolean;
  public nextPageKey: string;
  private getItemsRequestOptions: any;

  protected async initFilteredDataObservable() {
    this.filteredDataSubscription = combineLatest([this.filterConfigSubject, this.sortConfigSubject])
      .pipe(
        switchMap(async ([filterConfig, sortConfig]) => {
          if (!filterConfig || !sortConfig || this.loadingSubject.getValue() === true) {
            return;
          }

          this.loadingSubject.next(true);
          this.nextPageKey = undefined;

          // ITEM LIBRARY (SEARCH)
          const relations = ['itemFamily'];
          let searchTerm = filterConfig.searchTerm.trim() || '';
          const filterDefinition = filterConfig.filterDefinition;
          const criteria = filterConfig.baseCriteria;
          if (!searchTerm?.endsWith('*')) {
            searchTerm += '*';
          }
          let apiCriteria = Object.assign({}, criteria);
          const filterCriteria = FilterHelper.toSimpleCriteria(filterDefinition);
          if (filterCriteria) {
            apiCriteria = Object.assign(apiCriteria, filterCriteria);
          }

          const sortOrders = sortConfig.map((sortDefinition) => {
            return {
              order:
                sortDefinition.direction === SortDirection.ASCENDING ? SortOrderOptions.ASC : SortOrderOptions.DESC,
              orderField: sortDefinition.propertySlug,
            };
          });

          this.getItemsRequestOptions = {
            entityName: 'item',
            relations,
            criteria: apiCriteria,
            search: searchTerm,
            order: sortOrders,
            apiVersion: this.shouldUsePagination ? API_VERSION.V2 : API_VERSION.V1,
            paginate: this.shouldUsePagination,
          };

          const results = await this.getItemData();
          this.isFirstPageOfItems = true;
          this.loadingSubject.next(false);
          this.filteredDataSubject.next(results);
        }),
      )
      .subscribe();
  }

  private async getItemData(): Promise<Array<ItemData>> {
    const numberOfResultsPerPage = 100;

    this.getItemsRequestOptions = {
      ...this.getItemsRequestOptions,
      take: numberOfResultsPerPage,
      nextPageKey: this.shouldUsePagination ? this.nextPageKey : undefined,
    };

    const response = await new Entities().get(this.getItemsRequestOptions);
    if (!response) {
      console.error('Error: Failed to fetch items.');
      return [];
    }

    const results = this.shouldUsePagination ? response.results : response;
    const hydratedResults =
      this.projectId && results?.length > 0 ? await this.getAndAssignProjectItems(results) : results;
    const itemData = hydratedResults.map((obj) => new ItemData(obj));

    if (this.shouldUsePagination) {
      this.nextPageKey = response.nextPageKey ? response.nextPageKey : undefined;
    }

    return itemData;
  }

  protected async getAndAssignProjectItems(items: Array<any>): Promise<Array<any>> {
    const itemIds = items.map((item) => item.id);
    const itemFamilyIds = items.map((item) => item.itemFamily?.id).filter((id) => id);
    const allItemIds = [...new Set(itemIds.concat(itemFamilyIds))];
    const projectItems = await new Entities().get({
      entityName: 'project-item',
      criteria: {
        projectId: this.projectId,
        itemIds: allItemIds,
      },
    });

    const itemsWithProjectItems = items.map((item) => {
      const projectItem = projectItems.find((projectItem) => projectItem.itemId === item.id);
      const itemFamilyProjectItem = projectItems.find((projectItem) => projectItem.itemId === item.itemFamily?.id);
      const itemFamily = item.itemFamily ? { ...item.itemFamily, projectItem: itemFamilyProjectItem } : undefined;

      return { ...item, projectItem, itemFamily };
    });

    return itemsWithProjectItems;
  }

  public async getMorePaginatedResults() {
    if (
      this.shouldUsePagination &&
      this.nextPageKey &&
      this.loadingSubject.value === false &&
      this.moreResultsLoadingSubject.value === false
    ) {
      this.moreResultsLoadingSubject.next(true);

      const nextSetOfResults = await this.getItemData();
      const existingResults = this.getFilteredData();
      const combinedResults = [...existingResults, ...nextSetOfResults];
      this.isFirstPageOfItems = false;
      this.moreResultsLoadingSubject.next(false);
      this.filteredDataSubject.next(combinedResults);
    }
  }
}
