import { State } from '../collection-manager.state';
import { collectionElementEntityAdapter } from './collection-elements.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as CollectionElementActions from './collection-elements.actions';
import { PlansActions } from 'src/app/root-store';
import { PlaceholderUtil } from '../../placeholders/placeholder-util';
import { ObjectUtil } from '@contrail/util';

const setCollectionData = (state: State, { data }) => {
  return {
    ...state,
    collectionElements: collectionElementEntityAdapter.setAll(data, state.collectionElements),
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    collectionElements: collectionElementEntityAdapter.removeAll(state.collectionElements),
  };
};
const addCollectionEntity = (state: State, { entity }) => {
  return {
    ...state,
    collectionElements: collectionElementEntityAdapter.addOne(entity, state.collectionElements),
  };
};

const addCollectionEntities = (state: State, { entities }) => {
  return {
    ...state,
    collectionElements: collectionElementEntityAdapter.addMany(entities, state.collectionElements),
  };
};

const updateCollectionEntity = (state: State, { id, changes }) => {
  return {
    ...state,
    collectionElements: collectionElementEntityAdapter.updateOne({ id, changes }, state.collectionElements),
  };
};

const updateCollectionEntities = (state: State, { changes }) => {
  return {
    ...state,
    collectionElements: collectionElementEntityAdapter.updateMany(changes, state.collectionElements),
  };
};

const removeCollectionEntity = (state: State, { entity }) => {
  return {
    ...state,
    collectionElements: collectionElementEntityAdapter.removeOne(entity.id, state.collectionElements),
  };
};
const removeCollectionEntities = (state: State, { ids }) => {
  return {
    ...state,
    collectionElements: collectionElementEntityAdapter.removeMany(ids, state.collectionElements),
  };
};

const setFilteredEntityIds = (state: State, { filteredEntityIds }) => {
  return {
    ...state,
    collectionElements: { ...state.collectionElements, filteredEntityIds },
  };
};

export const collectionElementReducers = [
  on(CollectionElementActions.addCollectionEntity, addCollectionEntity),
  on(CollectionElementActions.addCollectionEntities, addCollectionEntities),
  on(CollectionElementActions.applyCollectionEntityChangesSuccess, updateCollectionEntity),
  on(CollectionElementActions.batchApplyCollectionEntityChangesSuccess, updateCollectionEntities),
  on(CollectionElementActions.removeCollectionEntity, removeCollectionEntity),
  on(CollectionElementActions.removeCollectionEntities, removeCollectionEntities),
  // on(CollectionElementActions.loadCollectionDataSuccess, setCollectionData),
  on(CollectionElementActions.loadCollectionData, clearData),
  on(CollectionElementActions.setCollectionData, setCollectionData),
  on(CollectionElementActions.setFilteredEntityIds, setFilteredEntityIds),
];
