import { SelectedCellInfo } from '../context-menu.component';
import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { FilterService } from '../../filter/filter-service';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { FilterConditionType, FilterConditionTypeMetaMap, FilterConditionTypePropertyTypeMap } from '@contrail/filters';
import { GridViewManager } from '../../grid-view/grid-view.manager';

export class QuickFilterContextMenuAction extends ContextMenuAction {
  selectorCells: any[];
  selectedEntityIds: any[];
  currentViewProperties: any[];
  constructor(
    protected store: Store<RootStoreState.State>,
    protected gridViewManager: GridViewManager,
    protected filterService: FilterService,
    protected filterConditionType: FilterConditionType = null,
    protected isUserOrgAdmin: boolean,
    protected isAdminView: boolean,
  ) {
    super(store);
    if (!filterConditionType) {
      this.actionIcon = 'filter_list';
      this.actionLabel = 'Filter cell value';
      this.shortCutKey = 'Ctrl-E';
      this.testRef = 'app-menu-quick-filter-action';
      const selectedCell = this.gridViewManager.selectedCell;
      if (selectedCell) {
        const filterConditionTypeMap = FilterConditionTypePropertyTypeMap.get(selectedCell.propertyType);
        this.childActions = filterConditionTypeMap?.map((conditionType) => {
          return new QuickFilterContextMenuAction(
            this.store,
            this.gridViewManager,
            this.filterService,
            conditionType,
            this.isUserOrgAdmin,
            this.isAdminView,
          );
        });
      }
    } else {
      this.actionLabel = FilterConditionTypeMetaMap.get(this.filterConditionType).label;
      this.testRef = 'app-menu-quick-filter-action-' + this.filterConditionType;
    }

    this.store
      .select(CollectionManagerSelectors.currentViewProperties)
      .subscribe((currentViewProperties) => (this.currentViewProperties = currentViewProperties));
  }

  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    this.filterService.applyQuickFilter(
      selectedCellInfo.propertySlug,
      selectedCellInfo.propertyValue,
      this.filterConditionType,
    );
  }

  canInvoke(editorMode: string, ids: Array<string>, selectedCellInfo: SelectedCellInfo = null) {
    if (!selectedCellInfo) {
      return false;
    }
    if (!this.filterService.isQuickFilterAllowed(selectedCellInfo.propertySlug)) {
      return false;
    }

    if (editorMode !== 'EDIT') {
      return false;
    }

    const isFilterDisabled = !this.isUserOrgAdmin && this.isAdminView;
    if (isFilterDisabled) {
      return false;
    }

    return true;
  }
}
