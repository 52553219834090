<app-menu
  data-test="sort-menu-button"
  name="Sort"
  [toolTip]="toolTip"
  icon="sort"
  [highlight]="currentSorts.length > 0"
  [disabled]="disabled"
>
  <app-sort-panel
    data-test="sort-panel"
    [currentSorts]="currentSorts"
    [properties]="properties"
    (performSort)="performSort($event)"
  >
  </app-sort-panel>
</app-menu>
