<div class="viewInfo">
  <mat-icon [svgIcon]="icon" class="material-icons-outlined"></mat-icon>
  <div class="label" [ngClass]="{ highlight: active }" data-test="view-label">{{ viewDefinition.label }}</div>
  <mat-icon
    style="height: 14px; width: 14px; line-height: 14px; font-size: 14px"
    class="ml-2 material-icons-outlined"
    *ngIf="viewDefinition.private"
    >lock</mat-icon
  >
  <mat-icon
    style="height: 14px; width: 14px; line-height: 14px; font-size: 14px"
    class="ml-2 material-icons-outlined"
    *ngIf="viewDefinition.admin"
    >group</mat-icon
  >
</div>
