<!-- Plan selection -->
<div *ngIf="step === 'select-plan'">
  <div class="font-bold mb-2">Select a plan</div>
  <ng-template [ngTemplateOutlet]="planSearch"></ng-template>
  <ng-template [ngTemplateOutlet]="browsePlans"></ng-template>
</div>

<!-- View Selection-->
<div *ngIf="step === 'select-views'">
  <ng-template [ngTemplateOutlet]="selectViews"></ng-template>
</div>

<ng-template #browsePlans>
  <mat-divider class="!my-2"></mat-divider>
  <div class="flex items-center justify-start font-bold h-6">
    <ng-container *ngIf="browseStep === 'WORKSPACE_SELECT'">
      <mat-icon (click)="browseStep = 'DOCUMENT_SELECT'" class="cursor-pointer text-[16px] !w-4 !h-4"
        >arrow_back_ios_new</mat-icon
      >
      <span class="ml-2">Select project</span>
    </ng-container>
    <ng-container *ngIf="browseStep === 'DOCUMENT_SELECT'">
      <mat-icon (click)="browseStep = 'WORKSPACE_SELECT'" class="cursor-pointer text-[16px] !w-4 !h-4"
        >arrow_back_ios_new</mat-icon
      >
      <span class="ml-2">Select document</span>
    </ng-container>
  </div>
  <mat-divider class="!my-2"></mat-divider>

  <div *ngIf="browseStep === 'WORKSPACE_SELECT'">
    <mat-form-field class="w-full mt-4" appearance="fill">
      <mat-label>Project</mat-label>
      <mat-select [formControl]="sourceWorkspaceControl">
        <mat-option *ngFor="let workspace of sourceWorkspaces$ | async" [value]="workspace">
          {{ workspace.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="flex justify-end">
      <button mat-raised-button color="primary" (click)="selectWorkspace()">Select</button>
    </div>
  </div>

  <div *ngIf="browseStep === 'DOCUMENT_SELECT'" class="max-w-[320px] min-h-[300px]">
    <div class="flex-center w-full h-72" *ngIf="documentLoading$ | async">
      <app-mat-spinner [message]="'Loading'" [overlay]="false" [loading]="true"></app-mat-spinner>
    </div>

    <div [ngStyle]="{ visibility: (documentLoading$ | async) ? 'hidden' : 'visible' }">
      <app-folder-breadcrumb-nav></app-folder-breadcrumb-nav>

      <div class="entity-container max-h-[300px] overflow-auto narrow-scroll">
        <div
          *ngFor="let item of transformedEntities$ | async"
          class="entity-item flex items-center px-1 h-9 rounded cursor-pointer hover:bg-blue-50"
          (click)="chooseEntity(item)"
        >
          <mat-icon class="!text-blue-300" *ngIf="item.matIcon">{{ item.matIcon }}</mat-icon>
          <img *ngIf="!item.matIcon" [src]="item.icon" class="w-6 h-6 object-contain" />
          <span [matTooltip]="item?.name" [matTooltipDisabled]="item?.name.length < 30">{{
            item.name | shorten: 30
          }}</span>
        </div>

        <span *ngIf="!(transformedEntities$ | async)?.length" class="py-10 w-full flex-center">No Plans</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #planSearch>
  <div class="flex items-center h-9 relative">
    <span class="material-icons absolute left-2 pointer-events-none text-black24">search</span>
    <input
      #searchInput
      class="!w-full !h-full bg-neutral-50 px-10 rounded-md"
      [ngClass]="{ 'border bg-white': focus === 'in' }"
      type="text"
      placeholder="Search plans in your organization"
      [formControl]="globalSearch"
      [matAutocomplete]="auto"
      (blur)="onBlur()"
      (focus)="onFocus()"
    />
    <div class="flex-center absolute right-0 min-w-[40px] h-10 cursor-pointer z-50">
      <mat-spinner *ngIf="moreLoading || loading" [diameter]="20"></mat-spinner>
      <ng-container *ngIf="!moreLoading && !loading && (globalSearch.value || focus === 'in')">
        <span class="material-icons text-black24 hover:text-black60" (click)="clear($event)"> close </span>
      </ng-container>
    </div>
  </div>

  <mat-autocomplete
    #auto="matAutocomplete"
    [class]="'global-search-overlay'"
    [panelWidth]="320"
    (optionSelected)="selectPlan($event)"
  >
    <ng-container *ngIf="globalSearch.value && !focusing">
      <ng-container *ngIf="!loading">
        <mat-option *ngFor="let plan of plans$ | async" [value]="plan">
          <div class="entity-item flex items-center">
            <img src="/assets/images/plan_icon.svg" class="w-6 h-6 object-contain" />
            <span [matTooltip]="plan?.name" [matTooltipDisabled]="plan?.name.length < 30">{{
              plan?.name | shorten: 30
            }}</span>
          </div>
        </mat-option>
      </ng-container>

      <mat-option *ngIf="!loading && !(plans$ | async)?.length" class="pointer-events-none opacity-70">
        <span class="no-result flex-center text-sm">There are no plans matching your query</span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</ng-template>

<ng-template #selectViews>
  <div class="font-bold">Select views to import</div>
  <div class="view-list" *ngIf="!(loadingViews$ | async)">
    <div class="my-2 flex justify-between" *ngFor="let viewDef of viewDefinitions$ | async">
      <div class="flex">
        <mat-checkbox
          class="ml-2 mr-3"
          [checked]="isSelected(viewDef.id)"
          (change)="toggleSelected(viewDef)"
        ></mat-checkbox>
        <app-view-list-item [viewDefinition]="viewDef"></app-view-list-item>
      </div>
    </div>
    <ng-container *ngIf="!(viewDefinitions$ | async)?.length && !(loadingViews$ | async)">
      <span class="py-10 w-full flex-center">No Views Found</span>
    </ng-container>
  </div>

  <div class="actions" *ngIf="!(loadingViews$ | async)">
    <div class="cursor-pointer text-accent !ml-2" (click)="handleBack()" data-test="cancel-button">Back</div>
    <div class="cursor-pointer text-accent !ml-2" (click)="handleCancel()" data-test="cancel-button">Cancel</div>
    <button
      class="small-button"
      color="primary"
      mat-raised-button
      data-test="submit-button"
      [disabled]="!(selectedViewDefs?.size > 0)"
      (click)="import()"
    >
      Ok
    </button>
  </div>

  <div class="loading-container" *ngIf="loadingViews$ | async">
    <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
    <div class="message">Loading views...</div>
  </div>
</ng-template>
