import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ViewDefinition } from '@contrail/client-views';
import { Entities } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { ConfirmationBoxService } from '@common/components/confirmation-box/confirmation-box';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { MenuComponent } from 'src/app/common/components/menu/menu.component';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { ConfigureViewDefModalLauncher } from './configure-view-def-modal-launcher';
import { ViewModalComponent } from './view-modal/view-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Actions, ofType } from '@ngrx/effects';
import { CollectionViewsActionTypes } from '../collection-manager-store/collection-views/collection-views.actions';

@Component({
  selector: 'app-collection-views',
  templateUrl: './collection-views.component.html',
  styleUrls: ['./collection-views.component.scss'],
})
export class CollectionViewsComponent implements OnInit, OnDestroy {
  public currentView$: Observable<ViewDefinition>;
  @Output() createdNewView = new EventEmitter();
  @ViewChild(MenuComponent) menu: MenuComponent;
  @ViewChild('viewActions') actionMenu: MenuComponent;

  public nameInput: UntypedFormControl;

  public mode = 'list';
  public viewActions = [
    {
      icon: 'edit',
      label: 'Rename',
      action: 'rename',
    },
    {
      icon: 'delete_forever',
      label: 'Delete',
      action: 'delete',
    },
  ];

  public isEditing = false;
  private subscription: Subscription = new Subscription();
  public currentView: ViewDefinition;
  public allViews: Array<ViewDefinition> = [];
  public editorMode = 'VIEW';
  public nextSortOrder = 0;

  constructor(
    private store: Store<RootStoreState.State>,
    private confirmationBoxService: ConfirmationBoxService,
    private matDialog: MatDialog,
    private configureViewDefModalLauncher: ConfigureViewDefModalLauncher,
    private actions$: Actions,
  ) {
    this.currentView$ = this.store.select(CollectionManagerSelectors.selectCurrentView).pipe(
      tap((data) => {
        this.currentView = data;
        this.nameInput = new UntypedFormControl(data?.label);
      }),
    );

    this.subscription.add(
      this.store.select(CollectionManagerSelectors.selectCollectionViews).subscribe((data) => {
        this.allViews = data.sort((a, b) => ((a.sortOrder || 0) > (b.sortOrder || 0) ? 1 : -1));
        this.getNewViewSortOrder();
      }),
    );
    this.subscription.add(
      this.store.select(PlansSelectors.editorMode).subscribe((mode) => {
        this.editorMode = mode;
      }),
    );
  }

  private getNewViewSortOrder() {
    for (const view of this.allViews) {
      if (this.nextSortOrder <= view.sortOrder) {
        this.nextSortOrder++;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  init() {
    console.log('CollectionViews: Init');
    this.mode = 'list';
  }
  ngOnInit(): void {
    this.init();
  }
  setCurrentView(viewDefinition: ViewDefinition) {
    this.menu.close();
    this.store.dispatch(CollectionManagerActions.setCurrentViewDefinition({ viewDefinition }));
  }
  addNewView() {
    const viewDefinition: ViewDefinition = {
      applicationViewSlug: 'plan:plan_editor',
      label: 'New View',
      viewType: 'grid',
      properties: [],
      sortOrder: 0,
    };
    this.menu.close();
    this.store.dispatch(CollectionManagerActions.createViewDefinitions({ viewDefinitions: [viewDefinition] }));
  }
  async handleAction($event) {
    this.isEditing = false;
    if ($event.action === 'rename') {
      this.isEditing = true;
    } else if ($event.action === 'delete') {
      const confirm = await this.confirmationBoxService.open(
        `Confirm`,
        `Are you sure you want to delete this view definition?`,
      );
      if (confirm) {
        this.store.dispatch(CollectionManagerActions.deleteViewDefinition({ viewDefinition: this.currentView }));
      }
    } else if ($event.action === 'make_template') {
      this.promoteToTemplateLibrary();
    } else if ($event.action === 'configure') {
      this.configureViewDefModalLauncher.openModal({ currentView: $event.data });
    } else if ($event.action === 'copy') {
      this.openCopyModal();
    }
    this.actionMenu.close();
  }
  openCopyModal(): void {
    const params = {
      currentView: this.currentView,
      formMode: 'COPY',
    };
    const dialogRef = this.matDialog.open(ViewModalComponent, {
      width: '350px',
      //height: '320px',
      disableClose: false,
      autoFocus: true,
      panelClass: 'full-screen-modal',
      data: params,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  async promoteToTemplateLibrary() {
    const confirm = await this.confirmationBoxService.open(`Confirm`, `Copy this view into the template library?`);
    if (confirm) {
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));

      const newView = ObjectUtil.cloneDeep(this.currentView);
      delete newView.id;
      delete newView.createdOn;
      delete newView.updatedOn;
      delete newView.createdById;
      delete newView.updatedById;
      delete newView.orgId;
      delete newView.contextReference; // This makes it a template
      delete newView.private; // This makes sure its not private once its a template.
      delete newView.admin; // This makes sure its not admin once its a template.
      await new Entities().create({ entityName: 'view-definition', object: newView });

      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(keyEvent: KeyboardEvent) {
    const saveActionKeyCodeList = ['Tab', 'Enter'];
    if (saveActionKeyCodeList.includes(keyEvent.code) && this.isEditing) {
      this.save();
      keyEvent.preventDefault();
    }
  }

  save() {
    if (this.nameInput.value.length > 0) {
      this.store.dispatch(
        CollectionManagerActions.updateViewDefinition({
          id: this.currentView.id,
          changes: { label: this.nameInput.value },
        }),
      );
    }
    this.isEditing = false;
  }

  close() {
    this.mode = 'list';
    this.menu.close();
  }

  handleNewViewCreated() {
    this.close();
    this.actions$
      .pipe(
        ofType(CollectionViewsActionTypes.CREATE_VIEW_DEFINITIONS_SUCCESS),
        tap((action: any) => {
          this.createdNewView.emit();
        }),
        take(1),
      )
      .subscribe();
  }
}
