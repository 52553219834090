import { OnChanges, Renderer2 } from '@angular/core';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PropertyValueFormatter } from '@contrail/types';
import { DataCellViewBaseComponent } from '../data-cell-view-base-component';

@Component({
  selector: 'app-data-cell-view-formula',
  template: ` {{ display }} `,
  styles: [``],
})
export class DataCellViewFormulaComponent extends DataCellViewBaseComponent implements OnChanges {
  @Input() value;
  @Input() display;

  constructor() {
    super();
  }

  ngOnChanges(): void {
    this.display = new PropertyValueFormatter().formatValue(
      this.value,
      this.property.propertyDefinition.propertyType,
      this.property.propertyDefinition?.numberFormat,
    );
  }
}
