import { Component, Input } from '@angular/core';
import { CollectionDataEntity } from 'src/app/collection-manager/collection-manager.service';
import { GridViewPropertyConfiguration } from '../data-cell.component';

@Component({ template: '' })
export class DataCellViewBaseComponent {
  @Input() property: GridViewPropertyConfiguration;
  @Input() value: any;
  @Input() selected: boolean;
  @Input() data: CollectionDataEntity;
  @Input() accessLevel = 'EDIT';
  constructor() {}
}
