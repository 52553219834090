import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ViewDefinition } from '@contrail/client-views';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { take, tap } from 'rxjs';

@Component({
  selector: 'app-promote-option-modal',
  templateUrl: './promote-option-modal.component.html',
  styleUrls: ['./promote-option-modal.component.scss'],
})
export class PromoteOptionModalComponent implements OnInit {
  public formMode = 'UPDATE';
  public currentView: ViewDefinition;
  public modalTitle = 'View Configuration';
  placeholdersWithOptionAlreadyInPlan: any[];
  placeholders: any[];
  familyOptionMap: any = {};
  phsForOptionAssignment = [];

  constructor(
    public dialogRef: MatDialogRef<PromoteOptionModalComponent>,
    private store: Store<RootStoreState.State>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.placeholders = data;
  }

  async ngOnInit(): Promise<void> {
    this.store
      .select(CollectionManagerSelectors.displayedData)
      .pipe(
        take(1),
        tap((data) => {
          this.placeholdersWithOptionAlreadyInPlan = this.placeholders.filter((obj) =>
            data.find((ph) => ph.itemFamilyId === obj.itemFamilyId && ph.itemOption?.optionName === obj.optionName),
          );
          const ids = this.placeholdersWithOptionAlreadyInPlan.map((ph) => ph.id);
          this.placeholders = this.placeholders.filter((ph) => !ids.includes(ph.id));
        }),
      )
      .subscribe();

    const itemGroups = this.groupBy(this.placeholders, 'itemFamilyId');
    const itemFamilyIds = Object.keys(itemGroups);
    for (let i = 0; i < itemFamilyIds.length; i++) {
      const options = await new Entities().get({ entityName: 'item', criteria: { itemFamilyId: itemFamilyIds[i] } });
      this.familyOptionMap[itemFamilyIds[i]] = options.filter((option) => option.roles.includes('color'));
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  selectAll() {
    if (this.phsForOptionAssignment.length === this.placeholders.length) {
      this.phsForOptionAssignment = [];
    } else {
      this.phsForOptionAssignment = this.placeholders.map((ph) => ph.id);
    }
  }

  toggle(ph) {
    if (!this.phsForOptionAssignment.includes(ph.id)) {
      this.phsForOptionAssignment.push(ph.id);
    } else {
      this.phsForOptionAssignment.splice(this.phsForOptionAssignment.indexOf(ph.id), 1);
    }
  }

  assignOptions() {
    const assignmentCandidates: any[] = [];
    this.phsForOptionAssignment.forEach((phId) => {
      const placeholder = this.placeholders.find((ph) => ph.id === phId);
      const option = this.familyOptionMap[placeholder.itemFamilyId].find(
        (option) => option.optionName === placeholder.optionName,
      );
      assignmentCandidates.push({ placeholderId: placeholder.id, optionId: option.id });
    });
    this.dialogRef.close(assignmentCandidates);
  }

  private groupBy(cells, key) {
    return cells.reduce((cell, x) => {
      (cell[x[key]] = cell[x[key]] || []).push(x);
      return cell;
    }, {});
  }
}
