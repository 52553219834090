<div class="wrapper">
  <div class="left-bar">
    <app-content-upload *ngIf="editable" class="my-4" (contentUploaded)="contentUploaded($event)"></app-content-upload>
    <div class="item-filter">
      <app-search-bar [lgGray]="false" [placeholder]="'Search items'"></app-search-bar>
      <div class="flex-between-center">
        <div class="filter-by" [matMenuTriggerFor]="filter">
          <p class="text-sm mr-2">Type:</p>
          <p class="text-sm text-primary">{{ filterControl.value }}</p>
          <mat-icon class="ml-1">arrow_drop_down</mat-icon>
        </div>
        <mat-menu #filter="matMenu" yPosition="below" xPosition="before" class="menu-sm">
          <button mat-menu-item *ngFor="let option of filterOptions" (click)="filterBy(option)">
            {{ option }}
          </button>
        </mat-menu>

        <div class="sort-by" [matMenuTriggerFor]="sortByMenu">
          <p class="text-sm mr-2">Sort by:</p>
          <p class="text-sm text-primary">{{ sortByControl.value?.label }}</p>
          <mat-icon class="ml-1">arrow_drop_down</mat-icon>
        </div>
        <mat-menu #sortByMenu="matMenu" yPosition="below" xPosition="before" class="menu-sm">
          <button mat-menu-item *ngFor="let option of sortOptions" (click)="sortBy(option)">
            {{ option?.label }}
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="item-list" *ngIf="(content$ | async)?.length">
      <virtual-scroller #scroll class="narrow-scroll" [items]="content$ | async">
        <app-content-card
          class="content-item-card"
          *ngFor="let element of scroll.viewPortItems; index as idx"
          [class.active]="(selectedContent$ | async).id === element.id"
          (click)="selectContent(element)"
          [item]="element"
          [primaryViewableId]="contentHolder.primaryViewableId"
          [itemIndex]="idx"
          (updateContent)="updateItemContent($event)"
          [editable]="editable"
        >
        </app-content-card>
      </virtual-scroller>
    </div>
  </div>

  <div class="placeholder-content flex-center">
    <div class="wrap w-[680px] h-[680px] border flex-center">
      <h6 *ngIf="!(content$ | async)?.length && !(loading$ | async)">No content has been assigned to this item.</h6>
      <app-content-preview
        [content]="selectedContent$ | async"
        *ngIf="!(loading$ | async) && !showroomConfigure && (content$ | async)?.length"
      ></app-content-preview>
      <div class="flex-center relative" *ngIf="!(loading$ | async) && showroomConfigure">
        <canvas id="renderCanvasConfig" class="w-[678px] h-[678px]"></canvas>

        <div class="canvas-buttons flex-center">
          <img src="/assets/images/3d_coordinates.svg" id="webglMove" matTooltip="Offset" matTooltipPosition="above" />
          <img src="/assets/images/3d_rotate.svg" id="webglRotate" matTooltip="Rotation" matTooltipPosition="above" />
          <img src="/assets/images/zoom_in.svg" id="webglZoomIn" matTooltip="Zoom in" matTooltipPosition="above" />
          <img src="/assets/images/zoom_out.svg" id="webglZoomOut" matTooltip="Zoom out" matTooltipPosition="above" />
        </div>
      </div>
    </div>
  </div>

  <div class="right-bar">
    <app-content-info-panel
      [content]="selectedContent$ | async"
      [downloadBtn]="true"
      [configureBtn]="true"
      (downloadContent)="downloadContent($event)"
      (openConfigure)="showroomConfigure = $event"
      *ngIf="!showroomConfigure && (content$ | async)?.length"
    >
    </app-content-info-panel>

    <div class="showroom-configure" *ngIf="is3d && showroomConfigure">
      <div class="flex items-center h-14">
        <button mat-button class="btn-36 !mx-3" matTooltipPosition="right" (click)="showroomConfigure = false">
          <mat-icon class="text-black80">chevron_left</mat-icon>
        </button>
        <h6>Showroom Configuration</h6>
      </div>
      <mat-divider></mat-divider>
      <app-webgl-content-configurator
        [content]="selectedContent$ | async"
        [size]="'339px'"
        [viewMode]="'modal'"
      ></app-webgl-content-configurator>
    </div>
  </div>
</div>

<div
  class="loading bg-white rounded flex-center flex-col absolute w-80 h-40 right-0 top-0 bottom-0 left-0 m-auto"
  *ngIf="loading$ | async"
>
  <mat-spinner diameter="40"> </mat-spinner>
  <h6>Loading</h6>
</div>

<div
  class="loading bg-white rounded flex-center flex-col absolute w-80 h-40 right-0 top-0 bottom-0 left-0 m-auto"
  *ngIf="uploading$ | async"
>
  <mat-spinner diameter="40"> </mat-spinner>
  <h6>Files Uploading...</h6>
</div>
