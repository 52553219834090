import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../collection-manager-store';
import { CollectionManagerService } from '../collection-manager.service';
import { DataGroup } from '../data-group/data-group';
import { DataGroupHelper } from '../data-group/data-group-helper';
import { ViewDefinition } from '@contrail/client-views';

interface MatrixRow {
  id: string;
  set: Array<any>;
}
@Component({
  selector: 'app-matrix-view',
  templateUrl: './matrix-view.component.html',
  styleUrls: ['./matrix-view.component.scss'],
})
export class MatrixViewComponent implements OnInit, OnDestroy {
  constructor(
    private collectionManagerService: CollectionManagerService,
    private store: Store<RootStoreState.State>,
    private dataGroupHelper: DataGroupHelper,
  ) {
    this.subscribe();
  }
  public data: any;
  public currentView: ViewDefinition;
  public groupingProperty1: TypeProperty;
  public groupingProperty2: TypeProperty;
  public matrix: Array<MatrixRow>;

  private dataSub: Subscription;
  private editorModeSub: Subscription;
  public editorMode = 'VIEW';
  private currentViewSub: Subscription;
  ngOnInit() {
    this.subscribe();
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {
    this.dataSub = this.store.select(CollectionManagerSelectors.displayedData).subscribe((data) => {
      if (data) {
        this.data = data;
        this.initMatrix();
      }
    });
    this.currentViewSub = this.store.select(CollectionManagerSelectors.selectCurrentView).subscribe((view) => {
      if (view && view.viewType === 'matrix') {
        this.currentView = view;
        this.initMatrix();
      }
    });
    this.editorModeSub = this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
  }

  initMatrix() {
    if (!this.data || !this.currentView) {
      return;
    }
    this.matrix = [];
    const dim1 = this.currentView.dimensions[0].split(':');
    const dim1TypeRoot = dim1[0];
    const dim1PropertySlug = dim1[1];
    const dim1PropertyScope = this.dataGroupHelper.getPropertyScope(dim1PropertySlug);
    const dim2 = this.currentView.dimensions[1].split(':');
    const dim2TypeRoot = dim2[0];
    const dim2PropertySlug = dim2[1];
    const dim2PropertyScope = this.dataGroupHelper.getPropertyScope(dim2PropertySlug);
    console.log('dims: ', dim1, dim2);
    this.groupingProperty1 = this.dataGroupHelper.getProperty(dim1TypeRoot, dim1PropertySlug);
    this.groupingProperty2 = this.dataGroupHelper.getProperty(dim2TypeRoot, dim2PropertySlug);
    this.matrix = [];

    // Row 1: Group 1 is null
    let set = [];
    set.push(this.createGroup('Not Set', 'Not Set', null, null, dim1PropertyScope, dim2PropertyScope));
    this.groupingProperty2.options.forEach((option2) => {
      set.push(this.createGroup('Not Set', option2.display, null, option2.value, dim1PropertyScope, dim2PropertyScope));
    });
    this.matrix.push({ id: 'Not Set', set });

    // Rest of Rows, one per option 1 val
    this.groupingProperty1.options.forEach((option1) => {
      set = [];
      set.push(this.createGroup(option1.display, 'Not Set', option1.value, null, dim1PropertyScope, dim2PropertyScope));
      // Both null
      this.groupingProperty2.options.forEach((option2) => {
        set.push(
          this.createGroup(
            option1.display,
            option2.display,
            option1.value,
            option2.value,
            dim1PropertyScope,
            dim2PropertyScope,
          ),
        );
      });
      this.matrix.push({ id: option1.display, set });
    });
    console.log('matrix: ', this.matrix);
  }
  createGroup(label1, label2, val1, val2, scope1, scope2): DataGroup {
    return {
      dim1: {
        label: label1,
        scope: scope1,
        value: val1,
        slug: this.groupingProperty1.slug,
        propertyDefinition: this.groupingProperty1,
      },
      dim2: {
        label: label2,
        scope: scope2,
        value: val2,
        slug: this.groupingProperty2.slug,
        propertyDefinition: this.groupingProperty2,
      },
      data: this.getData(val1, val2),
    };
  }
  getData(val1, val2) {
    if (!val1 || !val2) {
    }
    return this.data.filter((obj) => {
      return (
        (obj[this.groupingProperty1.slug] === val1 || (obj[this.groupingProperty1.slug] === undefined && !val1)) &&
        (obj[this.groupingProperty2.slug] === val2 || (obj[this.groupingProperty2.slug] === undefined && !val2))
      );
    });
  }

  unsubscribe() {
    this.dataSub.unsubscribe();
    this.currentViewSub.unsubscribe();
    this.editorModeSub.unsubscribe();
  }
  drop(event: CdkDragDrop<string[]>) {
    this.dataGroupHelper.handleDrop(event, this.matrix, [this.groupingProperty1, this.groupingProperty2]);
  }

  testDrop(event) {
    console.log('testDrop: ', event);
  }
  dragOver(event) {
    // event.preventDefault();
    // console.log("dragOver: ", event)
  }
  groupTrackByFn(index, group: any) {
    return group.dim1.value + group.dim2.value;
  }
  matrixRowTrackByFn(index, row: MatrixRow) {
    return row.id;
  }
}
