<div data-test="data-group" class="data-group" *ngIf="group">
  <div class="group-heading">
    <div class="group-title">
      <div class="count">{{ group.data.length }}</div>
      <span class="text-ellipsis overflow-hidden whitespace-nowrap max-w-[150px]" [matTooltip]="group.dim1.label">{{
        group.dim1.label
      }}</span>
      <span
        class="text-ellipsis overflow-hidden whitespace-nowrap max-w-[150px]"
        [matTooltip]="group.dim2.label"
        *ngIf="group.dim2"
      >
        - {{ group.dim2.label }}</span
      >
    </div>
    <div class="group-actions" *ngIf="editorMode === 'EDIT'">
      <!--<mat-icon *ngIf="editorMode === 'EDIT'" (click)="add()">add</mat-icon>-->
      <app-create-placeholders (createPlaceholders)="createPlaceholders($event)"></app-create-placeholders>
    </div>
  </div>
  <virtual-scroller
    [items]="group.data"
    #scroll
    cdkDropList
    [cdkDropListData]="group.data"
    class="item-list"
    (cdkDropListDropped)="listDrop($event)"
  >
    <app-data-group-list-item
      [listItem]="item"
      [editorMode]="editorMode"
      [group]="group"
      *ngFor="let item of scroll.viewPortItems"
      [properties]="properties"
    >
    </app-data-group-list-item>
  </virtual-scroller>
</div>
