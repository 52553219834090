import { ViewDefinition } from '@contrail/client-views';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { SidePanelService, SidePanelView } from './side-panel.service';
import { FocusedItemFamilyService } from '../focus-item/focused-item-family.service';
import { CollectionStatusMessage } from '../collection-manager-store/collection-status-messages/collection-status-messages.state';
import { CollectionStatusMessageService } from './status-messages/collection-status-message.service';
import { CommentsContextReferenceFilter } from '@common/comments/context-comments-list/context-comments-list.component';
import { PlanSnapshotService } from '../snapshot/plan-snapshot.service';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  host: {
    '[style.height]': "showSidePanel? 'calc(100% - 113px)': '1px'",
  },
})
export class SidePanelComponent implements OnInit, OnDestroy {
  constructor(
    private sidePanelService: SidePanelService,
    private focusedItemFamilyService: FocusedItemFamilyService,
    private collectionMessageStatusService: CollectionStatusMessageService,
    private planSnapshotService: PlanSnapshotService,
    private store: Store<RootStoreState.State>,
  ) {}

  public showSidePanel = false;
  public currentView: SidePanelView;
  private subscriptions: Subscription = new Subscription();
  public selectedEntityIds: any[];
  public editorMode;
  public chooserLoaded = false;
  public baseCriteria: any = { roles: 'option', isPrimary: true };
  public currentViewDefinition: ViewDefinition;
  public typeDefinition: any;
  messages$: Observable<Array<CollectionStatusMessage>>;
  public contextReferenceTypes$: Observable<Array<CommentsContextReferenceFilter>>;
  public currentPlan;

  ngOnInit(): void {
    this.subscribe();
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
  private subscribe() {
    this.contextReferenceTypes$ = combineLatest([this.store.select(PlansSelectors.currentPlan)]).pipe(
      switchMap(async ([plan]) => {
        if (plan) {
          return [
            {
              key: 'contextReference',
              value: `plan:${plan?.id}`,
              label: 'Plan',
              selected: true,
              ownerInfo: {
                entityType: 'plan',
                id: plan?.id,
              },
            },
          ];
        }
      }),
    );

    this.subscriptions.add(
      this.store.select(CollectionManagerSelectors.currentViewDefinition).subscribe((currentViewDefinition) => {
        this.currentViewDefinition = currentViewDefinition;
      }),
    );

    this.subscriptions.add(
      this.store.select(PlansSelectors.currentPlan).subscribe((currentPlan) => {
        this.currentPlan = currentPlan;
      }),
    );

    this.subscriptions.add(
      this.store.select(CollectionManagerSelectors.typeDefinitions).subscribe((typeMap) => {
        this.typeDefinition = typeMap['plan-placeholder'];
      }),
    );

    this.subscriptions.add(
      this.sidePanelService.currentView.subscribe((view) => {
        if (view?.slug?.length) {
          this.showSidePanel = true;
        } else {
          this.showSidePanel = false;
        }
        this.currentView = view;
        if (this.currentView?.slug === 'addItem') {
          this.chooserLoaded = true;
        }
      }),
    );

    this.subscriptions.add(
      this.focusedItemFamilyService.focusedItemSearchCriteria$.subscribe((baseCriteria) => {
        this.baseCriteria = baseCriteria;
      }),
    );
    this.subscriptions.add(this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode)));
    this.messages$ = this.collectionMessageStatusService.messages$;
  }

  private unsubscribe() {
    this.subscriptions.unsubscribe();
  }

  loadSidePanel(view) {
    this.sidePanelService.setCurrentView(view);
  }

  handleClose() {
    if (this.currentView.slug === 'versionHistory') {
      // this.planSnapshotService.clearSnapshot();
    }
    this.showSidePanel = false;
    this.sidePanelService.setCurrentView(null);
  }

  handleViewDefinitionUpdated(viewDefinition: ViewDefinition) {
    this.store.dispatch(
      CollectionManagerActions.updateViewDefinitionSuccess({ id: viewDefinition.id, changes: viewDefinition }),
    );
  }

  handleMessageClick(event) {
    this.collectionMessageStatusService.setStatusMessageElement(event);
  }
}
