import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { PropertyType, PropertyValueFormatter } from '@contrail/types';
import { CollectionDataEntity } from 'src/app/collection-manager/collection-manager.service';

const formatter = new PropertyValueFormatter();
@Component({
  selector: 'app-data-cell-view',
  templateUrl: './data-cell-view.component.html',
  styleUrls: ['./data-cell-view.component.scss'],
})
export class DataCellViewComponent implements OnChanges {
  @Input() property: ViewPropertyConfiguration;
  @Input() data: CollectionDataEntity;
  @Input() display: any;
  @Input() value: any;
  @Input() align: string;
  @Input() selected: boolean;
  @Input() accessLevel = 'EDIT';
  public propertyType: PropertyType;
  constructor() {}

  ngOnChanges() {
    if (this.data && this.property) {
      this.setModel();
    }
  }
  setModel() {
    this.propertyType = this.property.propertyDefinition?.propertyType as PropertyType;
  }
}
