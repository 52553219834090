import { createAction, props } from '@ngrx/store';
import { CollectionStatusMessage } from './collection-status-messages.state';

export enum CollectionStatusMessagesActionTypes {
  CLEAR_STATUS_MESSAGES = '[CollectionStatusMessages] Clear Messages',
  SET_STATUS_MESSAGE_ENTITIES = '[CollectionStatusMessages] Set Status Messages',
  ADD_STATUS_MESSAGE_ENTITY = '[CollectionStatusMessages] Add Status Message',
  ADD_STATUS_MESSAGE_ENTITIES = '[CollectionStatusMessages] Add Status Messages',
  REMOVE_STATUS_MESSAGE_ENTITY = '[CollectionStatusMessages] Remove Status Message',
  REMOVE_STATUS_MESSAGE_ENTITIES = '[CollectionStatusMessages] Remove Status Messages',
  UPDATE_STATUS_MESSAGE_ENTITIES = '[CollectionStatusMessages] Update Status Messages',
}

////////////////////////////////////////////////////////////////////////////////
export const clearStatusMessages = createAction(CollectionStatusMessagesActionTypes.CLEAR_STATUS_MESSAGES);
export const setStatusMessages = createAction(
  CollectionStatusMessagesActionTypes.SET_STATUS_MESSAGE_ENTITIES,
  props<{ messages: CollectionStatusMessage[] }>(),
);
export const addStatusMessage = createAction(
  CollectionStatusMessagesActionTypes.ADD_STATUS_MESSAGE_ENTITY,
  props<{ message: CollectionStatusMessage }>(),
);
export const addStatusMessages = createAction(
  CollectionStatusMessagesActionTypes.ADD_STATUS_MESSAGE_ENTITIES,
  props<{ messages: CollectionStatusMessage[] }>(),
);
////////////////////////////////////////////////////////////////////////////////
export const removeStatusMessage = createAction(
  CollectionStatusMessagesActionTypes.REMOVE_STATUS_MESSAGE_ENTITY,
  props<{ id: any }>(),
);

export const removeStatusMessages = createAction(
  CollectionStatusMessagesActionTypes.REMOVE_STATUS_MESSAGE_ENTITIES,
  props<{ ids: any }>(),
);

export const updateStatusMessages = createAction(
  CollectionStatusMessagesActionTypes.UPDATE_STATUS_MESSAGE_ENTITIES,
  props<{ changes: any[] }>(),
);
