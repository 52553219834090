import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { SelectedCellInfo } from '../context-menu.component';
import { ClipboardCopyPasteHandler } from '../../grid-view/copy-paste/clipboard-copy-paste-handler';
import { CollectionManagerSelectors } from '../../collection-manager-store';

export class CopyContextMenuAction extends ContextMenuAction {
  sorts: [];
  constructor(
    protected store: Store<RootStoreState.State>,
    private copyPasteHandler: ClipboardCopyPasteHandler,
  ) {
    super(store);
    this.actionLabel = 'Copy cell';
    this.actionIcon = 'content_copy';
    this.shortCutKey = 'Ctrl-C';
    this.testRef = 'app-menu-copy-action';

    this.store.select(CollectionManagerSelectors.selectedEntityIds).subscribe((selectedEntityIds) => {
      if (selectedEntityIds.length > 0) {
        this.actionLabel = 'Copy row(s)';
      }
    });
  }

  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    let rowIds = [];
    if (ids.length > 0) {
      rowIds = ids;
    } else {
      rowIds = [selectedCellInfo.entityId];
    }
    this.copyPasteHandler.handleDocumentCopyEvent();
  }

  canInvoke(editorMode: string, ids: Array<string>, selectedCellInfo: SelectedCellInfo = null) {
    if (editorMode !== 'EDIT') {
      return false;
    }
    if (!ids?.length) {
      return false;
    }
    return true;
  }
}
