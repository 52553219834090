import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Plan } from '../../plans-store/plans.state';

@Component({
  selector: 'app-publish-summary',
  templateUrl: './publish-summary.component.html',
  styleUrls: ['./publish-summary.component.scss'],
})
export class PublishSummaryComponent implements OnChanges {
  @Input() plan: Plan;
  @Input() diff: any;

  constructor() {}

  ngOnChanges(): void {}
}
