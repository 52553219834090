import { createAction, props } from '@ngrx/store';
import { Plan, PlanRowOrder } from './plans.state';

export enum PlansActionTypes {
  LOAD_PLANS = '[Plans] Load Plans',
  LOAD_PLANS_SUCCESS = '[Plans] Load Plans Success',
  LOAD_PLANS_FAILURE = '[Plans] Load Plans Failure',

  CREATE_PLAN = '[Plans] Create Plan',
  CREATE_PLAN_SUCCESS = '[Plans] Create Plan Success',
  CREATE_PLAN_FAILURE = '[Plans] Create Plan Failure',

  DELETE_PLAN = '[Plans] Delete Plan',
  DELETE_PLAN_SUCCESS = '[Plans] Delete Plan Success',
  DELETE_PLAN_FAILURE = '[Plans] Delete Plan Failure',

  UPDATE_PLAN = '[Plans] Update Plan',
  UPDATE_PLAN_SUCCESS = '[Plans] Update Plan Success',
  UPDATE_PLAN_FAILURE = '[Plans] Update Plan Failure',

  LOAD_CURRENT_PLAN = '[Plans] Load Current Plan',
  LOAD_CURRENT_PLAN_SUCCESS = '[Plans] Load Current Plan Success',
  LOAD_CURRENT_PLAN_FAILURE = '[Plans] Load Current Plan Failure',

  CLEAR_CURRENT_PLAN = '[Plans] Clear Current Plan',
  SET_PLANS_LOADED = '[Plans] Set Plans Loaded',
  SET_CURRENT_PLAN_GOALS = '[Plans] Set Current Plan Goals',
  SET_EDITOR_MODE = '[Plans] Set Editor Mode',

  SET_PLAN_ROW_ORDER = '[Plans] Set Plan Row Order',
  UPDATE_PLAN_ROW_ORDER = '[Plans] Update Plan Row Order',
  SYNC_PLAN_ROW_ORDER = '[Plans] Sync Plan Row Order',

  COPY_PLAN = '[Plan] Copy Plan',
  COPY_PLAN_SUCCESS = '[Plan] Copy Plan Success',
  COPY_PLAN_FAILURE = '[Plan] Copy Plan Failure',

  ASYNC_COPY_PLAN = '[Plan] Async Plan Showcase',
  ASYNC_COPY_PLAN_SUCCESS = '[Plan] Async Copy Plan Success',

  LOAD_PLAN_EDITOR_MODE = '[Plans] Load Plan Editor Mode',
}

////////////////////////////////////////////////////////////////////////////////
export const setEditorMode = createAction(PlansActionTypes.SET_EDITOR_MODE, props<{ editorMode: string }>());
export const setCurrentPlanGoals = createAction(
  PlansActionTypes.SET_CURRENT_PLAN_GOALS,
  props<{ planGoals: Array<any> }>(),
);
export const setPlansLoaded = createAction(PlansActionTypes.SET_PLANS_LOADED, props<{ loaded: boolean }>());
export const loadPlans = createAction(PlansActionTypes.LOAD_PLANS);

export const loadPlansSuccess = createAction(PlansActionTypes.LOAD_PLANS_SUCCESS, props<{ data: Array<any> }>());

export const loadPlansFailure = createAction(PlansActionTypes.LOAD_PLANS_FAILURE, props<{ error: any }>());

export const createPlan = createAction(PlansActionTypes.CREATE_PLAN, props<{ plan: Plan }>());

export const createPlanSuccess = createAction(PlansActionTypes.CREATE_PLAN_SUCCESS, props<{ plan: Plan }>());

export const createPlanFailure = createAction(PlansActionTypes.CREATE_PLAN_FAILURE, props<{ error: any }>());

export const updatePlan = createAction(PlansActionTypes.UPDATE_PLAN, props<{ id: string; changes: Plan }>());

export const updatePlanSuccess = createAction(
  PlansActionTypes.UPDATE_PLAN_SUCCESS,
  props<{ id: string; changes: Plan }>(),
);

export const updatePlanFailure = createAction(PlansActionTypes.UPDATE_PLAN_FAILURE, props<{ error: any }>());

export const deletePlan = createAction(PlansActionTypes.DELETE_PLAN, props<{ plan: Plan }>());

export const deletePlanSuccess = createAction(PlansActionTypes.DELETE_PLAN_SUCCESS, props<{ plan: Plan }>());

export const deletePlanFailure = createAction(PlansActionTypes.DELETE_PLAN_FAILURE, props<{ error: any }>());

export const loadCurrentPlan = createAction(PlansActionTypes.LOAD_CURRENT_PLAN, props<{ id: string }>());

export const loadCurrentPlanSuccess = createAction(PlansActionTypes.LOAD_CURRENT_PLAN_SUCCESS, props<{ plan: Plan }>());

export const loadCurrentPlanFailure = createAction(PlansActionTypes.LOAD_CURRENT_PLAN_FAILURE, props<{ error: any }>());

export const clearCurrentPlan = createAction(PlansActionTypes.CLEAR_CURRENT_PLAN);

export const setPlanRowOrder = createAction(
  PlansActionTypes.SET_PLAN_ROW_ORDER,
  props<{ planRowOrder: PlanRowOrder }>(),
);

export const updatePlanRowOrder = createAction(
  PlansActionTypes.UPDATE_PLAN_ROW_ORDER,
  props<{ rowIds: Array<string>; targetRowIndex?: number; skipRecordingUndoRedo?: boolean }>(),
);

export const syncPlanRowOrder = createAction(
  PlansActionTypes.SYNC_PLAN_ROW_ORDER,
  props<{ planRowOrder: PlanRowOrder }>(),
);

export const copyPlan = createAction(PlansActionTypes.COPY_PLAN, props<{ name: string; sourceId: string }>());

export const copyPlanSuccess = createAction(PlansActionTypes.COPY_PLAN_SUCCESS, props<{ plan: Plan }>());

export const copyPlanFailure = createAction(PlansActionTypes.COPY_PLAN_FAILURE, props<{ error: any }>());

export const asyncCopyPlan = createAction(
  PlansActionTypes.ASYNC_COPY_PLAN,
  props<{ name: string; sourceId: string }>(),
);

export const asyncCopyPlanSuccess = createAction(
  PlansActionTypes.ASYNC_COPY_PLAN_SUCCESS,
  props<{ jobId: string; path: string }>(),
);

export const loadPlanEditorMode = createAction(
  PlansActionTypes.LOAD_PLAN_EDITOR_MODE,
  props<{ workspaceId: string }>(),
);
