import { Component, Input, OnInit } from '@angular/core';
import { ViewPropertyConfiguration } from '@contrail/client-views';

@Component({
  selector: 'app-aggregate-row',
  templateUrl: './aggregate-row.component.html',
  styleUrls: ['./aggregate-row.component.scss'],
})
export class AggregateRowComponent implements OnInit {
  @Input() properties: Array<ViewPropertyConfiguration>;
  @Input() side: string;
  constructor() {}

  ngOnInit(): void {}
}
