<button
  class="!w-10 !h-6"
  mat-icon-button
  disableRipple="true"
  [disabled]="isViewOnly"
  [matMenuTriggerFor]="menu"
  aria-label="Update comment color"
>
  <div class="flex flex-row">
    <div class="comment-color-chip m-[3px]" [style.background-color]="currentHex"></div>
    <mat-icon *ngIf="!isViewOnly" class="-ml-1.5 text-black60">arrow_drop_down</mat-icon>
  </div>
</button>
<mat-menu #menu="matMenu" class="menu-row comment-color-chip-menu">
  <ng-template matMenuContent>
    <button *ngFor="let hex of colorOptions" mat-menu-item (click)="updateComment($event, hex)" disableRipple="true">
      <div class="comment-color-chip" [style.background-color]="hex"></div>
    </button>
  </ng-template>
</mat-menu>
