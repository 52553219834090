import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { chunk } from 'lodash';
import pLimit from 'p-limit';
import { CollectionManagerActions } from './collection-manager-store';
import { PlansActions, PlansSelectors, RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { AsyncErrorsActions } from '@common/errors/async-errors-store';
import { ErrorActionType } from '@common/errors/async-errors-store/async-errors.state';
import { Item } from '@contrail/entity-types/lib/item';
import { nanoid } from 'nanoid';
import { UndoRedoActionType, UndoRedoObject } from './undo-redo/undo-redo-objects';
import { WebSocketService } from '@common/web-socket/web-socket.service';
import { UndoRedoService } from '@common/undo-redo/undo-redo-service';
import { PlansService } from '../plans/plans.service';
import { filter, take } from 'rxjs';
import { Plan, PlanRowOrder } from '../plans/plans-store/plans.state';
import { PlaceholderUtil } from './placeholders/placeholder-util';
const limit = pLimit(3);

export interface CollectionDataEntity {
  id?: string;
  itemFamilyId?: string;
  itemOptionId?: string;
  itemFamily?: Item;
  itemOption?: Item;
  [key: string]: any;
}

export interface BatchUpdateCollectionDataEntityRequest {
  changes: Array<{ id: string; changes: any }>;
  undoActionUuid?: string;
}

export interface UpdateCollectionDataEntityRequest {
  entity: CollectionDataEntity;
  changes: any;
  selectedCell?: {
    rowId: string;
    propertySlug: string;
  };
  undoActionUuid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CollectionManagerService {
  private currentPlan: Plan;

  constructor(
    private store: Store<RootStoreState.State>,
    private webSocketService: WebSocketService,
    private undoRedoService: UndoRedoService,
    private planService: PlansService,
  ) {
    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(filter((plan) => !!plan))
      .subscribe((plan) => {
        this.currentPlan = plan;
      });
  }

  async getCollectionData(planId: string) {
    const plan = await new Entities().get({
      entityName: 'plan',
      id: planId,
      relations: [
        'planPlaceholders',
        'planPlaceholders.itemFamily',
        'planPlaceholders.itemOption',
        'planPlaceholders.projectItem',
      ],
    });

    if (plan.planPlaceholdersDownloadURL) {
      const response = await fetch(plan.planPlaceholdersDownloadURL);
      const placeholdersResp = await response.json();
      plan.planPlaceholders = placeholdersResp;
    }

    return plan.planPlaceholders;
  }

  buildEntityForCreate(entity: CollectionDataEntity) {
    const id = entity.id || nanoid(16);
    return Object.assign(
      { ...entity },
      {
        planId: this.currentPlan.id,
        id,
        specifiedId: id,
        createdOn: new Date().toISOString(),
        updatedOn: new Date().toISOString(),
      },
    );
  }

  async createEntity(placeholder) {
    this.store.dispatch(CollectionManagerActions.updateSubscribedTopics({ placeholders: [placeholder] }));
    const reducedPlaceholder = await this.buildReducePlaceholderEntityForSave(placeholder);
    return await new Entities().create({ entityName: 'plan-placeholder', object: reducedPlaceholder });
  }

  async createEntities(placeholders, batchSize = 100) {
    this.store.dispatch(CollectionManagerActions.updateSubscribedTopics({ placeholders: placeholders }));
    const reducedPlaceholders = await Promise.all(
      placeholders.map((placeholder) => this.buildReducePlaceholderEntityForSave(placeholder)),
    );

    const placeholdersInBatches = chunk(reducedPlaceholders, batchSize);
    const promises: Array<Promise<any>> = [];

    for (const placeholdersToCreate of placeholdersInBatches) {
      const promise = limit(async () => {
        return await new Entities().batchCreate({ entityName: 'plan-placeholder', objects: placeholdersToCreate });
      });
      promises.push(promise);
    }

    const createdPlaceholders = await Promise.all(promises);
    return createdPlaceholders.flat();
  }

  async deleteEntity(entity: CollectionDataEntity) {
    await new Entities().delete({ entityName: 'plan-placeholder', id: entity.id });
    return entity;
  }
  async deleteEntities(ids: Array<string>) {
    await new Entities().batchDelete({ entityName: 'plan-placeholder', ids });
    return ids;
  }
  async updateEntity({ entity, changes, undoActionUuid, selectedCell }: UpdateCollectionDataEntityRequest) {
    this.store.dispatch(CollectionManagerActions.updateSubscribedTopics({ placeholders: [changes] }));

    const reducedPlaceholderChanges = await this.buildReducePlaceholderEntityForSave(changes);

    // Remove all createdOn && updatedOn values
    delete reducedPlaceholderChanges.createdOn;
    delete reducedPlaceholderChanges.updatedOn;
    return new Entities()
      .update({ entityName: 'plan-placeholder', id: entity.id, object: reducedPlaceholderChanges })
      .catch((error) => {
        this.store.dispatch(
          AsyncErrorsActions.addAsyncError({
            error,
            errorType: ErrorActionType.UPDATE_PLACEHOLDERS,
            undoActionUuid,
            selectedCell,
          }),
        );
      });
  }
  private async updateBatchOfEntities(changes: Array<any>): Promise<any[]> {
    this.store.dispatch(CollectionManagerActions.updateSubscribedTopics({ placeholders: changes }));
    // Remove all createdOn && updatedOn values
    const changesClone = ObjectUtil.cloneDeep(changes);
    for (let change of changesClone) {
      delete change.changes?.createdOn;
      delete change.changes?.updatedOn;
    }

    return new Entities().batchUpdate({ entityName: 'plan-placeholder', objects: changesClone });
  }

  async updateEntities({ changes, undoActionUuid }: BatchUpdateCollectionDataEntityRequest) {
    const reducedPlaceholderChanges = await Promise.all(
      changes.map(async ({ id, changes }) => {
        const reducedChanges = await this.buildReducePlaceholderEntityForSave(changes);
        return { id, changes: reducedChanges };
      }),
    );

    const entityChangesInBatches = chunk(reducedPlaceholderChanges, 100);
    const promises: Array<Promise<any>> = [];

    for (const changesBatch of entityChangesInBatches) {
      const promise = limit(async () => {
        return await this.updateBatchOfEntities(changesBatch);
      });
      promises.push(promise);
    }

    const updatedEntities = await Promise.all(promises).catch((error) => {
      this.store.dispatch(
        AsyncErrorsActions.addAsyncError({
          error,
          errorType: ErrorActionType.UPDATE_PLACEHOLDERS,
          undoActionUuid,
        }),
      );
    });

    if (updatedEntities) {
      return updatedEntities.flat();
    }
  }

  async copyEntities(ids: Array<string>): Promise<CollectionDataEntity[]> {
    return await new Entities().batchCreate({ entityName: 'plan-placeholder', objects: ids, suffix: 'deep-copy' });
  }

  async createEntitiesAtIndices(entitiesByIndex: { entities: CollectionDataEntity[]; index: number }[]): Promise<void> {
    const changesList: UndoRedoObject[] = [];

    const placeholdersToCreateByIndex = entitiesByIndex.map((entitiesAtIndex) => {
      const entities = entitiesAtIndex.entities.map((entity) => {
        return this.buildEntityForCreate(entity);
      });

      return {
        entities,
        index: entitiesAtIndex.index,
      };
    });

    const placeholdersToCreateByIndexInInsertOrder = placeholdersToCreateByIndex.sort((a, b) => b.index - a.index);
    const placeholdersToCreate = placeholdersToCreateByIndex.map((obj) => obj.entities).flat();
    this.addPlaceholderAddsToChangesList(placeholdersToCreateByIndexInInsertOrder, changesList);

    this.store.dispatch(
      CollectionManagerActions.createCollectionDataEntitiesSuccess({
        entities: placeholdersToCreate,
      }),
    );

    const initialRowOrder = this.getPlanRowOrder();
    const updateRowOrderRequests = await this.buildUpdateRowOrderRequestsAndSyncLocally(
      placeholdersToCreateByIndexInInsertOrder,
      initialRowOrder,
      changesList,
    );

    const undoAction = this.undoRedoService.addUndo({
      actionType: UndoRedoActionType.CREATE_PLACEHOLDER,
      changesList,
    });

    try {
      await this.createEntities(placeholdersToCreate);
      for (const updateRowOrderRequest of updateRowOrderRequests) {
        await this.planService.updatePlanRowOrder(initialRowOrder.id, updateRowOrderRequest);
      }
    } catch (error) {
      this.store.dispatch(
        AsyncErrorsActions.addAsyncError({
          error,
          errorType: ErrorActionType.CREATE_PLACEHOLDERS,
          undoActionUuid: undoAction.uuid,
        }),
      );

      throw new Error(error);
    }
  }

  public async buildReducePlaceholderEntityForSave(placeholder: CollectionDataEntity): Promise<CollectionDataEntity> {
    const reducedPlaceholder = ObjectUtil.cloneDeep(placeholder);
    delete reducedPlaceholder.itemOption;
    delete reducedPlaceholder.itemFamily;

    if (reducedPlaceholder.itemOptionId || reducedPlaceholder.itemFamilyId) {
      await PlaceholderUtil.deleteMappedPropertiesFromPlaceholder(reducedPlaceholder);
    }

    return reducedPlaceholder;
  }

  private addPlaceholderAddsToChangesList(
    placeholdersToCreateByIndex: { entities: CollectionDataEntity[]; index: number }[],
    changesList: UndoRedoObject[],
  ): void {
    for (const placeholdersByIndex of placeholdersToCreateByIndex) {
      const newPlaceholderIds = placeholdersByIndex.entities.map((entity) => entity.id).filter((id) => !!id);
      newPlaceholderIds.forEach((id, index) => {
        changesList.push({
          id,
          changes: ObjectUtil.cloneDeep(placeholdersByIndex.entities[index]),
          undoChanges: null,
          objectType: 'placeholder',
        });
      });
    }
  }

  private buildUpdateRowOrderRequestsAndSyncLocally(
    placeholdersToCreateByIndex: { entities: CollectionDataEntity[]; index: number }[],
    initialRowOrder: PlanRowOrder,
    changesList: UndoRedoObject[],
  ): { rowIds: string[]; targetRowIndex: number }[] {
    const indexOfLastRow = initialRowOrder.rowOrder.length;
    const updatedRowOrder = ObjectUtil.cloneDeep(initialRowOrder);
    const updateRowOrderRequests: { rowIds: string[]; targetRowIndex: number }[] = [];

    for (const placeholdersByIndex of placeholdersToCreateByIndex) {
      const isValidIndex = typeof placeholdersByIndex.index === 'number' && placeholdersByIndex.index > -1;
      const insertIndex = isValidIndex ? placeholdersByIndex.index : indexOfLastRow;

      const newPlaceholderIds = placeholdersByIndex.entities.map((entity) => entity.id).filter((id) => !!id);

      updatedRowOrder.rowOrder.splice(insertIndex, 0, ...newPlaceholderIds);
      updateRowOrderRequests.push({ rowIds: newPlaceholderIds, targetRowIndex: insertIndex });

      changesList.push({
        id: updatedRowOrder.id,
        changes: { rowIds: newPlaceholderIds, targetRowIndex: insertIndex },
        undoChanges: { rowIds: newPlaceholderIds, targetRowIndex: -1 },
        objectType: 'rowOrder',
      });
    }

    /**
     * Dispatch updated planRowOrder before the api requests are sent for rendering performance.
     * Doing this in a setTimeout allows the collection data store to be updated first
     * */
    setTimeout(() => {
      this.store.dispatch(PlansActions.syncPlanRowOrder({ planRowOrder: updatedRowOrder }));
      this.webSocketService.sendSessionEvent({ eventType: 'SYNC_PLAN_ROW_ORDER', changes: updatedRowOrder });
    }, 1);

    return updateRowOrderRequests;
  }

  private getPlanRowOrder() {
    let planRowOrder: PlanRowOrder;

    this.store
      .select(PlansSelectors.planRowOrder)
      .pipe(
        filter((rowOrder) => !!rowOrder),
        take(1),
      )
      .subscribe((rowOrder) => {
        planRowOrder = rowOrder;
      });

    return planRowOrder;
  }
}
