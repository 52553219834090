<div [ngClass]="{ hidden: !(loaded$ | async) }" style="height: 100%; overflow: hidden" (click)="handleClick($event)">
  <router-outlet></router-outlet>
</div>

<div *ngIf="!(loaded$ | async)" class="loading-page">
  <img class="logo" src="assets/images/vibeiq_large.jpg" />
  <mat-spinner diameter="60"></mat-spinner>
  <span>{{ message }}</span>
</div>
<!-- TEST -->
