import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewDefinition } from '@contrail/client-views';
import { TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { CollectionDataEntity } from '../collection-manager.service';
import { PlaceholderItemCreationService } from '../placeholders/placeholder-item-creation-service';
import { SidePanelService } from '../side-panel/side-panel.service';
import { PlaceholderItemPromoteService } from '../placeholders/placeholder-item-promote-service';
import { AuthService } from '@common/auth/auth.service';
import { SearchReplaceService } from '../grid-view/search-replace.service';
import { SelectorCell } from '../grid-view/selectors/grid-selector.service';
import { SearchReplaceComponent } from '@components/search-replace/search-replace.component';
import { PivotGridService } from '@components/pivot-grid/pivot-grid-service';
import { FeatureFlagRegistryService } from '@common/feature-flags/feature-flags.service';
import { Feature, FeatureFlag } from '@common/feature-flags/feature-flag';
import { AppExtensionsActions, AppExtensionsSelectors } from '@common/app-extensions/app-extensions-store';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { AppExtension, AppExtensionUserApps } from '@contrail/entity-types';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';
import { ViewPropertyConfiguratorComponent } from '../collection-views/view-property-configurator/view-property-configurator.component';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit {
  @ViewChild(SearchReplaceComponent) searchReplace: SearchReplaceComponent;
  @ViewChild(ViewPropertyConfiguratorComponent) viewPropertyConfigurator: ViewPropertyConfiguratorComponent;

  planData$: Observable<Array<CollectionDataEntity>>;
  exportProperties$: Observable<Array<TypeProperty>>;
  owner$: Observable<any>;
  currentView$: Observable<any>;
  editorMode$: Observable<any>;
  selectedIds: Array<string>;
  focusedFamilyItem$: Observable<any>;
  isOrgAdmin = false;
  adminFilterTooltip = 'Admin views can only be edited by Org Admins.';
  searchReplaceCells$: Observable<any>;
  public showPivotGoals$: Observable<boolean>;

  public productView = {
    label: 'Add Item',
    slug: 'addItem',
    icon: 'add_new_icon',
    isSVGIcon: true,
  };

  public planAppExtensions: Array<AppExtension> = [];
  private currentView: any;

  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
    private sidePanelService: SidePanelService,
    private pivotService: PivotGridService,
    private featureFlagService: FeatureFlagRegistryService,
    private searchReplaceService: SearchReplaceService,
    private placeholderItemPromoteService: PlaceholderItemPromoteService,
  ) {
    this.showPivotGoals$ = this.featureFlagService.getFeatureObservable(Feature.PROJECT_GOALS);
  }

  public relations = [
    'itemFamily',
    'itemOption',
    'itemFamily.primaryViewable',
    'itemFamily.primaryViewable.primaryFile',
    'itemOption.primaryViewable',
    'itemOption.primaryViewable.primaryFile',
  ];

  async ngOnInit(): Promise<void> {
    this.store.dispatch(AppExtensionsActions.loadAppExtensions());
    this.store
      .select(FeatureFlagsSelectors.featureFlags)
      .pipe(tap((flags: FeatureFlag[]) => this.subscribeToAppExtension(flags)))
      .subscribe();

    this.isOrgAdmin = this.authService.isAdmin();
    this.planData$ = this.store.select(CollectionManagerSelectors.displayedData);
    this.focusedFamilyItem$ = this.store.select(CollectionManagerSelectors.focusedFamilyItem);
    // this.subscriptions.push(
    this.exportProperties$ = this.store.select(CollectionManagerSelectors.selectCurrentView).pipe(
      map((view) => {
        return this.prepareExportProperties(view);
      }),
    );

    this.store
      .select(CollectionManagerSelectors.selectedEntityIds)
      .pipe(
        tap((ids) => {
          this.selectedIds = ids;
        }),
        map((ids) => {
          return ids?.length > 1;
        }),
      )
      .subscribe();

    this.owner$ = this.store.select(PlansSelectors.currentPlan);
    this.currentView$ = this.store.select(CollectionManagerSelectors.selectCurrentView);
    this.editorMode$ = this.store.select(PlansSelectors.editorMode);
    this.searchReplaceCells$ = this.store.select(CollectionManagerSelectors.searchReplaceCells);
    this.currentView$
      .pipe(
        tap((currentView) => {
          if (currentView?.viewType === 'grid') {
            this.searchReplace?.close();
          }
          this.currentView = currentView;
        }),
      )
      .subscribe();
    this.searchReplaceService.toggleSearchReplace$
      .pipe(
        tap((toggleSearchReplace) => {
          this.searchReplace.toggleFocus();
        }),
      )
      .subscribe();
  }

  subscribeToAppExtension(flags: FeatureFlag[]) {
    const hasExtensionsFlag = flags.map((x) => x.featureName).includes(Feature.EXTENSIONS);
    if (!hasExtensionsFlag) return;

    this.store.select(AppExtensionsSelectors.appExtensions).subscribe((extensions) => {
      this.planAppExtensions = extensions.filter((extension) =>
        extension.userApps.some((appType) => [AppExtensionUserApps.PLAN, AppExtensionUserApps.ALL].includes(appType)),
      );
    });
  }

  prepareExportProperties(view: ViewDefinition) {
    const exportProperties = [];
    if (view.properties) {
      const properties = view.properties;
      properties.forEach((property) => {
        if (property.enabled) {
          exportProperties.push(property.propertyDefinition);
        }
      });
    }
    return exportProperties;
  }

  loadSidePanel(view) {
    this.sidePanelService.setCurrentView(view);
  }

  promoteItems() {
    let placeholders;
    this.store
      .select(CollectionManagerSelectors.displayedData)
      .pipe(
        take(1),
        tap((data) => {
          placeholders = data.filter((obj) => this.selectedIds.includes(obj.id));
        }),
      )
      .subscribe();
    this.placeholderItemPromoteService.promotePlaceholderItems(placeholders);
    this.store.dispatch(CollectionManagerActions.clearSelectedEntityIds());
  }

  showPivotConfigurator() {
    const view = {
      label: 'View Options',
      slug: 'pivotViewOptions',
      icon: '',
    };
    this.sidePanelService.setCurrentView(view);
  }

  searchForCells(value: string) {
    this.searchReplaceService.search(value);
  }

  setActiveSearchReplaceCell(cell: SelectorCell) {
    this.searchReplaceService.setActiveSearchReplaceCell(cell);
  }

  closeSearchReplacePanel() {
    this.searchReplaceService.resetSearchReplace();
  }

  toggleShowGoals(event) {
    let value = event.checked;
    this.pivotService.setShowGoals(value);
  }

  public get isOptionDisabled(): boolean {
    return !this.isOrgAdmin && this.currentView?.admin;
  }

  openPropertyConfigurator() {
    if (this.isOptionDisabled || this.currentView?.viewType === 'pivot') {
      return;
    }

    if (!this.viewPropertyConfigurator.propertyMenu.panelVisible) {
      setTimeout(() => {
        this.viewPropertyConfigurator.propertyMenu.togglePanel();
      });
    }
  }
}
