<div class="search-bar mb-2 mt-2">
  <app-search-bar #searchBar data-test="search-bar" [placeholder]="'Search'" [showClearButton]="true"></app-search-bar>
</div>
<div
  class="property-list narrow-scroll"
  cdkDropList
  [class.disable-reorder]="!isReorderInSameListAllowed"
  [cdkDropListAutoScrollStep]="10"
  [cdkDropListSortingDisabled]="!isReorderInSameListAllowed"
  (cdkDropListDropped)="droppedIntoList($event)"
>
  <div
    *ngFor="let property of filteredProperties$ | async; trackBy: rowTrackByFn"
    class="property-row"
    data-test="view-property-row"
    [class.selected]="isSelected(property.typeProperty.id)"
    [class.cdk-visually-hidden]="isSelected(property.typeProperty.id) && draggingRef"
    cdkDrag
    cdkDragBoundary=".view-property-configuration"
    (cdkDragStarted)="dragStarted($event, property.typeProperty.id)"
    (cdkDragEnded)="dragEnded()"
    (cdkDragDropped)="dropped($event)"
    (click)="select($event, property.typeProperty.id)"
    (dblclick)="handleDoubleClick(property)"
  >
    <div
      class="property-display line-ellipsis"
      [class.freeze-column]="property.typeProperty.id === 'freezeColumnBreakpoint'"
    >
      {{ property.typeProperty.label }}
      <div *cdkDragPreview class="select-property-drag-preview">
        <div class="first-ghost ghost-shadow p-3">
          <div class="line-ellipsis">
            {{ property.typeProperty.label }}
          </div>
        </div>
        <ng-container *ngIf="selectedIndexes.length > 1">
          <span class="num-ghost">{{ selectedIndexes.length }}</span>
          <div class="second-ghost ghost-shadow"></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
