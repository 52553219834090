import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SortOrderOptions } from '@contrail/sdk';
import { ListColumnDefinition } from '../list-column-definition';
import { ListItemData } from '../list-item-data';

export interface ListSortBy {
  label: string;
  direction: SortOrderOptions;
}

@Component({
  selector: 'app-virtual-scroll-list',
  templateUrl: './virtual-scroll-list.component.html',
  styleUrls: ['./virtual-scroll-list.component.scss'],
})
export class VirtualScrollListComponent implements OnChanges {
  @Input() columnDefinitions: Array<ListColumnDefinition>;
  @Input() sortBy: ListSortBy = null;
  @Input() listData: Array<ListItemData>;
  @Input() shortenCellCharacterLimit: number;
  @Output() doubleClickRow = new EventEmitter();
  @Output() clickRow = new EventEmitter();
  @Output() clickHeaderColumn = new EventEmitter();
  constructor() {}

  ngOnChanges(): void {}

  sort(column) {
    this.clickHeaderColumn.emit(column);
  }

  handleDoubleClickRow(listItemData) {
    this.doubleClickRow.emit(listItemData);
  }
  handleClickRow(listItemData) {
    this.clickRow.emit(listItemData);
  }
}
