import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidePanelComponent } from './side-panel.component';
import { SidePanelOptionsComponent } from './side-panel-options/side-panel-options.component';
import { SummaryViewComponent } from './summary-view/summary-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { GoogleChartsModule } from '@common/angular-google-charts/google-charts.module';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { ItemChooserModule } from 'src/app/plan-item-chooser/plan-item-chooser.module';
import { CommentsModule } from 'src/app/common/comments/comments.module';
import { ComponentsModule } from 'src/app/common/components/components.module';
import { FilterModule } from 'src/app/common/components/filter/filter.module';
import { ItemsModule } from 'src/app/common/items/items.module';
import { TypesModule } from 'src/app/common/types/types.module';
import { SummaryMetricPanelComponent } from './summary-view/summary-metric-panel/summary-metric-panel.component';
import { CollectionManagerStoreModule } from '../collection-manager-store';
import { SidePanelService } from './side-panel.service';
import { AddPlanGoalComponent } from './summary-view/add-plan-goal/add-plan-goal.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ItemDataChooserModule } from 'src/app/common/item-data-chooser/item-data-chooser.module';
import { MatDividerModule } from '@angular/material/divider';
import { PipesModule } from '@common/pipes/pipes.module';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { CollectionStatusMessageModule } from '@common/collection-status-message/collection-status-message.module';
import { DocumentHistoryModule } from '@common/document-history/document-history.module';

@NgModule({
  declarations: [
    SidePanelComponent,
    SidePanelOptionsComponent,
    SummaryViewComponent,
    SummaryMetricPanelComponent,
    AddPlanGoalComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ItemDataChooserModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    CommentsModule,
    GoogleChartsModule,
    VirtualScrollerModule,
    MatSlideToggleModule,
    ItemsModule,
    TypesModule,
    ItemChooserModule,
    MatBadgeModule,
    FilterModule,
    BrowserAnimationsModule,
    CollectionManagerStoreModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDividerModule,
    PipesModule,
    MatAutocompleteModule,
    DragDropModule,
    MatExpansionModule,
    CollectionStatusMessageModule,
    DocumentHistoryModule,
  ],
  exports: [SidePanelComponent, SidePanelOptionsComponent, SummaryViewComponent, SummaryMetricPanelComponent],
  providers: [SidePanelService],
})
export class SidePanelModule {}
