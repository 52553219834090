import { SelectedCellInfo } from '../context-menu.component';
import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { GridViewManager } from '../../grid-view/grid-view.manager';
import { GridSelectorActionHandler } from '../../grid-view/selectors/grid-selector-action-handler';

export class ClearDataContextMenuAction extends ContextMenuAction {
  private selectedCells = [];
  constructor(
    protected store: Store<RootStoreState.State>,
    protected gridViewManager: GridViewManager,
    protected selectorActionHandler: GridSelectorActionHandler,
  ) {
    super(store);
    this.svgActionIcon = 'clear-icon';
    this.actionLabel = 'Clear cell data';
    this.shortCutKey = 'Del';
    this.testRef = 'app-menu-clear-data-action';
    this.store.select(CollectionManagerSelectors.selectedEntityIds).subscribe((selectedEntityIds) => {
      if (selectedEntityIds.length > 0) {
        this.actionLabel = 'Clear row data';
      }
    });
    this.store
      .select(CollectionManagerSelectors.selectorCells)
      .subscribe((selectedCells) => (this.selectedCells = selectedCells));
  }
  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    if (this.selectedCells.length > 1) {
      this.selectorActionHandler.deleteCells();
    } else if (this.gridViewManager.selectedCell) {
      this.gridViewManager.delete();
    } else {
      this.selectorActionHandler.clearRowData();
    }
  }
  canInvoke(editorMode: string, ids: Array<string>, selectedCellInfo: SelectedCellInfo = null) {
    if (editorMode !== 'EDIT') {
      return false;
    }
    if (!ids?.length) {
      return false;
    }
    return true;
  }
}
