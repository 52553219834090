import { Action, createReducer, on } from '@ngrx/store';
import * as UserSessionActions from './user-sessions.actions';
import { initialState, State } from './user-sessions.state';

export const userSessionFeatureKey = 'userSession';

const leaveSession = (state: State, { userSession }) => {
  return {
    ...state,
    userSessions: state.userSessions.filter((us) => us !== userSession),
  };
};
const removeRemoteUserFromSession = (state: State, { userSession }) => {
  return {
    ...state,
    userSessions: state.userSessions.filter((us) => us.user.clientId !== userSession.user.clientId), // THIS REALLY NEEDS TO BE BASED ON CLIENT ID... AS ONE USER CAN HAVE MULTIPLE TABS OPEN
  };
};
const addRemoteUserToSession = (state: State, { userSession }) => {
  // Adds, only if this clientId is not already in the list.
  // This level of safegaurding should not be needed, but resolves
  // an issue where the same client was being added multiple times due to synchronization issues
  let existing = state.userSessions.find((obj) => obj.user?.clientId === userSession.user?.clientId);
  let sessions;
  if (existing) {
    console.log('### WARNING:  Duplicate client id in addRemoteUserToSession.');
    sessions = state.userSessions;
  } else {
    sessions = [...state.userSessions, userSession];
  }
  return {
    ...state,
    userSessions: sessions,
  };
};
const joinSession = (state: State, { sessionId }) => {
  return {
    ...state,
    currentSessionId: sessionId,
  };
};
const loadRemoteUsersSuccess = (state: State, { data }) => {
  return {
    ...state,
    userSessions: data.map((u) => {
      return { user: u };
    }),
  };
};
export const userSessionsReducer = createReducer(
  initialState,
  on(UserSessionActions.joinSession, joinSession),
  on(UserSessionActions.leaveSession, leaveSession),
  on(UserSessionActions.addRemoteUserToSession, addRemoteUserToSession),
  on(UserSessionActions.removeRemoteUserFromSession, removeRemoteUserFromSession),
  on(UserSessionActions.loadRemoteUsersSuccess, loadRemoteUsersSuccess),
);

export function reducer(state: State | undefined, action: Action): any {
  return userSessionsReducer(state, action);
}
