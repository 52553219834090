import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { plansReducers } from './plans.reducers';
import { EffectsModule } from '@ngrx/effects';
import { PlansEffects } from './plans-effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('plans', plansReducers), EffectsModule.forFeature([PlansEffects])],
})
export class PlansStoreModule {}
