<div class="view-container">
  <div *ngIf="isEditing" class="rename-view">
    <mat-form-field>
      <input matInput #inputField [formControl]="nameInput" (blur)="save()" autofocus data-test="rename-view-input" />
    </mat-form-field>
  </div>
  <div class="view-list" *ngIf="!isEditing" data-test="view-list-container" class="mr-2">
    <app-menu
      data-test="view-selector-menu"
      [hideTooltip]="true"
      icon=""
      isSVGIcon="true"
      (showMenu)="init()"
      highlight="true"
      (hideMenu)="mode = 'list'"
    >
      <div name class="flex items-center">
        <mat-icon svgIcon="{{ (currentView$ | async)?.viewType }}_active"></mat-icon>
        {{ (currentView$ | async)?.label }}
        <mat-icon
          style="height: 14px; width: 14px; line-height: 14px; font-size: 14px"
          class="ml-2 material-icons-outlined"
          *ngIf="(currentView$ | async).private"
          >lock</mat-icon
        >
        <mat-icon
          style="height: 16px; width: 16px; line-height: 16px; font-size: 16px"
          class="ml-2 material-icons-outlined"
          *ngIf="(currentView$ | async).admin"
          >group</mat-icon
        >
      </div>
      <div *ngIf="mode === 'list'" class="views-list">
        <app-view-selector
          [editorMode]="editorMode"
          [allViews]="allViews"
          [currentView]="currentView$ | async"
          (selectView)="setCurrentView($event)"
        ></app-view-selector>
        <div class="actions">
          <div
            class="cursor-pointer text-primary my-1"
            *ngIf="editorMode === 'EDIT'"
            (click)="mode = 'create'"
            data-test="add-new-view"
          >
            + Create new
          </div>
          <div
            class="cursor-pointer text-primary my-1"
            *ngIf="editorMode === 'EDIT'"
            (click)="mode = 'create-from-template'"
            data-test="add-new-view-template"
          >
            + Create from template
          </div>
          <div
            class="cursor-pointer text-primary my-1"
            *ngIf="editorMode === 'EDIT'"
            (click)="mode = 'import-views'"
            data-test="add-new-view-template"
          >
            + Import from plan
          </div>
        </div>
      </div>
      <app-view-form
        *ngIf="mode === 'create'"
        (cancel)="mode = 'list'"
        (done)="handleNewViewCreated()"
        [newViewSortOrder]="nextSortOrder"
      >
      </app-view-form>
      <app-view-form
        *ngIf="mode === 'create-from-template'"
        [fromTemplate]="true"
        (cancel)="mode = 'list'"
        (done)="close()"
        [newViewSortOrder]="nextSortOrder"
      >
      </app-view-form>
      <app-import-views
        *ngIf="mode === 'import-views'"
        (cancel)="mode = 'list'"
        (done)="close()"
        [newViewSortOrder]="nextSortOrder"
      >
      </app-import-views>
    </app-menu>
  </div>

  <div
    class="current-view-actions"
    *ngIf="!isEditing && (currentView$ | async) && editorMode === 'EDIT'"
    data-test="current-view-actions-holder"
  >
    <app-menu #viewActions icon="more_actions" (showMenu)="init()" [isSVGIcon]="true" data-test="current-view-actions">
      <app-view-actions [currentView]="currentView$ | async" (triggerAction)="handleAction($event)"></app-view-actions>
    </app-menu>
  </div>
</div>
