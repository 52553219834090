import { ChangeDetectorRef, Component, ElementRef, HostListener, OnChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { PlanEntityReferenceHelper } from 'src/app/collection-manager/entity-references/entity-reference-helper';
import { PlaceholderItemAssignmentService } from 'src/app/collection-manager/placeholders/placeholder-item-assignment-service';
import { DataCellEditBaseComponent } from '../../data-cell-edit-base-component';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { CollectionManagerSelectors } from 'src/app/collection-manager/collection-manager-store';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';

@Component({
  selector: 'app-data-cell-edit-item-object-reference',
  templateUrl: './data-cell-edit-item-object-reference.component.html',
  styleUrls: ['./data-cell-edit-item-object-reference.component.scss'],
})
export class DataCellEditItemObjectReferenceComponent extends DataCellEditBaseComponent {
  public create = false;
  public isCreateAllowed = true;
  public nameFormField: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  public panelTopPosition = 0;
  public baseCriteria: any = {};
  public hideChooser = true;
  public itemProperties: any;
  @ViewChild('chooser', { static: false }) chooser: ElementRef;

  constructor(
    private store: Store<RootStoreState.State>,
    private placeholderItemService: PlaceholderItemAssignmentService,
    private objectReferenceHelper: PlanEntityReferenceHelper,
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {
    super();
    this.store.select(CollectionManagerSelectors.typeDefinitions).subscribe((typeMap) => {
      this.itemProperties = typeMap?.item?.typeProperties;
    });
  }

  ngOnChanges(): void {
    console.log('on changes.. getting items...: ', this.property.slug, this.data);
    this.baseCriteria = {};
    if (this.property.slug === 'itemFamily') {
      this.baseCriteria = { roles: 'family' };
    } else if (this.property.slug === 'itemOption') {
      this.baseCriteria = { roles: 'option', isPrimary: true };
      if (this.data?.itemFamilyId) {
        this.baseCriteria.itemFamilyId = this.data.itemFamilyId;
      }
    } else if (this.property.propertyDefinition.referencedTypeRole === 'family') {
      this.baseCriteria = { roles: 'family' };
    } else if (this.property.propertyDefinition.referencedTypeRole === 'option') {
      this.baseCriteria = { roles: 'option', isPrimary: true };
    }
    this.setPosition();
    if (!this.data.itemFamilyId && this.property.slug === 'itemOption') {
      this.isCreateAllowed = false;
    }
  }

  async select(item) {
    try {
      if (this.property.slug === 'itemOption') {
        await this.placeholderItemService.setItemOptionOnPlaceholder(this.data, item);
        this.value = item.id;
      } else if (this.property.slug === 'itemFamily') {
        await this.placeholderItemService.setItemFamilyOnPlaceholder(this.data, item);
        this.value = item.id;
      } else {
        this.value = item.item; // from ItemData
        this.handleChange();
      }
    } catch (error) {
      console.log('Error in data-cell-item-object-reference: ', error);
      return;
    }
  }

  setPosition() {
    this.panelTopPosition = this.elementRef.nativeElement.getBoundingClientRect().top;
    const pageY = this.elementRef.nativeElement.getBoundingClientRect().y;
    const chooserContainerHeight = 450;
    const singleRowHeight = 30;
    const maxDim =
      window.innerHeight * 0.4 > chooserContainerHeight ? chooserContainerHeight : window.innerHeight * 0.4;
    const distanceFromBottom = (100 * (window.innerHeight - maxDim - pageY)) / window.innerHeight;

    let newPanelPosition = pageY - chooserContainerHeight - singleRowHeight;

    if (distanceFromBottom < 12) {
      // pushes the chooser above the selected cell which cuts a part of the chooser so pushing it down by 2 rows
      if (distanceFromBottom > 5) {
        newPanelPosition += 2 * singleRowHeight;
      }
      this.panelTopPosition = newPanelPosition;
    }
  }
  new() {
    this.create = true;
    setTimeout(() => {
      const input = document.querySelector('#nameInput') as HTMLElement;
      input.focus();
    }, 200);
  }
  async createNewObject() {
    this.objectReferenceHelper.createObject(this.data, this.property.propertyDefinition, this.nameFormField.value);
  }
  clearValue() {
    this.value = null;
    if (this.property.slug === 'itemOption') {
      this.placeholderItemService.setItemOptionOnPlaceholder(this.data, null);
    } else if (this.property.slug === 'itemFamily') {
      this.placeholderItemService.setItemFamilyOnPlaceholder(this.data, null);
    }
  }

  handleClick() {
    this.objectReferenceHelper.viewDetails(
      this.data,
      'item',
      this.property.propertyDefinition,
      this.value,
      this.accessLevel,
    );
  }

  handleClose() {
    this.hideChooser = true;
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleAddClick(event: KeyboardEvent = null) {
    if (!this.value?.id) {
      this.hideChooser = false;
      this.changeDetectorRef.detectChanges();
      const currentChooserRightPosition = this.chooser.nativeElement.getBoundingClientRect().right;
      if (currentChooserRightPosition > window.innerWidth) {
        this.chooser.nativeElement.style.setProperty('right', '20px');
      }
    }
  }
}
