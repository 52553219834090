import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { escapeRegExp } from 'lodash';
import { RootStoreState } from 'src/app/root-store';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { ComparePlansModalLauncher } from '../compare-plans/compare-plans-modal-launcher';
import { PlansActions, PlansSelectors } from '../plans-store';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import {
  ContextMenuActionDefinition,
  ContextMenuComponent,
} from 'src/app/common/components/context-menu/context-menu.component';
import { ConfirmationBoxService } from 'src/app/common/components/confirmation-box/confirmation-box';
import { map, startWith, take } from 'rxjs/operators';
import { Plan } from '../plans-store/plans.state';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { CreatePlanComponent } from '../create-plan/create-plan.component';
import { SortByOptions } from '@common/util/filter-util';
import { UntypedFormControl } from '@angular/forms';
import { AuthSelectors } from '@common/auth/auth-store';
import { AccessManagedType, Types } from '@contrail/sdk';
import { CopyRenameEntityModalLauncher } from '@components/copy-rename-entity/copy-rename-entity-modal-launcher';

@Component({
  selector: 'app-plans-home',
  templateUrl: './plans-home.component.html',
  styleUrls: ['./plans-home.component.scss'],
})
export class PlansHomeComponent implements AfterViewInit, OnInit {
  public authContext$: Observable<any>;
  public plans: Array<Plan>;
  public selectedPlans: Array<Plan>;
  public viewMode = 'list';
  public selectedPlanIds: Set<string> = new Set();
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @ViewChild(ContextMenuComponent) contextMenu: ContextMenuComponent;
  public filteredPlans$: Observable<Array<Plan>>;
  public contextMenuActions: Array<ContextMenuActionDefinition> = [
    { actionName: 'open', icon: 'open_in_new', label: 'Open' },
    { actionName: 'rename', icon: 'drive_file_rename_outline', label: 'Rename' },
    { actionName: 'copy', icon: 'content_copy', label: 'Copy' },
  ];

  sortOptions = SortByOptions;
  private sortDirectionSubject = new BehaviorSubject('desc');
  public sortDirection$ = this.sortDirectionSubject.asObservable();
  public sortByControl = new UntypedFormControl({ label: 'Date Modified', property: 'updatedOn' });
  public myPlansOnly = new UntypedFormControl(false);
  public canCreate = true;

  constructor(
    private store: Store<RootStoreState.State>,
    private confirmationBoxService: ConfirmationBoxService,
    private copyRenameEntityModalLauncher: CopyRenameEntityModalLauncher,
    private routingService: RoutingService,
    private comparePlansModalLauncher: ComparePlansModalLauncher,
    private matDialog: MatDialog,
  ) {}
  subscribe() {
    this.filteredPlans$ = combineLatest([
      this.searchBar.valueChange.pipe(startWith('')),
      this.store.select(PlansSelectors.plans),
      this.sortByControl.valueChanges.pipe(startWith({ label: 'Date Modified', property: 'updatedOn' })),
      this.sortDirection$,
      this.myPlansOnly.valueChanges.pipe(startWith(false)),
      this.store.select(AuthSelectors.selectAuthContext),
    ]).pipe(
      map(([searchTerm, plans, sortByOption, sortDirection, myPlans, authContext]) => {
        const sortByField = sortByOption?.property;
        const keys = 'name';
        if (!plans) {
          return [];
        }
        return plans
          .filter((item) =>
            keys
              .split(',')
              .some((key) => item.hasOwnProperty(key) && new RegExp(escapeRegExp(searchTerm), 'gi').test(item[key])),
          )
          .filter((plan) => this.testMyPlans(plan, myPlans, authContext))
          .sort((plan1, plan2) => {
            const value1 = ['createdOn', 'updatedOn'].includes(sortByField)
              ? new Date(plan1[sortByField]).getTime()
              : plan1[sortByField].toLowerCase();
            const value2 = ['createdOn', 'updatedOn'].includes(sortByField)
              ? new Date(plan2[sortByField]).getTime()
              : plan2[sortByField].toLowerCase();

            if (['createdOn', 'updatedOn'].includes(sortByField)) {
              if (sortDirection === 'asc') {
                return value1 - value2;
              } else {
                return value2 - value1;
              }
            } else {
              if (sortDirection === 'asc') {
                return value2 < value1 ? 1 : -1;
              } else {
                return value2 > value1 ? 1 : -1;
              }
            }
          });
      }),
    );
  }

  async ngOnInit() {
    // this.store.dispatch(PlansActions.clearCurrentPlan());
    // this.store.dispatch(PlansActions.loadPlansSuccess({ data: [] }));
    // this.store.dispatch(PlansActions.loadPlans());
    // this.store.dispatch(WorkspacesActions.loadWorkspaces());
    this.store.dispatch(PlansActions.clearCurrentPlan());
    const planType: AccessManagedType = await new Types().getType({ path: 'plan' });
    this.canCreate = planType.canCreate;
  }
  async ngAfterViewInit() {
    setTimeout(() => {
      this.subscribe();
    }, 0); // SOLVES STATE CHANGE ERROR
  }

  private testMyPlans(obj, myPlans, authContext) {
    if (!myPlans) {
      return true;
    }
    return obj.createdById === authContext.user.id;
  }
  sortBy(option) {
    this.sortByControl.setValue(option);
  }
  setViewMode(option) {
    this.viewMode = option;
  }
  async delete(plan) {
    const confirm = await this.confirmationBoxService.open(
      `Delete Plan: ${plan.name}`,
      'Are you sure you want to delete this plan?',
    );
    if (confirm) {
      this.store.dispatch(PlansActions.deletePlan({ plan }));
    }
  }
  compare(target) {
    console.log('compare: ', this.selectedPlans);
    this.comparePlansModalLauncher.openModal(this.selectedPlans);
  }
  select(target, $event: MouseEvent) {
    if ($event.button !== 0) {
      return;
    }
    $event.preventDefault();
    $event.stopPropagation();
    console.log('click: ', $event);
    if (!$event.altKey && !$event.shiftKey) {
      this.selectedPlanIds = new Set();
      this.selectedPlanIds.add(target.id);
    } else {
      this.selectedPlanIds.add(target.id);
    }
    this.selectedPlans = this.plans.filter((plan) => this.selectedPlanIds.has(plan.id));
  }
  async handleContextMenu($event) {
    this.contextMenu.show($event.mouseEvent, $event.listItem);
  }
  handleMenuAction($event) {
    switch ($event.action) {
      case 'copy':
        this.copy($event.target);
        break;
      case 'open':
        this.goToPlan($event.target);
        break;
      case 'rename':
        this.rename($event.target);
        break;
    }
  }

  rename(plan) {
    const modalRef = this.copyRenameEntityModalLauncher.openModal({
      entityId: plan.id,
      entityName: plan.name,
      entityType: 'Plan',
      isRename: true,
    });

    modalRef.componentInstance.renameEntity.subscribe((plan) => {
      this.store.dispatch(PlansActions.updatePlan({ id: plan.id, changes: { name: plan.name } }));
    });
  }

  copy(plan) {
    const modalRef = this.copyRenameEntityModalLauncher.openModal({
      entityId: plan.id,
      entityName: plan.name,
      entityType: 'Plan',
      isRename: false,
    });

    modalRef.componentInstance.copyEntity.subscribe((plan) => {
      this.store.dispatch(PlansActions.asyncCopyPlan({ sourceId: plan.id, name: plan.name }));
    });
  }

  async onCopySuccess(data) {
    this.store.dispatch(PlansActions.asyncCopyPlanSuccess(data));
  }

  isSelected(plan) {
    return this.selectedPlanIds.has(plan.id);
  }
  goToPlan(plan) {
    this.routingService.go(`plan/${plan.id}`);
  }

  createPlan() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.data = {};
    const dialogRef = this.matDialog.open(CreatePlanComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  public showMenu($event) {
    this.contextMenu.show($event.mouseEvent, $event.itemData);
  }

  sortByDirection() {
    this.sortDirection$.pipe(take(1)).subscribe((sortDirection: string) => {
      if (sortDirection === 'asc') {
        this.sortDirectionSubject.next('desc');
      } else {
        this.sortDirectionSubject.next('asc');
      }
    });
  }
}
