<app-modal title="Create New Plan" [dialogRef]="dialogRef">
  <div *ngIf="step === 'name'">
    <div class="explaination">
      <img class="icon min-w-[60px] mr-4" src="assets/images/plan_icon.svg" />
      <div class="content text-sm">
        A plan lets you iteratively develop an assortment with your team. Develop alternate 'what if' plans, share with
        others to collect feedback, and when you are ready, 'Publish' your plan for use by other teams.
      </div>
    </div>
    <div>
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput [formControl]="nameFormControl" />
      </mat-form-field>
    </div>
    <div class="actions">
      <div></div>
      <div>
        <button mat-stroked-button (click)="cancel()">Cancel</button>
        <button mat-stroked-button color="primary" [disabled]="!nameFormControl.valid" (click)="step = 'source'">
          Next:Plan Constraints
        </button>
        <!-- <button mat-flat-button color="primary" [disabled]="!isStateValid()" (click)="save()">Create</button>   -->
      </div>
    </div>
  </div>

  <div *ngIf="step === 'target'">
    <div class="sub-title">Target Assortment</div>
    <div class="explaination no-margin">
      <div class="content text-sm">
        When your plan is ready, you can 'publish' its data into a target assortment. Publishing the plan allows other
        teams and other documents, such as Showcases, to leverage the data in the plan. For this plan, you can either
        select an existing assortment to publish into, or create a new assortment.
        <mat-radio-group class="restricted-radio" [(ngModel)]="targetMode" (change)="changeTargetMode($event)">
          <mat-radio-button class="restricted-option" value="new">
            <span class="radio-label">New Assortment</span>: Create a new assortment that this plan will publish to.
          </mat-radio-button>
          <mat-radio-button class="restricted-option" value="existing">
            <span class="radio-label">Existing Assortment</span>: Select an existing assortment to publish to.
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div>
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Target Assortment</mat-label>
        <mat-select [formControl]="targetAssortmentFormControl">
          <mat-option *ngFor="let assortment of targetAssortments$ | async" [value]="assortment">
            {{ assortment.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="actions">
      <button mat-stroked-button color="primary" (click)="step = 'name'">Back</button>
      <div>
        <button mat-stroked-button (click)="cancel()">Cancel</button>
        <button
          mat-stroked-button
          color="primary"
          [disabled]="!isStateValid() || targetMode === 'existing'"
          (click)="step = 'source'"
        >
          Next: Item Contraints
        </button>
        <button mat-flat-button color="primary" [disabled]="!isStateValid()" (click)="save()">Create</button>
      </div>
    </div>
  </div>

  <div *ngIf="step === 'source'">
    <div class="sub-title">Data Source</div>
    <div class="explaination no-margin">
      <div class="content text-sm">
        You may optionally select a 'source' assortment for your plan. This will limit the items that are available for
        selection in your plan, and you will be notified if items in the source are dropped.

        <mat-radio-group class="restricted-radio" [(ngModel)]="sourceMode" (change)="changeSourceMode($event)">
          <mat-radio-button class="restricted-option" value="unrestricted">
            <span class="radio-label">Unrestricted</span>: Any item can be added to the plan.
          </mat-radio-button>
          <mat-radio-button class="restricted-option" value="restricted">
            <span class="radio-label">Restricted</span>: Items can only be added if they are in a designated 'source
            assortment'.
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="source-selector">
      <mat-form-field class="no-margin" style="width: 100%" appearance="fill">
        <mat-label>Source Project</mat-label>
        <mat-select [formControl]="sourceWorkspaceControl" (ngModelChange)="handleWorkspaceChange($event)">
          <mat-option *ngFor="let workspace of sourceWorkspaces$ | async" [value]="workspace">
            {{ workspace.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="no-margin"
        style="width: 100%"
        appearance="fill"
        *ngIf="(sourceAssortments$ | async)?.length"
      >
        <mat-label>Source Assortment</mat-label>
        <mat-select [formControl]="sourceAssortmentFormControl">
          <mat-option *ngFor="let assortment of sourceAssortments$ | async" [value]="assortment">
            {{ assortment.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="warning" *ngIf="(sourceAssortments$ | async)?.length === 0">
        The selected project does not contain an eligible source assortment. Either select a different project, or
        change to 'Unrestricted Mode'
      </div>
      <div class="init-from-source" *ngIf="sourceAssortmentFormControl.value">
        <mat-checkbox [(ngModel)]="initFromSource">Add all items from source assortment into plan.</mat-checkbox>
      </div>
    </div>

    <div class="actions">
      <button mat-stroked-button color="primary" (click)="step = 'name'">Back</button>
      <div>
        <button mat-stroked-button (click)="cancel()">Cancel</button>
        <button mat-flat-button color="primary" [disabled]="!isStateValid()" (click)="save()">Create</button>
      </div>
    </div>
  </div>
</app-modal>
