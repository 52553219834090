import { CdkDragDrop, CdkDragStart, DragRef } from '@angular/cdk/drag-drop';
import {
  Component,
  Input,
  HostListener,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
  SimpleChange,
  OnChanges,
  AfterViewInit,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { pullAt, sumBy, escapeRegExp } from 'lodash';
import { BehaviorSubject, combineLatest, debounceTime, of, startWith, switchMap } from 'rxjs';
import { SearchBarComponent } from '@components/search-bar/search-bar.component';
import { ObjectUtil } from '@contrail/util';
import { PropertyConfiguration } from '../view-property-configurator.component';

@Component({
  selector: 'app-view-property-drag-drop-list',
  templateUrl: './view-property-drag-drop-list.component.html',
  styleUrls: ['./view-property-drag-drop-list.component.scss'],
})
export class ViewPropertyDragDropListComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() properties: PropertyConfiguration[];
  @Input() allowReorder: boolean = true;
  @Input() isVisible: boolean;
  @Output() propertiesUpdated = new EventEmitter<PropertyConfiguration[]>();
  @Output() propertiesRemoved = new EventEmitter<PropertyConfiguration[]>();
  @Output() propertyDoubleClick = new EventEmitter<PropertyConfiguration>();

  public draggingRef: DragRef = null;
  public selectedIndexes: number[] = [];
  public isReorderInSameListAllowed: boolean = true;

  private currentSelectionSpan: number[] = [];
  private lastSingleSelection: number;

  private filteredPropertiesSubject = new BehaviorSubject<Array<PropertyConfiguration>>(null);
  public filteredProperties$ = this.filteredPropertiesSubject.asObservable();
  private allPropertiesSubject = new BehaviorSubject<Array<PropertyConfiguration>>(null);
  @ViewChild('searchBar') searchBar: SearchBarComponent;

  constructor(
    private elementRef: ElementRef,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isReorderInSameListAllowed = this.allowReorder;
  }

  ngOnChanges(changes: { [input: string]: SimpleChange }) {
    if (changes.properties && changes.properties.currentValue) {
      setTimeout(() => {
        this.allPropertiesSubject.next(this.properties);
        this.cdRef.detectChanges();
      }, 0);
    }
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.searchBar?.valueChange.pipe(
        startWith(''),
        switchMap((value) => (!value ? of(value) : of(value).pipe(debounceTime(400)))),
      ),
      this.allPropertiesSubject.asObservable(),
    ]).subscribe(([searchTerm, properties]) => {
      const filteredProperties = this.filterPropertiesBySearchTerm(searchTerm, properties);
      this.filteredPropertiesSubject.next(filteredProperties);
    });
  }

  private filterPropertiesBySearchTerm(
    searchTerm: string,
    properties: PropertyConfiguration[],
  ): PropertyConfiguration[] {
    if (!properties) {
      return properties;
    }

    if (this.allowReorder) {
      this.isReorderInSameListAllowed = searchTerm?.trim()?.length ? false : true;
    }

    const searchOptions = ['typeProperty.label'];
    const filtered = properties.filter((prop) => {
      return (
        !searchTerm?.length ||
        searchOptions.some((key) =>
          new RegExp(escapeRegExp(searchTerm), 'gi').test(ObjectUtil.getByPath(prop, key.trim())),
        )
      );
    });

    return filtered;
  }

  rowTrackByFn(index, property: PropertyConfiguration) {
    return property.typeProperty.id;
  }

  dragStarted(event: CdkDragStart, propertyId: string): void {
    const properties = this.allPropertiesSubject.getValue();
    const index = properties.findIndex((property) => property.typeProperty.id === propertyId);

    this.draggingRef = event.source._dragRef;
    const indices = this.selectedIndexes.length ? this.selectedIndexes : [index];
    event.source.data = {
      indices,
      values: indices.map((i) => properties[i]),
      source: this,
    };
    this.cdRef.detectChanges();
  }

  dragEnded(): void {
    this.draggingRef = null;
    this.cdRef.detectChanges();
  }

  dropped(event: CdkDragDrop<any>): void {
    const isDropIllegal = Boolean(
      !event?.item?.data?.source || (event.previousContainer === event.container && !this.isReorderInSameListAllowed),
    );

    if (isDropIllegal) {
      return;
    }

    const properties = this.allPropertiesSubject.getValue();
    const data = event.item.data;
    if (data.source === this) {
      if (event.previousContainer !== event.container) {
        this.filterOutFreezeColumnFromSelectedData(data);
      }

      pullAt(properties, data.indices);

      if (event.previousContainer !== event.container) {
        this.allPropertiesSubject.next(properties);
        this.propertiesRemoved.emit(properties);
      }
    }

    this.draggingRef = null;
    this.clearSelection();
  }

  droppedIntoList(event: CdkDragDrop<any>): void {
    const isDropIllegal = Boolean(
      !event?.item?.data?.source || (event.previousContainer === event.container && !this.isReorderInSameListAllowed),
    );

    if (isDropIllegal) {
      return;
    }

    const properties = this.allPropertiesSubject.getValue();
    const data = event.item.data;
    if (event.previousContainer !== event.container) {
      this.filterOutFreezeColumnFromSelectedData(data);
    }

    let spliceIntoIndex = event.currentIndex;
    if (event.previousContainer === event.container && this.selectedIndexes.length > 1) {
      this.selectedIndexes.splice(-1, 1);
      const sum = sumBy(this.selectedIndexes, (selectedIndex) => (selectedIndex <= spliceIntoIndex ? 1 : 0));
      spliceIntoIndex -= sum;
    }

    if (!this.isReorderInSameListAllowed) {
      spliceIntoIndex = properties.length;
    }

    properties.splice(spliceIntoIndex, 0, ...data.values);
    this.allPropertiesSubject.next(properties);

    setTimeout(() => {
      this.propertiesUpdated.emit(properties);
      this.cdRef.detectChanges();
    });
  }

  filterOutFreezeColumnFromSelectedData(data) {
    const isMovingFreezeColumn = data.values.some((property) => property.typeProperty.id === 'freezeColumnBreakpoint');
    if (!isMovingFreezeColumn) {
      return;
    }

    const properties = this.allPropertiesSubject.getValue();
    const freezeColumnIndex = data.indices.findIndex(
      (index) => properties[index].typeProperty.id === 'freezeColumnBreakpoint',
    );
    data.indices.splice(freezeColumnIndex, 1);

    const freezeColumnValueIndex = data.values.findIndex(
      (property) => property.typeProperty.id === 'freezeColumnBreakpoint',
    );
    data.values.splice(freezeColumnValueIndex, 1);
  }

  select(event, propertyId: string) {
    const properties = this.allPropertiesSubject.getValue();
    const index = properties.findIndex((property) => property.typeProperty.id === propertyId);
    const isShiftSelect = Boolean(
      event.shiftKey &&
        (this.lastSingleSelection || this.lastSingleSelection === 0) &&
        this.lastSingleSelection !== index,
    );

    if (!this.selectedIndexes?.length) {
      this.selectedIndexes = [index];
      this.lastSingleSelection = index;
    } else if (event.metaKey || event.ctrlKey) {
      this.handleCtrlSelect(index);
    } else if (isShiftSelect) {
      this.handleShiftSelect(index);
    } else {
      const alreadySelected = this.selectedIndexes.find((selectedIndex) => selectedIndex === index);
      const shouldClearSelectionsAndSelectIndex = Boolean(
        (!alreadySelected && !event.shiftKey) || (alreadySelected && this.selectedIndexes.length > 1),
      );

      if (shouldClearSelectionsAndSelectIndex) {
        this.clearSelection();
        this.selectedIndexes = [index];
        this.lastSingleSelection = index;
      } else if (alreadySelected) {
        this.clearSelection();
      }
    }

    if (!event.shiftKey) {
      this.currentSelectionSpan = [];
    }

    this.cdRef.detectChanges();
  }

  handleCtrlSelect(index: number) {
    const alreadySelected = this.selectedIndexes.find((selectedIndex) => selectedIndex === index);
    if (alreadySelected) {
      this.selectedIndexes = this.selectedIndexes.filter((selectedIndex) => selectedIndex !== index);
      this.lastSingleSelection = null;
    } else {
      this.selectedIndexes.push(index);
      this.lastSingleSelection = index;
    }
  }

  handleShiftSelect(index: number) {
    const newSelectionBefore = index < this.lastSingleSelection;
    const count = newSelectionBefore ? this.lastSingleSelection - (index - 1) : index + 1 - this.lastSingleSelection;

    const shouldClearPreviousShiftSelection = Boolean(
      this.currentSelectionSpan && this.currentSelectionSpan.length > 0,
    );
    if (shouldClearPreviousShiftSelection) {
      this.currentSelectionSpan.forEach((currentSelectionIndex) => {
        this.selectedIndexes = this.selectedIndexes.filter((selectedIndex) => selectedIndex !== currentSelectionIndex);
      });

      this.currentSelectionSpan = [];
    }

    for (let i = 0; i < count; i++) {
      if (newSelectionBefore) {
        this.currentSelectionSpan.push(this.lastSingleSelection - i);
      } else {
        this.currentSelectionSpan.push(this.lastSingleSelection + i);
      }
    }

    this.currentSelectionSpan.forEach((currentSelectionIndex) => {
      if (!this.selectedIndexes.includes(currentSelectionIndex)) {
        this.selectedIndexes.push(currentSelectionIndex);
      }
    });
  }

  clearSelection() {
    if (this.selectedIndexes.length) {
      this.selectedIndexes = [];
      this.currentSelectionSpan = [];
      this.lastSingleSelection = null;
      this.cdRef.detectChanges();
    }
  }

  selectAll() {
    const properties = this.allPropertiesSubject.getValue();
    if (this.selectedIndexes.length !== properties.length) {
      const currentFilteredProperties = this.filteredPropertiesSubject.value;
      const indexesOfFilteredProperties = currentFilteredProperties.map((filteredProperty) =>
        properties.findIndex((property) => filteredProperty.typeProperty.id === property.typeProperty.id),
      );

      this.selectedIndexes = indexesOfFilteredProperties;
      this.currentSelectionSpan = [];
      this.lastSingleSelection = null;
      this.cdRef.detectChanges();
    }
  }

  isSelected(propertyId: string): boolean {
    const properties = this.allPropertiesSubject.getValue();
    const index = properties.findIndex((property) => property.typeProperty.id === propertyId);
    return this.selectedIndexes.indexOf(index) >= 0;
  }

  handleDoubleClick(property: PropertyConfiguration) {
    this.propertyDoubleClick.emit(property);
  }

  @HostListener('document:keydown', ['$event'])
  private handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.isVisible) {
      return;
    }

    const isSelectAllCommand = Boolean(
      event.key === 'a' &&
        (event.ctrlKey || event.metaKey) &&
        this.selectedIndexes.length &&
        document.activeElement.nodeName !== 'INPUT',
    );

    if (isSelectAllCommand) {
      event.stopPropagation();
      event.preventDefault();
      this.selectAll();
    }
  }

  @HostListener('document:click', ['$event'])
  private clickOut(event) {
    if (!this.isVisible) {
      return;
    }

    if (this.selectedIndexes.length && !this.elementRef.nativeElement.contains(event.target)) {
      this.clearSelection();
    }
  }
}
