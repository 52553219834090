<div class="placeholder" [ngClass]="{ 'placeholder-top-margin': !reducedWidth }">
  <div class="title" *ngIf="title" [ngClass]="{ 'side-menu-title-font-size': reducedWidth }">
    {{ title }}
  </div>
  <div class="icon" *ngIf="icon" [ngClass]="{ 'side-menu-icon': reducedWidth }">
    <mat-icon [svgIcon]="icon"></mat-icon>
  </div>
  <div class="footer" *ngIf="footer" [ngClass]="{ 'side-menu-footer-width': reducedWidth }">
    {{ footer }}
  </div>
  <div class="action" *ngIf="actionLabel">
    <button mat-raised-button color="primary" (click)="triggerEvent()">{{ actionLabel }}</button>
  </div>
</div>
