import { Injectable } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions } from '../collection-manager-store';
import { DataCellComponent } from './data-rows/data-cell/data-cell.component';
import { DataRowComponent } from './data-rows/data-row/data-row.component';

const KEY_EVENT_IGNORE_PATHS = [
  'app-comment-overlay', // COMMENTS OVERLAY
  'app-comment-form',
  'app-item-details-modal',
  'app-side-panel',
  'mat-dialog-container',
  'app-chooser',
  'app-search-bar',
  'app-menu',
  'app-search-replace',
  'app-spread-value',
  'app-plan-item-chooser',
  'mat-form-field',
  'app-type-property-form',
  'app-create-entity',
];

export const DEFAULT_COLUMN_WIDTH = 150;
export const FIRST_COLUMN_LOCATION = 80;
export const COLUMN_PADDING = 0;
export interface GridRow {
  leftCells: Array<DataCellComponent>;
  rightCells: Array<DataCellComponent>;
  allCells: Array<DataCellComponent>;
  id: string;
}
export interface EntityPropertyDefinition {
  entityId: string;
  propertySlug: string;
  propertyValue: any;
  propertyType?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GridViewManager {
  public selectedCell: DataCellComponent;
  public rowMap: Map<string, GridRow> = new Map();
  public currentRows: Array<GridRow>;
  public rowIds: Array<string>; // ORDERED LIST OF ROW IDS
  private selectedEntityProperty: any;
  public editorMode = 'VIEW';
  public displayedRowsSubject: BehaviorSubject<Array<GridRow>> = new BehaviorSubject(null);
  public displayedRows$: Observable<Array<GridRow>> = this.displayedRowsSubject.asObservable();

  constructor(private store: Store<RootStoreState.State>) {
    this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
  }

  getCell(rowId: string, propertyId: string): DataCellComponent {
    let cell: DataCellComponent;
    const rowDef = this.rowMap.get(rowId);
    if (rowDef) {
      const index = rowDef.allCells.findIndex((cell) => cell.propertySlug === propertyId);
      if (index > -1) {
        cell = rowDef.allCells[index];
      }
    }
    return cell;
  }
  setSelectedCell(cell: DataCellComponent) {
    if (this.selectedCell) {
      if (this.selectedCell !== cell) {
        this.selectedCell.deselect();
      }
    }
    this.selectedCell = cell;
    this.setSelectedEntityProperty();
  }

  removeSelectedCell() {
    if (this.selectedCell) {
      this.selectedCell.deselect();
      this.selectedCell = null;
    }
  }

  setSelectedEntityProperty() {
    const newSelectedEntityProperty: EntityPropertyDefinition = {
      entityId: this.selectedCell.rowId,
      propertySlug: this.selectedCell.propertySlug,
      propertyValue: this.selectedCell.value,
      propertyType: this.selectedCell.property?.propertyDefinition?.propertyType,
    };
    // Only broadcast if a real change occurred
    if (JSON.stringify(newSelectedEntityProperty) !== JSON.stringify(this.selectedEntityProperty)) {
      this.selectedEntityProperty = ObjectUtil.cloneDeep(newSelectedEntityProperty);
      this.store.dispatch(CollectionManagerActions.setSelectedEntityProperty(this.selectedEntityProperty));
    }
  }

  /** Called by DataRowsComponent as the view changes.
   * Lets us do work on the set of available rows within the service.
   * There are two different 'DataRowsComponets' that will call this function,
   * the left side and the ride side.  The sequence of these calls is non determinate.
   * Our goal is to maintain a map/index of the current set of rows for easy look up by other
   * clients.
   */
  setRows(rows: Array<DataRowComponent>) {
    if (!rows?.length) {
      return;
    }
    const firstRowId = rows[0].rowId;
    if (this.rowIds?.length && firstRowId === this.rowIds[0]) {
      //    console.log('GridViewManager: setRows: SAME first row id.. ');
      this.rowIds = [];
      this.rowMap = this.rowMap || new Map();
    } else {
      //    console.log('GridViewManager: setRows: NEW first row id.. ');
      this.rowIds = [];
      this.rowMap = new Map();
    }
    const newRowSet = [];

    rows.forEach((row) => {
      this.rowIds.push(row.rowId);
      const rowDef: GridRow = this.rowMap.get(row.rowId) || {
        id: row.rowId,
        leftCells: [],
        rightCells: [],
        allCells: [],
      };
      if (row.side === 'left') {
        rowDef.leftCells = row.cells;
      } else {
        rowDef.rightCells = row.cells;
      }
      rowDef.allCells = [...rowDef.leftCells, ...rowDef.rightCells];
      // console.log('rowDef: ', row.rowId, rowDef);
      this.rowMap.set(row.rowId, rowDef);
      newRowSet.push(rowDef);
    });
    this.currentRows = newRowSet;
    this.displayedRowsSubject.next(this.currentRows);
  }

  delete() {
    if (this.selectedCell) {
      this.selectedCell.delete();
    }
  }

  isEventAllowed(event: any) {
    // Special handling for buttons, which do not have composedPaths
    // This is required to capture a key stroke on a 'SAVE' or 'OK' button
    // in the chooser, create-entity, etc.
    if (event.target['tagName'] === 'BUTTON') {
      return false;
    }

    for (const el of event.composedPath()) {
      // CHECK IF ORIGINATING FROM SOMETHING OTHER THAN THE GRID
      if (KEY_EVENT_IGNORE_PATHS.includes(el.tagName?.toLowerCase())) {
        return false;
      }
    }
    return true;
  }
}
