import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlansHomeComponent } from './plans-home/plans-home.component';
import { PlanCardComponent } from './plan-card/plan-card.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ComponentsModule } from '../common/components/components.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ComparePlanModalComponent } from './compare-plans/compare-plan-modal/compare-plan-modal.component';
import { ComparePlansComponent } from './compare-plans/compare-plans/compare-plans.component';
import { PlanColumnComponent } from './compare-plans/compare-plans/plan-column/plan-column.component';
import { ComparePieChartComponent } from './compare-plans/compare-plans/plan-column/compare-pie-chart/compare-pie-chart.component';
import { GoogleChartsModule } from '@common/angular-google-charts/google-charts.module';
import { ListModule } from '../common/components/list/list.module';
import { PlansListComponent } from './plans-list/plans-list.component';
import { ContextMenuModule } from '../common/components/context-menu/context-menu.module';
import { CreatePlanComponent } from './create-plan/create-plan.component';
import { PublishPlanComponent } from './publish-plan/publish-plan.component';
import { PublishSummaryComponent } from './publish-plan/publish-summary/publish-summary.component';
import { AssortmentCompareComponent } from './assortment-compare/assortment-compare.component';
import { PlanAlternatesComponent } from './plan-alternates/plan-alternates.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PlanConfigurationComponent } from './plan-configuration/plan-configuration.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { PipesModule } from '@common/pipes/pipes.module';
import { ExportsModule } from '@common/exports/exports.module';

@NgModule({
  declarations: [
    PlansHomeComponent,
    PlanCardComponent,
    ComparePlanModalComponent,
    ComparePlansComponent,
    PlanColumnComponent,
    ComparePieChartComponent,
    PlansListComponent,
    CreatePlanComponent,
    PublishPlanComponent,
    PublishSummaryComponent,
    AssortmentCompareComponent,
    PlanAlternatesComponent,
    PlanConfigurationComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatMenuModule,
    ComponentsModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSlideToggleModule,
    GoogleChartsModule,
    ListModule,
    ContextMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatRadioModule,
    MatTooltipModule,
    PipesModule,
    ExportsModule,
  ],
  exports: [PublishPlanComponent, PlanAlternatesComponent, PlanConfigurationComponent],
})
export class PlansModule {}
