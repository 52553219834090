import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { CollectionManagerSelectors } from '../collection-manager/collection-manager-store';
import { FocusedItemFamilyService } from '../collection-manager/focus-item/focused-item-family.service';
import { GridRowService } from '../collection-manager/grid-view/grid-row-service';
import { PlaceholderItemAssignmentService } from '../collection-manager/placeholders/placeholder-item-assignment-service';
import { ItemData } from '../common/item-data/item-data';
import { RootStoreState } from '../root-store';
import { take, tap } from 'rxjs/operators';
import { CollectionElementActionValidator } from '../collection-manager/collection-element-validator/collection-element-action-validator';
import { DisableEntityCheck } from '@common/item-data-chooser/item-data-chooser.component';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature, FeatureFlag } from '@common/feature-flags/feature-flag';

@Component({
  selector: 'app-plan-item-chooser',
  templateUrl: './plan-item-chooser.component.html',
  styleUrls: ['./plan-item-chooser.component.scss'],
})
export class PlanItemChooserComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<RootStoreState.State>,
    private placeholderItemService: PlaceholderItemAssignmentService,
    private focusedItemFamilyService: FocusedItemFamilyService,
    private gridRowService: GridRowService,
  ) {}
  @Input() arrowKeyNavigationEnabled = true;
  @Input() showHeader = true;
  @Input() showFilter = true;
  @Input() showAllControl = true;
  @Input() draggable = true;
  @Input() resultsHeight = '100%';
  @Input() allowAddEntity: false;
  @Input() allowAddMultipleEntities: false;
  @Output() close = new EventEmitter();
  @Output() entityClicked = new EventEmitter();
  @Input() criteria: any = { roles: 'option', isPrimary: true };
  @Input() allowShowDetails = true;
  @Input() targetSourceType: ChooserSourceOptionTypes;
  @Input() hideSource: boolean = false;
  @Input() title: string;
  @Input() ignorePersistedBaseCriteria: boolean;
  @Input() minimumItemCountOnLoad: number;

  public collectionItemIds$: Observable<Set<string>>;
  private selectedEntitySub: Subscription;
  private selectedEntityIds: any[];
  public currentProjectId;

  ngOnInit() {
    this.collectionItemIds$ = this.store.select(CollectionManagerSelectors.selectCollectionData).pipe(
      map((data: Array<any>) => {
        const set = new Set<string>();
        data.forEach((o) => {
          set.add(o.itemOptionId);
          set.add(o.itemFamilyId); // add item family id as well to filter out stand alone families.
        });
        return set;
      }),
    );
    this.selectedEntitySub = this.store
      .select(CollectionManagerSelectors.selectedEntityIds)
      .subscribe((selectedEntityIds) => (this.selectedEntityIds = selectedEntityIds));

    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(
        take(1),
        tap(async (ws) => {
          this.currentProjectId = ws.projectId;
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.selectedEntitySub.unsubscribe();
  }

  async addItemData(itemData: ItemData) {
    await this.addMultipleItemData([itemData]);
  }

  async addMultipleItemData(itemData: ItemData[]) {
    let row;
    const itemGroup = this.groupBy(itemData, 'item.itemFamilyId');
    const currentData = this.getCurrentData();
    // find rows with selected itemFamilyId
    const itemFamilyIds = Object.keys(itemGroup);

    const newItemsToAdd: ItemData[] = [];
    const newItemsToAddAtIndexes: { itemData: ItemData[]; index: number }[] = [];
    const itemsToSetOnExistingPlaceholders: { placeholder: any; itemData: ItemData }[] = [];

    for (let familyIndex = 0; familyIndex < itemFamilyIds.length; familyIndex++) {
      const itemFamilyRows = currentData.filter((rowData) => rowData.itemFamilyId === itemFamilyIds[familyIndex]);
      if (itemFamilyRows.length === 0) {
        newItemsToAdd.push(...itemGroup[itemFamilyIds[familyIndex]]);
      } else {
        for (let index = 0; index < itemFamilyRows.length; index++) {
          row = this.gridRowService.getRow(itemFamilyRows[index].id);
          if (itemGroup[row.data.itemFamilyId].length > 0) {
            if (!row.data.itemOption) {
              // no itemOption. Use this one
              itemsToSetOnExistingPlaceholders.push({
                placeholder: row.data,
                itemData: itemGroup[row.data.itemFamilyId][0],
              });

              itemGroup[row.data.itemFamilyId].splice(0, 1);
            }
          }
        }
        // after looping through all selected items and there's no more placeholder that can be assigned to
        if (itemGroup[row.data.itemFamilyId].length > 0) {
          const itemsToAddAtIndex = {
            itemData: itemGroup[row.data.itemFamilyId],
            index: row.rowIndex + 1,
          };

          newItemsToAddAtIndexes.push(itemsToAddAtIndex);
        }
      }
    }

    await this.placeholderItemService.assignItemsToPlaceholders(
      newItemsToAdd,
      newItemsToAddAtIndexes,
      itemsToSetOnExistingPlaceholders,
    );
  }

  public handleClose() {
    this.close.emit();
  }
  public handleClick(entity) {
    this.entityClicked.emit(entity);
  }

  private groupBy(data, path) {
    return data.reduce((item, x) => {
      (item[ObjectUtil.getByPath(x, path)] = item[ObjectUtil.getByPath(x, path)] || []).push(x);
      return item;
    }, {});
  }

  getCurrentData() {
    let currentData;
    this.store
      .select(CollectionManagerSelectors.displayedData)
      .pipe(
        take(1),
        tap((data) => {
          currentData = data;
        }),
      )
      .subscribe();
    return currentData;
  }

  isItemDisabled(itemData: ItemData): DisableEntityCheck {
    const itemFamily = itemData.item.itemFamily ? itemData.item.itemFamily : itemData.item;
    const itemOption = itemData.item.itemFamilyId !== itemData.item.id ? itemData.item : null;

    const collectionElementActionValidator = new CollectionElementActionValidator(this.store);
    if (itemOption) {
      const addItemOptionCheck = collectionElementActionValidator.isItemOptionAddable(itemOption, itemFamily);
      return {
        isDisabled: !addItemOptionCheck.isValid,
        reason: addItemOptionCheck.reason,
      };
    }

    const addItemFamilyCheck = collectionElementActionValidator.isItemFamilyAddable(itemFamily);
    return {
      isDisabled: !addItemFamilyCheck.isValid,
      reason: addItemFamilyCheck.reason,
    };
  }
}
