import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Store } from '@ngrx/store';
import { CommentsSelectors } from '@common/comments/comments-store';
import { Comment } from '@common/comments/comments.service';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../collection-manager-store';
import { GridNavigationHandler } from './grid-navigation-handler';
import { GridViewManager } from './grid-view.manager';
import { SelectorCell } from './selectors/grid-selector.service';

@Injectable({
  providedIn: 'root',
})
export class GridCommentService {
  activeComment: Comment;
  activeCommentCell: SelectorCell;
  scrollVerticalPercentage: any;
  displayedData: Array<any>;
  allData: Array<any>;
  viewProperties: Array<any>;

  constructor(
    private store: Store<RootStoreState.State>,
    private gridViewManager: GridViewManager,
    private gridNavigationHandler: GridNavigationHandler,
    private snackBar: MatSnackBar,
  ) {
    this.store.select(CommentsSelectors.selectedComment).subscribe((activeComment) => {
      this.activeComment = activeComment;
      if (this.activeComment) {
        if (this.activeComment.ownedByReference?.startsWith('plan-placeholder:')) {
          if (this.activeComment.ownedByPropertySlug) {
            this.activeCommentCell = {
              rowId: this.activeComment.ownedByReference.substring(17),
              columnId: this.activeComment.ownedByPropertySlug,
            };
            this.scrollIfNecessary();
            this.selectCell();
          }
        } else if (this.activeComment.ownedByReference?.startsWith('plan:')) {
          this.snackBar.open('Plan specific comment.', '', { duration: 5000 });
          this.activeCommentCell = null;
        }
      } else {
        this.activeCommentCell = null;
      }
    });
    this.store.select(CollectionManagerSelectors.allData).subscribe((allData) => (this.allData = allData));
    this.store
      .select(CollectionManagerSelectors.displayedData)
      .subscribe((displayedData) => (this.displayedData = displayedData));
    this.store
      .select(CollectionManagerSelectors.scrollVerticalPercentage)
      .subscribe((scrollVerticalPercentage) => (this.scrollVerticalPercentage = scrollVerticalPercentage));
    this.store
      .select(CollectionManagerSelectors.currentViewProperties)
      .subscribe((props) => (this.viewProperties = props));
    this.store.select(CollectionManagerSelectors.statusMessageElement).subscribe((statusMessageElement) => {
      if (statusMessageElement) {
        this.gridNavigationHandler.goToRow(statusMessageElement);
      }
    });
  }

  private scrollIfNecessary() {
    if (this.activeCommentCell) {
      this.gridNavigationHandler.goToCell(this.activeCommentCell.rowId, this.activeCommentCell.columnId);
    }
  }

  selectCell() {
    setTimeout(() => {
      const cell = this.gridViewManager.getCell(this.activeCommentCell.rowId, this.activeCommentCell.columnId);
      if (cell) {
        cell.select();
      } else {
        if (this.allData.findIndex((row) => row.id === this.activeCommentCell.rowId) === -1) {
          // row deleted
          this.snackBar.open('Row no longer exists.', '', { duration: 4000 });
        } else {
          const displayedRowIndex = this.displayedData.findIndex((row) => row.id === this.activeCommentCell.rowId);
          const property = this.viewProperties.find(
            (viewProperty) => viewProperty.slug === this.activeCommentCell.columnId,
          );
          if (displayedRowIndex === -1) {
            // row is hidden
            this.snackBar.open('Row is hidden. Clear filter to show the row.', '', { duration: 4000 });
          } else {
            // column is hidden
            const propertyName = property?.propertyDefinition?.label || this.activeCommentCell.columnId;
            this.snackBar.open('Property ' + propertyName + ' is hidden.  Unhide the column to view the comment.', '', {
              duration: 4000,
            });
          }
        }
      }
    }, 6);
  }
}
