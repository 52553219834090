import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import pLimit from 'p-limit';
import { Entities, Types } from '@contrail/sdk';
import { EntityHistoryEvent, EntityHistoryEventPropertyChange } from './entity-history-event';
import { User } from '@contrail/entity-types';
import { TypeManagedEntity } from '@common/entities/entities.interfaces';
import { isReferencePropertyType, Type, TypeProperty } from '@contrail/types';

const limit = pLimit(10);
const IGNORED_HISTORY_PROPERTY_SLUGS = [
  'updatedOn',
  'primaryFileUrl',
  'mediumViewableDownloadUrl',
  'largeViewableDownloadUrl',
  'smallViewableDownloadUrl',
];

interface ChangeHistory {
  entityReference: string;
  diff: {
    [propertyKey: string]: {
      propertyName: string;
      oldValue: any;
      newValue: any;
    };
  };
  entity: TypeManagedEntity;
  createdOn: Date;
  createdBy: User;
}

@Injectable({
  providedIn: 'root',
})
export class EntityHistoryService {
  constructor() {}

  public async getHistoryEventsForEntities(entities: TypeManagedEntity[]): Promise<EntityHistoryEvent[]> {
    const entityReferencesMap = new Map(entities.map((entity) => [this.buildEntityReference(entity), entity]));
    const entityReferences = Array.from(entityReferencesMap.keys());
    if (!entityReferences?.length) {
      return [];
    }

    const changeHistoryResults = await this.getBulkChangeHistory({
      criteria: { entityReferences },
      relations: ['createdBy'],
    });

    const historyEventPromises = Object.entries(changeHistoryResults).map(async ([entityReference, changeHistory]) => {
      const entity = entityReferencesMap.get(entityReference);
      return entity ? this.buildEntityHistoryEvents(entity, changeHistory) : [];
    });

    const groupedHistoryEvents = await Promise.all(historyEventPromises);
    return groupedHistoryEvents.flat();
  }

  private async getBulkChangeHistory({ criteria, relations }: { criteria; relations: string[] }): Promise<{
    [entityReferences: string]: ChangeHistory[];
  }> {
    const changeHistoryResults = await new Entities().get({
      entityName: 'change-history',
      criteria,
      relations,
      suffix: 'bulk',
    });

    if (changeHistoryResults.downloadURL) {
      const response = await fetch(changeHistoryResults.downloadURL);
      return await response.json();
    }

    return changeHistoryResults;
  }

  public async getHistoryEventsForEntity(entity: TypeManagedEntity): Promise<EntityHistoryEvent[]> {
    const entityReference = `${entity.entityType}:${entity.id}`;
    const changeHistoryResults: ChangeHistory[] = await new Entities().get({
      entityName: 'change-history',
      criteria: { entityReference },
      relations: ['createdBy'],
    });

    return this.buildEntityHistoryEvents(entity, changeHistoryResults);
  }

  public async buildEntityHistoryEvents(
    entity: TypeManagedEntity,
    changeHistoryResults: ChangeHistory[],
  ): Promise<EntityHistoryEvent[]> {
    const entityReference = `${entity.entityType}:${entity.id}`;
    const historyEvents: Array<EntityHistoryEvent> = [];
    const type = await new Types().getType({ id: entity.typeId });
    const changeHistoryWithPropertyChanges = changeHistoryResults.filter((o) => o.diff);
    const changeHistoryWithHydratedDiffs = await this.buildChangeHistoryWithHydratedObjectReferenceDiffs(
      changeHistoryWithPropertyChanges,
      type,
    );

    for (const changeHistoryEntity of changeHistoryWithHydratedDiffs) {
      const entityHistoryEvent: EntityHistoryEvent = {
        entityTypeId: entity.typeId,
        entityReference,
        eventDate: changeHistoryEntity.createdOn,
        user: {
          id: changeHistoryEntity.createdBy?.id,
          email: changeHistoryEntity.createdBy?.email,
          firstName: changeHistoryEntity.createdBy?.firstName,
          lastName: changeHistoryEntity.createdBy?.lastName,
        },
        propertyChanges: [],
      };

      if (entity.entityType === 'project-item') {
        entityHistoryEvent.projectId = entity.project?.id;
        entityHistoryEvent.projectName = entity.project?.name;
      }

      const propertyMap = this.buildTypePropertyMapBySlug(type);
      const isItemImageChange = Boolean(
        entity.entityType === 'item' && Object.keys(changeHistoryEntity.diff).includes('primaryFileUrl'),
      );

      for (const propertyKey of Object.keys(changeHistoryEntity.diff)) {
        if (IGNORED_HISTORY_PROPERTY_SLUGS.includes(propertyKey)) {
          continue;
        }

        const propertyHistoryEvent: EntityHistoryEventPropertyChange = {
          typeProperty: null,
          oldValue: changeHistoryEntity.diff[propertyKey].oldValue,
          newValue: changeHistoryEntity.diff[propertyKey].newValue,
          propertyKey,
        };

        const isItemThumbnailChange = Boolean(isItemImageChange && propertyKey === 'tinyViewableDownloadUrl');
        if (isItemThumbnailChange) {
          propertyHistoryEvent.isImageChange = true;
          propertyHistoryEvent.propertyLabel = 'Primary Thumbnail';
        }

        const validProperty = propertyMap[propertyKey];
        if (validProperty) {
          propertyHistoryEvent.typeProperty = validProperty;
          propertyHistoryEvent.propertyLabel = validProperty.label;
        }

        const isPropertyUnchanged = Boolean(
          (!propertyHistoryEvent.oldValue && !propertyHistoryEvent.newValue) ||
            propertyHistoryEvent.oldValue === propertyHistoryEvent.newValue,
        );

        const isValidChange = Boolean(
          propertyHistoryEvent.isImageChange || propertyHistoryEvent.typeProperty || isPropertyUnchanged,
        );

        if (isValidChange) {
          entityHistoryEvent.propertyChanges.push(propertyHistoryEvent);
        }
      }

      if (entityHistoryEvent.propertyChanges?.length) {
        historyEvents.push(entityHistoryEvent);
      }
    }

    return historyEvents;
  }

  private async buildChangeHistoryWithHydratedObjectReferenceDiffs(
    changeHistoryEntities: ChangeHistory[],
    type: Type,
  ): Promise<ChangeHistory[]> {
    const propertyMap = this.buildTypePropertyMapBySlug(type);
    const entityIdsToHydrateByEntityName: Record<string, Set<string>> = {};

    for (const historyEntity of changeHistoryEntities) {
      for (const propertyKey of Object.keys(historyEntity.diff)) {
        if (!propertyKey.endsWith('Id')) {
          continue;
        }

        const propertySlug = propertyKey.slice(0, -2);
        const referenceProperty = this.getReferenceProperty(propertyMap, propertySlug);
        if (!referenceProperty) {
          continue;
        }

        const entityName = referenceProperty.referencedTypeRootSlug;
        if (!entityIdsToHydrateByEntityName[entityName]) {
          entityIdsToHydrateByEntityName[entityName] = new Set();
        }

        const { newValue, oldValue } = historyEntity.diff[propertyKey];
        if (newValue) {
          entityIdsToHydrateByEntityName[entityName].add(newValue);
        }

        if (oldValue) {
          entityIdsToHydrateByEntityName[entityName].add(oldValue);
        }
      }
    }

    const entityMapById = await this.getEntitiesByIds(entityIdsToHydrateByEntityName);
    return this.buildHistoryWithHydratedReferenceDiffs(changeHistoryEntities, propertyMap, entityMapById);
  }

  private async getEntitiesByIds(
    entityIdsByEntityName: Record<string, Set<string>>,
    chunkSize = 500,
  ): Promise<Record<string, TypeManagedEntity>> {
    const entityMapById: Record<string, TypeManagedEntity> = {};

    const getEntitiesPromises = Object.entries(entityIdsByEntityName)
      .filter(([, idSet]) => idSet.size > 0)
      .map(([entityName, idSet]) => {
        const uniqueIds = Array.from(idSet);
        const idChunks = _.chunk(uniqueIds, chunkSize);
        return idChunks.map((chunkOfIds) =>
          limit(async () => {
            return await new Entities().get({
              entityName,
              criteria: { ids: chunkOfIds },
            });
          }),
        );
      });

    const entitiesArrays = await Promise.all(getEntitiesPromises.flat());
    for (const entities of entitiesArrays) {
      for (const entity of entities) {
        entityMapById[entity.id] = entity;
      }
    }

    return entityMapById;
  }

  private buildHistoryWithHydratedReferenceDiffs(
    changeHistoryEntities: ChangeHistory[],
    propertyMap: Record<string, TypeProperty>,
    referenceEntityMapById: Record<string, any>,
  ): ChangeHistory[] {
    return changeHistoryEntities.map((historyEntity) => {
      const updatedDiff: Record<string, any> = {};

      for (const [propertyKey, diffValue] of Object.entries(historyEntity.diff)) {
        if (!propertyKey.endsWith('Id')) {
          updatedDiff[propertyKey] = diffValue;
          continue;
        }

        const propertySlugWithoutId = propertyKey.slice(0, -2);
        const referenceProperty = this.getReferenceProperty(propertyMap, propertySlugWithoutId);
        if (!referenceProperty) {
          updatedDiff[propertyKey] = diffValue;
          continue;
        }

        updatedDiff[propertySlugWithoutId] = {
          propertyName: propertySlugWithoutId,
          newValue: diffValue.newValue ? referenceEntityMapById[diffValue.newValue] : null,
          oldValue: diffValue.oldValue ? referenceEntityMapById[diffValue.oldValue] : null,
        };
      }

      return { ...historyEntity, diff: updatedDiff };
    });
  }

  private getReferenceProperty(propertyMap: Record<string, TypeProperty>, slug: string): TypeProperty | null {
    const property = propertyMap[slug];
    return property && isReferencePropertyType(property.propertyType) && property.referencedTypeRootSlug
      ? property
      : null;
  }

  private buildTypePropertyMapBySlug(type: Type): Record<string, TypeProperty> {
    const propertyMap: Record<string, TypeProperty> = {};
    for (const typeProperty of type?.typeProperties || []) {
      propertyMap[typeProperty.slug] = typeProperty;
    }
    return propertyMap;
  }

  private buildEntityReference(entity: TypeManagedEntity): string {
    return `${entity.entityType}:${entity.id}`;
  }
}
