import { SortDefinition } from '../../components/sort/sort-definition';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { AssortmentUtil } from '../../assortments/assortment-util';
import { AssortmentsService } from '../../assortments/assortments.service';
import { ChooserFilterConfig, ItemDataChooserDataSource } from './item-data-chooser-data-source';
import { FilterDefinition } from '../../types/filters/filter-definition';
import { FilterObjects } from '@contrail/filters';
import { AssortmentItem } from '@common/assortments/assortment-item';
import { ObjectUtil } from '@contrail/util';

export class AssortmentChooserDataSource extends ItemDataChooserDataSource {
  criteria: any;
  optionItemData: AssortmentItem[];
  familyItemData: AssortmentItem[];

  constructor(
    protected store: Store<RootStoreState.State>,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    protected dateFilterSubject: Observable<FilterDefinition>,
    protected assortmentId,
  ) {
    super(store, filterConfigSubject, sortConfigSubject, existingItemIdsSubject, showAllSubject, dateFilterSubject);
    this.initFilteredDataObservable();
    this.initResultsObservable();
    this.loadAssortment();
  }

  protected async initFilteredDataObservable() {
    this.filteredData$ = combineLatest([
      this.filterConfigSubject,
      this.dateFilterSubject,
      this.sortConfigSubject,
      this.assortmentItemData$,
    ]).pipe(
      switchMap(async ([filterConfig, dateFilter, sortConfig, assortmentItemData]) => {
        let data;
        const searchTerm = filterConfig?.searchTerm;
        const filterDefinition = filterConfig?.filterDefinition;
        this.criteria = filterConfig?.baseCriteria;
        // SOURCE ASSORTMENT CONSTRAINT, OPTION LEVEL
        data = assortmentItemData;
        if (this.optionItemData) {
          if (this.criteria?.roles === 'family') {
            data = ObjectUtil.cloneDeep(this.familyItemData);
          } else {
            data = ObjectUtil.cloneDeep(this.optionItemData);
          }
        }
        data = this.filterItemsByLevel(data, this.criteria);
        data = this.filterLocalItemData(
          { searchTerm, itemFamilyId: this.criteria?.itemFamilyId },
          data,
          this.searchableProperties,
        );
        if (filterDefinition?.filterCriteria) {
          data = FilterObjects.filter(data, filterDefinition?.filterCriteria);
        }
        if (dateFilter) {
          data = this.filterByAssortmentItems(data, dateFilter);
        }

        console.log('AssortmentChooserDataSource: ', data);
        this.sortData(data, sortConfig);
        return data;
      }),
    );
  }

  private async loadAssortment() {
    this.loadingSubject.next(true);
    const assortment = await AssortmentsService.getAssortment(this.assortmentId, ['assortmentItems.item.itemFamily']);
    const assortmentItems = assortment.assortmentItems;
    let itemData;

    this.familyItemData = AssortmentUtil.convertAssortmentItemsFamilyItemData(assortmentItems);
    this.optionItemData = AssortmentUtil.convertAssortmentItemsToItemData(assortmentItems);

    if (this.criteria?.roles === 'family') {
      itemData = ObjectUtil.cloneDeep(this.familyItemData);
    } else {
      itemData = ObjectUtil.cloneDeep(this.optionItemData);
    }

    this.assortmentSubject.next(itemData);
    this.loadingSubject.next(false);
  }

  private filterItemsByLevel(items: any[], criteria: any) {
    if (!items) {
      return;
    }

    if (criteria?.roles === 'option') {
      return items.filter((itemData) => itemData.item.itemFamilyId !== itemData.item.id);
    }

    if (criteria?.roles === 'family') {
      return items.filter((itemData) => itemData.item.itemFamilyId === itemData.item.id);
    }

    return items;
  }
}
