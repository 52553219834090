import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PropertyValueFormatter, TypeProperty } from '@contrail/types';
import { ChangeSuggestion } from '../../comments.service';

@Component({
  selector: 'app-comment-suggested-changes-display',
  template: `
    <div *ngIf="suggestedChanges?.changeDetails" class="text-xs">
      <div class="flex font-bold" *ngIf="typeProperty?.label">{{ typeProperty.label }}</div>
      <div class="flex space-x-0.5">
        <div class="font-bold">From:</div>
        <div>{{ getDisplayValue(suggestedChanges.changeDetails.oldValue) }}</div>
      </div>
      <div class="flex space-x-0.5">
        <div class="font-bold">To:</div>
        <div class="text-amber-500">{{ getDisplayValue(suggestedChanges.changeDetails.newValue) }}</div>
      </div>
    </div>
  `,
  styles: [],
})
export class CommentSuggestedChangesDisplayComponent implements OnInit {
  @Input() suggestedChanges: ChangeSuggestion;
  @Input() typeProperty: TypeProperty;
  private formatter = new PropertyValueFormatter();

  public currentValue: string;
  public suggestedValue: string;
  constructor() {}

  ngOnInit(): void {}

  public getDisplayValue(value) {
    if (this.typeProperty) {
      return this.formatter.formatValueForProperty(value, this.typeProperty);
    }
    return value;
  }
}
