import { Component, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { AuthService } from '@common/auth/auth.service';
import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';
import { DocumentHistoryActions, DocumentHistorySelectors } from '@common/document-history/document-history-store';
import { currentEntitySnapshot } from '@common/document-history/document-history-store/document-history.selectors';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, Observable, take } from 'rxjs';
import { SidePanelService } from 'src/app/collection-manager/side-panel/side-panel.service';
import { CollectionStatusMessageService } from 'src/app/collection-manager/side-panel/status-messages/collection-status-message.service';
import { PlanSnapshotService } from 'src/app/collection-manager/snapshot/plan-snapshot.service';
import { AssortmentHistoryComponent } from 'src/app/common/assortment-history/assortment-history.component';
import { AuthSelectors } from 'src/app/common/auth/auth-store';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { ShareAppModalLauncher } from 'src/app/common/share-app/share-app-modal-launcher';
import { PlanAlternatesComponent } from 'src/app/plans/plan-alternates/plan-alternates.component';
import { Plan } from 'src/app/plans/plans-store/plans.state';
import { PublishPlanComponent } from 'src/app/plans/publish-plan/publish-plan.component';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { environment } from 'src/environments/environment';
import { CollectionManagerSelectors } from 'src/app/collection-manager/collection-manager-store';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  currentPlan: Plan;
  editName = false;
  orgSlug = '';
  editorMode = 'VIEW';
  canEdit = false;
  canComment = false;
  isGuest = true;
  currentAuthContext;
  entitySnapshot;
  isSnapshotRestoreAllowed = true;
  public messagesView = {
    label: 'Messages',
    slug: 'messages',
    icon: 'warning',
  };
  public commentsView = {
    label: 'Comments',
    slug: 'comments',
    icon: 'comment',
  };
  public versionHistoryView = {
    label: 'Version History',
    slug: 'versionHistory',
    icon: 'history',
  };

  public clipboardView = {
    label: 'Add Item',
    slug: 'addItem',
    icon: 'add_new_icon',
    isSVGIcon: true,
    targetSourceType: ChooserSourceOptionTypes.CLIPBOARD,
    title: 'Clipboard',
    hideSource: true,
  };

  messages$: Observable<Array<CollectionStatusMessage>>;
  public isLoadingMessages$: BehaviorSubject<boolean>;
  public hasFailedToLoadMessages$: BehaviorSubject<boolean>;
  private hasRestrictedAccessToProperties: boolean = false;

  constructor(
    private authService: AuthService,
    private store: Store<RootStoreState.State>,
    private matDialog: MatDialog,
    private routingService: RoutingService,
    private shareAppModalLauncher: ShareAppModalLauncher,
    private sidePanelService: SidePanelService,
    private planSnapshotService: PlanSnapshotService,
    private collectionStatusMessageService: CollectionStatusMessageService,
  ) {
    this.store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => {
      this.currentAuthContext = authContext;
      this.orgSlug = authContext?.currentOrg?.orgSlug;
      this.isGuest = authContext?.currentOrg?.role === 'GUEST';
    });
  }

  ngOnInit(): void {
    this.store.select(PlansSelectors.currentPlan).subscribe((plan) => {
      this.currentPlan = plan;
    });
    this.store.select(PlansSelectors.editorMode).subscribe((editorMode) => {
      this.editorMode = editorMode;
      this.canEdit = ['EDIT'].includes(editorMode);
      this.canComment = ['COMMENT', 'EDIT'].includes(editorMode);
    });

    this.store
      .select(PlansSelectors.editorMode)
      .pipe(
        filter((editorMode) => !!editorMode),
        take(1),
      )
      .subscribe((editorMode) => {
        this.isSnapshotRestoreAllowed = editorMode === EditorMode.EDIT && !this.authService.isOnSharedLink();
      });

    this.store.select(CollectionManagerSelectors.typeDefinitions).subscribe((typeMap) => {
      if (!typeMap) {
        return;
      }

      const hasRestrictedAccessToPlacholders = Boolean(
        (typeMap['plan-placeholder'] as any)?.restrictedTypePropertySlugs?.length,
      );
      const hasRestrictedAccessToItems = Boolean((typeMap['item'] as any)?.restrictedTypePropertySlugs?.length);
      const hasRestrictedAccessToProjectItems = Boolean(
        (typeMap['project-item'] as any)?.restrictedTypePropertySlugs?.length,
      );
      const hasRestrictedAccessToAssortmentItems = Boolean(
        (typeMap['assortment-item'] as any)?.restrictedTypePropertySlugs?.length,
      );

      this.hasRestrictedAccessToProperties =
        hasRestrictedAccessToPlacholders ||
        hasRestrictedAccessToItems ||
        hasRestrictedAccessToProjectItems ||
        hasRestrictedAccessToAssortmentItems;
    });

    this.store
      .select(DocumentHistorySelectors.currentEntitySnapshot)
      .subscribe((currentEntitySnapshot) => (this.entitySnapshot = currentEntitySnapshot));
    this.messages$ = this.collectionStatusMessageService.messages$;
    this.isLoadingMessages$ = this.collectionStatusMessageService.isLoadingMessages$;
    this.hasFailedToLoadMessages$ = this.collectionStatusMessageService.hasFailedToLoadMessages$;
  }

  public goHome() {
    if (!this.isGuest) {
      this.routingService.go('home');
    }
  }

  share() {
    const baseUrl = `${environment.planAppHost}/org/${this.orgSlug}/plan/${this.currentPlan.id}`;
    this.shareAppModalLauncher.openModal({ contextId: this.currentPlan.id, contextType: 'plan', baseUrl });
  }
  publish() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '688px';
    dialogConfig.data = { targetPlan: this.currentPlan, canPublish: this.canPublish };
    const dialogRef = this.matDialog.open(PublishPlanComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  get canPublish() {
    return !this.isGuest && this.currentPlan?.targetAssortmentId && !this.hasRestrictedAccessToProperties;
  }
  viewAlternates() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { targetPlan: this.currentPlan };
    const dialogRef = this.matDialog.open(PlanAlternatesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  viewAssortmentHistory() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '1200px';
    dialogConfig.data = { assortmentId: this.currentPlan?.targetAssortmentId };
    dialogConfig.panelClass = 'no-padding';

    const dialogRef = this.matDialog.open(AssortmentHistoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  viewVersionHistory() {
    this.sidePanelService.setCurrentView(this.versionHistoryView);
  }

  showMessages() {
    this.sidePanelService.setCurrentView(this.messagesView);
  }

  showComment() {
    this.sidePanelService.setCurrentView(this.commentsView);
  }

  clearSnapshot() {
    this.planSnapshotService.clearSnapshot();
  }

  restoreSnapshot() {
    this.store.dispatch(DocumentHistoryActions.restoreEntitySnapshot({ id: this.entitySnapshot.id }));
  }

  showClipboard() {
    this.sidePanelService.setCurrentView(this.clipboardView);
  }
}
