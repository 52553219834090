import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Types } from '@contrail/sdk';
import { PlaceholderItemCreationService } from './placeholder-item-creation-service';
import { Item } from '@common/items/item';
import { UndoRedoService } from '@common/undo-redo/undo-redo-service';
import { UndoRedoActionType } from '../undo-redo/undo-redo-objects';
import { TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { CollectionManagerActions } from '../collection-manager-store';
import { ItemUpdateService } from '../items/item-update-service';
import { CollectionManagerLiveDataHandler } from '../collection-manager-live-data-handler';

@Injectable({
  providedIn: 'root',
})
export class PlaceholderItemPromoteService {
  private lifecycleProperty;

  constructor(
    private store: Store<RootStoreState.State>,
    private placeholderItemCreateService: PlaceholderItemCreationService,
    private undoRedoService: UndoRedoService,
    private itemUpdateService: ItemUpdateService,
    private liveDataHandler: CollectionManagerLiveDataHandler,
  ) {
    this.init();
  }

  async init() {
    const itemType = await new Types().getType({ root: 'item', path: 'item' });
    this.lifecycleProperty = itemType.typeProperties.find((prop) => prop.slug === 'lifecycleStage')?.slug;
    if (!this.lifecycleProperty) {
      this.lifecycleProperty = itemType.typeProperties.find((prop) => prop.slug === 'itemStatus')?.slug;
    }
  }

  public async promotePlaceholderItems(placeholders: Array<any>) {
    const lifecycleStagePhCandidates = placeholders.filter(
      (ph) => ph.itemOptionId || (!ph.itemOptionId && !ph.optionName && ph.itemFamilyId),
    );
    if (this.lifecycleProperty) {
      await this.promoteToNextLifecycleStage(lifecycleStagePhCandidates);
    }

    this.liveDataHandler.stopProcessing();
    try {
      await this.placeholderItemCreateService.promotePlaceholdersToItems(placeholders);
    } catch (e) {
      setTimeout(async () => {
        // needs to delay this to allow the placeholders in the store to update.
        this.liveDataHandler.startProcessing();
      }, 500);
      throw e;
    }

    setTimeout(() => {
      this.store.dispatch(CollectionManagerActions.clearSelectedEntityIds());
    }, 1);

    setTimeout(async () => {
      // needs to delay this to allow the placeholders in the store to update.
      this.liveDataHandler.startProcessing();
    }, 500);
  }

  public async synchItemFamilyLifecycle(placeholder: any, property: TypeProperty, lcStateValue: string) {
    if (property?.slug !== this.lifecycleProperty && !placeholder.itemOption && lcStateValue) {
      return;
    }
    const itemFamily = placeholder.itemFamily;
    const lcStageOptions = await this.getAllLifecycleStageOptions(itemFamily);
    const currentFamilyLCStateIndex = lcStageOptions.findIndex(
      (option) => option.value === itemFamily[this.lifecycleProperty],
    );
    const targetOptionLCSStateIndex = lcStageOptions.findIndex((option) => option.value === lcStateValue);
    if (currentFamilyLCStateIndex < targetOptionLCSStateIndex) {
      const changes = {};
      const undoChanges = {};
      changes[this.lifecycleProperty] = lcStateValue;
      undoChanges[this.lifecycleProperty] = itemFamily[this.lifecycleProperty];
      return {
        changes,
        item: ObjectUtil.cloneDeep(itemFamily),
        undoChanges,
      };
    }
  }

  private async promoteToNextLifecycleStage(placeholders: any[]) {
    const itemUpdates = [];
    const optionItemUpdates = {};
    const changesList = [];
    const familyGroups = this.groupByFamily(placeholders);
    for (const itemFamilyId of Object.keys(familyGroups)) {
      const itemFamily = familyGroups[itemFamilyId].itemFamily;
      const lcStageOptions = await this.getAllLifecycleStageOptions(itemFamily);
      const currentFamilyLCStateIndex = lcStageOptions.findIndex(
        (option) => option.value === itemFamily[this.lifecycleProperty],
      );
      let targetFamilyLCOptionIndex = currentFamilyLCStateIndex;
      if (familyGroups[itemFamilyId].promoteFamily && currentFamilyLCStateIndex < lcStageOptions.length - 1) {
        targetFamilyLCOptionIndex = currentFamilyLCStateIndex + 1;
      }
      // Loops through item options for an item family and promotes its lifecycle stage.
      familyGroups[itemFamilyId].itemOptions.forEach(async (itemOption) => {
        const currentOptionLCSStateIndex = lcStageOptions.findIndex(
          (option) => option.value === itemOption[this.lifecycleProperty],
        );
        if (currentOptionLCSStateIndex < lcStageOptions.length - 1) {
          const targetLifecycleStageIndex = currentOptionLCSStateIndex + 1;
          const changeData = this.determineChanges(itemOption, lcStageOptions, targetLifecycleStageIndex);
          // determines the lifecycle stage that the item-family should be set to so that it's in synch with the item-options' lc stage.
          if (
            targetLifecycleStageIndex >= targetFamilyLCOptionIndex &&
            targetLifecycleStageIndex > currentFamilyLCStateIndex
          ) {
            targetFamilyLCOptionIndex = targetLifecycleStageIndex;
            optionItemUpdates[itemOption.id] = { changes: changeData.changes };
          } else {
            itemUpdates.push({ item: itemOption, changes: changeData.changes });
          }
          changesList.push({
            id: itemOption.id,
            changes: changeData.changes,
            undoChanges: changeData.undoChanges,
            scope: 'item',
            level: 'option',
          });
        }
      });

      // Only update family item if family is changed.
      if (targetFamilyLCOptionIndex > currentFamilyLCStateIndex) {
        const changeData = this.determineChanges(itemFamily, lcStageOptions, targetFamilyLCOptionIndex);
        itemUpdates.push({ item: itemFamily, changes: changeData.changes });
        changesList.push({
          id: itemFamily.id,
          changes: changeData.changes,
          undoChanges: changeData.undoChanges,
          scope: 'item',
          level: 'family',
        });
      }
    }
    if (changesList.length > 0) {
      const addedUndo = this.undoRedoService.addUndo({
        actionType: UndoRedoActionType.UPDATE_PLACEHOLDER,
        changesList,
      });

      this.itemUpdateService.batchUpdateItems({
        changes: changesList,
        placeholderUpdates: {
          updates: itemUpdates,
          skipRecordingUndoRedo: true,
          optionUpdates: optionItemUpdates,
        },
        undoUuid: addedUndo?.uuid,
      });
    }
  }

  private groupByFamily(placeholders: any[]): any {
    const itemFamiliesForPromotion = placeholders
      .filter((ph) => !ph.itemOptionId && !ph.optionName && ph.itemFamilyId)
      .map((ph) => ph.itemFamilyId);

    const familyGroups = {};
    for (const placeholder of placeholders) {
      if (!familyGroups[placeholder.itemFamilyId]) {
        familyGroups[placeholder.itemFamilyId] = {
          itemFamily: placeholder.itemFamily,
          promoteFamily: itemFamiliesForPromotion.includes(placeholder.itemFamilyId) ? true : false,
          itemOptions: [],
        };
      }
      if (placeholder.itemOption) {
        familyGroups[placeholder.itemFamilyId].itemOptions.push(placeholder.itemOption);
      }
    }
    return familyGroups;
  }

  private async getAllLifecycleStageOptions(item: Item) {
    const itemType = await new Types().getType({ id: item.typeId });
    const lifecycleStageProp = itemType.typeProperties.find((prop) => prop.slug === this.lifecycleProperty);
    const options = lifecycleStageProp.options;
    return options;
  }

  private determineChanges(item: Item, lcStageOptions: any[], targetOptionIndex: number): any {
    const changes = {};
    const undoChanges = {};
    changes[this.lifecycleProperty] = lcStageOptions[targetOptionIndex].value;
    undoChanges[this.lifecycleProperty] = item[this.lifecycleProperty];
    return { changes, undoChanges };
  }
}
