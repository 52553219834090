import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ViewDefinition } from '@contrail/client-views';

@Component({
  selector: 'app-view-list-item',
  templateUrl: './view-list-item.component.html',
  styleUrls: ['./view-list-item.component.scss'],
})
export class ViewListItemComponent implements OnChanges {
  @Input() viewDefinition: ViewDefinition;
  @Input() active = false;
  public icon;

  constructor() {}

  ngOnChanges(): void {
    this.icon = this.getIconForView();
  }

  getIconForView() {
    if (!this.viewDefinition) {
      return;
    }
    let icon = this.viewDefinition.viewType;
    if (this.active) {
      icon = icon += '_active';
    } else {
      icon = icon += '_inactive';
    }
    return icon;
  }
}
