import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { PipesModule } from '@pipes/pipes.module';
import { AggregateRowComponent } from './aggregate-row/aggregate-row.component';
import { ColumnAggregateComponent } from './aggregate-row/column-aggregate/column-aggregate.component';
import { PivotDataCellComponent } from './data-rows/data-cell/pivot-data-cell.component';
import { PivotDataRowComponent } from './data-rows/data-row/pivot-data-row.component';
import { PivotDataRowsComponent } from './data-rows/pivot-data-rows.component';
import { PivotColumnHeaderComponent } from './header-row/column-header/pivot-column-header.component';
import { PivotHeaderRowComponent } from './header-row/pivot-header-row.component';
import { PivotGridComponent } from './pivot-grid.component';
import { PivotViewConfiguratorComponent } from './pivot-view-configurator/pivot-view-configurator.component';
import { PivotViewPropertySelectorComponent } from './pivot-view-configurator/pivot-view-property-selector/pivot-view-property-selector.component';
import { PivotContextMenuComponent } from './context-menu/pivot-context-menu.component';

@NgModule({
  declarations: [
    PivotGridComponent,
    PivotColumnHeaderComponent,
    PivotHeaderRowComponent,
    PivotDataRowsComponent,
    PivotDataRowComponent,
    PivotDataCellComponent,
    AggregateRowComponent,
    ColumnAggregateComponent,
    PivotViewConfiguratorComponent,
    PivotViewPropertySelectorComponent,
    PivotContextMenuComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatTooltipModule,
    DragDropModule,
    MatCheckboxModule,
    MatMenuModule,
    MatExpansionModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDividerModule,
    PipesModule,
    MatAutocompleteModule,
    DragDropModule,
  ],
  exports: [PivotGridComponent, PivotViewConfiguratorComponent, PivotViewPropertySelectorComponent],
})
export class PivotGridModule {
  constructor() {}
}
