<div
  class="overlay"
  #modal
  [ngClass]="{ visible: (spreadValueInfo$ | async) }"
  [ngStyle]="{ top: (spreadValueInfo$ | async)?.y + 'px', left: (spreadValueInfo$ | async)?.x + 'px' }"
>
  <h6>Spread values</h6>
  <div class="instructions">This feature will spread the value across the selected cells.</div>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Current Total</mat-label>
      <input data-test="app-spread-selected-total" matInput [formControl]="currentTotal" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>New Total (Optional)</mat-label>
      <input
        #newtotal
        id="newTotal"
        data-test="app-spread-new-total"
        type="number"
        placeholder="Enter new value (optional)"
        matInput
        [formControl]="newTotal"
      />
    </mat-form-field>
  </div>

  <div *ngIf="weightProperty">
    <span class="text-primary text-title-2">Spread mode</span>
    <mat-radio-group [formControl]="spreadType">
      <mat-radio-button data-test="app-spread-even-type" value="even" #mode>Evenly</mat-radio-button>
      <mat-radio-button data-test="app-spread-weighted-type" value="weighted"
        >Weighted ({{ weightProperty?.propertyDefinition.label }})</mat-radio-button
      >
    </mat-radio-group>
  </div>

  <div class="button">
    <button
      id="cancelSpread"
      data-test="app-cancel-spread-value"
      (click)="close()"
      mat-stroked-button
      color="primary"
      class="!mr-3"
    >
      Cancel
    </button>
    <button
      #applyspread
      id="applySpread"
      data-test="app-apply-spread-value"
      (click)="applySpreadValue()"
      mat-raised-button
      color="primary"
    >
      Apply
    </button>
  </div>
</div>
