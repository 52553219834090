<div
  [attr.data-test]="slug + '-summary-metric'"
  *ngIf="!editingGoal"
  style="width: 100%"
  [ngClass]="{ 'allow-goal': allowGoal }"
  (click)="startEditGoal()"
>
  <div *ngIf="goalValue && !filterActive" style="width: 100%">
    <div class="progress-bar-holder">
      <div class="label">{{ label }}</div>
      <div class="progress-bar">
        <div [style.width.%]="progressPercent" class="progress-bar-progress"></div>
      </div>
      <div class="progress-value">{{ value | roundNumber: 1 }}/{{ goalValue | roundNumber: 1 }}</div>
    </div>
  </div>
  <div *ngIf="!goalValue && !filterActive" class="standard-display">
    <div class="value" [ngClass]="label.toLowerCase()">
      {{ value || 0 | roundNumber: 1 }}<span *ngIf="goalValue">/{{ goalValue | roundNumber: 2 }}</span>
    </div>
    <div class="label">{{ label }}</div>
  </div>
  <div *ngIf="filterActive" class="standard-display">
    <div class="value" [ngClass]="label.toLowerCase()">{{ value || 0 | roundNumber: 1 }}</div>
    <div class="label">{{ label }}</div>
  </div>
</div>
<div *ngIf="allowGoal && !editingGoal" (click)="startEditGoal()" class="add-goal">Click to edit goal.</div>
<app-add-plan-goal
  *ngIf="editingGoal"
  [typeProperty]="typeProperty"
  [goalValue]="goalValue"
  (valueChange)="handleValueChange($event)"
  (cancel)="editingGoal = false"
>
</app-add-plan-goal>
