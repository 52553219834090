import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Entities, EntityReference, SortOrderOptions } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesActions, WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { ChooserSourceOption, ChooserSourceOptionTypes } from '../source-option';
import { ObjectUtil } from '@contrail/util';
import { WorkspaceEntitiesHelperService } from '@common/workspaces/workspace-entities-helper.service';
import { WorkspaceEntity } from '@common/workspaces/workspaces-store/workspaces.state';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from '@common/auth/auth.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { AssortmentsService } from '@common/assortments/assortments.service';
import { AssortmentType } from '@common/assortments/assortments-store/assortments.state';

@Component({
  selector: 'app-item-data-source-selector',
  templateUrl: './item-data-source-selector.component.html',
  styleUrls: ['./item-data-source-selector.component.scss'],
})
export class ItemDataSourceSelectorComponent implements OnInit, OnChanges {
  private destroy$ = new Subject();

  public transformedEntities$: Observable<Array<any>>;
  public loading$: Observable<boolean>;
  public loadingRecent = false;

  @Output() cancel = new EventEmitter();
  @Output() selectSource: EventEmitter<ChooserSourceOption> = new EventEmitter(null);
  @Input() sourceAssortment: any;
  public assortmentFormControl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
  public sourceWorkspaceControl = new UntypedFormControl({ value: null, disabled: false }, Validators.required);
  public sourceAssortments$: Observable<Array<any>>;
  public sourceWorkspaces$: Observable<Array<any>>;
  public sourceWorkspaces: any[];
  public selectedWorkspace: any;
  public selectedSource: ChooserSourceOption;
  public step = 'OPTION_TYPE';
  public optionTypesList: any;

  constructor(
    private store: Store<RootStoreState.State>,
    private workspaceEntitiesHelper: WorkspaceEntitiesHelperService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private assortmentsService: AssortmentsService,
  ) {}

  ngOnInit(): void {
    this.sourceWorkspaces$ = this.store.select(WorkspacesSelectors.workspaces);
    this.sourceWorkspaces$.subscribe((sourceWorkspaces) => (this.sourceWorkspaces = sourceWorkspaces));
    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentWorkspace) => {
        if (this.sourceWorkspaces) {
          const workspace = this.sourceWorkspaces.find((sourceWorkspace) => currentWorkspace.id === sourceWorkspace.id);
          if (!workspace) {
            return;
          }

          this.sourceWorkspaceControl.setValue(workspace);
          this.initSourceAssortments(workspace?.id);
          this.assortmentFormControl.enable();
        }
      });

    this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags) => {
      this.initOptionsTypeList();
    });

    this.loading$ = this.store.select(WorkspacesSelectors.currentWorkspaceFolderLoading);
  }

  ngOnChanges() {
    this.initOptionsTypeList();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  setIcon(entity) {
    this.workspaceEntitiesHelper.setIcon(entity);
  }
  private initOptionsTypeList() {
    this.optionTypesList = [
      {
        name: 'Item Library',
        sourceType: ChooserSourceOptionTypes.ITEM_LIBRARY,
        img: 'assets/images/item.svg',
        description: 'Import any items from the entire library.',
      },
      {
        name: 'Browse Documents',
        sourceType: ChooserSourceOptionTypes.DOCUMENT,
        img: 'assets/images/folder_icon.svg',
        description: 'Import items from any documents you have access to.',
      },
      {
        name: 'Recent Documents',
        sourceType: 'RECENT_DOCUMENT',
        img: 'assets/images/recents_icon.svg',
        description: 'Import items from recently updated documents.',
      },
      {
        name: 'Assortment',
        sourceType: ChooserSourceOptionTypes.ASSORTMENT,
        img: 'assets/images/assortment_icon.svg',
        description: 'Import items from any other assortments.',
      },
    ];
    if (this.sourceAssortment) {
      this.optionTypesList.splice(3, 0, {
        name: 'Source Assortment',
        sourceType: ChooserSourceOptionTypes.SOURCE_ASSORTMENT,
        img: 'assets/images/linked_icon.svg',
        description: `Import items from this document's source assortment.`,
      });
    }

    this.optionTypesList.push({
      name: 'Clipboard',
      sourceType: ChooserSourceOptionTypes.CLIPBOARD,
      img: 'assets/images/clipboard.svg',
      description: 'Import items from clipboard.',
      class: 'opacity-50',
    });
  }

  handleCancel() {
    if (this.step === 'DOCUMENT_SELECT') {
      this.step = 'WORKSPACE_SELECT';
      return;
    }
    if (this.step === 'ASSORTMENT_SELECT' || this.step === 'WORKSPACE_SELECT' || this.step === 'RECENT_DOCUMENT') {
      this.step = 'OPTION_TYPE';
      return;
    }
    this.cancel.emit();
  }

  async selectSourceOptionType(optionType) {
    if (optionType.sourceType === ChooserSourceOptionTypes.ITEM_LIBRARY) {
      this.selectSource.emit({
        sourceType: ChooserSourceOptionTypes.ITEM_LIBRARY,
        name: 'Item Library',
        icon: 'assets/images/item.svg',
      });
    } else if (optionType.sourceType === ChooserSourceOptionTypes.SOURCE_ASSORTMENT) {
      this.selectSource.emit({
        sourceType: ChooserSourceOptionTypes.SOURCE_ASSORTMENT,
        name: this.sourceAssortment?.name,
        workspaceId: this.sourceAssortment?.workspaceId,
        entityReference: 'assortment:' + this.sourceAssortment?.id,
        icon: 'assets/images/linked_icon.svg',
      });
    } else if (optionType.sourceType === ChooserSourceOptionTypes.ASSORTMENT) {
      this.step = 'ASSORTMENT_SELECT';
    } else if (optionType.sourceType === ChooserSourceOptionTypes.DOCUMENT) {
      this.step = 'WORKSPACE_SELECT';
    } else if (optionType.sourceType === 'RECENT_DOCUMENT') {
      this.loadingRecent = true;
      this.step = 'RECENT_DOCUMENT';
      const user = await this.authService.getCurrentUser();
      let data = await new Entities().get({
        entityName: 'user-last-access',
        criteria: { userId: user.id },
        relations: ['entity', 'entity.workspace'],
        order: [{ order: SortOrderOptions.DESC, orderField: 'lastAccess' }],
      });
      let entities = [];
      data
        .filter((d) => d?.entity)
        .forEach((ent) => {
          this.setIcon(ent);
          entities.push(ent);
        });
      this.transformedEntities$ = of(entities);
      this.loadingRecent = false;
    } else if (optionType.sourceType === ChooserSourceOptionTypes.CLIPBOARD) {
      this.selectSource.emit({
        sourceType: ChooserSourceOptionTypes.CLIPBOARD,
        name: 'Clipboard',
        icon: 'assets/images/clipboard.svg',
        class: 'opacity-50',
      });
    }
  }

  private initSourceAssortments(workspaceId: string) {
    this.sourceAssortments$ = from(this.assortmentsService.getAllNonBackingAssortmentsInWorkspace(workspaceId));
  }
  handleWorkspaceChange(workspace) {
    if (workspace) {
      this.initSourceAssortments(workspace.id);
      this.assortmentFormControl.enable();
    } else {
      this.assortmentFormControl.disable();
    }
  }

  selectWorkspace() {
    this.selectedWorkspace = this.sourceWorkspaceControl.value;
    this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolder({ workspace: this.selectedWorkspace }));
    this.workspaceEntitiesHelper.rootWorkspace = this.selectedWorkspace;
    this.step = 'DOCUMENT_SELECT';

    this.transformedEntities$ = this.store.select(WorkspacesSelectors.workspaceFolderEntities).pipe(
      map((entities) => {
        const transformed = [];
        entities.forEach((e) => {
          const listItem: any = ObjectUtil.cloneDeep(e);
          this.setIcon(listItem);
          if (listItem.entityReference?.indexOf('workspace') > -1) {
            listItem.isDropTarget = true;
          }
          transformed.push(listItem);
        });
        transformed.sort((ent1, ent2) => {
          if (this.isFolder(ent1) && !this.isFolder(ent2)) {
            return -1;
          }
          if (this.isFolder(ent1) && this.isFolder(ent2)) {
            return ent1.name < ent2.name ? -1 : 1;
          }
          return ent2.name < ent1.name ? 1 : -1;
        });
        return transformed;
      }),
    );
  }

  private isFolder(workspaceEntity: WorkspaceEntity) {
    return workspaceEntity?.entityReference?.indexOf('workspace') > -1;
  }

  async selectEntity(item) {
    if (item?.entity?.workspaceType === 'FOLDER') {
      this.workspaceEntitiesHelper.goToEntity(item);
    } else {
      const ref = new EntityReference(item.entityReference);
      let assortmentId;
      if (ref.entityType === 'plan') {
        if (item.entity?.targetAssortmentId) {
          assortmentId = item.entity?.targetAssortmentId;
        } else {
          this.snackBar.open('Error - No targetAssortmentId', '⛌', { duration: 1500 });
          // const plan = await new Entities().get({ entityName: 'plan', id: item.entity.id, relations: ['targetAssortment', 'targetAssortment.sourceAssortments']});
          // console.log('no targetAssortmentId', plan);
          return false;
        }
      } else if (ref.entityType === 'showcase' || ref.entityType === 'board') {
        if (item.entity?.backingAssortmentId) {
          assortmentId = item.entity?.backingAssortmentId;
        } else {
          this.snackBar.open('Error - No backingAssortmentId', '⛌', { duration: 1500 });
          // const test = await new Entities().get({ entityName: ref.entityType, id: item.entity.id, relations: ['sourceAssortment']});
          // console.log('no assortment id', test);
          return false;
        }
      } else if (ref.entityType === 'showroom') {
        this.snackBar.open('Not supported yet', '⛌', { duration: 1500 });
        return false;
      }

      // ----------------------------------------------------------------

      this.selectedSource = {
        workspaceId: item?.workspaceId || item?.entity?.workspaceId,
        sourceType: ChooserSourceOptionTypes.ASSORTMENT,
        name: item?.name || item?.entity?.name,
        entityReference: 'assortment: ' + assortmentId,
        id: assortmentId,
        icon: item.icon,
      };
      this.selectSource.emit(this.selectedSource);
    }
  }

  handleAssortmentChange() {
    const assortment = this.assortmentFormControl.value;
    console.log('handleAssortmentChange: ', assortment);
    this.selectAssortment(assortment);
  }
  selectAssortment(assortment) {
    this.selectedSource = {
      workspaceId: assortment.workspaceId,
      sourceType: ChooserSourceOptionTypes.ASSORTMENT,
      name: assortment.name,
      entityReference: 'assortment:' + assortment.id,
      id: assortment.id,
      icon: 'assets/images/assortment_icon.svg',
    };
  }

  /** Finish the selection. */
  handleSelectSource() {
    if (!this.selectedSource) {
      return;
    }
    this.selectSource.emit(this.selectedSource);
  }
}
