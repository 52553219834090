import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';
import { ObjectUtil } from '@contrail/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CollectionManagerActions } from 'src/app/collection-manager/collection-manager-store';
import { UndoRedoActionType, UndoRedoObject } from 'src/app/collection-manager/undo-redo/undo-redo-objects';
import { AssortmentsActions } from 'src/app/common/assortments/assortments-store';
import { AuthActions } from 'src/app/common/auth/auth-store';
import { CommentsActions } from 'src/app/common/comments/comments-store';
import { UndoRedoService } from 'src/app/common/undo-redo/undo-redo-service';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { RootStoreState, UserSessionActions } from 'src/app/root-store';
import { PlansActions } from '.';
import { PlansService } from '../plans.service';
import { Plan } from './plans.state';
import { AuthService } from '@common/auth/auth.service';
import { WorkspacePrincipalRole } from '@common/workspaces/workspace-principal';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { WorkspacesService } from '@common/workspaces/workspaces.service';
import { DownloadService } from '@common/exports/download/download.service';

@Injectable()
export class PlansEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private planService: PlansService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private titleService: Title,
    private webSocketService: WebSocketService,
    private undoRedoService: UndoRedoService,
    private analyticsService: AnalyticsService,
    private workspacesService: WorkspacesService,
    private downloadService: DownloadService,
  ) {}

  authChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.AuthActionTypes.SET_AUTH_CONTEXT),
        tap((action: any) => {}),
      ),
    { dispatch: false },
  );

  editorModeChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.PlansActionTypes.SET_EDITOR_MODE),
        tap((action: any) => {
          this.store.dispatch(CommentsActions.setCommentsAccessLevel({ commentsAccessLevel: action.editorMode }));
        }),
      ),
    { dispatch: false },
  );

  loadPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlansActions.PlansActionTypes.LOAD_PLANS),
      withLatestFrom(this.store),
      tap(() => {
        this.store.dispatch(PlansActions.setPlansLoaded({ loaded: false }));
      }),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.planService.getPlans(store.workspaces.currentWorkspace?.id)).pipe(
          map((data) => {
            const loadedPlans = data ?? [];
            this.store.dispatch(PlansActions.setPlansLoaded({ loaded: true }));
            return PlansActions.loadPlansSuccess({ data: loadedPlans });
          }),
          catchError((error) => observableOf(PlansActions.loadPlansFailure({ error }))),
        );
      }),
    ),
  );

  loadCurrentPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlansActions.PlansActionTypes.LOAD_CURRENT_PLAN),

      switchMap((action: any) => {
        this.store.dispatch(CollectionManagerActions.loadCollectionData({ id: action.id }));
        this.store.dispatch(CollectionManagerActions.setCollectionElementsLoaded({ loaded: false }));
        return from(this.planService.getPlanById(action.id)).pipe(
          tap((data) => this.titleService.setTitle(data.name)),
          switchMap((data) => {
            console.log(data);
            return [
              PlansActions.loadCurrentPlanSuccess({ plan: data }),
              PlansActions.setPlanRowOrder({ planRowOrder: data.planRowOrder }),
              UserSessionActions.loadRemoteUsers({ sessionId: 'plan:' + data.id }),
              UserSessionActions.joinSession({ sessionId: 'plan:' + data.id }),
              WorkspacesActions.loadCurrentWorkspace({ id: data.workspace.rootWorkspaceId }), // Ensure workspace is this plans workspace
              PlansActions.loadPlanEditorMode({ workspaceId: data.workspaceId }),
            ];
          }),
          catchError((error) => {
            if (error?.message?.includes('401 Error:')) {
              this.snackBar.open(`You cannot access the project.`, '', { duration: 1500 });
              setTimeout(() => {
                this.authService.logout(false);
              }, 1800);
            }
            return observableOf(PlansActions.loadCurrentPlanFailure({ error }));
          }),
        );
      }),
    ),
  );

  loadCurrentPlanSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.PlansActionTypes.LOAD_CURRENT_PLAN_SUCCESS),
        tap((action: any) => {
          const plan = action.plan;
          const targetAssortment = plan.targetAssortment;
          if (targetAssortment?.sourceAssortments?.length) {
            const source = targetAssortment.sourceAssortments[0];
            this.store.dispatch(AssortmentsActions.clearSourceAssortment());
            this.store.dispatch(AssortmentsActions.loadSourceAssortment({ sourceAssortmentId: source.id }));
          } else {
            // CLEAR SOURCE ASSORTMENT
            this.store.dispatch(AssortmentsActions.clearSourceAssortment());
          }
          this.analyticsService.emitEvent({
            eventName: 'PLAN_ACCESS',
            eventCategory: EVENT_CATEGORY.ACCESS,
            eventContextProjectId: plan.rootWorkspaceId,
            eventContextWorkspaceId: plan.workspaceId,
            eventLabel: `Plan ${plan.name}`,
            eventTarget: `plan:${plan.id}`,
            eventContext: `plan:${plan.id}`,
          });
        }),
      ),
    { dispatch: false },
  );

  createPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlansActions.PlansActionTypes.CREATE_PLAN),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const plan: Plan = { ...action.plan };
        plan.workspaceId = store.workspaces.currentWorkspace.id;
        return from(this.planService.createPlan(plan)).pipe(
          map((data) => {
            this.snackBar.open('Plan Created.', '', { duration: 2000 });
            // this.store.dispatch(PlansActions.setCurrentPlan({ currentPlan: data }));
            return PlansActions.createPlanSuccess({ plan: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(PlansActions.createPlanFailure({ error }));
          }),
        );
      }),
    ),
  );
  deletePlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlansActions.PlansActionTypes.DELETE_PLAN),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.planService.deletePlan(action.plan)).pipe(
          map((data) => {
            this.snackBar.open('Plan Deleted.', '', { duration: 2000 });
            // this.store.dispatch(PlansActions.setCurrentPlan({ currentPlan: null }));
            return PlansActions.deletePlanSuccess({ plan: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(PlansActions.deletePlanFailure({ error }));
          }),
        );
      }),
    ),
  );
  updatePlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlansActions.PlansActionTypes.UPDATE_PLAN),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.planService.updatePlan(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Plan Updated.', '', { duration: 2000 });
            return PlansActions.updatePlanSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(PlansActions.updatePlanFailure({ error }));
          }),
        );
      }),
    ),
  );

  updateCurrentPlanRowOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.updatePlanRowOrder),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          const currentPlanRowOrder = ObjectUtil.cloneDeep(store.plans.planRowOrder);
          if (action.targetRowIndex > -1) {
            // this is a row move with a single position index. (e.g. real move row)
            if (action.rowIds.length > 0) {
              const clonedSelectedIds = ObjectUtil.cloneDeep(action.rowIds);
              const removedElements = [];
              clonedSelectedIds.forEach((id) => {
                const sourceRowIndex = currentPlanRowOrder.rowOrder.indexOf(id);
                removedElements.push(currentPlanRowOrder.rowOrder.splice(sourceRowIndex, 1)[0]);
              });
              removedElements.forEach((element, index) => {
                currentPlanRowOrder.rowOrder.splice(action.targetRowIndex + index, 0, element);
              });
            }
            // undo-redo
            if (!action.skipRecordingUndoRedo) {
              const updatedPlanRowOrder = ObjectUtil.cloneDeep(store.plans.planRowOrder);
              const changesList: UndoRedoObject[] = [];
              const rowOrderUndoChanges = [];
              const movedRowOrders = action.rowIds
                .filter((id) => updatedPlanRowOrder.rowOrder.indexOf(id) > -1)
                .map((id) => ({ id, index: updatedPlanRowOrder.rowOrder.indexOf(id) }));

              movedRowOrders.forEach((undoChange) => {
                rowOrderUndoChanges.push(undoChange);
              });
              changesList.push({
                id: updatedPlanRowOrder.id,
                changes: { rowIds: action.rowIds, targetRowIndex: action.targetRowIndex },
                undoChanges: rowOrderUndoChanges,
              });
              this.undoRedoService.addUndo({ actionType: UndoRedoActionType.UPDATE_ROW_ORDER, changesList });
            }
          } else {
            // this a row move with individual position index (e.g. undo move rows)
            if (action.rowIds.length > 0) {
              const clonedSelectedIds = ObjectUtil.cloneDeep(action.rowIds);
              clonedSelectedIds.forEach((row) => {
                const sourceRowIndex = currentPlanRowOrder.rowOrder.indexOf(row.id);
                currentPlanRowOrder.rowOrder.splice(sourceRowIndex, 1);
              });
              clonedSelectedIds.forEach((row) => {
                currentPlanRowOrder.rowOrder.splice(row.index, 0, row.id);
              });
            }
          }
          this.webSocketService.sendSessionEvent({
            eventType: 'SYNC_PLAN_ROW_ORDER',
            changes: currentPlanRowOrder,
          });
          //  dispatch event first to move rows before persisting for better user experience
          this.store.dispatch(PlansActions.syncPlanRowOrder({ planRowOrder: currentPlanRowOrder }));
          this.planService.updatePlanRowOrder(currentPlanRowOrder.id, {
            rowIds: action.rowIds,
            targetRowIndex: action.targetRowIndex,
          });
        }),
      ),
    { dispatch: false },
  );

  copyPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlansActions.PlansActionTypes.COPY_PLAN),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.planService.copy(action.name, action.sourceId)).pipe(
          map((data) => {
            this.snackBar.open('Plan Copied.', '', { duration: 2000 });
            return PlansActions.copyPlanSuccess({ plan: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(PlansActions.copyPlanFailure({ error }));
          }),
        );
      }),
    ),
  );

  asyncCopyPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.PlansActionTypes.ASYNC_COPY_PLAN),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.planService.asyncCopyPlan(action.name, action.sourceId)).pipe(
            map((data) => {
              if (!data?.jobId) {
                throw new Error();
              }
              this.downloadService.initDownloadPolling(data.jobId, `/plans/copy/${data.jobId}`);
              return data;
            }),
            catchError((error) => {
              this.snackBar.open(error, 'An error occurred while attempting to copy this document. Please try again.', {
                duration: 2000,
              });
              return observableOf(PlansActions.copyPlanFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  asyncCopyPlanSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.PlansActionTypes.ASYNC_COPY_PLAN_SUCCESS),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          if (action.path) {
            const response = await fetch(action.path);
            const plan = await response.json();
            this.snackBar.open('Plan Copied.', '', { duration: 2000 });
            this.store.dispatch(PlansActions.copyPlanSuccess({ plan }));
          }
        }),
      ),
    { dispatch: false },
  );

  loadPlanEditorMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.PlansActionTypes.LOAD_PLAN_EDITOR_MODE),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          if (store.plans.editorMode) {
            return;
          }

          const workspaceId = action.workspaceId;
          const userRole = await this.workspacesService.getUserRoleInWorkspace(workspaceId);
          if (userRole.role === WorkspacePrincipalRole.viewer) {
            this.store.dispatch(PlansActions.setEditorMode({ editorMode: EditorMode.VIEW }));
          } else {
            this.store.dispatch(PlansActions.setEditorMode({ editorMode: EditorMode.EDIT }));
          }
        }),
      ),
    { dispatch: false },
  );
}
