import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { FilterConditionType } from '@contrail/filters';
import { combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { FilterDefinitionBuilder } from 'src/app/common/components/filter/filter-definition-builder';
import { MenuComponent } from 'src/app/common/components/menu/menu.component';
import { FilterDefinition } from 'src/app/common/types/filters/filter-definition';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { FilterService } from '../filter-service';
import { ViewDefinition } from '@contrail/client-views';
import { TypeProperty } from '@contrail/types';
import { CollectionManagerViewService } from '../../collection-manager-view.service';
import { AuthSelectors } from '@common/auth/auth-store';

@Component({
  selector: 'app-filter-selector',
  templateUrl: './filter-selector.component.html',
  styleUrls: ['./filter-selector.component.scss'],
})
export class FilterSelectorComponent implements OnInit {
  public filterDefinition$: Observable<FilterDefinition>;
  public selectedFilterDefinition$: Observable<FilterDefinition>;
  public allPlaceholders$: Observable<Array<any>>;
  public currentView: ViewDefinition;
  public propertyTypeDefaultFilterConditions: any = {};
  private currentFilterDefinition: FilterDefinition;

  constructor(
    private store: Store<RootStoreState.State>,
    private filterService: FilterService,
    private viewService: CollectionManagerViewService,
  ) {}
  selectedFilterCriteria;
  label = 'Filter';
  filterActive = false;
  @Input() toolTip;
  @Input() disabled = false;
  @ViewChild('filterMenu') filterMenu: MenuComponent;

  ngOnInit(): void {
    this.allPlaceholders$ = this.store.select(CollectionManagerSelectors.allData);

    combineLatest([
      this.store.select(CollectionManagerSelectors.typeDefinitions),
      this.store.select(CollectionManagerSelectors.selectCurrentView),
    ])
      .pipe(
        map(([typeMap, currentView]) => {
          this.currentView = currentView;
          const typeProperties: TypeProperty[] = ObjectUtil.cloneDeep(typeMap['plan-placeholder'].typeProperties);
          typeProperties.push(this.viewService.alertProperty);
          const clonedFilterDefinition: FilterDefinition =
            FilterDefinitionBuilder.buildFilterDefinition(typeProperties);

          if (currentView?.filterCriteria) {
            clonedFilterDefinition.filterCriteria = ObjectUtil.cloneDeep(currentView.filterCriteria);
            if (clonedFilterDefinition.filterCriteria.propertyCriteria.length > 0) {
              this.label = 'Filter (' + clonedFilterDefinition.filterCriteria.propertyCriteria.length + ')';
              this.filterActive = true;
            }
          }

          this.store.dispatch(
            CollectionManagerActions.setFilterDefinition({ filterDefinition: clonedFilterDefinition }),
          );

          const hasFilterChanged =
            ObjectUtil.compareDeep(this.currentFilterDefinition, clonedFilterDefinition, '').length > 0;
          if (hasFilterChanged) {
            this.store.dispatch(CollectionManagerActions.setScrollVerticalPercentage({ percentage: 0 }));
          }
          this.currentFilterDefinition = clonedFilterDefinition;
        }),
      )
      .subscribe();

    this.selectedFilterDefinition$ = this.store.select(CollectionManagerSelectors.filterDefinition).pipe(
      map((filterDefinition) => {
        const clonedFilterDefinition: FilterDefinition = ObjectUtil.cloneDeep(filterDefinition);
        if (clonedFilterDefinition?.filterCriteria) {
          clonedFilterDefinition.filterCriteria.propertyCriteria.forEach((criteria) => {
            if (
              !criteria.hasOwnProperty('criteriaValue') &&
              criteria.filterConditionType !== FilterConditionType.IS_EMPTY
            ) {
              // This is to open the filter panel automatically when filter is set from column header menu
              // assuming filter set from column header menu will not have criteria
              if (this.filterMenu && !this.filterMenu.panelVisible) {
                this.filterMenu.togglePanel();
              }
            }
          });
          if (clonedFilterDefinition.filterCriteria.propertyCriteria.length > 0) {
            this.label = 'Filter (' + clonedFilterDefinition.filterCriteria.propertyCriteria.length + ')';
            this.filterActive = true;
          } else {
            this.label = 'Filter';
            this.filterActive = false;
          }
        }
        return clonedFilterDefinition;
      }),
    );
    this.store.select(AuthSelectors.currentOrg).subscribe((org) => {
      if (org.orgConfig?.propertyTypeDefaultFilterConditions) {
        this.propertyTypeDefaultFilterConditions = org.orgConfig.propertyTypeDefaultFilterConditions;
      }
    });
  }

  setFilterCriteria(filterCriteria) {
    this.selectedFilterCriteria = filterCriteria;
    this.store
      .select(CollectionManagerSelectors.filterDefinition)
      .pipe(
        take(1),
        tap((currentfilterDefinition) => {
          const newDefinition: FilterDefinition = ObjectUtil.cloneDeep(currentfilterDefinition);
          newDefinition.filterCriteria = ObjectUtil.cloneDeep(filterCriteria);
          //this.store.dispatch(CollectionManagerActions.setFilterDefinition({ filterDefinition: newDefinition }));
          this.store.dispatch(
            CollectionManagerActions.updateViewDefinition({ id: this.currentView.id, changes: { filterCriteria } }),
          );
        }),
      )
      .subscribe();
    this.store.dispatch(CollectionManagerActions.clearSelectedEntityIds());
  }

  removeFilters(event) {
    event.stopPropagation();
    this.filterService.removeAllFilters();
  }
}
