<div class="header">
  <button mat-raised-button color="primary" (click)="createPlan()" *ngIf="canCreate">
    <mat-icon>add</mat-icon>NEW
  </button>
  <app-search-bar [lgGray]="true" [placeholder]="'Search'"></app-search-bar>
  <app-list-view-toggle (toggle)="setViewMode($event)" [mode]="viewMode" class="mr-auto"></app-list-view-toggle>
  <mat-slide-toggle
    labelPosition="before"
    class="mr-12 my-2"
    color="primary"
    [checked]="myPlansOnly.value"
    [formControl]="myPlansOnly"
    name="myPlansOnly"
  >
    <p class="text-sm">Created by me</p>
  </mat-slide-toggle>
  <div class="flex items-center">
    <div class="sort-by flex items-center cursor-pointer my-2" [matMenuTriggerFor]="sortByMenu">
      <p class="text-sm mr-2">Sort by:</p>
      <p class="text-sm text-primary">{{ sortByControl.value?.label }}</p>
    </div>
    <div class="cursor-pointer flex items-center" (click)="sortByDirection()">
      <mat-icon class="ml-1" *ngIf="(sortDirection$ | async) === 'asc'">arrow_drop_up</mat-icon>
      <mat-icon class="ml-1" *ngIf="(sortDirection$ | async) === 'desc'">arrow_drop_down</mat-icon>
    </div>
  </div>
  <mat-menu #sortByMenu="matMenu" yPosition="below" xPosition="before" class="menu-sm">
    <button mat-menu-item *ngFor="let option of sortOptions" (click)="sortBy(option)">
      {{ option?.label }}
    </button>
  </mat-menu>
</div>
<div class="body p-3">
  <div *ngIf="viewMode === 'cards'" class="cards">
    <app-plan-card
      [plan]="plan"
      *ngFor="let plan of filteredPlans$ | async"
      (dblclick)="goToPlan(plan)"
      (openMenu)="showMenu({ mouseEvent: $event, itemData: plan })"
      (contextmenu)="handleContextMenu({ mouseEvent: $event, listItem: plan })"
    >
    </app-plan-card>
  </div>
  <app-plans-list
    [plans]="filteredPlans$ | async"
    *ngIf="viewMode === 'list'"
    (contextMenu)="handleContextMenu($event)"
  ></app-plans-list>
</div>
<app-context-menu [actionDefinitions]="contextMenuActions" (selected)="handleMenuAction($event)"></app-context-menu>
<app-download-content-snack
  class="fixed bottom-6 left-6"
  [label]="'Copying...'"
  [errorMessage]="'An error occurred while attempting to copy this document. Please try again.'"
  useOnDownloadSuccess="true"
  (onDownloadSuccess)="onCopySuccess($event)"
></app-download-content-snack>
