import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, from, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { PlansActions, RootStoreState } from '../root-store';
import { EditorMode } from '../collection-manager/collection-manager-store/collection-manager.state';
import { AuthSelectors } from '../common/auth/auth-store';
import { ItemService } from '@common/items/item.service';

@Injectable({
  providedIn: 'root',
})
export class PlanEditorModeResolver {
  constructor(
    private store: Store<RootStoreState.State>,
    private itemService: ItemService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const planId = route.params.planId;
    this.itemService.checkItemChooserCriteria(planId);

    return from(this.getEditorModeForUser(route)).pipe(
      switchMap((mode) => {
        if (mode) {
          this.store.dispatch(PlansActions.setEditorMode({ editorMode: mode }));
        }
        return of(mode);
      }),
    );
  }

  private async getEditorModeForUser(route: ActivatedRouteSnapshot): Promise<EditorMode> {
    let sharedLink;
    this.store
      .select(AuthSelectors.sharedLink)
      .pipe(
        take(1),
        tap((link) => (sharedLink = link)),
      )
      .subscribe();

    if (sharedLink) {
      return EditorMode[sharedLink.accessLevel];
    }
  }
}
