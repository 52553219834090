import { AggregateHelper, AggregatePropertyInfo, AggregateTypePropertyHelper } from '@contrail/aggregates';
import { PropertyType } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';

export const AGGREGATE_FUNCTION_MAP = {
  SUM: 'total',
  MAX: 'max',
  MIN: 'min',
  AVG: 'average',
};
export interface AggregateRowEntity {
  id: string;
  propertySlug: string;
  children?: Array<AggregateRowEntity>; // nested group properties
  [key: string]: any;
}

export class PivotAggregateHelper {
  public static EMPTY_VALUE = '**empty**';
  public static computeAggregates(
    aggregateRowEntity: AggregateRowEntity,
    data: Array<AggregatePropertyInfo>,
    rowProperties: Array<AggregatePropertyInfo>,
    columnProperties: Array<AggregatePropertyInfo>,
  ) {
    this.constructNestedAggregateRowEntity(rowProperties, aggregateRowEntity, data, columnProperties, -1);
  }

  private static constructNestedAggregateRowEntity(
    rowProperties: Array<AggregatePropertyInfo>,
    aggregateRowEntity: AggregateRowEntity,
    data: Array<any>,
    columnProperties: AggregatePropertyInfo[],
    level: number,
    parentGroupingValues = {},
  ) {
    const rowGroupingProperty = rowProperties.splice(0, 1)[0];
    let optionVals: any[] = rowGroupingProperty.typeProperty.options?.map((option) => option.value);
    level++;

    if (rowGroupingProperty.typeProperty.propertyType === PropertyType.MultiSelect) {
      const validValues = data.map((cell) => {
        let cellValue = ObjectUtil.cloneDeep(cell[rowGroupingProperty.typeProperty.slug]);
        if (cellValue) {
          if (!Array.isArray(cellValue)) {
            cellValue = [cellValue];
          }
          cellValue.sort();
        }
        return cellValue;
      });
      const set = new Set(validValues.map((value) => (value ? JSON.stringify(value) : null)));
      optionVals = Array.from(set).map((value) => (value ? JSON.parse(value) : value));
    } else if (rowGroupingProperty.typeProperty.propertyType === PropertyType.ObjectReference) {
      optionVals = [
        ...new Map(
          data.map((cell) => cell[rowGroupingProperty.typeProperty.slug]).map((obj) => [obj?.id, obj]),
        ).values(),
      ];
    } else if (!optionVals) {
      optionVals = Array.from(new Set(data.map((cell) => cell[rowGroupingProperty.typeProperty.slug])));
    }
    const index = `${rowGroupingProperty.indexPrefix || ''}${rowGroupingProperty.typeProperty.slug}`;
    // FOR EACH OPTION..
    for (const optionValue of optionVals) {
      if (optionValue) {
        const filteredData = data.filter((obj) => {
          let testValue = ObjectUtil.cloneDeep(ObjectUtil.getByPath(obj, index));
          if (rowGroupingProperty.typeProperty.propertyType === PropertyType.MultiSelect) {
            if (!Array.isArray(testValue)) {
              testValue = [testValue];
            }
          }
          if (Array.isArray(optionValue)) {
            return optionValue.sort().toString() === testValue?.sort().toString(); // change array to string to compare?
          } else if (rowGroupingProperty.typeProperty.propertyType === PropertyType.ObjectReference) {
            return optionValue?.id === testValue?.id;
          } else {
            return optionValue === testValue;
          }
        });
        if (filteredData?.length > 0) {
          PivotAggregateHelper.addAggregateData(
            optionValue,
            filteredData,
            rowGroupingProperty,
            columnProperties,
            aggregateRowEntity,
            rowProperties,
            level,
            parentGroupingValues,
          );
        }
      }
    }
    // Test for empty value
    const emptyFilteredData = data.filter((obj) => {
      const testValue = ObjectUtil.getByPath(obj, index);
      return testValue === '' || testValue === null || testValue === undefined;
    });
    PivotAggregateHelper.addAggregateData(
      this.EMPTY_VALUE,
      emptyFilteredData,
      rowGroupingProperty,
      columnProperties,
      aggregateRowEntity,
      rowProperties,
      level,
    );
  }

  private static addAggregateData(
    value: any,
    data: any,
    rowGroupingProperty: any,
    columnProperties: any[],
    aggregates: any,
    rowProperties: any[],
    level: number,
    parentGroupingValues = {},
  ) {
    if (data.length > 0) {
      const id = aggregates.id + '-' + (value.id || value);
      const aggregateRowEntity: AggregateRowEntity = { id, propertySlug: rowGroupingProperty.typeProperty.slug, level };
      aggregateRowEntity[rowGroupingProperty.typeProperty.slug] = value; // Sets the value of the current grouping property

      // Set all parent values on this row
      Object.assign(aggregateRowEntity, parentGroupingValues);
      parentGroupingValues = ObjectUtil.cloneDeep(parentGroupingValues || {});
      parentGroupingValues[rowGroupingProperty.typeProperty.slug] = value; // Add this value.

      this.computeColumnAggregates(aggregateRowEntity, data, columnProperties);
      aggregates.children = aggregates.children || [];
      aggregates.children.push(aggregateRowEntity);
      if (rowProperties.length > 0) {
        this.constructNestedAggregateRowEntity(
          ObjectUtil.cloneDeep(rowProperties),
          aggregateRowEntity,
          data,
          columnProperties,
          level,
          parentGroupingValues,
        );
      }
    }
  }

  private static computeColumnAggregates(aggregateRowEntity, data, columnProperties: Array<any>) {
    columnProperties.forEach((property) => {
      const propertyAggregate = AggregateHelper.computeAggregatesForNumeric(
        data,
        property.indexPrefix,
        property.typeProperty.slug,
      );
      if (propertyAggregate) {
        aggregateRowEntity[property.typeProperty.slug] = propertyAggregate; // could be total, average, min, max?
      }
    });
    aggregateRowEntity['count'] = data.length;
  }
}
