import {
  OnChanges,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { escapeRegExp } from 'lodash';
import { TypePropertyOption } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { DataCellEditBaseComponent } from '../data-cell-edit-base-component';

export interface DropDownOption {
  value: string;
  display: string;
  longDisplay?: string;
}
@Component({
  selector: 'app-drop-down-list',
  template: ` <div
      class="select-edit"
      style="width:100%"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu"
      (menuOpened)="menuOpened()"
      (menuClosed)="menuClosed()"
    >
      <div class="view">{{ display$ | async }}</div>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <mat-menu #menu="matMenu" class="menu-sm">
      <div mat-menu-item class="filter-widget" (focus)="filterFocused()" (click)="$event.stopPropagation()">
        <app-search-bar [placeholder]="'Search'"></app-search-bar>
      </div>
      <button *ngFor="let option of filteredOptions$ | async" mat-menu-item (click)="setOptionValue(option)">
        {{ option.longDisplay || option.display }}
      </button>
    </mat-menu>`,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        justify-content: flex-end;
        text-align: right;
      }
      .select-edit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 20px;
        cursor: pointer;
      }
      .view {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .filter-widget {
        height: inherit;
        padding: 4px 8px;
      }
    `,
  ],
})
export class DropDownListComponent implements AfterViewInit, OnChanges {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @Input() dropDownOptions: Array<DropDownOption> = [];
  @Input() selectedOption: DropDownOption;
  @Output() optionChange = new EventEmitter();

  public filteredOptions$: Observable<Array<DropDownOption>>;
  public allAvailableOptions$: BehaviorSubject<Array<DropDownOption>> = new BehaviorSubject([]);
  public editing = false;
  public display$ = new BehaviorSubject('');
  constructor(private changeRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.filteredOptions$ = combineLatest([
      this.searchBar.valueChange.pipe(startWith('')),
      this.allAvailableOptions$,
    ]).pipe(
      map(([searchTerm, availableOptions]) => {
        const keys = 'display';
        const options: Array<DropDownOption> = ObjectUtil.cloneDeep(availableOptions);
        if (!options) {
          return [];
        } else {
          return options.filter((option) =>
            keys
              .split(',')
              .some(
                (key) => option.hasOwnProperty(key) && new RegExp(escapeRegExp(searchTerm), 'gi').test(option[key]),
              ),
          );
        }
      }),
    );
    this.searchBar.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('DropDOwnListComponent: ', this.dropDownOptions);
    this.allAvailableOptions$.next(this.dropDownOptions);
    if (this.selectedOption) {
      this.display$.next(this.selectedOption.display);
    } else {
      this.display$.next('');
    }
  }

  setOptionValue(option) {
    this.display$.next(option.display);
    this.optionChange.emit(option);
  }

  filterFocused() {
    this.searchBar.focus();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  menuOpened() {
    this.editing = true;
  }

  menuClosed() {
    this.editing = false;
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent = null) {
    // use composedPath to make sure that keyevent did not originate from other element
    if (event.composedPath()[0] instanceof HTMLBodyElement && !this.menuTrigger.menuOpen && !this.editing) {
      setTimeout(() => {
        this.menuTrigger.openMenu();
        this.searchBar.focus();
      }, 100);
    } else if (this.menuTrigger.menuOpen && this.editing) {
      this.menuTrigger.closeMenu();
    }
    event.stopPropagation();
  }

  @HostListener('document:keydown', ['$event'])
  handleEvents(event: KeyboardEvent = null) {
    if (this.editing) {
      event.stopPropagation(); // stop propagating key event to other listeners
    }
  }
}
