import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { DataCellEditBaseComponent } from '../data-cell-edit-base-component';
@Component({
  selector: 'app-data-cell-edit-text',
  template: `
    <input [attr.data-test]="'input-' + property.slug" #inputField (blur)="handleChange()" [(ngModel)]="value" />
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      input {
        border: none;
        font-size: 12px;
        line-height: normal;
        padding: 0px;
        width: 95%;
      }
    `,
  ],
})
export class DataCellEditTextComponent extends DataCellEditBaseComponent implements AfterViewInit {
  @ViewChild('inputField') input: ElementRef;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.focusOnCell.emit(this.input?.nativeElement);
  }
}
