import {
  Component,
  Input,
  OnChanges,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  HostListener,
  SimpleChange,
} from '@angular/core';
import { ViewDefinition } from '@contrail/client-views';
import { Type } from '@contrail/types';
import { Store } from '@ngrx/store';
import { CollectionManagerActions } from 'src/app/collection-manager/collection-manager-store';
import { RootStoreState } from 'src/app/root-store';
import { ViewPropertyDragDropListComponent } from '../view-property-drag-drop-list/view-property-drag-drop-list.component';
import { PropertyConfiguration } from '../view-property-configurator.component';

@Component({
  selector: 'app-view-property-configurator-list',
  templateUrl: './view-property-configurator-list.component.html',
  styleUrls: ['./view-property-configurator-list.component.scss'],
})
export class ViewPropertyConfiguratorListComponent implements OnChanges {
  @Input() typeMap: { [key: string]: Type };
  @Input() currentView: ViewDefinition;
  @Input() isVisible: boolean;
  public hidePropertiesList: Array<PropertyConfiguration>;
  public showPropertiesList: Array<PropertyConfiguration>;

  private propertiesList: Array<PropertyConfiguration> = [];
  private propertySlugsAtStartOfHideList: Array<string> = ['itemFamily', 'itemOption', 'thumbnail'];
  private undoList: { hidePropertyIds: string[]; showPropertyIds: string[] }[] = [];
  private redoList: { hidePropertyIds: string[]; showPropertyIds: string[] }[] = [];

  @ViewChild('hideProperties') hidePropertiesComponent: ViewPropertyDragDropListComponent;
  @ViewChild('showProperties') showPropertiesComponent: ViewPropertyDragDropListComponent;

  constructor(private store: Store<RootStoreState.State>) {}

  ngOnChanges(changes: { [input: string]: SimpleChange }): void {
    if (changes.currentView || changes.typeMap) {
      if (this.typeMap && this.currentView) {
        this.buildPropertiesList();
      } else {
        this.propertiesList = [];
        this.showPropertiesList = [];
        this.hidePropertiesList = [];
      }
    }

    if (changes?.isVisible?.currentValue === true) {
      this.initializeUndoRedoState();
    }

    if (changes?.isVisible?.currentValue === false) {
      this.undoList = [];
      this.redoList = [];
    }
  }

  public buildPropertiesList() {
    const viewProperties = this.currentView.properties; // LIST OF PROPERTIES IN THE CURRENT VIEW
    const propertyMap = {}; // MAP OF AVAILABLE PROPERTIES

    // Creates a map of all available properties, mapped by type root slug and property slug
    const typeRootSlug = 'plan-placeholder';
    const type = this.typeMap[typeRootSlug];
    let enabled = false;
    type.typeProperties.forEach((prop) => {
      const viewProp = viewProperties.find((p) => p.slug === prop.slug);
      enabled = !!viewProp && viewProp.enabled;
      propertyMap[`${typeRootSlug}:${prop.slug}`] = {
        enabled,
        typeProperty: prop,
        rootTypeSlug: typeRootSlug,
      };
    });

    // Build sortable list of properties
    const propertiesList = [];
    viewProperties.forEach((viewProp) => {
      if (!viewProp.propertyDefinition) {
        return;
      }
      const key = `${viewProp.typeRootSlug}:${viewProp.propertyDefinition.slug}`;
      const propertyDef = propertyMap[key];
      if (propertyDef) {
        propertiesList.push(propertyDef);
      }
    });

    // Add properties that are not in the view
    Object.keys(propertyMap).forEach((key) => {
      const propertyDef = propertyMap[key];
      const inView = propertiesList.find((p) => p.typeProperty.slug === propertyDef.typeProperty.slug);
      if (!inView) {
        propertiesList.push(propertyDef);
      }
    });
    // Find the last frozen column and insert the "freeze column breakpoint" item next to it
    const freezeColumnBreakpointIndex = this.currentView.properties.filter((prop) => prop.frozen).length;
    propertiesList.splice(freezeColumnBreakpointIndex, 0, {
      typeProperty: { id: 'freezeColumnBreakpoint', label: 'Freeze Column' },
      enabled: true,
    });
    this.propertiesList = propertiesList;

    const startingHideProperties = propertiesList
      .filter((prop) => !prop.enabled && this.propertySlugsAtStartOfHideList.includes(prop.typeProperty.slug))
      .sort(
        (a, b) =>
          this.propertySlugsAtStartOfHideList.indexOf(a.typeProperty.slug) -
          this.propertySlugsAtStartOfHideList.indexOf(b.typeProperty.slug),
      );

    const sortedHideProperties = propertiesList
      .filter((prop) => !prop.enabled && !this.propertySlugsAtStartOfHideList.includes(prop.typeProperty.slug))
      .sort((a, b) => a.typeProperty.label.localeCompare(b.typeProperty.label));

    this.hidePropertiesList = [...startingHideProperties, ...sortedHideProperties];
    this.showPropertiesList = propertiesList.filter((prop) => prop.enabled);
  }

  private updateView(hideProperties: PropertyConfiguration[], showProperties: PropertyConfiguration[]) {
    const newViewPropertyConfig = JSON.parse(JSON.stringify(this.currentView.properties)); // clone

    const viewPropertyMap = {};
    newViewPropertyConfig.forEach((prop) => (viewPropertyMap[prop.slug] = prop));
    const freezeColumnBreakpointIndex = showProperties.findIndex(
      (prop) => prop.typeProperty.id === 'freezeColumnBreakpoint',
    );
    // Roll through the properties list, which is all available properties.
    // If enabled, and not in view property config, add to the view.
    // If not enabled and in the view properry config, disable in the view config.
    const newViewPropList = [];

    showProperties.forEach((listProp, index) => {
      if (listProp.typeProperty.id === 'freezeColumnBreakpoint') {
        return;
      }

      let viewProp = viewPropertyMap[listProp.typeProperty.slug];
      if (!viewProp) {
        viewProp = {
          slug: listProp.typeProperty.slug,
          typeRootSlug: listProp.rootTypeSlug,
          enabled: true,
          propertyDefinition: listProp.typeProperty,
        };
      }

      if (viewProp) {
        viewProp.enabled = true;
        viewProp.frozen = Boolean(index < freezeColumnBreakpointIndex);
        newViewPropList.push(viewProp);
      }
    });

    hideProperties.forEach((listProp, index) => {
      if (listProp.typeProperty.id === 'freezeColumnBreakpoint') {
        return;
      }

      const viewProp = viewPropertyMap[listProp.typeProperty.slug];
      if (viewProp) {
        viewProp.enabled = false;
        viewProp.frozen = false;
        newViewPropList.push(viewProp);
      }
    });

    this.store.dispatch(
      CollectionManagerActions.updateViewDefinition({
        id: this.currentView.id,
        changes: { properties: newViewPropList },
      }),
    );
  }

  handleShowPropertiesRemoved(showProperties: Array<PropertyConfiguration>) {
    this.showPropertiesList = showProperties;
  }

  handleHidePropertiesRemoved(hideProperties: Array<PropertyConfiguration>) {
    this.hidePropertiesList = hideProperties;
  }

  handleHidePropertiesUpdated(hideProperties: Array<PropertyConfiguration>) {
    this.handlePropertiesUpdated(hideProperties, this.showPropertiesList);
  }

  handleShowPropertiesUpdated(showProperties: Array<PropertyConfiguration>) {
    this.handlePropertiesUpdated(this.hidePropertiesList, showProperties);
  }

  moveShowPropertyToHide(property: PropertyConfiguration) {
    if (property.typeProperty.id === 'freezeColumnBreakpoint') {
      return;
    }

    const propertyIndex = this.showPropertiesList.findIndex(
      (showProperty) => showProperty.typeProperty.id === property.typeProperty.id,
    );
    const updatedShowPropertiesList = this.showPropertiesList.filter((prop, index) => index !== propertyIndex);
    const updatedHidePropertiesList = [...this.hidePropertiesList, property];
    this.handlePropertiesUpdated(updatedHidePropertiesList, updatedShowPropertiesList);
  }

  moveHidePropertyToShow(property: PropertyConfiguration) {
    const propertyIndex = this.hidePropertiesList.findIndex(
      (hideProperty) => hideProperty.typeProperty.id === property.typeProperty.id,
    );
    const updatedHidePropertiesList = this.hidePropertiesList.filter((prop, index) => index !== propertyIndex);
    const updatedShowPropertiesList = [...this.showPropertiesList, property];
    this.handlePropertiesUpdated(updatedHidePropertiesList, updatedShowPropertiesList);
  }

  addSelectedToShowProperties() {
    const selectedHidePropertyIndexes = this.hidePropertiesComponent.selectedIndexes;
    const hidePropertiesToAdd = selectedHidePropertyIndexes
      ?.map((index) => this.hidePropertiesList[index])
      ?.filter((property) => !!property);

    if (!hidePropertiesToAdd?.length) {
      return;
    }

    const updatedShowPropertiesList = [...this.showPropertiesList, ...hidePropertiesToAdd];
    const updatedHidePropertiesList = this.hidePropertiesList.filter(
      (_, index) => !selectedHidePropertyIndexes.includes(index),
    );
    this.handlePropertiesUpdated(updatedHidePropertiesList, updatedShowPropertiesList);
  }

  addSelectedToHideProperties() {
    const selectedShowPropertyIndexes = this.showPropertiesComponent.selectedIndexes;
    const showPropertiesToAdd = selectedShowPropertyIndexes
      ?.map((index) => this.showPropertiesList[index])
      ?.filter((property) => !!property && property.typeProperty.id !== 'freezeColumnBreakpoint');

    if (!showPropertiesToAdd?.length) {
      return;
    }

    const updatedHidePropertiesList = [...this.hidePropertiesList, ...showPropertiesToAdd];
    const updatedShowPropertiesList = this.showPropertiesList.filter(
      (_, index) => !selectedShowPropertyIndexes.includes(index),
    );
    this.handlePropertiesUpdated(updatedHidePropertiesList, updatedShowPropertiesList);
  }

  handlePropertiesUpdated(hideProperties: PropertyConfiguration[], showProperties: PropertyConfiguration[]) {
    this.addToUndoList(hideProperties, showProperties);
    this.updateView(hideProperties, showProperties);
  }

  initializeUndoRedoState() {
    this.undoList = [];
    this.redoList = [];

    if (this.propertiesList.length) {
      this.addToUndoList(this.hidePropertiesList, this.showPropertiesList);
    }
  }

  addToUndoList(hideProperties: PropertyConfiguration[], showProperties: PropertyConfiguration[]) {
    const state = this.buildUndoRedoState(hideProperties, showProperties);
    this.undoList.push(state);
    this.redoList = [];
  }

  buildUndoRedoState(
    hideProperties: PropertyConfiguration[],
    showProperties: PropertyConfiguration[],
  ): { hidePropertyIds: string[]; showPropertyIds: string[] } {
    const hidePropertyIds = hideProperties.map((prop) => prop.typeProperty.id);
    const showPropertyIds = showProperties.map((prop) => prop.typeProperty.id);
    return { hidePropertyIds, showPropertyIds };
  }

  redoLastChange() {
    if (this.redoList.length === 0) {
      return;
    }

    const redo = this.redoList.pop();
    this.undoList.push(redo);
    this.applyUndoRedoState(redo);
  }

  undoLastChange() {
    if (this.undoList.length === 1) {
      return;
    }

    const redoStateToSave = this.undoList.pop();
    const undoStateToApply = this.undoList[this.undoList.length - 1];
    this.redoList.push(redoStateToSave);
    this.applyUndoRedoState(undoStateToApply);
  }

  applyUndoRedoState(state: { hidePropertyIds: string[]; showPropertyIds: string[] }) {
    const { hidePropertyIds, showPropertyIds } = state;
    const hideProperties = hidePropertyIds.map((id) => this.propertiesList.find((prop) => prop.typeProperty.id === id));
    const showProperties = showPropertyIds.map((id) => this.propertiesList.find((prop) => prop.typeProperty.id === id));

    const updatedHideProperties = hideProperties;
    const updatedShowProperties = showProperties;
    this.updateView(updatedHideProperties, updatedShowProperties);
  }

  @HostListener('document:keydown', ['$event'])
  private handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.isVisible) {
      return;
    }

    const isRedo = Boolean(event.key === 'z' && (event.ctrlKey || event.metaKey) && event.shiftKey);
    if (isRedo) {
      event.stopPropagation();
      this.redoLastChange();
      return;
    }

    const isUndo = Boolean(event.key === 'z' && (event.ctrlKey || event.metaKey));
    if (isUndo) {
      event.stopPropagation();
      this.undoLastChange();
      return;
    }
  }
}
