import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Plan } from '../plans-store/plans.state';

@Component({
  selector: 'app-plan-card',
  template: `<app-list-card
    *ngIf="plan"
    [title]="plan.name"
    [imageUrl]=""
    (openMenu)="showMenu($event)"
    [updatedOn]="plan.updatedOn"
    iconUrl="assets/images/plan_icon.svg"
  >
  </app-list-card>`,
  styles: [],
})
export class PlanCardComponent implements OnInit {
  @Input() plan: Plan;
  @Input() selected: boolean;
  @Output() openMenu = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  public showMenu($event) {
    this.openMenu.emit($event);
  }
}
