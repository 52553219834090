import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { subscribeOn, take, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { CollectionDataEntity } from '../../collection-manager.service';
import { GridViewManager } from '../grid-view.manager';
import { DataRowComponent } from './data-row/data-row.component';

interface DataRow {
  data: CollectionDataEntity;
  rowIndex: number;
}

@Component({
  selector: 'app-data-rows',
  templateUrl: './data-rows.component.html',
  styleUrls: ['./data-rows.component.scss'],
})
export class DataRowsComponent implements AfterViewInit, OnChanges {
  @Input() allData: Array<CollectionDataEntity>;
  @Input() data: Array<CollectionDataEntity>;
  @Input() properties: Array<ViewPropertyConfiguration>;
  @Input() side: string;
  @Input() sideWidth: number;
  @Input() editorMode: string;
  @ViewChildren(DataRowComponent) rowQuery: QueryList<DataRowComponent>;
  public rows: Array<DataRowComponent> = [];
  public visibleDataRows: Array<DataRow>;
  private rowStartIndex = 0;
  constructor(
    private gridViewService: GridViewManager,
    private store: Store<RootStoreState.State>,
  ) {}
  public rowViewPortHeight$: Observable<number>;

  public ngAfterViewInit(): void {
    if (this.rowQuery.toArray().length) {
      this.rows = this.rowQuery.toArray();
      this.setRowsIntoGridService();
    }
    this.rowQuery.changes.subscribe((rowQuery: QueryList<DataRowComponent>) => {
      this.rows = rowQuery.toArray();
      this.setRowsIntoGridService();
    });

    this.store.select(CollectionManagerSelectors.scrollRowIndexStart).subscribe((index) => {
      this.rowStartIndex = index;
      this.deriveVisibleRows();
    });

    this.rowViewPortHeight$ = this.store.select(CollectionManagerSelectors.rowViewPortHeight).pipe(
      tap(() => {
        this.deriveVisibleRows();
      }),
    );
  }

  public setRowsIntoGridService() {
    this.gridViewService.setRows(this.rows);
  }

  ngOnChanges() {
    this.deriveVisibleRows();
    this.sideWidth = this.sideWidth || 100000;
  }

  /** This function determines which of the data/collection rows should be
   * visible based on the the rowStartIndex (which is subscribed to)
   */
  deriveVisibleRows() {
    const startIndex = this.rowStartIndex;
    let rowsToRender = 40; // This is the number of rows to render, which should be dependant on the screen size... zoom level.
    this.store
      .select(CollectionManagerSelectors.renderedRowCount)
      .pipe(
        take(1),
        tap((count) => {
          rowsToRender = count;
        }),
      )
      .subscribe();

    if (rowsToRender < 0) {
      return;
    }

    const endIndex = startIndex + rowsToRender;
    const visibleData = this.data.slice(startIndex, endIndex);

    let index = startIndex;
    const visibleRows = [];
    visibleData.forEach((data) => {
      visibleRows.push({
        rowIndex: index++,
        data,
        realRowNumber: this.allData.findIndex((rowData) => rowData.id === data.id),
      });
    });
    // Edit just once.
    this.visibleDataRows = [...visibleRows];

    // We just modified the source data for grid row elements.
    // The actual GridRow objects are not changing, but their source data is.
    // Because of this, we need to tell the gridService to 'reindex' the row map it
    // holds.  There is a real race condition here.. so we may want to setTimeout..
    // We need to give the GridRow objects a chance to ngChange and update their row id.
    setTimeout(() => {
      this.setRowsIntoGridService();
    }, 100);
  }
  rowTrackByFn(index, row: any) {
    return row.data.id;
  }

  showRow(rowNumber) {}
}
