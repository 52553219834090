import { UserSessionStoreState } from '../common/user-sessions/user-sessions-store';
import { AuthStoreState } from '../common/auth/auth-store';
import { CommentsStoreState } from '../common/comments/comments-store';
import { LoadingIndicatorStoreState } from '../common/loading-indicator/loading-indicator-store';
import { PlansStoreState } from '../plans/plans-store';
import { CollectionManagerStoreState } from '../collection-manager/collection-manager-store';
import { AssortmentsStoreState } from '../common/assortments/assortments-store';
import { WorkspacesStoreState } from '@common/workspaces/workspaces-store';
import { DocumentHistoryStoreState } from '@common/document-history/document-history-store';
import { TypePropertyUserListsStoreState } from '@common/user-list/user-list-store';
import { FeatureFlagsStoreState } from '@common/feature-flags';
import { AsyncErrorsStoreState } from '@common/errors/async-errors-store';
import { AppExtensionsStoreState } from '@common/app-extensions/app-extensions-store';
import { ClipboardStoreState } from '@common/clipboard/clipboard-store';

export interface State {
  userSessions: UserSessionStoreState.State;
  auth: AuthStoreState.State;
  comments: CommentsStoreState.State;
  clipboard: ClipboardStoreState.State;
  loadingIndicator: LoadingIndicatorStoreState.State;
  plans: PlansStoreState.State;
  collectionManager: CollectionManagerStoreState.State;
  assortments: AssortmentsStoreState.State;
  workspaces: WorkspacesStoreState.State;
  documentHistory: DocumentHistoryStoreState.State;
  featureFlags: FeatureFlagsStoreState.State;
  typePropertyUserLists: TypePropertyUserListsStoreState.State;
  errors: AsyncErrorsStoreState.State;
  appExtensions: AppExtensionsStoreState.State;
}
