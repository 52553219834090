import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { SelectedCellInfo } from '../context-menu.component';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { FocusedItemFamilyService } from '../../focus-item/focused-item-family.service';

export class FocusItemFamilyMenuAction extends ContextMenuAction {
  constructor(
    protected store: Store<RootStoreState.State>,
    protected focusedItemFamilyService: FocusedItemFamilyService,
  ) {
    super(store);
    this.svgActionIcon = 'focus-icon';
    this.actionLabel = 'Focus mode';
    this.testRef = 'app-menu-focus-item-family-action';
    this.shortCutKey = 'Ctrl-G';
  }

  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    this.focusedItemFamilyService.handleFocusedItemFamily(ids[0]);
  }

  canInvoke(editorMode: string, ids: Array<string>, selectedCellInfo: SelectedCellInfo = null) {
    if (editorMode !== 'EDIT') {
      return false;
    }
    if (ids?.length !== 1) {
      return false;
    }

    if (!this.focusedItemFamilyService.isFocusedItemFamilyAllowed(ids[0])) {
      return false;
    }
    return true;
  }
}
