import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ViewDefinition } from '@contrail/client-views';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CollectionManagerSelectors } from '../collection-manager-store';
import { SidePanelService } from '../side-panel/side-panel.service';
import { ProjectGoalSet, ProjectGoalsService } from '@common/projects/project-goals.service';

@Component({
  selector: 'app-pivot-view',
  templateUrl: './pivot-view.component.html',
  styleUrls: ['./pivot-view.component.scss'],
})
export class PivotViewComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  showEmptyPage = true;
  public title = 'Pivot view';
  public icon = 'pivot-view-icon';
  public footer = 'Select properties to display pivot table';
  public actionLabel = '';
  public currentViewDefinition: ViewDefinition;
  public data: any[] = [];
  public height = window.innerHeight - 200;
  public width = window.innerWidth - 50;
  public goals$: Observable<any>;

  constructor(
    private store: Store<RootStoreState.State>,
    private projectGoalsService: ProjectGoalsService,
    private sidePanelService: SidePanelService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .select(CollectionManagerSelectors.filteredData)
        .pipe(
          tap((rawData) => {
            this.data = rawData;
          }),
        )
        .subscribe(),
    );

    this.subscriptions.add(
      this.store
        .select(CollectionManagerSelectors.currentViewDefinition)
        .pipe(
          tap((currentViewDefinition) => {
            if (currentViewDefinition.properties?.length === 0) {
              const view = {
                label: 'View Options',
                slug: 'pivotViewOptions',
                icon: '',
              };
              this.sidePanelService.setCurrentView(view);
              this.showEmptyPage = true;
            } else {
              this.showEmptyPage = false;
              if (this.currentViewDefinition?.id !== currentViewDefinition.id) {
                this.sidePanelService.setCurrentView(null);
              }
            }
            this.currentViewDefinition = currentViewDefinition;
          }),
        )
        .subscribe(),
    );

    this.goals$ = this.projectGoalsService.projectGoalSets$.pipe(
      map((goalSets) => {
        console.log('goalSets: ', goalSets);
        if (goalSets?.length) {
          let goals = goalSets[0].goals;
          console.log('goals: ', goals);
          return goals; // Currently only supporting one goal set... although API support multiple
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.sidePanelService.setCurrentView(null);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.height = window.innerHeight - 200;
    this.width = window.innerWidth - 50;
  }
}
