import { createAction, props } from '@ngrx/store';
import { SortDefinition } from 'src/app/common/components/sort/sort-definition';
import { FilterDefinition } from 'src/app/common/types/filters/filter-definition';
import { SelectorCell } from '../grid-view/selectors/grid-selector.service';
import { EditorMode } from './collection-manager.state';
export enum CollectionManagerActionTypes {
  CLEAR_COLLECTION_MANAGER = '[CollectionManager] Clear',
  SET_COLLECTION_ELEMENTS_LOADED = '[CollectionManager] Set Collection Elements Loaded',

  SET_HOVERED_ENTITY_ID = '[CollectionManager] Set Hovered Entity Id',
  ADD_SELECTED_ENTITY_ID = '[CollectionManager] Add Selected Entity Id',
  SET_SELECTED_ENTITY_IDS = '[CollectionManager] Set Selected Entity Ids',
  REMOVE_SELECTED_ENTITY_ID = '[CollectionManager] Remove Selected Entity Id',
  REMOVE_SELECTED_ENTITY_IDS = '[CollectionManager] Remove Selected Entity Ids',
  CLEAR_SELETED_ENTITITY_IDS = '[CollectionManager] Clear Selected Entity Ids',

  SET_SELECTED_ENTITY_PROPERTY = '[CollectionManager] Set Selected Entity Property',
  UPDATE_REMOTE_SELECTED_ENTITY_PROPERTIES = '[CollectionManager] Update Remote Selected Entity Properties',

  SET_SELECTED_ELEMENT_LOCATION = '[Collection Manager] Set Selected Element Location',
  SET_TYPE_DEFINITIONS = '[Collection Manager] Set Type Definitions',
  LOAD_TYPE_DEFINITIONS = '[Collection Manager] Load Type Definitions',

  SET_SCROLL_HORIZONTAL_PERCENTAGE = '[Collection Manager] Set Horizontal Scroll Percentage',
  SET_SCROLL_VERTICAL_PERCENTAGE = '[Collection Manager] Set Vertical Scroll Percentage',

  SET_SORTS = '[Collection Manager] Set Sorts',
  SET_SORTS_SUCCESS = '[Collection Manager] Set Sorts Success',

  SET_FILTER_DEFINITION = '[Collection Manager] Set Filter Definition',
  SET_FILTER_DEFINITION_SUCCESS = '[Collection Manager] Set Filter Definition Success',

  SET_GRID_TOTAL_WIDTH = '[Collection Manager] Set the grid total width',
  SET_GRID_TOTAL_HEIGHT = '[Collection Manager] Set the grid total height',

  SET_HIDE_EMPTY_GROUPS = '[Collection Manager] Set Hide Empty Groups',
  SET_EDITOR_MODE = '[Collection Manager] Set Editor Mode',

  SET_SELECTOR_ACTIVE = '[Collection Manager] Set Selector Active',
  SET_SELECTOR_KEY_ACTIVE = '[Collection Manager] Set Selector Key Active',
  SET_SELECTOR_CELLS = '[Collection Manager] Set Selector Cells',

  SET_ROW_SELECTOR_ACTIVE = '[Collection Manager] Set Row Selector Active',

  SET_DRAG_ACTIVE_ROW = '[Collection Manager] Set Drag Active Row',

  SET_CUT_CELLS = '[Collection Manager] Set Cut Cells',

  SET_COPIED_ROWS = '[Collection Manager] Set Copied Rows',

  SET_ANCHOR_ROW_SELECTOR_ID = '[Collection Manager] Set Anchor Row Selector Id',

  RESET_SEARCH_REPLACE = '[Collection Manager] Reset Search Replace',

  SET_SEARCH_REPLACE_CELLS = '[Collection Manager] Set Search Replace Cells',

  SET_ACTIVE_SEARCH_REPLACE_CELL = '[Collection Manager] Set Active Search Replace Cell',

  SET_FILL_ANCHOR_CELL = '[Collection Manager] Set Fill Anchor Cell',

  SET_FILL_CELLS = '[Collection Manager] Set Fill Cells',

  SET_SIDE_PANEL_WIDTH = '[Collection Manager] Set Side Panel Width',

  SET_STATUS_MESSAGE_ELEMENT = '[Collection Manager] Set Status Message Element',

  SET_FOCUSED_ITEM_FAMILY = '[Collection Manager] Set Focused Item Family',
  SET_SPREAD_VALUE_INFO = '[Collection Manager Set Spread Value Info',

  SET_CONTEXT_MENU_ACTIVE = '[Collection Manager Set Context Menu Active]',
  SET_OVERRIDE_OPTION_CELLS = '[Collection Manager Set Override Option Cells]',
}
export const setFocusedItemFamily = createAction(
  CollectionManagerActionTypes.SET_FOCUSED_ITEM_FAMILY,
  props<{ itemFamily: any }>(),
);
export const setEditorMode = createAction(
  CollectionManagerActionTypes.SET_EDITOR_MODE,
  props<{ editorMode: EditorMode }>(),
);
export const clearCollectionManager = createAction(CollectionManagerActionTypes.CLEAR_COLLECTION_MANAGER);

export const setCollectionElementsLoaded = createAction(
  CollectionManagerActionTypes.SET_COLLECTION_ELEMENTS_LOADED,
  props<{ loaded: boolean }>(),
);
////////////////////////////////////////////////////////////////////////////////
// ROW SELECTION
////////////////////////////////////////////////////////////////////////////////
export const setHoveredEntityId = createAction(
  CollectionManagerActionTypes.SET_HOVERED_ENTITY_ID,
  props<{ id: string }>(),
);
export const addSelectedEntityId = createAction(
  CollectionManagerActionTypes.ADD_SELECTED_ENTITY_ID,
  props<{ id: string }>(),
);
export const setSelectedEntityIds = createAction(
  CollectionManagerActionTypes.SET_SELECTED_ENTITY_IDS,
  props<{ ids: Array<string> }>(),
);
export const removeSelectedEntityId = createAction(
  CollectionManagerActionTypes.REMOVE_SELECTED_ENTITY_ID,
  props<{ id: string }>(),
);
export const removeSelectedEntityIds = createAction(
  CollectionManagerActionTypes.REMOVE_SELECTED_ENTITY_IDS,
  props<{ ids: Array<string> }>(),
);
export const clearSelectedEntityIds = createAction(CollectionManagerActionTypes.CLEAR_SELETED_ENTITITY_IDS);

////////////////////////////////////////////////////////////////////////////////

export const setSelectedEntityProperty = createAction(
  CollectionManagerActionTypes.SET_SELECTED_ENTITY_PROPERTY,
  props<{ entityId: string; propertySlug: string; propertyValue: string; propertyType: string }>(),
);
export const updateRemoteSelectedEntityProperties = createAction(
  CollectionManagerActionTypes.UPDATE_REMOTE_SELECTED_ENTITY_PROPERTIES,
  props<{ clientId: string; user: any; entityId: string; propertySlug: string }>(),
);
////////////////////////////////////////////////////////////////////////////////

export const setSelectedElementLocation = createAction(
  CollectionManagerActionTypes.SET_SELECTED_ELEMENT_LOCATION,
  props<{ location: DOMRect }>(),
);

export const loadTypeDefinitions = createAction(CollectionManagerActionTypes.LOAD_TYPE_DEFINITIONS);

export const setTypeDefinitions = createAction(
  CollectionManagerActionTypes.SET_TYPE_DEFINITIONS,
  props<{ typeDefinitions: any }>(),
);

export const setScrollHorizontalPercentage = createAction(
  CollectionManagerActionTypes.SET_SCROLL_HORIZONTAL_PERCENTAGE,
  props<{ percentage: number }>(),
);

export const setScrollVerticalPercentage = createAction(
  CollectionManagerActionTypes.SET_SCROLL_VERTICAL_PERCENTAGE,
  props<{ percentage: number }>(),
);

export const setSorts = createAction(CollectionManagerActionTypes.SET_SORTS, props<{ sorts: Array<SortDefinition> }>());

export const setSortsSuccess = createAction(CollectionManagerActionTypes.SET_SORTS_SUCCESS);

export const setFilterDefinition = createAction(
  CollectionManagerActionTypes.SET_FILTER_DEFINITION,
  props<{ filterDefinition: FilterDefinition }>(),
);

export const setFilterDefinitionSuccess = createAction(CollectionManagerActionTypes.SET_FILTER_DEFINITION_SUCCESS);

export const setGridTotalWidth = createAction(
  CollectionManagerActionTypes.SET_GRID_TOTAL_WIDTH,
  props<{ gridTotalWidth: number }>(),
);

export const setGridTotalHeight = createAction(
  CollectionManagerActionTypes.SET_GRID_TOTAL_HEIGHT,
  props<{ gridTotalHeight: number }>(),
);

export const setHideEmptyGroups = createAction(
  CollectionManagerActionTypes.SET_HIDE_EMPTY_GROUPS,
  props<{ hideEmptyGroups: boolean }>(),
);

export const setSelectorActive = createAction(
  CollectionManagerActionTypes.SET_SELECTOR_ACTIVE,
  props<{ selectorActive: boolean }>(),
);

export const setSelectorKeyActive = createAction(
  CollectionManagerActionTypes.SET_SELECTOR_KEY_ACTIVE,
  props<{ selectorKeyActive: boolean }>(),
);

export const setSelectorCells = createAction(
  CollectionManagerActionTypes.SET_SELECTOR_CELLS,
  props<{ selectorCells: Array<SelectorCell> }>(),
);

export const setRowSelectorActive = createAction(
  CollectionManagerActionTypes.SET_ROW_SELECTOR_ACTIVE,
  props<{ rowSelectorActive: boolean }>(),
);

export const setRowDragActive = createAction(
  CollectionManagerActionTypes.SET_DRAG_ACTIVE_ROW,
  props<{ dragActiveRow: any }>(),
);

export const setCutCells = createAction(
  CollectionManagerActionTypes.SET_CUT_CELLS,
  props<{ cutCells: Array<SelectorCell> }>(),
);

export const setCopiedRows = createAction(
  CollectionManagerActionTypes.SET_COPIED_ROWS,
  props<{ copiedRows: Array<string> }>(),
);

export const setAnchorRowSelectorId = createAction(
  CollectionManagerActionTypes.SET_ANCHOR_ROW_SELECTOR_ID,
  props<{ anchorRowSelectorId: string }>(),
);

export const resetSearchReplace = createAction(CollectionManagerActionTypes.RESET_SEARCH_REPLACE);

export const setSearchReplaceCells = createAction(
  CollectionManagerActionTypes.SET_SEARCH_REPLACE_CELLS,
  props<{ searchReplaceCells: Array<SelectorCell> }>(),
);

export const setActiveSearchReplaceCell = createAction(
  CollectionManagerActionTypes.SET_ACTIVE_SEARCH_REPLACE_CELL,
  props<{ activeSearchReplaceCell: SelectorCell }>(),
);

export const setFillAnchorCell = createAction(
  CollectionManagerActionTypes.SET_FILL_ANCHOR_CELL,
  props<{ fillAnchorCell: SelectorCell }>(),
);

export const setFillCells = createAction(
  CollectionManagerActionTypes.SET_FILL_CELLS,
  props<{ fillCells: SelectorCell[] }>(),
);

export const setSidePanelWidth = createAction(
  CollectionManagerActionTypes.SET_SIDE_PANEL_WIDTH,
  props<{ sidePanelWidth: number }>(),
);

export const setStatusMessageElement = createAction(
  CollectionManagerActionTypes.SET_STATUS_MESSAGE_ELEMENT,
  props<{ statusMessageElement: any }>(),
);

export const setSpreadValueInfo = createAction(
  CollectionManagerActionTypes.SET_SPREAD_VALUE_INFO,
  props<{ spreadValueInfo: any }>(),
);

export const setContextMenuActive = createAction(
  CollectionManagerActionTypes.SET_CONTEXT_MENU_ACTIVE,
  props<{ contextMenuActive: boolean }>(),
);

export const setOverrideOptionCells = createAction(
  CollectionManagerActionTypes.SET_OVERRIDE_OPTION_CELLS,
  props<{ overrideOptionCells: SelectorCell[] }>(),
);
