import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { HeaderColumnResizeService } from '../header-column-resize.service';
import { HeaderColumnReorderService } from '../header-column-reorder.service';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Observable, tap } from 'rxjs';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { FilterService } from 'src/app/collection-manager/filter/filter-service';
import { Types } from '@contrail/sdk';
import { AuthService } from '@common/auth/auth.service';
import { CollectionManagerSelectors } from 'src/app/collection-manager/collection-manager-store';

@Component({
  selector: 'app-column-header',
  templateUrl: './column-header.component.html',
  styleUrls: ['./column-header.component.scss'],
})
export class ColumnHeaderComponent implements OnInit {
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  @Input() property: ViewPropertyConfiguration;
  propertyIsFiltered$: Observable<boolean>;
  isDataOverridable = false;
  isOrgAdmin = false;
  currentView$: Observable<any>;
  currentView: any;
  isCellSearchReplaceActive = false;

  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
    private headerRowService: HeaderColumnReorderService,
    private headerResizeService: HeaderColumnResizeService,
    private filterService: FilterService,
    private currentElement: ElementRef,
    private renderer: Renderer2,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isOrgAdmin = this.authService.isAdmin();
    this.propertyIsFiltered$ = this.filterService.isPropertyFiltered(this.property.slug);
    const itemType = await new Types().getType({ root: 'item', path: 'item' });
    let property = itemType.typeProperties.find((prop) => prop.slug === this.property.slug);
    if (!property) {
      const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
      property = projectItemType.typeProperties.find((prop) => prop.slug === this.property.slug);
    }
    if (property) {
      this.isDataOverridable = property.propertyLevel === 'overridable';
    }

    this.currentView$ = this.store.select(CollectionManagerSelectors.selectCurrentView);
    this.currentView$.pipe(tap((currentView) => (this.currentView = currentView))).subscribe();

    this.store.select(CollectionManagerSelectors.activeSearchReplaceCell).subscribe((activeSearchReplaceCell) => {
      if (activeSearchReplaceCell?.rowId === 'header' && activeSearchReplaceCell?.columnId === this.property.slug) {
        this.isCellSearchReplaceActive = true;
        this.addClass('active-search-replace-cell');
      } else if (this.isCellSearchReplaceActive) {
        this.removeClass('active-search-replace-cell');
      }
    });
  }

  columnHeaderDragStart(event) {
    this.headerRowService.handleColumnHeaderDragStart(event, this.property);
  }
  columnHeaderDragMove(event) {
    this.headerRowService.handleColumnHeaderDragMove(event, this.property);
  }
  columnHeaderDrop(event) {
    this.headerRowService.handleColumnHeaderDragDrop(event, this.property);
  }

  columnResizeDragStart(event) {
    this.headerResizeService.handleColumnResizeDragStart(event, this.property);
  }
  columnResizeDragMove(event) {
    this.headerResizeService.handleColumnResizeDragMove(event, this.property);
  }
  columnResizeDrop(event) {
    this.headerResizeService.handleColumnResizeDragDrop(event, this.property);
  }

  showColumnContextMenu($event) {
    if (!this.contextMenu) {
      return;
    }

    const currentHeaderElement = document.getElementById('column_header_' + this.property.slug);
    const box = currentHeaderElement.getBoundingClientRect();
    this.contextMenuPosition.x = box.x + 'px';
    this.contextMenuPosition.y = box.y + box.height + 5 + 'px';
    this.contextMenu.openMenu();
    $event.stopPropagation();
    $event.preventDefault();
  }

  hideColumn() {
    this.headerRowService.hideColumn(this.property.slug);
  }

  removeFilter(event) {
    event.stopPropagation();
    this.filterService.removeFilter(this.property.slug);
  }

  addClass(className: string) {
    this.renderer.addClass(this.currentElement.nativeElement, className);
  }

  removeClass(className: string) {
    this.renderer.removeClass(this.currentElement.nativeElement, className);
  }

  public get isFilterAllowed(): boolean {
    return this.isOrgAdmin || !this.currentView?.admin;
  }
}
