import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { ViewDefinition, ViewPropertyConfiguration } from '@contrail/client-views';
import { PivotGridService } from '../pivot-grid-service';

@Component({
  selector: 'app-pivot-header-row',
  templateUrl: './pivot-header-row.component.html',
  styleUrls: ['./pivot-header-row.component.scss'],
})
export class PivotHeaderRowComponent implements OnDestroy, OnChanges {
  @Input() viewDefinition: ViewDefinition;
  @Input() properties: Array<ViewPropertyConfiguration>;
  @Input() side: string;
  @Input() showGoals: boolean = false;
  @Output() resizeColumn: EventEmitter<any> = new EventEmitter();
  @Output() toggleRows: EventEmitter<any> = new EventEmitter();

  public showGoals$ = this.pivotGridService.showGoals$;
  totalWidth = 0;
  groupingHeader = '';
  groupingProperty: ViewPropertyConfiguration;

  constructor(private pivotGridService: PivotGridService) {}

  ngOnDestroy(): void {}

  ngOnChanges(): void {
    if (this.properties?.length > 0) {
      if (this.side === 'left') {
        this.groupingProperty = this.properties[0]; // use the first property for width
        this.totalWidth = this.properties[0].width || this.properties.length * 100;
        this.groupingHeader = '';
        this.properties.forEach((prop) => {
          this.groupingHeader += (this.groupingHeader !== '' ? ' / ' : '') + prop.propertyDefinition.label;
        });
      }

      console.log('header: properties: ', this.properties);
    }
  }

  performResizeColumn(columnChanges: any) {
    this.resizeColumn.emit(columnChanges);
  }

  performToggleRows(columnChanges: any) {
    this.toggleRows.emit(columnChanges);
  }
}
