import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { EntityHistoryEvent } from '@common/entity-details/entity-history/entity-history-event';
import { TypePropertyFormConfiguration } from '@common/types/forms/type-property-form/type-property-form.component';
import { SearchBarComponent } from '@components/search-bar/search-bar.component';
import { Entities } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  startWith,
  Subject,
} from 'rxjs';
import { escapeRegExp } from 'lodash';
import { EntityHistoryService } from '@common/entity-details/entity-history/entity-history.service';
import { FilterDefinition, FilterObjects, FilterPropertyCriteria } from '@contrail/filters';
import { TypeManagedEntity } from '@common/entities/entities.interfaces';

@Component({
  selector: 'app-item-history',
  templateUrl: './item-history.component.html',
  styleUrls: ['./item-history.component.scss'],
})
export class ItemHistoryComponent implements AfterViewInit {
  @Input() item: any;
  public historyEvents: Array<EntityHistoryEvent>;
  public historyEvents$: Subject<Array<EntityHistoryEvent>> = new Subject();
  public filteredHistoryEvents$: Observable<Array<EntityHistoryEvent>>;
  public isLoading = false;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  private projectFilterSubject: Subject<string[]> = new BehaviorSubject(null);
  private projectFilter$: Observable<string[]> = this.projectFilterSubject.asObservable();

  private propertyFilterSubject: Subject<string[]> = new BehaviorSubject(null);
  private propertyFilter$: Observable<string[]> = this.propertyFilterSubject.asObservable();

  private usersFilterSubject: Subject<string[]> = new BehaviorSubject(null);
  private usersFilter$: Observable<string[]> = this.usersFilterSubject.asObservable();

  private dateFilterSubject: Subject<FilterPropertyCriteria[]> = new BehaviorSubject(null);
  private dateFilter$: Observable<FilterPropertyCriteria[]> = this.dateFilterSubject.asObservable();

  public projectFilterDefinition: TypePropertyFormConfiguration = {
    typeProperty: {
      slug: 'project',
      label: 'Project',
      options: [],
    },
    isFilter: true,
  };

  public propertyFilterDefinition: TypePropertyFormConfiguration = {
    typeProperty: {
      slug: 'property',
      label: 'Property',
      options: [],
    },
    isFilter: true,
  };

  public userFilterDefinition: TypePropertyFormConfiguration = {
    typeProperty: {
      slug: 'user',
      label: 'User',
      options: [],
    },
    isFilter: true,
  };

  public dateFilterProperty = {
    label: 'Date Modified',
    attribute: 'eventDate',
  };

  constructor(private entityHistoryService: EntityHistoryService) {}

  ngAfterViewInit() {
    this.filteredHistoryEvents$ = combineLatest([
      this.historyEvents$,
      this.searchBar?.valueChange.pipe(startWith(''), debounceTime(200), distinctUntilChanged()),
      this.projectFilter$,
      this.propertyFilter$,
      this.usersFilter$,
      this.dateFilter$,
    ]).pipe(
      map(([historyEvents, searchTerm, selectedProjectIds, selectedProperties, selectedUsers, dateFilterCriteria]) => {
        const filteredResults = this.filterHistoryEvents(historyEvents, {
          searchTerm,
          selectedProjectIds,
          selectedProperties,
          selectedUsers,
          dateFilterCriteria,
        });

        return filteredResults;
      }),
    );
  }

  private filterHistoryEvents(
    historyEvents: EntityHistoryEvent[],
    filterOptions: {
      searchTerm?: string;
      selectedProjectIds?: string[];
      selectedProperties?: string[];
      selectedUsers?: string[];
      dateFilterCriteria: FilterPropertyCriteria[];
    },
  ) {
    const { searchTerm, selectedProjectIds, selectedProperties, selectedUsers, dateFilterCriteria } = filterOptions;

    return historyEvents
      .map((historyEvent: EntityHistoryEvent) => {
        if (searchTerm?.length) {
          const searchString = this.buildSearchString(historyEvent);
          const matchesSearchTerm = new RegExp(escapeRegExp(searchTerm), 'gi').test(searchString);
          if (!matchesSearchTerm) return;
        }

        const isProjectExcluded = selectedProjectIds && !selectedProjectIds.includes(historyEvent.projectId);
        if (isProjectExcluded) {
          return;
        }

        const isUserExcluded = selectedUsers && !selectedUsers.includes(historyEvent.user?.email);
        if (isUserExcluded) {
          return;
        }

        if (dateFilterCriteria?.length) {
          const isDateIncluded = FilterObjects.testAndCriteria(historyEvent, { propertyCriteria: dateFilterCriteria });
          if (!isDateIncluded) {
            return;
          }
        }

        const filteredPropertyEvents = !selectedProperties?.length
          ? historyEvent.propertyChanges
          : historyEvent.propertyChanges.filter((propertyChange) => {
              return selectedProperties.includes(propertyChange.propertyLabel);
            });

        return {
          ...historyEvent,
          propertyChanges: filteredPropertyEvents,
        };
      })
      .filter((historyEvent) => historyEvent && !!historyEvent.propertyChanges?.length);
  }

  private buildSearchString(historyEvent): string {
    const { projectName, user, propertyChanges } = historyEvent;
    const userInfo = [user?.email, user?.firstName, user?.lastName].filter(Boolean).join(' ');
    const propertyLabels = propertyChanges.map((prop) => prop.propertyLabel).join(' ');

    return [projectName, userInfo, propertyLabels].filter(Boolean).join(' ');
  }

  ngOnChanges() {
    if (this.item) {
      this.loadHistory();
    }
  }

  async getItemHistoryEvents() {
    const historyEntities: TypeManagedEntity[] = [this.item];
    const projectItems = await new Entities().get({
      entityName: 'project-item',
      criteria: { itemId: this.item.id },
      relations: ['project'],
    });

    for (const projectItem of projectItems) {
      historyEntities.push(projectItem);
    }

    const historyEvents = await this.entityHistoryService.getHistoryEventsForEntities(historyEntities);
    const historyEventReferences = new Set(historyEvents.map((event) => event.entityReference));
    const projectItemsWithHistoryEvents = projectItems.filter((projectItem) =>
      historyEventReferences.has(`${projectItem.entityType}:${projectItem.id}`),
    );

    const projectFilterOptions = projectItemsWithHistoryEvents.map((projectItem: any) => ({
      display: projectItem.project.name,
      value: projectItem.project.id,
      additionalValues: {},
    }));

    this.projectFilterDefinition = {
      ...this.projectFilterDefinition,
      typeProperty: {
        ...this.projectFilterDefinition.typeProperty,
        options: projectFilterOptions,
      },
    };

    return historyEvents;
  }

  async loadHistory() {
    this.isLoading = true;
    const historyEvents = await this.getItemHistoryEvents();
    const orderedEvents = historyEvents.sort((o1, o2) => {
      return o1.eventDate < o2.eventDate ? 1 : -1;
    });

    this.setUpPropertyFilter(orderedEvents);
    this.setUpUsersFilter(orderedEvents);
    this.isLoading = false;
    this.historyEvents$.next(orderedEvents);
  }

  /** Populates the property filter with the unique property options from the collection of events */
  private setUpPropertyFilter(historyEvents: Array<EntityHistoryEvent>) {
    const propertySet = new Set();
    historyEvents.map((event: EntityHistoryEvent) => {
      if (!event.propertyChanges) {
        return;
      }

      for (const propertyChange of event.propertyChanges) {
        const oldValue = propertyChange.oldValue;
        const newValue = propertyChange.newValue;

        const isPropertyUnchanged = Boolean((!oldValue && !newValue) || oldValue === newValue);
        const isChangeToHiddenProperty = ['createdOn', 'updatedOn'].includes(propertyChange.propertyKey);
        if (isPropertyUnchanged || isChangeToHiddenProperty) {
          continue;
        }

        propertySet.add(propertyChange.propertyLabel);
      }
    });

    this.propertyFilterDefinition.typeProperty.options = [...propertySet.values()].map((val: any) => ({
      display: val,
      value: val,
      additionalValues: {},
    }));
    this.propertyFilterDefinition.typeProperty.options.sort((o1, o2) => (o1.display > o2.display ? 1 : -1));
    this.propertyFilterDefinition = ObjectUtil.cloneDeep(this.propertyFilterDefinition);
  }

  private setUpUsersFilter(historyEvents: Array<EntityHistoryEvent>) {
    const usersSet = new Set();
    historyEvents.forEach((ev) => {
      usersSet.add(ev.user?.email);
    });

    this.userFilterDefinition.typeProperty.options = [...usersSet.values()].map((val: any) => ({
      display: val,
      value: val,
      additionalValues: {},
    }));

    this.userFilterDefinition.typeProperty.options.sort((o1, o2) => (o1.display > o2.display ? 1 : -1));
    this.userFilterDefinition = ObjectUtil.cloneDeep(this.userFilterDefinition);
  }

  handleProjectFilterChange(filterChange: { value: string[] }) {
    const projectNames = filterChange.value;
    if (!projectNames?.length) {
      this.projectFilterSubject.next(null);
    } else {
      this.projectFilterSubject.next(projectNames);
    }
  }

  handlePropertyFilterChange(filterChange: { value: string[] }) {
    const propertySlugs = filterChange.value;
    if (!propertySlugs?.length) {
      this.propertyFilterSubject.next(null);
    } else {
      this.propertyFilterSubject.next(propertySlugs);
    }
  }

  handleUserFilterChange(filterChange: { value: string[] }) {
    const userEmails = filterChange.value;
    if (!userEmails?.length) {
      this.usersFilterSubject.next(null);
    } else {
      this.usersFilterSubject.next(userEmails);
    }
  }

  handleDateFilterChange(filterChange: FilterDefinition) {
    const dateFilterCriteria = filterChange?.filterCriteria?.propertyCriteria || [];
    if (!dateFilterCriteria?.length) {
      this.dateFilterSubject.next(null);
    } else {
      this.dateFilterSubject.next(dateFilterCriteria);
    }
  }
}
