import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface Workspace {
  id?: string;
  orgId?: string;
  createdOn?: Date;
  updatedOn?: Date;
  name?: string;
  parentId?: string;
  workspaceType?: string;
  rootWorkspaceId?: string;
  projectId?: string;
  parent?: Workspace;
  workspacePrincipals?: any;
}

export interface WorkspaceEntity {
  workspaceId?: string;
  entityReference?: string;
  entity?: any;
  createdBy?: any;
  updatedBy?: any;
  createdOn?: Date;
  updatedOn?: Date;
  name?: string;
  thumbnailUrl?: string;
  workspace?: any;
  id?: string;
}

export interface WorkspaceFolder {
  name: string;
  children?: Array<WorkspaceFolder>;
  workspace: Workspace;
  expanded?: boolean;
}

interface WorkspacesState extends EntityState<Workspace> {}

export interface State {
  workspaces: WorkspacesState;
  currentWorkspace: Workspace;
  currentWorkspaceFolder: Workspace;
  workspaceFolderEntities: Array<WorkspaceEntity>;
  workspacesLoaded: boolean;
  currentWorkspaceFolderLoading: boolean;
  workspaceFolderStructure: Array<WorkspaceFolder>;
}
export const workspacesEntityAdapter: EntityAdapter<Workspace> = createEntityAdapter<Workspace>({});

export const workspacesInitialState = {
  workspaces: workspacesEntityAdapter.getInitialState({}),
  currentWorkspace: null,
  currentWorkspaceFolder: null,
  workspaceFolderEntities: [],
  workspacesLoaded: false,
  currentWorkspaceFolderLoading: true,
};
