import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  CollectionStatusMessage,
  CollectionStatusMessageTypes,
} from '@common/collection-status-message/collection-status-message';
import { Type, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';
import { AuthSelectors } from 'src/app/common/auth/auth-store';
import { ItemData } from 'src/app/common/item-data/item-data';
import { AuthService } from '@common/auth/auth.service';
import { Plan } from 'src/app/plans/plans-store/plans.state';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { CollectionElementsActionTypes } from '../../collection-manager-store/collection-elements/collection-elements.actions';
import { CollectionDataEntity } from '../../collection-manager.service';
import { CollectionStatusHelper } from './collection-status-helper';
import { EntityValidationMessages, ValidationWorkerData } from './collection-status.interfaces';
import { AssortmentsActionTypes } from '@common/assortments/assortments-store/assortments.actions';

const MISSING_IN_SOURCE = '_missing_in_source';
const DUPLICATE_OPTION = '_duplicated_option';
const VALIDATION_ERROR = '_validation_error';

interface ValidationErrorCheck {
  hasErrors: boolean;
  errorMessages?: any[];
}

@Injectable({
  providedIn: 'root',
})
export class CollectionStatusMessageService {
  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
    private actions$: Actions,
    private snackBar: MatSnackBar,
  ) {
    this.initState();
  }
  private typeMap: any = {};
  private sourceItemIdSet: Set<string>;
  private sourceAssortmentWarningMessages: CollectionStatusMessage[];
  private propertyValueWarningMessages: CollectionStatusMessage[];
  private validateValueProperties: TypeProperty[];
  private context: any;
  private currentCollectionEntities: CollectionDataEntity[];
  private currentPlan: Plan;
  messages$: Observable<Array<CollectionStatusMessage>>;
  isLoadingMessages$ = new BehaviorSubject(false);
  hasFailedToLoadMessages$ = new BehaviorSubject(false);
  hasLoadedDataValidationMessages$ = new BehaviorSubject(false);
  hasLoadedSourceAssortmentValidationMessages$ = new BehaviorSubject(false);

  private initState() {
    combineLatest([
      this.store.select(AssortmentsSelectors.sourceAssortmentItemData),
      this.store.select(CollectionManagerSelectors.selectCollectionData).pipe(
        filter((data) => !!data?.length),
        //        take(1), // IF WE DON'T TAKE ONE HERE, IT RECOMPUTES ON EVERY DATA EDIT...
      ),
      this.store.select(PlansSelectors.currentPlan),
      this.store.select(AuthSelectors.selectAuthContext),
    ])
      .pipe(
        debounceTime(1000),
        tap(
          async ([sourceItemData, currentCollectionEntities, currentPlan, auth]: [
            Array<ItemData>,
            Array<CollectionDataEntity>,
            any,
            any,
          ]) => {
            if (!sourceItemData) {
              return;
            }

            this.mapSourceAssortment(sourceItemData);
            const context = { assortment: currentPlan?.targetAssortment };
            this.context = context;
            await this.validateVsSourceAssortment(currentCollectionEntities, sourceItemData, auth?.currentOrg?.orgSlug);

            this.hasLoadedSourceAssortmentValidationMessages$.next(true);
          },
        ),
      )
      .subscribe();

    combineLatest([this.hasLoadedDataValidationMessages$, this.hasLoadedSourceAssortmentValidationMessages$])
      .pipe(
        map(
          ([hasLoadedDataValidationMessages, hasLoadedSourceAssortmentValidationMessages]) =>
            hasLoadedDataValidationMessages && hasLoadedSourceAssortmentValidationMessages,
        ),
      )
      .subscribe((hasFinishedLoadingAlerts) => {
        if (hasFinishedLoadingAlerts) {
          this.isLoadingMessages$.next(false);
        }
      });

    this.store
      .select(CollectionManagerSelectors.collectionStatusMessages)
      .subscribe((collectionStatusMessages: CollectionStatusMessage[]) => {
        this.sourceAssortmentWarningMessages = collectionStatusMessages.filter(
          (message) =>
            message['id'].includes(MISSING_IN_SOURCE) ||
            message['id'].includes(DUPLICATE_OPTION) ||
            message['id'].includes(VALIDATION_ERROR),
        );

        this.propertyValueWarningMessages = collectionStatusMessages.filter(
          (message) => !message['id'].includes(MISSING_IN_SOURCE) && !message['id'].includes(DUPLICATE_OPTION),
        );
      });

    this.messages$ = this.store.select(CollectionManagerSelectors.collectionStatusMessages) as Observable<
      Array<CollectionStatusMessage>
    >;

    // only do this one time
    combineLatest([
      this.store.select(CollectionManagerSelectors.selectCollectionData),
      this.store.select(PlansSelectors.currentPlan),
    ])
      .pipe(
        tap(([currentCollectionEntities, currentPlan]: [Array<CollectionDataEntity>, any]) => {
          this.currentCollectionEntities = currentCollectionEntities;
          if (this.currentPlan?.id !== currentPlan?.id && this.currentCollectionEntities?.length > 0) {
            const context = { assortment: currentPlan?.targetAssortment };
            if (!this.context) {
              this.context = context;
            }
            this.currentPlan = currentPlan;
          }

          const isCurrentPlanWithoutSourceAssortment =
            this.currentPlan && !this.currentPlan.targetAssortment?.sourceAssortments?.length;

          if (isCurrentPlanWithoutSourceAssortment) {
            this.hasLoadedSourceAssortmentValidationMessages$.next(true);
          }
        }),
      )
      .subscribe();

    // subscribes to collection entity addition only
    this.actions$
      .pipe(
        ofType(CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITIES_SUCCESS),
        tap((action: any) => {
          this.validatePropertyValuesAndSetAlerts(action.entities);
        }),
      )
      .subscribe();

    // subscribes to collection entity deletion only
    this.actions$
      .pipe(
        ofType(CollectionElementsActionTypes.DELETE_COLLECTION_DATA_ENTITIES_SUCCESS),
        tap((action: any) => {
          const removedMessages = this.propertyValueWarningMessages
            .filter((message) => action.ids.includes(message.collectionElementId))
            .map((message) => message.id);
          this.store.dispatch(CollectionManagerActions.removeStatusMessages({ ids: removedMessages }));
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AssortmentsActionTypes.LOAD_ASSORTMENTS_FAILURE),
        tap((_action: any) => {
          this.snackBar.open('Error: Alerts failed to load.', '', { duration: 4000 });
          this.hasFailedToLoadMessages$.next(true);
          this.isLoadingMessages$.next(false);
        }),
      )
      .subscribe();
  }

  public async validateChangesAndSetAlerts(action: any): Promise<ValidationErrorCheck> {
    if (Array.isArray(action.changes)) {
      const entities = action.changes.map((change) =>
        ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(change.changes), { id: change.id }),
      );
      return this.validatePropertyValuesAndSetAlerts(entities);
    } else {
      const entity = { ...ObjectUtil.cloneDeep(action.changes), id: action.id };
      return this.validatePropertyValuesAndSetAlerts([entity]);
    }
  }

  private mapSourceAssortment(sourceItemData) {
    if (!sourceItemData) {
      return;
    }
    this.sourceItemIdSet = new Set();
    sourceItemData.forEach((itemData) => {
      this.sourceItemIdSet.add(itemData.id);
      this.sourceItemIdSet.add(itemData.item?.itemFamilyId);
    });
  }
  public containsItem(itemId, orgSlug) {
    const found = this.sourceItemIdSet.has(itemId);
    // if (FilterUtil.isDropped(found, orgSlug)) {
    //   return false;
    // }
    const returnVal = found;
    return returnVal;
  }

  public async validateVsSourceAssortment(
    collection: Array<CollectionDataEntity>,
    sourceItemData: Array<ItemData>,
    orgSlug = '',
  ) {
    // Valiate each planplaceholder in the plan.
    const messages: CollectionStatusMessage[] = [];
    for (const entity of collection) {
      // Source Assortment validation. Check if the item is in the source assortment.
      if (!sourceItemData) {
        return;
      }
      if (entity.isDropped === true) {
        continue;
      }

      // VALIDATE FAMILY
      if (!entity.itemOptionId) {
        const containsFamily = this.containsItem(entity.itemFamilyId, orgSlug);
        if (!containsFamily && entity.itemFamily) {
          messages.push({
            id: entity.id + MISSING_IN_SOURCE,
            type: CollectionStatusMessageTypes.NOT_IN_SOURCE_ASSORTMENT,
            message: `Item Family is missing from this document\'s source assortment.`,
            collectionElementId: entity.id,
            entityName: entity.itemFamily.name,
          });
        }
      }

      // VALIDATE OPTION
      if (entity.itemOptionId && !this.containsItem(entity.itemOptionId, orgSlug)) {
        messages.push({
          id: entity.id + MISSING_IN_SOURCE,
          type: CollectionStatusMessageTypes.NOT_IN_SOURCE_ASSORTMENT,
          message: `Item is missing from this document\'s source assortment.`,
          collectionElementId: entity.id,
          entityName: `${entity.itemOption.name} / ${entity.itemOption.optionName}`,
        });
      }
    }
    if (ObjectUtil.compareDeep(messages, this.sourceAssortmentWarningMessages, '').length > 0) {
      this.sourceAssortmentWarningMessages = messages;
      const allMessages = this.sourceAssortmentWarningMessages.concat(this.propertyValueWarningMessages);
      this.store.dispatch(CollectionManagerActions.setStatusMessages({ messages: allMessages }));
      this.store.dispatch(CollectionManagerActions.setFilterDefinitionSuccess());
    }
  }

  setStatusMessageElement(statusMessageElement) {
    this.store.dispatch(CollectionManagerActions.setStatusMessageElement(statusMessageElement));
  }

  public async processAlertsOnAllEntities(
    plan: Plan,
    collectionEntities: CollectionDataEntity[],
    typeDefinitions: { [key: string]: Type },
  ): Promise<void> {
    this.setupValidationProperties(plan, typeDefinitions);
    this.store.dispatch(CollectionManagerActions.setStatusMessages({ messages: [] }));
    this.validatePropertyValuesAndSetAlerts(collectionEntities, true);
  }

  public async launchWorkerToProcessAlertsOnAllEntities(
    plan: Plan,
    collectionEntities: CollectionDataEntity[],
    typeDefinitions: { [key: string]: Type },
  ) {
    if (collectionEntities?.length) {
      this.isLoadingMessages$.next(true);
    }

    this.setupValidationProperties(plan, typeDefinitions);
    const worker = new Worker(new URL('./collection-status-validation.worker', import.meta.url));
    worker.onmessage = ({ data }: { data: EntityValidationMessages }) => {
      console.log('Validation Worker: END: Completed processing all validation alerts.');
      this.updateValidationMessages({
        newMessages: data.newMessages,
        updatedMessages: [],
        removedMessageIds: [],
      });
      this.store.dispatch(CollectionManagerActions.setFilterDefinitionSuccess());
      this.hasLoadedDataValidationMessages$.next(true);
    };

    const apiUserToken = await this.authService.getToken();
    const orgSlug = this.authService.getCurrentOrg().orgSlug;
    const apiGateway = environment.thumbnailBaseUrl || 'https://api.vibeiq.com/prod/api';
    const validationData: ValidationWorkerData = {
      plan,
      collectionEntities,
      typeDefinitions,
      authConfig: {
        apiUserToken,
        orgSlug,
        apiGateway,
      },
    };

    worker.postMessage(validationData);
  }

  public setupValidationProperties(plan: Plan, typeDefinitions: { [key: string]: Type }): void {
    const context = { assortment: plan?.targetAssortment };
    this.context = context;

    if (typeDefinitions) {
      for (const typeName in typeDefinitions) {
        this.typeMap[typeDefinitions[typeName].id] = ObjectUtil.cloneDeep(typeDefinitions[typeName]);
      }
      this.typeMap['plan-placeholder'] = ObjectUtil.cloneDeep(typeDefinitions['plan-placeholder']);
    }

    this.validateValueProperties = CollectionStatusHelper.getPropertiesToValidate(this.typeMap);
  }

  /** Assesses a set of collection elements and updates the state of the
   * status messagess based on any validation errors they may have.
   * Can be called to create messages, or update / remove messages for these
   * entities.
   */
  private async validatePropertyValuesAndSetAlerts(
    collectionSetEntities: CollectionDataEntity[],
    shouldResetMessages: boolean = false,
  ): Promise<ValidationErrorCheck> {
    const entitiesToValidate = shouldResetMessages
      ? collectionSetEntities
      : this.mergeEntitiesWithCurrentValues(collectionSetEntities);
    const messages = await CollectionStatusHelper.validateEntitiesAndGetValidationMessages({
      entities: entitiesToValidate,
      propertiesToValidate: this.validateValueProperties,
      planContext: this.context,
      typeMap: this.typeMap,
      existingMessages: this.propertyValueWarningMessages,
    });

    if (messages.errorMessages?.length > 0) {
      return { hasErrors: true, errorMessages: messages.errorMessages };
    }

    if (shouldResetMessages) {
      this.clearAndSetNewValidationMessages(messages);
    } else {
      this.updateValidationMessages(messages);
    }

    return { hasErrors: false };
  }

  public clearAndSetNewValidationMessages(messages: EntityValidationMessages): void {
    this.store.dispatch(CollectionManagerActions.setStatusMessages({ messages: messages.newMessages }));
  }

  public updateValidationMessages(messages: EntityValidationMessages): void {
    if (messages.updatedMessages.length > 0) {
      this.store.dispatch(CollectionManagerActions.updateStatusMessages({ changes: messages.updatedMessages }));
    }
    if (messages.newMessages.length > 0) {
      this.store.dispatch(CollectionManagerActions.addStatusMessages({ messages: messages.newMessages }));
    }
    if (messages.removedMessageIds.length > 0) {
      this.store.dispatch(CollectionManagerActions.removeStatusMessages({ ids: messages.removedMessageIds }));
    }
  }

  private mergeEntitiesWithCurrentValues(entities: CollectionDataEntity[]) {
    const mergedEntities = entities.map((entity) => {
      const currentEntity = this.currentCollectionEntities?.find((row) => row.id === entity.id);
      return currentEntity ? { ...currentEntity, ...entity } : entity;
    });

    return mergedEntities;
  }
}
