import { ElementRef, Injectable } from '@angular/core';
import { ViewDefinition, ViewPropertyConfiguration } from '@contrail/client-views';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { COLUMN_PADDING, DEFAULT_COLUMN_WIDTH, FIRST_COLUMN_LOCATION } from '../grid-view.manager';

@Injectable({
  providedIn: 'root',
})
export class HeaderColumnResizeService {
  private headerYCoord;
  private draggedDistance = 0;
  private currentHeaderProperty: ViewPropertyConfiguration;
  private currentHeaderElement: HTMLElement;
  private dragStartEventLocation: any;
  private currentView: ViewDefinition;
  private horizontalScrollOffset: number;

  constructor(private store: Store<RootStoreState.State>) {
    this.store.select(CollectionManagerSelectors.selectCurrentView).subscribe((view) => {
      this.currentView = ObjectUtil.cloneDeep(view);
    });
    this.store.select(CollectionManagerSelectors.scrollColumnMarginOffset).subscribe((offSet) => {
      this.horizontalScrollOffset = offSet;
    });
  }

  // RESIZE COLUMN LOGIC
  handleColumnResizeDragStart(event, property: ViewPropertyConfiguration) {
    this.currentHeaderProperty = property;
    this.currentHeaderElement = document.getElementById('column_header_' + this.currentHeaderProperty.slug);
    this.dragStartEventLocation = null;
  }
  handleColumnResizeDragMove(event, property: ViewPropertyConfiguration) {
    if (!this.headerYCoord) {
      const gridBodyBox = document.getElementById('grid-body').getBoundingClientRect();
      this.headerYCoord = gridBodyBox.y;
    }
    // HANDLE START OF DRAG
    if (!this.dragStartEventLocation) {
      this.dragStartEventLocation = event.pointerPosition;
    }

    const dragPreview = document.getElementById('column_resize_' + this.currentHeaderProperty.slug);
    const xPos = event.pointerPosition.x;
    this.draggedDistance = xPos - this.dragStartEventLocation.x;

    // HANDLE START OF DRAG
    if (!this.dragStartEventLocation) {
      this.dragStartEventLocation = event.pointerPosition;
    }

    dragPreview.style.transform = `translate3d(${xPos}px, ${this.headerYCoord}px, 0)`;
  }
  handleColumnResizeDragDrop(event, property: ViewPropertyConfiguration) {
    console.log('HeaderColumnResizeService: drop: ', this.draggedDistance);
    const column = this.currentView.properties.find((prop) => prop.slug === this.currentHeaderProperty.slug);
    if (!column) {
      return;
    }
    column.width = column.width || DEFAULT_COLUMN_WIDTH;
    column.width += this.draggedDistance;
    if (column.width < 45) {
      column.width = 45;
    }

    this.store.dispatch(
      CollectionManagerActions.updateViewDefinition({
        id: this.currentView.id,
        changes: { properties: this.currentView.properties },
      }),
    );
  }
}
