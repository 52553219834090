import { Component, Input, OnInit } from '@angular/core';
import { PropertyLevel, PropertyType, PropertyValueFormatter } from '@contrail/types';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { CollectionManagerSelectors } from 'src/app/collection-manager/collection-manager-store';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { RootStoreState } from 'src/app/root-store';
import { map } from 'rxjs/operators';
import { FilterObjects } from '@contrail/filters';

const formatter = new PropertyValueFormatter();
@Component({
  selector: 'app-column-aggregate',
  templateUrl: './column-aggregate.component.html',
  styleUrls: ['./column-aggregate.component.scss'],
})
export class ColumnAggregateComponent implements OnInit {
  constructor(private store: Store<RootStoreState.State>) {}

  @Input() property: ViewPropertyConfiguration & { propertyLevel: string };
  public data: Array<any>;
  public value: number;
  public display: string;
  public align = 'left';

  private dataSub: Subscription;
  ngOnInit() {
    this.subscribe();
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {
    this.dataSub = combineLatest([
      this.store.select(CollectionManagerSelectors.displayedData),
      this.store.select(CollectionManagerSelectors.filterDefinition),
    ])
      .pipe(
        map(([data, filterDef]) => {
          if (filterDef?.filterCriteria?.propertyCriteria.length > 0) {
            return FilterObjects.filter(data, filterDef.filterCriteria);
          }
          return data;
        }),
      )
      .subscribe((data) => {
        this.data = data;
        this.computeValue();
        this.align = this.getAlign();
      });
  }
  computeValue() {
    if (!this.data || !this.property) {
      return;
    }
    if (
      ![PropertyType.Currency, PropertyType.Number, PropertyType.Formula].includes(
        this.property.propertyDefinition?.propertyType,
      )
    ) {
      return;
    }

    const propertyLevel = this.property.propertyLevel;
    const countedItemFamilyIds = new Set<string>();

    this.value = this.data.reduce((sum, placeholderRow) => {
      const hasFamilyLevelPropertyData = Boolean(
        (propertyLevel === PropertyLevel.FAMILY && placeholderRow.itemFamilyId) ||
          (propertyLevel === PropertyLevel.OVERRIDABLE &&
            placeholderRow.itemFamilyId &&
            !placeholderRow.itemOptionId) ||
          (propertyLevel === PropertyLevel.ALL && placeholderRow.itemFamilyId && !placeholderRow.itemOptionId),
      );

      if (hasFamilyLevelPropertyData) {
        if (countedItemFamilyIds.has(placeholderRow.itemFamilyId)) {
          return sum;
        } else {
          countedItemFamilyIds.add(placeholderRow.itemFamilyId);
        }
      }

      if (placeholderRow[this.property.slug]) {
        return sum + parseFloat(placeholderRow[this.property.slug]);
      }
      return sum;
    }, 0);

    this.display = formatter.formatValueForProperty(this.value?.toString(), this.property.propertyDefinition);
  }

  getAlign() {
    if (!this.property) {
      return;
    }
    return [PropertyType.Currency, PropertyType.Number, PropertyType.Formula].includes(
      this.property.propertyDefinition?.propertyType,
    )
      ? 'right'
      : 'left';
  }
  unsubscribe() {
    this.dataSub.unsubscribe();
  }
}
