<div
  *ngIf="name || icon"
  [matTooltip]="hideTooltip ? null : toolTip || name"
  [matTooltipDisabled]="tooltipDisabled"
  class="menu-button select-none"
  [ngClass]="{ highlight: highlight, disabled: disabled }"
  (click)="togglePanel()"
>
  <mat-icon *ngIf="icon && !isSVGIcon">{{ icon }}</mat-icon>
  <mat-icon svgIcon="{{ icon }}" *ngIf="icon && isSVGIcon"></mat-icon>
  <span *ngIf="name" style="margin-left: 0.5em">{{ name }}</span>
</div>
<div *ngIf="!name && !icon" class="menu-button select-none" (click)="togglePanel()">
  <ng-content select="[name]"></ng-content>
</div>

<div
  [id]="id"
  [ngClass]="{ visible: panelVisible, left: position === 'left', right: position === 'right' }"
  #filterPanel
  class="panel"
>
  <ng-content></ng-content>
</div>
