import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, take, tap } from 'rxjs';
import { AppExtension, AppExtensionType } from '@contrail/entity-types';
import { AppExtensionService } from '@common/app-extensions/app-extension.service';
import { PlansSelectors, RootStoreState } from '@rootstore';
import { PlanWebSDKMessageHandler } from '../plan-web-sdk-message-handler';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { ObjectUtil } from '@contrail/util';
import { PlanContext, VibeIQAppType } from '@contrail/extensions-sdk';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';

@Component({
  selector: 'app-plan-app-extensions',
  templateUrl: './plan-app-extensions.component.html',
  styleUrls: ['./plan-app-extensions.component.scss'],
})
export class PlanAppExtensionsComponent implements OnInit, OnDestroy {
  @Input() tooltipPosition = 'right';
  @Input() planAppExtensions: Array<AppExtension> = [];

  public isEditor = false;
  private subscriptions: Array<Subscription> = [];
  private selectedPlaceholderIds: Array<string> = [];

  constructor(
    private store: Store<RootStoreState.State>,
    private planWebSDKMessageHandler: PlanWebSDKMessageHandler,
    private appExtensionService: AppExtensionService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .select(CollectionManagerSelectors.selectedEntityIds)
        .subscribe((selectedEntityIds) => (this.selectedPlaceholderIds = selectedEntityIds)),
    );

    this.store.select(PlansSelectors.editorMode).subscribe((mode) => {
      this.isEditor = mode === EditorMode.EDIT;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  launchAppExtension(appExtension: AppExtension) {
    const isContextualAction = appExtension.extensionType === AppExtensionType.CONTEXTUAL_ACTION;
    const selectedElements = isContextualAction ? this.getSelectedPlaceholders() : undefined;
    const currentPlan = this.getCurrentPlan();
    const { itemIds, planPlaceholders } = this.getItemIdsAndPlaceholdersOnPlan();
    const hasFilter = this.isFilterApplied();
    const filteredPlanPlaceholderIds = hasFilter ? this.getFilteredPlanPlaceholderIds() : null;

    const planContext: PlanContext = {
      id: currentPlan.id,
      name: currentPlan.name,
      orgId: currentPlan.orgId,
      targetAssortmentId: currentPlan.targetAssortmentId,
      workspaceId: currentPlan.workspaceId,
      itemIds,
      planPlaceholders,
      filteredPlanPlaceholderIds,
      hasFilter,
    };

    const context = {
      plan: planContext,
      vibeIQApp: VibeIQAppType.PLAN,
      selectedElements,
    };

    this.appExtensionService.launchExtensionModal(appExtension, this.planWebSDKMessageHandler, context);
  }

  getCurrentPlan(): any {
    let plan;
    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(
        take(1),
        tap((currentPlan) => {
          plan = currentPlan;
        }),
      )
      .subscribe();

    return plan;
  }

  getItemIdsAndPlaceholdersOnPlan(): { itemIds: string[]; planPlaceholders: any[] } {
    let itemIds: string[];
    let planPlaceholders: any[];

    this.store
      .select(CollectionManagerSelectors.selectCollectionData)
      .pipe(
        take(1),
        tap((data: Array<any>) => {
          const itemIdsSet = new Set<string>();
          data.forEach((row) => {
            if (row.itemOptionId) {
              itemIdsSet.add(row.itemOptionId);
            }
            if (row.itemFamilyId) {
              itemIdsSet.add(row.itemFamilyId);
            }
          });

          planPlaceholders = data;
          itemIds = Array.from(itemIdsSet);
        }),
      )
      .subscribe();

    return { itemIds, planPlaceholders };
  }

  getFilteredPlanPlaceholderIds(): string[] {
    let filteredPlanPlaceholderIds: string[];

    this.store
      .select(CollectionManagerSelectors.filteredData)
      .pipe(
        take(1),
        tap((data: Array<any>) => {
          filteredPlanPlaceholderIds = data.map((row) => row.id);
        }),
      )
      .subscribe();

    return filteredPlanPlaceholderIds;
  }

  private getSelectedPlaceholders() {
    if (!this.selectedPlaceholderIds?.length) {
      return [];
    }

    let placeholders: any[];
    this.store
      .select(CollectionManagerSelectors.displayedData)
      .pipe(
        take(1),
        tap((data) => {
          placeholders = ObjectUtil.cloneDeep(data.filter((obj) => this.selectedPlaceholderIds.includes(obj.id)));
        }),
      )
      .subscribe();

    return placeholders;
  }

  private isFilterApplied(): boolean {
    let hasActiveFilter = false;

    this.store
      .select(CollectionManagerSelectors.filterDefinition)
      .pipe(
        take(1),
        tap((filterDefinition: any) => {
          const filterPropertyCriteria = filterDefinition?.filterCriteria?.propertyCriteria;
          if (filterPropertyCriteria && filterPropertyCriteria.length > 0) {
            hasActiveFilter = true;
          }
        }),
      )
      .subscribe();

    return hasActiveFilter;
  }
}
