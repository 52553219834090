import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  Observable,
  startWith,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { RootStoreState, UserSessionSelectors } from 'src/app/root-store';
import { AuthService } from '../../auth/auth.service';
import { UserSession } from '../user-session';

@Component({
  selector: 'app-user-sessions-avatars',
  templateUrl: './user-sessions-avatars.component.html',
  styleUrls: ['./user-sessions-avatars.component.scss'],
})
export class UserSessionsAvatarsComponent implements OnInit, AfterViewInit {
  public userSessions$: Observable<Array<UserSession>>;
  public filteredUserSessions$: Observable<Array<any>>;
  public user: any;
  public isOpened = false;
  public searchInput = new UntypedFormControl();
  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;
  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.userSessions$ = this.store.select(UserSessionSelectors.selectUserSessions);
    this.authService.authContext.subscribe((authContext) => {
      this.user = authContext.user;
    });
  }

  ngAfterViewInit(): void {
    this.filteredUserSessions$ = combineLatest([
      this.searchInput.valueChanges.pipe(startWith(''), debounceTime(100), distinctUntilChanged()),
      this.store.select(UserSessionSelectors.selectUserSessions),
    ]).pipe(
      switchMap(async ([text, userSessions]) => {
        if (!text) {
          return this.formatUserSesssion(userSessions);
        }
        const searchText = text.toLowerCase();
        return this.formatUserSesssion(
          userSessions?.filter(
            (session) =>
              session?.user?.email?.toLowerCase()?.includes(searchText) ||
              session?.user?.firstName?.toLowerCase()?.includes(searchText) ||
              session?.user?.lastName?.toLowerCase()?.includes(searchText) ||
              (session?.user?.firstName
                ? (session?.user?.firstName?.toLowerCase() + ' ' + session?.user?.lastName?.toLowerCase()).includes(
                    searchText,
                  )
                : false),
          ),
        );
      }),
    );
  }

  private formatUserSesssion(userSessions) {
    return ObjectUtil.cloneDeep(userSessions).map((session) => {
      session.user.displayName = this.getDisplayName(session.user);
      return session;
    });
  }

  private getDisplayName(user) {
    if (user) {
      if (user.firstName) {
        return user.firstName + ' ' + user.lastName;
      } else if (this.user.email) {
        return user.email;
      }
    }
  }

  public toggleDisplay() {
    this.isOpened = !this.isOpened;
    if (this.isOpened) {
      const destroy$ = new Subject();
      this.cdkConnectedOverlay?.overlayOutsideClick.pipe(takeUntil(destroy$)).subscribe((evt: any) => {
        destroy$.next(null);
        destroy$.complete;
        this.isOpened = false;
      });
    }
  }
}
