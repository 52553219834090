import { Injectable } from '@angular/core';
import { ViewDefinition, ViewPropertyConfiguration } from '@contrail/client-views';
import { Entities } from '@contrail/sdk';

const DEFAULT_COLUMN_WIDTH = 150;

export class PivotHeaderColumnResizeHelper {
  private headerYCoord;
  private draggedDistance = 0;
  private currentHeaderProperty: ViewPropertyConfiguration;
  private currentHeaderElement: HTMLElement;
  private dragStartEventLocation: any;

  // RESIZE COLUMN LOGIC
  handleColumnResizeDragStart(property: ViewPropertyConfiguration) {
    this.currentHeaderProperty = property;
    this.currentHeaderElement = document.getElementById('column_header_' + this.currentHeaderProperty.slug);
    this.dragStartEventLocation = null;
  }

  handleColumnResizeDragMove(event) {
    if (!this.headerYCoord) {
      const gridBodyBox = document.getElementById('grid-body').getBoundingClientRect();
      this.headerYCoord = gridBodyBox.y;
    }
    // HANDLE START OF DRAG
    if (!this.dragStartEventLocation) {
      this.dragStartEventLocation = event.pointerPosition;
    }

    const dragPreview = document.getElementById('column_resize_' + this.currentHeaderProperty.slug);
    const xPos = event.pointerPosition.x;
    this.draggedDistance = xPos - this.dragStartEventLocation.x;

    // HANDLE START OF DRAG
    if (!this.dragStartEventLocation) {
      this.dragStartEventLocation = event.pointerPosition;
    }

    dragPreview.style.transform = `translate3d(${xPos}px, ${this.headerYCoord}px, 0)`;
  }

  async handleColumnResizeDragDrop(viewDefinition: ViewDefinition) {
    const column = viewDefinition.properties.find((prop) => prop.slug === this.currentHeaderProperty.slug);
    if (!column) {
      return;
    }
    column.width = column.width || DEFAULT_COLUMN_WIDTH;
    column.width += this.draggedDistance;
    if (column.width < 45) {
      column.width = 45;
    }
    const updatedViewDefinition = await new Entities().update({
      entityName: 'view-definition',
      id: viewDefinition.id,
      object: { properties: viewDefinition.properties },
    });
    this.bindPropertiesToView(viewDefinition, updatedViewDefinition);
    return updatedViewDefinition;
  }

  private bindPropertiesToView(sourceViewDefinition: ViewDefinition, targetViewDefinition: ViewDefinition) {
    sourceViewDefinition.properties?.forEach((prop, index) => {
      targetViewDefinition.properties[index].propertyDefinition = prop.propertyDefinition;
    });
  }
}
