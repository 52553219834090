<div class="header">
  {{ plan.name }}
</div>
<div class="body">
  <div class="value chart">
    <app-compare-pie-chart [plan]="plan"></app-compare-pie-chart>
  </div>
  <div class="value">
    {{ plan.planSummary.count }}
  </div>
  <div class="value">
    {{ plan.planSummary.aggregates.itemName.distinct | number }}
  </div>
  <div class="value">
    {{ plan.planSummary.aggregates.targetRetail.average | currency }}
  </div>
  <div class="value">
    {{ plan.planSummary.aggregates.targetVolume.total | number }}
  </div>
  <div class="value">${{ plan.planSummary.aggregates.totalRevenue.total | roundNumber: 2 }}</div>
  <div class="value">${{ plan.planSummary.aggregates.totalMargin.total | roundNumber: 2 }}</div>
  <div class="action">
    <button mat-stroked-button (click)="goToPlan()">Open</button>
  </div>
</div>
