import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSessionsStoreModule } from './user-sessions-store';
import { UserSessionsAvatarsComponent } from './user-sessions-avatars/user-sessions-avatars.component';
import { UserSessionMessageHandler } from './user-session-message-handler';
import { ComponentsModule } from '../components/components.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '@pipes/pipes.module';

@NgModule({
  declarations: [UserSessionsAvatarsComponent],
  imports: [
    CommonModule,
    UserSessionsStoreModule,
    ComponentsModule,
    OverlayModule,
    MatIconModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
  ],
  exports: [UserSessionsAvatarsComponent],
})
export class UserSessionsModule {
  constructor(private userSessionMessageHandler: UserSessionMessageHandler) {}
}
