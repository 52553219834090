import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { CollectionManagerSelectors } from 'src/app/collection-manager/collection-manager-store';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { Plan } from '../plans-store/plans.state';
import { AuthSelectors } from '@common/auth/auth-store';
import { PublishService } from './publish.service';
import { CollectionStatusMessageService } from 'src/app/collection-manager/side-panel/status-messages/collection-status-message.service';

@Component({
  selector: 'app-publish-plan',
  templateUrl: './publish-plan.component.html',
  styleUrls: ['./publish-plan.component.scss'],
})
export class PublishPlanComponent implements OnInit, OnDestroy {
  canPublish = false;
  public currentPlan$: Observable<Plan>;
  public status;
  public diff;
  public nameFormControl = new UntypedFormControl('');
  public commentsFormControl = new UntypedFormControl('');
  public isLoadingAlerts$: BehaviorSubject<boolean>;
  public haveAlertsFailedToLoad$: BehaviorSubject<boolean>;
  private messagesSub: Subscription;
  private alertsLoadingSubscription: Subscription;
  authContext: any = {};

  constructor(
    private store: Store<RootStoreState.State>,
    private publishService: PublishService,
    public dialogRef: MatDialogRef<PublishPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private collectionStatusMessageService: CollectionStatusMessageService,
  ) {
    this.currentPlan$ = this.store.select(PlansSelectors.currentPlan);
    this.publishService.completedJobsObservable$.subscribe((completedJob) => {
      if (completedJob) {
        this.status = completedJob.status === 'completed' ? 'COMPLETE' : 'FAILURE';
      }
    });
    this.publishService.errorObservable$.subscribe((error) => {
      this.status = 'FAILURE';
    });
    this.canPublish = data.canPublish;
    console.log('canPublish ===', this.canPublish);
    store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => (this.authContext = authContext));
  }

  ngOnInit(): void {
    if (!this.canPublish) {
      alert(`Publish isn't available`);
    }

    this.haveAlertsFailedToLoad$ = this.collectionStatusMessageService.hasFailedToLoadMessages$;
    this.isLoadingAlerts$ = this.collectionStatusMessageService.isLoadingMessages$;

    this.alertsLoadingSubscription = combineLatest([this.haveAlertsFailedToLoad$, this.isLoadingAlerts$])
      .pipe(
        filter(([hasFailed, isLoading]) => !isLoading && !hasFailed),
        take(1),
      )
      .subscribe(() => {
        this.validateCollectionStatusMessages();
      });
  }

  validateCollectionStatusMessages() {
    this.messagesSub = this.store
      .select(CollectionManagerSelectors.collectionStatusMessages)
      .pipe(
        tap((messages: Array<CollectionStatusMessage>) => {
          let hasError = false;
          for (const message of messages) {
            this.store
              .select(CollectionManagerSelectors.selectCollectionElement(message.collectionElementId))
              .pipe(
                take(1),
                tap((ph) => {
                  //if(!(ph.isDropped === true)){
                  hasError = true;
                  //}
                }),
              )
              .subscribe();
          }
          if (hasError) {
            this.status = 'INVALID';
          } else {
            this.status = 'PENDING';
            this.getDiff();
          }
        }),
      )
      .subscribe();
  }

  cancel() {
    this.dialogRef.close();
  }
  confirm() {
    this.status = 'PUBLISHING';
    this.currentPlan$
      .pipe(
        take(1),
        switchMap(async (plan) => {
          const data = {
            versionName: this.nameFormControl.value,
            versionComments: this.commentsFormControl.value,
          };
          /*
        try {
          await new Entities().create({ entityName: 'plan', id: plan.id, object: data, relation: 'publish' });
          return 'success';
        } catch (e) {
          return 'failure';
        }*/
          return await this.publishService.initPublish({ data, plan });
        }),
      )
      .subscribe((res) => {
        console.log(res);
      });
  }

  async getDiff() {
    this.currentPlan$
      .pipe(
        filter((plan) => !!plan),
        take(1),
        tap(async (plan) => {
          const diff = await this.getPlanComparison(plan.id);
          if (diff) {
            this.diff = diff;
            const noChanges = !this.diff.adds?.length && !this.diff.deletes?.length && !this.diff.updates?.length;
            this.status = noChanges ? 'NO_CHANGES' : 'HAS_CHANGES';
          }
        }),
      )
      .subscribe();
  }

  async getPlanComparison(planId: string): Promise<any> {
    try {
      return await new Entities().create({ entityName: 'plan', id: planId, object: {}, relation: 'compare' });
    } catch (error) {
      if (error?.message && error.message.includes('validation error')) {
        this.status = 'INVALID';
      } else {
        this.status = 'FAILURE';
      }
    }
  }

  ngOnDestroy(): void {
    if (this.messagesSub) {
      this.messagesSub?.unsubscribe();
    }

    if (this.alertsLoadingSubscription) {
      this.alertsLoadingSubscription.unsubscribe();
    }
  }
}
