import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SourceChangeHistoryListComponent } from './source-change-history-list/source-change-history-list.component';
import { SourceChangeHistoryHeaderComponent } from './source-change-history-header/source-change-history-header.component';
import { SourceChangeHistoryFiltersComponent } from './source-change-history-filters/source-change-history-filters.component';
import { SourceChangeHistoryPanelComponent } from './source-change-history-panel/source-change-history-panel.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { SourceChangeHistoryEventComponent } from './source-change-history-event/source-change-history-event.component';
import { SourceChangeHistoryEventChangeDetailsComponent } from './source-change-history-event-change-details/source-change-history-event-change-details.component';
import { DateFilterModule } from '@common/components/date-filter/date-filter.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@pipes/pipes.module';
import { SourceChangeHistoryEventImageComponent } from './source-change-history-event/source-change-history-event-image.component';

@NgModule({
  declarations: [
    SourceChangeHistoryListComponent,
    SourceChangeHistoryHeaderComponent,
    SourceChangeHistoryFiltersComponent,
    SourceChangeHistoryPanelComponent,
    SourceChangeHistoryEventComponent,
    SourceChangeHistoryEventChangeDetailsComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    VirtualScrollerModule,
    DateFilterModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    SourceChangeHistoryEventImageComponent,
  ],
  exports: [SourceChangeHistoryPanelComponent],
})
export class SourceChangeHistoryModule {}
