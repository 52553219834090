import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { UndoRedoService } from 'src/app/common/undo-redo/undo-redo-service';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../collection-manager-store';
import { CreatePlaceholderUndoRedoHandler } from './handlers/create-placeholder-undo-redo.handler';
import { DeletePlaceholderUndoRedoHandler } from './handlers/delete-placeholder-undo-redo.handler';
import { UpdatePlaceholderUndoRedoHandler } from './handlers/update-placeholder-undo-redo.handler';
import { UpdateRowOrderUndoRedoHandler } from './handlers/update-row-order-undo-redo.handler';
import { UndoRedoActionType, UndoRedoDetails } from './undo-redo-objects';
import { ItemUpdateService } from '../items/item-update-service';
import { ProjectItemUpdateService } from '../project-items/project-item-update-service';
import { UndoRedoActions } from '@common/undo-redo/undo-redo-store';

@Injectable({
  providedIn: 'root',
})
export class PlanUndoRedoService {
  data: any[];
  constructor(
    private undoRedoService: UndoRedoService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private itemUpdateService: ItemUpdateService,
    private projectItemUpdateService: ProjectItemUpdateService,
  ) {
    this.store.select(CollectionManagerSelectors.displayedData).subscribe((data) => {
      if (data) {
        this.data = data;
      }
    });
  }

  public undoActions() {
    this.undoRedoService.getNextUndo().subscribe((nextUndo) => {
      if (nextUndo) {
        const undoRedoDetails: UndoRedoDetails = ObjectUtil.cloneDeep(nextUndo);
        this.getHandler(undoRedoDetails).undoActions();
      } else {
        this.snackBar.open('No more actions to undo', '', { duration: 2000 });
      }
    });
  }

  public redoActions() {
    this.undoRedoService.getNextRedo().subscribe((nextRedo) => {
      if (nextRedo) {
        const undoRedoDetails: UndoRedoDetails = ObjectUtil.cloneDeep(nextRedo);
        this.getHandler(undoRedoDetails).redoActions();
      } else {
        this.snackBar.open('No more actions to redo', '', { duration: 2000 });
      }
    });
  }

  public undoAndRemoveActionByUuid(undoActionUuid: string) {
    this.undoRedoService.getUndoByUuid(undoActionUuid).subscribe((undoAction) => {
      if (undoAction) {
        const undoRedoDetails: UndoRedoDetails = ObjectUtil.cloneDeep(undoAction);
        this.getHandler(undoRedoDetails).undoActions();
        this.store.dispatch(UndoRedoActions.removeUndo({ uuid: undoAction.uuid }));
      }
    });
  }

  private getHandler(undoRedoDetails: UndoRedoDetails) {
    switch (undoRedoDetails.actionType) {
      case UndoRedoActionType.CREATE_PLACEHOLDER:
        return new CreatePlaceholderUndoRedoHandler(undoRedoDetails, this.store);
      case UndoRedoActionType.UPDATE_PLACEHOLDER:
        return new UpdatePlaceholderUndoRedoHandler(
          undoRedoDetails,
          this.store,
          this.itemUpdateService,
          this.projectItemUpdateService,
          this.data,
        );
      case UndoRedoActionType.DELETE_PLACEHOLDER:
        return new DeletePlaceholderUndoRedoHandler(undoRedoDetails, this.store);
      case UndoRedoActionType.UPDATE_ROW_ORDER:
        return new UpdateRowOrderUndoRedoHandler(undoRedoDetails, this.store);
    }
  }
}
