import { Component, Input, OnChanges } from '@angular/core';
import { PropertyType, PropertyValueFormatter } from '@contrail/types';
import { EntityHistoryEvent, EntityHistoryEventListItem } from '../entity-history-event';
import { ListColumnDefinition, ListColumnRenderType } from '@components/list/list-column-definition';
import { UntypedFormControl } from '@angular/forms';
import { ListSortBy } from '@components/list/list.component';
import { SortOrderOptions } from '@contrail/entity-types';

@Component({
  selector: 'app-entity-history-list',
  templateUrl: './entity-history-list.component.html',
  styleUrls: ['./entity-history-list.component.scss'],
})
export class EntityHistoryListComponent implements OnChanges {
  @Input() historyEvents: Array<EntityHistoryEvent>;
  public isLoading: boolean = false;
  public historyListItems: Array<any>;
  private readonly sortOptions: ListSortBy[] = [
    { label: 'Property', direction: SortOrderOptions.ASC },
    { label: 'Project', direction: SortOrderOptions.ASC },
    { label: 'Old Value', direction: SortOrderOptions.ASC },
    { label: 'New Value', direction: SortOrderOptions.ASC },
    { label: 'Updated By', direction: SortOrderOptions.ASC },
    { label: 'Date Modified', direction: SortOrderOptions.ASC },
  ];
  public sortByControl = new UntypedFormControl(this.sortOptions[5]);
  public columnDefinitions: ListColumnDefinition[] = [
    { index: 'propertyLabel', label: 'Property', alignment: 'left', propertyType: PropertyType.String },
    { index: 'projectName', label: 'Project', alignment: 'left', propertyType: PropertyType.String },
    {
      index: 'oldValue',
      label: 'Old Value',
      alignment: 'left',
      propertyType: PropertyType.String,
      renderType: ListColumnRenderType.TEXT_OR_IMAGE,
    },
    {
      index: 'newValue',
      label: 'New Value',
      alignment: 'left',
      propertyType: PropertyType.String,
      renderType: ListColumnRenderType.TEXT_OR_IMAGE,
    },
    { index: 'updatedByEmail', label: 'Updated By', alignment: 'right', propertyType: PropertyType.String },
    {
      index: 'updatedOn',
      label: 'Date Modified',
      alignment: 'right',
      propertyType: PropertyType.Date,
      dateFormat: { includeTime: true },
    },
  ];

  private formatter: PropertyValueFormatter;

  constructor() {
    this.formatter = new PropertyValueFormatter();
  }

  ngOnChanges() {
    this.buildHistoryListItems();
  }

  buildHistoryListItems() {
    const historyListItems: EntityHistoryEventListItem[] = [];

    let itemIndex = 0;
    for (const historyEvent of this.historyEvents || []) {
      for (const propertyChange of historyEvent.propertyChanges) {
        const isImageChange = propertyChange.isImageChange;

        const oldValue = isImageChange
          ? propertyChange.oldValue
          : this.formatter.formatValueForProperty(propertyChange.oldValue, propertyChange.typeProperty);

        const newValue = isImageChange
          ? propertyChange.newValue
          : this.formatter.formatValueForProperty(propertyChange.newValue, propertyChange.typeProperty);

        const isPropertyUnchanged = Boolean((!oldValue && !newValue) || oldValue === newValue);
        if (isPropertyUnchanged) {
          continue;
        }

        historyListItems.push({
          id: itemIndex++,
          propertyKey: propertyChange.propertyKey,
          propertyLabel: propertyChange.propertyLabel,
          oldValue,
          newValue,
          projectName: historyEvent.projectName,
          updatedByEmail: historyEvent?.user?.email,
          updatedOn: historyEvent.eventDate,
          user: historyEvent.user,
        });
      }
    }

    this.historyListItems = historyListItems;
  }
}
