import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { escapeRegExp } from 'lodash';
import { ViewDefinition } from '@contrail/client-views';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { startWith, debounceTime, switchMap } from 'rxjs/operators';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions } from '../../collection-manager-store';

@Component({
  selector: 'app-view-selector',
  templateUrl: './view-selector.component.html',
  styleUrls: ['./view-selector.component.scss'],
})
export class ViewSelectorComponent implements AfterViewInit, OnChanges {
  @Input() allViews: Array<ViewDefinition> = [];
  @Input() currentView: ViewDefinition = {};
  @Input() editorMode = 'VIEW';
  @Output() selectView: EventEmitter<ViewDefinition> = new EventEmitter();

  public allowViewsReorder = true;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  filteredViews$: Observable<Array<ViewDefinition>>;
  private existingViews$: BehaviorSubject<Array<ViewDefinition>> = new BehaviorSubject(null);
  constructor(private store: Store<RootStoreState.State>) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.updateExistingViews();
    this.allowViewsReorder = this.editorMode === 'EDIT';
  }
  setCurrentView(viewDefinition: ViewDefinition) {
    this.selectView.emit(viewDefinition);
  }
  getIconForView(viewDefinition: ViewDefinition) {
    switch (viewDefinition.viewType) {
      case 'grid':
        return 'view_comfy';
      case 'kanban':
        return 'view_column';
      case 'matrix':
        return 'grid_view';
    }
  }

  private updateExistingViews() {
    this.existingViews$.next(this.allViews);
  }

  async listDrop(event) {
    moveItemInArray(this.allViews, event.previousIndex, event.currentIndex);
    this.updateExistingViews();
    const viewSortOrder = [];

    // do not update all views instead just update the views in the moved window
    let startUpdateIndex = event.previousIndex < event.currentIndex ? event.previousIndex : event.currentIndex;
    const endUpdateIndex = event.previousIndex > event.currentIndex ? event.previousIndex : event.currentIndex;

    while (startUpdateIndex <= endUpdateIndex) {
      viewSortOrder.push({
        id: this.allViews[startUpdateIndex].id,
        sortOrder: startUpdateIndex,
        label: this.allViews[startUpdateIndex].label,
      });
      startUpdateIndex++;
    }
    await this.store.dispatch(CollectionManagerActions.updateViewOrder({ viewSortOrder }));
  }

  ngAfterViewInit(): void {
    this.filteredViews$ = combineLatest([
      this.searchBar?.valueChange.pipe(startWith('')),
      this.existingViews$.asObservable(),
    ]).pipe(
      switchMap(async ([searchTerm, views]) => {
        this.allowViewsReorder = true;
        if (searchTerm.trim().length || this.editorMode !== 'EDIT') {
          this.allowViewsReorder = false;
        }
        const searchOptions = ['label'];
        const filtered = views.filter((prop) => {
          return (
            !searchTerm?.length ||
            searchOptions.some((key) =>
              new RegExp(escapeRegExp(searchTerm), 'gi').test(ObjectUtil.getByPath(prop, key.trim())),
            )
          );
        });
        return filtered;
      }),
    );
  }
}
