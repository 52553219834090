<div class="list-container h-full">
  <div class="list-header">
    <div
      class="list-header-cell"
      [ngClass]="{
        right: column.alignment === 'right',
        left: column.alignment === 'left',
        center: column.alignment === 'center'
      }"
      *ngFor="let column of columnDefinitions"
    >
      <ng-container *ngIf="!sortBy">{{ column.label }}</ng-container>
      <ng-container *ngIf="sortBy">
        <span class="label" (debounceClick)="sort(column)" appDebounceClick="100">
          {{ column.label }}
          <span *ngIf="column?.label && column.label === sortBy?.label" class="material-icons z-40">
            {{ sortBy?.direction === 'asc' ? 'arrow_drop_up' : 'arrow_drop_down' }}
          </span>
          <span *ngIf="column?.label" class="material-icons z-50 hover-icon opacity-0">
            {{ sortBy?.direction === 'desc' ? 'arrow_drop_up' : 'arrow_drop_down' }}
          </span>
        </span>
      </ng-container>
    </div>
  </div>

  <virtual-scroller #scroll [items]="listData" class="narrow-scroll h-full">
    <div
      class="list-row"
      *ngFor="let itemData of scroll.viewPortItems"
      [class.selected]="itemData?.selected"
      (click)="handleClickRow(itemData)"
      (dblclick)="handleDoubleClickRow(itemData)"
    >
      <div
        class="list-cell"
        [ngClass]="{
          right: column.alignment === 'right',
          left: column.alignment === 'left',
          center: column.alignment === 'center'
        }"
        *ngFor="let column of columnDefinitions"
      >
        <app-list-item-cell
          class="prevent-select"
          [listItemData]="itemData"
          [columnDefinition]="column"
          [shortenCellCharacterLimit]="shortenCellCharacterLimit"
        ></app-list-item-cell>
      </div>
    </div>
  </virtual-scroller>
</div>
