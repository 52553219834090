import { API_VERSION, Entities, SortOrderOptions, Types } from '@contrail/sdk';
import { SortDefinition, SortDirection } from '../../components/sort/sort-definition';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FilterHelper } from '../../types/filters/filter-helper';
import { ChooserDataSource, ChooserFilterConfig } from './chooser-data-source';

export class LibraryChooserPaginatedDataSource extends ChooserDataSource {
  protected moreResultsLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public moreResultsLoading$: Observable<boolean> = this.moreResultsLoadingSubject.asObservable();

  constructor(
    protected entityType: string,
    private typePath: string,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    protected searchContext: any,
  ) {
    super(entityType, filterConfigSubject, sortConfigSubject, existingItemIdsSubject, showAllSubject, null);
    this.initFilteredDataObservable();
    this.initResultsObservable();
  }
  private results: Array<any> = [];
  private nextPageKey: string;
  private getPaginatedDataConfig: any;

  protected async initFilteredDataObservable() {
    this.filteredData$ = combineLatest([this.filterConfigSubject, this.sortConfigSubject]).pipe(
      switchMap(async ([filterConfig, sortConfig]) => {
        this.results = [];
        this.nextPageKey = undefined;

        if (!filterConfig || !sortConfig) {
          return;
        }
        const relations = [];
        let searchTerm = filterConfig.searchTerm.trim() || '';
        const filterDefinition = filterConfig.filterDefinition;
        const criteria = filterConfig.baseCriteria;
        if (!searchTerm?.endsWith('*')) {
          searchTerm += '*';
        }

        // Contextual criteria allows us to append automatic filters based
        // on a pass in context.  This is useful for object references
        // which may need to be filtered based on a merch hiearchy, etc (RL, etc)
        // This could also be used for applying plan level criteria as well, such as a
        // gender / etc for item searching.
        const contextualCriteria = await this.getContextualCriteria();

        console.log('criteria: ', criteria, contextualCriteria, filterDefinition);
        let apiCriteria = Object.assign({}, contextualCriteria, criteria);
        const filterCriteria = FilterHelper.toSimpleCriteria(filterDefinition);
        if (filterCriteria) {
          apiCriteria = Object.assign(apiCriteria, filterCriteria);
        }

        this.loadingSubject.next(true);
        const sortOrders = sortConfig.map((sortDefinition) => {
          return {
            order: sortDefinition.direction === SortDirection.ASCENDING ? SortOrderOptions.ASC : SortOrderOptions.DESC,
            orderField: sortDefinition.propertySlug,
          };
        });

        const isSubType = Boolean(this.typePath && this.entityType && this.typePath !== this.entityType);
        if (isSubType) {
          apiCriteria.typePath = this.typePath;
        }

        this.getPaginatedDataConfig = {
          apiVersion: API_VERSION.V2,
          entityName: this.entityType,
          relations,
          criteria: apiCriteria,
          search: searchTerm,
          order: sortOrders,
          paginate: true,
          nextPageKey: this.nextPageKey,
        };

        if (searchTerm?.length && searchTerm !== '*') {
          this.getPaginatedDataConfig.search = searchTerm.replace(/\\/g, '');
          this.getPaginatedDataConfig.criteria.useExactMatchFirst = true;
        }

        const results = await this.getData();
        this.loadingSubject.next(false);
        return results;
      }),
    );
  }

  private async getData() {
    const numberOfResultsPerPage = 100;

    this.getPaginatedDataConfig = {
      ...this.getPaginatedDataConfig,
      take: numberOfResultsPerPage,
      nextPageKey: this.nextPageKey,
    };

    const data = await new Entities().get(this.getPaginatedDataConfig);
    if (data) {
      this.nextPageKey = data.nextPageKey;
      this.results = [...this.results, ...data.results];
    } else {
      console.error('Error: Failed to fetch entities.');
    }

    return this.results;
  }
  async getContextualCriteria() {
    const criteria: any = {};

    if (this.typePath && this.searchContext) {
      await this.setContextualCriteriaForSearchContext(criteria);
    }

    const archivableEntityTypes = ['custom-entity', 'color'];
    const shouldIgnoreArchived = archivableEntityTypes.includes(this.entityType);
    if (shouldIgnoreArchived) {
      criteria.isArchived = false;
    }

    return criteria;
  }

  private async setContextualCriteriaForSearchContext(criteria: any) {
    //Using root type because subtype properties are not yet indexed for filtering.
    const rootType =
      this.typePath.indexOf(':') > 1 ? this.typePath.substring(0, this.typePath.indexOf(':')) : this.typePath;

    const type = await new Types().getType({ path: rootType });
    const properties = type.typeProperties.filter(
      (p) => !['name', 'createdOn', 'updatedOn', 'createdBy', 'updatedBy'].includes(p.slug),
    );

    for (const property of properties) {
      const value = this.searchContext[property.slug];
      if (value) {
        criteria[property.slug] = value;
      }
    }
  }

  public async getMorePaginatedResults() {
    if (this.nextPageKey && this.results.length > 0 && this.loadingSubject.value === false) {
      this.moreResultsLoadingSubject.next(true);
      const nextSetOfResults = await this.getData();
      this.assignResults(nextSetOfResults);
      this.moreResultsLoadingSubject.next(false);
    }
  }
}
