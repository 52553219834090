import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ViewDefinition, ViewPropertyConfiguration } from '@contrail/client-views';
import { Entities } from '@contrail/sdk';
import { PropertyType, Type } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { ELIGIBLE_GROUP_PROPERTY_TYPES } from '../pivot-grid-grouping-row-properties';
import { CollectionManagerViewService } from 'src/app/collection-manager/collection-manager-view.service';

const PLAN_PLACEHOLDER_TYPE = 'plan-placeholder';

@Component({
  selector: 'app-pivot-view-configurator',
  templateUrl: './pivot-view-configurator.component.html',
  styleUrls: ['./pivot-view-configurator.component.scss'],
})
export class PivotViewConfiguratorComponent implements OnInit, OnDestroy {
  @Input() typeDefinition: Type;
  @Input() viewDefinition: ViewDefinition;
  @Output() close = new EventEmitter();
  @Output() viewDefinitionUpdated = new EventEmitter();
  @ViewChild('inputField') nameInputField: ElementRef;
  public nameInput: UntypedFormControl;
  rowGroupingProperties: any[] = []; // available properties for row grouping
  currentRowGroupingProperties: any[] = [];
  columnProperties: any[]; // available properties for right columns
  currentColumnProperties: any[] = [];
  control = new UntypedFormControl();
  editName = false;

  constructor(private collectionManagerViewService: CollectionManagerViewService) {}

  async ngOnInit(): Promise<void> {
    this.setProperties();
    this.setCurrentProperties(this.viewDefinition);
  }

  ngOnDestroy() {}

  setCurrentProperties(viewDefinition: ViewDefinition): any {
    if (viewDefinition.properties.length > 0) {
      this.currentRowGroupingProperties = viewDefinition.properties
        .filter((property) => property.enabled && property.frozen && property.propertyDefinition)
        .map((property) => {
          return {
            value: property.slug,
            label: property.propertyDefinition.label,
            propertyType: property.propertyDefinition.propertyType,
          };
        });
      this.currentColumnProperties = viewDefinition.properties
        .filter((property) => property.enabled && !property.frozen && property.propertyDefinition)
        .map((property) => {
          return {
            value: property.slug,
            label: property.propertyDefinition?.label || 'Count',
            propertyType: property.propertyDefinition?.propertyType || PropertyType.Number,
            aggregateFunction: property.aggregateFunction || 'SUM',
          };
        });
    }
  }

  private setProperties() {
    const columnProperties = this.typeDefinition.typeProperties
      .filter((property) =>
        [PropertyType.Formula, PropertyType.Number, PropertyType.Currency].includes(property.propertyType),
      )
      .map((property) => {
        return { value: property.slug, label: property.label, propertyType: property.propertyType };
      });

    //add the count property
    columnProperties.push({ value: 'count', label: 'Count', propertyType: PropertyType.Number });

    const groupProperties = this.typeDefinition.typeProperties
      .filter((property) => ELIGIBLE_GROUP_PROPERTY_TYPES.includes(property.propertyType))
      .filter((property) => property.slug !== 'createdOn' && property.slug !== 'updatedOn')
      .map((property) => {
        return { value: property.slug, label: property.label, propertyType: property.propertyType };
      });

    this.rowGroupingProperties = groupProperties;
    this.columnProperties = columnProperties;
  }

  updateProperties(selectedProperties, isGroupProperties = true) {
    const viewPropertyDefs = [];
    selectedProperties.forEach((property) => {
      let propertyDef = this.typeDefinition.typeProperties.find((typeProperty) => typeProperty.slug === property.value);
      if (!propertyDef) {
        propertyDef = {
          slug: property.value,
          label: property.label,
          propertyType: property.propertyType,
        };
      }
      const newProp: ViewPropertyConfiguration = {
        enabled: true,
        frozen: isGroupProperties,
        propertyDefinition: propertyDef,
        slug: propertyDef.slug,
        typeRootSlug: PLAN_PLACEHOLDER_TYPE,
        width: 150,
      };
      if (!isGroupProperties) {
        newProp.aggregateFunction = 'SUM';
        property.aggregateFunction = 'SUM';
      }
      viewPropertyDefs.push(newProp);
    });
    if (isGroupProperties) {
      this.currentRowGroupingProperties = ObjectUtil.cloneDeep(selectedProperties);
    } else {
      this.currentColumnProperties = ObjectUtil.cloneDeep(selectedProperties);
    }
    // Get group properties and swap them out.
    const updatedViewDef = ObjectUtil.cloneDeep(this.viewDefinition);
    const currentPropertyDefs = updatedViewDef.properties.filter((property) => property.frozen === isGroupProperties);
    currentPropertyDefs.forEach((property) => {
      const index = updatedViewDef.properties.findIndex(
        (currentViewProperty) => currentViewProperty.slug === property.slug,
      );
      if (index > -1) {
        updatedViewDef.properties.splice(index, 1);
      }
    });
    updatedViewDef.properties = isGroupProperties
      ? viewPropertyDefs.concat(updatedViewDef.properties)
      : updatedViewDef.properties.concat(viewPropertyDefs);
    const groupProperties = updatedViewDef.properties.filter((prop) => prop.frozen);
    if (updatedViewDef.properties.length > 0 && updatedViewDef.properties[0].frozen) {
      updatedViewDef.properties[0].width = 150 * groupProperties.length;
    }
    this.updateViewDefinition(this.viewDefinition, { properties: updatedViewDef.properties });
  }

  updateSortProperties(event) {
    let updateView = false;
    const newSort = {
      direction: event.direction,
      propertyType: event.property.propertyType,
      propertyLabel: event.property.label,
      propertySlug: event.property.value,
    };
    const updatedViewDef = ObjectUtil.cloneDeep(this.viewDefinition);
    updatedViewDef.sorts = updatedViewDef.sorts || [];
    const index = updatedViewDef.sorts.findIndex((sort) => sort.propertySlug === newSort.propertySlug);
    if (index > -1) {
      if (updatedViewDef.sorts[index].direction !== newSort.direction) {
        updatedViewDef.sorts.splice(index, 1, newSort);
        updateView = true;
      }
    } else {
      updatedViewDef.sorts.push(newSort);
      updateView = true;
    }
    if (updateView) {
      this.updateViewDefinition(this.viewDefinition, { sorts: updatedViewDef.sorts });
    }
  }

  updatePropertyAggregateFunction(event) {
    const currentColumnProperty = this.currentColumnProperties.find(
      (property) => property.value === event.property.value,
    );
    currentColumnProperty.aggregateFunction = event.functionType;
    const properties: ViewPropertyConfiguration[] = ObjectUtil.cloneDeep(this.viewDefinition.properties);
    const updatedProperty = properties.find((property) => property.slug === event.property.value);
    updatedProperty.aggregateFunction = event.functionType;
    this.updateViewDefinition(this.viewDefinition, { properties });
  }

  startEditName() {
    this.editName = true;
    this.nameInput = new UntypedFormControl(this.viewDefinition.label);
    setTimeout(() => {
      this.nameInputField.nativeElement.focus();
    }, 100);
  }

  saveName() {
    if (this.nameInput.value.length > 0 && this.nameInput.value !== this.viewDefinition.label) {
      this.updateViewDefinition(this.viewDefinition, { label: this.nameInput.value });
    }
    this.editName = false;
  }

  async updateViewDefinition(viewDefinition: ViewDefinition, changes: any) {
    const adjustedChanges = this.collectionManagerViewService.getViewDefinitionWithRestrictedPropertiesAddedBack(
      viewDefinition.id,
      changes,
    );
    const updatedViewDefinition = await new Entities().update({
      entityName: 'view-definition',
      id: viewDefinition.id,
      object: adjustedChanges,
    });
    this.bindPropertiesToView(updatedViewDefinition);
    this.viewDefinitionUpdated.emit(updatedViewDefinition);
  }

  private bindPropertiesToView(viewDefinition: ViewDefinition) {
    viewDefinition.properties?.forEach((prop, index) => {
      const propDef = this.typeDefinition.typeProperties.find((p) => p.slug === prop.slug);
      prop.propertyDefinition = propDef;
    });
  }
}
