import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { ViewDefinition } from '@contrail/client-views';
import { collectionViewsEntityAdapter } from './collection-views.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = collectionViewsEntityAdapter.getSelectors();

const collectionManagerViews = (state: any) => state.collectionManager.viewDefinitions.entities;
export const selectCollectionViews: MemoizedSelector<
  any,
  ViewDefinition[],
  DefaultProjectorFn<ViewDefinition[]>
> = createSelector(collectionManagerViews, (state) => Object.values(state));
export const selectCurrentView = (state: any) => state.collectionManager.currentViewDefinition;
