import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ViewDefinition } from '@contrail/client-views';
import { Type, TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { MenuComponent } from '@components/menu/menu.component';

export interface PropertyConfiguration {
  enabled: boolean;
  typeProperty: TypeProperty;
  rootTypeSlug: string;
}

@Component({
  selector: 'app-view-property-configurator',
  templateUrl: './view-property-configurator.component.html',
  styleUrls: ['./view-property-configurator.component.scss'],
})
export class ViewPropertyConfiguratorComponent implements OnInit {
  @Input() disabled = false;
  @Input() toolTip = false;
  @ViewChild('propertyMenu') propertyMenu: MenuComponent;

  public typeMap$: Observable<{ [key: string]: Type }>;
  public currentView$: Observable<ViewDefinition>;
  constructor(private store: Store<RootStoreState.State>) {
    this.typeMap$ = this.store.select(CollectionManagerSelectors.typeDefinitions);
    this.currentView$ = this.store.select(CollectionManagerSelectors.selectCurrentView);
  }

  ngOnInit(): void {}
}
