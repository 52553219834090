import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { SelectedCellInfo } from '../context-menu.component';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { take, tap } from 'rxjs/operators';
import { Types } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { PlaceholderDropService } from '../../placeholders/placeholder-drop-service';

export class DropItemMenuAction extends ContextMenuAction {
  constructor(
    protected store: Store<RootStoreState.State>,
    protected placeholderDropService: PlaceholderDropService,
  ) {
    super(store);
    this.actionIcon = 'do_not_disturb_on_outline';
    this.actionLabel = 'Drop item(s)';
    this.iconClass = 'material-icons-outlined';
    this.testRef = 'app-menu-drop-action';
    this.shortCutKey = 'Ctrl-O';
  }

  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    this.placeholderDropService.toggleDropPlaceholders(ids);
  }

  canInvoke(editorMode: string, ids: Array<string>, selectedCellInfo: SelectedCellInfo = null) {
    if (!this.placeholderDropService.hasIsDroppedFlag) {
      return false;
    }

    if (editorMode !== 'EDIT') {
      return false;
    }

    if (ids.length === 1) {
      let singleElementDropped = false;
      this.store
        .select(CollectionManagerSelectors.selectCollectionElement(ids[0]))
        .pipe(
          take(1),
          tap((ph) => {
            singleElementDropped = ph.isDropped === true;
          }),
        )
        .subscribe();
      if (singleElementDropped) {
        return;
      }
    }

    return true;
  }
}
