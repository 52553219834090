<div data-test="view-template-list">
  <div class="my-2 font-bold">Select from template library</div>
  <div class="search-bar mb-2">
    <app-search-bar class="w-52" data-test="searchBar" [placeholder]="'Search'"></app-search-bar>
  </div>
  <div class="flex flex-col items-center" *ngIf="loading$ | async">
    <mat-spinner class="my-20" diameter="40"></mat-spinner>
    <!-- <div class="my-2">Loading Templates</div> -->
  </div>
  <div
    class="my-2 flex justify-between"
    *ngFor="let viewDef of filteredTemplates$ | async"
    data-test="template-library"
  >
    <div class="flex">
      <mat-checkbox
        class="ml-2 mr-3"
        [checked]="isSelected(viewDef.id)"
        (change)="toggle(viewDef)"
        [attr.data-test]="'checkbox-view-templates-' + viewDef.id"
      ></mat-checkbox>
      <app-view-list-item [viewDefinition]="viewDef"></app-view-list-item>
    </div>
    <mat-icon
      matTooltip="Delete template from the library."
      *ngIf="(role$ | async) === 'ADMIN'"
      class="cursor-pointer material-icons-outlined"
      (click)="deleteTemplate(viewDef)"
      >delete</mat-icon
    >
  </div>
  <div class="actions">
    <div class="cursor-pointer text-accent" (click)="handleCancel()" data-test="cancel-button">Cancel</div>
    <button
      class="small-button"
      [disabled]="!(selectedViewDefs?.size > 0)"
      mat-raised-button
      (click)="submit()"
      color="primary"
      data-test="submit-button"
    >
      Ok
    </button>
  </div>
</div>
