import { Types } from '@contrail/sdk';
import { PropertyType, PropertyValueFormatter, Type, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { GridViewManager } from '../grid-view.manager';
import { PasteDataInfo } from './paste-data-info';

const propertyValueFormatter: PropertyValueFormatter = new PropertyValueFormatter();

export class CopyPasteUtil {
  constructor(protected gridViewManager: GridViewManager) {}

  groupBy(cells, key) {
    return cells.reduce((cell, x) => {
      (cell[x[key]] = cell[x[key]] || []).push(x);
      return cell;
    }, {});
  }

  generatePasteDataInfo(
    clipboardTextData: string,
    data: any[],
    viewProperties: any[],
    selectedRows: any[],
    selectorCells: any[],
  ): PasteDataInfo {
    let delimiter = '\n';
    if (clipboardTextData.indexOf('\r\n') > -1) {
      delimiter = '\r\n';
    }
    let clipboardData = clipboardTextData.split(delimiter);
    if (delimiter === '\r\n' && clipboardData[clipboardData.length - 1] === '') {
      // when copying from excel, an extra empty row is copied
      clipboardData.splice(clipboardData.length - 1, 1);
    }
    // If destination rows or copied rows exist, we are pasting the clipboard data as rows.
    // This means that a copied row can be pasted on one single cell and the row of the cell will be populated with the copied row.
    // Otherwise, we are treating the clipboard data as individual cells.
    const targetColumns =
      selectedRows.length > 0 || clipboardData[0].split('\t').length === viewProperties.length
        ? viewProperties
        : this.getTargetViewProperties(clipboardData[0].split('\t').length, viewProperties);
    let targetRows = [];
    if (selectedRows.length > 0) {
      // paste rows
      if (selectedRows.length >= clipboardData.length) {
        targetRows = selectedRows;
        if (selectedRows.length > clipboardData.length) {
          clipboardData = this.synchClipboardDataWithDestinationRows(clipboardData, selectedRows.length);
        }
      } else {
        // number of destination rows are smaller from the copied rows.. Need to find the number of destination rows.
        targetRows = this.getTargetRows(clipboardData.length, data, selectedRows[0]);
      }
    } else {
      // paste cells
      if (selectorCells.length > clipboardData.length) {
        targetRows = Array.from(new Set(selectorCells.map((cell) => cell.rowId))); // get unique row ids
        clipboardData = this.synchClipboardDataWithDestinationRows(clipboardData, targetRows.length);
      } else {
        targetRows = this.getTargetRows(clipboardData.length, data);
      }
    }

    const localStorageClipboardData = JSON.parse(localStorage.getItem('clipboardData'));
    let localStorageRowIds = ObjectUtil.cloneDeep(localStorageClipboardData);
    if (localStorageClipboardData?.length < targetRows.length) {
      localStorageRowIds = this.synchClipboardDataWithDestinationRows(localStorageClipboardData, targetRows.length);
    }
    return { targetRows, targetColumns, clipboardData, localStorageRowIds };
  }

  // This function will push clipboard's row data into a new array so that its number matches with the number of destination rows.
  // Use case 1: A user copies one row to the clipboard and wants to paste the same row into 5 other rows.
  // Use case 2: A user copies one cell to the clipboard and wants to paste the same cell into 5 other cells vertically.
  protected synchClipboardDataWithDestinationRows(clipboardData: Array<any>, destinationRowCount: number) {
    const synchedClipboardData = [];
    let innerCount = 0;
    for (let i = 0; i < destinationRowCount; i++) {
      for (let j = innerCount; j < clipboardData.length; j++) {
        synchedClipboardData.push(clipboardData[j]);
        if (j === clipboardData.length - 1) {
          innerCount = 0;
        }
      }
    }
    return synchedClipboardData;
  }

  // generates a string delimited with \t and \n (e.g. '4243244\t645456\t56564\n66666765\t757776\t567758\n')
  async generateClipboardCellData(data, selectorCells, typeProperties: TypeProperty[]) {
    let clipboardData = '';
    const cellGroups = this.groupBy(selectorCells, 'rowId');
    const cellMap = {};
    const localStorageData = [];
    const keys = Object.keys(cellGroups);
    for (let i = 0; i < keys.length; i++) {
      const rowId = keys[i];
      const rowDataIndex = data.findIndex((row) => row.id === rowId);
      const rowData = data[rowDataIndex];
      let clipboardRowData = '';
      for (let index = 0; index < cellGroups[rowId].length; index++) {
        const cell = cellGroups[rowId][index];
        if (!cellMap[cell.columnId]) {
          const cellIndex = typeProperties.findIndex((property) => property.slug === cell.columnId);
          if (cellIndex > -1) {
            const propertyDefinition = typeProperties[cellIndex];
            cellMap[cell.columnId] = propertyDefinition;
          }
        }
        const cellValue = await this.getDataInRow(cellMap[cell.columnId], data[rowDataIndex]);
        clipboardRowData = clipboardRowData + (index > 0 ? '\t' : '') + (cellValue || '');
      }
      localStorageData.push(rowData.id);
      clipboardData = clipboardData + (i > 0 ? '\n' : '') + clipboardRowData; // copy and empty cell and add '\n' delimiter
    }
    localStorage.setItem('clipboardData', JSON.stringify(localStorageData));

    return clipboardData;
  }

  async generateClipboardRowsData(data, rowIds, typeProperties: TypeProperty[]) {
    let clipboardData = '';
    for (let rowIndex = 0; rowIndex < rowIds.length; rowIndex++) {
      const rowId = rowIds[rowIndex];
      let rowData = '';
      const currentRowData = data.filter((row) => row.id === rowId)[0];
      for (let propertyIndex = 0; propertyIndex < typeProperties.length; propertyIndex++) {
        const property = typeProperties[propertyIndex];
        const value = await this.getDataInRow(property, currentRowData);
        rowData = rowData + (propertyIndex > 0 ? '\t' : '') + (value || '');
      }
      clipboardData = clipboardData + (rowIndex > 0 ? '\n' : '') + rowData; // copy and empty cell and add '\n' delimiter
    }
    localStorage.setItem('clipboardData', JSON.stringify(rowIds));

    return clipboardData;
  }

  async getDataInRow(propertyDefinition: any, row: any) {
    let cellValue = row[propertyDefinition.slug];
    if (propertyDefinition.propertyType === PropertyType.TypeReference && row[propertyDefinition.slug + 'Id']) {
      cellValue = row[propertyDefinition.slug + 'Id'];
    }
    return this.getData(propertyDefinition, cellValue);
  }

  async getData(propertyDefinition: any, cellValue: any) {
    if (propertyDefinition.propertyType === PropertyType.TypeReference && cellValue) {
      const typeDef = await new Types().getType({ id: cellValue });
      cellValue = await this.getTypePath(typeDef);
    } else if (propertyDefinition.propertyType === 'image') {
      // don't copy image url to clipboard
      cellValue = '';
    }
    if (ObjectUtil.isObject(cellValue) && !(cellValue instanceof Date)) {
      const clonedCellValue = ObjectUtil.cloneDeep(cellValue);
      // don't like this hard-coding
      if (propertyDefinition.slug === 'itemOption') {
        cellValue = cellValue.optionName;
      } else if ([PropertyType.ObjectReference, PropertyType.UserList].includes(propertyDefinition.propertyType)) {
        cellValue = cellValue.email || cellValue.name;
      }

      if (!cellValue || PropertyType.SizeRange === propertyDefinition.propertyType) {
        cellValue = JSON.stringify(clonedCellValue);
      }
    }

    switch (propertyDefinition.propertyType) {
      // format display for the clipboard
      case PropertyType.Currency:
      case PropertyType.Percent:
      case PropertyType.Date:
      case PropertyType.SingleSelect:
      case PropertyType.MultiSelect:
        return propertyValueFormatter.formatValueForProperty(cellValue, propertyDefinition);
      default:
        return cellValue;
    }
  }

  private getTargetRows(rowNumber: number, data: any[], beginningRowId?: string) {
    const targetRows = [];
    let rowId;
    const selectedCell = this.gridViewManager.selectedCell;
    if (beginningRowId) {
      rowId = beginningRowId;
    } else {
      if (!selectedCell) {
        return;
      }
      rowId = selectedCell.rowId;
    }
    if (!rowId) {
      return;
    }
    const selectedRowIndex = data.findIndex((row) => row.id === rowId);

    for (let i = 0; i < rowNumber; i++) {
      const nextRowIndex = selectedRowIndex + i;
      if (data[nextRowIndex]) {
        targetRows.push(ObjectUtil.cloneDeep(data[nextRowIndex].id));
      }
    }
    return targetRows;
  }

  private getTargetViewProperties(columnNumber: number, viewProperties: any[]) {
    const columns = [];
    const selectedCell = this.gridViewManager.selectedCell;
    if (!selectedCell) {
      return;
    }
    const rowId = selectedCell.rowId;
    const propertySlug = selectedCell.propertySlug;
    if (!rowId || !propertySlug) {
      return;
    }
    const index = viewProperties.findIndex((viewProperty) => viewProperty.slug === propertySlug);
    for (let i = index; i < index + columnNumber; i++) {
      if (viewProperties[i]) {
        if (!viewProperties[i].enabled) {
          columnNumber = columnNumber + 1;
        } else {
          columns.push(viewProperties[i]);
        }
      }
    }
    return columns;
  }

  private async getTypePath(type: Type) {
    const types = type.typePath.split(':');
    let currentTypePath = '';
    let fullPathLabel = '';
    for (let i = 0; i < types.length; i++) {
      currentTypePath += (currentTypePath !== '' ? ':' : '') + types[i];
      const typeDef = await new Types().getType({ path: currentTypePath });
      fullPathLabel += (fullPathLabel !== '' ? '\\' : '') + typeDef.label;
    }
    return fullPathLabel;
  }
}
