import { TypePropertyFormComponent } from '@common/types/forms/type-property-form/type-property-form.component';
import { TypeConstraintsHelper, ValidationError, ValidatorFunctionProcessor } from '@contrail/type-validation';
import { PropertyType, Type } from '@contrail/types';

export class EntityFormHelper {
  /** Generates a list of errors for the given state of an entity. */
  public static async validatePropertyValues(type: Type, entity: any, context: any): Promise<any> {
    const errors = {};
    for (const property of type.typeProperties) {
      let allValidationErrors: ValidationError[] = [];
      let value = entity[property.slug];
      if (property.propertyType === PropertyType.ObjectReference) {
        value = entity[property.slug + 'Id'];
      }
      if (property.required && !value) {
        errors[property.slug] = 'Value is required';
      }
      if (property.validationFunction) {
        const validatorErrors: ValidationError[] = await ValidatorFunctionProcessor.processValidatorFunction(
          property.validationFunction,
          entity,
          context,
        );
        allValidationErrors = validatorErrors ?? [];
      }
      const validationErrors: ValidationError[] = await TypeConstraintsHelper.isValueLegalForEntity(
        type,
        property,
        entity,
        entity[property.slug],
      );
      allValidationErrors = allValidationErrors.concat(validationErrors);

      // validate size range (Note that this really shouldn't be here, it should be in our validation library..)
      // if (property.propertyType === PropertyType.SizeRange) {
      //   const sizeRangeValidationErrors: ValidationError[] = SizeRangeHelper.validateSizeRange(entity, property);
      //   if (sizeRangeValidationErrors.length > 0) {
      //     allValidationErrors = allValidationErrors.concat(sizeRangeValidationErrors);
      //   }
      // }

      if (allValidationErrors.length > 0) {
        errors[property.slug] = allValidationErrors[0].message; // just return the first error
      }
    }
    return errors;
  }

  /** Processes form state and determines if there
   * are any type based validation errors.
   */
  public static async getValidationErrors(form: TypePropertyFormComponent, type: Type): Promise<Array<any>> {
    const entityValues = EntityFormHelper.compileEntityValues(form);
    return EntityFormHelper.validatePropertyValues(type, entityValues, {});
  }

  /** Extracts form values into an object keyed by property.
   */
  public static compileEntityValues(form: TypePropertyFormComponent): any {
    const formFields = form.formFields;
    const entityValues = {};
    for (let field of formFields) {
      if (field.property.propertyType === PropertyType.ObjectReference) {
        entityValues[field.property.slug + 'Id'] = field.value?.id;
      } else {
        entityValues[field.property.slug] = field.value;
      }
    }
    return entityValues;
  }

  /**
   * Checks basic form validation rules ffrom a TypePropertyForm
   * */
  public static isFormValid(form: TypePropertyFormComponent): boolean {
    const formFields = form.formFields;
    let isFormInvalid = false;
    for (let field of formFields) {
      if (field.formFieldComponent.isInvalid()) {
        isFormInvalid = true;
      }
    }
    return isFormInvalid;
  }
}
