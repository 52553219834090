import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { RootStoreState } from 'src/app/root-store';
import { FeatureFlagActions } from '.';
import { FeatureFlagRegistryService } from './feature-flags.service';

@Injectable()
export class FeatureFlagsEffects {
  constructor(
    private actions$: Actions,
    private featureFlagService: FeatureFlagRegistryService,
    private store: Store<RootStoreState.State>,
  ) {}

  loadFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagActions.FeatureFlagsActionTypes.LOAD_FEATURE_FLAGS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.featureFlagService.getFeatureFlags()).pipe(
          map((data) => FeatureFlagActions.loadFeatureFlagsSuccess({ data })),
          catchError((error) => observableOf(FeatureFlagActions.loadFeatureFlagsFailure({ error }))),
        );
      }),
    ),
  );
}
