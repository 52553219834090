import { ElementRef, Injectable } from '@angular/core';
import { ViewDefinition, ViewPropertyConfiguration } from '@contrail/client-views';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { GridNavigationHandler } from '../grid-navigation-handler';
import { COLUMN_PADDING, DEFAULT_COLUMN_WIDTH, FIRST_COLUMN_LOCATION } from '../grid-view.manager';
interface BreakPointDefinition {
  start?: number;
  end?: number;
  width?: number;
  viewProperty?: ViewPropertyConfiguration;
}

@Injectable({
  providedIn: 'root',
})
export class HeaderColumnReorderService {
  private headerYCoord;
  private currentHeaderProperty: ViewPropertyConfiguration;
  private currentHeaderElement: HTMLElement;
  private dragStartEventLocation: any;
  private currentView: ViewDefinition;
  private currentColumnBreakPoint: BreakPointDefinition;
  private breakPoints: Array<BreakPointDefinition>;
  private breakPointMap: Map<string, BreakPointDefinition>;
  private horizontalScrollOffset: number;
  private targetBreakPoint: BreakPointDefinition;
  private leftPropertiesColumnWidth: number;
  private lastHorizontalScrollOffset = 0;

  constructor(
    private store: Store<RootStoreState.State>,
    private gridNavigationHandler: GridNavigationHandler,
  ) {
    this.store.select(CollectionManagerSelectors.selectCurrentView).subscribe((view) => {
      this.currentView = ObjectUtil.cloneDeep(view);
      this.computeColumnBreakPoints();
    });
    this.store.select(CollectionManagerSelectors.leftPropertiesTotalColumnWidth).subscribe((width) => {
      this.leftPropertiesColumnWidth = width;
    });

    this.store.select(CollectionManagerSelectors.scrollColumnMarginOffset).subscribe((offSet) => {
      this.horizontalScrollOffset = offSet;
    });
  }

  handleColumnHeaderDragStart(event, property: ViewPropertyConfiguration) {
    console.log('handleColumHeaderDragStart: ', event);
    this.currentHeaderProperty = property;
    this.currentColumnBreakPoint = this.breakPointMap.get(property.slug);
    const dragPreview = document.getElementById('column_header_preview_' + this.currentHeaderProperty.slug);
    this.currentHeaderElement = document.getElementById('column_header_' + this.currentHeaderProperty.slug);
    this.dragStartEventLocation = null;
    this.lastHorizontalScrollOffset = this.horizontalScrollOffset;
  }

  /** Responsilbe for updating DOM as a user drags a column header.
   * 1) Places the drag preview
   * 2) Places 'target guideline' that shows the user where the column would be dropped (vertical line)
   * This code needs to determine where the column would be dropped, while taking into account
   * what the current column set it, what the scroll offset is on the screen, and other considerations.
   */
  handleColumnHeaderDragMove(event, property: ViewPropertyConfiguration) {
    this.currentHeaderProperty = property;

    if (!this.headerYCoord) {
      const gridBodyBox = document.getElementById('grid-body').getBoundingClientRect();
      this.headerYCoord = gridBodyBox.y;
    }

    const dragPreview = document.getElementById('column_header_preview_' + this.currentHeaderProperty.slug);

    const xPos = event.pointerPosition.x;
    const currentColumnStartX = this.currentHeaderElement.getBoundingClientRect().x;

    // HANDLE START OF DRAG
    const width = this.currentHeaderProperty.width || DEFAULT_COLUMN_WIDTH;
    if (!this.dragStartEventLocation) {
      this.dragStartEventLocation = event.pointerPosition;
      dragPreview.style.width = `${width}px`;
    }

    // scroll if necessary
    this.gridNavigationHandler.checkForHorizontalScrollByPosition(xPos + 100, xPos - 5, 5);
    // HOW FAR HAVE WE MOVED
    const draggedDistance = xPos - this.dragStartEventLocation.x;

    // WHERE THE LEFT SIDE OF THE PREVIEW SHOULD BE, RELATIVE TO THE WINDOW
    const newPreviewXPosition =
      currentColumnStartX + draggedDistance + (this.horizontalScrollOffset - this.lastHorizontalScrollOffset);

    // WHERE IS THE LEFT SIDE OF THE PREVIEW SHOULD BE, RELATIVE TO THE GRID, ACCOUNTING FOR SCROLL OFFSET.
    const gridRelativePosition = this.currentColumnBreakPoint.start + draggedDistance;

    // Stop dragging and any logic in the case that we have a horizontal scroll active (frozen columns active)
    // Ideally, this situation would prompt an 'auto scroll situation', but we'll come back to this.
    if (this.horizontalScrollOffset > 0 && newPreviewXPosition < this.leftPropertiesColumnWidth) {
      console.log('dragged past freeze frame.');
      dragPreview.style.transform = `translate3d(${this.leftPropertiesColumnWidth}px, ${this.headerYCoord}px, 0)`;
      return;
    } else {
      dragPreview.style.transform = `translate3d(${newPreviewXPosition}px, ${this.headerYCoord}px, 0)`;
    }

    // Determine which column we would be swapping with.  This algorithm determines the mid point location of the drag preview
    // and looks for a column break point that fit within a range of that mid point.  The behavior is different goign left vs right
    const range = 25;
    const previewMidPoint =
      this.horizontalScrollOffset -
      this.lastHorizontalScrollOffset +
      gridRelativePosition +
      (this.currentHeaderProperty.width || DEFAULT_COLUMN_WIDTH) / 2;
    if (draggedDistance > 0) {
      // DRAGGING RIGHT
      this.targetBreakPoint = this.breakPoints.find(
        (bp) => previewMidPoint + range > bp.end && previewMidPoint - range < bp.end,
      );
    } else {
      // DRAGGING LEFT
      this.targetBreakPoint = this.breakPoints.find(
        (bp) => previewMidPoint + range > bp.start && previewMidPoint - range < bp.start,
      );
    }

    if (this.targetBreakPoint?.viewProperty.slug === this.currentHeaderProperty.slug) {
      this.targetBreakPoint = null;
    }

    /** Render the blue bar that indicates where the column will be dropped. */
    if (this.targetBreakPoint) {
      this.drawDropPreview(draggedDistance);
    } else {
      this.hideDropPreview();
    }
  }

  handleColumnHeaderDragDrop(event, property: ViewPropertyConfiguration) {
    console.log('handleColumHeaderDragDrop: ', event, ' target: ', this.targetBreakPoint);
    if (this.targetBreakPoint) {
      this.updateViewColumnOrder();
    }
    this.dragStartEventLocation = null;
    this.currentHeaderProperty = null;
    this.currentHeaderElement = null;
    this.hideDropPreview();
  }

  // Renders the line on the screen that shows where the column will go if
  drawDropPreview(draggedDistance) {
    if (!this.targetBreakPoint) {
      return;
    }
    const dropPreview = document.getElementById('colum_drop_preview');
    let screenLocation;
    if (draggedDistance < 0) {
      screenLocation = this.targetBreakPoint.start + FIRST_COLUMN_LOCATION - this.horizontalScrollOffset;
    } else {
      screenLocation = this.targetBreakPoint.end + FIRST_COLUMN_LOCATION - this.horizontalScrollOffset;
    }
    dropPreview.style.display = 'block';
    dropPreview.style.transform = `translate3d(${screenLocation}px, ${this.headerYCoord}px, 0)`;
  }
  hideDropPreview() {
    const dropPreview = document.getElementById('colum_drop_preview');
    dropPreview.style.display = 'none';
  }

  /** Updates  */
  updateViewColumnOrder() {
    console.log(
      'Reordering columns: target: ',
      this.targetBreakPoint.viewProperty.slug,
      ' source: ',
      this.currentHeaderProperty.slug,
      ' currentView: ',
      this.currentView,
    );
    const currentIndex = this.currentView.properties.findIndex(
      (prop) => prop.propertyDefinition.slug === this.currentHeaderProperty.slug,
    );
    const targetIndex = this.currentView.properties.findIndex(
      (prop) => prop.propertyDefinition.slug === this.targetBreakPoint.viewProperty.slug,
    );
    const frozenColumns = this.currentView.properties.filter((prop) => prop.frozen);
    if (frozenColumns.length > 0) {
      if (this.currentView.properties[currentIndex].frozen) {
        // this column was frozen
        if (targetIndex >= frozenColumns.length) {
          // moved to the regular column section
          this.currentView.properties[currentIndex].frozen = false;
        }
      } else {
        if (targetIndex < frozenColumns.length) {
          // moved to the frozen column section
          this.currentView.properties[currentIndex].frozen = true;
        }
      }
    }

    console.log('moving column: ', this.currentHeaderProperty.slug, ' from: ', currentIndex, ' to: ', targetIndex);
    this.arrayMove(this.currentView.properties, currentIndex, targetIndex);
    this.store.dispatch(
      CollectionManagerActions.updateViewDefinition({
        id: this.currentView.id,
        changes: { properties: this.currentView.properties },
      }),
    );
  }

  public hideColumn(slug) {
    const column = this.currentView.properties.find((prop) => prop.slug === slug);
    if (!column) {
      return;
    }
    column.enabled = false;
    this.store.dispatch(
      CollectionManagerActions.updateViewDefinition({
        id: this.currentView.id,
        changes: { properties: this.currentView.properties },
      }),
    );
  }

  private computeColumnBreakPoints() {
    if (!this.currentView) {
      return;
    }
    const breakPoints: Array<BreakPointDefinition> = [];
    const breakPointMap = new Map();
    let totalWidth = 0;
    for (const viewProperty of this.currentView.properties) {
      if (!viewProperty.enabled) {
        continue;
      }
      let columnWidth = viewProperty.width || DEFAULT_COLUMN_WIDTH;
      columnWidth += COLUMN_PADDING;
      const breakPoint: BreakPointDefinition = {
        viewProperty,
        start: totalWidth,
        width: columnWidth,
      };
      totalWidth += columnWidth;
      breakPoint.end = totalWidth;
      breakPoints.push(breakPoint);
      breakPointMap.set(viewProperty.slug, breakPoint);
    }
    this.breakPoints = breakPoints;
    this.breakPointMap = breakPointMap;
  }

  public arrayMove(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr; // for testing
  }
}
