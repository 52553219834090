import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions } from '../../collection-manager-store';
import { UndoRedoDetails } from '../undo-redo-objects';
import { BasicUndoRedoHandler } from './basic-undo-redo.handler';

export class CreatePlaceholderUndoRedoHandler implements BasicUndoRedoHandler {
  constructor(
    public undoRedoDetails: UndoRedoDetails,
    public store: Store<RootStoreState.State>,
  ) {}

  redoActions(): void {
    const entities = this.undoRedoDetails.changesList
      .filter((redo) => redo.objectType === 'placeholder')
      .map((redo) => {
        return { id: redo.id, ...redo.changes };
      });
    const rowOrder = this.undoRedoDetails.changesList
      .filter((redo) => redo.objectType === 'rowOrder')
      .map((redo) => redo.changes)[0];
    this.store.dispatch(
      CollectionManagerActions.createCollectionDataEntities({
        entities,
        targetRowIndex: rowOrder.targetRowIndex,
        skipRecordingUndoRedo: true,
      }),
    );
  }

  undoActions(): void {
    const ids = this.undoRedoDetails.changesList
      .filter((undo) => undo.objectType === 'placeholder')
      .map((undo) => undo.id);
    this.store.dispatch(CollectionManagerActions.deleteCollectionDataEntities({ ids, skipRecordingUndoRedo: true }));
  }
}
