import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserAnalyticsService } from '@common/analytics/user-analytics.service';
import { Store } from '@ngrx/store';
import { combineLatest, from, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CollectionManagerActions, CollectionManagerSelectors } from './collection-manager/collection-manager-store';
import { GridViewManager } from './collection-manager/grid-view/grid-view.manager';
import { PlansSelectors, RootStoreState } from './root-store';
import { FeatureFlagActions } from '@common/feature-flags';
import { AuthSelectors } from '@common/auth/auth-store';
import { PlanErrorHandlerService } from './collection-manager/errors/plan-error-handler.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public loaded$: Observable<boolean>;
  public message = '';
  constructor(
    private matIconRegistry: MatIconRegistry,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private store: Store<RootStoreState.State>,
    private gridViewManager: GridViewManager,
    private userAnalyticsService: UserAnalyticsService,
    private errorHandlerService: PlanErrorHandlerService,
  ) {
    this.store
      .select(AuthSelectors.selectAuthContext)
      .pipe(
        map((selectAuthContext) => {
          if (selectAuthContext?.currentOrg?.orgSlug) {
            this.store.dispatch(FeatureFlagActions.loadFeatureFlags());
          }
        }),
      )
      .subscribe();
    this.matIconRegistry.addSvgIcon(
      'plan-app-action',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/plan-app-action.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'more_actions',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/more_actions.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'sort_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/sort_icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'filter_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/filter_icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'list_view_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/list_view_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'show_hide_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/show_hide_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'vertical_separator_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/vertical_separator_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'add_new_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/add_new_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'export_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/export_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'dashboard_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/dashboard_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'notification_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/notification_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'alternate_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/alternate_icon.svg'),
    );
    /////

    this.matIconRegistry.addSvgIcon(
      'grid_active',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/grid_active.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'grid_inactive',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/grid_inactive.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'kanban_active',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/kanban_active.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'kanban_inactive',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/kanban_inactive.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'pivot_active',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/pivot_active.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'pivot_inactive',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/pivot_inactive.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'sort',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/sort_icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'matrix_active',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/matrix_active.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'matrix_inactive',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/matrix_inactive.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'comment-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/comment_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'comment_indicator',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/comment_indicator.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'comments_placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/comments_placeholder.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'warning-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/warning-placeholder.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'item-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/item-placeholder.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'img-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/img-placeholder.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'pin-column',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/pin_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'unpin-column',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/unpin_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'sort-ascending',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/sort_ascending_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'sort-descending',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/sort_descending_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'up-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/up_arrow_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'clear-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/clear_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'spread-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/spread_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'remove-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/remove_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'focus-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/focus_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'pivot-view-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/pivot_view_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'functions-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/functions_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'hierarchy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/hierarchy.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'override-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/override.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'drop-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/drop.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'item-copy-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/item-copy-icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'magic',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/magic.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'clipboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/clipboard.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'placeholder-item-option-image',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/image_item_option_placeholder_no_margin.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'placeholder-item-family-image',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/image_item_family_placeholder_no_margin.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'tag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/tag.svg'),
    );

    /////

    this.userAnalyticsService.setEventApplication('PLANS');
    this.errorHandlerService.subscribeToErrors();

    this.setLoadingIndicator();
    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.store = this.store;
    }
  }

  public setLoadingIndicator() {
    this.loaded$ = combineLatest([this.router.events.pipe(filter((event) => event instanceof NavigationStart))]).pipe(
      switchMap(([routerEvent]) => {
        console.log('AppComponent: loaded: ', routerEvent['url']);
        const url = routerEvent['url'];
        if (url?.indexOf('/plan/') > -1) {
          this.message = 'Loading your plan.';
          return this.getLoadPlanIndicator();
        } else if (url?.indexOf('/home') > -1) {
          this.message = 'Loading, one moment.';
          return this.getPlansHomeIndicator();
        } else {
          return from([true]);
        }
      }),
    );
  }
  public getLoadPlanIndicator() {
    return combineLatest([
      this.store.select(CollectionManagerSelectors.typeDefinitions),
      this.store.select(CollectionManagerSelectors.collectionElementsLoaded),
    ]).pipe(
      map(([typeDefs, elementsLoaded]) => {
        return !!typeDefs && elementsLoaded;
      }),
    );
  }
  public getPlansHomeIndicator() {
    return this.store.select(PlansSelectors.plansLoaded).pipe(
      map((loaded) => {
        return loaded;
      }),
    );
  }

  handleClick(event) {
    let shouldRemoveSelectedCell = true;
    let originatedFromStatusMessages = false;
    let originatedFromHeader = false;

    const eventPath = event?.composedPath()?.reverse() || [];
    for (const el of eventPath) {
      const tagName = el.tagName?.toLowerCase();
      const wasGridClick = ['app-data-cell', 'app-data-rows', 'app-spread-value'].includes(tagName);
      if (wasGridClick) {
        shouldRemoveSelectedCell = false;
      }

      const wasHeaderClick = ['app-header-bar'].includes(tagName);
      if (wasHeaderClick) {
        originatedFromHeader = true;
        shouldRemoveSelectedCell = false;
      }

      const wasClearableHeaderClick = [
        'app-filter-selector',
        'app-sort-selector',
        'app-view-property-configurator',
      ].includes(tagName);
      if (originatedFromHeader && wasClearableHeaderClick) {
        shouldRemoveSelectedCell = true;
      }

      if (['app-status-messages-list'].includes(el.tagName?.toLowerCase())) {
        originatedFromStatusMessages = true;
      }
    }

    if (shouldRemoveSelectedCell && this.gridViewManager.selectedCell) {
      // clicking anywhere other than cells should remove selected cell.
      this.gridViewManager.removeSelectedCell();
    }
    if (!originatedFromStatusMessages) {
      this.store.dispatch(CollectionManagerActions.setStatusMessageElement(null));
    }
    this.store.dispatch(CollectionManagerActions.setContextMenuActive({ contextMenuActive: false })); // close context menu if opened.
  }
}
