import { createSelector } from '@ngrx/store';
import { collectionElementEntityAdapter } from './collection-elements.state';
import { RootStoreState } from 'src/app/root-store';
import { CollectionDataEntity } from '../../collection-manager.service';

export const { selectAll, selectEntities, selectIds, selectTotal } = collectionElementEntityAdapter.getSelectors();

const collectionManagerEntities = (state: RootStoreState.State) => state.collectionManager.collectionElements.entities;
const filteredEntityIds = (state: RootStoreState.State) => state.collectionManager.collectionElements.filteredEntityIds;

export const selectCollectionData = createSelector(collectionManagerEntities, (state) => Object.values(state));

export const selectCollectionElement = (id: string) =>
  createSelector(collectionManagerEntities, (map) => {
    return map[id];
  });

export const selectFilteredCollectionElements = createSelector(
  selectCollectionData,
  filteredEntityIds,
  (allElements: CollectionDataEntity[], filteredIds: Set<string>) => {
    if (filteredIds === null) {
      return allElements;
    }
    return allElements.filter((element) => filteredIds.has(element.id));
  },
);
