import { PropertyType } from '@contrail/types';

export const ELIGIBLE_GROUP_PROPERTY_TYPES = [
  PropertyType.Date,
  PropertyType.SingleSelect,
  PropertyType.MultiSelect,
  PropertyType.String,
  PropertyType.Number,
  PropertyType.Currency,
  PropertyType.Formula,
  PropertyType.ObjectReference,
  PropertyType.Percent,
  PropertyType.Sequence,
];
