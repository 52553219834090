import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.scss'],
})
export class DevelopmentComponent implements OnInit {
  constructor() {}
  public entityReference = 'color:xHBjUbSvoh_OjheM';

  ngOnInit(): void {}

  log($event) {
    console.log('log event: ', $event);
  }
}
