import { createAction, props } from '@ngrx/store';
import { CollectionDataEntity } from '../../collection-manager.service';

export enum CollectionElementsActionTypes {
  LOAD_COLLECTION_DATA = '[CollectionManager] Load Collection Data',
  LOAD_COLLECTION_DATA_SUCCESS = '[CollectionManager] Load Collection Data Success',
  LOAD_COLLECTION_DATA_FAILURE = '[CollectionManager] Load Collection Data Failure',

  SET_COLLECTION_DATA = '[CollectionManager] Set Collection Data',

  CREATE_COLLECTION_DATA_ENTITY = '[CollectionManager] Create Collection Data Entity',
  CREATE_COLLECTION_DATA_ENTITY_SUCCESS = '[CollectionManager] Create Collection Data Entity Success',
  CREATE_COLLECTION_DATA_ENTITY_FAILURE = '[CollectionManager] Create Collection Data Entity Failure',

  CREATE_COLLECTION_DATA_ENTITIES_AT_INDICES = '[CollectionManager] Create Collection Data Entities at Indices',
  CREATE_COLLECTION_DATA_ENTITIES_AT_INDICES_COMPLETE = '[CollectionManager] Create Collection Data Entities at Indices Complete',

  CREATE_COLLECTION_DATA_ENTITIES = '[CollectionManager] Create Collection Data Entities',
  CREATE_COLLECTION_DATA_ENTITIES_SUCCESS = '[CollectionManager] Create Collection Data Entities Success',
  CREATE_COLLECTION_DATA_ENTITIES_FAILURE = '[CollectionManager] Create Collection Data Entities Failure',
  CREATE_COLLECTION_DATA_ENTITIES_COMPLETE = '[CollectionManager] Create Collection Data Entities Complete',

  DELETE_COLLECTION_DATA_ENTITY = '[CollectionManager] Delete Collection Data Entity',
  DELETE_COLLECTION_DATA_ENTITY_SUCCESS = '[CollectionManager] Delete Collection Data Entity Success',
  DELETE_COLLECTION_DATA_ENTITY_FAILURE = '[CollectionManager] Delete Collection Data Entity Failure',

  DELETE_COLLECTION_DATA_ENTITIES = '[CollectionManager] Delete Collection Data Entities',
  DELETE_COLLECTION_DATA_ENTITIES_SUCCESS = '[CollectionManager] Delete Collection Data Entities Success',
  DELETE_COLLECTION_DATA_ENTITIES_FAILURE = '[CollectionManager] Delete Collection Data Entities Failure',

  UPDATE_COLLECTION_DATA_ENTITY = '[CollectionManager] Update Collection Data Entity',
  UPDATE_COLLECTION_DATA_ENTITY_SUCCESS = '[CollectionManager] Update Collection Data Entity Success',
  UPDATE_COLLECTION_DATA_ENTITY_FAILURE = '[CollectionManager] Update Collection Data Entity Failure',

  UPDATE_COLLECTION_DATA_ENTITIES = '[CollectionManager] Update Collection Data Entities',
  UPDATE_COLLECTION_DATA_ENTITIES_SUCCESS = '[CollectionManager] Update Collection Data Entities Success',
  UPDATE_COLLECTION_DATA_ENTITIES_FAILURE = '[CollectionManager] Update Collection Data Entities Failure',
  UPDATE_COLLECTION_DATA_ENTITIES_COMPLETE = '[CollectionManager] Update Collection Data Entities Complete',

  APPLY_COLLECTION_ENTITY_CHANGES = '[CollectionManager] Apply Collection Entity Changes',
  APPLY_COLLECTION_ENTITY_CHANGES_SUCCESS = '[CollectionManager] Apply Collection Entity Changes Success',
  BATCH_APPLY_COLLECTION_ENTITY_CHANGES = '[CollectionManager] Batch Apply Collection Entity Changes',
  BATCH_APPLY_COLLECTION_ENTITY_CHANGES_SUCCESS = '[CollectionManager] Batch Apply Collection Entity Changes Success',
  ADD_COLLECTION_ENTITY = '[CollectionManager] Add Collection Entity',
  ADD_COLLECTION_ENTITIES = '[CollectionManager] Add Collection Entities',
  REMOVE_COLLECTION_ENTITY = '[CollectionManager] Remove Collection Entity',
  REMOVE_COLLECTION_ENTITIES = '[CollectionManager] Remove Collection Entities',
  SET_FILTERED_ENTITY_IDS = '[CollectionManager] Set Filtered Entity Ids',

  UPDATE_LIVE_DATA_TOPICS = '[CollectionManager] Update Live Data Topics',
  RESUBSCRIBE_ALL_LIVE_DATA_TOPICS = '[CollectionManager] Resubscribe all Live Data Topics',
}

export interface CreateCollectionEntitiesAtIndicesAction {
  entitiesByIndex: {
    entities: CollectionDataEntity[];
    index: number;
  }[];
}

////////////////////////////////////////////////////////////////////////////////

export const loadCollectionData = createAction(
  CollectionElementsActionTypes.LOAD_COLLECTION_DATA,
  props<{ id: string }>(),
);

export const loadCollectionDataSuccess = createAction(
  CollectionElementsActionTypes.LOAD_COLLECTION_DATA_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const setCollectionData = createAction(
  CollectionElementsActionTypes.SET_COLLECTION_DATA,
  props<{ data: Array<any> }>(),
);

export const loadCollectionDataFailure = createAction(
  CollectionElementsActionTypes.LOAD_COLLECTION_DATA_FAILURE,
  props<{ error: any }>(),
);
////////////////////////////////////////////////////////////////////////////////

export const createCollectionDataEntity = createAction(
  CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITY,
  props<{ entity: any }>(),
);

export const createCollectionDataEntitySuccess = createAction(
  CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITY_SUCCESS,
  props<{ entity: CollectionDataEntity }>(),
);

export const createCollectionDataEntityFailure = createAction(
  CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITY_FAILURE,
  props<{ error: any }>(),
);

export const createCollectionEntitiesAtIndices = createAction(
  CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITIES_AT_INDICES,
  props<CreateCollectionEntitiesAtIndicesAction>(),
);

export const createCollectionEntitiesAtIndicesComplete = createAction(
  CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITIES_AT_INDICES_COMPLETE,
);

export const createCollectionDataEntities = createAction(
  CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITIES,
  props<{ entities: any; targetRowIndex?: number; rowOrder?: any; skipRecordingUndoRedo?: boolean }>(),
);

export const createCollectionDataEntitiesSuccess = createAction(
  CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITIES_SUCCESS,
  props<{ entities: CollectionDataEntity[]; shouldAddToRowOrder?: boolean }>(),
);

export const createCollectionDataEntitiesFailure = createAction(
  CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITIES_FAILURE,
  props<{ error: any }>(),
);

export const createCollectionDataEntitiesComplete = createAction(
  CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITIES_COMPLETE,
);

export const addCollectionEntity = createAction(
  CollectionElementsActionTypes.ADD_COLLECTION_ENTITY,
  props<{ entity: CollectionDataEntity }>(),
);

export const addCollectionEntities = createAction(
  CollectionElementsActionTypes.ADD_COLLECTION_ENTITIES,
  props<{ entities: CollectionDataEntity[] }>(),
);
////////////////////////////////////////////////////////////////////////////////

export const updateCollectionDataEntity = createAction(
  CollectionElementsActionTypes.UPDATE_COLLECTION_DATA_ENTITY,
  props<{
    id: string;
    changes: CollectionDataEntity;
    skipRecordingUndoRedo?: boolean;
    selectedCell?: { rowId: string; propertySlug: string };
    broadcast?: boolean;
  }>(),
);

export const updateCollectionDataEntities = createAction(
  CollectionElementsActionTypes.UPDATE_COLLECTION_DATA_ENTITIES,
  props<{ entities: CollectionDataEntity[]; skipRecordingUndoRedo?: boolean; undoActionUuid?: string }>(),
);

export const updateCollectionDataEntitiesComplete = createAction(
  CollectionElementsActionTypes.UPDATE_COLLECTION_DATA_ENTITIES_COMPLETE,
);

export const updateCollectionDataEntitySuccess = createAction(
  CollectionElementsActionTypes.UPDATE_COLLECTION_DATA_ENTITY_SUCCESS,
  props<{ entity: CollectionDataEntity }>(),
);

export const updateCollectionDataEntityFailure = createAction(
  CollectionElementsActionTypes.UPDATE_COLLECTION_DATA_ENTITY_FAILURE,
  props<{ error: any }>(),
);

export const applyCollectionEntityChanges = createAction(
  CollectionElementsActionTypes.APPLY_COLLECTION_ENTITY_CHANGES,
  props<{ id; changes: CollectionDataEntity; broadcast: boolean; skipErrorValidation?: boolean }>(),
);

export const applyCollectionEntityChangesSuccess = createAction(
  CollectionElementsActionTypes.APPLY_COLLECTION_ENTITY_CHANGES_SUCCESS,
  props<{ id; changes: CollectionDataEntity; broadcast: boolean; skipErrorValidation?: boolean }>(),
);

export const batchApplyCollectionEntityChanges = createAction(
  CollectionElementsActionTypes.BATCH_APPLY_COLLECTION_ENTITY_CHANGES,
  props<{ changes: CollectionDataEntity[]; broadcast: boolean; skipErrorValidation?: boolean }>(),
);

export const batchApplyCollectionEntityChangesSuccess = createAction(
  CollectionElementsActionTypes.BATCH_APPLY_COLLECTION_ENTITY_CHANGES_SUCCESS,
  props<{ changes: CollectionDataEntity[]; broadcast: boolean; skipErrorValidation?: boolean }>(),
);
////////////////////////////////////////////////////////////////////////////////
export const deleteCollectionDataEntity = createAction(
  CollectionElementsActionTypes.DELETE_COLLECTION_DATA_ENTITY,
  props<{ entity: CollectionDataEntity }>(),
);

export const deleteCollectionDataEntitySuccess = createAction(
  CollectionElementsActionTypes.DELETE_COLLECTION_DATA_ENTITY_SUCCESS,
  props<{ entity: CollectionDataEntity }>(),
);

export const deleteCollectionDataEntityFailure = createAction(
  CollectionElementsActionTypes.DELETE_COLLECTION_DATA_ENTITY_FAILURE,
  props<{ error: any }>(),
);

export const deleteCollectionDataEntities = createAction(
  CollectionElementsActionTypes.DELETE_COLLECTION_DATA_ENTITIES,
  props<{ ids: Array<string>; skipRecordingUndoRedo?: boolean }>(),
);

export const deleteCollectionDataEntitiesSuccess = createAction(
  CollectionElementsActionTypes.DELETE_COLLECTION_DATA_ENTITIES_SUCCESS,
  props<{ ids: Array<string>; skipRecordingUndoRedo?: boolean }>(),
);

export const deleteCollectionDataEntitiesFailure = createAction(
  CollectionElementsActionTypes.DELETE_COLLECTION_DATA_ENTITIES_FAILURE,
  props<{ error: any }>(),
);

export const removeCollectionEntity = createAction(
  CollectionElementsActionTypes.REMOVE_COLLECTION_ENTITY,
  props<{ entity: CollectionDataEntity }>(),
);
export const removeCollectionEntities = createAction(
  CollectionElementsActionTypes.REMOVE_COLLECTION_ENTITIES,
  props<{ ids: Array<string> }>(),
);

export const setFilteredEntityIds = createAction(
  CollectionElementsActionTypes.SET_FILTERED_ENTITY_IDS,
  props<{ filteredEntityIds: Set<string> }>(),
);

export const updateSubscribedTopics = createAction(
  CollectionElementsActionTypes.UPDATE_LIVE_DATA_TOPICS,
  props<{ placeholders }>(),
);

export const resubscribeToAllTopics = createAction(
  CollectionElementsActionTypes.RESUBSCRIBE_ALL_LIVE_DATA_TOPICS,
  props<{ any }>(),
);
