import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Plan } from '../plans-store/plans.state';
import { ComparePlanModalComponent } from './compare-plan-modal/compare-plan-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ComparePlansModalLauncher {
  constructor(public dialog: MatDialog) {}

  openModal(plans: Array<Plan>): void {
    const dialogRef = this.dialog.open(ComparePlanModalComponent, {
      data: { plans },
      minWidth: '500px',
      minHeight: '500px',
      disableClose: false,
      autoFocus: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
