import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { AggregateHelper } from './aggregate-helper';
import { filter, tap } from 'rxjs/operators';
import { Type } from '@contrail/types';
import { PlanGoal } from '../../../plans/plans-store/plans.state';
import { FilterDefinition } from 'src/app/common/types/filters/filter-definition';
import { FilterObjects } from '@contrail/filters';

@Component({
  selector: 'app-summary-view',
  templateUrl: './summary-view.component.html',
  styleUrls: ['./summary-view.component.scss'],
})
export class SummaryViewComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter();

  constructor(private store: Store<RootStoreState.State>) {
    this.subscribe();
  }
  public editorMode = 'VIEW';
  public data: any;
  public count;
  public retailTotal = 0;
  public volumeTotal = 0;
  public itemCount = 0;
  public marginTotal = 0;
  public revenueTotal = 0;
  public aggregateGroupProperty;
  public aggregateValueProperty;

  public aggregates = {};
  public aggregatePropertiesKey = ['targetVolume', 'totalMargin', 'totalRevenue'];
  public aggregateProperties = [];

  public planGoals: Array<PlanGoal>;

  chartOptions = {
    chart: {},
    colors: ['#2085ec', '#72b4eb', '#0a417a', '#8464a0', '#cea9bc'],
    pieHole: 0.4,
    legend: 'none',
    backgroundColor: { fill: 'none' },
  };

  chartType: any = 'PieChart';
  chartData: Array<any>;

  private dataSub: Subscription;
  private editorModeSub: Subscription;
  private filterDefSub: Subscription;
  private typeMap: { [key: string]: Type };
  public filterDef: FilterDefinition;
  public filterData = true;
  ngOnInit() {
    this.subscribe();
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {
    this.dataSub = combineLatest([
      this.store.select(CollectionManagerSelectors.selectCollectionData),
      this.store.select(CollectionManagerSelectors.typeDefinitions),
      this.store.select(PlansSelectors.currentPlan),
    ])
      .pipe(
        filter(([data, typeMap, plan]) => {
          return data?.length && plan && typeMap && typeMap['plan-placeholder'] ? true : false;
        }),
        tap(([data, typeMap, plan]) => {
          this.data = data;
          this.typeMap = typeMap;
          this.planGoals = plan?.planGoals;
          this.aggregateGroupProperty = typeMap['plan-placeholder'].typeProperties.find((prop) => {
            return (
              (prop.slug === 'productConcept' || prop.slug === 'planningLevel2' || prop.slug === 'collection') &&
              prop.options
            );
          });
          this.computeValues();
        }),
      )
      .subscribe();

    this.editorModeSub = this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));

    this.filterDefSub = this.store.select(CollectionManagerSelectors.filterDefinition).subscribe((filterDef) => {
      this.filterDef = filterDef;
      this.computeValues();
    });
  }

  computeValues() {
    this.setChartData();
    const hasFilter = Boolean(this.filterData && this.filterDef?.filterCriteria);
    const data = hasFilter ? FilterObjects.filter(this.data, this.filterDef.filterCriteria) : this.data;
    this.count = data.length;
    this.itemCount = data.filter((item) => item.itemOption)?.length || 0;
    this.aggregateProperties = [];
    this.aggregatePropertiesKey.forEach((propSlug) => {
      const placeholderProperties = this.typeMap['plan-placeholder']?.typeProperties || [];
      const property = placeholderProperties.find((p) => p.slug === propSlug);
      if (property) {
        this.aggregateProperties.push(property);
        this.aggregates[propSlug] = AggregateHelper.getTotal(data, propSlug);
      }
    });
  }
  getTotal(index) {
    const total = this.data.reduce((a, b) => {
      if (b[index]) {
        return a + parseFloat(b[index]);
      }
      return a;
    }, 0);
    return total;
  }
  getGoalValue(propertySlug) {
    if (this.planGoals) {
      const goal = this.planGoals.find((goal) => goal.propertySlug === propertySlug);
      return goal?.targetValue;
    }
  }
  unsubscribe() {
    this.dataSub.unsubscribe();
    this.editorModeSub.unsubscribe();
    this.filterDefSub.unsubscribe();
  }
  setChartData() {
    if (this.aggregateGroupProperty) {
      const hasFilter = Boolean(this.filterData && this.filterDef?.filterCriteria);
      const sourceData = hasFilter ? FilterObjects.filter(this.data, this.filterDef.filterCriteria) : this.data;
      const groupingValues = this.aggregateGroupProperty.options.map((opt) => opt.value);
      const totals = AggregateHelper.getValueCounts(sourceData, this.aggregateGroupProperty.slug, groupingValues);
      console.log('totals: ', totals);
      // totals = Object.entries(totals).filter(([key, value]) => value > 0 );
      const data = [];
      Object.entries(totals).map(([key, value]: [string, number]) => {
        if (value > 0) {
          const option = this.aggregateGroupProperty.options.find((opt) => opt.value === key);
          data.push([option.display, value]);
        }
      });
      this.chartData = data;
    }
  }

  toggleFilterData(event) {
    this.filterData = !this.filterData;
    this.computeValues();
  }
}
