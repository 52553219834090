import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { escapeRegExp } from 'lodash';
import { Content, Entities } from '@contrail/sdk';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';

import { ConfirmationBoxService } from '@common/components/confirmation-box/confirmation-box';
import { SearchBarComponent } from '@common/components/search-bar/search-bar.component';
import { Item } from '@common/items/item';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ContentHolderService } from '../content-holder.service';

export interface IContent {
  id?: string;
  primaryFile?: any;
  largeViewable?: any;
  mediumViewable?: any;
}

@Component({
  selector: 'app-content-holder-details',
  templateUrl: './content-holder-details.component.html',
  styleUrls: ['./content-holder-details.component.scss'],
})
export class ContentHolderDetailsComponent implements OnChanges {
  contentHolderId: string;
  @Input() contentHolder: Item;
  @Input() editable = true;
  @Output() primaryViewableChanged = new EventEmitter();

  public is3d = false;
  public showroomConfigure = false;

  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  filterOptions = ['All', 'Img', '3D'];
  public filterControl = new UntypedFormControl('All');
  sortOptions = [
    { label: 'N/A', property: 'n/a' },
    { label: 'Date Created', property: 'createdOn' },
    { label: 'Date Modified', property: 'updatedOn' },
    { label: 'Type', property: 'type' },
  ];
  public sortByControl = new UntypedFormControl({ label: 'N/A', property: '' });

  public content$: Observable<Array<IContent>>;
  public selectedContent$: Observable<IContent>;
  public loading$: Observable<boolean>;
  public uploading$: Observable<boolean>;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private contentHolderService: ContentHolderService,
    private confirmationBoxService: ConfirmationBoxService,
  ) {
    this.selectedContent$ = this.contentHolderService.selectedContent$.pipe(
      tap((c) => {
        this.is3d = c?.primaryFile?.contentType?.indexOf('gltf-binary') > -1;
      }),
    );

    this.loading$ = this.contentHolderService.contentLoading$;
    this.uploading$ = this.contentHolderService.contentUploading$;
    this.subscriptions.push(
      this.contentHolderService.primaryViewableChanged.subscribe((content) => {
        this.primaryViewableChanged.emit(content);
      }),
    );
  }

  async ngOnChanges() {
    if (this.contentHolderId !== this.contentHolder.id) {
      this.contentHolderId = this.contentHolder.id;
    }
    if (this.contentHolderId && this.contentHolderId !== this.contentHolder.id) {
      console.error('HELLO___, Please let Rory know, if you see this console');
    }
    await this.contentHolderService.loadContentHolder(this.contentHolder, 'item:' + this.contentHolderId);
    this.showroomConfigure = false;
    this.initContenObservable();
  }

  async contentUploaded(evt) {
    const files = evt.files;
    await this.contentHolderService.addContentToContentHolder(files);
  }

  private initContenObservable() {
    this.content$ = combineLatest([
      this.contentHolderService.contentHolderContent$,
      this.searchBar.valueChange.pipe(startWith('')),
      this.filterControl.valueChanges.pipe(startWith('All')),
      this.sortByControl.valueChanges.pipe(startWith({ label: 'N/A', property: 'n/a' })),
    ]).pipe(
      map(([content, searchTerm, filter, sortByOption]) => {
        const sortByField = sortByOption?.property;
        if (!content) {
          return [];
        }
        return content
          .filter((c) => new RegExp(escapeRegExp(searchTerm), 'gi').test(c?.primaryFile?.fileName))
          .filter((c) => {
            switch (filter) {
              case 'All':
                return true;
              case 'Img':
                const isImage =
                  c?.primaryFile?.contentType?.indexOf('image') > -1 ||
                  c?.mediumViewable?.contentType?.indexOf('image') > -1;
                return isImage ? true : false;
              case '3D':
                const is3d = c?.primaryFile?.contentType?.indexOf('gltf-binary') > -1;
                return is3d ? true : false;
            }
          })
          .sort((content1, content2) => {
            switch (sortByField) {
              case 'createdOn':
              case 'updatedOn':
                return content2[sortByField] > content1[sortByField] ? 1 : -1;
              case 'type':
                return content2?.primaryFile?.contentType > content1?.primaryFile.contentType ? 1 : -1;
              default:
                return 1;
            }
          });
      }),
    );
  }

  // content-card functions -----------------------------------------
  public selectContent(content) {
    this.contentHolderService.selectContent(content);
    this.showroomConfigure = false;
  }

  async deleteContent(content) {
    if (!this.editable) {
      return;
    }
    const confirm = await this.confirmationBoxService.open(
      'Delete Content',
      'Are you sure you want to delete this content?',
    );
    if (!confirm) {
      return false;
    }
    this.contentHolderService.deleteContent(content);
    this.showroomConfigure = false;
  }

  isPrimaryViewable(content) {
    return this.contentHolderService.isPrimaryViewable(content);
  }
  public async updateItemContent(evt) {
    if (!this.editable) {
      return;
    }
    const item = evt?.item;
    if (evt?.remove) {
      await this.deleteContent(item);
    } else {
      if (evt?.primary) {
        this.makePrimaryViewable(item);
      }
    }
  }
  private async makePrimaryViewable(content) {
    if (!this.editable) {
      return;
    }
    this.contentHolderService.makePrimaryViewable(content);
  }

  drop(event: CdkDragDrop<string[]>) {
    // virtual scroll doesn't give us the real index as it only considers the elements displayed in the scroller.
    // the real current index must be calculated based on the realPrevious index.
    const realPreviousIndex = event.item.data;
    const realCurrentIndex = event.currentIndex + (realPreviousIndex - event.previousIndex);
    // const undoChangeDefinition = ObjectUtil.cloneDeep(this.group);
    // moveItemInArray(this.content, realPreviousIndex, realCurrentIndex);
    // TODO:
  }

  filterBy(option) {
    this.filterControl.setValue(option);
  }
  sortBy(option) {
    this.sortByControl.setValue(option);
  }

  downloadContent(content) {
    const fileUrl = content?.primaryFile?.downloadUrl;
    window.open(fileUrl, '_blank');
  }
}
