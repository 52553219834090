export enum UndoRedoActionType {
  CREATE_PLACEHOLDER = 'create_placeholder',
  UPDATE_PLACEHOLDER = 'update_placeholder',
  DELETE_PLACEHOLDER = 'delete_placeholder',
  UPDATE_ROW_ORDER = 'update_row_order',
}

export interface UndoRedoObject {
  id: string;
  changes: any;
  undoChanges: any;
  objectType?: string;
  scope?: string;
  level?: string;
  uuid?: string;
}

export interface UndoRedoDetails {
  actionType: UndoRedoActionType;
  changesList: UndoRedoObject[];
}
