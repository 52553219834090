import { Component, Input, OnInit } from '@angular/core';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { Plan } from 'src/app/plans/plans-store/plans.state';

@Component({
  selector: 'app-plan-column',
  templateUrl: './plan-column.component.html',
  styleUrls: ['./plan-column.component.scss'],
})
export class PlanColumnComponent implements OnInit {
  @Input() plan: Plan;
  @Input() compareToPlan: Plan;
  constructor(private routingService: RoutingService) {}

  ngOnInit(): void {}

  goToPlan() {
    this.routingService.go(`/plan/${this.plan.id}`);
  }
}
