import { Injectable } from '@angular/core';
import { AsyncErrorActionTypes } from './async-errors.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Injectable()
export class AsyncErrorsEffects {
  constructor(private actions$: Actions) {}

  addError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AsyncErrorActionTypes.ADD_ASYNC_ERROR_ENTITY),
        tap((action: any) => {}),
      ),
    { dispatch: false },
  );
}
