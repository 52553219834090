<button
  class="header-btn"
  [matMenuTriggerFor]="advancedMenu"
  mat-icon-button
  disableRipple="true"
  data-test="document-add-advanced"
  *ngIf="planAppExtensions?.length && isEditor"
>
  <mat-icon class="kabob-icon">more_vert</mat-icon>
</button>

<mat-menu #advancedMenu="matMenu" class="tool-menu-horizontal">
  <ng-template matMenuContent>
    <div class="advanced-menu">
      <button mat-menu-item *ngFor="let appExtension of planAppExtensions" (click)="launchAppExtension(appExtension)">
        {{ appExtension.name }}
      </button>
    </div>
  </ng-template>
</mat-menu>
