<ng-container *ngIf="!edit" [ngSwitch]="propertyType">
  <ng-container *ngSwitchCase="'object_reference'">
    <app-data-cell-view-object-reference
      *ngIf="value && value !== ''"
      class="view"
      [data]="data"
      [accessLevel]="editorMode"
      [selected]="selected"
      [value]="value"
      [property]="property"
    ></app-data-cell-view-object-reference>
  </ng-container>
  <ng-container *ngSwitchCase="'type_reference'">
    <app-data-cell-type-reference
      class="view"
      [data]="data"
      [accessLevel]="editorMode"
      [selected]="selected"
      [value]="data.itemTypeId"
      [property]="property"
    ></app-data-cell-type-reference>
  </ng-container>
  <ng-container *ngSwitchCase="'formula'">
    <app-data-cell-view-formula
      *ngIf="value && value !== ''"
      class="view"
      [data]="data"
      [accessLevel]="editorMode"
      [selected]="selected"
      [value]="value"
      [property]="property"
    ></app-data-cell-view-formula>
  </ng-container>
  <ng-container *ngSwitchCase="'text'">
    <app-data-cell-view-text-area
      *ngIf="value && value !== ''"
      class="view"
      [data]="data"
      [accessLevel]="editorMode"
      [selected]="selected"
      [value]="value"
      [property]="property"
    ></app-data-cell-view-text-area>
  </ng-container>

  <ng-container *ngSwitchCase="'size_range'">
    <app-data-cell-view-size-range
      class="view"
      [data]="data"
      [editable]="editable"
      [accessLevel]="editorMode"
      [selected]="selected"
      [value]="value"
      [property]="property"
    ></app-data-cell-view-size-range>
  </ng-container>

  <ng-container class="view" *ngSwitchCase="'date'">
    <div class="view" *ngIf="display && display !== ''">{{ display }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="'boolean'">
    <mat-icon *ngIf="display === 'Yes'">check</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'image'">
    <app-data-cell-view-image
      [value]="value"
      [previewUrl]="data[propertySlug + 'Preview']"
      class="image"
      *ngIf="value"
    ></app-data-cell-view-image>
    <ng-container *ngIf="!value && data.itemFamily">
      <mat-icon class="image">panorama</mat-icon>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'string'">
    <div class="view" *ngIf="display && display !== ''">{{ display }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="'choice'">
    <div class="view" *ngIf="display && display !== ''">{{ display }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="'multi_select'">
    <div class="view" *ngIf="display && display !== ''">{{ display }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="'userList'">
    <div class="view" *ngIf="display && display !== ''">{{ display }}</div>
  </ng-container>
  <ng-container *ngSwitchDefault>{{ display }}</ng-container>
</ng-container>

<app-data-cell-edit
  #dataCellEditor
  *ngIf="edit"
  [value]="value"
  [data]="data"
  [display]="display"
  [align]="align"
  [property]="property"
  [accessLevel]="editorMode"
  [valueCleared]="valueCleared"
  (focusOnCell)="handleFocusOnCell($event)"
  (endEditChange)="handleEndEdit()"
  (valueChange)="handleValueChange($event)"
>
</app-data-cell-edit>

<app-fill-cell-handle
  [rowId]="rowId"
  *ngIf="selected || isCellRangeSelected"
  [propertySlug]="propertySlug"
  [cellSelected]="selected"
></app-fill-cell-handle>

<div class="warnings" *ngIf="statusMessages?.length"></div>
<div
  class="overridden"
  *ngIf="isDataOverridden"
  [attr.data-test]="'overridden-' + property?.slug + '-' + rowId"
  [matTooltip]="'The option value is different from the item value: ' + getDisplay(overriddenValue)"
></div>
<div *ngIf="statusMessages?.length > 0" class="{{ selected ? 'selected' : '' }}}" class="warning-message">
  <div *ngFor="let message of statusMessages">
    {{ message.message }}
  </div>
</div>
<div class="comment-indicator" *ngIf="hasComment" (click)="showComments($event)">
  <mat-icon svgIcon="comment_indicator"></mat-icon>
</div>
<div class="locked-message" *ngIf="locked && selected && showLockedMessage">{{ lockedMessage }}</div>
