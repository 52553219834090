import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@common/auth/auth-store';
import { OrgMembership } from '@common/auth/auth.service';
import { RootStoreState } from 'src/app/root-store';
import { CollectionDataEntity } from '../collection-manager.service';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';

export interface CollectionElementActionCheck {
  isValid: boolean;
  reason?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CollectionElementActionValidator {
  private currentOrg: OrgMembership;
  private currentProjectId: string;

  constructor(private store: Store<RootStoreState.State>) {
    this.store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => {
      this.currentOrg = authContext?.currentOrg;
    });

    this.store.select(WorkspacesSelectors.currentWorkspace).subscribe((workspace) => {
      this.currentProjectId = workspace?.projectId;
    });
  }

  public isDroppable(collectionElement: CollectionDataEntity): CollectionElementActionCheck {
    if (collectionElement && this.currentOrg) {
      const context = { org: this.currentOrg, projectId: this.currentProjectId };
      const actionCheck = this.isNewBalanceRemoveActionValidated(collectionElement, context, 'dropped'); //TODO: replace with Action Validators when implemented
      return {
        isValid: actionCheck.isValid,
        reason: actionCheck.reason,
      };
    }

    return { isValid: true };
  }

  public isClearable(collectionElement: CollectionDataEntity): CollectionElementActionCheck {
    if (collectionElement && this.currentOrg) {
      const context = { org: this.currentOrg, projectId: this.currentProjectId };
      const actionCheck = this.isNewBalanceRemoveActionValidated(collectionElement, context, 'cleared'); //TODO: replace with Action Validators when implemented
      return {
        isValid: actionCheck.isValid,
        reason: actionCheck.reason,
      };
    }

    return { isValid: true };
  }

  public isDeletable(collectionElement: CollectionDataEntity): CollectionElementActionCheck {
    if (collectionElement && this.currentOrg) {
      const context = { org: this.currentOrg, projectId: this.currentProjectId };
      const actionCheck = this.isNewBalanceRemoveActionValidated(collectionElement, context, 'deleted'); //TODO: replace with Action Validators when implemented
      return {
        isValid: actionCheck.isValid,
        reason: actionCheck.reason,
      };
    }

    return { isValid: true };
  }

  public isItemFamilyAddable(itemFamily: CollectionDataEntity) {
    if (itemFamily && this.currentOrg) {
      const context = { org: this.currentOrg, projectId: this.currentProjectId };
      const actionCheck = this.isNewBalanceAddItemFamilyActionValidated(itemFamily, context); //TODO: replace with Action Validators when implemented
      return {
        isValid: actionCheck.isValid,
        reason: actionCheck.reason,
      };
    }

    return { isValid: true };
  }

  public isItemOptionAddable(itemOption: CollectionDataEntity, itemFamily: CollectionDataEntity) {
    if (itemFamily && this.currentOrg) {
      const context = { org: this.currentOrg, projectId: this.currentProjectId };
      const actionCheck = this.isNewBalanceAddItemOptionActionValidated(itemOption, itemFamily, context); //TODO: replace with Action Validators when implemented
      return {
        isValid: actionCheck.isValid,
        reason: actionCheck.reason,
      };
    }

    return { isValid: true };
  }

  //Hardcoded New Balance logic we will replace with admin console defined Action Validators in the future
  private newBalanceReadyForLEValue = 'nbReadyforLE';

  private isNewBalanceRemoveActionValidated(
    entity: CollectionDataEntity,
    context: { org: OrgMembership; projectId: string },
    actionType: string,
  ): CollectionElementActionCheck {
    const { org } = context;
    if (!this.isValidNewBalanceOrganization(org)) {
      return { isValid: true };
    }

    const familyLevelPushToLeValue = entity?.itemFamily?.projectItem?.nbReadyforLENonFootwear;
    const optionLevelPushToLeValue = entity?.itemOption?.projectItem?.nbReadyforLEFootwear;
    if ([familyLevelPushToLeValue, optionLevelPushToLeValue].includes(this.newBalanceReadyForLEValue)) {
      return {
        isValid: false,
        reason: `Ready for LE items cannot be ${actionType}.`,
      };
    }

    return { isValid: true };
  }

  private isNewBalanceAddItemFamilyActionValidated(
    itemFamily: CollectionDataEntity,
    context: { org: OrgMembership; projectId: string },
  ) {
    const { org, projectId } = context;
    if (!this.isValidNewBalanceOrganization(org)) {
      return { isValid: true };
    }

    const projectIdOfAddedItem = itemFamily?.projectItem?.projectId;
    if (projectIdOfAddedItem && projectIdOfAddedItem !== projectId) {
      return { isValid: true };
    }

    const familyLevelPushToLeValue = itemFamily?.projectItem?.nbReadyforLENonFootwear;
    if (familyLevelPushToLeValue === this.newBalanceReadyForLEValue) {
      return {
        isValid: false,
        reason: `Ready for LE items cannot be added to the plan.`,
      };
    }

    return { isValid: true };
  }

  private isNewBalanceAddItemOptionActionValidated(
    itemOption: CollectionDataEntity,
    itemFamily: CollectionDataEntity,
    context: { org: OrgMembership; projectId: string },
  ) {
    const { org, projectId } = context;
    if (!this.isValidNewBalanceOrganization(org)) {
      return { isValid: true };
    }

    const projectIdOfAddedItem = itemOption?.projectItem?.projectId;
    if (projectIdOfAddedItem && projectIdOfAddedItem !== projectId) {
      return { isValid: true };
    }

    const familyLevelPushToLeValue = itemFamily?.projectItem?.nbReadyforLENonFootwear;
    const optionLevelPushToLeValue = itemOption?.projectItem?.nbReadyforLEFootwear;
    if ([familyLevelPushToLeValue, optionLevelPushToLeValue].includes(this.newBalanceReadyForLEValue)) {
      return {
        isValid: false,
        reason: `Ready for LE items cannot be added to the plan.`,
      };
    }

    return { isValid: true };
  }

  private isValidNewBalanceOrganization(org: OrgMembership): boolean {
    const validNewBalanceOrgSlugs = [
      'demo',
      'lauren-zone-3',
      'new-balance-plm-sandbox-1',
      'nb-reimagine-dev-1',
      'nb-reimagine-qa-1',
    ];
    return validNewBalanceOrgSlugs.includes(org.orgSlug);
  }
}
