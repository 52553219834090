import { Injectable } from '@angular/core';
import { GridRowService } from './grid-row-service';
import { Store } from '@ngrx/store';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { ClipboardCopyPasteHandler } from './copy-paste/clipboard-copy-paste-handler';
import { GridNavigationHandler } from './grid-navigation-handler';
import { GridSelectorActionHandler } from './selectors/grid-selector-action-handler';
import { GridSelectorService } from './selectors/grid-selector.service';
import { GridViewManager } from './grid-view.manager';
import { AddCommentsService } from './add-comments-service';
import { PlanUndoRedoService } from '../undo-redo/plan-undo-redo.service';
import { SpreadValueHandler } from './spread/spread-value-handler';
import { FilterService } from '../filter/filter-service';
import { FocusedItemFamilyService } from '../focus-item/focused-item-family.service';
import { PlaceholderDropService } from '../placeholders/placeholder-drop-service';
import { OverrideOptionService } from './override-option/override-option-service';
import { SearchReplaceService } from './search-replace.service';
import { PlaceholderItemCopyService } from '../placeholders/placeholder-item-copy-service';
import { CopyPasteUtil } from './copy-paste/copy-paste-util';

@Injectable({
  providedIn: 'root',
})
export class GridViewKeyHandler {
  selectorCells = [];
  selectedRows = [];
  editorMode: string;
  restrictedEvents = ['KeyK', 'KeyO', 'KeyP', 'KeyQ', 'KeyX'];
  private copyPasteUtil: CopyPasteUtil;

  constructor(
    private gridNavigationHandler: GridNavigationHandler,
    private gridRowService: GridRowService,
    private addCommentsService: AddCommentsService,
    private copyPasteHandler: ClipboardCopyPasteHandler,
    private gridViewManager: GridViewManager,
    private gridSelectorHandler: GridSelectorService,
    private gridSelectorActionHandler: GridSelectorActionHandler,
    private undoRedoService: PlanUndoRedoService,
    private spreadValueHandler: SpreadValueHandler,
    private overrideOptionsService: OverrideOptionService,
    private filterService: FilterService,
    private focusedItemFamilyService: FocusedItemFamilyService,
    private searchReplaceService: SearchReplaceService,
    private placeholderDropService: PlaceholderDropService,
    private placeholderItemCopyService: PlaceholderItemCopyService,
    private store: Store<RootStoreState.State>,
  ) {
    this.store
      .select(CollectionManagerSelectors.selectorCells)
      .subscribe((selectorCells) => (this.selectorCells = selectorCells));
    this.store
      .select(CollectionManagerSelectors.selectedEntityIds)
      .subscribe((selectedRows) => (this.selectedRows = selectedRows));
    this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
    this.copyPasteUtil = new CopyPasteUtil(this.gridViewManager);
  }

  handleKeyPress(keyEvent: KeyboardEvent) {
    if (
      (keyEvent.code !== 'KeyG' && !this.gridViewManager.isEventAllowed(keyEvent)) ||
      this.isKeyRestricted(keyEvent)
    ) {
      return;
    }
    switch (keyEvent.code) {
      case 'Enter':
      case 'NumpadEnter': {
        if (!keyEvent.shiftKey) {
          this.gridNavigationHandler.handleEnter();
        } else {
          let rowIds = null;
          if (this.selectedRows.length > 0) {
            rowIds = this.selectedRows;
          } else if (this.gridViewManager.selectedCell) {
            rowIds = [this.gridViewManager.selectedCell.rowId];
          }
          this.gridRowService.addRowToCollection(1, rowIds, 'below');
        }
        break;
      }
      case 'Escape':
        this.gridNavigationHandler.handleEscape();
        this.gridSelectorHandler.removeCutCells();
        this.gridSelectorHandler.removeCopiedRows();
        this.overrideOptionsService.setOverrideOptionCells(true);
        break;
      case 'Tab':
        this.gridNavigationHandler.handleTab(keyEvent);
        break;
      case 'ArrowLeft':
        if (keyEvent.shiftKey) {
          this.gridSelectorHandler.selectCellsByKeyHorizontally('left');
        } else {
          this.gridSelectorHandler.removeSelector();
          this.gridNavigationHandler.selectToSide('left');
        }
        break;
      case 'ArrowRight': {
        if (keyEvent.shiftKey) {
          this.gridSelectorHandler.selectCellsByKeyHorizontally('right');
        } else {
          this.gridSelectorHandler.removeSelector();
          this.gridNavigationHandler.selectToSide('right');
        }
        break;
      }
      case 'ArrowDown':
        if (keyEvent.ctrlKey) {
          this.gridNavigationHandler.goToLastCellInColumn();
        } else if (keyEvent.shiftKey) {
          this.gridSelectorHandler.selectByKeyVertically('down');
        } else {
          this.gridSelectorHandler.removeSelector();
          this.gridSelectorHandler.removeCutCells();
          this.gridNavigationHandler.selectUpDown('down');
        }
        break;
      case 'ArrowUp':
        if (keyEvent.ctrlKey) {
          this.gridNavigationHandler.goToFirstCellInColumn();
        } else if (keyEvent.shiftKey) {
          this.gridSelectorHandler.selectByKeyVertically('up');
        } else {
          this.gridSelectorHandler.removeSelector();
          this.gridSelectorHandler.removeCutCells();
          this.gridNavigationHandler.selectUpDown('up');
        }
        break;
      case 'Delete':
      case 'Backspace':
        if (this.selectorCells.length > 1) {
          // delete multiple cells
          this.gridSelectorActionHandler.deleteCells();
        } else if (this.selectedRows.length > 0) {
          if (keyEvent.shiftKey) {
            // delete rows
            this.gridRowService.deleteRowFromCollection(this.selectedRows);
          } else {
            this.gridSelectorActionHandler.clearRowData(); // clear row data
          }
        } else {
          this.gridNavigationHandler.handleDelete();
        }
        break;
      case 'KeyC':
        if (keyEvent.ctrlKey || keyEvent.metaKey) {
          if (keyEvent.altKey) {
            const selectedIds = this.getSelectedRowIds();
            this.placeholderItemCopyService.copyPlaceholderItems(selectedIds);
            keyEvent.preventDefault();
          } else {
            console.log('RUNNING CMD-C');
            this.copyPasteHandler.handleDocumentCopyEvent();
            this.gridSelectorHandler.resetSelectionAnchorCell();
            this.gridSelectorHandler.removeCutCells();
            keyEvent.stopPropagation();
          }
        } else {
          console.log('RUNNING C');
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'KeyD': // copy/paste cell above
        if (keyEvent.ctrlKey || keyEvent.metaKey) {
          this.gridSelectorActionHandler.handlePasteDataFromCellsAbove();
          keyEvent.stopPropagation();
          keyEvent.preventDefault();
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'KeyE': // quick filter
        const selectedCell = this.gridViewManager.selectedCell;
        if (selectedCell && (keyEvent.ctrlKey || keyEvent.metaKey)) {
          if (this.filterService.isQuickFilterAllowed(selectedCell.propertySlug)) {
            this.filterService.applyQuickFilter(selectedCell.propertySlug, selectedCell.value);
          }
          keyEvent.stopPropagation();
          keyEvent.preventDefault();
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'KeyF': // find in plan
        if (keyEvent.ctrlKey || keyEvent.metaKey) {
          this.searchReplaceService.toggleSearchReplace();
          keyEvent.stopPropagation();
          keyEvent.preventDefault();
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'KeyG': // focus item
        if (keyEvent.ctrlKey || keyEvent.metaKey) {
          keyEvent.preventDefault();
          keyEvent.stopPropagation();
          this.focusedItemFamilyService.handleFocusedItemFamily(this.gridViewManager.selectedCell?.rowId);
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'KeyK': // override option
        if (keyEvent.ctrlKey || keyEvent.metaKey) {
          keyEvent.preventDefault();
          keyEvent.stopPropagation();
          this.overrideOptionsService.setOverrideOptionCells();
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'KeyM': // add comments
        if (keyEvent.ctrlKey) {
          this.addCommentsService.addComments();
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'KeyO': // drop placeholders
        if (keyEvent.ctrlKey) {
          keyEvent.preventDefault();
          this.placeholderDropService.toggleDropPlaceholders(this.selectedRows);
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'KeyP': // promotes placeholders
        if (keyEvent.ctrlKey || keyEvent.metaKey) {
          this.gridSelectorActionHandler.promotePlaceholders();
          keyEvent.preventDefault();
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'KeyQ': // spread function
        if (keyEvent.ctrlKey || keyEvent.metaKey) {
          if (this.spreadValueHandler.isSpreadActionAllowed()) {
            keyEvent.preventDefault();
            this.gridViewManager.selectedCell.endEdit(); // need to end edit so that changes are persisted first
            setTimeout(() => {
              this.spreadValueHandler.handleSpreadValue();
            }, 5);
          }
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'KeyX':
        if (keyEvent.ctrlKey || keyEvent.metaKey) {
          this.copyPasteHandler.handleDocumentCutEvent();
          this.gridSelectorHandler.removeSelector();
          keyEvent.stopPropagation();
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'KeyY':
        if (keyEvent.ctrlKey || keyEvent.metaKey) {
          this.undoRedoService.redoActions();
          this.gridSelectorHandler.resetSelectionAnchorCell();
          keyEvent.preventDefault();
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'KeyZ':
        if ((keyEvent.ctrlKey && keyEvent.shiftKey) || (keyEvent.metaKey && keyEvent.shiftKey)) {
          this.undoRedoService.redoActions();
          this.gridSelectorHandler.resetSelectionAnchorCell();
        } else if (keyEvent.ctrlKey || keyEvent.metaKey) {
          this.undoRedoService.undoActions();
          this.gridSelectorHandler.resetSelectionAnchorCell();
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'Space':
        if (keyEvent.shiftKey) {
          this.gridSelectorHandler.handleSelectRows();
        } else {
          this.gridNavigationHandler.handleInputKeyPress();
        }
        break;
      case 'ControlLeft':
      case 'ControlRight':
      case 'ShiftLeft':
      case 'ShiftRight':
      case 'MetaLeft':
      case 'MetaRight':
        this.gridSelectorHandler.setSelectionAnchorCell();
        break;
      case 'AltLeft':
      case 'AltRight':
        break;
      default:
        const code = keyEvent.code.toLowerCase();
        if (
          keyEvent.ctrlKey ||
          keyEvent.metaKey ||
          code.indexOf('control') > -1 ||
          code.indexOf('ctrl') > -1 ||
          code.indexOf('caps') > -1
        ) {
          return;
        }
        this.gridNavigationHandler.handleInputKeyPress();
    }
  }

  isKeyRestricted(keyEvent) {
    if (
      ['VIEW', 'COMMENT'].includes(this.editorMode) &&
      (keyEvent.ctrlKey || keyEvent.metaKey) &&
      this.restrictedEvents.includes(keyEvent.code)
    ) {
      keyEvent.preventDefault();
      return true;
    }
    return false;
  }

  private getSelectedRowIds(): string[] {
    if (this.selectedRows.length > 0) {
      return this.selectedRows;
    }

    if (this.selectorCells.length > 0) {
      const affectedCollectionElementRows = this.copyPasteUtil.groupBy(this.selectorCells, 'rowId');
      return Object.keys(affectedCollectionElementRows);
    }

    if (this.gridViewManager.selectedCell) {
      return [this.gridViewManager.selectedCell.rowId];
    }

    return [];
  }
}
