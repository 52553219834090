import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../collection-manager-store';
import { Type } from '@contrail/types';
import { GridViewManager } from './grid-view.manager';
import { CommentsActions } from 'src/app/common/comments/comments-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';

@Injectable({
  providedIn: 'root',
})
export class AddCommentsService {
  private editorMode: string = EditorMode.VIEW;

  constructor(
    private gridViewManager: GridViewManager,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
  }

  public async addComments() {
    if (this.editorMode === EditorMode.VIEW) return;

    const selectedCell = this.gridViewManager.selectedCell;
    if (selectedCell) {
      const selectedCellLocation = selectedCell.getBoundingRectangle();
      const slugTypeProperty = await this.getProperty(selectedCell.propertySlug);
      const property = {
        ...slugTypeProperty,
      };
      const value = selectedCell.value || '';
      let position = { x: 300, y: 300 };
      if (selectedCellLocation) {
        let xPosition = selectedCellLocation.x + selectedCellLocation.width;
        if (xPosition + 420 > window.innerWidth) {
          xPosition = window.innerWidth - 450;
        }
        position = {
          x: xPosition,
          y: selectedCellLocation.y,
        };
      }
      this.store.dispatch(
        CommentsActions.showCommentOverlay({
          ownerInfo: {
            entityType: 'plan-placeholder',
            id: selectedCell.rowId,
            property,
            value,
            suggestionValueEditable: selectedCell.editable,
          },
          position,
        }),
      );
    }
  }

  async getProperty(slug: string) {
    let prop;
    this.store
      .select(CollectionManagerSelectors.typeDefinitions)
      .pipe(
        filter((defs) => !!defs),
        take(1),
        map((defs: { [key: string]: Type }) => {
          const props = defs['plan-placeholder']?.typeProperties;
          prop = props?.find((p) => p.slug === slug);
        }),
      )
      .subscribe();
    return { ...prop };
  }
}
