import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleChartsModule } from '@common/angular-google-charts/google-charts.module';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { ItemChooserModule } from '../plan-item-chooser/plan-item-chooser.module';
import { CommentsModule } from '../common/comments/comments.module';
import { ComponentsModule } from '../common/components/components.module';
import { FilterModule } from '../common/components/filter/filter.module';
import { ExportsModule } from '../common/exports/exports.module';
import { TypesModule } from '../common/types/types.module';
import { CollectionManagerMessageHandler } from './collection-manager-message-handler';
import { CollectionManagerLiveDataHandler } from './collection-manager-live-data-handler';
import { CollectionManagerComponent } from './collection-manager.component';
import { CollectionViewsComponent } from './collection-views/collection-views.component';
import { ViewFormComponent } from './collection-views/view-form/view-form.component';
import { ViewActionsComponent } from './collection-views/view-actions/view-actions.component';
import { ViewPropertyConfiguratorListComponent } from './collection-views/view-property-configurator/view-property-configurator-list/view-property-configurator-list.component';
import { ViewPropertyDragDropListComponent } from './collection-views/view-property-configurator/view-property-drag-drop-list/view-property-drag-drop-list.component';
import { ViewPropertyConfiguratorComponent } from './collection-views/view-property-configurator/view-property-configurator.component';
import { ViewSelectorComponent } from './collection-views/view-selector/view-selector.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { DataGroupListItemComponent } from './data-group/data-group-list-item/data-group-list-item.component';
import { DataGroupComponent } from './data-group/data-group.component';
import { EmptyGroupToggleComponent } from './data-group/empty-group-toggle/empty-group-toggle.component';
import { FilterSelectorComponent } from './filter/filter-selector/filter-selector.component';
import { GridViewModule } from './grid-view/grid-view.module';
import { GroupBySelectorComponent } from './header-bar/group-by-selector/group-by-selector.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { KanbanViewComponent } from './kanban-view/kanban-view.component';
import { MatrixViewComponent } from './matrix-view/matrix-view.component';
import { SidePanelModule } from './side-panel/side-panel.module';
import { ViewModalComponent } from './collection-views/view-modal/view-modal.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { UndoRedoModule } from '../common/undo-redo/undo-redo-module';
import { ViewTemplateListComponent } from './collection-views/view-template-list/view-template-list.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ViewListItemComponent } from './collection-views/view-list-item/view-list-item.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SortSelectorComponent } from './sort/sort-selector.component';
import { SortModule } from '@common/components/sort/sort.module';
import { FocusedItemFamilyHeaderComponent } from './focus-item/focused-item-family-header/focused-item-family-header.component';
import { PipesModule } from '@common/pipes/pipes.module';
import { PivotViewComponent } from './pivot-view/pivot-view.component';
import { NoConnectionOverlayComponent } from '@common/web-socket/no-connection-overlay.component';
import { ImportViewsComponent } from './collection-views/import-views/import-views.component';
import { PromoteOptionModalComponent } from './placeholders/promote-option-modal/promote-option-modal.component';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { FolderBreadcrumbNavComponent } from '@common/item-data-chooser/item-data-source-selector/folder-breadcrumb-nav.component';
import { ImageAssignmentOptionsComponent } from './image-assignment/image-assignment-options.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { PlanAppExtensionsComponent } from './app-extensions/plan-app-extensions/plan-app-extensions.component';

@NgModule({
  declarations: [
    CollectionManagerComponent,
    HeaderBarComponent,
    MatrixViewComponent,
    GroupBySelectorComponent,
    ViewSelectorComponent,
    KanbanViewComponent,
    SortSelectorComponent,
    DataGroupComponent,
    DataGroupListItemComponent,
    ContextMenuComponent,
    ViewPropertyConfiguratorComponent,
    ViewPropertyConfiguratorListComponent,
    ViewPropertyDragDropListComponent,
    ViewFormComponent,
    CollectionViewsComponent,
    FilterSelectorComponent,
    ViewActionsComponent,
    EmptyGroupToggleComponent,
    ViewModalComponent,
    ViewTemplateListComponent,
    ViewListItemComponent,
    FocusedItemFamilyHeaderComponent,
    PivotViewComponent,
    ImportViewsComponent,
    PromoteOptionModalComponent,
    ImageAssignmentOptionsComponent,
    PlanAppExtensionsComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatDividerModule,
    GridViewModule,
    CommentsModule,
    GoogleChartsModule,
    VirtualScrollerModule,
    MatSlideToggleModule,
    TypesModule,
    ItemChooserModule,
    ExportsModule,
    MatBadgeModule,
    FilterModule,
    BrowserAnimationsModule,
    SidePanelModule,
    MatAutocompleteModule,
    UndoRedoModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    SortModule,
    PipesModule,
    NoConnectionOverlayComponent,
    FolderBreadcrumbNavComponent,
    MatDialogModule,
    MatRadioModule,
  ],
  exports: [CollectionManagerComponent, ImageAssignmentOptionsComponent],
})
export class CollectionManagerModule {
  constructor(
    private messageHandler: CollectionManagerMessageHandler,
    private liveDataMessageHandler: CollectionManagerLiveDataHandler,
  ) {}
}
