import { Component, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { RoutingService } from '@common/routing/routing.service';
import { CreatePlanComponent } from 'src/app/plans/create-plan/create-plan.component';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  navOptions = [
    {
      matIcon: false,
      icon: 'plan_icon_black.svg',
      iconActive: 'plan_icon_blue.svg',
      routerLink: this.routingService.getRouterLink('/home'),
      label: `All plans`,
    },
  ];

  constructor(
    private routingService: RoutingService,
    private matDialog: MatDialog,
  ) {}

  ngOnInit() {}

  createPlan() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.data = {};
    const dialogRef = this.matDialog.open(CreatePlanComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  goHome() {
    this.routingService.go('/home');
  }
  helpCenter() {
    window.open('https://intercom.help/vibesolutions/en/', '_blank');
  }
}
