import { ReplaySubject } from 'rxjs';
import { buffer, debounceTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { AssortmentHistoryService } from '@common/assortment-history/assortment-history.service';

const DEBOUNCE_TIME = 3000;

@Injectable({
  providedIn: 'root',
})
export class EntitySnapshotService {
  private createSnapshotSubject: ReplaySubject<string> = new ReplaySubject(1);

  constructor(private assortmentHistoryService: AssortmentHistoryService) {
    this.debounceCreateEntitySnapshot();
  }

  public async getEntitySnapshotById(id: string) {
    const snapshot = await new Entities().get({ entityName: 'entity-snapshot', id });
    if (snapshot.snapshotDownloadURL) {
      const response = await fetch(snapshot.snapshotDownloadURL);
      const snapshotDetails = await response.json();
      snapshot.snapshot = snapshotDetails;
    }
    return snapshot;
  }

  public async getEntitySnapshotsByEntityReference(entityReference: string) {
    return new Entities().get({
      entityName: 'entity-snapshot',
      criteria: { entityReference },
      relations: ['createdBy'],
    });
  }

  public async setAssortmentHistory(documentHistoryObjs: any[], assortmentId: string) {
    const assortmentHistory = await this.assortmentHistoryService.getAssortmentHistoryItems(assortmentId);

    documentHistoryObjs.forEach((historyObj) => {
      if (historyObj.assortmentChangeId) {
        const assortmentHistoryObj = assortmentHistory.find(
          (assortmentHistoryObj) => assortmentHistoryObj.id === historyObj.assortmentChangeId,
        );
        historyObj.publishName = assortmentHistoryObj.versionName;
      }
    });
  }

  public async createEntitySnapshot(entityReference: string) {
    return new Entities().create({ entityName: 'entity-snapshot', object: { entityReference } });
  }

  public async createEntitySnapshotWithDebounce(entityReference: string) {
    this.createSnapshotSubject.next(entityReference);
  }

  private async combineCreateEntitySnapshotRequests(entityReferences: Array<string>) {
    const uniqueEntityReferences = Array.from(new Set(entityReferences));
    for (const entityReference of uniqueEntityReferences) {
      await this.createEntitySnapshot(entityReference);
    }
  }

  private async debounceCreateEntitySnapshot() {
    // Observable that only emits a value if no additional value is added within DEBOUNCE_TIME
    const debounceAndCreateSnapshot$ = this.createSnapshotSubject.pipe(debounceTime(DEBOUNCE_TIME));

    // Observable which buffers any emitted values until debounceAndCreateSnapshot$ emits a value and closes the buffer
    const createSnapshot$ = this.createSnapshotSubject.pipe(buffer(debounceAndCreateSnapshot$));

    createSnapshot$.subscribe((entityReferences) => {
      this.combineCreateEntitySnapshotRequests(entityReferences);
    });
  }
}
