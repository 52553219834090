import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

@Component({
  selector: 'app-pivot-context-menu',
  templateUrl: './pivot-context-menu.component.html',
  styleUrls: ['./pivot-context-menu.component.scss'],
})
export class PivotContextMenuComponent {
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @Output() handleRowAction = new EventEmitter();
  contextMenuPosition = { x: '0px', y: '0px' };
  propLabel = '';
  rowData: any;

  constructor() {}

  async onContextMenu(event, rowData, propLabel) {
    event.stopPropagation();
    event.preventDefault();
    this.propLabel = propLabel;
    this.rowData = rowData;
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  closeContextMenu() {
    this.contextMenu.closeMenu();
  }

  expandSameLevelRows(event) {
    this.contextMenu.closeMenu();
    this.handleRowAction.emit({
      event: 'expand-rows',
      propLevel: this.rowData.level,
    });
  }

  collapseSameLevelRows(event) {
    this.contextMenu.closeMenu();
    this.handleRowAction.emit({
      event: 'collapse-rows',
      propLevel: this.rowData.level,
    });
  }
}
