import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { escapeRegExp } from 'lodash';
import { Types } from '@contrail/sdk';
import { TypeConstraintsHelper } from '@contrail/type-validation';
import { PropertyValueFormatter, TypePropertyOption, Type } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { map, startWith } from 'rxjs/operators';
import { DEFAULT_COLUMN_WIDTH } from 'src/app/collection-manager/grid-view/grid-view.manager';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { DataCellEditBaseComponent } from '../data-cell-edit-base-component';
@Component({
  selector: 'app-data-cell-edit-multi-select',

  template: ` <div
      class="select-edit"
      style="width:100%"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu"
      (menuOpened)="menuOpened()"
      (menuClosed)="menuClosed()"
    >
      <div>{{ display | shorten: maxDisplayLength }}</div>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <mat-menu #menu="matMenu" style="font-size: 11px; size: 11px;">
      <mat-selection-list #options (selectionChange)="setSelectionValues($event)">
        <mat-list-option class="filter-widget" (focus)="filterFocused()" (click)="$event.stopPropagation()">
          <app-search-bar [lgGray]="true" [placeholder]="'Search'"></app-search-bar>
        </mat-list-option>
        <mat-list-option
          class="list-option"
          [selected]="this.value?.includes(option.value)"
          [value]="option"
          checkboxPosition="before"
          (click)="$event.stopPropagation()"
          *ngFor="let option of filteredOptions"
        >
          {{ option.display }}
        </mat-list-option>
      </mat-selection-list>
      <!-- <button *ngFor="let option of property.propertyDefinition.options" mat-menu-item (click)="setOptionValue(option)">{{ option.display }}</button> -->
    </mat-menu>`,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        justify-content: flex-end;
        text-align: right;
      }
      :host ::ng-deep .filter-widget {
        display: none !important;
      }
      .select-edit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 20px;
        cursor: pointer;
        padding-bottom: 2px;
      }
      mat-list-option.list-option {
        font-size: 12px;
        height: 35px;
      }
      mat-list-option.list-option .mat-list-base {
        padding: 0 12px;
      }
      .filter-widget ::ng-deep .mat-pseudo-checkbox {
        display: none !important;
      }
    `,
  ],
})
export class DataCellEditMultiSelectComponent extends DataCellEditBaseComponent implements AfterViewInit, OnChanges {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('options', { static: false }) options: MatSelectionList;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  filteredOptions: Array<TypePropertyOption>;
  public maxDisplayLength;
  editing = false;
  isChangePending = false;

  constructor(private changeRef: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    this.subscribe();
  }

  ngOnChanges() {
    this.maxDisplayLength = ((this.property.width || DEFAULT_COLUMN_WIDTH) - 30) / 7;
  }

  async subscribe() {
    const options: Array<TypePropertyOption> = await this.getOptions();
    this.searchBar.valueChange
      .pipe(
        startWith(''),
        map((searchTerm) => {
          const keys = 'display';
          if (!options) {
            this.filteredOptions = [];
          } else {
            this.filteredOptions = options.filter((option) => {
              if (option.disabled) return false;
              return keys
                .split(',')
                .some(
                  (key) => option.hasOwnProperty(key) && new RegExp(escapeRegExp(searchTerm), 'gi').test(option[key]),
                );
            });

            this.searchBar.focus();
          }
          this.changeRef.detectChanges();
        }),
      )
      .subscribe();
  }

  private async getOptions(): Promise<Array<TypePropertyOption>> {
    let type: Type;
    if (this.data?.typeId) {
      type = await new Types().getType({ id: this.data.typeId });
    } else {
      type = await new Types().getByRootAndPath({ root: 'plan-placeholder', path: 'plan-placeholder' });
    }

    const eligibleOptions = await TypeConstraintsHelper.getValidOptionSets(
      type,
      this.property.propertyDefinition,
      this.data,
    );
    console.log('eligible options: ', eligibleOptions);
    return ObjectUtil.cloneDeep(eligibleOptions);
  }

  setSelectionValues($event) {
    console.log($event.source.selectedOptions.selected);
    const values = $event.source.selectedOptions.selected
      .filter((mapOption) => mapOption.value)
      .map((matOption) => matOption.value.value);
    console.log('setSelectionValues: ', values);
    this.value = values;
    this.display = new PropertyValueFormatter().formatValueForProperty(this.value, this.property.propertyDefinition);
    this.isChangePending = true;
  }

  stopPropagation(event) {
    if (event.key === 'Enter') {
      console.log('stop prog');
      event.stopPropagation();
    }
  }

  menuOpened() {
    this.editing = true;
    console.log(this.options);
    setTimeout(() => {
      this.searchBar.focus();
    }, 1);
  }

  menuClosed() {
    this.editing = false;
    if (this.isChangePending) {
      super.handleChange();
      this.isChangePending = false;
    }
  }

  filterFocused() {
    this.searchBar.focus();
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent = null) {
    if (event.composedPath()[0] instanceof HTMLBodyElement && !this.menuTrigger.menuOpen && !this.editing) {
      setTimeout(() => {
        this.menuTrigger.openMenu();
        this.searchBar.focus();
      }, 100);
    } else if (this.menuTrigger.menuOpen && this.editing) {
    }
    event.stopPropagation();
  }

  @HostListener('document:keydown.esc', ['$event'])
  handleEscapeKey(event: KeyboardEvent = null) {
    if (event.composedPath()[0] instanceof HTMLBodyElement && this.menuTrigger.menuOpen && this.editing) {
      this.menuTrigger.closeMenu();
    }
    event.stopPropagation();
  }

  @HostListener('document:keydown', ['$event'])
  handleEvents(event: KeyboardEvent = null) {
    if (this.editing) {
      event.stopPropagation(); // stop propagating key event to other listeners
    }
  }
}
