import { Component, Input, OnInit } from '@angular/core';
import { Plan } from '../../plans-store/plans.state';

@Component({
  selector: 'app-compare-plans',
  templateUrl: './compare-plans.component.html',
  styleUrls: ['./compare-plans.component.scss'],
})
export class ComparePlansComponent implements OnInit {
  @Input() plans: Array<Plan>;
  constructor() {}

  ngOnInit(): void {}
}
