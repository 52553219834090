import { ValidationError } from '@contrail/type-validation';
import { SizeRange, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';

export const ILLEGAL_SIZES_IN_SIZE_RANGE = 'This size range contains illegal sizes.';

export class SizeRangeHelper {
  public static sizeRangeTemplateProperty = 'sizeRangeTemplate';

  public static getConstrainingSizeRange(data: any) {
    let sizeRangeTemplate;
    if (data[this.sizeRangeTemplateProperty]) {
      sizeRangeTemplate = data[this.sizeRangeTemplateProperty].sizeRange;
    }
    if (data.itemOption) {
      if (data[this.sizeRangeTemplateProperty]) {
        sizeRangeTemplate = data[this.sizeRangeTemplateProperty].sizeRange;
      } else {
        if (data.itemFamily[this.sizeRangeTemplateProperty]) {
          sizeRangeTemplate = data.itemFamily[this.sizeRangeTemplateProperty].sizeRange;
        }
      }
    }

    if (!sizeRangeTemplate) {
      // get it from other objects?
    }

    if (sizeRangeTemplate) {
      return sizeRangeTemplate;
    }
    return null;
  }

  /**
   * Returns an array of sizes based on the selected sizes
   * @param sizeRange
   * @returns
   */
  public static getSizeRangeSizesList(sizeRange: SizeRange): Array<string> {
    if (!sizeRange) {
      return [];
    }
    if (Array.isArray(sizeRange.sizes)) {
      return sizeRange.sizes;
    } else {
      const sizes: Array<string> = [];
      Object.keys(sizeRange.sizes).forEach((dimension2Size) => {
        sizeRange.sizes[dimension2Size]?.forEach((size) => {
          sizes.push(dimension2Size + ' x ' + size);
        });
      });
      return sizes;
    }
  }
}
