import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { ClipboardCopyPasteHandler } from '../grid-view/copy-paste/clipboard-copy-paste-handler';
import { CommentContextMenuAction } from './context-menu-actions/comment-context-menu-action';
import { ContextMenuAction } from './context-menu-actions/context-menu-action';
import { DeleteContextMenuAction } from './context-menu-actions/delete-context-menu-action';
import { InsertRowMenuAction } from './context-menu-actions/insert-row-menu.action';
import { PromotContextMenuAction } from './context-menu-actions/promote-context-menu.action';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ClearDataContextMenuAction } from './context-menu-actions/clear-data-context-menu-action';
import { CopyContextMenuAction } from './context-menu-actions/copy-context-menu.action';
import { PasteContextMenuAction } from './context-menu-actions/paste-context-menu.action';
import { GridViewManager } from '../grid-view/grid-view.manager';
import { GridSelectorActionHandler } from '../grid-view/selectors/grid-selector-action-handler';
import { AddCommentsService } from '../grid-view/add-comments-service';
import { GridRowService } from '../grid-view/grid-row-service';
import { FocusItemFamilyMenuAction } from './context-menu-actions/focus-item-family-menu.action';
import { SpreadContextMenuAction } from './context-menu-actions/spread-context-menu.action';
import { SpreadValueHandler } from '../grid-view/spread/spread-value-handler';
import { QuickFilterContextMenuAction } from './context-menu-actions/quick-filter-context-menu.action';
import { FilterService } from '../filter/filter-service';
import { FocusedItemFamilyService } from '../focus-item/focused-item-family.service';
import { DropItemMenuAction } from './context-menu-actions/drop-item-menu.action';
import { UnDropItemMenuAction } from './context-menu-actions/un-drop-item-menu.action';
import { PlaceholderDropService } from '../placeholders/placeholder-drop-service';
import { PlaceholderItemPromoteService } from '../placeholders/placeholder-item-promote-service';
import { OverrideOptionContextMenuAction } from './context-menu-actions/override-option-context-menu-action';
import { OverrideOptionService } from '../grid-view/override-option/override-option-service';
import { CopyItemMenuAction } from './context-menu-actions/copy-item-menu.action';
import { PlaceholderItemCopyService } from '../placeholders/placeholder-item-copy-service';
import { AuthService } from '@common/auth/auth.service';
import { AddToClipboardMenuAction } from './context-menu-actions/add-to-clipboard-menu.action';
import { PlaceholderClipboardService } from '../placeholders/placeholder-clipboard-service';
export interface SelectedCellInfo {
  propertySlug?: string;
  entityId?: string;
  propertyValue?: string;
  propertyType?: string;
}
@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @ViewChild('viewOnlyTooltip', { static: true, read: MatTooltip }) tooltipComponent: MatTooltip;

  contextMenuPosition = { x: '0px', y: '0px' };

  public multiSelect$: Observable<boolean>;
  public isInFocusMode: boolean = false;
  private selectedIds: Array<string>;
  private hoveredId: string;
  private selectedProperty: SelectedCellInfo;
  public selectedProperty$: Observable<any>;
  private selectedElementLocation: DOMRect;
  public editorMode$: Observable<any>;
  public editorMode;
  public currentView: any;
  public actions = [];
  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<RootStoreState.State>,
    private placeholderItemPromoteService: PlaceholderItemPromoteService,
    private gridRowService: GridRowService,
    private copyPasteHandler: ClipboardCopyPasteHandler,
    private gridManagerService: GridViewManager,
    private selectorActionHandler: GridSelectorActionHandler,
    private addCommentsService: AddCommentsService,
    private spreadValueHandler: SpreadValueHandler,
    private snackBar: MatSnackBar,
    private filterService: FilterService,
    private focusedItemFamilyService: FocusedItemFamilyService,
    private overrideOptionService: OverrideOptionService,
    private placeholderDropService: PlaceholderDropService,
    private copyPlaceholderItemService: PlaceholderItemCopyService,
    private placeholderClipboardService: PlaceholderClipboardService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.store
        .select(CollectionManagerSelectors.selectedEntityIds)
        .pipe(
          tap((ids) => {
            this.selectedIds = ids;
          }),
          map((ids) => {
            return ids?.length > 1;
          }),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.store.select(CollectionManagerSelectors.hoveredEntityId).subscribe((id) => {
        this.hoveredId = id;
      }),
    );

    this.subscription.add(
      this.store.select(CollectionManagerSelectors.selectedProperty).subscribe((propInfo) => {
        this.selectedProperty = propInfo;
      }),
    );

    this.subscription.add(
      this.store.select(CollectionManagerSelectors.selectedElementLocation).subscribe((location) => {
        this.selectedElementLocation = location;
      }),
    );

    this.subscription.add(
      this.store.select(CollectionManagerSelectors.selectCurrentView).subscribe((view) => {
        this.currentView = view;
      }),
    );

    this.editorMode$ = this.store.select(PlansSelectors.editorMode);
    this.editorMode$.subscribe((mode) => (this.editorMode = mode));

    this.subscription.add(
      this.store.select(CollectionManagerSelectors.contextMenuActive).subscribe((contextMenuActive) => {
        if (!contextMenuActive && this.contextMenu) {
          this.contextMenu.closeMenu(); // close context menu if opened.
        }
      }),
    );

    this.subscription.add(
      this.store.select(CollectionManagerSelectors.focusedFamilyItem).subscribe((focusedFamilyItem) => {
        this.isInFocusMode = Boolean(!!focusedFamilyItem);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initActions() {
    this.actions = [];
    let ids = this.selectedIds?.length ? this.selectedIds : [this.hoveredId];
    ids = ids.filter((id) => !!id);

    console.log('initActions: ', this.currentView);

    if (!ids.length) {
      return;
    }
    if (this.currentView.viewType === 'grid') {
      this.actions.push(new PromotContextMenuAction(this.store, this.placeholderItemPromoteService));
      this.actions.push(new InsertRowMenuAction(this.store, this.gridRowService, 'above'));
      this.actions.push(new InsertRowMenuAction(this.store, this.gridRowService, 'below'));
      this.actions.push(new CopyContextMenuAction(this.store, this.copyPasteHandler));
      this.actions.push(new PasteContextMenuAction(this.store, this.copyPasteHandler, this.snackBar));
      this.actions.push(
        new ClearDataContextMenuAction(this.store, this.gridManagerService, this.selectorActionHandler),
      );
      this.actions.push(new DeleteContextMenuAction(this.store, this.currentView.viewType, this.gridRowService));
      this.actions.push(new CommentContextMenuAction(this.store, this.addCommentsService));
      this.actions.push(new SpreadContextMenuAction(this.store, this.spreadValueHandler));
      this.actions.push(
        new QuickFilterContextMenuAction(
          this.store,
          this.gridManagerService,
          this.filterService,
          null,
          this.authService.isAdmin(),
          this.currentView?.admin,
        ),
      );
      this.actions.push(new FocusItemFamilyMenuAction(this.store, this.focusedItemFamilyService));
      this.actions.push(new DropItemMenuAction(this.store, this.placeholderDropService));
      this.actions.push(new UnDropItemMenuAction(this.store, this.placeholderDropService));
      this.actions.push(
        new OverrideOptionContextMenuAction(this.store, this.gridManagerService, this.overrideOptionService),
      );
      this.actions.push(new CopyItemMenuAction(this.store, this.copyPlaceholderItemService));
      this.actions.push(new AddToClipboardMenuAction(this.store, this.placeholderClipboardService));
    } else {
      this.actions.push(new PromotContextMenuAction(this.store, this.placeholderItemPromoteService));
      this.actions.push(new DeleteContextMenuAction(this.store, this.currentView.viewType, this.gridRowService));
      this.actions.push(new DropItemMenuAction(this.store, this.placeholderDropService));
      this.actions.push(new UnDropItemMenuAction(this.store, this.placeholderDropService));
    }
  }

  /** Clears selected rows if context menu was on a non selected row
   */
  checkClearSelected() {
    if (this.selectedIds?.length && !this.selectedIds.includes(this.hoveredId)) {
      this.store.dispatch(CollectionManagerActions.clearSelectedEntityIds());
    }
  }
  async onContextMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    let ids = this.selectedIds?.length ? this.selectedIds : [this.hoveredId];
    ids = ids.filter((id) => !!id);
    console.log('onContextMenu IDS: ', ids);

    if (!ids.length) {
      return;
    }

    this.initActions();
    this.checkClearSelected();
    /*
    if (this.contextMenu.menuOpen){
      this.contextMenu.closeMenu();
    }*/
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menu.focusFirstItem('mouse');

    if (this.editorMode === 'VIEW') {
      const isNonTextPropertyType = [
        'object_reference',
        'type_reference',
        'size_range',
        'choice',
        'multi_select',
      ].includes(this.selectedProperty?.propertyType);
      if (!isNonTextPropertyType) {
        this.tooltipComponent.show();
        setTimeout(() => this.tooltipComponent.hide(4000));
      }
    } else {
      this.contextMenu.openMenu();
      this.store.dispatch(CollectionManagerActions.setContextMenuActive({ contextMenuActive: true }));
    }
  }
  async invoke(action: ContextMenuAction) {
    if (action.childActions) {
      return;
    }
    const ids = this.getIds();
    console.log('invoke IDS: ', ids);
    action.handleAction(this.editorMode, ids, this.selectedProperty, this.selectedElementLocation);
  }
  canInvoke(action: ContextMenuAction) {
    const ids = this.getIds();
    const canInvoke = action.canInvoke(this.editorMode, ids, this.selectedProperty, this.isInFocusMode);
    return canInvoke;
  }

  private getIds() {
    let ids = [];
    if (this.selectedIds?.length) {
      ids = this.selectedIds;
    } else if (this.gridManagerService.selectedCell?.rowId) {
      ids = [this.gridManagerService.selectedCell?.rowId];
    } else if (this.hoveredId) {
      ids = [this.hoveredId];
    }
    return ids;
  }
}
