import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  ElementRef,
} from '@angular/core';
import { Entities, EntityReference, SortOrderOptions } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { BehaviorSubject, Subject, of, from, Observable, firstValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { CollectionManagerActions } from '../../collection-manager-store';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { WorkspacesActions, WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { WorkspaceEntitiesHelperService } from '@common/workspaces/workspace-entities-helper.service';
import { SidePanelService } from '../../side-panel/side-panel.service';
import { ViewManagerService } from '@common/views/view-manager.service';

@Component({
  selector: 'app-import-views',
  templateUrl: './import-views.component.html',
  styleUrls: ['./import-views.component.scss'],
})
export class ImportViewsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() newViewSortOrder = 0;
  @Output() cancel = new EventEmitter();
  @Output() done = new EventEmitter();

  public viewDefinitions$: Subject<any> = new BehaviorSubject([]);
  public loadingViews$ = new BehaviorSubject(false);
  public step = 'select-plan';
  public selectedViewDefs = new Set();

  browseStep: 'WORKSPACE_SELECT' | 'DOCUMENT_SELECT' = 'DOCUMENT_SELECT';
  public selectedWorkspace: any;
  public sourceWorkspaceControl = new UntypedFormControl({ value: null, disabled: false }, Validators.required);
  public sourceWorkspaces: any[];
  public sourceWorkspaces$: Observable<Array<any>>;
  public transformedEntities$: Observable<Array<any>>;
  public documentLoading$: Observable<boolean>;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  private destroy$ = new Subject();
  focus: 'in' | 'out' = 'out';
  focusing = true;
  loading = false;
  moreLoading = false;
  searchKey: string = '';
  prevSearchTerm: string = '';
  count = 1;
  globalSearch = new FormControl<any>('');
  public plans$: Observable<any[]> = from([]);

  constructor(
    private store: Store<RootStoreState.State>,
    private workspaceEntitiesHelper: WorkspaceEntitiesHelperService,
    private sidePanelService: SidePanelService,
    private folderEntityService: WorkspaceEntitiesHelperService,
    private viewsService: ViewManagerService,
  ) {}

  ngOnInit(): void {
    this.sidePanelService.setCurrentView(null);
    this.sourceWorkspaces$ = this.store.select(WorkspacesSelectors.workspaces);
    this.sourceWorkspaces$
      .pipe(takeUntil(this.destroy$))
      .subscribe((sourceWorkspaces) => (this.sourceWorkspaces = sourceWorkspaces));
    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentWorkspace) => {
        if (this.sourceWorkspaces) {
          const workspace = this.sourceWorkspaces.find((sourceWorkspace) => currentWorkspace.id === sourceWorkspace.id);
          this.sourceWorkspaceControl.setValue(workspace);
          this.selectWorkspace();
        }
      });

    this.documentLoading$ = this.store.select(WorkspacesSelectors.currentWorkspaceFolderLoading);

    const entityLocation = this.folderEntityService?.entityLocationFolderView$.value; // goto current plan location (FolderBreadcrumbNavComponent)
    if (entityLocation) {
      this.folderEntityService.goToFolder(entityLocation);
    }
  }

  ngAfterViewInit(): void {
    this.globalSearch.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(async (inputValue) => {
        if (typeof inputValue === 'string') {
          this.loading = true;
          this.searchKey = inputValue;
          await this.getPlans(inputValue);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  // Browser Plans ------------------------------------------------------
  selectWorkspace() {
    this.selectedWorkspace = this.sourceWorkspaceControl.value;
    this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolder({ workspace: this.selectedWorkspace }));
    this.workspaceEntitiesHelper.rootWorkspace = this.selectedWorkspace;
    this.browseStep = 'DOCUMENT_SELECT';

    this.transformedEntities$ = this.store.select(WorkspacesSelectors.workspaceFolderEntities).pipe(
      map((entities) => {
        const transformed = [];
        entities.forEach((e) => {
          const listItem: any = ObjectUtil.cloneDeep(e);
          const ref = new EntityReference(listItem.entityReference);
          if (ref.entityType === 'workspace' || ref.entityType === 'plan') {
            if (ref.entityType === 'workspace') {
              listItem.isDropTarget = true;
              listItem.matIcon = 'folder_outline';
            }
            if (ref.entityType === 'plan') {
              listItem.icon = 'assets/images/plan_icon.svg';
            }
            transformed.push(listItem);
          }
        });
        transformed.sort((ent1, ent2) => {
          if (ent1?.matIcon && !ent2?.matIcon) {
            return -1;
          }
          if (ent1?.matIcon && ent2?.matIcon) {
            return ent1.name < ent2.name ? -1 : 1;
          }
          return ent2.name < ent1.name ? 1 : -1;
        });
        return transformed;
      }),
    );
  }

  chooseEntity(item) {
    if (item?.entity?.workspaceType === 'FOLDER') {
      this.workspaceEntitiesHelper.goToEntity(item);
    } else {
      this.step = 'select-views';
      this.loadViewDefinitions(item.entity.id);
    }
  }

  // Global Plan Search -------------------------------------------------
  async getPlans(key) {
    let searchTerm = key?.trim();
    if (!searchTerm) {
      this.loading = false;
      this.autocomplete.closePanel();
      return;
    }
    if (!searchTerm?.endsWith('*')) {
      searchTerm += '*';
    }

    if (this.prevSearchTerm !== searchTerm) {
      this.prevSearchTerm = searchTerm;
      const res = await firstValueFrom(this.getData(searchTerm, this.count));
      if (res.count > this.count) {
        this.plans$ = of(res.results);
        this.loading = false;
        this.moreLoading = true;
        const more = await firstValueFrom(this.getData(searchTerm, res.count - this.count, res.nextPageKey));
        this.plans$ = of([...res.results, ...more.results]);
        this.moreLoading = false;
      } else {
        this.plans$ = of(res.results);
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }

  getData(searchTerm, limit, next?) {
    return of(
      new Entities().get({
        entityName: 'open-search',
        search: searchTerm,
        take: limit,
        order: [{ order: SortOrderOptions.DESC, orderField: 'updatedOn' }],
        criteria: { entityType: 'global', entityTypes: ['plan'] },
        paginate: true,
        nextPageKey: next,
      }),
    );
  }

  onBlur() {
    setTimeout(() => {
      this.focusing = true;
    }, 250);
    this.focus = 'out';
  }
  onFocus() {
    this.focus = 'in';
    setTimeout(() => {
      this.focusing = false;
    }, 250);
  }
  clear(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.globalSearch.setValue('');
  }

  selectPlan(evt) {
    this.globalSearch.setValue(this.searchKey);
    const entity = evt.option.value;
    this.step = 'select-views';
    this.loadViewDefinitions(entity.id);
  }

  // SELECT VIEWS -------------------------------------------------------------
  async loadViewDefinitions(planId: string) {
    this.loadingViews$.next(true);
    const views = await this.viewsService.getViewDefinitions({
      applicationViewSlug: 'plan:plan_editor',
      contextReference: 'plan:' + planId,
    });

    let viewDefinitions = views;
    viewDefinitions = viewDefinitions.sort((v1, v2) => (v1.label > v2.label ? 1 : -1));
    this.viewDefinitions$.next(viewDefinitions);
    this.loadingViews$.next(false);
  }

  handleCancel() {
    this.cancel.emit();
  }
  handleBack() {
    this.step = 'select-plan';
  }

  isSelected(id) {
    return this.selectedViewDefs.has(id);
  }

  toggleSelected(viewDef) {
    if (this.selectedViewDefs.has(viewDef)) {
      this.selectedViewDefs.delete(viewDef);
    } else {
      this.selectedViewDefs.add(viewDef);
    }
    console.log('selectedIds.size: ', this.selectedViewDefs.size);
  }

  import() {
    const copies = ObjectUtil.cloneDeep(Array.from(this.selectedViewDefs));
    copies.forEach((newView) => {
      delete newView.id;
      delete newView.createdOn;
      delete newView.updatedOn;
      delete newView.createdById;
      delete newView.updatedById;
      delete newView.orgId;
      newView.sortOrder = this.newViewSortOrder++;
    });

    this.store.dispatch(CollectionManagerActions.createViewDefinitions({ viewDefinitions: copies }));
    this.done.emit();
  }
}
