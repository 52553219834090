import { Component, Input } from '@angular/core';
import { EntityHistoryEvent } from './entity-history-event';
import { EntityHistoryService } from './entity-history.service';

@Component({
  selector: 'app-entity-history',
  templateUrl: './entity-history.component.html',
  styleUrls: ['./entity-history.component.scss'],
})
export class EntityHistoryComponent {
  @Input() entity: any;
  public historyEvents: Array<EntityHistoryEvent>;

  constructor(private entityHistoryService: EntityHistoryService) {}

  ngOnChanges() {
    if (this.entity) {
      this.loadHistory();
    }
  }

  async loadHistory() {
    this.historyEvents = await this.entityHistoryService.getHistoryEventsForEntity(this.entity);
  }
}
