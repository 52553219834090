import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { SpreadValueHandler } from '../spread-value-handler';

@Component({
  selector: 'app-spread-value-menu',
  template: `<div *ngIf="isSpreadActionAllowed()">
    <button data-test="spread-value-menu-button" mat-button (click)="handleSpreadValue()">
      <mat-icon svgIcon="spread-icon"></mat-icon>
    </button>
  </div>`,
})
export class SpreadValueMenuComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<RootStoreState.State>,
    private spreadValueHandler: SpreadValueHandler,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  handleSpreadValue() {
    this.spreadValueHandler.handleSpreadValue();
  }

  isSpreadActionAllowed() {
    return this.spreadValueHandler.isSpreadActionAllowed();
  }
}
