import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../../collection-manager-store';

@Component({
  selector: 'app-horizontal-scroller',
  templateUrl: './horizontal-scroller.component.html',
  styleUrls: ['./horizontal-scroller.component.scss'],
})
export class HorizontalScrollerComponent implements AfterViewInit {
  constructor(private store: Store<RootStoreState.State>) {}
  @ViewChild('scrollbar') scrollBar: ElementRef;
  public scrollBarWidth$: Observable<number>;
  public sidePanelWidth$: Observable<number>;
  private scrollSubject = new Subject();
  public barSize = 300;
  private currentScrollPercent = 0;
  private isScrolling = false;
  scrollDragPosition = { x: 0, y: 0 };

  ngAfterViewInit(): void {
    this.scrollSubject
      .pipe(
        debounceTime(5),
        tap((event: any) => {
          this.handleScroll(event);
        }),
      )
      .subscribe();

    this.store.select(CollectionManagerSelectors.scrollHorizontalPercentage).subscribe((percentage) => {
      this.setScrollBarPosition(percentage);
    });

    this.sidePanelWidth$ = this.store.select(CollectionManagerSelectors.sidePanelWidth);

    this.initScrollBarWidth();
  }

  initScrollBarWidth() {
    this.scrollBarWidth$ = combineLatest([
      this.store.select(CollectionManagerSelectors.rightPropertiesViewPortWidth),
      this.store.select(CollectionManagerSelectors.rightPropertiesTotalColumnWidth),
    ]).pipe(
      map(([rightPropertiesViewPortWidth, rightPropertiesTotalColumnWidth]) => {
        if (rightPropertiesTotalColumnWidth <= rightPropertiesViewPortWidth) {
          return 0;
        }
        const viewedPercent = rightPropertiesViewPortWidth / rightPropertiesTotalColumnWidth;
        this.barSize = Math.ceil(rightPropertiesViewPortWidth) * viewedPercent;
        return this.barSize;
      }),
    );
  }

  setScroll(event) {
    this.scrollSubject.next(event);
  }

  /** Note, we need this to set the position when scroll is initiated by other events,
   * such as mouse wheel or cursor movement. But the scroll bar itself is updating the
   * percentage on drag, so we need to use caution that we don't do something recursive or
   * strange.
   */
  // not working yet
  setScrollBarPosition(scrollPercentage) {
    const scrollBar = document.querySelector('#horzScrollbar') as HTMLElement;
    if (scrollBar && !this.isScrolling) {
      const parentBox = scrollBar.parentElement.getBoundingClientRect();

      const availableDistance = Math.ceil(parentBox.width - this.barSize) - 10;
      const newDistance = availableDistance * scrollPercentage; // (this.singleCellWidth * scrollPercentage) + (leftCellWidth + availableDistance) * scrollPercentage;

      this.currentScrollPercent = scrollPercentage;
      this.scrollDragPosition = { x: newDistance, y: this.scrollDragPosition.y };
    }
    this.isScrolling = false;
  }

  handleScroll(event) {
    this.isScrolling = true;
    const element = event.source.getRootElement();
    const barBox = element.getBoundingClientRect();
    const parentBox = element.parentElement.getBoundingClientRect();
    const parentPosition = this.getPosition(element);

    const distanceFromLeft = Math.ceil(barBox.x - parentPosition.left);
    const availableDistance = Math.ceil(parentBox.width - this.barSize) - 10;
    let percent = Math.abs(Math.round((distanceFromLeft / availableDistance) * 100) / 100);
    percent = percent < 0 ? 0 : percent;
    percent = percent > 1 ? 1 : percent;

    this.currentScrollPercent = percent;
    this.store.dispatch(CollectionManagerActions.setScrollHorizontalPercentage({ percentage: percent }));
  }
  getPosition(el) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }

  handleClick(event) {
    const element = event.srcElement.childNodes[0];
    if (!element?.getBoundingClientRect()) {
      return;
    }

    const barBox = element.getBoundingClientRect();
    const parentBox = element.parentElement.getBoundingClientRect();
    let distanceFromLeft = 0;
    let factor = 1;
    // scroll right
    if (event.clientX >= barBox.x) {
      distanceFromLeft = Math.ceil(event.clientX - (barBox.x + this.barSize));
    } else {
      // scroll left
      distanceFromLeft = Math.ceil(barBox.x - event.clientX);
      factor = -1;
    }
    const availableDistance = Math.ceil(parentBox.width - this.barSize);

    let newScrollPercent =
      this.currentScrollPercent + Math.abs(Math.round((distanceFromLeft / availableDistance) * 100) / 100) * factor;
    if (newScrollPercent < 0.001) {
      newScrollPercent = 0;
    }
    this.currentScrollPercent = newScrollPercent;
    this.isScrolling = false;
    this.store.dispatch(CollectionManagerActions.setScrollHorizontalPercentage({ percentage: newScrollPercent }));
  }
}
