<div class="left">
  <div class="views header-options">
    <app-collection-views
      data-test="collection-views"
      (createdNewView)="openPropertyConfigurator()"
    ></app-collection-views>
    <mat-icon class="vertical-separator" svgIcon="vertical_separator_icon"></mat-icon>
  </div>
  <div class="settings header-options">
    <app-filter-selector
      [disabled]="isOptionDisabled"
      [toolTip]="(currentView$ | async)?.admin && !isOrgAdmin ? adminFilterTooltip : null"
    ></app-filter-selector>
    <!-- <app-group-by-selector></app-group-by-selector> -->
    <app-sort-selector
      [disabled]="isOptionDisabled"
      [toolTip]="(currentView$ | async)?.admin && !isOrgAdmin ? adminFilterTooltip : null"
      *ngIf="(currentView$ | async)?.viewType !== 'pivot'"
    ></app-sort-selector>
    <app-view-property-configurator
      [disabled]="isOptionDisabled"
      [toolTip]="(currentView$ | async)?.admin && !isOrgAdmin ? adminFilterTooltip : null"
      data-test="view-property-configurator"
      *ngIf="(currentView$ | async)?.viewType !== 'pivot'"
    ></app-view-property-configurator>
    <app-empty-group-toggle *ngIf="(currentView$ | async)?.viewType === 'kanban'"></app-empty-group-toggle>
    <mat-icon class="vertical-separator" svgIcon="vertical_separator_icon"></mat-icon>
    <div class="row-actions" *ngIf="(currentView$ | async)?.viewType !== 'pivot'">
      <div [matTooltip]="'Add Item'">
        <button
          mat-button
          (click)="loadSidePanel(productView)"
          *ngIf="(editorMode$ | async) === 'EDIT'"
          [attr.data-test]="'addItem-button'"
        >
          <mat-icon [svgIcon]="'add_new_icon'"></mat-icon>
        </button>
      </div>
      <div [matTooltip]="'Promote'">
        <button
          [disabled]="selectedIds.length === 0"
          mat-button
          (click)="promoteItems()"
          *ngIf="(editorMode$ | async) === 'EDIT'"
          [attr.data-test]="'promote-button'"
        >
          <mat-icon>bolt</mat-icon>
        </button>
      </div>
    </div>
    <div [matTooltip]="(currentView$ | async)?.admin && !isOrgAdmin ? adminFilterTooltip : null">
      <button
        (click)="showPivotConfigurator()"
        [disabled]="isOptionDisabled"
        mat-button
        [attr.data-test]="'pivot-config-button'"
        *ngIf="(currentView$ | async)?.viewType === 'pivot'"
      >
        <mat-icon>settings</mat-icon>
        <span
          [ngStyle]="{ opacity: !isOrgAdmin && (currentView$ | async)?.admin ? '0.3' : '1' }"
          class="text-black60 pr-1 pl-2"
          >Pivot Options</span
        >
      </button>
    </div>
    <div *ngIf="(currentView$ | async)?.viewType === 'pivot' && (showPivotGoals$ | async)">
      <mat-slide-toggle (change)="toggleShowGoals($event)">Show Goals</mat-slide-toggle>
    </div>
    <mat-icon
      class="vertical-separator"
      svgIcon="vertical_separator_icon"
      *ngIf="(editorMode$ | async) === 'EDIT'"
    ></mat-icon>
  </div>
  <div class="settings header-options export">
    <app-spread-value-menu [matTooltip]="'Spread values'"></app-spread-value-menu>
    <app-focused-item-family-header *ngIf="focusedFamilyItem$ | async"></app-focused-item-family-header>

    <app-excel-export
      [ownerId]="(owner$ | async)?.id"
      [ownerName]="(owner$ | async)?.name"
      entityName="plan-placeholder"
      [viewId]="(currentView$ | async)?.id"
      [viewName]="(currentView$ | async)?.label"
      [relations]="relations"
      [criteria]="{ planId: (owner$ | async)?.id }"
    >
    </app-excel-export>
    <app-download-content-indicator></app-download-content-indicator>

    <mat-icon
      class="vertical-separator"
      svgIcon="vertical_separator_icon"
      *ngIf="planAppExtensions?.length && (editorMode$ | async) === 'EDIT'"
    ></mat-icon>
    <app-plan-app-extensions
      [planAppExtensions]="planAppExtensions"
      *ngIf="planAppExtensions?.length"
    ></app-plan-app-extensions>
  </div>
</div>

<div class="right">
  <app-search-replace
    #searchReplace
    class="mr-2"
    [ngClass]="{ '!hidden': (currentView$ | async)?.viewType !== 'grid' }"
    [searchReplaceCells]="searchReplaceCells$ | async"
    (searchHandler)="searchForCells($event)"
    (setActiveCell)="setActiveSearchReplaceCell($event)"
    (closePanel)="closeSearchReplacePanel()"
  >
  </app-search-replace>
  <app-side-panel-options></app-side-panel-options>
</div>
