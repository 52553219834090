<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
>
  <div #viewOnlyTooltip matTooltip="The plan is in view only mode." matTooltipClass="view-only-tooltip"></div>
</div>
<mat-menu class="context-menu" #contextMenu="matMenu" [hasBackdrop]="false" [attr.data-test]="'context-menu'">
  <ng-template matMenuContent>
    <div *ngFor="let action of actions">
      <div *ngIf="action.childActions">
        <button
          [matMenuTriggerFor]="childMenu"
          mat-menu-item
          (click)="invoke(action)"
          *ngIf="canInvoke(action)"
          [attr.data-test]="action.testRef"
        >
          <div class="action">
            <div class="action-button">
              <mat-icon
                [ngClass]="action.iconClass"
                *ngIf="action.svgActionIcon"
                [svgIcon]="action.svgActionIcon"
              ></mat-icon>
              <mat-icon [ngClass]="action.iconClass" *ngIf="action.actionIcon">{{ action.actionIcon }}</mat-icon>
              <span>{{ action.actionLabel }}</span>
            </div>
            <div *ngIf="action.shortCutKey">{{ action.shortCutKey }}</div>
          </div>
        </button>
        <mat-menu #childMenu="matMenu" class="context-menu">
          <div [attr.data-test]="childAction.testRef" *ngFor="let childAction of action.childActions">
            <button mat-menu-item (click)="invoke(childAction)">{{ childAction.actionLabel }}</button>
          </div>
        </mat-menu>
      </div>
      <div *ngIf="!action.childActions">
        <button mat-menu-item (click)="invoke(action)" *ngIf="canInvoke(action)" [attr.data-test]="action.testRef">
          <div class="action">
            <div class="action-button">
              <mat-icon
                [ngClass]="action.iconClass"
                *ngIf="action.svgActionIcon"
                [svgIcon]="action.svgActionIcon"
              ></mat-icon>
              <mat-icon [ngClass]="action.iconClass" *ngIf="action.actionIcon">{{ action.actionIcon }}</mat-icon>
              <span>{{ action.actionLabel }}</span>
            </div>
            <div *ngIf="action.shortCutKey">{{ action.shortCutKey }}</div>
          </div>
        </button>
      </div>
    </div>

    <!-- <button *ngIf="(selectedProperty$ | async) && !(multiSelect$ | async) && editorMode !== 'VIEW'" mat-menu-item (click)="invoke('COMMENT')">
    <mat-icon class="icons">comment</mat-icon>
    <span>Comment on cell</span>
  </button> -->
  </ng-template>
</mat-menu>
