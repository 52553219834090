import { SelectedCellInfo } from '../context-menu.component';
import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { GridViewManager } from '../../grid-view/grid-view.manager';
import { OverrideOptionService } from '../../grid-view/override-option/override-option-service';

export class OverrideOptionContextMenuAction extends ContextMenuAction {
  private selectedCells = [];
  private selectedRows = [];
  constructor(
    protected store: Store<RootStoreState.State>,
    protected gridViewManager: GridViewManager,
    protected unlinkOptionService: OverrideOptionService,
  ) {
    super(store);
    this.svgActionIcon = 'override-icon';
    this.actionLabel = 'Override mode';
    this.shortCutKey = 'Ctrl-K';
    this.testRef = 'app-menu-override-option-action';
    this.store.select(CollectionManagerSelectors.selectedEntityIds).subscribe((selectedEntityIds) => {
      this.selectedRows = selectedEntityIds;
      if (selectedEntityIds.length > 0) {
        this.actionLabel = 'Override mode';
      }
    });
    this.store
      .select(CollectionManagerSelectors.selectorCells)
      .subscribe((selectedCells) => (this.selectedCells = selectedCells));
  }
  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    this.unlinkOptionService.setOverrideOptionCells();
  }

  canInvoke(editorMode: string, ids: Array<string>, selectedCellInfo: SelectedCellInfo = null) {
    if (editorMode !== 'EDIT') {
      return false;
    }
    if (!ids?.length) {
      return false;
    }

    if (this.selectedRows?.length > 0) {
      return false;
    }

    return true;
  }
}
