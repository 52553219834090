import { Component, Input, OnChanges } from '@angular/core';
import { PropertyValueFormatter } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { ListColumnDefinition, ListColumnRenderType } from '../list-column-definition';
import { ListItemData } from '../list-item-data';

@Component({
  selector: 'app-list-item-cell',
  templateUrl: './list-item-cell.component.html',
  styleUrls: ['./list-item-cell.component.scss'],
})
export class ListItemCellComponent implements OnChanges {
  private propertyValueFormatter = new PropertyValueFormatter();
  @Input() listItemData: ListItemData;
  @Input() columnDefinition: ListColumnDefinition;
  @Input() shortenCellCharacterLimit: number;

  public value;
  public display;
  public isImage: boolean;
  public isIcon: boolean;
  constructor() {}

  ngOnChanges(): void {
    if (this.columnDefinition.propertyType && this.columnDefinition.index) {
      this.value = ObjectUtil.getByPath(this.listItemData, this.columnDefinition.index);
      this.display = this.propertyValueFormatter.formatValue(
        this.value,
        this.columnDefinition.propertyType,
        {
          format: this.columnDefinition.numberFormat?.format,
          precision: this.columnDefinition.numberFormat?.decimalPlaces,
        },
        this.columnDefinition.dateFormat,
      );

      this.isIcon = this.columnDefinition?.propertyType === 'icon';
      this.isImage = this.shouldRenderCellAsImage();
    }
  }

  public shouldRenderCellAsImage(): boolean {
    if (this.isIcon) {
      return true;
    }

    const isMaybeImageUrl = this.columnDefinition.renderType === ListColumnRenderType.TEXT_OR_IMAGE;
    if (isMaybeImageUrl) {
      return this.isValidImageUrl(this.value);
    }

    return false;
  }

  private isValidImageUrl(value: string): boolean {
    if (!value || typeof value !== 'string') {
      return false;
    }

    return /^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|webp|svg)(\?.*)?)$/i.test(value);
  }
}
