<div data-test="item-details-header" class="flex flex-col w-full h-full px-3">
  <div class="flex-between-center h-[50px]">
    <div class="header-left">
      <span
        *ngIf="hasStatus"
        class="item-indicator min-w-[16px] h-4 rounded-2xl mr-3 bg-warn-light"
        [style.background-color]="statusHexColor"
      >
      </span>
      <h6 class="text-primary">ITEM-{{ item?.itemNumber }}</h6>
      <h6 class="text-black mx-3">{{ item?.name }}</h6>
      <h6 class="text-grey">{{ item?.optionName }}</h6>

      <button class="!ml-1 btn-32 hover:scale-110" mat-button [matMenuTriggerFor]="beforeMenu">
        <span class="material-icons-outlined opacity-80"> arrow_drop_down </span>
      </button>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <div class="w-[274px]">
          <app-item-family
            [selectedItem]="item"
            [itemFamily]="itemFamily"
            [itemOptions]="itemOptions"
            (itemClicked)="setCurrentItem($event)"
          ></app-item-family>
        </div>
      </mat-menu>

      <mat-icon *ngIf="!isProd" class="material-icons-outlined !ml-4" (click)="goToDetails()">launch</mat-icon>
    </div>
    <div class="header-right">
      <mat-icon class="cursor-pointer mr-3" (click)="cancel()" data-test="close-button">close</mat-icon>
    </div>
  </div>
  <div class="flex h-[35px] -mx-3">
    <div
      [ngClass]="{ selected: this.selectedNavigationOption === option }"
      *ngFor="let option of navOptions"
      class="nav-option"
      (click)="setSelectedNavOption(option)"
    >
      {{ option.label }}
    </div>
  </div>
</div>
