import { Injectable } from '@angular/core';
import { Types } from '@contrail/sdk';
import { PropertyType, Type, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import {
  CollectionManagerActions,
  CollectionManagerSelectors,
} from 'src/app/collection-manager/collection-manager-store';
import { PlaceholderItemUpdateService } from 'src/app/collection-manager/placeholders/placeholder-item-update-service';
import { RootStoreState } from 'src/app/root-store';
import { GridViewPropertyConfiguration } from './data-cell.component';
import { PlaceholderItemPromoteService } from 'src/app/collection-manager/placeholders/placeholder-item-promote-service';
import { OverrideOptionService } from '../../override-option/override-option-service';
import { SizeRangeHelper } from '@common/size-range/size-range-helper';
import { take, tap } from 'rxjs/operators';
import { PlaceholderUtil } from 'src/app/collection-manager/placeholders/placeholder-util';
import { CollectionDataEntity } from '../../../collection-manager.service';
import { ItemUpdateService } from 'src/app/collection-manager/items/item-update-service';
import { ProjectItemUpdateService } from 'src/app/collection-manager/project-items/project-item-update-service';

/** Utility class that localizes the logic required to handle the change of a
 * single value cell edit.
 */
@Injectable({
  providedIn: 'root',
})
export class DataCellValueChangeService {
  constructor(
    private store: Store<RootStoreState.State>,
    private placeholderItemUpdateService: PlaceholderItemUpdateService,
    private itemUpdateService: ItemUpdateService,
    private projectItemUpdateService: ProjectItemUpdateService,
    private overrideOptionService: OverrideOptionService,
    private placeholderItemPromoteService: PlaceholderItemPromoteService,
  ) {}
  private getPropertyName(typeProperty: TypeProperty) {
    if ([PropertyType.ObjectReference, PropertyType.UserList].includes(typeProperty.propertyType)) {
      return typeProperty.slug + 'Id';
    }
    return typeProperty.slug;
  }

  public async handleValueChange(
    property: GridViewPropertyConfiguration,
    data: CollectionDataEntity,
    value: any,
  ): Promise<void> {
    const changes = this.buildChangesToApplyToItem(property, data, value);
    console.log('DataCellValueChangeHandler: handleValueChange: changes: ', changes);

    const item = (await this.isOptionLevelChange(property, data)) ? data.itemOption : data.itemFamily;

    if (item && (property.scope === 'item' || property.slug === 'itemType')) {
      this.applyUpdatesToItem(property, data, value, item, changes);
    } else if (item && property.scope === 'project-item') {
      this.applyUpdatesToProjectItem(property, data, item, changes);
    } else {
      this.applyUpdatesToPlanPlaceholder(property, data, changes);
    }
  }

  private buildChangesToApplyToItem(
    property: GridViewPropertyConfiguration,
    data: CollectionDataEntity,
    value: any,
  ): any {
    const changes: any = {};

    if ([PropertyType.ObjectReference, PropertyType.UserList].includes(property.propertyDefinition.propertyType)) {
      changes[this.getPropertyName(property.propertyDefinition)] = value?.id || null;
      changes[property.slug] = value || null;
    } else {
      changes[this.getPropertyName(property.propertyDefinition)] = value;
    }

    const hasSizeRangeTemplateBeenRemoved = property.slug === SizeRangeHelper.sizeRangeTemplateProperty && !value;
    if (hasSizeRangeTemplateBeenRemoved) {
      const propertiesOfRow = this.getMappedProperties() || [];
      for (const property of propertiesOfRow) {
        if (property.propertyType === PropertyType.SizeRange && data[property.slug]) {
          changes[property.slug] = null;
        }
      }
    }

    return changes;
  }

  private async isOptionLevelChange(
    property: GridViewPropertyConfiguration,
    data: CollectionDataEntity,
  ): Promise<boolean> {
    const itemFamily = data.itemFamily;

    // Decide if this is an item family or item option change.
    // We need to check the property being edited, and then determine
    // which item is the target of the edit.  Note that if the edit is to
    // the family, propogation will occur later.
    if (itemFamily && property.scope === 'item') {
      const itemType = await new Types().getType({ id: itemFamily.typeId });
      const propertyDef = itemType.typeProperties.find((prop) => prop.slug === property.propertyDefinition.slug);
      if (
        property.propertyDefinition.slug === 'optionName' ||
        propertyDef.propertyLevel === 'option' ||
        (data.itemOption && propertyDef.propertyLevel === 'overridable') || // Option level edit if option is set and overridable
        (data.itemOption && propertyDef.propertyLevel === 'all') // Option level edit if option is set and all
      ) {
        return true;
      }
    } else if (itemFamily && property.scope === 'project-item') {
      const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
      const propertyDef = projectItemType.typeProperties.find((prop) => prop.slug === property.propertyDefinition.slug);
      if (
        propertyDef.propertyLevel === 'option' ||
        (data.itemOption && propertyDef.propertyLevel === 'overridable') || // Option level edit if option is set and overridable
        (data.itemOption && propertyDef.propertyLevel === 'all') // Option level edit if option is set and all
      ) {
        return true;
      }
    }

    return false;
  }

  private async applyUpdatesToItem(
    property: GridViewPropertyConfiguration,
    data: CollectionDataEntity,
    value: any,
    item: any,
    changes: any,
  ): Promise<void> {
    const itemUpdates = [];
    const itemChanges = [];

    // Special handling for itemType property.. (Not loving this)
    if (property.slug === 'itemType') {
      changes.typeId = changes.itemType;
      delete changes.itemType;
    }

    // WE JUST EDITED AN ITEM PROPERTY.  ASSUME THAT IT WAS ALLOWED (ACCESS CHECK PREVIOUS)
    item = ObjectUtil.cloneDeep(item);

    if (property.propertyLevel === 'overridable' && !this.overrideOptionService.isInOverrideMode) {
      // If data has not been overridden, edit as a family
      if (!this.overrideOptionService.isDataOverridden(property.propertyDefinition, data.itemFamily, item)) {
        item = item.roles.includes('color') ? data.itemFamily : item;
      }
    } else {
      // If editor is in override mode, edit as option
      if (item.roles.includes('option')) {
        const lifecycleChanges = await this.placeholderItemPromoteService.synchItemFamilyLifecycle(
          data,
          property.propertyDefinition,
          value,
        );
        if (lifecycleChanges) {
          itemChanges.push({ id: lifecycleChanges.item.id, changes: lifecycleChanges.changes });
          itemUpdates.push({ item: lifecycleChanges.item, changes: lifecycleChanges.changes });
        }
      }
    }
    await this.placeholderItemUpdateService.deriveOptionNameIfApplicable(item, changes);

    itemUpdates.push({ item, changes: ObjectUtil.cloneDeep(changes) });

    // NEED TO ALSO PERSIST THE ITEM...
    if ([PropertyType.ObjectReference, PropertyType.UserList].includes(property.propertyDefinition.propertyType)) {
      delete changes[property.slug];
    }
    itemChanges.push({ id: item.id, changes });

    this.itemUpdateService.batchUpdateItems({
      changes: itemChanges,
      placeholderUpdates: {
        updates: itemUpdates,
      },
      selectedCell: {
        rowId: data.id,
        propertySlug: property.slug,
      },
    });
  }

  private applyUpdatesToProjectItem(
    property: GridViewPropertyConfiguration,
    data: CollectionDataEntity,
    item: any,
    changes: any,
  ): void {
    const itemUpdates = [];

    // Editing a property that is project-item scoped.
    console.log('Project Item was editted!: ', property.propertyDefinition.slug, item);
    if (property.propertyLevel === 'overridable' && !this.overrideOptionService.isInOverrideMode) {
      if (
        !this.overrideOptionService.isDataOverridden(
          property.propertyDefinition,
          data.itemFamily.projectItem,
          item.projectItem,
        )
      ) {
        item = data.itemFamily;
      }
    }
    itemUpdates.push({ item, changes: ObjectUtil.cloneDeep(changes) });
    if ([PropertyType.ObjectReference, PropertyType.UserList].includes(property.propertyDefinition.propertyType)) {
      delete changes[property.slug];
    }

    this.projectItemUpdateService.batchUpdateProjectItems({
      changes: [{ id: item.id, changes }],
      placeholderUpdates: { updates: itemUpdates },
      selectedCell: {
        rowId: data.id,
        propertySlug: property.slug,
      },
    });
  }

  private applyUpdatesToPlanPlaceholder(property: GridViewPropertyConfiguration, data, changes): void {
    if (property.slug === 'itemType') {
      changes.itemTypeId = changes.itemType;
      delete changes.itemType;
    }

    this.store.dispatch(
      CollectionManagerActions.updateCollectionDataEntity({
        id: data.id,
        changes,
        selectedCell: { rowId: data.id, propertySlug: property.slug },
      }),
    );
  }

  private getMappedProperties(): any[] {
    const typeMap = this.getTypeMap();
    return PlaceholderUtil.getMappedProperties(typeMap);
  }

  private getTypeMap() {
    let typeMap;
    this.store
      .select(CollectionManagerSelectors.typeDefinitions)
      .pipe(
        take(1),
        tap((map) => {
          typeMap = map;
        }),
      )
      .subscribe();
    return typeMap;
  }
}
