import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { Entities, SubTypesHelper, Types } from '@contrail/sdk';
import { PropertyType, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { TypePropertyValidator } from '@contrail/type-validation';
import { CollectionElementValidator } from '../../collection-element-validator/collection-element-validator';
import { PlaceholderItemAssignmentService } from '../../placeholders/placeholder-item-assignment-service';
import { GridViewPropertyConfiguration } from '../data-rows/data-cell/data-cell.component';
import { OverrideOptionService } from '../override-option/override-option-service';
import { TypePropertyUserListService } from '@common/user-list/user-list.service';
import { CollectionElementActionValidator } from '../../collection-element-validator/collection-element-action-validator';

export interface CollectionItemUpdateMap {
  [itemId: string]: {
    item: any;
    changes: any;
    undoChanges: any;
  };
}

export class GridSelectorActionUtil {
  constructor(
    protected store: Store,
    protected collectionElementValidator: CollectionElementValidator,
    protected collectionElementActionValidator: CollectionElementActionValidator,
    protected placeholderItemAssignmentService: PlaceholderItemAssignmentService,
    protected overrideOptionService: OverrideOptionService,
    protected userListService: TypePropertyUserListService,
    protected snackBar: MatSnackBar,
  ) {}

  public collectItemUpdateInfo(
    entity: any,
    itemUpdates: CollectionItemUpdateMap,
    typeProperty: TypeProperty,
    value: any,
  ) {
    let item =
      typeProperty?.propertyLevel && typeProperty?.propertyLevel !== 'family'
        ? entity.itemOption || entity.itemFamily
        : entity.itemFamily;
    if (entity.itemOption && typeProperty.slug === 'optionName') {
      item = entity.itemOption;
    }
    if (this.overrideOptionService.isOverrideChangesOnFamily(typeProperty, entity, 'item')) {
      item = entity.itemFamily;
    }

    const currentValue = item[typeProperty.slug] ? item[typeProperty.slug] : null;
    this.setPropertyValues(itemUpdates, item, typeProperty, value, currentValue, entity);
  }

  public collectProjectItemUpdateInfo(
    entity: any,
    updates: CollectionItemUpdateMap,
    typeProperty: TypeProperty,
    value: any,
  ) {
    let item = entity.itemOption || entity.itemFamily; // will need to support family later.
    if (
      this.overrideOptionService.isOverrideChangesOnFamily(typeProperty, entity, 'project-item') ||
      typeProperty.propertyLevel === 'family'
    ) {
      item = entity.itemFamily;
    }
    if (!item) {
      return;
    }
    const projectItem = item.projectItem;
    const currentValue = projectItem ? projectItem[typeProperty.slug] : null;
    this.setPropertyValues(updates, item, typeProperty, value, currentValue, projectItem);
  }

  private setPropertyValues(
    updates: CollectionItemUpdateMap,
    item: any,
    typeProperty: TypeProperty,
    value: any,
    currentValue: any,
    entity: any,
  ) {
    let changes = {};
    let undoChanges = {};
    if (updates[item.id]) {
      changes = updates[item.id].changes;
      undoChanges = updates[item.id].undoChanges;
    }

    changes[typeProperty.slug] = value !== '' ? value : null;
    undoChanges[typeProperty.slug] = currentValue ? currentValue : null;
    if ([PropertyType.ObjectReference, PropertyType.UserList].includes(typeProperty.propertyType)) {
      changes[typeProperty.slug + 'Id'] = value ? value.id : null;
      undoChanges[typeProperty.slug + 'Id'] = entity[typeProperty.slug + 'Id']
        ? entity[typeProperty.slug + 'Id']
        : null;
      changes[typeProperty.slug] = value ? value : null;
      undoChanges[typeProperty.slug] = entity[typeProperty.slug] ? entity[typeProperty.slug] : null;
    } else if (typeProperty.propertyType === 'size_range') {
      changes[typeProperty.slug] = value !== '' ? JSON.parse(value) : null;
      undoChanges[typeProperty.slug] = entity[typeProperty.slug] ? entity[typeProperty.slug] : null;
    }
    updates[item.id] = { item, changes, undoChanges };
  }

  public async handlePasteItemFamilyData(
    data: any,
    entity: any,
    originalEntity: any,
    columnValues: any,
    itemFamilyPropIndex: number,
    itemOptionPropIndex: number,
    changes: {},
    undoChanges: {},
    localStorageRowIds: string[],
    rowIndex: number,
  ) {
    const itemChanges = await this.pasteItemFamily(
      data,
      entity,
      columnValues,
      itemFamilyPropIndex,
      itemOptionPropIndex,
      localStorageRowIds,
      rowIndex,
    );
    if (itemChanges && Object.keys(itemChanges).length > 0) {
      for (const property in itemChanges) {
        if (itemChanges.hasOwnProperty(property)) {
          const typeProperty: TypeProperty = itemChanges.itemFamily
            ? await new Types().getProperty(itemChanges.itemFamily?.typeId, property)
            : undefined;

          const pasteable =
            typeProperty?.propertyLevel !== 'option' ||
            (await this.collectionElementValidator.isPasteableForPropertySlug(itemChanges, property));
          pasteable ? (changes[property] = itemChanges[property]) : (changes[property] = undefined);
          undoChanges[property] = originalEntity[property] ? originalEntity[property] : null;
        }
      }
    }
    if (itemChanges) {
      entity['itemFamily'] = itemChanges.itemFamily;
      if (itemChanges.itemOption) {
        entity['itemOption'] = itemChanges.itemOption;
      }
    }
  }

  public async searchForObject(entityName: string, criteria: any) {
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
    const data = await new Entities().get({
      entityName,
      relations: [],
      criteria,
      take: 100,
    });
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
    return data;
  }

  public isPasteAllowed(
    entity: any,
    viewProperty: any,
    columnValues: any,
    itemFamilyPropIndex: number,
    itemOptionPropIndex: number,
    typeProperty: TypeProperty,
  ) {
    // Decide if we should process this change, or it was covered above...
    const enabled = viewProperty?.enabled;
    const isItemFamilyColumn = viewProperty?.slug === 'itemFamily';
    const hasItemFamilyAssigned = !!columnValues[itemFamilyPropIndex];
    const hasItemOptionAssigned = !!columnValues[itemOptionPropIndex];
    const isOptionLevelProperty = viewProperty?.scope === 'item' && typeProperty?.propertyLevel === 'option';
    const isQualifiedOptionLevelProperty =
      hasItemFamilyAssigned &&
      (isOptionLevelProperty || // skip all family level properties..
        (entity['itemOption'] && typeProperty?.slug === 'optionName')); // Not sure why this is an outlier..
    return (
      enabled &&
      !isItemFamilyColumn && // No need to do this again... as we did it above.
      (isQualifiedOptionLevelProperty ||
        !hasItemFamilyAssigned || // Can always proceed if there was no item family.
        (viewProperty?.scope !== 'item' &&
          viewProperty?.scope !== 'project-item' &&
          viewProperty.slug !== 'itemType') || // Covers all non item properties (placeholder, etc.)
        (viewProperty?.scope === 'project-item' &&
          typeProperty?.propertyLevel === 'option' &&
          !hasItemOptionAssigned) ||
        (viewProperty?.scope === 'project-item' &&
          typeProperty?.propertyLevel === 'family' &&
          !hasItemFamilyAssigned) ||
        (!hasItemFamilyAssigned && viewProperty.slug === 'itemType'))
    );
  }

  public async collectUpdatesFromPasteData(
    typeProperty: TypeProperty,
    viewProperty: GridViewPropertyConfiguration,
    entity: any,
    originalEntity: any,
    value: any,
    changes: {},
    undoChanges: {},
    updates: {},
    pasteMode: string,
  ) {
    console.log(
      'collectUpdatesFromPasteData: ',
      typeProperty,
      viewProperty,
      entity,
      value,
      ObjectUtil.cloneDeep(changes),
      ObjectUtil.cloneDeep(undoChanges),
      ObjectUtil.cloneDeep(updates),
      pasteMode,
    );
    let updateAllowed = true;
    if (pasteMode === 'rows') {
      // only check for pasteability when pasting rows
      updateAllowed = await this.collectionElementValidator.isPasteableForProperty(entity, viewProperty);
    } else {
      updateAllowed = await this.isPropertyUpdateAllowed(entity, viewProperty.propertyDefinition, value);
    }
    if (!updateAllowed) {
      return;
    }
    /*
     * Accumulate changes into "updates" (updates can be either for item or option) when:
     * 1. Updating item-scope attributes except for "optionName".
     * 2. Updating non-option level attributes and itemFamily is assigned.
     * 3. Updating option level attributes and itemOption is assigned.
     *
     * Otherwise, changes are happening in the placeholder.
     */
    if (
      typeProperty &&
      viewProperty.scope === 'item' &&
      ((entity.itemFamily && typeProperty.propertyLevel !== 'option' && typeProperty.slug !== 'optionName') ||
        (entity.itemOption && (typeProperty.propertyLevel === 'option' || typeProperty.slug === 'optionName')))
    ) {
      this.collectItemUpdateInfo(entity, updates, typeProperty, value);
    } else if (typeProperty?.slug === 'typeId' && entity.itemFamily) {
      this.collectItemUpdateInfo(entity, updates, typeProperty, value);
    } else if (
      ((entity.itemFamily && typeProperty?.propertyLevel !== 'option') ||
        (entity.itemOption && typeProperty?.propertyLevel !== 'family')) &&
      viewProperty.scope === 'project-item'
    ) {
      this.collectProjectItemUpdateInfo(entity, updates, typeProperty, value);
    } else {
      if (
        [PropertyType.ObjectReference, PropertyType.UserList].includes(viewProperty.propertyDefinition.propertyType)
      ) {
        changes[viewProperty.slug + 'Id'] = value ? value.id : null;
        undoChanges[viewProperty.slug + 'Id'] = originalEntity[viewProperty.slug + 'Id']
          ? originalEntity[viewProperty.slug + 'Id']
          : null;
        changes[viewProperty.slug] = value ? value : null;
        undoChanges[viewProperty.slug] = originalEntity[viewProperty.slug] ? originalEntity[viewProperty.slug] : null;
      } else if (viewProperty.propertyDefinition.propertyType === 'type_reference') {
        changes[viewProperty.slug + 'Id'] = value ? value : null;
        undoChanges[viewProperty.slug + 'Id'] = originalEntity[viewProperty.slug + 'Id']
          ? originalEntity[viewProperty.slug + 'Id']
          : null;
      } else if (viewProperty.propertyDefinition.propertyType === 'size_range') {
        changes[viewProperty.slug] = value !== '' ? JSON.parse(value) : null;
        undoChanges[viewProperty.slug] = originalEntity[viewProperty.slug] ? originalEntity[viewProperty.slug] : null;
      } else {
        changes[viewProperty.slug] = value !== '' ? value : null;
        undoChanges[viewProperty.slug] = originalEntity[viewProperty.slug] ? originalEntity[viewProperty.slug] : null;
      }
    }
  }

  /** Determines if a given value cab be assigned into a given entity for a given property
   * @entity  The entity that you are pasting into (may need to be a plan-placeholder)
   * @typeProperty  Property you are assignign the value to
   * @value The value you are assigning.
   */
  public async isPropertyUpdateAllowed(entity, typeProperty: TypeProperty, value: any) {
    let checkValue = ObjectUtil.cloneDeep(value);
    if (typeProperty.propertyType === 'size_range' && checkValue) {
      checkValue = JSON.parse(checkValue);
    }
    const validations = await TypePropertyValidator.validatePropertyValue(checkValue, typeProperty);

    if (typeProperty.slug === 'isDropped' && value === true) {
      const dropCheck = this.collectionElementActionValidator.isDroppable(entity);
      if (!dropCheck.isValid) {
        this.snackBar.open(dropCheck.reason, '', { duration: 5000 });
        return false;
      }
    }

    const editCheck = await this.collectionElementValidator.isEditableForPropertySlug(entity, typeProperty.slug);
    const editable = editCheck.editable;
    const propertyType = typeProperty.propertyType;
    if (validations.length === 0 && editable) {
      return true;
    }
    return false;
  }

  public async parseObjRefValue(data: any, value: any, propertyDefinition: any) {
    if (value && value !== '') {
      if (propertyDefinition.referencedTypeRootSlug === 'item') {
        return await this.parseItemObjRefValue(data, value, propertyDefinition);
      }

      const placeholder = data.find((row) => row[propertyDefinition.slug]?.name === value);
      if (placeholder) {
        return placeholder[propertyDefinition.slug];
      }

      const objects = await this.searchForObject(propertyDefinition.referencedTypeRootSlug, { name: value });
      if (objects.length === 0) {
        this.snackBar.open('The object ' + value + ' is not found', '', { duration: 2000 });
      } else {
        return objects[0];
      }
      return null;
    }
    return null;
  }

  private async parseItemObjRefValue(data: any, value: any, propertyDefinition: any) {
    const placeholderWithFamilyItem = data.find(
      (row) => row[propertyDefinition.slug]?.name === value && row[propertyDefinition.slug]?.roles?.includes('family'),
    );
    if (placeholderWithFamilyItem) {
      return placeholderWithFamilyItem[propertyDefinition.slug];
    }

    const familyItem = await this.searchForObject('item', { roles: 'family', name: value });
    if (familyItem.length) {
      return familyItem[0];
    }

    this.snackBar.open(`The item family '${value}' is not found`, '', { duration: 2000 });
    return null;
  }

  public async parseTypeReferenceValue(propertyDefinition: TypeProperty, value: any) {
    let currentType;
    if (value.indexOf('\\') === -1) {
      currentType = await new Types().getType({ path: propertyDefinition.referencedTypePath });
    } else {
      const types = await SubTypesHelper.getTypeListFromPath(propertyDefinition.referencedTypePath);
      currentType = types.find((type) => type.pathLabel === value);
    }

    return currentType?.id;
  }

  public async parseUserList(data: any, value: any, propertyDefinition: any) {
    if (value && value !== '') {
      const placeholder = data.find((row) => row[propertyDefinition.slug]?.email === value);
      if (placeholder) {
        return placeholder[propertyDefinition.slug];
      }
      if (propertyDefinition.typePropertyUserListId) {
        return this.userListService.getTypePropertyUserByEMail(propertyDefinition.typePropertyUserListId, value);
      }
    }
    return null;
  }

  private async pasteItemFamily(
    data: any,
    targetPH: any,
    columnValues: any,
    itemFamilyPropIndex,
    itemOptionPropIndex,
    localStorageRowIds: string[],
    rowIndex: number,
  ): Promise<any> {
    const value = columnValues[itemFamilyPropIndex];
    if (value && value !== '') {
      let clipboardPlaceholder;
      let placeholder;
      if (localStorageRowIds && localStorageRowIds[rowIndex]) {
        clipboardPlaceholder = data.find((placeholder) => placeholder?.id === localStorageRowIds[rowIndex]);
        if (clipboardPlaceholder?.itemFamily?.name === value) {
          placeholder = data.find(
            (placeholder) => placeholder?.itemFamily?.name === value && clipboardPlaceholder.id === placeholder.id,
          );
        }
      }
      if (!placeholder) {
        placeholder = data.find((placeholder) => placeholder?.itemFamily?.name === value);
      }
      if (!placeholder) {
        const items = await this.searchForObject('item', { roles: 'family', name: value });
        if (items.length === 0) {
          this.snackBar.open('The item family ' + value + ' is not found', '', { duration: 2000 });
          return;
        } else {
          placeholder = { itemFamily: items[0] };
        }
      }
      let changes;
      if (columnValues[itemOptionPropIndex] && columnValues[itemOptionPropIndex] !== '') {
        targetPH.itemFamily = placeholder.itemFamily;
        changes = await this.placeholderItemAssignmentService.setSingleItemOptionOnPlaceholder(
          targetPH,
          columnValues[itemOptionPropIndex],
        );
        if (!changes) {
          changes = await this.placeholderItemAssignmentService.setItemFamilyOnPlaceholder(
            targetPH,
            placeholder.itemFamily,
            true,
            true,
          );
        }
      } else {
        changes = await this.placeholderItemAssignmentService.setItemFamilyOnPlaceholder(
          targetPH,
          placeholder.itemFamily,
          true,
          true,
        );
      }
      if (placeholder) {
        return changes;
      }
    }
    return null;
  }
}
