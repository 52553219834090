import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { ChooserFilterConfig, ItemDataChooserDataSource } from './item-data-chooser-data-source';
import { SortDefinition } from '../../components/sort/sort-definition';
import { FilterObjects } from '@contrail/filters';
import { ClipboardActions, ClipboardSelectors } from '@common/clipboard/clipboard-store';
import { ClipboardItemsService } from '@common/clipboard/clipboard-items.service';
import { ClipboardService } from '@common/clipboard/clipboard.service';
import { ItemData } from '@common/item-data/item-data';

export class ClipboardChooserDataSource extends ItemDataChooserDataSource {
  constructor(
    protected store: Store<RootStoreState.State>,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    protected disableFilterByLevel: boolean = false,
  ) {
    super(store, filterConfigSubject, sortConfigSubject, existingItemIdsSubject, showAllSubject, null);

    this.initFilteredDataObservable();
    this.initResultsObservable();
    this.loadClipboard();
  }

  protected async initFilteredDataObservable() {
    this.filteredData$ = combineLatest([
      this.filterConfigSubject,
      this.sortConfigSubject,
      this.store.select(ClipboardSelectors.clipboardItems),
    ]).pipe(
      switchMap(async ([filterConfig, sortConfig, clipboardItems]) => {
        if (!filterConfig) {
          return;
        }

        const clipboardItemData = clipboardItems.map((clipboardItem) => new ItemData(clipboardItem));
        this.assortmentSubject.next(clipboardItemData);

        const searchTerm = filterConfig.searchTerm;
        const criteria = filterConfig?.baseCriteria;
        const filterDefinition = filterConfig?.filterDefinition;

        const itemsFilteredByLevel = this.filterItemsByLevel(clipboardItemData, criteria);

        const searchFilterCriteria = { searchTerm, itemFamilyId: criteria?.itemFamilyId };
        const searchFilteredItems = this.filterLocalItemData(
          searchFilterCriteria,
          itemsFilteredByLevel,
          this.searchableProperties,
        );
        const filteredItems = filterDefinition?.filterCriteria
          ? FilterObjects.filter(searchFilteredItems, filterDefinition?.filterCriteria)
          : searchFilteredItems;

        this.sortData(filteredItems, sortConfig);
        return filteredItems;
      }),
    );
  }

  private filterItemsByLevel(items: ItemData[], criteria: any) {
    if (this.disableFilterByLevel) {
      return items;
    }

    if (criteria.roles === 'option') {
      return items.filter((itemData) => itemData.item.itemFamilyId !== itemData.item.id);
    }

    if (criteria.roles === 'family') {
      return items.filter((itemData) => itemData.item.itemFamilyId === itemData.item.id);
    }

    return items;
  }

  public async loadClipboard() {
    this.loadingSubject.next(true);

    const clipboardService = new ClipboardService();
    const clipboardItemsService = new ClipboardItemsService(clipboardService);
    const clipboardItems = await clipboardItemsService.getClipboardItems();
    this.store.dispatch(ClipboardActions.loadClipboardItemsSuccess({ clipboardItems }));

    this.loadingSubject.next(false);
  }
}
