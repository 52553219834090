import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { DataCellComponent } from './data-rows/data-cell/data-cell.component';
import { GridViewManager } from './grid-view.manager';

@Injectable({
  providedIn: 'root',
})
export class GridNavigationHandler {
  private DEFAULT_NUMBER_OF_ROWS_TO_SCROLL = 5;
  private DEFAULT_COLUMN_WIDTH = 150;

  private verticalScrollPercentage = 0;
  private renderedRowCount = 0;
  private totalDataRowCount = 0;
  private horizontalOffset = 0;
  private scrollHorizontalPercentage = 0;
  private leftPropertiesTotalColumnWidth = 0;
  private rightPropertiesTotalColumnWidth = 0;
  private filteredData = [];
  private sidePanelWidth = 0;
  private rightProperties = [];
  private allData: Array<any>;
  private scrollVerticalPercentage: any;
  private displayedData: Array<any>;

  constructor(
    private gridViewService: GridViewManager,
    private store: Store<RootStoreState.State>,
  ) {
    this.store
      .select(CollectionManagerSelectors.scrollVerticalPercentage)
      .subscribe((percent) => (this.verticalScrollPercentage = percent));

    this.store.select(CollectionManagerSelectors.scrollColumnMarginOffset).subscribe((horizontalOffset) => {
      //      console.log('horizontalOffset: ', horizontalOffset,  ' change : ', horizontalOffset - this.horizontalOffset);
      this.horizontalOffset = horizontalOffset;
    });

    this.store.select(CollectionManagerSelectors.scrollHorizontalPercentage).subscribe((scrollHorizontalPercentage) => {
      // console.log('scrollHorizontalPercentage: ', scrollHorizontalPercentage);
      this.scrollHorizontalPercentage = scrollHorizontalPercentage;
    });

    this.store
      .select(CollectionManagerSelectors.leftPropertiesTotalColumnWidth)
      .subscribe(
        (leftPropertiesTotalColumnWidth) => (this.leftPropertiesTotalColumnWidth = leftPropertiesTotalColumnWidth),
      );

    this.store
      .select(CollectionManagerSelectors.rightPropertiesTotalColumnWidth)
      .subscribe(
        (rightPropertiesTotalColumnWidth) => (this.rightPropertiesTotalColumnWidth = rightPropertiesTotalColumnWidth),
      );

    this.store
      .select(CollectionManagerSelectors.renderedRowCount)
      .subscribe((rowCount) => (this.renderedRowCount = rowCount));

    this.store.select(CollectionManagerSelectors.displayedData).subscribe((data) => {
      this.filteredData = data;
      this.totalDataRowCount = data.length;
    });

    this.store
      .select(CollectionManagerSelectors.rightProperties)
      .subscribe((rightProperties) => (this.rightProperties = rightProperties));
    this.store
      .select(CollectionManagerSelectors.sidePanelWidth)
      .subscribe((sidePanelWidth) => (this.sidePanelWidth = sidePanelWidth));
    this.store.select(CollectionManagerSelectors.allData).subscribe((allData) => (this.allData = allData));
    this.store
      .select(CollectionManagerSelectors.displayedData)
      .subscribe((displayedData) => (this.displayedData = displayedData));
    this.store
      .select(CollectionManagerSelectors.scrollVerticalPercentage)
      .subscribe((scrollVerticalPercentage) => (this.scrollVerticalPercentage = scrollVerticalPercentage));
  }

  handleDelete() {
    if (!(this.gridViewService.editorMode === 'EDIT')) {
      return;
    }
    this.gridViewService.delete();
  }
  handleTab(keyEvent: KeyboardEvent) {
    const selectedCell = this.gridViewService.selectedCell;
    if (selectedCell) {
      keyEvent.preventDefault();
    }
    if (selectedCell && selectedCell.edit) {
      selectedCell.endEdit();
    }

    let direction = 'right';
    if (keyEvent.shiftKey) {
      direction = 'left';
    }
    this.selectToSide(direction);
  }
  handleEscape() {
    const selectedCell = this.gridViewService.selectedCell;
    if (selectedCell && selectedCell.edit) {
      selectedCell.endEdit();
    }
  }

  handleInputKeyPress() {
    const selectedCell = this.gridViewService.selectedCell;
    //console.log('handleInputKeyPress: selectedCell', selectedCell);
    if (!selectedCell) {
      return;
    }
    if (!selectedCell.edit) {
      if (selectedCell.propertyType === 'text') {
        selectedCell.startEdit(false);
      } else {
        selectedCell.startEdit(true);
      }
    }
  }

  handleEnter() {
    const selectedCell = this.gridViewService.selectedCell;
    if (!selectedCell) {
      return;
    }
    if (selectedCell.edit) {
      if (this.cancelEditModeOnEnterKey(selectedCell)) {
        selectedCell.endEdit();
        this.selectUpDown('down');
      }
    } else {
      selectedCell.startEdit();
    }
  }

  /** Handles left/right arrow key logic */
  selectToSide(direction: string) {
    const selectedCell = this.gridViewService.selectedCell;
    // CHECK IF THERE IS A SELECTED CELL.  THE 'ALLOW ARROW KEY' IS RELATED TO AUTO EDITS..
    if (!selectedCell) {
      return;
    }

    const rowId = selectedCell.rowId;
    const propertySlug = selectedCell.propertySlug;
    if (!rowId || !propertySlug) {
      return;
    }
    const rowDef = this.gridViewService.rowMap.get(rowId);
    const index = rowDef.allCells.findIndex((cell) => cell.propertySlug === propertySlug);
    const newIndex = direction === 'left' ? index - 1 : index + 1;
    if (newIndex < 0 || newIndex > rowDef.allCells.length - 1) {
      return null;
    }
    const nextCell = rowDef.allCells[newIndex];
    nextCell.select();
    const nextSelectedCell = this.gridViewService.selectedCell;

    this.checkForHorizontalScroll(nextSelectedCell);
  }

  public checkForHorizontalScroll(cell, scrollAmount = null) {
    const cellCoords = cell.getBoundingRectangle();
    const screenCellRightEdge = cellCoords.x + cellCoords.width + 5;
    const xCoordinate = cellCoords.x;
    this.checkForHorizontalScrollByPosition(screenCellRightEdge, xCoordinate, 0, cell.propertySlug);
  }

  public checkForHorizontalScrollByPosition(
    screenCellRightEdge: number,
    xCoordinate: number,
    scrollAmount?: number,
    propertySlug?: string,
  ) {
    let calculatedScrollAmount = null;
    let newPercentScroll = 0;
    if (screenCellRightEdge > window.innerWidth - this.sidePanelWidth) {
      if (propertySlug) {
        calculatedScrollAmount = this.calculateScrollAmount(propertySlug, 'right') + this.DEFAULT_COLUMN_WIDTH;
        newPercentScroll = calculatedScrollAmount / this.rightPropertiesTotalColumnWidth;
      } else {
        // used by header repositioning
        const diff = screenCellRightEdge - window.innerWidth;
        console.log(diff, this.rightPropertiesTotalColumnWidth);
        const percentIncrease = (diff + (scrollAmount || 100)) / this.rightPropertiesTotalColumnWidth;
        newPercentScroll = this.scrollHorizontalPercentage + percentIncrease;
        console.log(newPercentScroll);
      }
      if (newPercentScroll > 1) {
        newPercentScroll = 1;
      }
      this.store.dispatch(CollectionManagerActions.setScrollHorizontalPercentage({ percentage: newPercentScroll }));
    } else if (xCoordinate < this.leftPropertiesTotalColumnWidth) {
      // selected cell is behind the frozen cells.
      if (propertySlug) {
        calculatedScrollAmount = this.calculateScrollAmount(propertySlug, 'left');
        newPercentScroll = (calculatedScrollAmount || scrollAmount) / this.rightPropertiesTotalColumnWidth;
      } else {
        // used by header repositioning
        const diff = this.leftPropertiesTotalColumnWidth - xCoordinate;
        const percentDecrease = (diff + (scrollAmount || 60)) / this.rightPropertiesTotalColumnWidth;
        newPercentScroll = this.scrollHorizontalPercentage - percentDecrease;
      }
      if (newPercentScroll < 0) {
        newPercentScroll = 0;
      }
      this.store.dispatch(CollectionManagerActions.setScrollHorizontalPercentage({ percentage: newPercentScroll }));
    }
  }

  calculateScrollAmount(propertySlug: string, direction: string) {
    let index = this.rightProperties.findIndex((property) => property.slug === propertySlug);
    if (index === 0) {
      return 0;
    }
    if (direction === 'left') {
      index = index - 1;
    }
    let scrollAmount = 0;
    this.rightProperties.forEach((prop, i) => {
      if (i <= index) {
        scrollAmount += prop.width || this.DEFAULT_COLUMN_WIDTH;
      }
    });
    return scrollAmount;
  }

  /** Handles up/down arrow key logic */
  selectUpDown(direction: string) {
    const selectedCell = this.gridViewService.selectedCell;
    if (!selectedCell) {
      return;
    }

    const rowId = selectedCell.rowId;
    const propertySlug = selectedCell.propertySlug;
    if (!rowId || !propertySlug) {
      return;
    }

    const rowIndex = this.gridViewService.rowIds.findIndex((id) => id === rowId);
    const nextRowIndex = direction === 'up' ? rowIndex - 1 : rowIndex + 1;
    if (nextRowIndex < 0 || nextRowIndex > this.gridViewService.rowIds.length - 1) {
      this.verticalAutoScroll(direction);
      return null;
    }
    const nextRowId = this.gridViewService.rowIds[nextRowIndex];
    const nextRowDef = this.gridViewService.rowMap.get(nextRowId);
    const nextCell = nextRowDef.allCells.find((cell) => cell.propertySlug === propertySlug);
    nextCell.select();
  }

  /**
   * Scrolls the screen vertically in response to a user input such as a key down navigation
   * on a cell.
   */
  public verticalAutoScroll(direction, rowsToScroll = this.DEFAULT_NUMBER_OF_ROWS_TO_SCROLL) {
    const percentageAlreadyRendered = 100 * (this.renderedRowCount / this.totalDataRowCount);

    // total number of rows is less than the view port area
    if (percentageAlreadyRendered > 100) {
      return;
    }

    // scroll by % of DEFAULT_NUMBER_OF_ROWS_TO_SCROLL
    const factor = rowsToScroll / this.totalDataRowCount;

    let scrollPercent = 0;
    if (direction === 'up') {
      scrollPercent = this.verticalScrollPercentage - factor;
      if (percentageAlreadyRendered > 90 && this.verticalScrollPercentage > 0) {
        scrollPercent = 1 - this.verticalScrollPercentage;
      }
    } else {
      // down
      scrollPercent = this.verticalScrollPercentage + factor;
      if (percentageAlreadyRendered > 90 && this.verticalScrollPercentage > 0) {
        scrollPercent = factor + this.renderedRowCount / this.totalDataRowCount;
      }
    }

    const newScrollPercent = scrollPercent > 1 ? 1 : scrollPercent < 0 ? 0 : scrollPercent;
    this.store.dispatch(CollectionManagerActions.setScrollVerticalPercentage({ percentage: newScrollPercent }));
  }

  private cancelEditModeOnEnterKey(selectedCell: DataCellComponent) {
    if (selectedCell.propertyType.includes('object_reference') && !selectedCell.value?.id) {
      return false;
    }
    return true;
  }

  public goToLastCellInColumn() {
    const selectedCell = this.gridViewService.selectedCell;
    if (!selectedCell) {
      return;
    }
    this.store.dispatch(CollectionManagerActions.setScrollVerticalPercentage({ percentage: 1 }));

    // ISSUE HERE IN THAT WE NEED TO WAIT UNTIL SCROLL HAS HAPPENED
    setTimeout(() => {
      const nextRowId = this.filteredData[this.totalDataRowCount - 1]?.id;
      console.log('newRowId: ', nextRowId);
      const nextRowDef = this.gridViewService.rowMap.get(nextRowId);
      const nextCell = nextRowDef.allCells.find((cell) => cell.propertySlug === selectedCell.propertySlug);
      nextCell.select();
    }, 50);
  }
  public goToFirstCellInColumn() {
    const selectedCell = this.gridViewService.selectedCell;
    if (!selectedCell) {
      return;
    }
    this.store.dispatch(CollectionManagerActions.setScrollVerticalPercentage({ percentage: 0 }));

    // ISSUE HERE IN THAT WE NEED TO WAIT UNTIL SCROLL HAS HAPPENED
    setTimeout(() => {
      const nextRowId = this.gridViewService.rowIds[0];
      const nextRowDef = this.gridViewService.rowMap.get(nextRowId);
      const nextCell = nextRowDef.allCells.find((cell) => cell.propertySlug === selectedCell.propertySlug);
      nextCell.select();
    }, 50);
  }

  public goToRow(rowId: string) {
    if (!this.gridViewService.rowMap.get(rowId)) {
      const scrolledRows = Math.round(this.scrollVerticalPercentage * this.displayedData.length);
      const targetRow = this.displayedData.findIndex((data) => data.id === rowId);
      const direction = scrolledRows > targetRow ? 'up' : 'down';
      this.verticalAutoScroll(direction, Math.abs(scrolledRows - targetRow) + 1);
    }
  }

  public goToCell(rowId: string, columnId: string) {
    if (rowId === 'header') {
      // look for header columns
      const currentHeaderElement = document.getElementById('column_header_' + columnId);
      const box = currentHeaderElement.getBoundingClientRect();
      const screenCellRightEdge = box.x + box.width + 5;
      const xCoordinate = box.x;
      this.checkForHorizontalScrollByPosition(screenCellRightEdge, xCoordinate, 0, columnId);
    } else {
      let visibleCell = this.gridViewService.getCell(rowId, columnId);
      if (visibleCell) {
        this.checkForHorizontalScroll(visibleCell, 200);
      } else {
        this.goToRow(rowId);
        setTimeout(() => {
          visibleCell = this.gridViewService.getCell(rowId, columnId);
          this.checkForHorizontalScroll(visibleCell, 200);
        }, 1);
      }
    }
  }
}
