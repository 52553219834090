import { Injectable } from '@angular/core';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { v4 as uuid } from 'uuid';
import { AnalyticsService } from '../analytics/analytics.service';
import { AuthSelectors } from '../auth/auth-store';
import { DownloadService } from './download/download.service';
import { DocumentHistorySelectors } from '@common/document-history/document-history-store';

export enum ExportReportType {
  PDF = 'pdf',
  EXCEL = 'excel',
  CSV = 'csv',
}

export enum ExportableEntityType {
  CUSTOM_ENTITY = 'custom-entity',
}

export interface ExportEntitiesOptions {
  entityType: ExportableEntityType;
  typePath?: string;
  criteria?: {
    [key: string]: any;
  };
}

export interface ExportRequestEntity {
  ownerId: string;
  ownerName: string;
  entityName: string;
  criteria: any;
  relations: Array<string>;
  viewId?: string;
  viewName?: string;
  reportType: string;
  fileName?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ExportsService {
  authContext: any = {};
  snapshot: any;
  constructor(
    private analyticsService: AnalyticsService,
    private downloadService: DownloadService,
    store: Store<RootStoreState.State>,
  ) {
    store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => (this.authContext = authContext));
    store
      .select(DocumentHistorySelectors.currentEntitySnapshot)
      .subscribe((currentEntitySnapshot) => (this.snapshot = currentEntitySnapshot));
  }

  async initDownloadEntityReference(options) {
    this.analyticsService.emitEvent({
      eventName: options.reportType === 'pdf' ? 'PDF_EXPORT' : 'EXCEL_EXPORT',
      eventCategory: EVENT_CATEGORY.EXPORT,
      eventTarget: options.entityReference,
      eventLabel: options.ownerName,
    });
    const id = options.ownerId || options.entityReference;
    const jobId = await this.getJobId(id, options.reportType);

    console.log(`input request ${JSON.stringify(options)}
    jobId ${jobId}
    `);

    this.checkDownloadProgress(jobId);

    const entityName = options.reportType === 'pdf' ? 'export' : 'excel-export';
    const obj = {
      ...options,
      jobId,
      fileName: this.generateFileName(options),
    };
    let payload;
    if (options.reportType === 'pdf') {
      payload = { id: options.ownerId, jobId };
    } else {
      payload = { entityReference: options.entityReference, viewDefinitionId: options.viewDefinitionId, jobId };
      if (this.snapshot) {
        payload.snapshotReference = `entity-snapshot:${this.snapshot.id}`;
      }
    }
    await new Entities().create({
      entityName,
      object: payload,
    });
    return jobId;
  }

  async initDownloadAllEntities(reportType: ExportReportType, options: ExportEntitiesOptions) {
    if (reportType !== ExportReportType.CSV) {
      throw new Error('Only CSV export is supported when exporting all entities.');
    }

    const jobId = await this.getJobId(options.entityType, reportType);
    this.checkDownloadProgress(jobId);

    await new Entities().create({
      entityName: 'export',
      object: {
        ...options,
        jobId,
      },
      suffix: 'csv',
    });

    return jobId;
  }

  public checkDownloadProgress(jobId: string) {
    this.downloadService.initDownloadPolling(jobId);
  }

  async getJobId(id: string, reportType: string) {
    return `${id}:${await uuid()}:${reportType}:${this.authContext.user.id}:${this.authContext.currentOrg.orgSlug}`;
  }

  private generateFileName(request: ExportRequestEntity) {
    const date = new Date();
    const currentDate = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    return `${request.ownerName}_${request.viewName ? request.viewName + '_' : ''} ${currentDate}`;
  }
}
