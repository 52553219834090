<div class="grid-totals">
  <app-column-aggregate
    [style.minWidth.px]="property.width || 150"
    class="header-cell"
    *ngFor="let property of properties; last as isLast"
    [ngClass]="{ last: isLast }"
    [data]="data"
    [property]="property"
  >
  </app-column-aggregate>
</div>
