import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanItemChooserComponent } from './plan-item-chooser.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { MatIconModule } from '@angular/material/icon';
import { ComponentsModule } from '../common/components/components.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FilterModule } from '../common/components/filter/filter.module';
import { TypesModule } from '../common/types/types.module';
import { ItemDataChooserModule } from '../common/item-data-chooser/item-data-chooser.module';

@NgModule({
  declarations: [PlanItemChooserComponent],
  imports: [
    CommonModule,
    VirtualScrollerModule,
    MatIconModule,
    ComponentsModule,
    MatButtonModule,
    FilterModule,
    TypesModule,
    ItemDataChooserModule,
  ],
  exports: [PlanItemChooserComponent],
})
export class ItemChooserModule {}
