export const PAGINATED_ENTITY_TYPES = [
  'assortment',
  'asset',
  'color',
  'custom-entity',
  'custom-font-family',
  'item',
  'plan-placeholder',
  'project-item',
  'size-range-template',
];

export interface TypeManagedEntity {
  id: string;
  typeId: string;
  typePath: string;
  entityType: string;
  orgId: string;
  [key: string]: any;
}

export interface ChangeObject<T> {
  id: string;
  changes: T;
}

export interface PaginatedSearchResult<T> {
  results: T[];
  nextPageKey?: string;
}
