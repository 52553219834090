import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Entities } from '@contrail/sdk';
import { TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { PlansActions, PlansSelectors, RootStoreState } from 'src/app/root-store';

interface PlanGoal {
  id?: string;
  aggregationType: string;
  propertySlug: string;
}

@Component({
  selector: 'app-add-plan-goal',
  templateUrl: './add-plan-goal.component.html',
  styleUrls: ['./add-plan-goal.component.scss'],
})
export class AddPlanGoalComponent implements OnChanges {
  @Input() goalValue: number;
  @Input() typeProperty: TypeProperty;
  @Output() cancel = new EventEmitter();
  public goalValueFormControl = new UntypedFormControl();
  @Output() public valueChange = new EventEmitter();
  constructor(private store: Store<RootStoreState.State>) {}

  ngOnChanges(): void {
    this.goalValueFormControl.setValue(this.goalValue);
  }

  cancelEdit() {
    this.cancel.emit();
  }
  async changeValue() {
    const value = this.goalValueFormControl.value;
    if (!value) {
      return;
    }
    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(
        take(1),
        tap(async (plan) => {
          await new Entities().create({
            entityName: 'plan-goal',
            object: {
              planId: plan.id,
              targetValue: value,
              aggregationType: 'SUM',
              propertySlug: this.typeProperty.slug,
            },
          });

          const goals = await new Entities().get({ entityName: 'plan-goal', criteria: { planId: plan.id } });
          this.store.dispatch(PlansActions.setCurrentPlanGoals({ planGoals: goals }));
        }),
      )
      .subscribe();
    this.valueChange.emit(value);
  }
}
