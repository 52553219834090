<div *ngIf="sizeRangeTemplate" class="h-full flex w-[calc(100%-13px)] overflow-hidden">
  <div class="size-chip" *ngFor="let size of sizes">
    {{ size }}
  </div>
  <span class="absolute bottom-0 right-1" *ngIf="showEllipsis">...</span>
</div>

<div #sizeRangePopup class="panel" *ngIf="showPanel" id="sizeRangePopup">
  <app-size-range-editor
    #sizeRangeEditor
    [sizeRangeConstraint]="sizeRangeTemplate"
    [sizeRange]="value"
    [editMode]="true"
    (dataChanged)="handleDataChange($event)"
  >
  </app-size-range-editor>

  <div class="flex justify-end mt-2 text-primary">
    <div
      class="ml-1 cursor-pointer"
      (click)="save()"
      [ngClass]="{ action: isSaveEnabled, 'action-disabled': !isSaveEnabled }"
    >
      Save
    </div>
  </div>
</div>
