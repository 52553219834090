import { SelectedCellInfo } from '../context-menu.component';
import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { GridRowService } from '../../grid-view/grid-row-service';

export class DeleteContextMenuAction extends ContextMenuAction {
  selectedRows = [];
  constructor(
    protected store: Store<RootStoreState.State>,
    protected viewType: string,
    private gridRowService: GridRowService,
  ) {
    super(store);
    this.actionIcon = 'delete';
    this.actionLabel = viewType === 'grid' ? 'Delete row(s)' : 'Delete';
    this.shortCutKey = 'Shift-Del';
    this.testRef = 'app-menu-delete-action';
    this.store
      .select(CollectionManagerSelectors.selectedEntityIds)
      .subscribe((selectedEntityIds) => (this.selectedRows = selectedEntityIds));
  }
  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    this.gridRowService.deleteRowFromCollection(ids);
  }
  canInvoke(editorMode: string, ids: Array<string>, selectedCellInfo: SelectedCellInfo = null) {
    if (editorMode !== 'EDIT') {
      return false;
    }
    if (!ids?.length) {
      return false;
    }
    if (this.selectedRows.length === 0 && this.viewType === 'grid') {
      // only used to delete rows
      return false;
    }
    return true;
  }
}
