import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewDefinition } from '@contrail/client-views';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@common/auth/auth-store';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ConfirmationBoxService } from 'src/app/common/components/confirmation-box/confirmation-box';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';

@Component({
  selector: 'app-view-actions',
  templateUrl: './view-actions.component.html',
  styleUrls: ['./view-actions.component.scss'],
})
export class ViewActionsComponent implements OnInit {
  @Input() public currentView: ViewDefinition;
  // @Output() isEditing = new EventEmitter<boolean>();
  @Output() triggerAction = new EventEmitter();
  public allViews$: Observable<Array<ViewDefinition>>;
  public role$: Observable<any>;
  constructor(
    private store: Store<RootStoreState.State>,
    private confirmationBoxService: ConfirmationBoxService,
  ) {
    this.allViews$ = this.store.select(CollectionManagerSelectors.selectCollectionViews);
    this.role$ = this.store.select(AuthSelectors.selectAuthContext).pipe(map((ctx) => ctx?.currentOrg?.role));
  }

  ngOnInit(): void {}

  async handleAction(action) {
    this.triggerAction.emit({
      action,
      data: this.currentView,
    });
  }
}
