import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { BehaviorSubject } from 'rxjs';

const NON_VIEWABLE_EXTS = ['.ai', '.pdf', '.eps', '.ps', '.psd', '.psb', '8bps'];
const NON_IMAGE_VIEWABLE_CONTENT_TYPES = ['application/postscript', 'application/pdf'];

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  public contentColorVariant$: BehaviorSubject<any> = new BehaviorSubject(null);

  public static isNonViewableImage(fileName) {
    return NON_VIEWABLE_EXTS.indexOf(fileName.substring(fileName.lastIndexOf('.'))) > -1;
  }

  public static shouldContentTypeHavePreview(content) {
    const contentType = content?.primaryFile?.contentType;
    if (contentType.includes('image')) {
      return true;
    }
    return NON_IMAGE_VIEWABLE_CONTENT_TYPES.includes(contentType);
  }

  public static isImage(content) {
    const contentType = content?.primaryFile?.contentType;
    return contentType.includes('image') ? true : false;
  }
  public static isSVG(content) {
    const contentType = content?.primaryFile?.contentType;
    return contentType.includes('svg') ? true : false;
  }
  public static isAsyncViewableContentType(content) {
    const contentType = content?.primaryFile?.contentType;
    return NON_IMAGE_VIEWABLE_CONTENT_TYPES.includes(contentType);
  }

  public static async getContentForContentHolder(contentHolderReference: string) {
    const content = await new Entities().get({
      entityName: 'content',
      criteria: { contentHolderReference },
      relations: ['largeViewable', 'mediumViewable', 'primaryFile'],
    });
    content.sort((c1, c2) => {
      return new Date(c1.createdOn).getTime() - new Date(c2.createdOn).getTime();
    });
    return content;
  }
}
