import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { WebSocketService } from '../web-socket/web-socket.service';
@Injectable({
  providedIn: 'root',
})
export class UserSessionsService {
  constructor(private websocketService: WebSocketService) {}

  public async loadUserSessions(sessionId: string) {
    let sessions = await new Entities().get({ entityName: 'session', criteria: { sessionId } });
    const filtered = sessions.filter((userSession) => userSession.clientId !== this.websocketService.getClientId());
    return filtered;
  }
}
