import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NumberFormat, PropertyType, PropertyValueFormatter } from '@contrail/types';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { AGGREGATE_FUNCTION_MAP } from '@components/pivot-grid/pivot-aggregate-helper';

const formatter = new PropertyValueFormatter();
@Component({
  selector: 'app-column-aggregate',
  templateUrl: './column-aggregate.component.html',
  styleUrls: ['./column-aggregate.component.scss'],
})
export class ColumnAggregateComponent implements OnInit, OnDestroy, OnChanges {
  @Input() property: ViewPropertyConfiguration;
  @Input() data: any[] = [];
  public value: number;
  public display: string;
  public align = 'left';

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.computeValue();
    this.align = this.getAlign();
  }

  computeValue() {
    if (!this.property) {
      return;
    }
    if (
      ![PropertyType.Currency, PropertyType.Number, PropertyType.Formula].includes(
        this.property.propertyDefinition?.propertyType,
      ) &&
      this.property.slug !== 'count'
    ) {
      return;
    }

    const functionType = AGGREGATE_FUNCTION_MAP[this.property.aggregateFunction];
    this.value = this.data
      .filter((row) => row.topRow)
      .reduce((a, b) => {
        if (this.property.slug === 'count' && b.count) {
          return a + b.count;
        }
        if (b[this.property.slug]) {
          return a + parseFloat(b[this.property.slug][functionType]);
        }
        return a;
      }, 0);

    let propertyType = this.property.propertyDefinition?.propertyType || PropertyType.Number;
    let numberFormat = this.property.propertyDefinition?.numberFormat || { format: NumberFormat.Integer, precision: 0 };
    if (propertyType === PropertyType.Formula) {
      propertyType = PropertyType.Currency; // THIS IS WRONG... BUT NOT A BAD SHORT TERM SOLUTION
      numberFormat = { currency: 'USD', format: NumberFormat.Currency, precision: 0 };
    }
    this.display = formatter.formatValueForProperty(this.value?.toString(), this.property.propertyDefinition);
  }

  getAlign() {
    if (!this.property) {
      return;
    }
    return [PropertyType.Currency, PropertyType.Number, PropertyType.Formula].includes(
      this.property.propertyDefinition?.propertyType,
    ) || this.property.slug === 'count'
      ? 'right'
      : 'left';
  }
}
