import { Component, Inject, Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Item } from '@common/items/item';
import { Entities } from '@contrail/sdk';

interface ImageAssignmentOptionsData {
  item: Item;
}

@Injectable({
  providedIn: 'root',
})
export class ImageAssignmentOptionsService {
  constructor(public dialog: MatDialog) {}

  public open(item: Item): Promise<any> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ImageAssignmentOptionsComponent, {
        data: { item },
      });
      dialogRef.afterClosed().subscribe((value) => {
        resolve(value);
      });
    });
  }

  public close() {
    this.dialog?.closeAll();
  }
}

@Component({
  template: `
    <div class="modal-container mb-2 flex flex-col" style="min-width: 400px;">
      <div class="flex justify-between">
        <h6 data-test="app-confirmation-box-title">Assign Image</h6>
        <mat-icon [mat-dialog-close]="false" class="cursor-pointer -mr-2 ml-3">close</mat-icon>
      </div>

      <div class="my-5">
        <div class="font-bold">{{ item.name }}</div>
        <div *ngIf="item.optionName">{{ item.optionName }}</div>
      </div>

      <mat-radio-group
        *ngIf="content"
        data-test="image-assignment-option"
        [formControl]="assignmentOption"
        (change)="changeAssignmentOption($event)"
        class="flex flex-col gap-2"
      >
        <mat-radio-button
          data-test="image-assignment-option-replace-primary"
          value="replacePrimary"
          *ngIf="content?.length > 0"
        >
          Add as primary & move current primary to secondary
        </mat-radio-button>
        <mat-radio-button data-test="image-assignment-option-add-new" value="addNew" *ngIf="content?.length > 0">
          Add as secondary content
        </mat-radio-button>
        <mat-radio-button
          data-test="image-assignment-option-add-first-content"
          value="assignFirstContent"
          *ngIf="content.length === 0"
        >
          Add as primary
        </mat-radio-button>
        <mat-radio-button
          data-test="image-assignment-option-replace-primary-delete-current"
          value="replacePrimaryAndDeleteCurrent"
          *ngIf="content?.length > 0"
        >
          Replace & delete current primary
        </mat-radio-button>
      </mat-radio-group>
      <mat-progress-bar *ngIf="!content" mode="indeterminate"></mat-progress-bar>

      <!-- <div *ngIf="assignmentOption.value === 'replaceContent'">
      <app-content-thumbnail
          *ngFor="let contentItem of content; index as idx"
          [content]="contentItem"
          cdkDrag>
      </app-content-thumbnail>
    </div> -->
      <div mat-dialog-actions class="flex items-center justify-end mt-3">
        <button
          data-test="app-confirmation-box-cancel-button"
          mat-stroked-button
          color="primary"
          [mat-dialog-close]="false"
          class="!mr-3"
        >
          Cancel
        </button>
        <button
          data-test="app-confirmation-box-ok-button"
          mat-raised-button
          color="primary"
          (click)="complete()"
          [disabled]="!content || !item"
          cdkFocusInitial
        >
          OK
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .modal-container {
        max-width: 600px;
      }
    `,
  ],
})
export class ImageAssignmentOptionsComponent {
  public item;
  public content;
  public selectedContent;
  public assignmentOption = new UntypedFormControl('replacePrimary');
  constructor(
    public dialogRef: MatDialogRef<ImageAssignmentOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageAssignmentOptionsData,
  ) {
    this.item = data.item;
    this.init();
  }

  async init() {
    const promises = [];
    promises.push(this.setContent());
    promises.push(this.setItem());
    await Promise.all(promises);
  }

  public changeAssignmentOption($event) {
    console.log('changeAssignmentOption: ', $event);
  }

  public async setItem() {
    this.item = await new Entities().get({
      entityName: 'item',
      id: this.item.id,
    });
  }
  public async setContent() {
    this.content = await new Entities().get({
      entityName: 'content',
      criteria: { contentHolderReference: 'item:' + this.item.id },
    });
    if (!this.content.length) {
      this.assignmentOption.setValue('assignFirstContent');
    } else {
      this.assignmentOption.setValue('replacePrimary');
    }
  }

  public complete() {
    const event = {
      content: this.content,
      selectedContent: this.selectedContent,
      action: this.assignmentOption.value,
      priorPrimaryViewableId: this.item.primaryViewableId,
      item: this.item,
    };
    this.dialogRef.close(event);
  }
}
