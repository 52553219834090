import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ViewDefinition, ViewPropertyConfiguration } from '@contrail/client-views';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Observable } from 'rxjs';
import { PivotGridService } from '@components/pivot-grid/pivot-grid-service';

@Component({
  selector: 'app-pivot-column-header',
  templateUrl: './pivot-column-header.component.html',
  styleUrls: ['./pivot-column-header.component.scss'],
})
export class PivotColumnHeaderComponent implements OnInit {
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  @Input() fixedLabel: string;
  @Input() showGoals: boolean = false;
  @Input() viewDefinition: ViewDefinition;
  @Input() property: ViewPropertyConfiguration;
  @Output() resizeColumn: EventEmitter<any> = new EventEmitter();
  @Output() toggleRows: EventEmitter<any> = new EventEmitter();
  propertyIsFiltered$: Observable<boolean>;

  constructor() {}

  ngOnInit(): void {}

  columnResizeDragStart(event) {
    this.emitEvent('dragStart', event);
  }

  columnResizeDragMove(event) {
    this.emitEvent('dragMove', event);
  }

  columnResizeDrop(event) {
    this.emitEvent('dragEnd', event);
  }

  emitEvent(eventType: string, event) {
    this.resizeColumn.emit({
      eventType,
      event,
      property: this.property,
    });
  }

  expandRows() {
    this.toggleRows.emit({
      eventType: 'expand',
      property: this.property,
    });
  }

  collapseRows() {
    this.toggleRows.emit({
      eventType: 'collapse',
      property: this.property,
    });
  }
}
