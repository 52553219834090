import { Injectable } from '@angular/core';
import { Entities, Request } from '@contrail/sdk';
import { WorkspaceEntityHelper } from './workspace-entity-helper';
import { Workspace } from './workspaces-store/workspaces.state';
import { AuthService } from '@common/auth/auth.service';
import { SortDefinition, SortDirection } from '@components/sort/sort-definition';
import { SortObjects } from '@components/sort/sort-objects';
import { PropertyType } from '@contrail/types';

@Injectable({
  providedIn: 'root',
})
export class WorkspacesService {
  constructor(private authService: AuthService) {}

  public async getWorkspaces() {
    let workspaces = await new Entities().get({
      entityName: 'workspace',
      relations: ['workspacePrincipals'],
      criteria: { isArchived: false },
    });
    workspaces = this.sortWorkspaces(workspaces);
    return workspaces;
  }
  public async getWorkspaceById(id: string) {
    return new Entities().get({ entityName: 'workspace', id, relations: ['workspacePrincipals'] });
  }

  public async getUserRoleInWorkspace(workspaceId: string): Promise<{ role: string }> {
    return await Request.request(`/workspaces/${workspaceId}/callerRole`, {});
  }

  public async createWorkspace(workspace: Workspace) {
    return new Entities().create({ entityName: 'workspace', object: workspace });
  }
  public async deleteWorkspace(workspace: Workspace) {
    await new Entities().delete({ entityName: 'workspace', id: workspace.id });
    return workspace;
  }
  public async updateWorkspace(id: string, changes: Workspace) {
    return new Entities().update({ entityName: 'workspace', id, object: changes });
  }

  public async getWorkspaceEntities(workspace: Workspace) {
    const workspaceEntities = await new Entities().get({
      entityName: 'workspace-entity',
      criteria: { workspaceId: workspace.id, isTrashed: false },
      relations: ['entity'],
    });
    WorkspaceEntityHelper.postProcessWorkspaceEntities(workspaceEntities, workspace);
    return workspaceEntities;
  }
  public async getWorkspaceFolders(workspace: Workspace) {
    const workspaceEntities = await new Entities().get({
      entityName: 'workspace-entity',
      criteria: { workspaceId: workspace.id, entityType: 'workspace' },
      relations: ['entity'],
    });
    WorkspaceEntityHelper.postProcessWorkspaceEntities(workspaceEntities, workspace);
    return workspaceEntities;
  }

  private sortWorkspaces(workspaces: any) {
    const currentOrgConfig = this.authService.getCurrentOrg().orgConfig;
    let sortDefinitions: SortDefinition[] = [
      {
        propertySlug: 'name',
        direction: SortDirection.ASCENDING,
        propertyType: PropertyType.String,
      },
    ];
    if (currentOrgConfig?.projectSortField) {
      sortDefinitions = [
        {
          propertySlug: currentOrgConfig.projectSortField,
          direction:
            currentOrgConfig.projectSortDirection === 'asc' ? SortDirection.ASCENDING : SortDirection.DESCENDING,
          propertyType: ['createdOn', 'modifiedOn'].includes(currentOrgConfig.projectSortField)
            ? PropertyType.Date
            : PropertyType.String,
        },
      ];
    }
    workspaces = SortObjects.sort(workspaces, sortDefinitions);
    return workspaces;
  }

  async getPolicyOwnerForWorkspace(workspaceId: string): Promise<{ id: string } | null> {
    return new Entities().get({
      entityName: 'workspace',
      suffix: `${workspaceId}/policyOwner`,
    });
  }
}
