import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { PropertyType, PropertyValueFormatter, Type } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CollectionElementValidator } from 'src/app/collection-manager/collection-element-validator/collection-element-validator';
import {
  CollectionManagerActions,
  CollectionManagerSelectors,
} from 'src/app/collection-manager/collection-manager-store';
import { PlaceholderItemAssignmentService } from 'src/app/collection-manager/placeholders/placeholder-item-assignment-service';
import { GridSelectorService } from 'src/app/collection-manager/grid-view/selectors/grid-selector.service';
import { CommentsActions } from 'src/app/common/comments/comments-store';
import { ColorUtil } from 'src/app/common/util/color-util';
import { RootStoreState } from 'src/app/root-store';
import { CollectionDataEntity } from '../../../collection-manager.service';
import { GridViewManager } from '../../grid-view.manager';
import { DataCellEditComponent } from './data-cell-edit/data-cell-edit.component';
import { CollectionStatusMessage } from 'src/app/collection-manager/collection-manager-store/collection-status-messages/collection-status-messages.state';
import { DataCellValueChangeService } from './data-cell-value-change-handler';
import { OverrideOptionService } from '../../override-option/override-option-service';
import { CollectionElementActionValidator } from 'src/app/collection-manager/collection-element-validator/collection-element-action-validator';

const alwaysEditPropertyTypes = [
  PropertyType.SingleSelect,
  PropertyType.MultiSelect,
  PropertyType.TypeReference,
  PropertyType.ObjectReference,
  PropertyType.SizeRange,
  PropertyType.UserList,
];
const formatter = new PropertyValueFormatter();

export interface GridViewPropertyConfiguration extends ViewPropertyConfiguration {
  scope: string;
  propertyLevel: string;
}
@Component({
  selector: 'div[app-data-cell]',
  templateUrl: './data-cell.component.html',
  styleUrls: ['./data-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataCellComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<RootStoreState.State>,
    private placeholderItemService: PlaceholderItemAssignmentService,
    private gridSelectorService: GridSelectorService,
    private collectionElementValidator: CollectionElementValidator,
    private collectionElementActionValidator: CollectionElementActionValidator,
    private dataCellValueChangeService: DataCellValueChangeService,
    private overrideOptionService: OverrideOptionService,
    private gridService: GridViewManager,
    private currentElement: ElementRef,
    private renderer: Renderer2,
    private snackBar: MatSnackBar,
  ) {}

  @Input() property: GridViewPropertyConfiguration;
  @Input() data: CollectionDataEntity;
  @Input() collectionStatusMessages: any = [];
  @Input() remoteSelectedProperties: any = [];
  @Input() isCellRangeSelected: boolean;
  @Input() isCellCutSelected: boolean;
  @Input() isCellFillSelected: boolean;
  @Input() isCellSelected: boolean;
  @Input() isCellSearchReplaceSelected: boolean;
  @Input() isCellSearchReplaceActive: boolean;
  @Input() isCellOverrideOptionSelected: boolean;
  @Input() editorMode: string;
  @ViewChild('dataCellEditor', { static: false }) editCellComponent: DataCellEditComponent;
  public edit = false;
  public display = '';
  public value;
  public selected = false;
  public remoteSelected = false;
  public propertyType: PropertyType;
  public align = 'left';
  public editable;
  public rowId;
  public propertySlug;
  public remoteBorderColor;
  public remoteSelectedUser: any;
  public hasComment = false;
  public locked = false;
  public lockedMessage = null;
  public showLockedMessage = false;
  public valueCleared = false;
  public isDataOverridden = false;
  public overriddenValue;
  isStatusMessageSelected;
  isSearchReplaceActive;
  isRemoteSelected;

  private editableSubject = new BehaviorSubject(false);
  public editable$ = this.editableSubject;
  public statusMessages: Array<CollectionStatusMessage>;

  @HostListener('click', ['$event']) onClick(event) {
    this.handleClick(event);
  }
  @HostListener('mousedown', ['$event']) onMousedown(event) {
    this.cellSelectionStart(event);
  }
  @HostListener('mouseenter', ['$event']) onMouseenter(event) {
    this.cellSelectionInProgress(event);
  }
  @HostListener('mouseup', ['$event']) onMouseup(event) {
    this.cellSelectionEnded(event);
  }
  @HostListener('contextmenu', ['$event']) onContextMenu(event) {
    this.handleContextMenu();
  }
  ngOnInit() {
    this.rowId = this.data.id;
    this.propertySlug = this.property.slug;
  }

  ngOnChanges(changes) {
    // SHOULD REALLY VALIDATE IF ANYTHING IMPORTANT CHANGED
    // TO CUT BACK ON PROCESSING AS THERE ARE SO MANY CELLS IN THE DOM
    if (this.data && this.property) {
      this.setModel();
    }

    this.handleRemotePropertyChanges(changes);

    this.handleSelectedRangeChanges(changes);

    this.handleFillCellChanges(changes);

    this.handleCutCellChanges(changes);

    this.handleSearchReplaceCellChanges(changes);

    this.handleOverrideOptionCellChanges(changes);

    this.handleCollectionMessageStatusChanges(changes);

    if (changes.isCellSelected) {
      if (changes.isCellSelected.currentValue && !this.selected) {
        this.select();
      }
    }

    this.handleSearchReplaceCellActiveChange(changes);
    this.handleOverriddenValueChanges(changes);
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  async setModel() {
    this.rowId = this.data.id;
    this.propertySlug = this.property.slug;
    this.propertyType = this.property.propertyDefinition?.propertyType as PropertyType;
    this.value = this.getValue();
    this.display = this.getDisplay(this.value);
    this.align = this.getAlign();

    this.editable = await this.determineCanEdit();

    this.locked = !this.editable; // MAYBE WANT A DIFFERENT EQUATION HERE.
    if (this.align === 'right') {
      this.addClass('right-align');
    }
    if (!this.editable) {
      this.addClass('non-editable');
    } else {
      this.removeClass('non-editable');
    }

    if (this.collectionStatusMessages) {
      this.checkForError(this.collectionStatusMessages);
    }
  }

  async determineCanEdit() {
    const editCheck = await this.collectionElementValidator.isEditableForProperty(this.data, this.property);

    this.lockedMessage = editCheck.reason;
    const editable = editCheck.editable;
    return editable;
  }
  getValue() {
    if (PropertyType.TypeReference === this.property.propertyDefinition.propertyType) {
      return this.data[this.property.slug + 'Id'];
    }

    if (
      PropertyType.MultiSelect === this.property.propertyDefinition.propertyType &&
      this.data[this.property.slug] &&
      typeof this.data[this.property.slug] === 'string'
    ) {
      return this.data[this.property.slug].split(',');
    }

    return this.data[this.property.slug];
  }
  getDisplay(value) {
    if (this.isSystemGeneratedDate(value)) {
      value = new Date(value).toLocaleDateString();
    }

    if (PropertyType.MultiSelect === this.property.propertyDefinition.propertyType) {
      value = this.getMultiSelectValuesInOrderOfFamilyWhenEquivalent(value);
    }

    const display = formatter.formatValueForProperty(value, this.property.propertyDefinition);
    return display;
  }
  getAlign() {
    return [PropertyType.Percent, PropertyType.Currency, PropertyType.Number, PropertyType.Formula].includes(
      this.propertyType,
    )
      ? 'right'
      : 'left';
  }

  startEdit(clearValue = false) {
    if (!this.editable) {
      this.showLockedMessage = true;
      return;
    }
    this.showLockedMessage = false;
    this.edit = true;
    if (clearValue) {
      this.value = null;
      this.valueCleared = true;
    }
    this.changeDetectorRef.markForCheck();
  }
  endEdit() {
    if (!this.edit) {
      return;
    }
    this.edit = false;
    this.showLockedMessage = false;
    if (this.editCellComponent) {
      this.editCellComponent.completeEdit();
    }
    this.changeDetectorRef.markForCheck();
  }
  delete() {
    if (!this.editable) {
      return;
    }
    // allow backspace/delete to delete the ref value in the cell
    if ([PropertyType.ObjectReference, PropertyType.UserList].includes(this.property.propertyDefinition.propertyType)) {
      this.edit = false;
    }

    // We auto edit some properties... so we can't stop this on a subset of those.
    if (
      this.edit &&
      ![PropertyType.MultiSelect, PropertyType.SingleSelect, PropertyType.SizeRange].includes(
        this.property.propertyDefinition.propertyType,
      )
    ) {
      return;
    }
    console.log('Deleting.. ', this.propertySlug);

    // HATE THIS... BUT NEED TO DO IT CURRENTLY BECAUSE OUR FLOW IS DIFFERENT.
    if (this.property.slug === 'itemOption') {
      this.placeholderItemService.setItemOptionOnPlaceholder(this.data, null);
    } else if (this.property.slug === 'itemFamily') {
      this.placeholderItemService.setItemFamilyOnPlaceholder(this.data, null);
    } else {
      this.handleValueChange({ value: null });
    }

    // this.edit = true;
  }

  handleClick($event) {
    this.store.dispatch(CollectionManagerActions.clearSelectedEntityIds());
    this.store.dispatch(CollectionManagerActions.setSelectorKeyActive({ selectorKeyActive: false }));
    if ($event.shiftKey || $event.ctrlKey || $event.metaKey) {
      if ($event.ctrlKey || $event.metaKey) {
        this.select();
      }
      this.gridSelectorService.handleClick($event, this.rowId, this.propertySlug);
    } else {
      if ($event.detail > 1) {
        // double click
        this.select(true);
      } else {
        this.select();
      }
      this.gridSelectorService.removeSelector();
    }
  }

  async handleContextMenu() {
    const selectedRows = await this.store
      .pipe(select(CollectionManagerSelectors.selectedEntityIds), take(1))
      .toPromise();
    if (selectedRows.length === 0) {
      this.select();
    }
  }

  select(edit = false) {
    this.gridService.setSelectedCell(this);
    this.selected = true;

    // There is a desire to put a cell into edit mode as soon as it is selected for some property types
    if (alwaysEditPropertyTypes.includes(this.property.propertyDefinition.propertyType)) {
      edit = true;
    }
    if (edit) {
      this.startEdit();
    }
    this.setRemoteBorderColor();
    if (this.currentElement) {
      this.store.dispatch(
        CollectionManagerActions.setSelectedElementLocation({
          location: this.currentElement.nativeElement.getBoundingClientRect(),
        }),
      );
    }
    this.addClass('selected');
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
      // reset selected comment cell
      this.store.dispatch(CommentsActions.selectComment({ comment: null }));
    }, 1);
  }
  deselect() {
    this.endEdit();
    this.showLockedMessage = false;
    this.selected = false;
    this.setRemoteBorderColor();
    this.removeClass('selected');
    this.changeDetectorRef.markForCheck();
  }

  handleFocusOnCell(htmlElement) {
    if (htmlElement) {
      htmlElement.focus({ preventScroll: true });
      this.gridSelectorService.scrollHorizontallyToSelectedCell();
    }
  }

  /** Handels the change of a single cell */
  async handleValueChange(event) {
    if (this.property.slug === 'isDropped' && event.value === true) {
      const canDrop = this.isItemDroppable();
      if (!canDrop) return;
    }

    this.value = event.value;
    this.display = this.getDisplay(this.value);

    // Apply the change.
    this.dataCellValueChangeService.handleValueChange(this.property, this.data, event.value);

    if (this.propertyType === PropertyType.ObjectReference) {
      this.edit = false;
    }
  }

  getPropertyName() {
    const typeProperty = this.property.propertyDefinition;
    if ([PropertyType.ObjectReference, PropertyType.UserList].includes(typeProperty.propertyType)) {
      return typeProperty.slug + 'Id';
    }
    return typeProperty.slug;
  }

  remoteSelect(user) {
    this.remoteSelectedUser = user; // GAP, only covering one user at a time
    this.remoteSelected = true;
    this.setRemoteBorderColor();
    this.changeDetectorRef.markForCheck();
  }
  remoteDeselect() {
    this.remoteSelected = false;
    this.remoteSelectedUser = null;
    this.setRemoteBorderColor();
    this.changeDetectorRef.markForCheck();
  }
  setRemoteBorderColor() {
    if (this.selected || !this.remoteSelected) {
      this.remoteBorderColor = null;
      this.removeClass('remote-selected');
      this.removeStyle('border-color');
    } else {
      this.remoteBorderColor = ColorUtil.stringToHslaColor(this.remoteSelectedUser.clientId);
      this.addClass('remote-selected');
      this.addStyle('border-color', this.remoteBorderColor);
    }
  }
  showComments(e) {
    let x = e.clientX;
    const y = e.clientY;
    if (x + 420 > window.innerWidth) {
      x = window.innerWidth - 450;
    }
    const position = { x, y };
    this.showCommentsOverlay(position);
  }

  private async showCommentsOverlay(position) {
    const slugTypeProperty = await this.getProperty(this.property.slug);
    const property = {
      ...slugTypeProperty,
    };
    this.store.dispatch(
      CommentsActions.showCommentOverlay({
        ownerInfo: {
          entityType: 'plan-placeholder',
          id: this.data.id,
          property,
          value: this.value,
          suggestionValueEditable: this.editable,
        },
        position,
      }),
    );
  }
  async getProperty(slug: string) {
    let prop;
    this.store
      .select(CollectionManagerSelectors.typeDefinitions)
      .pipe(
        filter((defs) => !!defs),
        take(1),
        map((defs: { [key: string]: Type }) => {
          const props = defs['plan-placeholder']?.typeProperties;
          prop = props?.find((p) => p.slug === slug);
        }),
      )
      .subscribe();
    return { ...prop };
  }
  public getBoundingRectangle() {
    return this.currentElement.nativeElement.getBoundingClientRect();
  }

  cellSelectionStart(event) {
    if (event.button === 0) {
      // should only select with left-mouse click
      if (!event.shiftKey && !event.ctrlKey) {
        // event.preventDefault(); // removed so that input value can be selected for copying.
        this.gridService.setSelectedCell(this);
        this.selected = true;
        this.gridSelectorService.handleCellMousedown(event, this.rowId, this.propertySlug);
      }
    }
  }

  cellSelectionInProgress(event) {
    event.preventDefault();
    this.gridSelectorService.handleCellMouseover(event, this.rowId, this.propertySlug);
  }

  cellSelectionEnded(event) {
    event.preventDefault();
    this.gridSelectorService.handleCellMouseup(event, this.rowId, this.propertySlug);
  }
  setHasComment(val: boolean) {
    this.hasComment = val;
    this.changeDetectorRef.markForCheck();
  }

  handleEndEdit() {
    this.edit = false;
  }

  addClass(className: string) {
    this.renderer.addClass(this.currentElement.nativeElement, className);
  }

  removeClass(className: string) {
    this.renderer.removeClass(this.currentElement.nativeElement, className);
  }

  addStyle(styleProp: string, styleVal: string) {
    this.renderer.setStyle(this.currentElement.nativeElement, styleProp, styleVal);
  }

  removeStyle(styleProp: string) {
    this.renderer.removeStyle(this.currentElement.nativeElement, styleProp);
  }

  private handleSearchReplaceCellActiveChange(changes: any) {
    if (changes.isCellSearchReplaceActive?.currentValue !== changes.isCellSearchReplaceActive?.previousValue) {
      if (this.isCellSearchReplaceActive && !changes.isCellSearchReplaceActive?.previousValue) {
        this.addClass('active-search-replace-cell');
        this.changeDetectorRef.markForCheck();
      } else if (!this.isCellSearchReplaceActive && changes.isCellSearchReplaceActive?.previousValue) {
        this.removeClass('active-search-replace-cell');
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  private handleCollectionMessageStatusChanges(changes: any) {
    if (
      ObjectUtil.compareDeep(
        changes.collectionStatusMessages?.currentValue,
        changes.collectionStatusMessages?.previousValue,
        '',
      ).length > 0
    ) {
      const messages = changes.collectionStatusMessages?.currentValue;
      this.checkForError(messages);
    }
  }

  private checkForError(allMessages: CollectionStatusMessage[]) {
    this.statusMessages = allMessages?.filter((mes) => mes.propertySlug === this.propertySlug);
    if (this.statusMessages?.length > 0) {
      if (!this.isStatusMessageSelected) {
        this.isStatusMessageSelected = true;
        this.addClass('text-rose-600');
        console.log('isStatusMessageSelected');
        this.changeDetectorRef.markForCheck();
      }
    } else {
      if (this.isStatusMessageSelected) {
        this.isStatusMessageSelected = false;
        this.removeClass('text-rose-600');
        console.log('not isStatusMessageSelected');
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  private handleSearchReplaceCellChanges(changes: any) {
    if (changes.isCellSearchReplaceSelected?.currentValue !== changes.isCellSearchReplaceSelected?.previousValue) {
      if (this.isCellSearchReplaceSelected && !changes.isCellSearchReplaceSelected?.previousValue) {
        this.addClass('range-selected');
        this.changeDetectorRef.markForCheck();
      } else if (!this.isCellSearchReplaceSelected && changes.isCellSearchReplaceSelected?.previousValue) {
        this.removeClass('range-selected');
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  private handleOverrideOptionCellChanges(changes: any) {
    if (changes.isCellOverrideOptionSelected?.currentValue !== changes.isCellOverrideOptionSelected?.previousValue) {
      if (this.isCellOverrideOptionSelected && !changes.isCellOverrideOptionSelected?.previousValue) {
        this.addClass('overridden-option');
        this.changeDetectorRef.markForCheck();
      } else if (!this.isCellOverrideOptionSelected && changes.isCellOverrideOptionSelected?.previousValue) {
        this.removeClass('overridden-option');
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  private handleRemotePropertyChanges(changes: any) {
    if (changes.remoteSelectedProperties?.currentValue !== changes.remoteSelectedProperties?.previousValue) {
      const index = changes.remoteSelectedProperties?.currentValue.findIndex(
        (property) => property.propertySlug === this.propertySlug,
      );
      if (index > -1) {
        if (!this.isRemoteSelected) {
          this.isRemoteSelected = true;
          this.remoteSelect(changes.remoteSelectedProperties?.currentValue[index]);
        }
      } else {
        if (this.isRemoteSelected) {
          this.isRemoteSelected = false;
          this.remoteDeselect();
        }
      }
    }
  }

  private handleCutCellChanges(changes: any) {
    if (changes.isCellCutSelected?.currentValue !== changes.isCellCutSelected?.previousValue) {
      if (this.isCellCutSelected && !changes.isCellCutSelected?.previousValue) {
        this.addClass('range-cut');
        this.changeDetectorRef.markForCheck();
      } else if (!this.isCellCutSelected && changes.isCellCutSelected?.previousValue) {
        this.removeClass('range-cut');
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  private handleFillCellChanges(changes: any) {
    if (changes.isCellFillSelected?.currentValue !== changes.isCellFillSelected?.previousValue) {
      if (this.isCellFillSelected && !changes.isCellFillSelected?.previousValue) {
        this.addClass('fill-cell');
        this.changeDetectorRef.markForCheck();
      } else if (!this.isCellFillSelected && changes.isCellFillSelected?.previousValue) {
        this.removeClass('fill-cell');
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  private handleSelectedRangeChanges(changes: any) {
    if (changes.isCellRangeSelected?.currentValue !== changes.isCellRangeSelected?.previousValue) {
      if (this.isCellRangeSelected && !changes.isCellRangeSelected?.previousValue) {
        this.addClass('range-selected');
        this.changeDetectorRef.markForCheck();
      } else if (!this.isCellRangeSelected && changes.isCellRangeSelected?.previousValue) {
        this.removeClass('range-selected');
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  private handleOverriddenValueChanges(changes: any) {
    this.isDataOverridden = false;
    if (!this.data.itemOption) {
      return;
    }

    const isDataCellOverridable = Boolean(this.data.itemOption && this.property?.propertyLevel === 'overridable');
    if (isDataCellOverridable) {
      if (this.property.scope === 'item') {
        this.isDataOverridden = this.overrideOptionService.isDataOverridden(
          this.property.propertyDefinition,
          this.data.itemFamily,
          this.data.itemOption,
        );
      } else if (this.property.scope === 'project-item') {
        this.isDataOverridden = this.overrideOptionService.isDataOverridden(
          this.property.propertyDefinition,
          this.data.itemFamily.projectItem,
          this.data.itemOption.projectItem,
        );
      }
      this.overriddenValue = this.getFamilyValue() || '';
    }
  }

  private getFamilyValue() {
    const propSlug = this.property?.propertyDefinition?.slug;
    if (this.property.scope === 'project-item' && this.data.itemFamily.projectItem) {
      return this.data.itemFamily.projectItem[propSlug];
    }

    return this.data.itemFamily[propSlug];
  }

  private getMultiSelectValuesInOrderOfFamilyWhenEquivalent(value: Array<any>) {
    if (PropertyType.MultiSelect !== this.property.propertyDefinition.propertyType || !value || !this.data.itemFamily) {
      return value;
    }

    if (this.property.scope !== 'project-item' && this.property.scope !== 'item') {
      return value;
    }

    const familyData = this.property.scope === 'project-item' ? this.data.itemFamily.projectItem : this.data.itemFamily;
    if (ObjectUtil.areItemPropertyValuesEqual(this.property.propertyDefinition, this.data, familyData)) {
      return familyData[this.property.slug];
    }

    return value;
  }

  private isSystemGeneratedDate(dateValue) {
    return (
      dateValue &&
      PropertyType.Date === this.property.propertyDefinition.propertyType &&
      ['createdOn', 'updatedOn'].includes(this.property.slug)
    );
  }

  private isItemDroppable() {
    const dropCheck = this.collectionElementActionValidator.isDroppable(this.data);
    if (!dropCheck.isValid) {
      this.snackBar.open(dropCheck.reason, '', { duration: 5000 });
      this.editCellComponent.resetValue();
      this.edit = false;
      return false;
    }

    return true;
  }
}
