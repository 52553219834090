import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Entities, Types } from '@contrail/sdk';
import { PropertyType, PropertyValueFormatter, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { UndoRedoService } from 'src/app/common/undo-redo/undo-redo-service';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionElementValidator } from '../../collection-element-validator/collection-element-validator';
import { CollectionElementActionValidator } from '../../collection-element-validator/collection-element-action-validator';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { GridViewManager } from '../grid-view.manager';
import { PlaceholderItemAssignmentService } from '../../placeholders/placeholder-item-assignment-service';
import { PlaceholderItemUpdateService } from '../../placeholders/placeholder-item-update-service';
import { SelectorCell } from './grid-selector.service';
import { FillDataInfo, PasteDataInfo } from '../copy-paste/paste-data-info';
import { CopyPasteUtil } from '../copy-paste/copy-paste-util';
import { FillPasteUtil } from '../copy-paste/fill-paste-util';
import { UndoRedoActionType, UndoRedoObject } from '../../undo-redo/undo-redo-objects';
import { CollectionItemUpdateMap, GridSelectorActionUtil } from './grid-selector-action-util';
import { SizeRangeHelper } from '@common/size-range/size-range-helper';
import { PlaceholderItemPromoteService } from '../../placeholders/placeholder-item-promote-service';
import { OverrideOptionService } from '../override-option/override-option-service';
import { TypePropertyUserListService } from '@common/user-list/user-list.service';
import { BatchUpdateItemsRequest, ItemUpdateService } from '../../items/item-update-service';
import {
  BatchUpdateProjectItemsRequest,
  ProjectItemUpdateService,
} from '../../project-items/project-item-update-service';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';

const propertyValueFormatter: PropertyValueFormatter = new PropertyValueFormatter();
const ignoredProperties = ['createdOn', 'updatedOn'];

interface ItemUpdateChanges {
  changes: any[];
  placeholderUpdates: any[];
}

@Injectable({
  providedIn: 'root',
})
export class GridSelectorActionHandler {
  private selectorCells: Array<SelectorCell>;
  private selectedRows: Array<any>;
  private data: any;
  private cutCells: Array<SelectorCell>;
  private viewProperties: Array<any>;
  private copyPasteUtil: CopyPasteUtil;
  private fillPasteUtil: FillPasteUtil;
  private gridSelectorActionUtil: GridSelectorActionUtil;
  private editorMode: string = EditorMode.VIEW;

  constructor(
    private store: Store<RootStoreState.State>,
    private collectionElementValidator: CollectionElementValidator,
    private collectionElementActionValidator: CollectionElementActionValidator,
    private undoRedoService: UndoRedoService,
    private gridViewManager: GridViewManager,
    private placeholderItemAssignmentService: PlaceholderItemAssignmentService,
    private placeholderItemPromoteService: PlaceholderItemPromoteService,
    private placeholderItemUpdateService: PlaceholderItemUpdateService,
    private userListService: TypePropertyUserListService,
    private itemUpdateService: ItemUpdateService,
    private projectItemUpdateService: ProjectItemUpdateService,
    private overrideOptionService: OverrideOptionService,
    private snackBar: MatSnackBar,
  ) {
    this.store
      .select(CollectionManagerSelectors.selectorCells)
      .subscribe((selectorCells) => (this.selectorCells = selectorCells));
    this.store
      .select(CollectionManagerSelectors.currentViewProperties)
      .subscribe((props) => (this.viewProperties = props));
    this.store.select(CollectionManagerSelectors.displayedData).subscribe((data) => (this.data = data));
    this.store.select(CollectionManagerSelectors.cutCells).subscribe((cutCells) => (this.cutCells = cutCells));
    this.store
      .select(CollectionManagerSelectors.selectedEntityIds)
      .subscribe((selectedRows) => (this.selectedRows = selectedRows));
    this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
    this.copyPasteUtil = new CopyPasteUtil(this.gridViewManager);
    this.fillPasteUtil = new FillPasteUtil(this.gridViewManager);
    this.gridSelectorActionUtil = new GridSelectorActionUtil(
      this.store,
      this.collectionElementValidator,
      this.collectionElementActionValidator,
      this.placeholderItemAssignmentService,
      this.overrideOptionService,
      userListService,
      this.snackBar,
    );
  }

  public async clearRowData() {
    if (this.editorMode !== EditorMode.EDIT) return;

    const updates = [];
    let errorMessage: string;
    const changesList: UndoRedoObject[] = [];
    for (const rowId of this.selectedRows) {
      const selectedRowIndex = this.data.findIndex((row) => row.id === rowId);
      const entity = ObjectUtil.cloneDeep(this.data[selectedRowIndex]);
      const clearCheck = this.collectionElementActionValidator.isClearable(entity);
      if (!clearCheck.isValid) {
        errorMessage = clearCheck.reason;
        continue;
      }

      const changes = {};
      const undoChanges = {};
      for (const property of this.viewProperties) {
        // CHECK IS EDITABLE
        if (entity[property.slug] && !ignoredProperties.includes(property.slug)) {
          changes[property.slug] = null;
          undoChanges[property.slug] = entity[property.slug];
        }
        if (entity['itemFamilyId']) {
          changes['itemFamilyId'] = null;
          undoChanges['itemFamilyId'] = entity['itemFamilyId'];
        }
        if (entity['itemOptionId']) {
          changes['itemOptionId'] = null;
          undoChanges['itemOptionId'] = entity['itemOptionId'];
        }
      }
      if (Object.keys(changes).length > 0) {
        updates.push({ id: rowId, changes });
        changesList.push({ id: rowId, changes, undoChanges });
      }
    }

    if (errorMessage) {
      this.snackBar.open(errorMessage, '', { duration: 5000 });
    }

    const undoActionUuid = this.addUndoActionAndGetUuid(changesList, null);
    if (updates.length > 0) {
      this.updatePlaceholders(updates, undoActionUuid);
    }

    this.store.dispatch(CollectionManagerActions.removeSelectedEntityIds({ ids: this.selectedRows }));
  }

  public async deleteCells(undoRedoList?) {
    if (this.editorMode !== EditorMode.EDIT) return;

    const updates = [];
    let affectedCollectionElementRows = this.copyPasteUtil.groupBy(this.selectorCells, 'rowId');
    if (this.cutCells.length > 0) {
      affectedCollectionElementRows = this.copyPasteUtil.groupBy(this.cutCells, 'rowId');
    }
    const changesList: UndoRedoObject[] = [];
    const itemFamilyUpdates = {};
    const itemOptionUpdates = {};
    const projectItemUpdates = {};
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
    for (const rowId of Object.keys(affectedCollectionElementRows)) {
      const selectedRowIndex = this.data.findIndex((row) => row.id === rowId);
      const entity = ObjectUtil.cloneDeep(this.data[selectedRowIndex]);
      const changes = {};
      const undoChanges = {};
      const wasFamilyRemovedFromRow = Boolean(
        entity?.itemFamily &&
          affectedCollectionElementRows[rowId].some((property) => property.columnId === 'itemFamily'),
      );

      const wasOptionRemovedFromRow = Boolean(
        entity?.itemOption &&
          affectedCollectionElementRows[rowId].some((property) => property.columnId === 'itemOption'),
      );

      for (const property of affectedCollectionElementRows[rowId]) {
        const viewProperty = this.viewProperties.find((viewPropertyDef) => viewPropertyDef.slug === property.columnId);

        // CHECK IS EDITABLE
        const editCheck = await this.collectionElementValidator.isEditableFromId(entity.id, property.columnId);
        const editable = editCheck.editable;
        // Fetch collection element from store for id
        // Fetch types from type map or types api..(cached)
        // apply logic for determining if property on that object editable
        //    -- including item binding status check for concept..
        if (editable && property.columnId !== 'thumbnail') {
          const hasItemFamilyAlreadyBeenCleared = changes.hasOwnProperty('itemFamily');
          if (!hasItemFamilyAlreadyBeenCleared) {
            if (viewProperty.slug === 'itemOption' && entity.itemOption) {
              await this.clearItemOptionFromEntity(entity, changes, undoChanges);
            } else if (viewProperty.slug === 'itemFamily' && entity.itemFamily) {
              await this.clearItemFamilyFromEntity(entity, changes, undoChanges);
            }
          }

          if (wasFamilyRemovedFromRow && (viewProperty.scope === 'item' || viewProperty.scope === 'project-item')) {
            continue;
          }

          if (wasOptionRemovedFromRow && viewProperty.scope === 'item' && viewProperty.propertyLevel === 'option') {
            continue;
          }

          if (
            wasOptionRemovedFromRow &&
            viewProperty.scope === 'project-item' &&
            viewProperty.propertyLevel === 'option'
          ) {
            continue;
          }

          let typeProperty: TypeProperty;
          if (viewProperty.scope === 'project-item') {
            typeProperty = projectItemType.typeProperties.find((p) => p.slug === viewProperty.slug);
          }

          if (viewProperty.scope === 'item' && entity.itemFamily) {
            typeProperty = await new Types().getProperty(entity.itemFamily.typeId, property.columnId);
            if (entity.itemFamily && typeProperty.propertyLevel !== 'option' && viewProperty.slug !== 'optionName') {
              this.gridSelectorActionUtil.collectItemUpdateInfo(entity, itemFamilyUpdates, typeProperty, null);
            } else if (
              entity.itemOption &&
              (viewProperty.slug === 'optionName' || typeProperty.propertyLevel !== 'family')
            ) {
              this.gridSelectorActionUtil.collectItemUpdateInfo(entity, itemOptionUpdates, typeProperty, null);
            } else {
              // place-holder changes only without item option
              changes[property.columnId] = null;
              undoChanges[property.columnId] = entity[property.columnId];
            }
          } else if (
            viewProperty.scope === 'project-item' &&
            ((entity.itemFamily && typeProperty?.propertyLevel !== 'option') ||
              (entity.itemOption && typeProperty?.propertyLevel !== 'family'))
          ) {
            const typeProperty = projectItemType.typeProperties.find((p) => p.slug === viewProperty.slug);
            this.gridSelectorActionUtil.collectProjectItemUpdateInfo(entity, projectItemUpdates, typeProperty, null);
          } else {
            changes[property.columnId] = null;
            undoChanges[property.columnId] = entity[property.columnId];
            if (
              [PropertyType.ObjectReference, PropertyType.UserList].includes(
                viewProperty.propertyDefinition?.propertyType,
              )
            ) {
              changes[property.columnId + 'Id'] = null;
              undoChanges[property.columnId + 'Id'] = entity[property.columnId + 'Id'];
            }
          }
        }
      }

      if (Object.keys(changes).length > 0) {
        updates.push({ id: rowId, changes });
        changesList.push({ id: rowId, changes, undoChanges });
      }
    }

    this.applyAllItemUpdates(
      updates,
      itemFamilyUpdates,
      itemOptionUpdates,
      projectItemUpdates,
      changesList,
      undoRedoList,
    );
  }

  public async handlePasteDataFromCellsAbove() {
    const placeholderType = await new Types().getType({ root: 'plan-placeholder', path: 'plan-placeholder' });
    let data = null;
    if (this.selectorCells.length > 0) {
      // multiple cells selected
      const affectedCollectionElementRows = this.copyPasteUtil.groupBy(this.selectorCells, 'rowId');
      const rowIds = Object.keys(affectedCollectionElementRows);
      if (rowIds.length === 1) {
        const index = this.data.findIndex((row) => row.id === rowIds[0]);
        if (index > 0) {
          data = '';
          const propSlugs = affectedCollectionElementRows[rowIds[0]].map((cell) => cell.columnId);
          const properties = propSlugs.map((prop) =>
            placeholderType.typeProperties.find((property) => property.slug === prop),
          );
          data = await this.copyPasteUtil.generateClipboardRowsData(this.data, [this.data[index - 1].id], properties);
        }
      }
    } else if (this.gridViewManager.selectedCell) {
      // single cell selected
      const index = this.data.findIndex((row) => row.id === this.gridViewManager.selectedCell.rowId);
      if (index > 0) {
        const propertyDefinition = placeholderType.typeProperties.find(
          (prop) => prop.slug === this.gridViewManager.selectedCell.propertySlug,
        );
        data = await this.copyPasteUtil.generateClipboardRowsData(
          this.data,
          [this.data[index - 1].id],
          [propertyDefinition],
        );
      }
    } else if (this.selectedRows.length === 1) {
      // one row only selected
      const index = this.data.findIndex((row) => row.id === this.selectedRows[0]);
      if (index > 0) {
        data = await this.copyPasteUtil.generateClipboardRowsData(
          this.data,
          [this.data[index - 1].id],
          this.viewProperties.map((viewProperty) => viewProperty.propertyDefinition),
        );
      }
    }
    if (data) {
      this.applyChangesFromClipboard(data);
    }
  }

  public async handleFillCellValues(targetRows: Array<string>, sourceRows: any[], properties: any[]) {
    const targetColumns = this.viewProperties.filter((property) => properties.includes(property.slug));
    const sourceRowData = this.data.filter((rowData) => sourceRows.includes(rowData.id));
    if (
      this.data.findIndex((row) => row.id === targetRows[0]) < this.data.findIndex((row) => row.id === sourceRows[0])
    ) {
      targetRows.reverse(); // reverse the rows because this is dragging up
      sourceRowData.reverse();
    }
    const fillDataInfo: FillDataInfo = await this.fillPasteUtil.generateFillData(
      sourceRowData,
      properties,
      this.viewProperties,
      targetRows.length,
    );
    const pasteDataInfo: PasteDataInfo = {
      targetColumns,
      targetRows,
      clipboardData: fillDataInfo.fillData,
      localStorageRowIds: fillDataInfo.localStorageRowIds,
    };
    this.applyChangesFromClipboard('', null, pasteDataInfo);
  }

  public handleSpreadValues(spreadValues: any[], columnId, targetRows) {
    const targetColumns = [this.viewProperties.find((property) => property.slug === columnId)];
    const spreadDataInfo: PasteDataInfo = { targetColumns, targetRows, clipboardData: spreadValues };
    this.applyChangesFromClipboard('', null, spreadDataInfo);
  }

  public async handleFillRowCells(selectedCells: any[]) {
    const propertyGroup = Object.keys(this.copyPasteUtil.groupBy(selectedCells, 'columnId'));
    const sourceCells =
      selectedCells.length > 0
        ? ObjectUtil.cloneDeep(selectedCells)
        : [
            {
              rowId: this.gridViewManager.selectedCell.rowId,
              columnId: this.gridViewManager.selectedCell.propertySlug,
            },
          ];
    const sourceRows = Object.keys(this.copyPasteUtil.groupBy(sourceCells, 'rowId'));
    const startFillRowIndex =
      sourceRows.length > 0
        ? this.data.findIndex((rowData) => rowData.id === sourceRows[sourceRows.length - 1])
        : this.data.findIndex((rowData) => rowData.id === this.gridViewManager.selectedCell.rowId);
    const properties = propertyGroup.length > 0 ? propertyGroup : [this.gridViewManager.selectedCell.propertySlug];
    const visibleProperties = this.viewProperties.filter((property) => property.enabled);
    const targetRows = this.fillPasteUtil.getFillRowTargetRows(
      this.data,
      visibleProperties,
      properties,
      startFillRowIndex,
    );
    await this.handleFillCellValues(targetRows, sourceRows, properties);
  }

  /** Takes a set of clipboard data (rows/colums matrix) and makes changes.
   * Also mutates a set of undo
   */
  public async applyChangesFromClipboard(clipboardTextData, undoRedoList?: any[], customPasteDataInfo?: PasteDataInfo) {
    console.log('applyChangesFromClipboard: ', clipboardTextData, undoRedoList, customPasteDataInfo);
    const pasteDataInfo: PasteDataInfo =
      customPasteDataInfo ||
      this.copyPasteUtil.generatePasteDataInfo(
        clipboardTextData,
        this.data,
        this.viewProperties,
        this.selectedRows,
        this.selectorCells,
      );
    const { targetColumns, targetRows, clipboardData, localStorageRowIds } = pasteDataInfo;
    const changesList: UndoRedoObject[] = [];
    const itemFamilyUpdates = {};
    const itemOptionUpdates = {};
    const projectItemUpdates = {};
    const updates = [];
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
    for (let rowIndex = 0; rowIndex < targetRows.length; rowIndex++) {
      const rowId = targetRows[rowIndex];
      const selectedRowIndex = this.data.findIndex((entityRow) => entityRow.id === rowId);
      const entity = ObjectUtil.cloneDeep(this.data[selectedRowIndex]); // entity is the targetRow
      const originalEntity = ObjectUtil.cloneDeep(entity);
      const changes = {};
      const undoChanges = {};
      const columnValues = clipboardData[rowIndex].split('\t');
      const itemFamilyPropIndex = targetColumns.findIndex((targetColumn) => targetColumn.slug === 'itemFamily');
      const itemOptionPropIndex = targetColumns.findIndex((targetColumn) => targetColumn.slug === 'itemOption');
      const sizeRangeTemplateProp = SizeRangeHelper.sizeRangeTemplateProperty;
      const sizeRangeTemplatePropIndex = targetColumns.findIndex(
        (targetColumn) => targetColumn.slug === sizeRangeTemplateProp,
      );

      const pasteMode = columnValues[itemFamilyPropIndex] ? 'rows' : 'cells';

      // If one of the things we are pasting is the item family, we need
      // to assign item family values to the placeholder via the standard mappings.
      if (columnValues[itemFamilyPropIndex]) {
        await this.gridSelectorActionUtil.handlePasteItemFamilyData(
          this.data,
          entity,
          originalEntity,
          columnValues,
          itemFamilyPropIndex,
          itemOptionPropIndex,
          changes,
          undoChanges,
          localStorageRowIds,
          rowIndex,
        );
      }

      // Iterate over each 'column' of the paste data and apply.  This section is only here
      for (let columnIndex = 0; columnIndex < columnValues.length; columnIndex++) {
        const viewProperty = targetColumns[columnIndex];

        let typeProperty: TypeProperty;
        if (viewProperty?.scope === 'item') {
          typeProperty = entity.itemFamily
            ? await new Types().getProperty(entity.itemFamily.typeId, viewProperty.slug)
            : viewProperty.propertyDefinition; // get the property from the item type
        } else if (viewProperty?.scope === 'project-item') {
          typeProperty = projectItemType.typeProperties.find((p) => p.slug === viewProperty.slug);
        } else if (entity.itemFamily && viewProperty?.slug === 'itemType') {
          typeProperty = ObjectUtil.cloneDeep(viewProperty.propertyDefinition);
          typeProperty.slug = 'typeId';
          typeProperty.propertyLevel = 'family';
        }
        if (sizeRangeTemplatePropIndex > -1) {
          const sizeRangeTemplate = await this.gridSelectorActionUtil.parseObjRefValue(
            this.data,
            columnValues[sizeRangeTemplatePropIndex],
            targetColumns[sizeRangeTemplatePropIndex],
          );
          if (sizeRangeTemplate) {
            entity[sizeRangeTemplateProp] = sizeRangeTemplate;
          }
        }
        if (
          this.gridSelectorActionUtil.isPasteAllowed(
            entity,
            viewProperty,
            columnValues,
            itemFamilyPropIndex,
            itemOptionPropIndex,
            typeProperty,
          )
        ) {
          let value = columnValues[columnIndex];
          if (typeof value === 'string' && value.trim() === '') {
            // an empty cell that is copied to the clibpboard is a space.
            value = null;
          } else {
            if (
              viewProperty.propertyDefinition.propertyType === 'object_reference' &&
              !['itemFamily', 'itemOption'].includes(viewProperty.slug)
            ) {
              value = await this.gridSelectorActionUtil.parseObjRefValue(
                this.data,
                value,
                viewProperty.propertyDefinition,
              );
            } else if (viewProperty.propertyDefinition.propertyType === 'type_reference') {
              value = await this.gridSelectorActionUtil.parseTypeReferenceValue(viewProperty.propertyDefinition, value);
            } else if (viewProperty.propertyDefinition.propertyType === PropertyType.UserList) {
              value = await this.gridSelectorActionUtil.parseUserList(
                this.data,
                value,
                viewProperty.propertyDefinition,
              );
            } else {
              // we get the value from the display.. this is so we can paste the displays to/from excel, etc.
              value = propertyValueFormatter.parseValue(value, viewProperty.propertyDefinition);
            }
          }
          // We are goign to pass a map of 'updates' to the collectUpdatesFromPasteData function
          // Depending on the scope of the property being modified, we collect into a different
          // 'targetedUpdates' map, so we can send the updates to the correct update logic later (item, project-item, etc)
          let targetedUpdates;
          if (viewProperty.scope === 'item' || typeProperty?.slug === 'typeId') {
            if (typeProperty?.propertyLevel && typeProperty?.propertyLevel !== 'family') {
              // TO DO May need to adjust this for 'all' and 'inherit'
              if (entity.itemOption) {
                targetedUpdates = itemOptionUpdates;
                const itemFamilyLCStateInfo = await this.placeholderItemPromoteService.synchItemFamilyLifecycle(
                  entity,
                  typeProperty,
                  value,
                );
                if (itemFamilyLCStateInfo) {
                  itemFamilyUpdates[entity.itemFamilyId] = itemFamilyLCStateInfo;
                }
              }
              if (this.overrideOptionService.isOverrideChangesOnFamily(typeProperty, entity, 'item')) {
                targetedUpdates = itemFamilyUpdates;
              } else {
                targetedUpdates = itemOptionUpdates;
              }
            } else {
              targetedUpdates = itemFamilyUpdates;
            }
          } else if (viewProperty.scope === 'project-item') {
            targetedUpdates = projectItemUpdates;
          } else {
            targetedUpdates = {};
          }
          await this.gridSelectorActionUtil.collectUpdatesFromPasteData(
            typeProperty,
            viewProperty,
            entity,
            originalEntity,
            value,
            changes,
            undoChanges,
            targetedUpdates,
            pasteMode,
          );
        }
      }
      console.log(
        'applyChangesFromClipboard: targetedUpdates: family: ',
        itemFamilyUpdates,
        ' option: ',
        itemOptionUpdates,
        ' project-item: ',
        projectItemUpdates,
      );
      console.log('applyChangesFromClipboard: changes', changes);
      // Add all changes to the placeholder.. regardless of item, etc.
      if (Object.keys(changes).length > 0) {
        updates.push({ id: rowId, changes });
        changesList.push({ id: rowId, changes, undoChanges });
      }
    }

    this.applyAllItemUpdates(
      updates,
      itemFamilyUpdates,
      itemOptionUpdates,
      projectItemUpdates,
      changesList,
      undoRedoList,
    );
  }

  public async applyChangesFromClipboardAndDeleteCells(clipboardTextData) {
    const changesList: UndoRedoObject[] = [];
    this.deleteCells(changesList);
    await this.applyChangesFromClipboard(clipboardTextData, changesList);
    this.undoRedoService.addUndo({ actionType: UndoRedoActionType.UPDATE_PLACEHOLDER, changesList });
    this.store.dispatch(CollectionManagerActions.setCutCells({ cutCells: [] }));
  }

  public async promotePlaceholders() {
    let ids: Array<string> = [];
    if (this.selectedRows.length > 0) {
      ids = this.selectedRows;
    } else if (this.selectorCells.length > 0) {
      const affectedCollectionElementRows = this.copyPasteUtil.groupBy(this.selectorCells, 'rowId');
      ids = Object.keys(affectedCollectionElementRows);
    } else if (this.gridViewManager.selectedCell) {
      ids = [this.gridViewManager.selectedCell.rowId];
    }
    if (ids.length > 0) {
      const placeholders = this.data.filter((obj) => ids.includes(obj.id));
      this.placeholderItemPromoteService.promotePlaceholderItems(placeholders);
    }
  }

  private async applyAllItemUpdates(
    placeholderUpdates: { id: string; changes: any }[],
    itemUpdates: CollectionItemUpdateMap,
    optionUpdates: CollectionItemUpdateMap,
    projectItemUpdates: CollectionItemUpdateMap,
    changesList: UndoRedoObject[],
    undoRedoList: any[],
  ) {
    const updateItemsPayload = await this.buildBatchUpdateItemsRequest(itemUpdates, optionUpdates, changesList);
    const updateProjectItemsPayload = this.buildBatchUpdateProjectItemsRequest(projectItemUpdates, changesList);
    const undoUuid = this.addUndoActionAndGetUuid(changesList, undoRedoList);

    if (placeholderUpdates?.length) {
      this.updatePlaceholders(placeholderUpdates, undoUuid);
    }

    setTimeout(async () => {
      // needs to delay this to allow the placeholders in the store to update.
      if (updateItemsPayload) {
        await this.itemUpdateService.batchUpdateItems({ ...updateItemsPayload, undoUuid });
      }

      if (updateProjectItemsPayload) {
        this.projectItemUpdateService.batchUpdateProjectItems({ ...updateProjectItemsPayload, undoUuid });
      }
    }, 1);
  }

  /** Applies a set of item updates:  Updating items via the API, and then applying
   * those changes to the plan/placeholders using placeholderItemUpdateService (which triggers broadcast, etc)
   */
  private async buildBatchUpdateItemsRequest(
    itemUpdates: CollectionItemUpdateMap,
    optionUpdates: CollectionItemUpdateMap,
    changesList: UndoRedoObject[],
  ): Promise<BatchUpdateItemsRequest> {
    if (!Object.keys(itemUpdates)?.length && !Object.keys(optionUpdates)?.length) {
      return;
    }

    const itemFamilyUpdateChanges = await this.buildItemUpdates(itemUpdates, changesList, 'family');
    const itemOptionUpdateChanges = await this.buildItemUpdates(optionUpdates, changesList, 'option');

    const mergedItemChanges = itemOptionUpdateChanges.changes.concat(itemFamilyUpdateChanges.changes);
    const mergedPlaceholderUpdates = itemOptionUpdateChanges.placeholderUpdates.concat(
      itemFamilyUpdateChanges.placeholderUpdates,
    );

    return {
      changes: mergedItemChanges,
      placeholderUpdates: {
        updates: mergedPlaceholderUpdates,
        skipRecordingUndoRedo: true,
        optionUpdates,
      },
    };
  }

  private async buildItemUpdates(
    itemUpdates: CollectionItemUpdateMap,
    changesList: UndoRedoObject[],
    level: string,
  ): Promise<ItemUpdateChanges> {
    if (!itemUpdates || Object.keys(itemUpdates).length === 0) {
      return { changes: [], placeholderUpdates: [] };
    }

    const changes = [];
    const updates = [];
    for (let itemId of Object.keys(itemUpdates)) {
      const itemChanges = ObjectUtil.cloneDeep(itemUpdates[itemId].changes);
      const itemUndoChanges = ObjectUtil.cloneDeep(itemUpdates[itemId].undoChanges);
      const item = ObjectUtil.cloneDeep(itemUpdates[itemId].item);
      await this.placeholderItemUpdateService.deriveOptionNameIfApplicable(item, itemChanges);
      changesList.push({
        id: itemId,
        changes: itemChanges,
        undoChanges: itemUndoChanges,
        scope: 'item',
        level,
      });
      changes.push({
        id: itemId,
        changes: this.formatChangesForPersistence(itemChanges),
      });
      updates.push({
        item,
        changes: itemChanges,
      });
    }

    return {
      changes: changes,
      placeholderUpdates: updates,
    };
  }

  /** Applies a set of project-item updates:  Updating project-items via the API, and then applying
   * those changes to the plan/placeholders using batchUpdateProjectItems (which triggers broadcast, etc)
   */
  private buildBatchUpdateProjectItemsRequest(
    projectItemUpdates,
    changesList: UndoRedoObject[],
    level: string = 'option',
  ): BatchUpdateProjectItemsRequest {
    if (!Object.keys(projectItemUpdates)?.length) {
      return;
    }

    console.log('updateProjectItems: ', projectItemUpdates, changesList);
    const changes = [];
    const updates = [];
    Object.keys(projectItemUpdates).forEach((itemId) => {
      changesList.push({
        id: itemId,
        changes: projectItemUpdates[itemId].changes,
        undoChanges: projectItemUpdates[itemId].undoChanges,
        scope: 'project-item',
        level: projectItemUpdates[itemId].item.roles.includes('color') ? 'option' : 'family',
      });
      changes.push({
        id: itemId,
        changes: this.formatChangesForPersistence(projectItemUpdates[itemId].changes),
      });
      updates.push({
        item: projectItemUpdates[itemId].item,
        changes: projectItemUpdates[itemId].changes,
      });
    });

    return {
      changes,
      placeholderUpdates: {
        updates,
        skipRecordingUndoRedo: true,
      },
    };
  }

  private updatePlaceholders(entities: any[], undoActionUuid?: string) {
    this.store.dispatch(
      CollectionManagerActions.updateCollectionDataEntities({
        entities,
        skipRecordingUndoRedo: true,
        undoActionUuid,
      }),
    );
  }

  private addUndoActionAndGetUuid(changesList: UndoRedoObject[], undoRedoList): string {
    if (changesList.length > 0) {
      const addedUndo = this.addUndoRedoActions(changesList, undoRedoList);
      return addedUndo?.uuid;
    }
  }

  private addUndoRedoActions(changesList: UndoRedoObject[], undoRedoList): any {
    if (changesList.length > 0) {
      // skipping undo-redo dispatch so that it can be combined with other undo-redo actions
      if (undoRedoList) {
        undoRedoList.push(...changesList);
      } else {
        return this.undoRedoService.addUndo({ actionType: UndoRedoActionType.UPDATE_PLACEHOLDER, changesList });
      }
    }
  }

  private formatChangesForPersistence(changes) {
    const formattedChanges = {};
    Object.keys(changes).forEach((slug) => {
      if (changes.hasOwnProperty(slug + 'Id')) {
        formattedChanges[slug + 'Id'] = changes[slug + 'Id'];
      } else {
        formattedChanges[slug] = changes[slug];
      }
    });
    return formattedChanges;
  }

  private async clearItemOptionFromEntity(entity: any, changes: any, undoChanges: any) {
    const { entities } = await this.placeholderItemAssignmentService.buildChangesToSetItemOptionsOnPlaceholders([
      { placeholder: entity, itemData: null },
    ]);
    const entityUpdate = entities.find((entityUpdate) => entityUpdate.id === entity.id);
    const placeholderChanges = ObjectUtil.cloneDeep(entityUpdate.changes);
    const changedPropertySlugs = Object.keys(placeholderChanges) ?? [];

    for (const propertySlug of changedPropertySlugs) {
      changes[propertySlug] = placeholderChanges[propertySlug];
      undoChanges[propertySlug] = entity[propertySlug];
    }
  }

  private async clearItemFamilyFromEntity(entity: any, changes: any, undoChanges: any) {
    const placeholderChangeObj = await this.placeholderItemAssignmentService.buildChangesToSetItemFamilyOnPlaceholder(
      entity,
      null,
    );
    const placeholderChanges = ObjectUtil.cloneDeep(placeholderChangeObj.changes);
    const changedPropertySlugs = Object.keys(placeholderChanges) ?? [];

    for (const propertySlug of changedPropertySlugs) {
      changes[propertySlug] = placeholderChanges[propertySlug];
      undoChanges[propertySlug] = ObjectUtil.cloneDeep(entity[propertySlug]);
    }
  }
}
