import { AfterViewInit, ChangeDetectorRef, Component, HostListener, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { escapeRegExp } from 'lodash';
import { TypePropertyOption } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { map, startWith } from 'rxjs/operators';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { DataCellEditBaseComponent } from '../data-cell-edit-base-component';
import { TypePropertyUserListService } from '@common/user-list/user-list.service';

@Component({
  selector: 'app-data-cell-edit-user',
  template: ` <div
      class="select-edit"
      style="width:100%"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu"
      (menuOpened)="menuOpened()"
      (menuClosed)="menuClosed()"
    >
      <div class="view">{{ display }}</div>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <mat-menu #menu="matMenu" class="menu-sm">
      <div mat-menu-item class="filter-widget" (focus)="filterFocused()" (click)="$event.stopPropagation()">
        <app-search-bar [placeholder]="'Search'"></app-search-bar>
      </div>
      <button *ngFor="let option of filteredOptions" mat-menu-item (click)="setOptionValue(option)">
        {{ option.display }}
      </button>
    </mat-menu>`,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        justify-content: flex-end;
        text-align: right;
      }
      .select-edit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 20px;
        cursor: pointer;
      }
      .view {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .filter-widget {
        height: inherit;
        padding: 4px 8px;
      }
    `,
  ],
})
export class DataCellEditUserListComponent extends DataCellEditBaseComponent implements AfterViewInit {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  filteredOptions: Array<TypePropertyOption> = [];

  editing = false;
  constructor(
    private changeRef: ChangeDetectorRef,
    private userListService: TypePropertyUserListService,
  ) {
    super();
  }

  async subscribe() {
    const options: Array<TypePropertyOption> = await this.getOptions();
    this.searchBar.valueChange
      .pipe(
        startWith(''),
        map((searchTerm) => {
          const keys = 'display';
          if (!options) {
            this.filteredOptions = [];
          } else {
            this.filteredOptions = options.filter((option) =>
              keys
                .split(',')
                .some(
                  (key) => option.hasOwnProperty(key) && new RegExp(escapeRegExp(searchTerm), 'gi').test(option[key]),
                ),
            );
            this.searchBar.focus();
          }
          this.changeRef.detectChanges();
        }),
      )
      .subscribe();
  }

  private async getOptions(): Promise<Array<TypePropertyOption>> {
    let eligibleOptions = await this.userListService.getTypePropertyUserList(
      this.property.propertyDefinition.typePropertyUserListId,
    );
    return ObjectUtil.cloneDeep(eligibleOptions);
  }

  ngAfterViewInit() {
    this.subscribe();
    this.searchBar.focus();
  }

  setOptionValue(option) {
    this.value = { id: option.value, email: option.display };
    this.display = option.display;
    super.handleChange();
  }

  filterFocused() {
    this.searchBar.focus();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  menuOpened() {
    this.editing = true;
  }

  menuClosed() {
    this.editing = false;
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent = null) {
    // use composedPath to make sure that keyevent did not originate from other element
    if (event.composedPath()[0] instanceof HTMLBodyElement && !this.menuTrigger.menuOpen && !this.editing) {
      setTimeout(() => {
        this.menuTrigger.openMenu();
        this.searchBar.focus();
      }, 100);
    } else if (this.menuTrigger.menuOpen && this.editing) {
      this.menuTrigger.closeMenu();
    }
    event.stopPropagation();
  }

  @HostListener('document:keydown', ['$event'])
  handleEvents(event: KeyboardEvent = null) {
    if (this.editing) {
      event.stopPropagation(); // stop propagating key event to other listeners
    }
  }
}
