import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PlansActions, RootStoreState } from 'src/app/root-store';
import { SessionMessage, SessionMessageHandler } from '../common/web-socket/session-message';
import { WebSocketMessageDispatcher } from '../common/web-socket/web-socket-message-dispatcher';
import { CollectionManagerActions } from './collection-manager-store';
import { CollectionDataEntity } from './collection-manager.service';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';

@Injectable({
  providedIn: 'root',
})
export class CollectionManagerMessageHandler implements SessionMessageHandler {
  public someProp = false;
  constructor(
    private store: Store<RootStoreState.State>,
    private confirmationBoxService: ConfirmationBoxService,
    private websocketMessageDispatcher: WebSocketMessageDispatcher,
  ) {
    this.init();
  }

  private init() {
    this.websocketMessageDispatcher.registerHandler('UPDATE_COLLECTION_ENTITY', this);
    this.websocketMessageDispatcher.registerHandler('UPDATE_COLLECTION_ENTITIES', this);
    this.websocketMessageDispatcher.registerHandler('DELETE_COLLECTION_ENTITIES', this);
    this.websocketMessageDispatcher.registerHandler('CREATE_COLLECTION_ENTITY', this);
    this.websocketMessageDispatcher.registerHandler('CREATE_COLLECTION_ENTITIES', this);
    this.websocketMessageDispatcher.registerHandler('SYNC_PLAN_ROW_ORDER', this);
    this.websocketMessageDispatcher.registerHandler('UPDATE_COLLECTION_VIEW', this);
    this.websocketMessageDispatcher.registerHandler('RESTORE_PLAN_SNAPSHOT', this);
  }

  public async handleMessage(message: SessionMessage) {
    console.log('CollectionManagerMessageHandler: handleMessage --> ', message);

    switch (message.event?.eventType) {
      case 'UPDATE_COLLECTION_ENTITY': {
        const changes = message.event.changes as CollectionDataEntity;
        this.store.dispatch(
          CollectionManagerActions.applyCollectionEntityChanges({ id: changes.id, changes, broadcast: false }),
        );
        this.store.dispatch(CollectionManagerActions.updateSubscribedTopics({ placeholders: [changes] }));
        break;
      }
      case 'UPDATE_COLLECTION_ENTITIES': {
        const changes = message.event.changes as CollectionDataEntity[];
        this.store.dispatch(CollectionManagerActions.updateSubscribedTopics({ placeholders: changes }));
        this.store.dispatch(CollectionManagerActions.batchApplyCollectionEntityChanges({ changes, broadcast: false }));
        break;
      }
      case 'CREATE_COLLECTION_ENTITY': {
        const changes = message.event.changes as CollectionDataEntity;
        this.store.dispatch(CollectionManagerActions.updateSubscribedTopics({ placeholders: [changes] }));
        this.store.dispatch(CollectionManagerActions.addCollectionEntity({ entity: changes }));
        break;
      }
      case 'CREATE_COLLECTION_ENTITIES': {
        const changes = message.event.changes as CollectionDataEntity[];
        this.store.dispatch(CollectionManagerActions.updateSubscribedTopics({ placeholders: changes }));
        this.store.dispatch(CollectionManagerActions.addCollectionEntities({ entities: changes }));
        break;
      }
      case 'DELETE_COLLECTION_ENTITIES': {
        const changes = message.event.changes as CollectionDataEntity;
        this.store.dispatch(CollectionManagerActions.removeCollectionEntities({ ids: changes.ids }));
        break;
      }
      case 'SYNC_PLAN_ROW_ORDER': {
        const changes = message.event.changes as CollectionDataEntity;
        this.store.dispatch(PlansActions.syncPlanRowOrder({ planRowOrder: changes }));
        break;
      }
      case 'UPDATE_COLLECTION_VIEW': {
        const changes = message.event.changes as CollectionDataEntity;
        const id = changes.id;
        this.store.dispatch(CollectionManagerActions.applyViewDefinitionChanges({ id, changes }));
        break;
      }
      case 'RESTORE_PLAN_SNAPSHOT': {
        const confirm = await this.confirmationBoxService.open(
          'New Version',
          'Another version of this board has been updated or restored. Please refresh your browser to load the new changes.',
          '',
          'REFRESH',
          true,
          true,
        );
        if (confirm) {
          console.log(message.event.changes);
          this.store.dispatch(PlansActions.loadCurrentPlan({ id: message.event.changes }));
          this.store.dispatch(CollectionManagerActions.resubscribeToAllTopics({} as any));
        }
        break;
      }
    }
  }
}
