<div class="flex border-t-[1px]">
  <app-pivot-grid
    class="flex m-[10px]"
    [viewDefinition]="currentViewDefinition"
    [data]="data"
    [goals]="goals$ | async"
    [gridWidth]="width"
    [gridHeight]="height"
    *ngIf="!showEmptyPage"
  >
  </app-pivot-grid>
  <!--
  <app-pivot-grid
    class="flex m-[10px]" 
    [viewDefinition]="currentViewDefinition" 
    [data]="data"
    [gridWidth]="width"
    [gridHeight]="height"
    *ngIf="!showEmptyPage"
  >
  </app-pivot-grid>

</div>

<div class="flex">
  <app-pivot-grid
  class="flex m-[10px]" 
  [viewDefinition]="currentViewDefinition" 
  [data]="data"
  [gridWidth]="width"
  [gridHeight]="height"
  *ngIf="!showEmptyPage"
>
</app-pivot-grid>

<app-pivot-grid
  class="flex m-[10px]" 
  [viewDefinition]="currentViewDefinition" 
  [data]="data"
  [gridWidth]="width"
  [gridHeight]="height"
  *ngIf="!showEmptyPage"
>
</app-pivot-grid>
--></div>

<app-empty-placeholder
  *ngIf="showEmptyPage"
  [title]="title"
  [icon]="icon"
  [footer]="footer"
  [actionLabel]="actionLabel"
>
</app-empty-placeholder>
