import { ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, Subject } from 'rxjs';
import { switchMapTo, takeUntil, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { GridNavigationHandler } from '../grid-navigation-handler';

export class RowDragPreviewHandler {
  dragActiveRow = null;
  selectedEntityIds = [];

  constructor(
    private store: Store<RootStoreState.State>,
    private navigationHandler: GridNavigationHandler,
    private gridViewElement: ElementRef,
    private rowDragPreviewElem: ElementRef,
  ) {
    this.store.select(CollectionManagerSelectors.dragActiveRow).subscribe((dragActiveRow: any) => {
      if (this.dragActiveRow && !dragActiveRow) {
        this.rowDragPreviewElem.nativeElement.setAttribute('style', 'visibility: hidden');
      }
      this.dragActiveRow = dragActiveRow;
    });

    this.store.select(CollectionManagerSelectors.selectedEntityIds).subscribe((selectedEntityIds) => {
      this.selectedEntityIds = selectedEntityIds;
    });
    const element = this.gridViewElement.nativeElement;
    const mousedown$ = fromEvent(element, 'mousedown');
    const mousemove$ = fromEvent(document, 'mousemove');
    const mouseup$ = fromEvent(element, 'mouseup');
    // This will only respond to mousemove event that is done after a mousedown for performance.
    // After a mouseup, it will no longer respond to the mousemove event
    mousedown$
      .pipe(
        switchMapTo(
          mousemove$.pipe(
            tap((event: any) => {
              if (this.dragActiveRow) {
                this.rowDragPreviewElem.nativeElement.setAttribute('style', this.calculatRowDragPreviewStyle(event));
                this.scrollVertically(event);
              }
            }),
            takeUntil(mouseup$),
          ),
        ),
      )
      .subscribe();
  }

  // This function shows the row drag preview which is initially hidden.
  calculatRowDragPreviewStyle(event) {
    const offsetY = this.gridViewElement.nativeElement.getBoundingClientRect().top;
    let rowCount = 1;
    let currentIndex = 0;
    if (this.selectedEntityIds.length > 0) {
      rowCount = this.selectedEntityIds.length;
      currentIndex = this.selectedEntityIds.indexOf(this.dragActiveRow);
    }
    const height = rowCount * 25;
    const top = event.pageY - offsetY - currentIndex * 25;
    const style = 'visibility: visible; top: ' + top + 'px; height: ' + height + 'px; left: 0px';
    return style;
  }

  // This function will scroll the grid view vertically depending on the position of the mouse during row drag
  scrollVertically(event) {
    const topY = this.gridViewElement.nativeElement.getBoundingClientRect().top;
    const bottomY = this.gridViewElement.nativeElement.getBoundingClientRect().bottom;
    if (event.pageY + 50 > bottomY) {
      this.navigationHandler.verticalAutoScroll('down', 1);
    } else if (event.pageY - 30 < topY) {
      this.navigationHandler.verticalAutoScroll('up', 1);
    }
  }
}
