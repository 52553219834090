import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserSessionsStoreModule } from '../common/user-sessions/user-sessions-store';
import { AuthStoreModule } from '../common/auth/auth-store';
import { CommentsStoreModule } from '../common/comments/comments-store';
import { LoadingIndicatorStoreModule } from '../common/loading-indicator/loading-indicator-store';
import { PlansStoreModule } from '../plans/plans-store';
import { CollectionManagerStoreModule } from '../collection-manager/collection-manager-store';
import { AssortmentsStoreModule } from '../common/assortments/assortments-store';
import { UndoRedoStoreModule } from '../common/undo-redo/undo-redo-store';
import { WorkspacesStoreModule } from '@common/workspaces/workspaces-store';
import { DocumentHistoryStoreModule } from '@common/document-history/document-history-store';
import { TypePropertyUserListsStoreModule } from '@common/user-list/user-list-store/user-list-store.module';
import { FeatureFlagsStoreModule } from '@common/feature-flags';
import { AsyncErrorsStoreModule } from '@common/errors/async-errors-store';
import { AppExtensionsStoreModule } from '@common/app-extensions/app-extensions-store';
import { ClipboardStoreModule } from '@common/clipboard/clipboard-store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    UserSessionsStoreModule,
    AuthStoreModule,
    CommentsStoreModule,
    LoadingIndicatorStoreModule,
    PlansStoreModule,
    CollectionManagerStoreModule,
    AssortmentsStoreModule,
    WorkspacesStoreModule,
    UndoRedoStoreModule,
    FeatureFlagsStoreModule,
    DocumentHistoryStoreModule,
    TypePropertyUserListsStoreModule,
    AsyncErrorsStoreModule,
    AppExtensionsStoreModule,
    ClipboardStoreModule,
  ],
})
export class RootStoreModule {}
