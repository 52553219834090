import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthSelectors } from '@common/auth/auth-store';
import { AuthService } from '@common/auth/auth.service';
import { SizeRangeHelper } from '@common/size-range/size-range-helper';
import { Entities, Types } from '@contrail/sdk';
import {
  ValidatorFunctionProcessor,
  ValidationError,
  TypeConstraintsHelper,
  validateSizeRangeAgainstTemplate,
} from '@contrail/type-validation';
import { FormulaFunctionProcessor, PropertyType, Type, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { take, tap } from 'rxjs/operators';
import { Item, ItemDocument } from '../item';
import { ItemPropertiesFormComponent } from '../item-properties-form/item-properties-form.component';
import { ItemNavigationOption } from './item-details-header/item-details-header.component';
import { ItemService } from '../item.service';
@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
})
export class ItemDetailsComponent implements OnChanges, AfterViewInit {
  @ViewChildren(ItemPropertiesFormComponent) propertyFormQuery: QueryList<ItemPropertiesFormComponent>;
  private propertyForm: ItemPropertiesFormComponent;
  private validateValueProperties: TypeProperty[];
  private objRefProperties: Array<TypeProperty>;

  @Input() itemId: string;
  @Output() cancelView = new EventEmitter();
  @Output() updated = new EventEmitter();
  @Input() accessLevel = 'EDIT';
  public selectedItem: Item;
  public selectedItemOriginal: Item;
  public itemOptions: any;
  public itemFamily: any;
  public type: Type;
  public contentEditable = true;
  public propertyEditable = true;
  public navOptions: Array<ItemNavigationOption> = [
    {
      label: 'Details',
      slug: 'details',
    },
    {
      label: 'Assets',
      slug: 'content',
    },
    {
      label: 'Documents',
      slug: 'documents',
    },
    // }, {
    //   label: 'History',
    //   slug: 'history'
    // }, {
    //   label: 'Feedback',
    //   slug: 'feedback'
  ];
  public currentNavOption: ItemNavigationOption = this.navOptions[0];
  public allDocumentsUnderFamily: ItemDocument[] = [];
  public hasLoadedDocuments = false;
  public isLoadingDocuments = false;

  public changes: any = {};
  public changesForUpdate: any = {};
  public errors: any = {};

  constructor(
    private ref: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private store: Store<RootStoreState.State>,
    private itemService: ItemService,
  ) {}

  async ngOnChanges() {
    if (this.itemId) {
      await this.loadItem(this.itemId);
      if (this.authService.isOnSharedLink()) {
        this.contentEditable = false;
        this.propertyEditable = false;
      }
    }
  }
  async setCurrentItem(item: Item) {
    if (this.selectedItem.itemFamilyId !== item.itemFamilyId) {
      await this.loadItem(item.id);
    } else {
      this.selectedItem = item;
      this.selectedItemOriginal = ObjectUtil.cloneDeep(this.selectedItem);
      this.itemId = item.id;
      // this.selectedItem.itemOptions = ObjectUtil.cloneDeep(this.itemOptions)
      // this.selectedItem.itemFamily = ObjectUtil.cloneDeep(this.itemFamily)
    }
  }
  async loadItem(itemId: string) {
    this.errors = {};
    this.itemId = itemId;
    this.selectedItem = null;
    this.selectedItem = await new Entities().get({
      entityName: 'item',
      id: itemId,
      relations: ['itemFamily', 'itemOptions', 'updatedBy'],
    });
    if (this.selectedItem.roles.includes('family')) {
      this.itemFamily = ObjectUtil.cloneDeep(this.selectedItem);
    } else {
      this.itemFamily = ObjectUtil.cloneDeep(this.selectedItem.itemFamily);
    }
    this.itemOptions = ObjectUtil.cloneDeep(this.selectedItem.itemOptions);
    delete this.selectedItem.itemOptions;
    delete this.selectedItem.itemFamily;
    this.selectedItemOriginal = ObjectUtil.cloneDeep(this.selectedItem);
    this.type = await new Types().getType({ id: this.selectedItem.typeId });
    this.objRefProperties = this.type.typeProperties.filter((property) =>
      [PropertyType.ObjectReference, PropertyType.ObjectReferenceList, PropertyType.UserList].includes(
        property.propertyType,
      ),
    );
    await this.validatePropertyValues(this.type, this.selectedItem, null);
    if (Object.keys(this.errors).length > 0 && this.accessLevel === 'EDIT') {
      this.snackBar.open('There are errors in this item. Please fix the errors.', '', { duration: 4000 });
    }
  }

  ngAfterViewInit() {
    if (this.propertyFormQuery.toArray().length) {
      this.subscribeToForm(this.propertyFormQuery.toArray()[0]);
    }
    this.propertyFormQuery.changes.subscribe((formQuery: QueryList<ItemPropertiesFormComponent>) => {
      const results = formQuery.toArray();
      if (results.length > 0) {
        this.subscribeToForm(results[0]);
      }
    });

    this.store
      .select(AuthSelectors.selectAuthContext)
      .pipe(
        take(1),
        tap((context) => {
          // Short term rule for converse. TODO
          // This could be later replaced with something that checks for
          // editability of a 'relationship' property definition on the item type.
          if (context.currentOrg.orgSlug.indexOf('converse') > -1) {
            this.contentEditable = false;
          }
        }),
      )
      .subscribe();

    this.contentEditable = this.accessLevel === 'EDIT';
  }
  private subscribeToForm(form: ItemPropertiesFormComponent) {
    this.propertyForm = form;
    this.propertyForm.itemChanges.subscribe((formChanges) => this.updateEntityValues(formChanges));
  }
  cancel() {
    this.cancelView.emit();
  }

  isValid() {}
  async updateEntityValues(formChange) {
    console.log('ItemDetailsComponent: updateEntityValues: ', formChange);
    const changesForUpdate = {};
    if (this.objRefProperties.find((prop) => prop.slug === formChange.propertySlug)) {
      changesForUpdate[formChange.propertySlug + 'Id'] = formChange.value.id || null;
      // changesForUpdate[formChange.propertySlug] = formChange.value.id ? formChange.value : null;
    } else {
      changesForUpdate[formChange.propertySlug] = formChange.value;
    }
    const changes = {};
    // object-reference property
    changes[formChange.propertySlug] = formChange.value;
    if (
      formChange.value &&
      typeof formChange.value === 'object' &&
      formChange.value.hasOwnProperty(formChange.propertySlug + 'Id')
    ) {
      changes[formChange.propertySlug + 'Id'] = formChange.value[formChange.propertySlug + 'Id'];
      if (changes[formChange.propertySlug + 'Id'] === null) {
        changes[formChange.propertySlug] = null;
      }
    }
    // APPLY CHANGES LOCALLY
    const originalSelectedItem = ObjectUtil.cloneDeep(this.selectedItem);
    this.selectedItem = Object.assign({}, this.selectedItem, changes);
    const itemIsDifferent =
      ObjectUtil.compareDeep(ObjectUtil.cloneDeep(this.selectedItem), this.selectedItemOriginal, '').length > 0;

    // FOR OPTION EDIT, REFRESH DATA IN THE OPTIONS MAP
    const changedOption = this.itemOptions['color']?.find((o) => o.id === this.selectedItem.id);
    if (changedOption) {
      Object.assign(changedOption, this.selectedItem);
      await this.deriveOptionNameIfApplicable(changedOption, changesForUpdate);
    }

    // MODIFY LOCAL ITEM FAMLY IF THAT WAS CHANGED
    if (this.itemFamily.id === this.selectedItem.id) {
      Object.assign(this.itemFamily, changes);
    }
    this.changes = Object.assign(this.changes, changes);
    this.changesForUpdate = Object.assign(this.changesForUpdate, changesForUpdate);
    await this.validatePropertyValues(this.type, this.selectedItem, null);

    if (Object.keys(this.errors).length === 0 || Object.values(this.errors).every((x) => x === null)) {
      if (!itemIsDifferent) {
        // do not update is the updated item is the same as the original.
        return;
      }
      this.selectedItem.updatedOn = new Date();
      // PERSIST THE CHANGES
      console.log('updating item: ', JSON.stringify(changes));
      try {
        const updatedItem = await new Entities().update({
          entityName: 'item',
          id: this.selectedItem.id,
          object: ObjectUtil.cloneDeep(this.changesForUpdate),
        });
        // NEED TO RELOAD OPTIONS ON A FAMILY CHANGE DUE TO
        // PROPOGATION LOGIC.
        // if (this.itemFamily.id === this.selectedItem.id) {
        const reloadedFamily = await new Entities().get({
          entityName: 'item',
          id: this.itemFamily.id,
          relations: ['itemOptions'],
        });
        this.itemOptions = reloadedFamily.itemOptions;
        if (updatedItem.roles.includes('color')) {
          const changedOption = this.itemOptions['color']?.find((o) => o.id === this.selectedItem.id);
          if (changedOption) {
            this.selectedItem = Object.assign({}, this.selectedItem, updatedItem);
          }
        }
        //}
        // EMIT CHANGES TO THE SOURCE APP
        const emittedChanges = ObjectUtil.cloneDeep(this.changes); // to void mutation...
        this.updated.emit({ object: this.selectedItem, changes: emittedChanges });
        this.snackBar.open('Item was updated successfully.', '', { duration: 4000 });
      } catch (error) {
        this.selectedItem = ObjectUtil.cloneDeep(originalSelectedItem);
        const changedOption = this.itemOptions['color']?.find((o) => o.id === this.selectedItem.id);
        if (changedOption) {
          Object.assign(changedOption, this.selectedItem);
        }

        const errorMessage = this.getErrorMessage(error);
        this.snackBar.open('Error: Item was not updated. ' + errorMessage, '', { duration: 8000 });
      }
    } else {
      this.snackBar.open('Item was not updated because of errors. Please fix errors to continue.', '', {
        duration: 4000,
      });
    }
  }

  handlePrimaryViewableChange(content) {
    console.log('HandlePrimaryViewableChange-----------');
    let changes;
    if (content) {
      changes = {
        largeViewableDownloadUrl:
          (content?.contentType === 'image/svg+xml' && content?.primaryFile?.fileUrl) ||
          content?.largeViewable?.fileUrl,
        mediumViewableDownloadUrl: content.mediumViewable.fileUrl,
        mediumLargeViewableDownloadUrl: content.mediumViewable.fileUrl,
        smallViewableDownloadUrl: content.mediumViewable?.fileUrl,
      };
    } else {
      changes = {
        largeViewableDownloadUrl: null,
        mediumViewableDownloadUrl: null,
        mediumLargeViewableDownloadUrl: null,
        tinyViewableDownloadUrl: null,
        smallViewableDownloadUrl: null,
      };
    }
    Object.assign(this.selectedItem, changes);
    this.updated.emit({ object: this.selectedItem, changes });
  }

  selectNavOption(navOption: ItemNavigationOption) {
    this.currentNavOption = navOption;

    if (this.currentNavOption.slug === 'documents') {
      this.loadDocuments();
    }
  }

  async loadDocuments() {
    if (this.isLoadingDocuments || this.hasLoadedDocuments) {
      return;
    }

    this.isLoadingDocuments = true;
    this.allDocumentsUnderFamily = await this.itemService.getItemDocuments(this.itemFamily.id);
    this.isLoadingDocuments = false;
    this.hasLoadedDocuments = true;
  }

  async validatePropertyValues(type: Type, item: Item, context: any) {
    this.errors = ObjectUtil.cloneDeep(this.errors);
    for (const property of type.typeProperties) {
      let allValidationErrors: ValidationError[] = [];
      if (property.validationFunction) {
        const validatorErrors: ValidationError[] = await ValidatorFunctionProcessor.processValidatorFunction(
          property.validationFunction,
          item,
          context,
        );
        allValidationErrors = validatorErrors ?? [];
      }
      const validationErrors: ValidationError[] = await TypeConstraintsHelper.isValueLegalForEntity(
        type,
        property,
        item,
        item[property.slug],
      );
      allValidationErrors = allValidationErrors.concat(validationErrors);

      // validate size range if it exists
      if (property.propertyType === PropertyType.SizeRange) {
        const sizeRangeValidationErrors: ValidationError[] = validateSizeRangeAgainstTemplate(item, property);
        if (sizeRangeValidationErrors.length > 0) {
          allValidationErrors = allValidationErrors.concat(sizeRangeValidationErrors);
        }
      }
      if (allValidationErrors.length > 0) {
        this.errors[property.slug] = allValidationErrors[0].message; // just return the first error
      } else if (this.errors[property.slug]) {
        this.errors[property.slug] = null;
      }
    }
  }

  private async deriveOptionNameIfApplicable(changedItem: any, itemChanges: any) {
    const optionNameProp = (await new Types().getType({ path: 'item' })).typeProperties.find(
      (prop) => prop.slug === 'optionName',
    );
    if (optionNameProp.formulaFunction) {
      const clonedItem = Object.assign(ObjectUtil.cloneDeep(changedItem), itemChanges);
      await FormulaFunctionProcessor.processFormulaFunctionsForEntity(clonedItem, [optionNameProp], {});
      if (clonedItem.optionName) {
        Object.assign(itemChanges, { optionName: clonedItem.optionName });
      }
    }
  }

  private getErrorMessage(error: any) {
    if (error?.firstValidationErrorMessage) {
      return `${error.firstValidationErrorMessage}`;
    }

    if (error?.message === 'Bad Request Exception') {
      return '';
    }

    return error?.message;
  }
}
