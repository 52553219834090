import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions } from '../../collection-manager-store';
import { UndoRedoDetails } from '../undo-redo-objects';
import { BasicUndoRedoHandler } from './basic-undo-redo.handler';

export class DeletePlaceholderUndoRedoHandler implements BasicUndoRedoHandler {
  constructor(
    public undoRedoDetails: UndoRedoDetails,
    public store: Store<RootStoreState.State>,
  ) {}

  undoActions(): void {
    const entities = this.undoRedoDetails.changesList
      .filter((undo) => undo.objectType === 'placeholder')
      .map((undo) => undo.undoChanges);
    const rowOrder = this.undoRedoDetails.changesList
      .filter((undo) => undo.objectType === 'rowOrder')
      .map((undo) => undo.undoChanges);
    rowOrder.sort((a, b) => (a.index > b.index ? 1 : -1)); //make sure the row order is sorted by its index
    this.store.dispatch(
      CollectionManagerActions.createCollectionDataEntities({ entities, rowOrder, skipRecordingUndoRedo: true }),
    );
  }

  redoActions(): void {
    const ids = this.undoRedoDetails.changesList
      .filter((undo) => undo.objectType === 'placeholder')
      .map((redo) => redo.changes);
    this.store.dispatch(CollectionManagerActions.deleteCollectionDataEntities({ ids, skipRecordingUndoRedo: true }));
  }
}
