import { Injectable } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { PlansSelectors, RootStoreState } from '@rootstore';
import { take, tap } from 'rxjs';
import { CollectionManagerSelectors } from '../collection-manager-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { CollectionDataEntity, CollectionManagerService } from '../collection-manager.service';
import { ClipboardActions } from '@common/clipboard/clipboard-store';

@Injectable({
  providedIn: 'root',
})
export class PlaceholderClipboardService {
  private editorMode: string = EditorMode.VIEW;

  constructor(
    private store: Store<RootStoreState.State>,
    private collectionManagerService: CollectionManagerService,
  ) {
    this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
  }

  addPlaceholdersToClipboard(placeholderIds: string[]) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }

    const placeholdersWithItems = this.getPlaceholdersWithItemsByIds(placeholderIds);
    if (!placeholdersWithItems?.length) {
      return;
    }

    const clipboardItems = [];
    for (const placeholder of placeholdersWithItems) {
      const isOption = Boolean(placeholder.itemOption);
      const itemId = isOption ? placeholder.itemOption?.id : placeholder.itemFamily?.id;
      const projectItemId = isOption
        ? placeholder.itemOption?.projectItem?.id
        : placeholder.itemFamily?.projectItem?.id;
      if (!itemId) {
        continue;
      }

      clipboardItems.push({ itemId, projectItemId });
    }

    this.store.dispatch(ClipboardActions.addItemsToClipboard({ clipboardItems }));
  }

  private getPlaceholdersWithItemsByIds(placeholderIds: string[]): CollectionDataEntity[] {
    const placeholders = [];

    this.store
      .select(CollectionManagerSelectors.displayedData)
      .pipe(
        take(1),
        tap((data) => {
          placeholders.push(
            ...ObjectUtil.cloneDeep(
              data.filter((placeholder) => placeholderIds.includes(placeholder.id) && placeholder.itemFamilyId),
            ),
          );
        }),
      )
      .subscribe();

    return placeholders;
  }
}
