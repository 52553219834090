import { Component, ElementRef, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { PropertyType, PropertyValueFormatter } from '@contrail/types';
import { Store } from '@ngrx/store';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { PlanEntityReferenceHelper } from '../../entity-references/entity-reference-helper';
import { DataGroup } from '../data-group';
import { CollectionElementValidator } from '../../collection-element-validator/collection-element-validator';
import { PlaceholderItemAssignmentService } from '../../placeholders/placeholder-item-assignment-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';
import { CollectionDataEntity } from '../../collection-manager.service';

const propertyFormatter = new PropertyValueFormatter();
@Component({
  selector: 'app-data-group-list-item',
  templateUrl: './data-group-list-item.component.html',
  styleUrls: ['./data-group-list-item.component.scss'],
})
export class DataGroupListItemComponent implements OnChanges {
  @Input() listItem: CollectionDataEntity;
  @Input() properties: Array<ViewPropertyConfiguration>;
  @Input() maxRefDisplayLength = 35;
  @Input() editorMode = 'VIEW';
  @Input() group: DataGroup;
  imageUrl: string;
  public draggable = false;
  public lockedMessage;
  public messages$: Observable<string>;
  public dropped = false;
  constructor(
    private store: Store<RootStoreState.State>,
    private objectReferenceHelper: PlanEntityReferenceHelper,
    private collectionElementValidator: CollectionElementValidator,
    private placeholderItemAssignmentService: PlaceholderItemAssignmentService,
    private eRef: ElementRef,
  ) {}

  ngOnInit(): void {}
  async ngOnChanges() {
    this.draggable = await this.isDraggable();

    this.dropped = this.listItem?.isDropped === true;
    this.messages$ = this.store.select(CollectionManagerSelectors.collectionStatusMessages).pipe(
      map((messages: Array<CollectionStatusMessage>) => {
        return messages
          .filter((message) => {
            return message.collectionElementId === this.listItem.id;
          })
          .map((message) => message.message)
          .join('\n');
      }),
    );
  }
  private async isDraggable() {
    if (!this.properties || !this.group) {
      return false;
    }
    const property = this.group.dim1;
    const editCheck = await this.collectionElementValidator.isEditableForProperty(this.listItem, property);
    this.lockedMessage = editCheck.reason;
    return editCheck.editable;
  }

  @HostListener('mouseenter', ['$event']) public mouseEnter(event) {
    this.store.dispatch(CollectionManagerActions.setHoveredEntityId({ id: this.listItem.id }));
  }
  @HostListener('mouseleave', ['$event']) public mouseLeave(event) {
    let clear = true;
    if (event.relatedTarget?.className === 'cdk-overlay-pane') {
      clear = false;
    }
    if (clear) {
      this.store.dispatch(CollectionManagerActions.setHoveredEntityId({ id: null }));
    }
  }
  @HostListener('dragover', ['$event']) public stdDragOver(evt) {
    evt.preventDefault();
    const group = this.eRef.nativeElement.querySelector('.item-box');
    group.classList.add('hover');
    evt.stopPropagation();
  }
  @HostListener('drop', ['$event']) async stdDrop(evt) {
    evt.preventDefault();
    const listItemDiv = this.eRef.nativeElement.querySelector('.item-box');
    listItemDiv.classList.remove('hover');
    const entityString = evt.dataTransfer.getData('entity');
    const entity = JSON.parse(entityString);
    console.log('drop: entity: ', entity);
    if (entity) {
      // ASSIGNING AN ITEM TO A PLACEHOLDER
      this.placeholderItemAssignmentService.assignItemsToPlaceholders(
        [],
        [],
        [{ placeholder: this.listItem, itemData: entity }],
      );

      //this.placeholderItemAssignmentService.setItemOptionOnPlaceholder(this.listItem, entity);
    }
    evt.stopPropagation();
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    const listItem = this.eRef.nativeElement.querySelector('.item-box');
    listItem.classList.remove('hover');
    evt.stopPropagation();
  }

  getValue(property: ViewPropertyConfiguration) {
    if (property.propertyDefinition?.propertyType === PropertyType.ObjectReference) {
      return this.listItem[property.slug];
    }

    return propertyFormatter.getDisplayValue(this.listItem, property.propertyDefinition);
  }

  handleObjectReferenceClick(property) {
    this.objectReferenceHelper.viewDetails(this.listItem, 'item', property, this.getValue(property));
  }
}
