<div class="header" *ngIf="showHeader">
  <div class="title">Chooser</div>
  <div (click)="handleClose()" class="cursor-pointer flex-center">
    <mat-icon>close</mat-icon>
  </div>
</div>
<div class="chooser">
  <div class="search-bar-wrapper">
    <app-search-bar [placeholder]="'Search'" #searchBar></app-search-bar>
  </div>
  <div class="menu-wrapper" *ngIf="showFilter">
    <div class="sort-filter-menu">
      <app-menu
        name="Filter"
        icon="filter_list"
        position="left"
        [highlight]="filterDefinition?.filterCriteria?.propertyCriteria?.length"
      >
        <app-filter-panel
          [equalsOnly]="dataSourceType === 'ITEM_LIBRARY'"
          [sourceValues]="dataSource?.assortmentItemData$ | async"
          [filterDefinition]="filterDefinition"
          (filterCriteriaChange)="setFilterCriteria($event)"
        >
        </app-filter-panel>
      </app-menu>

      <app-menu name="Sort" icon="sort" position="left" [highlight]="currentSorts?.length">
        <app-sort-panel
          data-test="sort-panel"
          [currentSorts]="currentSorts"
          [properties]="sortProperties"
          (performSort)="performSort($event)"
        >
        </app-sort-panel>
      </app-menu>
    </div>

    <!--<div class="clearAll cursor-pointer text-accent" (click)="clearFilters()">Clear</div>-->
    <div
      class="cursor-pointer text-accent select-all-link"
      data-test="select-all-items-link"
      *ngIf="
        allowAddMultipleEntities &&
        (dataSource?.results$ | async)?.length > 0 &&
        numberOfEligibleResults !== selectedItems.length
      "
      (click)="handleToggleSelectAll()"
    >
      Select all
    </div>
    <div
      class="cursor-pointer text-accent select-all-link"
      data-test="deselect-all-items-link"
      *ngIf="
        allowAddMultipleEntities &&
        (dataSource?.results$ | async)?.length > 0 &&
        numberOfEligibleResults > 0 &&
        numberOfEligibleResults === selectedItems.length
      "
      (click)="handleToggleSelectAll()"
    >
      Deselect all
    </div>

    <div class="show-all-control" *ngIf="showAllControl">
      <app-menu name="" icon="more_horiz" position="left" [highlight]="dateFilter || !showAll">
        <div class="title">Advanced Options</div>
        <mat-divider></mat-divider>
        <div class="advanced-option">
          <mat-slide-toggle color="primary" (change)="toggleShowAll($event)" [checked]="false" labelPosition="before"
            >Eligible only</mat-slide-toggle
          >
          <app-date-filter
            *ngIf="(chooserSourceOption$ | async)?.sourceType !== 'ITEM_LIBRARY'"
            [dateFilterAttribute]="dateFilterAttribute"
            (dateFilterChanged)="dateFilterChanged($event)"
          >
          </app-date-filter>
        </div>
      </app-menu>
      <div class="count">{{ ((dataSource?.results$ | async)?.length | number) || 0 }}</div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="data-pane" *ngIf="!(dataSource?.loading$ | async)">
    <virtual-scroller
      [style.height]="resultsHeight"
      #scroll
      [items]="dataSource?.results$ | async"
      class="narrow-scroll"
      (vsEnd)="handleScrollEnd($event)"
    >
      <app-chooser-entity-card
        class="data-row"
        [ngClass]="activeEntityId === entity.id ? 'active-entity' : ''"
        [entityType]="entityType"
        (entityClicked)="handleEntityClicked($event)"
        *ngFor="let entity of scroll.viewPortItems"
        [entity]="entity"
      ></app-chooser-entity-card>
      <div class="flex justify-center mt-5" *ngIf="dataSource?.moreResultsLoading$ | async">
        <mat-progress-spinner diameter="30" mode="indeterminate"> </mat-progress-spinner>
      </div>
    </virtual-scroller>
  </div>
  <div class="flex flex-col justify-center items-center min-h-[250px]" *ngIf="dataSource?.loading$ | async">
    <mat-progress-spinner diameter="50" mode="indeterminate"> </mat-progress-spinner>
    <div class="mt-[20px]">Searching...</div>
  </div>
</div>
