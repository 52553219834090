<div [ngSwitch]="propertyType" class="view">
  <app-data-cell-view-object-reference
    [data]="data"
    [accessLevel]="accessLevel"
    [selected]="selected"
    *ngSwitchCase="'object_reference'"
    [value]="value"
    [property]="property"
  ></app-data-cell-view-object-reference>
  <app-data-cell-view-formula
    [data]="data"
    [accessLevel]="accessLevel"
    [selected]="selected"
    *ngSwitchCase="'formula'"
    [value]="value"
    [property]="property"
  ></app-data-cell-view-formula>
  <app-data-cell-view-formula
    [data]="data"
    [accessLevel]="accessLevel"
    [selected]="selected"
    *ngSwitchCase="'type_reference'"
    [value]="value"
    [property]="property"
  ></app-data-cell-view-formula>
  <app-data-cell-view-text-area
    [data]="data"
    [accessLevel]="accessLevel"
    [selected]="selected"
    *ngSwitchCase="'text'"
    [value]="value"
    [property]="property"
  ></app-data-cell-view-text-area>
  <div class="view" *ngSwitchCase="'date'" [ngClass]="{ 'right-align': align === 'right' }">{{ display }}</div>
  <div class="view center-align" *ngSwitchCase="'boolean'">
    <mat-icon *ngIf="display === 'Yes'">check</mat-icon>
  </div>
  <div class="view center-align" *ngSwitchCase="'userList'">
    {{ value.display }}
  </div>
  <div class="image" *ngSwitchCase="'image'" [ngClass]="{ 'right-align': align === 'right' }">
    <!-- <img *ngIf="value" [src]="value" class="thumbnail"> -->
    <app-data-cell-view-image [value]="value" class="thumbnail" *ngIf="value"></app-data-cell-view-image>
    <div *ngIf="!value && data.itemFamily">
      <mat-icon class="placeholder-img">panorama</mat-icon>
    </div>
  </div>
  <div class="view" *ngSwitchDefault [ngClass]="{ 'right-align': align === 'right' }">{{ display }}</div>
</div>
