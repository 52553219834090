<div
  (contextmenu)="showColumnContextMenu($event)"
  [id]="'column_header_' + property.slug"
  cdkDropList
  class="w-full non-selectable"
>
  <div
    [attr.data-test]="'column_header_' + property.slug + '_draggable'"
    class="column-drag-wrapper header-cell"
    [ngClass]="{ 'cursor-grab': (isOrgAdmin && (currentView$ | async)?.admin) || !(currentView$ | async)?.admin }"
    [cdkDragDisabled]="!isOrgAdmin && (currentView$ | async)?.admin"
    cdkDrag
    cdkDragLockAxis="x"
    (cdkDragStarted)="columnHeaderDragStart($event)"
    (cdkDragDropped)="columnHeaderDrop($event)"
    (cdkDragMoved)="columnHeaderDragMove($event)"
  >
    <span class="label" [matTooltip]="property?.propertyDefinition?.label" matTooltipPosition="below">
      {{ property?.propertyDefinition?.label }}
    </span>
    <div *ngIf="(propertyIsFiltered$ | async) && isFilterAllowed" class="filter">
      <mat-icon [attr.data-test]="'column_header_' + property.slug + '_filter_active'" color="primary"
        >filter_list</mat-icon
      >
      <mat-icon
        [attr.data-test]="'column_header_' + property.slug + '_filter_remove'"
        (click)="removeFilter($event)"
        class="remove-icon"
        color="primary"
        svgIcon="remove-icon"
      ></mat-icon>
    </div>
    <div *ngIf="isDataOverridable" class="overridable">
      <mat-icon color="primary" svgIcon="override-icon"></mat-icon>
    </div>
    <app-column-header-menu
      *ngIf="(isOrgAdmin && (currentView$ | async)?.admin) || !(currentView$ | async)?.admin"
      [property]="property"
    ></app-column-header-menu>
    <div
      [attr.data-test]="'column_header_' + property.slug + '_drag_preview'"
      [id]="'column_header_preview_' + property.slug"
      class="column-drag-preview"
      *cdkDragPreview
    ></div>
  </div>
</div>

<div cdkDropList>
  <div
    [ngClass]="{ 'resize-handle': (isOrgAdmin && (currentView$ | async)?.admin) || !(currentView$ | async)?.admin }"
    [attr.data-test]="'column_resize_' + property.slug + '_draggable'"
    cdkDrag
    cdkDragLockAxis="x"
    [cdkDragDisabled]="!isOrgAdmin && (currentView$ | async)?.admin"
    (cdkDragStarted)="columnResizeDragStart($event)"
    (cdkDragDropped)="columnResizeDrop($event)"
    (cdkDragMoved)="columnResizeDragMove($event)"
  >
    <div
      [attr.data-test]="'column_resize_' + property.slug + '_drag_preview'"
      [id]="'column_resize_' + property.slug"
      class="column-resize-preview"
      *cdkDragPreview
    ></div>
  </div>
</div>

<mat-menu #contextMenu="matMenu" [hasBackdrop]="true" [attr.data-test]="'column-context-menu_' + property.slug">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="hideColumn()">
      <mat-icon class="icons" svgIcon="show_hide_icon"></mat-icon>
      <span>Hide Column</span>
    </button>
  </ng-template>
</mat-menu>
