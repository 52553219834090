import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { PropertyType } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { SortDefinition, SortDirection } from 'src/app/common/components/sort/sort-definition';
import { Subscription } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../../collection-manager-store';
import { HeaderColumnFreezeService } from '../header-column-freeze.service';
import { HeaderColumnReorderService } from '../header-column-reorder.service';
import { FilterService } from 'src/app/collection-manager/filter/filter-service';

@Component({
  selector: 'app-column-header-menu',
  templateUrl: './column-header-menu.component.html',
  styleUrls: ['./column-header-menu.component.scss'],
})
export class ColumnHeaderMenuComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @Input() property: ViewPropertyConfiguration;
  @Input() actions: string[] = ['pin', 'unpin', 'sort-ascending', 'sort-descending', 'filter', 'hide'];
  contextMenuPosition = { x: '0px', y: '0px' };

  private frozenColumnSub: Subscription;
  private frozenColumns: number;
  private sortSub: Subscription;
  private sorts: Array<SortDefinition> = [];

  public menuActions = [];
  constructor(
    private store: Store<RootStoreState.State>,
    private headerRowService: HeaderColumnReorderService,
    private headerFreezeService: HeaderColumnFreezeService,
    private filterService: FilterService,
  ) {}
  ngOnInit() {
    this.frozenColumnSub = this.store
      .select(CollectionManagerSelectors.frozenColumns)
      .subscribe((frozenColumns) => (this.frozenColumns = frozenColumns));
    this.sortSub = this.store
      .select(CollectionManagerSelectors.selectCurrentView)
      .subscribe((currentView) => (this.sorts = ObjectUtil.cloneDeep(currentView?.sorts) || []));
    this.initActions();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  initActions() {
    const availableActions = {
      pin: { svgIcon: 'pin-column', label: 'Pin', action: 'pin' },
      unpin: { svgIcon: 'unpin-column', label: 'Unpin', action: 'unpin' },
      'sort-ascending': { svgIcon: 'sort-ascending', label: 'Sort ascending', action: 'sort-ascending' },
      'sort-descending': { svgIcon: 'sort-descending', label: 'Sort descending', action: 'sort-descending' },
      filter: { icon: 'filter_list', label: 'Filter by this column', action: 'filter' },
      hide: { icon: 'visibility_off', label: 'Hide Column', action: 'hide' },
    };
    this.actions.forEach((action) => {
      this.menuActions.push(availableActions[action]);
    });
  }

  async invoke(action: string) {
    switch (action) {
      case 'pin':
        // this.store.dispatch(CollectionManagerActions.addFrozenColumn({property: this.property.slug}));
        this.headerFreezeService.freezeColumn(this.property.slug);
        break;
      case 'unpin':
        // this.store.dispatch(CollectionManagerActions.removeFrozenColumn({property: this.property.slug}));
        this.headerFreezeService.unfreezeColumn(this.property.slug);
        break;
      case 'sort-ascending':
        this.addSort(SortDirection.ASCENDING);
        break;
      case 'sort-descending':
        this.addSort(SortDirection.DESCENDING);
        break;
      case 'filter':
        this.addFilter();
        break;
      case 'hide':
        this.headerRowService.hideColumn(this.property.slug);
        break;
    }
  }

  private addSort(direction: SortDirection) {
    const sort: SortDefinition = {
      propertySlug: this.property.slug,
      propertyType: this.property.propertyDefinition.propertyType,
      direction,
    };
    const newSorts = ObjectUtil.cloneDeep(this.sorts);
    const existingSortIndex = newSorts.findIndex((currentSort) => currentSort.propertySlug === sort.propertySlug);
    if (existingSortIndex > -1) {
      if (newSorts[existingSortIndex].direction !== sort.direction) {
        newSorts.splice(existingSortIndex, 1, sort);
        this.store.dispatch(CollectionManagerActions.setSorts({ sorts: newSorts }));
      }
    } else {
      newSorts.push(sort);
      this.store.dispatch(CollectionManagerActions.setSorts({ sorts: newSorts }));
    }
  }

  private addFilter() {
    this.filterService.addFilter(this.property.slug);
  }

  showAction(action: string) {
    const showAction = true;
    switch (action) {
      case 'pin':
        return !this.property.frozen;
      case 'unpin':
        return this.property.frozen;
      case 'sort-ascending':
      case 'sort-descending':
      case 'filter':
        return this.property.propertyDefinition?.propertyType !== PropertyType.Image;
      default:
        return true;
    }
  }

  unsubscribe() {
    this.frozenColumnSub.unsubscribe();
    this.sortSub.unsubscribe();
  }
}
