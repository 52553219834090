import { Inject, Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { EntityModalComponent } from '@common/entity-details/entity-modal/entity-modal.component';
import { EntityReference } from '@contrail/sdk';
import { TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ItemDetailsModalComponent } from 'src/app/common/items/item-details-modal/item-details-modal.component';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { EntitySnapshotService } from '@common/document-history/entity-snapshot.service';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { collectionElementEntityAdapter } from '../collection-manager-store/collection-elements/collection-elements.state';
import { CollectionDataEntity } from '../collection-manager.service';
import { PlaceholderItemCreationService } from '../placeholders/placeholder-item-creation-service';
import { PlaceholderItemUpdateService } from '../placeholders/placeholder-item-update-service';

@Injectable({
  providedIn: 'root',
})
export class PlanEntityReferenceHelper {
  constructor(
    private matDialog: MatDialog,
    private store: Store<RootStoreState.State>,
    private entitySnapshotService: EntitySnapshotService,
    private placeholderItemCreationService: PlaceholderItemCreationService,
    private placeholderItemUpdateService: PlaceholderItemUpdateService,
  ) {}
  public viewDetails(
    sourceObject: any,
    entityType: string,
    typeProperty: TypeProperty,
    entity: any,
    accessLevel = 'EDIT',
  ) {
    if (!entity) {
      return;
    }
    console.log('viewDetails: ', entityType);
    if ('item' === entityType) {
      console.log('object reference clicked: Item: ', entity?.id);
      this.launchItemDetails(sourceObject, typeProperty, entity.id, accessLevel);
    } else {
      const entityReference = new EntityReference(`${entityType}:${entity.id}`);
      this.launchEntityDetails(sourceObject, entityReference.reference, typeProperty, accessLevel);
      console.log('object reference clicked: Non Item');
    }
  }

  private launchItemDetails(sourceObject: any, typeProperty: TypeProperty, itemId: string, accessLevel = 'EDIT') {
    console.log('launchItemDetails: accessLevel = ', accessLevel);
    const config = {
      data: { itemId, accessLevel },
      panelClass: [`no-padding`, `item-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '800px',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(ItemDetailsModalComponent, config);
    const itemModalComponent: ItemDetailsModalComponent = dialogRef.componentInstance;
    const sub: Subscription = itemModalComponent.updated.subscribe(async (result) => {
      // IS THIS A MEMORY LEAK? TODO: CHECK
      console.log('item updated: ', result, typeProperty.slug);
      const { placeholderChanges } = await this.placeholderItemUpdateService.processItemUpdateAndGetChangesToApply(
        result.object,
        result.changes,
        false,
        null,
        true,
      );
      this.store.dispatch(
        CollectionManagerActions.batchApplyCollectionEntityChanges({ changes: placeholderChanges, broadcast: true }),
      );
      this.triggerSnapshotOfPlan();
    });

    itemModalComponent.dialogRef.afterClosed().subscribe(() => {
      // unsubscribe automatically
      sub.unsubscribe();
      // IS THIS A MEMORY LEAK?
    });
  }

  private launchEntityDetails(
    sourceObject: any,
    entityReference: string,
    typeProperty: TypeProperty,
    accessLevel = 'EDIT',
  ) {
    const config = {
      data: { entityReference, accessLevel },
      panelClass: [`no-padding`, `entity-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '800px',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(EntityModalComponent, config);
    const entityModalComponent: EntityModalComponent = dialogRef.componentInstance;
    const sub: Subscription = entityModalComponent.updated.subscribe((result) => {
      // IS THIS A MEMORY LEAK? TODO: CHECK
      console.log('entity updated: ', result, typeProperty.slug);
      this.handleObjectReferenceUpdate(result.entity, typeProperty.slug);
    });

    entityModalComponent.dialogRef.afterClosed().subscribe(() => {
      // unsubscribe automatically
      sub.unsubscribe();
      // IS THIS A MEMORY LEAK?
    });
  }

  public async createObject(data: CollectionDataEntity, typeProperty: TypeProperty, name: string) {
    if (['itemFamily', 'itemOption'].includes(typeProperty.slug)) {
      this.placeholderItemCreationService.createItem(data, typeProperty.slug, name);
    }
  }

  /** Handles the update of a referenced entity.  Locates all affected placeholders
   * in the data and updates the referred object.
   */
  private handleObjectReferenceUpdate(referencedEntity, propertySlug) {
    const affectedPlaceholders = this.getMatchingPlaceholders(propertySlug + 'Id', referencedEntity.id);
    const changes = [];
    for (const ph of affectedPlaceholders) {
      const change = {};
      change[propertySlug] = referencedEntity;
      changes.push({
        id: ph.id,
        changes: change,
      });
    }

    // Dispatch changes (no persist needed) and broadcast to other sessions
    this.store.dispatch(
      CollectionManagerActions.batchApplyCollectionEntityChanges({
        changes,
        broadcast: true,
      }),
    );
  }

  private getMatchingPlaceholders(index, value) {
    let matches = [];
    this.store
      .select(CollectionManagerSelectors.selectCollectionData)
      .pipe(take(1))
      .subscribe((phs) => {
        matches = phs.filter((ph) => ph[index] === value);
      });
    return matches;
  }

  private triggerSnapshotOfPlan() {
    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(
        take(1),
        tap((plan) => {
          if (plan?.id) {
            this.entitySnapshotService.createEntitySnapshotWithDebounce(`plan:${plan.id}`);
          }
        }),
      )
      .subscribe();
  }
}
