<div class="object-details" (click)="launchItemDetails()">
  <div class="selector-checkbox" *ngIf="allowAddMultiple" (click)="$event.stopPropagation()">
    <mat-checkbox
      color="primary"
      [attr.data-test]="'item-checkbox-' + dataObj.id"
      (change)="toggleCheckbox($event)"
      [checked]="selectedItemIndex > 0"
      [disabled]="isCheckboxDisabled"
    ></mat-checkbox>
  </div>
  <div class="image-placeholder" *ngIf="!dataObj.thumbnail">
    <mat-icon
      class="!w-full !h-full"
      svgIcon="placeholder-item-option-image"
      *ngIf="dataObj?.item?.roles?.indexOf('family') === -1"
    ></mat-icon>
    <mat-icon
      class="!w-full !h-full"
      svgIcon="placeholder-item-family-image"
      *ngIf="dataObj?.item?.roles?.indexOf('family') !== -1"
    ></mat-icon>
  </div>
  <div *ngIf="dataObj.thumbnail" class="image-holder">
    <img [attr.src]="dataObj.thumbnail | secureImage | async" />
  </div>
  <div class="details">
    <div class="meta project" *ngIf="dataObj?.properties?.projectName">{{ dataObj.properties.projectName }}</div>
    <div class="name">{{ dataObj?.properties.name }}</div>
    <div class="meta" *ngIf="level === 'option' && dataObj?.properties?.optionName">
      <mat-icon class="option-icon" svgIcon="hierarchy"></mat-icon>
      {{ dataObj.properties.optionName }}
    </div>
    <div class="meta" *ngFor="let property of metaViewDefinition?.properties">
      <app-property-value
        [value]="dataObj?.properties[property.slug]"
        [property]="property.propertyDefinition"
        [propertyType]="property.propertyDefinition.propertyType"
      >
      </app-property-value>
    </div>
  </div>
</div>
<div class="actions">
  <button
    mat-button
    [attr.data-test]="'add-single-item-button-' + dataObj.id"
    [class.disabled]="isAddIconNotClickable() ? false : isDisabled"
    *ngIf="allowAdd && selectedItemIndex === 0"
    (click)="addItemData(dataObj); $event.stopPropagation()"
  >
    <mat-icon>{{ getAddIcon() }}</mat-icon>
  </button>
  <div [attr.data-test]="'selected-item-index' + dataObj.id" class="selected-item" *ngIf="selectedItemIndex > 0">
    {{ selectedItemIndex }}
  </div>
</div>
