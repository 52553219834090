import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions } from '../../collection-manager-store';

@Component({
  selector: 'app-empty-group-toggle',
  templateUrl: './empty-group-toggle.component.html',
  styleUrls: ['./empty-group-toggle.component.scss'],
})
export class EmptyGroupToggleComponent implements OnInit {
  constructor(private store: Store<RootStoreState.State>) {}
  public hideEmptyGroups = false;
  ngOnInit(): void {}

  toggleValue() {
    this.store.dispatch(CollectionManagerActions.setHideEmptyGroups({ hideEmptyGroups: this.hideEmptyGroups }));
  }
}
