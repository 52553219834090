import { ObjectUtil } from '@contrail/util';
import { CopyPasteUtil } from './copy-paste-util';
import { FillDataInfo } from './paste-data-info';

interface Pattern {
  numeralText?: any;
  text?: any;
}

export class FillPasteUtil extends CopyPasteUtil {
  public getFillRowRange(rowId, data, selectedCells) {
    let fillRowRange = null;
    const currentRowIndex = data.map((row) => row.id).indexOf(rowId);

    let rowIndex = -1;
    if (this.gridViewManager?.selectedCell?.rowId) {
      rowIndex = data.findIndex((row) => row.id === this.gridViewManager.selectedCell.rowId);
    }

    if (selectedCells.length > 0) {
      // selected cells are in a range
      if (selectedCells.findIndex((selectedCell) => selectedCell.rowId === rowId) > -1) {
        // row is inside the selected cell range
        return null;
      }
      const cellGroup = this.groupBy(selectedCells, 'columnId');
      const selectedRows = cellGroup[Object.keys(cellGroup)[0]].map((row) => row.rowId);
      const firstRowId = selectedRows[0];
      const lastRowId = selectedRows[selectedRows.length - 1];
      const firstRowIndex = data.findIndex((row) => row.id === firstRowId);
      const lastRowIndex = data.findIndex((row) => row.id === lastRowId);

      if (currentRowIndex > lastRowIndex) {
        // dragging down
        rowIndex = lastRowIndex;
      } else if (currentRowIndex < firstRowIndex) {
        // dragging up
        rowIndex = firstRowIndex;
      }
    }
    if (currentRowIndex > rowIndex) {
      // dragging down
      fillRowRange = {
        rowStartIndex: rowIndex + 1,
        rowEndIndex: currentRowIndex,
        direction: 'down',
      };
    } else if (currentRowIndex < rowIndex) {
      // dragging up
      fillRowRange = {
        rowStartIndex: rowIndex - 1,
        rowEndIndex: currentRowIndex,
        direction: 'up',
      };
    }
    return fillRowRange;
  }

  public async generateFillData(
    sourceRowData,
    properties,
    viewProperties,
    targetRowCount: number,
  ): Promise<FillDataInfo> {
    const fillData = [];
    const cellMap = {};
    properties.forEach((property) => {
      if (!cellMap[property]) {
        const cellIndex = viewProperties.findIndex((currentProperty) => currentProperty.slug === property);
        if (cellIndex > -1) {
          const propertyDefinition = viewProperties[cellIndex].propertyDefinition;
          cellMap[property] = propertyDefinition;
        }
      }
    });
    const patterns = sourceRowData.length > 1 ? this.generateFillPatterns(sourceRowData, properties, cellMap) : null;
    const latestValues = this.setLatestValues(sourceRowData, properties, cellMap, patterns);
    await this.addFillData(sourceRowData, properties, cellMap, patterns, latestValues, viewProperties, fillData);
    if (targetRowCount > sourceRowData.length) {
      let count = sourceRowData.length - 1;
      while (count < targetRowCount) {
        await this.addFillData(sourceRowData, properties, cellMap, patterns, latestValues, viewProperties, fillData);
        count += sourceRowData.length;
      }
    }
    if (fillData.length > targetRowCount) {
      fillData.splice(targetRowCount, fillData.length - targetRowCount);
    }
    const localStorageClipboardData = JSON.parse(localStorage.getItem('clipboardData'));
    let localStorageRowIds = ObjectUtil.cloneDeep(localStorageClipboardData);
    if (localStorageClipboardData?.length < fillData.length) {
      localStorageRowIds = this.synchClipboardDataWithDestinationRows(localStorageClipboardData, fillData.length);
      console.log(localStorageRowIds);
    }
    return { fillData, localStorageRowIds };
  }

  public getFillRowTargetRows(data, visibleProperties: any[], properties: any[], currentRowIndex: number) {
    let lastCurrentRowWithData = data.length - 1;
    properties.forEach((property) => {
      const currentRowWithDataIndex =
        data.findIndex((rowData, index) => index > currentRowIndex && rowData[property]) - 1;
      if (currentRowWithDataIndex >= 0) {
        lastCurrentRowWithData = Math.min(lastCurrentRowWithData, currentRowWithDataIndex);
      }
    });

    const targetRows = [];
    for (let i = currentRowIndex + 1; i <= lastCurrentRowWithData; i++) {
      targetRows.push(data[i].id);
    }
    return targetRows;
  }

  private async addFillData(
    sourceRowData: any[],
    properties: any[],
    cellMap: any,
    patterns: any,
    latestValues: any,
    currentViewProperties: any,
    fillData: Array<string>,
  ) {
    const rowIds = [];
    for (let i = 0; i < sourceRowData.length; i++) {
      const sourceRow = sourceRowData[i];
      rowIds.push(sourceRow.id);
      let rowData = '';
      for (let index = 0; index < properties.length; index++) {
        let property = properties[index];
        let cellValue = sourceRow[property];
        if (patterns && patterns[property]) {
          // pattern is detected
          const pattern = patterns[property];
          if (['currency', 'number', 'percent'].includes(cellMap[property].propertyType)) {
            cellValue = latestValues[property].numeralText + pattern.numeralText;
            latestValues[property] = { numeralText: cellValue };
            cellValue = await this.getData(cellMap[property], cellValue);
          } else if (['string'].includes(cellMap[property].propertyType)) {
            const numeralText = pattern.numeralText ? latestValues[property].numeralText + pattern.numeralText : null;
            cellValue = pattern.text + (numeralText || '');
            latestValues[property] = { numeralText, text: pattern.text };
          } else if (['date'].includes(cellMap[property].propertyType)) {
            cellValue = new Date(
              new Date(latestValues[property].numeralText).getTime() + pattern.numeralText,
            ).toISOString();
            latestValues[property] = { numeralText: cellValue };
          }
        } else {
          cellValue = await this.getDataInRow(cellMap[property], sourceRow);
        }
        rowData = rowData + (index > 0 ? '\t' : '') + (cellValue || '');
      }
      fillData.push(rowData); // copy and empty cell and add '\n' delimiter
    }
    localStorage.setItem('clipboardData', JSON.stringify(rowIds));
  }

  private setLatestValues(sourceRows: string[], properties: any[], cellMap: any, patterns: any) {
    const latestValues = {};
    sourceRows.forEach((sourceRow) => {
      properties.forEach((property, index) => {
        if (patterns && patterns[property]) {
          const cellValue = sourceRow[property];
          if (['currency', 'number', 'percent'].includes(cellMap[property].propertyType)) {
            latestValues[property] = { numeralText: cellValue };
          } else if (['string'].includes(cellMap[property].propertyType)) {
            latestValues[property] = this.getPattern(cellValue, cellMap[property].propertyType);
          } else if (['date'].includes(cellMap[property].propertyType)) {
            latestValues[property] = { numeralText: new Date(cellValue) };
          }
        }
      });
    });
    return latestValues;
  }

  private generateFillPatterns(sourceRowData: any[], properties: any[], cellMap: any) {
    const patterns = {};
    properties.forEach((property) => {
      const values = [];
      sourceRowData.forEach((sourceRow) => {
        values.push(sourceRow[property]);
      });
      const pattern = this.getFillPatternForProperty(values, cellMap[property].propertyType);
      if (pattern) {
        patterns[property] = pattern;
      }
    });
    return patterns;
  }

  private getFillPatternForProperty(values: any[], propertyType: string) {
    const patterns = [];
    values.forEach((value, index) => {
      const pattern = this.getPattern(value, propertyType);
      if (pattern) {
        patterns.push(this.getPattern(value, propertyType));
      }
    });
    if (patterns.length > 1) {
      return this.analyzePattern(patterns, propertyType);
    }
    return null;
  }

  private getPattern(str: any, propertyType: string): Pattern {
    let pattern;
    if (['string'].includes(propertyType)) {
      // string e.g. "Item 2"
      const numberMatches = str.match(/\d+$/); // find number (2)
      let numeralText;
      if (numberMatches) {
        numeralText = Number(numberMatches[0]);
      }

      let text = str;
      if (numeralText) {
        text = text.substring(0, text.indexOf(numeralText));
      }
      if (text === '' || !numeralText) {
        pattern = null;
      } else {
        pattern = {
          text,
          numeralText,
        };
      }
    } else if (['currency', 'number', 'percent', 'date'].includes(propertyType)) {
      pattern = {
        numeralText: str,
      };
    }
    return pattern;
  }

  private analyzePattern(patterns: Pattern[], propertyType: string): Pattern {
    let patternExists = true;
    let numeralText;
    patterns.forEach((pattern, index) => {
      if (index > 0) {
        if (['currency', 'number', 'percent', 'string'].includes(propertyType)) {
          if (pattern.numeralText && patterns[index - 1].numeralText) {
            const diff = Number(pattern.numeralText) - Number(patterns[index - 1].numeralText);
            if (!numeralText) {
              numeralText = diff;
            } else if (numeralText !== diff) {
              patternExists = false;
            }
          }
          if (pattern.text && patterns[index - 1].text) {
            if (pattern.text !== patterns[index - 1].text) {
              patternExists = false;
            }
          }
        } else if (propertyType === 'date') {
          if (pattern.numeralText && patterns[index - 1].numeralText) {
            const date1 = new Date(pattern.numeralText);
            const date2 = new Date(patterns[index - 1].numeralText);
            const diff = date1.valueOf() - date2.valueOf();
            if (!numeralText) {
              numeralText = diff;
            } else if (numeralText !== diff) {
              patternExists = false;
            }
          }
        }
      }
    });
    if (patternExists) {
      const pattern: Pattern = {
        numeralText,
      };
      if (patterns[0].text) {
        pattern.text = patterns[0].text;
      }
      return pattern;
    }
    return null;
  }
}
