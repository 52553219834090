import { Component, Input, OnInit } from '@angular/core';
import { ExportRequestEntity, ExportsService } from '../exports.service';

@Component({
  selector: 'app-excel-export',
  templateUrl: './excel-export.component.html',
  styleUrls: ['./excel-export.component.scss'],
})
export class ExcelExportComponent implements OnInit {
  @Input() criteria: any;
  @Input() entityName: string;
  @Input() ownerId: string;
  @Input() viewId: string;
  @Input() ownerName: string;
  @Input() viewName: string;
  @Input() relations: Array<string>;

  constructor(private service: ExportsService) {}
  ngOnInit(): void {}

  downloadCSV() {
    const obj = {
      viewDefinitionId: this.viewId,
      entityReference: 'plan:' + this.ownerId,
    };

    console.log(`received object is ${JSON.stringify(obj)}`);

    this.service.initDownloadEntityReference(obj);
  }
}
