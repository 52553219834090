import { Injectable } from '@angular/core';
import { GridViewManager } from '../grid-view.manager';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { Store } from '@ngrx/store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { PropertyType } from '@contrail/types';
import { SelectorCell } from '../selectors/grid-selector.service';
import { GridSelectorActionHandler } from '../selectors/grid-selector-action-handler';
import { Clipboard } from '@angular/cdk/clipboard';
import { ObjectUtil } from '@contrail/util';
import { CopyPasteUtil } from './copy-paste-util';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';

@Injectable({
  providedIn: 'root',
})
export class ClipboardCopyPasteHandler {
  data: any;
  selectorCells: Array<SelectorCell>;
  selectedRows: Array<any>;
  currentViewProperties: Array<ViewPropertyConfiguration>;
  cutCells: Array<SelectorCell>;
  private copyPasteUtil: CopyPasteUtil;
  private editorMode: string = EditorMode.VIEW;

  constructor(
    private store: Store<RootStoreState.State>,
    private clipboard: Clipboard,
    private gridViewManager: GridViewManager,
    private gridSelectorActionHandler: GridSelectorActionHandler,
  ) {
    this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));

    this.store.select(CollectionManagerSelectors.displayedData).subscribe((data) => {
      if (data) {
        this.data = data;
      }
    });
    this.store.select(CollectionManagerSelectors.selectorCells).subscribe((selectorCells) => {
      this.selectorCells = selectorCells;
    });
    this.store.select(CollectionManagerSelectors.cutCells).subscribe((cutCells) => {
      this.cutCells = cutCells;
    });
    this.store.select(CollectionManagerSelectors.selectedEntityIds).subscribe((selectedRows) => {
      this.selectedRows = selectedRows;
    });
    this.store.select(CollectionManagerSelectors.currentViewProperties).subscribe((currentViewProperties) => {
      this.currentViewProperties = currentViewProperties;
    });
    this.copyPasteUtil = new CopyPasteUtil(this.gridViewManager);
  }

  public async handleDocumentCopyEvent() {
    const selectedCell = this.gridViewManager.selectedCell;
    const selectedRows = this.selectedRows;
    if (selectedCell || selectedRows.length > 0 || this.selectorCells.length > 1) {
      let clipboardData = '';
      if (this.selectorCells.length > 1) {
        if (this.isCopySelectorCellsAllowed()) {
          clipboardData = await this.copyPasteUtil.generateClipboardCellData(
            this.data,
            this.selectorCells,
            this.currentViewProperties.map((viewProp) => viewProp.propertyDefinition),
          );
        } else {
          clipboardData = await this.copyPasteUtil.getData(
            selectedCell.property.propertyDefinition,
            selectedCell.getValue(),
          );
        }
      } else if (selectedRows.length > 0) {
        clipboardData = await this.copyPasteUtil.generateClipboardRowsData(
          this.data,
          ObjectUtil.cloneDeep(selectedRows),
          this.currentViewProperties.map((viewProp) => viewProp.propertyDefinition),
        );
        this.store.dispatch(CollectionManagerActions.setCopiedRows({ copiedRows: ObjectUtil.cloneDeep(selectedRows) }));
        this.store.dispatch(CollectionManagerActions.clearSelectedEntityIds());
      } else if (selectedCell) {
        localStorage.setItem('clipboardData', JSON.stringify([selectedCell.rowId]));
        clipboardData = await this.copyPasteUtil.getData(
          selectedCell.property.propertyDefinition,
          selectedCell.getValue(),
        );
      }
      if (!clipboardData) {
        clipboardData = ' '; // need a space to copy to machine clipboard or nothing will get copied
      }
      this.copyToClipboard(clipboardData);
    }
  }

  public handleDocumentCutEvent() {
    if (this.editorMode !== EditorMode.EDIT) return;

    this.handleDocumentCopyEvent();
    const selectedCell = this.gridViewManager.selectedCell;
    if (selectedCell) {
      let cutCells = [];
      if (this.selectorCells.length > 1) {
        cutCells = ObjectUtil.cloneDeep(this.selectorCells);
      } else {
        cutCells = [{ rowId: selectedCell.rowId, columnId: selectedCell.propertySlug }];
      }
      this.store.dispatch(CollectionManagerActions.setCutCells({ cutCells }));
    }
  }

  public async handleDocumentPasteEvent(pasteEvent) {
    if ((pasteEvent && pasteEvent.target.tagName === 'INPUT') || this.editorMode !== EditorMode.EDIT) {
      return;
    }

    const selectedCell = this.gridViewManager.selectedCell;
    if (selectedCell || this.selectedRows.length > 0) {
      // Get contents from machine clipboard
      const clipboardContent = await this.getClipboardContent(pasteEvent);
      if (this.cutCells.length > 0) {
        this.gridSelectorActionHandler.applyChangesFromClipboardAndDeleteCells(clipboardContent);
      } else {
        this.gridSelectorActionHandler.applyChangesFromClipboard(clipboardContent);
      }
      this.store.dispatch(CollectionManagerActions.setCopiedRows({ copiedRows: [] }));
      this.store.dispatch(CollectionManagerActions.clearSelectedEntityIds());
    }
  }

  private copyToClipboard(clipboardData: string) {
    // This uses Angular Material's Clipboard
    const pending = this.clipboard.beginCopy(clipboardData);
    let remainingAttempts = 3;
    const attempt = async () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        pending.destroy();
      }
      if (!result && remainingAttempts === 0) {
        // this is only used for cypress test. Angular Clipboard should be successful on real UI.
        await window.navigator.clipboard.writeText(clipboardData);
      }
    };
    attempt();
  }

  private async getClipboardItemData(item: any): Promise<any> {
    const contentData = await new Promise((res) =>
      item.getAsString((data) => {
        res(data);
      }),
    );
    return contentData;
  }

  private async getClipboardContent(pasteEvent): Promise<any> {
    let contentData = '';
    if (navigator.clipboard && navigator.clipboard.readText) {
      // not all browsers support this
      contentData = await navigator.clipboard.readText();
    } else if (pasteEvent && pasteEvent.clipboardData?.items.length > 0) {
      pasteEvent.preventDefault();
      const item = pasteEvent.clipboardData.items[0];
      if (item.type === 'text' || item.type === 'text/plain') {
        contentData = await this.getClipboardItemData(item);
      }
    }
    return Promise.resolve(contentData);
  }

  getPropertyName(property) {
    const typeProperty = property.propertyDefinition;
    if ([PropertyType.ObjectReference, PropertyType.UserList].includes(typeProperty.propertyType)) {
      return typeProperty.slug + 'Id';
    }
    return typeProperty.slug;
  }

  groupBy(cells, key) {
    return cells.reduce((cell, x) => {
      (cell[x[key]] = cell[x[key]] || []).push(x);
      return cell;
    }, {});
  }

  isCopySelectorCellsAllowed() {
    const columnGroups = this.groupBy(this.selectorCells, 'columnId');
    let currentStartRowId = '';
    let currentEndRowId = '';
    let validCopy = true;
    // all selected columns must have the same number of rows to copy.
    Object.keys(columnGroups).forEach((columnGroup) => {
      const startRowId = columnGroups[columnGroup][0].rowId;
      const endRowId = columnGroups[columnGroup][columnGroups[columnGroup].length - 1].rowId;
      if (currentStartRowId === '') {
        currentStartRowId = startRowId;
        currentEndRowId = endRowId;
      }
      if (currentStartRowId !== startRowId && currentEndRowId !== endRowId) {
        validCopy = false;
      }
    });
    return validCopy;
  }
}
