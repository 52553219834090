import { Injectable } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors, CollectionManagerActions } from '../../collection-manager-store';
import { CopyPasteUtil } from '../copy-paste/copy-paste-util';
import { FillPasteUtil } from '../copy-paste/fill-paste-util';
import { DataCellComponent } from '../data-rows/data-cell/data-cell.component';
import { GridNavigationHandler } from '../grid-navigation-handler';
import { GridViewManager } from '../grid-view.manager';
import { GridSelectorActionHandler } from './grid-selector-action-handler';

export interface SelectorCell {
  rowId: string;
  columnId: string;
}

export interface SelectorRange {
  columnStartIndex?: number;
  columnEndIndex?: number;
  rowStartIndex: number;
  rowEndIndex: number;
  direction?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GridSelectorService {
  data;
  selectorActive = false;
  selectorKeyActive = false;
  selectionAnchorCell: DataCellComponent;
  currentView;
  startColumnIndex;
  startRowIndex;
  currentRowId;
  currentColumnId;
  selectedCellRange: SelectorRange = null;
  selectedCells: Array<SelectorCell> = [];
  selectorCells: Array<SelectorCell> = [];
  selectedRows: Array<any> = [];
  anchorRowSelectorId: string;
  fillHandleActive = false;
  selectedFillCellRange: SelectorRange = null;
  selectedFillCells: Array<SelectorCell> = [];
  fillAnchorCell: any = null;

  private copyPasteUtil: CopyPasteUtil;
  private fillPasteUtil: FillPasteUtil;

  constructor(
    private gridViewManager: GridViewManager,
    private navigationHandler: GridNavigationHandler,
    private gridSelectorActionHandler: GridSelectorActionHandler,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(CollectionManagerSelectors.displayedData).subscribe((data) => {
      if (data) {
        this.data = data;
      }
    });
    this.store
      .select(CollectionManagerSelectors.selectorActive)
      .subscribe((selectorActive) => (this.selectorActive = selectorActive));
    this.store
      .select(CollectionManagerSelectors.selectorKeyActive)
      .subscribe((selectorKeyActive) => (this.selectorKeyActive = selectorKeyActive));
    this.store
      .select(CollectionManagerSelectors.selectCurrentView)
      .subscribe((view) => (this.currentView = ObjectUtil.cloneDeep(view)));
    this.store
      .select(CollectionManagerSelectors.selectedEntityIds)
      .subscribe((selectedRows) => (this.selectedRows = ObjectUtil.cloneDeep(selectedRows)));
    this.store
      .select(CollectionManagerSelectors.anchorRowSelectorId)
      .subscribe((anchorRowSelectorId) => (this.anchorRowSelectorId = anchorRowSelectorId));
    this.store
      .select(CollectionManagerSelectors.selectorCells)
      .subscribe((selectorCells) => (this.selectorCells = selectorCells));

    this.copyPasteUtil = new CopyPasteUtil(this.gridViewManager);
    this.fillPasteUtil = new FillPasteUtil(this.gridViewManager);
  }

  handleCellMousedown(event, rowId, propertySlug) {
    if (this.selectedRows.length > 0) {
      this.store.dispatch(CollectionManagerActions.clearSelectedEntityIds());
    }
    this.initSelection();
    this.store.dispatch(CollectionManagerActions.setSelectorActive({ selectorActive: true }));
  }

  initSelection() {
    this.selectionAnchorCell = this.gridViewManager.selectedCell;
    if (this.selectionAnchorCell) {
      this.startRowIndex = this.data.map((row) => row.id).indexOf(this.selectionAnchorCell.rowId);
      this.startColumnIndex = this.currentView.properties.findIndex(
        (property) => property.slug === this.selectionAnchorCell.propertySlug,
      );
    }
  }

  setSelectionAnchorCell() {
    if (!this.selectionAnchorCell) {
      this.initSelection();
    }
  }

  resetSelectionAnchorCell() {
    this.selectionAnchorCell = null;
    this.startColumnIndex = null;
    this.startRowIndex = null;
  }

  handleCellMouseover(event, rowId, propertySlug) {
    if (this.selectorActive) {
      // Do not select the cell if it's the same cell as the anchor cell. A single selected cell should not be in the range selection
      if (this.selectionAnchorCell.rowId !== rowId || this.selectionAnchorCell.propertySlug !== propertySlug) {
        this.selectCells(propertySlug, rowId);
      }
    } else if (this.fillHandleActive) {
      this.handleAddFillCells(rowId);
    }
  }

  handleSelectRows() {
    if (this.selectedCells.length > 1) {
      const affectedCollectionElementRows = this.copyPasteUtil.groupBy(this.selectedCells, 'rowId');
      const ids = Object.keys(affectedCollectionElementRows);
      this.store.dispatch(CollectionManagerActions.setSelectedEntityIds({ ids }));
    } else if (this.gridViewManager.selectedCell) {
      const rowId = this.gridViewManager.selectedCell.rowId;
      if (this.selectedRows.includes(rowId)) {
        this.store.dispatch(CollectionManagerActions.removeSelectedEntityId({ id: rowId }));
      } else {
        this.store.dispatch(CollectionManagerActions.addSelectedEntityId({ id: rowId }));
      }
    }
    this.gridViewManager.removeSelectedCell();
  }

  handleSelectRowRange() {
    const sortedSelectedRows = this.selectedRows.sort((a, b) => {
      return this.data.findIndex((row) => row.id === a) - this.data.findIndex((row) => row.id === b);
    });
    const startIndex = this.data.findIndex((row) => row.id === sortedSelectedRows[0]);
    const endIndex = this.data.findIndex((row) => row.id === sortedSelectedRows[sortedSelectedRows.length - 1]);
    const selectedRows = [];
    for (let i = startIndex; i <= endIndex; i++) {
      selectedRows.push(this.data[i].id);
    }
    this.store.dispatch(CollectionManagerActions.setSelectedEntityIds({ ids: selectedRows }));
  }

  private selectCells(propertySlug: any, rowId: any) {
    const currentCellColumnIndex = this.currentView.properties.findIndex((property) => property.slug === propertySlug);
    const currentCellRowIndex = this.data.map((row) => row.id).indexOf(rowId);

    this.selectedCellRange = {
      columnStartIndex: Math.min(currentCellColumnIndex, this.startColumnIndex),
      columnEndIndex: Math.max(currentCellColumnIndex, this.startColumnIndex),
      rowStartIndex: Math.min(currentCellRowIndex, this.startRowIndex),
      rowEndIndex: Math.max(currentCellRowIndex, this.startRowIndex),
    };
    this.calculateSelectedCells();
    this.store.dispatch(CollectionManagerActions.setSelectorCells({ selectorCells: this.selectedCells }));
    this.setFillAnchorCell(this.selectedCells[this.selectedCells.length - 1]);
    if (this.gridViewManager.rowIds.indexOf(rowId) >= this.gridViewManager.rowIds.length - 2) {
      this.navigationHandler.verticalAutoScroll('down');
    } else if (this.gridViewManager.rowIds.indexOf(rowId) === 0) {
      this.navigationHandler.verticalAutoScroll('up');
    }
    const currentCell = this.gridViewManager.getCell(rowId, propertySlug);
    if (currentCell) {
      const cellCoords = currentCell.getBoundingRectangle();
      const screenCellRightEdge = cellCoords.x + cellCoords.width + 20; // right edge of the cell + 10 px to trigger scroll
      this.navigationHandler.checkForHorizontalScrollByPosition(screenCellRightEdge, cellCoords.x, 100);
    }
  }

  handleCellMouseup(event, rowId, propertySlug) {
    if (this.selectorActive) {
      this.store.dispatch(CollectionManagerActions.setSelectorActive({ selectorActive: false }));
      this.store.dispatch(CollectionManagerActions.setSelectorCells({ selectorCells: this.selectedCells }));
      this.setFillAnchorCell(this.selectedCells[this.selectedCells.length - 1]);
    } else if (this.fillHandleActive) {
      console.log(this.fillHandleActive);
      const sourceCells =
        this.selectedCells.length > 0
          ? ObjectUtil.cloneDeep(this.selectedCells)
          : [
              {
                rowId: this.gridViewManager.selectedCell.rowId,
                columnId: this.gridViewManager.selectedCell.propertySlug,
              },
            ];
      const properties = Object.keys(this.copyPasteUtil.groupBy(sourceCells, 'columnId'));
      const sourceRows = Object.keys(this.copyPasteUtil.groupBy(sourceCells, 'rowId'));
      const fillRows = Object.keys(this.copyPasteUtil.groupBy(this.selectedFillCells, 'rowId'));
      this.gridSelectorActionHandler.handleFillCellValues(fillRows, sourceRows, properties);
      this.fillHandleActive = false;
      this.selectedCells = this.selectedCells.concat(this.selectedFillCells);
      this.selectedFillCells = [];
      this.store.dispatch(CollectionManagerActions.setFillCells({ fillCells: [] }));
      this.store.dispatch(CollectionManagerActions.setSelectorCells({ selectorCells: this.selectedCells }));
    }
  }

  calculateSelectedCells() {
    const selectedCells = [];
    for (let j = this.selectedCellRange.rowStartIndex; j <= this.selectedCellRange.rowEndIndex; j++) {
      for (let i = this.selectedCellRange.columnStartIndex; i <= this.selectedCellRange.columnEndIndex; i++) {
        if (this.currentView.properties[i].enabled) {
          selectedCells.push({ rowId: this.data[j].id, columnId: this.currentView.properties[i].slug });
        }
      }
    }
    this.selectedCells = selectedCells;
  }

  handleClick(event, rowId, columnId) {
    if (event.shiftKey) {
      // handles shift key select
      const currentCellColumnIndex = this.currentView.properties.findIndex((property) => property.slug === columnId);
      const currentCellRowIndex = this.data.map((row) => row.id).indexOf(rowId);
      this.selectedCellRange = {
        columnStartIndex: Math.min(currentCellColumnIndex, this.startColumnIndex),
        columnEndIndex: Math.max(currentCellColumnIndex, this.startColumnIndex),
        rowStartIndex: Math.min(currentCellRowIndex, this.startRowIndex),
        rowEndIndex: Math.max(currentCellRowIndex, this.startRowIndex),
      };
      this.calculateSelectedCells();
      this.store.dispatch(CollectionManagerActions.setSelectorCells({ selectorCells: this.selectedCells }));
    } else if (event.ctrlKey || event.metaKey) {
      // handles control key select
      const selectedCells = ObjectUtil.cloneDeep(this.selectedCells);
      if (selectedCells.length === 0) {
        selectedCells.push({ rowId: this.selectionAnchorCell.rowId, columnId: this.selectionAnchorCell.propertySlug });
      }
      const cellIndex = this.selectedCells.findIndex(
        (selectedCell) => selectedCell.rowId === rowId && selectedCell.columnId === columnId,
      );
      if (cellIndex > -1) {
        selectedCells.splice(cellIndex, 1); // deselecting cell that has already been selected
      } else {
        selectedCells.push({ rowId, columnId });
      }
      this.selectedCells = selectedCells;
      this.store.dispatch(CollectionManagerActions.setSelectorCells({ selectorCells: this.selectedCells }));
    } else {
      // regular click
      this.selectionAnchorCell = this.gridViewManager.selectedCell;
      this.removeSelector();
    }
  }

  selectByKeyVertically(direction: string) {
    if (this.selectedRows.length > 0) {
      this.selectRowsByKeyVertically(direction);
    } else {
      this.selectCellsByKeyVertically(direction);
    }
  }

  selectRowsByKeyVertically(direction: string) {
    const sortedSelectedRows = this.selectedRows.sort((a, b) => {
      return this.data.findIndex((row) => row.id === a) - this.data.findIndex((row) => row.id === b);
    });

    const index = this.data.findIndex((row) => row.id === this.anchorRowSelectorId);
    const nextRowIndex = direction === 'up' ? index - 1 : index + 1;
    const nextRowId = this.data.map((row) => row.id)[nextRowIndex];
    if (!this.gridViewManager.rowIds.includes(nextRowId)) {
      this.navigationHandler.verticalAutoScroll(direction);
      return null;
    }
    if (this.selectedRows.includes(nextRowId)) {
      if (direction === 'down') {
        this.store.dispatch(CollectionManagerActions.removeSelectedEntityId({ id: sortedSelectedRows[0] }));
        this.store.dispatch(
          CollectionManagerActions.setAnchorRowSelectorId({
            anchorRowSelectorId: sortedSelectedRows.length >= 2 ? sortedSelectedRows[1] : null,
          }),
        );
      } else {
        this.store.dispatch(
          CollectionManagerActions.removeSelectedEntityId({ id: sortedSelectedRows[sortedSelectedRows.length - 1] }),
        );
        this.store.dispatch(
          CollectionManagerActions.setAnchorRowSelectorId({
            anchorRowSelectorId:
              sortedSelectedRows.length >= 2 ? sortedSelectedRows[sortedSelectedRows.length - 2] : null,
          }),
        );
      }
    } else {
      this.store.dispatch(CollectionManagerActions.addSelectedEntityId({ id: nextRowId }));
    }
  }

  selectCellsByKeyVertically(direction: string) {
    this.setSelectionAnchorCell();
    this.store.dispatch(CollectionManagerActions.setSelectorActive({ selectorActive: false }));
    if (!this.selectorKeyActive) {
      this.store.dispatch(CollectionManagerActions.setSelectorKeyActive({ selectorKeyActive: true }));
      const selectedCell = this.gridViewManager.selectedCell;
      if (!selectedCell) {
        return;
      }
      this.currentColumnId = selectedCell.propertySlug;
      this.currentRowId = selectedCell.rowId;
    }

    const rowIndex = this.gridViewManager.rowIds.findIndex((id) => id === this.currentRowId);
    const nextRowIndex = direction === 'up' ? rowIndex - 1 : rowIndex + 1;
    if (nextRowIndex < 0 || nextRowIndex > this.gridViewManager.rowIds.length - 1) {
      this.navigationHandler.verticalAutoScroll(direction);
      return null;
    }
    const nextRowId = this.gridViewManager.rowIds[nextRowIndex];
    this.currentRowId = nextRowId;
    this.selectCells(this.currentColumnId, this.currentRowId);
  }

  selectCellsByKeyHorizontally(direction: string) {
    if (!this.selectionAnchorCell) {
      this.initSelection();
    }
    this.store.dispatch(CollectionManagerActions.setSelectorActive({ selectorActive: false }));
    if (!this.selectorKeyActive) {
      this.store.dispatch(CollectionManagerActions.setSelectorKeyActive({ selectorKeyActive: true }));
      const selectedCell = this.gridViewManager.selectedCell;
      if (!selectedCell) {
        return;
      }
      this.currentColumnId = selectedCell.propertySlug;
      this.currentRowId = selectedCell.rowId;
    }

    const rowDef = this.gridViewManager.rowMap.get(this.currentRowId);
    const index = rowDef.allCells.findIndex((cell) => cell.propertySlug === this.currentColumnId);
    const newIndex = direction === 'left' ? index - 1 : index + 1;
    if (newIndex < 0 || newIndex > rowDef.allCells.length - 1) {
      return null;
    }
    const nextCell = rowDef.allCells[newIndex];
    this.currentColumnId = nextCell.propertySlug;

    this.navigationHandler.checkForHorizontalScroll(nextCell);
    this.selectCells(this.currentColumnId, this.currentRowId);
  }

  scrollHorizontallyToSelectedCell() {
    const selectedCell = this.gridViewManager.selectedCell;
    this.navigationHandler.checkForHorizontalScroll(selectedCell);
  }

  removeSelector() {
    this.selectedCells = [];
    if (this.selectorCells.length > 0) {
      this.store.dispatch(CollectionManagerActions.setSelectorCells({ selectorCells: [] }));
    }
    this.store.dispatch(CollectionManagerActions.setSelectorKeyActive({ selectorKeyActive: false }));
    this.store.dispatch(CollectionManagerActions.setSelectorActive({ selectorActive: false }));
    this.setFillAnchorCell(null);
    this.resetSelectionAnchorCell();
  }

  removeCutCells() {
    this.store.dispatch(CollectionManagerActions.setCutCells({ cutCells: [] }));
  }

  removeCopiedRows() {
    this.store.dispatch(CollectionManagerActions.setCopiedRows({ copiedRows: [] }));
  }

  handleFillRowCells() {
    this.gridSelectorActionHandler.handleFillRowCells(this.selectedCells);
  }

  setFillHandleActive() {
    this.fillHandleActive = true;
  }

  handleAddFillCells(rowId) {
    this.selectedFillCellRange = this.fillPasteUtil.getFillRowRange(rowId, this.data, this.selectedCells);
    this.selectedFillCells = [];
    if (this.selectedFillCellRange != null) {
      const cellGroup = Object.keys(this.copyPasteUtil.groupBy(this.selectedCells, 'columnId'));
      const properties = cellGroup.length > 0 ? cellGroup : [this.gridViewManager.selectedCell.propertySlug];
      properties.forEach((property) => {
        if (this.selectedFillCellRange.rowStartIndex <= this.selectedFillCellRange.rowEndIndex) {
          // dragging down
          if (this.selectedCells.findIndex((selectedCell) => selectedCell.rowId === rowId) === -1) {
            for (let j = this.selectedFillCellRange.rowStartIndex; j <= this.selectedFillCellRange.rowEndIndex; j++) {
              this.selectedFillCells.push({ rowId: this.data[j].id, columnId: property });
            }
          }
          if (this.selectedFillCellRange.direction === 'down') {
            this.fillAnchorCell = this.selectedFillCells[this.selectedFillCells.length - 1];
          }
        } else if (this.selectedFillCellRange.rowStartIndex > this.selectedFillCellRange.rowEndIndex) {
          // dragging up
          for (let j = this.selectedFillCellRange.rowStartIndex; j >= this.selectedFillCellRange.rowEndIndex; j--) {
            this.selectedFillCells.push({ rowId: this.data[j].id, columnId: property });
          }
          this.selectedFillCells.reverse();
        }
      });
    } else {
      this.fillAnchorCell = this.selectedCells[this.selectedCells.length - 1];
    }
    this.setFillAnchorCell(this.fillAnchorCell);
    this.store.dispatch(CollectionManagerActions.setFillCells({ fillCells: this.selectedFillCells }));
    if (this.gridViewManager.rowIds.indexOf(rowId) >= this.gridViewManager.rowIds.length - 2) {
      this.navigationHandler.verticalAutoScroll('down');
    } else if (this.gridViewManager.rowIds.indexOf(rowId) === 0) {
      this.navigationHandler.verticalAutoScroll('up');
    }
  }

  private setFillAnchorCell(cell) {
    this.fillAnchorCell = cell;
    this.store.dispatch(CollectionManagerActions.setFillAnchorCell({ fillAnchorCell: this.fillAnchorCell }));
  }
}
