import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ViewDefinition } from '@contrail/client-views';
import { ObjectUtil } from '@contrail/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LoadingIndicatorActions } from 'src/app/common/loading-indicator/loading-indicator-store';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { PlansActionTypes } from 'src/app/plans/plans-store/plans.actions';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '..';
import { CollectionManagerViewService } from '../../collection-manager-view.service';
import { CollectionManagerActionTypes } from '../collection-manager.actions';

@Injectable()
export class CollectionViewsEffects {
  constructor(
    private actions$: Actions,
    private viewService: CollectionManagerViewService,
    private store: Store<RootStoreState.State>,
    private webSocketService: WebSocketService,
    private snackBar: MatSnackBar,
  ) {}

  loadCurrentPlanSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActionTypes.LOAD_CURRENT_PLAN_SUCCESS),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          this.store.dispatch(CollectionManagerActions.loadViewDefinitions());
        }),
      ),
    { dispatch: false },
  );

  /** Stores the last selected view id into local storage (for this plan) */
  storeLastView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionViewsActionTypes.SET_CURRENT_VIEW_DEFINITION),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          const planId = store.plans?.currentPlan?.id;
          if (action.viewDefinition && planId) {
            localStorage.setItem('lastUsedViewId' + planId, action.viewDefinition.id);
          }
        }),
      ),
    { dispatch: false },
  );

  loadViewDefinitions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollectionManagerActions.CollectionViewsActionTypes.LOAD_VIEW_DEFINITIONS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const planId = store.plans.currentPlan.id;
        return from(this.viewService.getViewDefinitions(planId)).pipe(
          tap((views: Array<ViewDefinition>) => {
            views.sort((v1, v2) => (v1.sortOrder > v2.sortOrder ? 1 : -1));
            let currentView;

            let viewToLoad;
            const lastUsedViewId = localStorage.getItem('lastUsedViewId' + planId);
            if (lastUsedViewId) {
              viewToLoad = views.find((v) => v.id === lastUsedViewId);
            }
            if (!viewToLoad && views.length) {
              viewToLoad = views[0];
            }
            if (viewToLoad) {
              this.store.dispatch(CollectionManagerActions.setCurrentViewDefinition({ viewDefinition: viewToLoad }));
            }
          }),
          map((data) => CollectionManagerActions.loadViewDefinitionsSuccess({ data })),
          catchError((error) => observableOf(CollectionManagerActions.loadViewDefinitionsFailure({ error }))),
        );
      }),
    ),
  );
  loadViewDefinitionsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionViewsActionTypes.LOAD_VIEW_DEFINITIONS_SUCCESS),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          if (!action.data.length) {
            // If there is no plan specific view in existence yet, create one named Default
            console.log('No views exist! Creating default');
            const planId = store.plans.currentPlan.id;
            const viewDefinition: ViewDefinition = {
              applicationViewSlug: 'plan:plan_editor',
              contextReference: `plan:${planId}`,
              label: 'Default',
              viewType: 'grid',
              properties: [
                {
                  enabled: true,
                  slug: 'itemFamily',
                  typeRootSlug: 'plan-placeholder',
                },
                {
                  enabled: true,
                  slug: 'itemOption',
                  typeRootSlug: 'plan-placeholder',
                },
              ],
            };
            this.store.dispatch(CollectionManagerActions.createViewDefinitions({ viewDefinitions: [viewDefinition] }));
          }
        }),
      ),
    { dispatch: false },
  );

  setCurrentViewDefinition$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionViewsActionTypes.SET_CURRENT_VIEW_DEFINITION),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          const adjustedViewDefinition = this.viewService.getViewDefinitionWithRestrictedPropertiesRemoved(
            action.viewDefinition.id,
            action.viewDefinition,
          );
          this.store.dispatch(
            CollectionManagerActions.setCurrentViewDefinitionSuccess({ viewDefinition: adjustedViewDefinition }),
          );
        }),
      ),
    { dispatch: false },
  );

  updateViewDefinition$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionViewsActionTypes.UPDATE_VIEW_DEFINITION),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          const newView = ObjectUtil.cloneDeep(action.changes);
          this.viewService.bindPropertiesToView(newView);
          this.store.dispatch(
            CollectionManagerActions.updateViewDefinitionSuccess({ id: action.id, changes: newView }),
          );
          if (action.changes.sorts) {
            // sorts were changed...
            this.store.dispatch(CollectionManagerActions.setSortsSuccess());
          }
          try {
            this.viewService.updateViewDefinition(action.id, action.changes);
            if (action.changes.label) {
              this.snackBar.open('View Updated.', '', { duration: 2000 });
            }
          } catch (error) {
            this.snackBar.open(error, '', { duration: 2000 });
          }
        }),
      ),
    { dispatch: false },
  );

  updateViewDefinitionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionViewsActionTypes.UPDATE_VIEW_DEFINITION_SUCCESS),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          const viewDefintion = this.viewService.getViewDefinitionWithRestrictedPropertiesAddedBack(
            action.id,
            action.changes,
          );
          this.webSocketService.sendMessage({
            sessionId: store.userSessions.currentSessionId,
            action: 'SESSION_EVENT',
            event: {
              eventType: 'UPDATE_COLLECTION_VIEW',
              changes: { id: action.id, ...viewDefintion }, // this sends the whoe entity right now.
            },
          });
        }),
      ),
    { dispatch: false },
  );

  createViewDefinitions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollectionManagerActions.CollectionViewsActionTypes.CREATE_VIEW_DEFINITIONS),
      withLatestFrom(this.store),
      mergeMap(([action, store]: [any, RootStoreState.State]) => {
        this.store.dispatch(
          LoadingIndicatorActions.setLoading({ loading: true, message: 'Creating view definitions.' }),
        );
        const planId = store.plans.currentPlan.id;
        return from(this.viewService.createViewDefinitions(action.viewDefinitions, `plan:${planId}`)).pipe(
          map((data) => {
            const viewDefinitions = data;
            for (const viewDefinition of viewDefinitions) {
              this.viewService.bindPropertiesToView(viewDefinition);
            }
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open('View Created.', '', { duration: 2000 });
            return CollectionManagerActions.createViewDefinitionsSuccess({ viewDefinitions: data });
          }),
          catchError((error) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(CollectionManagerActions.createViewDefinitionsFailure({ error }));
          }),
        );
      }),
    ),
  );
  createViewDefinitionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionViewsActionTypes.CREATE_VIEW_DEFINITIONS_SUCCESS),
        tap((action: any) => {
          const viewDefinition = action.viewDefinitions[0];
          this.store.dispatch(CollectionManagerActions.setCurrentViewDefinition({ viewDefinition }));
        }),
      ),
    { dispatch: false },
  );

  deleteViewDefinition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollectionManagerActions.CollectionViewsActionTypes.DELETE_VIEW_DEFINITION),
      mergeMap((action: any) => {
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
        return from(this.viewService.deleteViewDefinition(action.viewDefinition)).pipe(
          map((entity) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open('View deleted successfully', '', { duration: 2000 });
            return CollectionManagerActions.deleteViewDefinitionSuccess({ viewDefinition: entity });
          }),
          catchError((error) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(CollectionManagerActions.deleteViewDefinitionFailure({ error }));
          }),
        );
      }),
    ),
  );

  deleteViewDefinitionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionViewsActionTypes.DELETE_VIEW_DEFINITION_SUCCESS),
        withLatestFrom(this.store.select(CollectionManagerSelectors.selectCollectionViews)),
        tap(([action, viewDefs]: [any, Array<ViewDefinition>]) => {
          this.store.dispatch(CollectionManagerActions.setCurrentViewDefinition({ viewDefinition: viewDefs[0] }));
        }),
      ),
    { dispatch: false },
  );

  updateViewDefinitionsSortOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollectionManagerActions.CollectionViewsActionTypes.UPDATE_VIEW_ORDER),
      mergeMap((action: any) => {
        return from(this.viewService.updateViewSortOrder(action.viewSortOrder)).pipe(
          map((updatedViewDefs) => {
            for (const viewDef of updatedViewDefs) {
              this.viewService.bindPropertiesToView(viewDef);
            }
            return CollectionManagerActions.updateViewOrderSuccess({ updatedViewDefs });
          }),
          catchError((error) => observableOf(CollectionManagerActions.updateViewOrderFailure({ error }))),
        );
      }),
    ),
  );

  applyViewDefinitionChanges$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionViewsActionTypes.APPLY_VIEW_DEFINITION_CHANGES),
        withLatestFrom(this.store.select(CollectionManagerSelectors.selectCollectionViews)),
        tap(([action, viewDefs]: [any, Array<ViewDefinition>]) => {
          const previousViewDef = viewDefs.find((v) => v.id === action.id);
          const viewDefWithNoRestrictedProps = this.viewService.getViewDefinitionWithRestrictedPropertiesRemoved(
            action.id,
            action.changes,
          );
          const viewDefWithHydratedProps = this.viewService.copyAndApplyPropertyDefinitionsFromPreviousView(
            viewDefWithNoRestrictedProps,
            previousViewDef,
          );

          this.store.dispatch(
            CollectionManagerActions.applyViewDefinitionChangesSuccess({
              ...action,
              changes: viewDefWithHydratedProps,
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  applyViewDefinitionChangesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionViewsActionTypes.APPLY_VIEW_DEFINITION_CHANGES_SUCCESS),
        withLatestFrom(this.store.select(CollectionManagerSelectors.selectCollectionViews)),
        tap(([action, viewDefs]: [any, Array<ViewDefinition>]) => {
          if (action.changes.sorts) {
            this.store.dispatch(CollectionManagerActions.setSortsSuccess());
          }
        }),
      ),
    { dispatch: false },
  );

  setFilterDefinitionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActionTypes.SET_FILTER_DEFINITION),
        tap(() => {
          this.store.dispatch(CollectionManagerActions.setFilterDefinitionSuccess());
        }),
      ),
    { dispatch: false },
  );
}
