import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { PropertyType, PropertyValueFormatter } from '@contrail/types';
import { AGGREGATE_FUNCTION_MAP, PivotAggregateHelper } from '../../pivot-aggregate-helper';
import { ELIGIBLE_GROUP_PROPERTY_TYPES } from '@components/pivot-grid/pivot-grid-grouping-row-properties';
import { PivotGoals } from '../../pivot-goals';
import { RoundNumberPipe } from '@pipes/round-number.pipe';

const formatter = new PropertyValueFormatter();

interface GridViewPropertyConfiguration extends ViewPropertyConfiguration {
  scope: string;
}
const EXPAND_ICON = 'add_box';
const COLLAPSE_ICON = 'indeterminate_check_box';

@Component({
  selector: 'div[app-pivot-data-cell]',
  templateUrl: './pivot-data-cell.component.html',
  styleUrls: ['./pivot-data-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PivotDataCellComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  constructor(
    private currentElement: ElementRef,
    private renderer: Renderer2,
  ) {}

  @Input() properties: GridViewPropertyConfiguration[];
  @Input() property: GridViewPropertyConfiguration;
  @Input() data: any;
  @Input() side: string;
  @Input() rowGoals: PivotGoals;
  @Input() showGoals: boolean = true;
  @Output() rowAction = new EventEmitter();

  public display = '';
  public value;
  public goalDisplay;
  public goalValue;
  public goalDifDisplay;
  public goalDifDisplayClass;
  public selected = false;
  public remoteSelected = false;
  public propertyType: PropertyType;
  public align = 'left';
  public editable;
  public rowId;
  public propertySlug;
  public cellClass;
  expanded = false;
  icon = EXPAND_ICON;
  isRowGrouping = false;
  marginLeft = 0;
  cellWidth = '95%';
  ngOnInit() {
    this.setModel();
  }

  ngOnChanges(changes) {
    if (this.data && this.property) {
      this.setModel();
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  async setModel() {
    let groupingDepth = 0;
    this.rowId = this.data.id;
    if (!this.property && this.properties && this.data) {
      // these properties are for the left side
      groupingDepth = this.properties.findIndex((prop) => prop.slug === this.data.propertySlug);
      this.property = this.properties[groupingDepth];
      this.marginLeft = groupingDepth * 50;
      this.cellWidth = `calc(100% - 25px)`;
    }
    this.propertySlug = this.property.slug;
    this.propertyType = this.property.propertyDefinition?.propertyType as PropertyType;
    this.isRowGrouping = ELIGIBLE_GROUP_PROPERTY_TYPES.includes(this.propertyType);
    this.value = this.getValue();
    this.display = this.getDisplay(this.value);
    this.align = this.getAlign();
    this.expanded = this.data.expanded;
    this.icon = !this.expanded ? EXPAND_ICON : COLLAPSE_ICON;
    if (this.align === 'right' || this.propertySlug === 'count') {
      this.addClass('right-align');
    }
    if (this.value === PivotAggregateHelper.EMPTY_VALUE) {
      this.addClass('italic');
      this.addClass('text-gray-400');
    }
    if (this.rowGoals) {
      const goal = this.rowGoals.goals[this.propertySlug];
      this.goalValue = goal?.value;

      if (this.goalValue) {
        this.goalDisplay = formatter.formatValueForProperty(this.goalValue, this.property.propertyDefinition);
        //this.goalDisplay = RoundNumberPipe.getRoundedNumber(this.goalValue,1);
      }

      if (this.goalValue !== undefined) {
        let value = this.value?.total || this.value;
        this.setGoalDifDisplay(value, goal);
      }
    }
  }

  setGoalDifDisplay(value, goal) {
    if (goal === undefined || value === undefined) {
      return;
    }
    this.goalValue = goal.value;
    let goalDif = this.goalValue - value;
    let goalDifPercentage;
    if (this.goalValue !== 0) {
      goalDifPercentage = value / this.goalValue - 1;
    }
    this.goalDifDisplay = '(' + Math.round(goalDifPercentage * 100) + '%)';

    this.goalDifDisplayClass = 'goal-good';
    // % based tollerance
    if (goal.tolerancePercentage) {
      const absDifPercent = Math.abs(goalDifPercentage);
      if (absDifPercent > goal.tolerancePercentage) {
        this.goalDifDisplayClass = 'goal-error';
      }
    }

    // Value based tollerance
    if (goal.toleranceValue) {
      const absDifValue = Math.abs(goalDif);
      if (absDifValue > goal.toleranceValue) {
        this.goalDifDisplayClass = 'goal-error';
      }
    } else if (goal.comparisionType === 'LESS_THAN') {
      if (value > goal.value) {
        this.goalDifDisplayClass = 'goal-error';
      }
    } else {
      // DEFAULT TO GREATER_THAN
      if (value < goal.value) {
        this.goalDifDisplayClass = 'goal-error';
      }
    }
  }

  getValue() {
    return this.data[this.property.slug];
  }

  getDisplay(value) {
    let display = '';
    if (value === PivotAggregateHelper.EMPTY_VALUE) {
      display = 'Empty';
    } else {
      if (!this.property.propertyDefinition) {
        display = value;
      } else if (this.property.propertyDefinition.slug === 'updatedOn') {
        display = formatter.formatDateTime(value);
      } else if (this.property.slug === 'count') {
        display = value;
      } else {
        if (this.property.aggregateFunction && value) {
          display = value[AGGREGATE_FUNCTION_MAP[this.property.aggregateFunction]];
          display = formatter.formatValueForProperty(display, this.property.propertyDefinition);
        } else {
          display = formatter.formatValueForProperty(value, this.property.propertyDefinition);
        }
      }
    }
    return display;
  }

  getAlign() {
    return [PropertyType.Percent, PropertyType.Currency, PropertyType.Number, PropertyType.Formula].includes(
      this.propertyType,
    )
      ? 'right'
      : 'left';
  }

  getPropertyName() {
    const typeProperty = this.property.propertyDefinition;
    if (PropertyType.ObjectReference === typeProperty.propertyType) {
      return typeProperty.slug + 'Id';
    }
    return typeProperty.slug;
  }

  addClass(className: string) {
    this.renderer.addClass(this.currentElement.nativeElement, className);
  }

  toggleRow() {
    if (!this.expanded) {
      this.rowAction.emit({ action: 'expand', rowData: this.data });
    } else {
      this.rowAction.emit({ action: 'collapse', rowData: this.data });
    }
    this.expanded = !this.expanded;
    this.icon = !this.expanded ? EXPAND_ICON : COLLAPSE_ICON;
  }
}
