import { Component, Input, OnInit, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { RootStoreModule, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions } from '../collection-manager-store';
import { DataGroup } from './data-group';
import { PropertyType } from '@contrail/types';

@Component({
  selector: 'app-data-group',
  templateUrl: './data-group.component.html',
  styleUrls: ['./data-group.component.scss'],
})
export class DataGroupComponent implements OnInit {
  @Input() group: DataGroup;
  @Input() properties: Array<ViewPropertyConfiguration>;
  @Input() editorMode = 'VIEW';
  @Output() onDrop: EventEmitter<any> = new EventEmitter();
  constructor(
    private store: Store<RootStoreState.State>,
    private eRef: ElementRef,
  ) {}

  ngOnInit(): void {}

  listDrop(event) {
    this.onDrop.emit(event);
  }
  dragOver(event) {
    event.preventDefault();
  }

  @HostListener('dragover', ['$event']) public stdDragOver(evt) {
    // evt.preventDefault();
    // console.log('dragOver!');
    // const group = this.eRef.nativeElement.querySelector('.data-group');
    // group.classList.add('hover');
    // evt.stopPropagation();
  }
  @HostListener('drop', ['$event']) public stdDrop(evt) {
    // evt.preventDefault();
    // const group = this.eRef.nativeElement.querySelector('.data-group');
    // group.classList.remove('hover');
    // const entityString = evt.dataTransfer.getData('entity');
    // const entity = JSON.parse(entityString);
    // console.log('entity: ', entity);
    // if (entity.entityType === 'item') {
    //   this.add({ itemOptionId: entity.id });
    // }
    // evt.stopPropagation();
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    // evt.preventDefault();
    // const group = this.eRef.nativeElement.querySelector('.data-group');
    // group.classList.remove('hover');
    // evt.stopPropagation();
  }

  add(values = {}) {
    const vals: any = { ...values };
    vals[this.group.dim1.propertyDefinition.slug] = this.group.dim1.value;
    if (this.group.dim1.propertyDefinition.propertyType === PropertyType.ObjectReference) {
      vals[this.group.dim1.propertyDefinition.slug + 'Id'] = this.group.dim1.value?.id;
    }
    if (this.group.dim2) {
      vals[this.group.dim2.propertyDefinition.slug] = this.group.dim2.value;
      if (this.group.dim2.propertyDefinition.propertyType === PropertyType.ObjectReference) {
        vals[this.group.dim2.propertyDefinition.slug + 'Id'] = this.group.dim2.value?.id;
      }
    }
    return vals;
  }

  async createPlaceholders(count: number = 1) {
    const newPhs = [];
    for (let i = 0; i < count; i++) {
      newPhs.push(this.add({}));
    }
    this.store.dispatch(CollectionManagerActions.createCollectionDataEntities({ entities: newPhs }));
  }

  itemTrackByFn(index, item) {
    return item.id;
  }
}
