import { Action, createReducer, on } from '@ngrx/store';
import { State, asyncErrorsEntityAdapter, asyncErrorsInitialState } from './async-errors.state';
import * as AsyncErrorActions from './async-errors.actions';
import { nanoid } from 'nanoid';

const add = (state: State, error) => {
  return {
    ...state,
    asyncErrors: asyncErrorsEntityAdapter.addOne({ ...error, id: error.id ?? nanoid(16) }, state.asyncErrors),
  };
};

export const asyncErrorsReducers = createReducer(asyncErrorsInitialState, on(AsyncErrorActions.addAsyncError, add));

export function reducer(state: State | undefined, action: Action): any {
  return asyncErrorsReducers(state, action);
}
