<div class="pr-6 pl-6 pb-3 h-full">
  <div class="item-history-header pr-2 pl-2">
    <app-search-bar
      class="w-64 mr-10"
      data-test="item-history-search"
      [disabled]="isLoading"
      [textSmall]="true"
      [lgGray]="true"
      [placeholder]="'Search history'"
    >
    </app-search-bar>

    <app-type-property-form-field-multi-select
      class="!w-40 mr-10"
      data-test="history-project-filter"
      appearance=""
      [propertyFormConfiguration]="projectFilterDefinition"
      (valueChange)="handleProjectFilterChange($event)"
      [showSearchBar]="true"
      [showSelectAll]="true"
    ></app-type-property-form-field-multi-select>
    <app-type-property-form-field-multi-select
      class="!w-40 mr-10"
      data-test="history-property-filter"
      appearance=""
      [propertyFormConfiguration]="propertyFilterDefinition"
      (valueChange)="handlePropertyFilterChange($event)"
      [showSearchBar]="true"
      [showSelectAll]="true"
    ></app-type-property-form-field-multi-select>
    <app-type-property-form-field-multi-select
      class="!w-40 mr-10"
      data-test="history-user-filter"
      appearance=""
      [propertyFormConfiguration]="userFilterDefinition"
      (valueChange)="handleUserFilterChange($event)"
      [showSearchBar]="true"
      [showSelectAll]="true"
    ></app-type-property-form-field-multi-select>
    <app-date-filter
      class="!w-40 mr-10"
      data-test="history-date-filter"
      [dateFilterAttribute]="dateFilterProperty"
      (dateFilterChanged)="handleDateFilterChange($event)"
    ></app-date-filter>
  </div>
  <div *ngIf="isLoading" class="loading pr-2 pl-2">
    <mat-spinner diameter="40"> </mat-spinner>
    <div class="message">Loading</div>
  </div>
  <div class="history-list-container pt-3 pr-2 pl-2 square-scroll" [ngClass]="{ hidden: isLoading }">
    <app-entity-history-list [historyEvents]="filteredHistoryEvents$ | async"></app-entity-history-list>
  </div>
</div>
