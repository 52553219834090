import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { Plan } from '../plans-store/plans.state';
import { PlansService } from '../plans.service';
import { AssortmentHistoryService } from '@common/assortment-history/assortment-history.service';

@Component({
  selector: 'app-assortment-compare',
  templateUrl: './assortment-compare.component.html',
  styleUrls: ['./assortment-compare.component.scss'],
})
export class AssortmentCompareComponent implements OnInit {
  public targetPlan: Plan;
  public history$: Observable<Array<any>>;
  constructor(
    private store: Store<RootStoreState.State>,
    private assortmentHistoryService: AssortmentHistoryService,
    public dialogRef: MatDialogRef<AssortmentCompareComponent>,
    private planService: PlansService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.targetPlan = data.targetPlan;
  }

  ngOnInit() {
    this.history$ = from(
      this.assortmentHistoryService.getAssortmentHistoryItems(this.targetPlan.targetAssortmentId),
    ).pipe(
      map((history) => {
        return history as Array<any>;
      }),
    );
  }
}
