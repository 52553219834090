export class AggregateHelper {
  /** Computes a total for a given index on a collection
   * @data  data to aggregte
   * @index index of data for which to create totals for
   */
  public static getTotal(data, index) {
    const total = data.reduce((a, b) => {
      if (b[index]) {
        return a + parseFloat(b[index]);
      }
      return a;
    }, 0);
    return total;
  }
  public static getAverage(data, index) {
    const withVal = data.filter((obj) => obj[index] !== undefined && obj[index] > 0);
    const total = this.getTotal(data, index);
    if (!total || total === 0 || withVal.length === 0) {
      return 0;
    }
    const average = total / withVal.length;
    return average;
  }
  public static getMin(data: Array<any>, index) {
    return Math.min(...data.map((obj) => (obj[index] ? obj[index] : 0)));
  }
  public static getMax(data: Array<any>, index) {
    return Math.max(...data.map((obj) => (obj[index] ? obj[index] : 0)));
  }
  public static getUniqueCount(data, index) {
    return [...new Set(data.filter((obj) => obj[index]).map((obj) => obj[index]))].length;
  }

  /** Gets a map of counts for a set of values */
  public static getValueCounts(data: Array<any>, index, values: Array<string>) {
    const counts = {};
    values.forEach((val) => {
      counts[val] = data.filter((obj) => obj[index] === val).length;
    });
    return counts;
  }

  /** Gets a map of totals for a given set of groups and a given 'value'.  Example would be
   * to compute total for 'volume' of a data set, grouped by 'collection'.
   * @data  data to aggregte
   * @groupingIndex  index in the data to use when grouping
   * @groupingValues set of values to compute groups for
   * @totalIndex index of data for which to create totals for
   */
  public static getGroupTotals(
    data: Array<any>,
    groupingIndex: string,
    groupingValues: Array<string>,
    totalIndex: string,
  ) {
    const totals = {};
    groupingValues.forEach((val) => {
      totals[val] = this.getTotal(
        data.filter((obj) => obj[groupingIndex] === val),
        totalIndex,
      );
    });
    return totals;
  }
}
