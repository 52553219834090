import { createSelector } from '@ngrx/store';
import { Plan, plansEntityAdapter } from './plans.state';
import { RootStoreState } from 'src/app/root-store';

export const { selectAll, selectEntities, selectIds, selectTotal } = plansEntityAdapter.getSelectors();

const planEntities = (state: RootStoreState.State) => state.plans.plans.entities;
export const plans = createSelector(planEntities, (state) => Object.values(state) as Array<Plan>);
export const currentPlan = (state: RootStoreState.State) => state.plans.currentPlan;
export const planRowOrder = (state: RootStoreState.State) => state.plans.planRowOrder;
export const plansLoaded = (state: RootStoreState.State) => state.plans.plansLoaded;
export const editorMode = (state: RootStoreState.State) => state.plans.editorMode;
