<div
  #dataRowElement
  [attr.data-test]="'row-' + side + '-' + rowId"
  class="grid-row"
  [style.top.px]="rowNumber * rowHeight"
  [style.height.px]="rowHeight"
  [ngClass]="{ dropped: isRowDropped }"
  class="{{ (isStatusMessageElementSelected$ | async) ? 'status-message-selected' : '' }} {{
    (isCopied$ | async) ? 'copied' : ''
  }} {{ (isHighlighted$ | async) ? 'highlighted' : '' }}"
>
  <div [style.height.px]="'25'" class="checkbox-cell" *ngIf="side === 'left'">
    <div
      #rowDragHandle
      [attr.data-test]="'row-drag-handle-' + rowId"
      class="{{
        (isHighlighted$ | async) && this.rowDragHandler && this.rowDragHandler.isDraggingAllowed()
          ? 'row-drag-handle'
          : ''
      }} {{
        (this.rowDragHandler && !this.rowDragHandler.isDraggingAllowed()) || !(isHighlighted$ | async)
          ? 'row-drag-handle-hidden'
          : ''
      }}"
    >
      <mat-icon>drag_indicator</mat-icon>
    </div>

    <div class="row-number-container non-selectable">
      <div class="row-number" [attr.data-test]="'row-number-' + rowId" *ngIf="!(isHighlighted$ | async)">
        {{ realRowNumber + 1 }}
      </div>
      <mat-checkbox
        [attr.data-test]="'row-selection-checkbox-' + rowId"
        [checked]="isSelected$ | async"
        (mousedown)="checkboxClicked($event)"
        (change)="toggleSelected()"
        *ngIf="isHighlighted$ | async"
      ></mat-checkbox>
    </div>
  </div>

  <app-data-row-validation [style.height.px]="'25'" *ngIf="side === 'left'" [data]="dataRow"></app-data-row-validation>

  <div
    app-data-cell
    [attr.data-test]="'cell-' + property?.slug + '-' + rowId"
    [editorMode]="editorMode"
    [style.width.px]="property.width || 150"
    class="row-cell"
    [ngClass]="{ 'center-align': property.propertyDefinition?.propertyType === 'boolean' }"
    [property]="property"
    [data]="dataRow"
    *ngFor="let property of properties; trackBy: trackByFn; last as isLast; let i = index"
    [isCellRangeSelected]="(selectedRangeCells$ | async).includes(property.slug)"
    [isCellCutSelected]="(selectedCutCells$ | async).includes(property.slug)"
    [isCellFillSelected]="(selectedFillCells$ | async).includes(property.slug)"
    [isCellSearchReplaceSelected]="(searchReplaceCells$ | async).includes(property.slug)"
    [isCellOverrideOptionSelected]="(overrideOptionCells$ | async).includes(property.slug)"
    [remoteSelectedProperties]="remoteSelectedProperties$ | async"
    [isCellSearchReplaceActive]="(activeSearchReplaceCell$ | async)?.columnId === property.slug"
    [isCellSelected]="(selectedCellRow$ | async)?.propertySlug === property.slug"
    [collectionStatusMessages]="collectionStatusMessages$ | async"
  ></div>
</div>
