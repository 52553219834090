<div class="flex flex-col flex-1">
  <div class="border-b-2 mt-2">
    <app-source-change-history-filters (filterChanges)="handleFilterChange($event)">
    </app-source-change-history-filters>
  </div>

  <div class="flex flex-col flex-1 h-full" *ngIf="!(loading$ | async)">
    <app-source-change-history-list class="h-full" *ngIf="(history$ | async).length > 0" [history]="history$ | async">
    </app-source-change-history-list>

    <div class="flex justify-center pt-44" *ngIf="(history$ | async).length === 0">
      No history available for this time range.
    </div>
  </div>

  <div class="loading-container" *ngIf="loading$ | async">
    <mat-progress-spinner diameter="50" mode="indeterminate"> </mat-progress-spinner>
    <div class="message">Loading source history...</div>
  </div>
</div>
