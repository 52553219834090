import { Injectable } from '@angular/core';
import { EditorModeActions } from '@common/editor-mode/editor-mode-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { DocumentHistoryActions } from '.';
import { EntitySnapshotService } from '../entity-snapshot.service';

@Injectable()
export class DocumentHistoryEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private entitySnapshotService: EntitySnapshotService,
  ) {}

  loadEntitySnapshotSucess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentHistoryActions.DocumentHistoryActionTypes.LOAD_CURRENT_ENTITY_SNAPSHOT_SUCCESS),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          // SET THE CURRENT WORKSPACE
          this.store.dispatch(EditorModeActions.setEditorMode({ editorMode: EditorMode.VIEW }));
        }),
      ),
    { dispatch: false },
  );
  clearEntitySnapshot$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DocumentHistoryActions.DocumentHistoryActionTypes.CLEAR_CURRENT_ENTITY_SNAPSHOT),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          // SET THE CURRENT WORKSPACE
          this.store.dispatch(EditorModeActions.setEditorMode({ editorMode: EditorMode.EDIT }));
        }),
      ),
    { dispatch: false },
  );

  loadCurrentEntitySnapshot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentHistoryActions.DocumentHistoryActionTypes.LOAD_CURRENT_ENTITY_SNAPSHOT),
      switchMap((action: any) => {
        return from(this.entitySnapshotService.getEntitySnapshotById(action.id)).pipe(
          map((data) => DocumentHistoryActions.loadCurrentEntitySnapshotSuccess({ entitySnapshot: data })),
          catchError((error) => observableOf(DocumentHistoryActions.loadCurrentEntitySnapshotFailure({ error }))),
        );
      }),
    ),
  );
}
