import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { SelectedCellInfo } from '../context-menu.component';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { SpreadValueHandler } from '../../grid-view/spread/spread-value-handler';
import { SelectorCell } from '../../grid-view/selectors/grid-selector.service';

export class SpreadContextMenuAction extends ContextMenuAction {
  sorts: [];
  selectorCells: SelectorCell[];
  viewProperties: any[];

  constructor(
    protected store: Store<RootStoreState.State>,
    private spreadValueHandler: SpreadValueHandler,
  ) {
    super(store);
    this.actionLabel = 'Spread values';
    this.svgActionIcon = 'spread-icon';
    this.shortCutKey = 'Ctrl-Q';
    this.testRef = 'app-menu-spread-action';

    this.store
      .select(CollectionManagerSelectors.selectorCells)
      .subscribe((selectorCells) => (this.selectorCells = selectorCells));
    this.store
      .select(CollectionManagerSelectors.currentViewProperties)
      .subscribe((props) => (this.viewProperties = props));
  }

  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    let rowIds = [];
    if (ids.length > 0) {
      rowIds = ids;
    } else {
      rowIds = [selectedCellInfo.entityId];
    }
    this.spreadValueHandler.handleSpreadValue(true);
  }

  canInvoke(editorMode: string, ids: Array<string>, selectedCellInfo: SelectedCellInfo = null) {
    if (!this.spreadValueHandler.isSpreadActionAllowed()) {
      return false;
    }
    if (editorMode !== 'EDIT') {
      return false;
    }
    return true;
  }
}
