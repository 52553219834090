import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-create-placeholders',
  templateUrl: './create-placeholders.component.html',
  styleUrls: ['./create-placeholders.component.scss'],
})
export class CreatePlaceholdersComponent implements OnInit {
  @Output() createPlaceholders = new EventEmitter();
  @Input() minValue = 1;
  @Input() maxValue = 99;
  @Input() defaultValue = 1;
  count: UntypedFormControl;
  error = false;
  isFocused = false;

  constructor() {}

  @HostListener('document:keydown.enter', ['$event'])
  handleAddItem(event: KeyboardEvent = null) {
    if (!this.error && this.isFocused) {
      event.stopPropagation();
      this.createPlaceholders.emit(this.count.value);
    }
  }

  ngOnInit(): void {
    this.count = new UntypedFormControl(this.defaultValue);
    this.count.valueChanges.subscribe((value) => {
      if (value < this.minValue || value > this.maxValue) {
        this.error = true;
      } else {
        this.error = false;
      }
    });
  }

  handleClick() {
    if (!this.error) {
      this.createPlaceholders.emit(this.count.value);
    }
  }

  toggleFocus() {
    this.isFocused = !this.isFocused;
  }
}
