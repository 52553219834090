import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs';
import { ObjectUtil } from '@contrail/util';
import { PlansSelectors, RootStoreState, UserSessionSelectors } from '@rootstore';
import { EntitySnapshotService } from '@common/document-history/entity-snapshot.service';
import { ProjectItemService } from '@common/projects/project-item.service';
import { CollectionManagerActions } from '../collection-manager-store';
import { CollectionStatusMessageService } from '../side-panel/status-messages/collection-status-message.service';
import { PlaceholderProjectItemUpdateService } from './placeholder-project-item-update-service';
import { AsyncErrorsActions } from '@common/errors/async-errors-store';
import { ErrorActionType } from '@common/errors/async-errors-store/async-errors.state';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { chunk } from 'lodash';
import pLimit from 'p-limit';
const limit = pLimit(5);

export interface BatchUpdateProjectItemsRequest {
  changes: Array<{ id: string; changes: any }>;
  placeholderUpdates: {
    updates: Array<any>;
    skipRecordingUndoRedo?: boolean;
    undoRedo?: boolean;
  };
  selectedCell?: {
    rowId: string;
    propertySlug: string;
  };
  undoUuid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectItemUpdateService {
  private currentProjectId;
  private sessionId;

  constructor(
    private store: Store<RootStoreState.State>,
    private projectItemService: ProjectItemService,
    private placeholderProjectItemUpdateService: PlaceholderProjectItemUpdateService,
    private collectionStatusMessageService: CollectionStatusMessageService,
    private entitySnapshotService: EntitySnapshotService,
    private snackBar: MatSnackBar,
    private webSocketService: WebSocketService,
  ) {
    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(
        tap((ws) => {
          if (!ws) {
            return;
          }
          this.currentProjectId = ws.projectId;
        }),
      )
      .subscribe();

    this.store.select(UserSessionSelectors.currentSessionId).subscribe((sessionId) => {
      if (sessionId) {
        this.sessionId = sessionId;
      }
    });
  }

  async batchUpdateProjectItems({
    changes,
    placeholderUpdates,
    selectedCell,
    undoUuid,
  }: BatchUpdateProjectItemsRequest) {
    const { placeholderChanges, undoActionUuid } =
      await this.placeholderProjectItemUpdateService.processProjectItemUpdatesAndGetChangesToApply(
        placeholderUpdates.updates,
        placeholderUpdates.skipRecordingUndoRedo,
        placeholderUpdates.undoRedo,
      );

    const undoActionUuidToUse = placeholderUpdates?.skipRecordingUndoRedo ? undoUuid : undoActionUuid;

    const validationCheck = await this.collectionStatusMessageService.validateChangesAndSetAlerts({
      changes: placeholderChanges,
    });
    if (validationCheck.hasErrors) {
      this.snackBar.open('Validation Error: Update(s) could not be processed.', '', { duration: 5000 });
    }

    const placeholderChangesToApply = this.filterOutPlaceholderChangesWithErrors(placeholderChanges, validationCheck);
    const projectItemChangesToApply = this.filterOutProjectItemChangesWithErrors(
      changes,
      placeholderChanges,
      validationCheck,
    );

    if (!projectItemChangesToApply || projectItemChangesToApply.length === 0) return;

    this.store.dispatch(
      CollectionManagerActions.batchApplyCollectionEntityChanges({
        changes: ObjectUtil.cloneDeep(placeholderChangesToApply),
        broadcast: true,
        skipErrorValidation: true,
      }),
    );

    this.projectItemService
      .batchUpsert(projectItemChangesToApply)
      .then(() => this.triggerSnapshotOfPlan())
      .catch((error) => {
        this.store.dispatch(
          AsyncErrorsActions.addAsyncError({
            error,
            errorType: ErrorActionType.UPDATE_PROJECT_ITEMS,
            undoActionUuid: undoActionUuidToUse,
            selectedCell,
          }),
        );
      });
  }

  private triggerSnapshotOfPlan() {
    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(
        take(1),
        tap((plan) => {
          if (plan?.id) {
            this.entitySnapshotService.createEntitySnapshotWithDebounce(`plan:${plan.id}`);
          }
        }),
      )
      .subscribe();
  }

  private filterOutPlaceholderChangesWithErrors(placeholderChanges, validationCheck) {
    if (!validationCheck.hasErrors) return placeholderChanges;

    const placeholderIdsWithErrors = validationCheck.errorMessages.map((error) => error.collectionElementId);
    return placeholderChanges.filter((change) => !placeholderIdsWithErrors.includes(change.id));
  }

  private filterOutProjectItemChangesWithErrors(projectItemChanges, placeholderChanges, validationCheck) {
    if (!validationCheck.hasErrors) return projectItemChanges;

    const placeholderIdsWithErrors = validationCheck.errorMessages.map((error) => error.collectionElementId);
    const placeholderChangesWithErrors = placeholderChanges.filter((change) =>
      placeholderIdsWithErrors.includes(change.id),
    );

    return projectItemChanges.filter((projectItemChange) => {
      const itemId = projectItemChange.id;
      const isItemFamilyInPlaceholdersWithErrors = placeholderChangesWithErrors.some(
        (placeholderChange) => placeholderChange.changes?.itemFamily?.id === itemId,
      );

      const isItemOptionInPlaceholdersWithErrors = placeholderChangesWithErrors.some(
        (placeholderChange) => placeholderChange.changes?.itemOption?.id === itemId,
      );

      return !(isItemFamilyInPlaceholdersWithErrors || isItemOptionInPlaceholdersWithErrors);
    });
  }

  public async upsertProjectItem(itemId, data) {
    if (!this.currentProjectId) {
      return;
    }
    const id = `${this.currentProjectId}:${itemId}`;
    this.webSocketService.sendMessage({
      sessionId: this.sessionId,
      action: 'ADD_LIVE_DATA_STREAM_TOPIC',
      topicsToSubscribe: [`project-item:${id}`],
    });

    return await this.projectItemService.upsertProjectItem(itemId, data);
  }

  public async batchUpsert(batchChanges: Array<{ id: string; changes: any }>): Promise<any[]> {
    const ids: Set<string> = new Set();
    for (const change of batchChanges) {
      const id = `${this.currentProjectId}:${change.id}`;
      ids.add(id);
    }
    const uniqueIds = Array.from(ids);
    this.webSocketService.sendMessage({
      sessionId: this.sessionId,
      action: 'ADD_LIVE_DATA_STREAM_TOPIC',
      topicsToSubscribe: uniqueIds.map((id) => `project-item:${id}`),
    });

    return await this.projectItemService.batchUpsert(batchChanges);
  }
}
