import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';

@Component({
  selector: 'app-status-messages-indicator',
  templateUrl: './status-messages-indicator.component.html',
  styleUrls: ['./status-messages-indicator.component.scss'],
})
export class StatusMessagesIndicatorComponent implements OnInit {
  @Output() onShowAlertMessages = new EventEmitter();
  @Input() messages: CollectionStatusMessage[];
  @Input() isLoading: boolean;
  @Input() hasFailedToLoad: boolean;

  constructor() {}

  ngOnInit(): void {}

  showAlertMessage() {
    this.onShowAlertMessages.emit();
  }

  get shouldShowIconWithBadges(): boolean {
    if (this.isLoading || this.hasFailedToLoad) {
      return false;
    }

    return this.messages?.length > 0;
  }
}
