import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../collection-manager-store';
import { DataCellComponent } from './data-rows/data-cell/data-cell.component';
import { GridViewManager } from './grid-view.manager';

@Injectable({
  providedIn: 'root',
})
export class RemoteEditorHandler {
  /**
   * Potential Issues:
   */
  private selectedProperties: Array<any> = [];
  constructor(
    private gridManagerService: GridViewManager,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(CollectionManagerSelectors.remoteSelectedProperties).subscribe((map) => {
      if (!map) {
        return;
      }
      this.deselectAll();
      this.select(map);
    });
  }

  private deselectAll() {
    this.selectedProperties.forEach((prop) => {
      let cell = this.gridManagerService.getCell(prop.entityId, prop.propertySlug);
      if (cell) {
        cell.remoteDeselect();
      }
    });
  }

  private select(map: Map<string, any>) {
    this.selectedProperties = [];
    map.forEach((selectedProperty, key) => {
      let cell = this.gridManagerService.getCell(selectedProperty.entityId, selectedProperty.propertySlug);
      if (cell) {
        cell.remoteSelect(selectedProperty.user);
        this.selectedProperties.push({
          entityId: selectedProperty.entityId,
          propertySlug: selectedProperty.propertySlug,
        });
      }
    });
  }
}
