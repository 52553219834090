<app-item-data-chooser
  data-test="item-data-chooser-panel"
  [arrowKeyNavigationEnabled]="arrowKeyNavigationEnabled"
  [showHeader]="showHeader"
  [showFilter]="showFilter"
  [showAllControl]="showAllControl"
  [hideSource]="hideSource"
  [chooserTitle]="title"
  [baseCriteria]="criteria"
  [allowAddEntity]="allowAddEntity"
  [allowAddMultipleEntities]="allowAddMultipleEntities"
  [existingItemIds]="collectionItemIds$ | async"
  (close)="handleClose()"
  (addEntity)="addItemData($event)"
  (addEntities)="addMultipleItemData($event)"
  [resultsHeight]="resultsHeight"
  (entityClicked)="handleClick($event)"
  [draggable]="true"
  [ngClass]="{ 'data-chooser': showFilter && showHeader }"
  [allowShowDetails]="allowShowDetails"
  [isEntityDisabled]="isItemDisabled"
  [projectId]="currentProjectId"
  [enableInfiniteScroll]="true"
  [targetSourceType]="targetSourceType"
  [allowItemLevelSelection]="itemChooserLevelSelectionActive"
  [allowAddDuplicate]="false"
  [shouldDefaultToSource]="true"
  [ignorePersistedBaseCriteria]="ignorePersistedBaseCriteria"
  [minimumItemCountOnLoad]="minimumItemCountOnLoad"
></app-item-data-chooser>
