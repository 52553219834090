import { ElementRef, HostListener, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { SizeRangeHelper } from '@common/size-range/size-range-helper';
import { SizeRangeEditorComponent } from '@components/size-range-editor/size-range-editor.component';
import { SizeRange } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { DataCellEditBaseComponent } from '../data-cell-edit-base-component';

@Component({
  selector: 'app-data-cell-edit-size-range',
  templateUrl: './data-cell-edit-size-range.component.html',
  styleUrls: ['./data-cell-edit-size-range.component.scss'],
})
export class DataCellEditSizeRangeComponent extends DataCellEditBaseComponent implements OnChanges {
  constructor(private elementRef: ElementRef) {
    super();
  }

  @ViewChild('sizeRangeEditor') sizeRangeEditor: SizeRangeEditorComponent;
  @ViewChild('sizeRangePopup') sizeRangeElem: ElementRef;

  showPanel = false;
  sizeRange: SizeRange;
  templateSizeArray: Array<string> = [];
  dimensionCells1: string[] = [];
  dimensionCells2: string[] = [];
  isSaveEnabled = false;
  sizeRangeTemplate: SizeRange;
  showEllipsis = false;
  sizes: Array<string> = [];

  @HostListener('click', ['$event']) onClick(event) {
    if (!this.showPanel) {
      this.openPanel();
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.templateSizeArray = [];

    if (changes.data) {
      this.sizeRangeTemplate = SizeRangeHelper.getConstrainingSizeRange(this.data);
    }
    if (changes.value && this.sizeRangeTemplate) {
      if (Array.isArray(this.sizeRangeTemplate.sizes)) {
        this.dimensionCells1 = ObjectUtil.cloneDeep(this.sizeRangeTemplate.sizes);
      } else {
        const dimensionCellSet1: Set<string> = new Set();
        this.dimensionCells2 = Object.keys(this.sizeRangeTemplate.sizes);
        this.dimensionCells2.forEach((size) => {
          this.sizeRangeTemplate.sizes[size].forEach((innerSize) => {
            dimensionCellSet1.add(innerSize);
          });
        });
        this.dimensionCells1 = Array.from(dimensionCellSet1).sort();
      }
    }
    if (Array.isArray(this.sizeRangeTemplate?.sizes)) {
      this.templateSizeArray = this.sizeRangeTemplate.sizes;
    }
    if (changes.value || changes.property) {
      const allSizes = SizeRangeHelper.getSizeRangeSizesList(this.value);
      this.showEllipsis = false;
      let totalLength = 0;
      let maxSizeCount = 0;
      const maxWidth = (this.property.width - 11) * 0.95;
      allSizes.forEach((size) => {
        totalLength += size.length * 5 + 14;
        if (totalLength < maxWidth) {
          maxSizeCount++;
        }
      });

      if (allSizes.length > maxSizeCount) {
        this.sizes = allSizes.slice(0, maxSizeCount + 1);
        this.showEllipsis = true;
      } else {
        this.sizes = allSizes;
      }
    }
  }

  openPanel() {
    this.showPanel = true;
    setTimeout(() => {
      this.setPosition();
    }, 1);
  }

  setPosition() {
    if (!this.sizeRangeElem) {
      return;
    }
    const panelHeight = this.sizeRangeElem.nativeElement?.getBoundingClientRect().height || 50;
    const currentChooserRightPosition = this.sizeRangeElem.nativeElement.getBoundingClientRect().right;
    const pageY = this.elementRef.nativeElement.getBoundingClientRect().y;
    const chooserContainerHeight = 450;
    const singleRowHeight = 30;
    let panelTopPosition = this.elementRef.nativeElement.getBoundingClientRect().top;
    const maxDim =
      window.innerHeight * 0.4 > chooserContainerHeight ? chooserContainerHeight : window.innerHeight * 0.4;
    const distanceFromBottom = (100 * (window.innerHeight - maxDim - pageY)) / window.innerHeight;
    if (distanceFromBottom < 12) {
      panelTopPosition = panelTopPosition - panelHeight - singleRowHeight;
    }
    if (currentChooserRightPosition > window.innerWidth) {
      this.sizeRangeElem.nativeElement.style.setProperty('right', '20px');
    }
    this.sizeRangeElem.nativeElement.style.top = `${panelTopPosition}px`;
  }

  handleDataChange(data) {
    this.isSaveEnabled = JSON.stringify(data) !== JSON.stringify(this.value);
  }

  save() {
    if (this.isSaveEnabled) {
      const rawData = this.sizeRangeEditor.getData();
      const data = { dimension1: rawData.dimension1, dimension2: rawData.dimension2, sizes: rawData.sizes };
      this.sizeRange = ObjectUtil.cloneDeep(data);
      this.value = ObjectUtil.cloneDeep(this.sizeRange);
      this.isSaveEnabled = false;
      this.handleChange();
    }
  }
}
