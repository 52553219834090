<div cdkDropListGroup class="group-list">
  <app-data-group
    *ngFor="let group of groups; trackBy: groupTrackByFn"
    [group]="group"
    (onDrop)="drop($event)"
    [editorMode]="editorMode"
    [properties]="properties"
  >
  </app-data-group>
</div>
