import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { SortDefinition, SortDirection } from 'src/app/common/components/sort/sort-definition';
import { tap } from 'rxjs/operators';
import { MenuComponent } from 'src/app/common/components/menu/menu.component';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';

@Component({
  selector: 'app-sort-selector',
  templateUrl: './sort-selector.component.html',
  styleUrls: ['./sort-selector.component.scss'],
})
export class SortSelectorComponent implements OnInit {
  constructor(private store: Store<RootStoreState.State>) {}
  properties: Array<SortDefinition>;
  currentSorts: Array<SortDefinition>;
  @Input() disabled = false;
  @Input() toolTip;
  @ViewChild('filterMenu') filterMenu: MenuComponent;

  ngOnInit(): void {
    this.store
      .select(CollectionManagerSelectors.typeDefinitions)
      .pipe(
        tap((typeDefinitions) => {
          this.properties = [...typeDefinitions['plan-placeholder'].typeProperties].map((property) => {
            return {
              propertySlug: property.slug,
              propertyLabel: property.label,
              propertyType: property.propertyType,
              direction: SortDirection.ASCENDING,
            };
          });
          this.properties = this.properties.sort((a, b) => {
            return a.propertyLabel?.toUpperCase() < b.propertyLabel?.toUpperCase() ? -1 : 1;
          });
        }),
      )
      .subscribe();
    this.store
      .select(CollectionManagerSelectors.selectCurrentView)
      .subscribe((currentView) => (this.currentSorts = ObjectUtil.cloneDeep(currentView?.sorts) || []));
  }

  performSort(event) {
    this.store.dispatch(CollectionManagerActions.setSorts({ sorts: event.sorts }));
  }
}
