<div [ngClass]="{ 'side-menu-open': (currentViewSubject$ | async) }">
  <div *ngIf="(scrollBarHeight$ | async) > 0" class="holder" (click)="handleClick($event)" [style.height.px]="height">
    <div
      id="scrollBar"
      [style.height.px]="scrollBarHeight$ | async"
      class="bar"
      cdkDragBoundary=".holder"
      cdkDrag
      cdkDragLockAxis="y"
      (cdkDragMoved)="setScroll($event)"
      [cdkDragFreeDragPosition]="scrollDragPosition"
    ></div>
  </div>
</div>
