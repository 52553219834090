<div *ngIf="currentView$ | async">
  <app-header-bar></app-header-bar>
  <div
    class="content"
    [ngStyle]="{ width: 'calc(100vw - ' + (sidePanelWidth$ | async) + 'px)' }"
    (contextmenu)="onContextMenu($event)"
  >
    <app-grid-view *ngIf="(currentView$ | async).viewType === 'grid'"></app-grid-view>
    <app-matrix-view *ngIf="(currentView$ | async).viewType === 'matrix'"></app-matrix-view>
    <app-kanban-view *ngIf="(currentView$ | async).viewType === 'kanban'"></app-kanban-view>
    <app-pivot-view *ngIf="(currentView$ | async).viewType === 'pivot'"></app-pivot-view>
  </div>
  <app-side-panel></app-side-panel>
  <app-comment-overlay></app-comment-overlay>
  <app-spread-value></app-spread-value>
  <app-context-menu *ngIf="(currentView$ | async).viewType !== 'pivot'"></app-context-menu>
</div>

<app-no-connection-overlay></app-no-connection-overlay>
