<div
  class="column-header-menu-icon"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
  [attr.data-test]="'column-header-menu-' + property.slug"
>
  <mat-icon>arrow_drop_down</mat-icon>
</div>

<mat-menu class="column-header-menu" #contextMenu="matMenu" [hasBackdrop]="true">
  <ng-template matMenuContent>
    <div *ngFor="let menuAction of menuActions">
      <button
        mat-menu-item
        (click)="invoke(menuAction.action)"
        *ngIf="showAction(menuAction.action)"
        [attr.data-test]="'column_header_action_' + menuAction.action"
      >
        <mat-icon *ngIf="menuAction.icon">{{ menuAction.icon }}</mat-icon>
        <mat-icon *ngIf="menuAction.svgIcon" [svgIcon]="menuAction.svgIcon"></mat-icon>

        <span>{{ menuAction.label }}</span>
      </button>
    </div>
  </ng-template>
</mat-menu>
