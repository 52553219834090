import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { PlanUndoRedoService } from '../undo-redo/plan-undo-redo.service';
import { GridViewManager } from '../grid-view/grid-view.manager';
import { GridNavigationHandler } from '../grid-view/grid-navigation-handler';
import { AsyncError, ErrorActionType } from '@common/errors/async-errors-store/async-errors.state';
import { AsyncErrorActionTypes } from '@common/errors/async-errors-store/async-errors.actions';
import { BadRequestError } from '@common/errors/bad-request-error';

@Injectable({
  providedIn: 'root',
})
export class PlanErrorHandlerService {
  private GENERIC_UPDATE_ERROR_MESSAGE = `Update(s) could not be processed.`;

  constructor(
    private planUndoRedoService: PlanUndoRedoService,
    private gridViewService: GridViewManager,
    private gridNavigationHandler: GridNavigationHandler,
    private snackBar: MatSnackBar,
    private actions$: Actions,
  ) {}

  public subscribeToErrors() {
    this.actions$
      .pipe(
        ofType(AsyncErrorActionTypes.ADD_ASYNC_ERROR_ENTITY),
        tap((action: any) => {
          this.handleAsyncError(action);
        }),
      )
      .subscribe();
  }

  public handleAsyncError(asyncError: AsyncError) {
    const errorType = asyncError.errorType;
    switch (errorType) {
      case ErrorActionType.CREATE_PLACEHOLDERS:
        this.handleErrorOnCreate(asyncError);
        break;
      case ErrorActionType.UPDATE_ITEMS:
      case ErrorActionType.UPDATE_PROJECT_ITEMS:
      case ErrorActionType.UPDATE_PLACEHOLDERS:
        this.handleErrorOnUpdate(asyncError);
        break;
    }
  }

  public handleErrorOnCreate({ error, undoActionUuid }: AsyncError) {
    if (undoActionUuid) {
      this.planUndoRedoService.undoAndRemoveActionByUuid(undoActionUuid);
    }

    if (this.isErrorMessageAlreadyOnDisplay()) {
      return;
    }

    this.snackBar.open('Error: Create operations could not be processed.', '', { duration: 5000 });
  }

  public handleErrorOnUpdate({ error, undoActionUuid, selectedCell }: AsyncError) {
    if (undoActionUuid) {
      this.planUndoRedoService.undoAndRemoveActionByUuid(undoActionUuid);
    }

    if (this.isErrorMessageAlreadyOnDisplay()) {
      return;
    }

    const errorMessage = this.getUpdateFailedErrorMessage(error);
    this.snackBar.open(errorMessage, '', { duration: 5000 });

    if (selectedCell) {
      this.gridNavigationHandler.goToCell(selectedCell.rowId, selectedCell.propertySlug);

      setTimeout(() => {
        const cell = this.gridViewService.getCell(selectedCell.rowId, selectedCell.propertySlug);
        this.gridViewService.setSelectedCell(cell);
      });
    }
  }

  private getUpdateFailedErrorMessage(error: any) {
    const isUniquenessError = Boolean(
      error instanceof BadRequestError &&
        Array.isArray(error.message) &&
        error.message.some((error) => error?.propertiesMustBeUnique?.length),
    );

    if (isUniquenessError) {
      return `Uniqueness Error: ${this.GENERIC_UPDATE_ERROR_MESSAGE}`;
    }

    if (error?.firstValidationErrorMessage) {
      return `Error: ${this.GENERIC_UPDATE_ERROR_MESSAGE} ${error.firstValidationErrorMessage}`;
    }

    return `Error: ${this.GENERIC_UPDATE_ERROR_MESSAGE}`;
  }

  private isErrorMessageAlreadyOnDisplay(): Boolean {
    return Boolean(
      this.snackBar._openedSnackBarRef &&
        this.snackBar._openedSnackBarRef.instance?.data?.message &&
        this.snackBar._openedSnackBarRef.instance.data.message.includes(this.GENERIC_UPDATE_ERROR_MESSAGE),
    );
  }
}
