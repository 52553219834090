<div data-test="sort-panel" [ngClass]="{ 'sort-panel': sorts?.length }">
  <div class="message" *ngIf="!sorts?.length">No sorts have been applied</div>
  <div id="cdk-drop-list" *ngIf="sorts?.length" cdkDropList (cdkDropListDropped)="drop($event)">
    <div *ngFor="let sort of sorts; index as i" cdkDrag data-test="sort-property">
      <app-sort-option
        (applySort)="applySort($event)"
        (removeSort)="removeSort($event)"
        [sortIndex]="i"
        [properties]="properties"
        [sort]="sort"
      ></app-sort-option>
    </div>
  </div>
  <div class="action-links">
    <div data-test="add-sort-button" class="action" (click)="addSort($event)">+ Add sort</div>
    <div class="action-right" *ngIf="sorts.length > 0">
      <!--<div>
          <mat-slide-toggle color="primary" data-test="keep-sorted-button" [checked]="keepSorted" (change)="toggleKeepSorted($event)">Keep sorted</mat-slide-toggle>
        </div>-->
      <div class="flex justify-between">
        <div class="mr-[25px]" data-test="clear-sort-button" (click)="clearAllSortProperties()">Clear all</div>
        <div
          data-test="apply-sort-button"
          [ngClass]="{ action: isApplyActive(), 'action-disabled': !isApplyActive() }"
          (click)="applySort(true)"
        >
          Apply
        </div>
      </div>
    </div>
  </div>
</div>
