import { ChangeDetectorRef, Injectable } from '@angular/core';
import { EntityReference } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { CommentsSelectors } from 'src/app/common/comments/comments-store';
import { Comment } from 'src/app/common/comments/comments.service';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../collection-manager-store';
import { GridRow, GridViewManager } from './grid-view.manager';

@Injectable({
  providedIn: 'root',
})
export class CollectionCommentsHandler {
  constructor(
    private gridManagerService: GridViewManager,
    private store: Store<RootStoreState.State>,
  ) {
    this.subscribe();
  }

  private subscribe() {
    combineLatest([this.store.select(CommentsSelectors.selectContextComments), this.gridManagerService.displayedRows$])
      .pipe(
        filter(([comments, gridRows]) => gridRows?.length && comments?.length),
        tap(([comments, gridRows]) => {
          this.clearCommentIndicators(gridRows);
          this.setCommentIndicators(comments, gridRows);
        }),
      )
      .subscribe();
  }

  private clearCommentIndicators(gridRows: Array<GridRow>) {
    gridRows.forEach((gridRow) => {
      gridRow.allCells.forEach((cell) => {
        cell.setHasComment(false);
      });
    });
  }

  private setCommentIndicators(comments: Array<Comment>, gridRows: Array<GridRow>) {
    comments.forEach((comment) => {
      if (!comment.ownedByPropertySlug || !comment.ownedByReference || comment.status === 'closed') {
        return;
      }
      const ref = new EntityReference(comment.ownedByReference);
      const cell = this.gridManagerService.getCell(ref.id, comment.ownedByPropertySlug); // This fails on initial load because cells are not rendered yet.
      if (cell) {
        console.log('setCommentIndicators: ', cell.rowId, cell.hasComment, ref.id);
        cell.setHasComment(true);
      }
    });
  }
}
