import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CollectionManagerSelectors } from 'src/app/collection-manager/collection-manager-store';
import { CollectionStatusMessage } from 'src/app/collection-manager/collection-manager-store/collection-status-messages/collection-status-messages.state';
import { CollectionDataEntity } from 'src/app/collection-manager/collection-manager.service';
import { RootStoreState } from 'src/app/root-store';

@Component({
  selector: 'app-data-row-validation',
  template: `
    <img
      src="/assets/images/drop.svg"
      style="height: 15px; width: 15px;"
      matTooltip="Item is dropped."
      matTooltipPosition="right"
      *ngIf="dropped"
    />
    <div
      class="warning"
      *ngIf="(messages$ | async) && !dropped"
      matTooltipPosition="right"
      [matTooltip]="messages$ | async"
      matTooltipClass="multiline-tooltip"
    >
      <mat-icon>warning</mat-icon>
    </div>
  `,
  styleUrls: ['./data-row-validation.scss'],
})
export class DataRowValidationComponent implements OnChanges {
  @Input() data: CollectionDataEntity;
  public valid = true;
  public messages$: Observable<string>;
  public dropped = false;
  constructor(private store: Store<RootStoreState.State>) {}

  ngOnChanges() {
    this.dropped = this.data?.isDropped === true;
    this.messages$ = this.store.select(CollectionManagerSelectors.collectionStatusMessages).pipe(
      map((messages: Array<CollectionStatusMessage>) => {
        return messages
          .filter((message) => {
            return message.collectionElementId === this.data.id;
          })
          .map((message) => message.message)
          .join('\n');
      }),
    );
  }
}
