import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs';
import { Entities } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { EntitySnapshotService } from '@common/document-history/entity-snapshot.service';
import { CollectionManagerActions } from '../collection-manager-store';
import { PlaceholderItemUpdateService } from '../placeholders/placeholder-item-update-service';
import { CollectionStatusMessageService } from '../side-panel/status-messages/collection-status-message.service';
import { AsyncErrorsActions } from '@common/errors/async-errors-store';
import { ErrorActionType } from '@common/errors/async-errors-store/async-errors.state';
import { ItemService } from '@common/items/item.service';

export interface BatchUpdateItemsRequest {
  changes: Array<any>;
  placeholderUpdates: {
    updates: Array<any>;
    skipRecordingUndoRedo?: boolean;
    optionUpdates?: any;
  };
  selectedCell?: {
    rowId: string;
    propertySlug: string;
  };
  undoUuid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ItemUpdateService {
  constructor(
    private store: Store<RootStoreState.State>,
    private placeholderItemUpdateService: PlaceholderItemUpdateService,
    private itemService: ItemService,
    private collectionStatusMessageService: CollectionStatusMessageService,
    private entitySnapshotService: EntitySnapshotService,
    private snackBar: MatSnackBar,
  ) {}

  async batchUpdateItems({ changes, placeholderUpdates, selectedCell, undoUuid }: BatchUpdateItemsRequest) {
    const updatesToPlaceholdersWithItems = await this.processItemUpdatesOnPlaceholders(
      placeholderUpdates,
      undoUuid,
      selectedCell,
    );
    if (!updatesToPlaceholdersWithItems) {
      return;
    }

    const placeholderChanges = updatesToPlaceholdersWithItems.placeholderChanges;
    const undoActionUuidToUse = placeholderUpdates?.skipRecordingUndoRedo
      ? undoUuid
      : updatesToPlaceholdersWithItems.undoActionUuid;

    const validationCheck = await this.collectionStatusMessageService.validateChangesAndSetAlerts({
      changes: placeholderChanges,
    });
    if (validationCheck.hasErrors) {
      this.snackBar.open('Validation Error: Update(s) could not be processed.', '', { duration: 5000 });
    }

    const placeholderChangesToApply = this.filterOutPlaceholderChangesWithErrors(placeholderChanges, validationCheck);
    const itemChangesToApply = this.filterOutItemChangesWithErrors(changes, placeholderChanges, validationCheck);

    if (!itemChangesToApply || itemChangesToApply.length === 0) return;

    this.store.dispatch(
      CollectionManagerActions.batchApplyCollectionEntityChanges({
        changes: ObjectUtil.cloneDeep(placeholderChangesToApply),
        broadcast: true,
        skipErrorValidation: true,
      }),
    );

    this.itemService
      .batchUpdate(itemChangesToApply)
      .then(() => this.triggerSnapshotOfPlan())
      .catch((error) => {
        this.store.dispatch(
          AsyncErrorsActions.addAsyncError({
            error,
            errorType: ErrorActionType.UPDATE_ITEMS,
            undoActionUuid: undoActionUuidToUse,
            selectedCell,
          }),
        );
      });
  }

  private async processItemUpdatesOnPlaceholders(placeholderUpdates, undoUuid, selectedCell) {
    try {
      return await this.placeholderItemUpdateService.processItemUpdatesAndGetChangesToApply(
        placeholderUpdates.updates,
        placeholderUpdates.skipRecordingUndoRedo,
        placeholderUpdates.optionUpdates,
      );
    } catch (error) {
      if (undoUuid) {
        this.store.dispatch(
          AsyncErrorsActions.addAsyncError({
            error,
            errorType: ErrorActionType.UPDATE_ITEMS,
            undoActionUuid: undoUuid,
            selectedCell,
          }),
        );
      }
    }
  }

  private triggerSnapshotOfPlan() {
    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(
        take(1),
        tap((plan) => {
          if (plan?.id) {
            this.entitySnapshotService.createEntitySnapshotWithDebounce(`plan:${plan.id}`);
          }
        }),
      )
      .subscribe();
  }

  private filterOutPlaceholderChangesWithErrors(placeholderChanges, validationCheck) {
    if (!validationCheck.hasErrors) return placeholderChanges;

    const placeholderIdsWithErrors = validationCheck.errorMessages.map((error) => error.collectionElementId);
    return placeholderChanges.filter((change) => !placeholderIdsWithErrors.includes(change.id));
  }

  private filterOutItemChangesWithErrors(itemChanges, placeholderChanges, validationCheck) {
    if (!validationCheck.hasErrors) return itemChanges;

    const placeholderIdsWithErrors = validationCheck.errorMessages.map((error) => error.collectionElementId);
    const placeholderChangesWithErrors = placeholderChanges.filter((change) =>
      placeholderIdsWithErrors.includes(change.id),
    );

    return itemChanges.filter((itemChange) => {
      const isItemFamilyInPlaceholdersWithErrors = placeholderChangesWithErrors.some(
        (placeholderChange) => placeholderChange.changes?.itemFamily?.id === itemChange.id,
      );

      const isItemOptionInPlaceholdersWithErrors = placeholderChangesWithErrors.some(
        (placeholderChange) => placeholderChange.changes?.itemOption?.id === itemChange.id,
      );

      return !(isItemFamilyInPlaceholdersWithErrors || isItemOptionInPlaceholdersWithErrors);
    });
  }
}
