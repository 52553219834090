import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { tap } from 'rxjs/operators';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';
@Component({
  selector: 'app-type-property-form-field-date',
  template: `
    <mat-form-field
      *ngIf="formControl"
      [floatLabel]="propertyFormConfiguration.isFilter ? 'never' : 'always'"
      [attr.data-test]="propertyFormConfiguration.typeProperty.slug + '-form-field'"
      class="date"
      [appearance]="appearance"
    >
      <mat-label>{{ propertyFormConfiguration.typeProperty.label }}</mat-label>
      <input
        #inputField
        [formControl]="formControl"
        (change)="handleInputChange()"
        matInput
        [min]="minDate"
        [max]="maxDate"
        [matDatepicker]="picker"
        placeholder="mm/dd/yyyy"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="formControl.hasError('error')">{{ formControl.getError('error') }}</mat-error>
      <app-type-property-form-validation-error
        *ngIf="formControl.hasError('error') && formControl.disabled"
        [errorMessage]="formControl.getError('error')"
      >
      </app-type-property-form-validation-error>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: block;
        min-height: 40px;
        max-width: 250px;
      }
      mat-form-field.date {
        max-width: 180px;
      }
      mat-form-field {
        width: 100%;
      }
    `,
  ],
})
export class TypePropertyFormFieldDateComponent extends TypePropertyFormFieldBaseComponent {
  minDate: Date;
  maxDate: Date;
  @Input() errorMessage: string;
  @ViewChild('inputField', {
    read: MatInput,
  })
  inputField: MatInput;
  @ViewChild('inputField') input: ElementRef;

  constructor() {
    super();
    this.minDate = new Date(1000, 0, 1);
    this.maxDate = new Date(9999, 11, 31);
  }

  clearValue(): void {
    this.inputField.value = '';
  }

  initFormControlListener() {
    this.formChangeSub = this.formControl.valueChanges
      .pipe(
        tap((val: any) => {
          if (val !== this.value && this.formControl.valid) {
            this.value = val;
            if (typeof this.value === 'string') {
              this.value = new Date(this.value);
            }
            if (this.value instanceof Date) {
              const isoDatePart = this.getIsoDatePart(this.value);
              const utCIso = isoDatePart + 'T00:00:00.000Z';
              this.value = utCIso;
            }
            this.handleChange();
          }
        }),
      )
      .subscribe();
  }

  handleInputChange() {
    let dateValue = this.input?.nativeElement.value;
    if (!dateValue.length) {
      this.formControl.setValue(null);
    }
    const re = new RegExp('^[0-9]{1,2}[-|/][0-9]{1,2}$'); // check for date entry of 1/22 or 1-22
    if (re.test(dateValue)) {
      dateValue = dateValue + '/' + new Date().getFullYear();
    }
    if (this.isValidInput(dateValue)) {
      this.formControl.setValue(new Date(dateValue));
    }
  }

  private isValidInput(dateValue: string): boolean {
    const d = new Date(dateValue);
    if (d === null || isNaN(d.getTime()) || d.getTime() === 0) {
      return false;
    } else {
      return true;
    }
  }

  private getIsoDatePart(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }
}
