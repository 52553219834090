<div class="flex h-full w-full view-property-configuration" cdkDropListGroup>
  <div class="flex flex-col property-list-container flex-1">
    <h6 class="mb-2">Hide</h6>
    <app-view-property-drag-drop-list
      #hideProperties
      data-test="hide-properties-list"
      (propertiesRemoved)="handleHidePropertiesRemoved($event)"
      (propertiesUpdated)="handleHidePropertiesUpdated($event)"
      (propertyDoubleClick)="moveHidePropertyToShow($event)"
      [isVisible]="isVisible"
      [properties]="hidePropertiesList"
      [allowReorder]="false"
    >
    </app-view-property-drag-drop-list>
  </div>

  <div class="flex flex-col show-hide-buttons items-center justify-center">
    <button
      class="!mb-4"
      color="primary"
      data-test="move-to-show-button"
      mat-stroked-button
      (click)="addSelectedToShowProperties()"
      [disabled]="!hidePropertiesComponent?.selectedIndexes?.length"
    >
      <mat-icon class="mr-1 mb-[2px]">chevron_right</mat-icon> SHOW
    </button>

    <button
      color="primary"
      mat-stroked-button
      data-test="move-to-hide-button"
      (click)="addSelectedToHideProperties()"
      [disabled]="!showPropertiesComponent?.selectedIndexes?.length"
    >
      <mat-icon class="mr-1 mb-[2px]">chevron_left</mat-icon> HIDE
    </button>
  </div>

  <div class="flex flex-col property-list-container flex-1">
    <h6 class="mb-2">Show</h6>
    <app-view-property-drag-drop-list
      #showProperties
      data-test="show-properties-list"
      (propertiesRemoved)="handleShowPropertiesRemoved($event)"
      (propertiesUpdated)="handleShowPropertiesUpdated($event)"
      (propertyDoubleClick)="moveShowPropertyToHide($event)"
      [isVisible]="isVisible"
      [properties]="showPropertiesList"
      [allowReorder]="true"
    >
    </app-view-property-drag-drop-list>
  </div>
</div>
