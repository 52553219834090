import { Injectable } from '@angular/core';
import { chunk } from 'lodash';
import pLimit from 'p-limit';
import { take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Entities } from '@contrail/sdk';
import { RootStoreState } from '@rootstore';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';

const limit = pLimit(3);

export interface ProjectItem {
  id?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectItemService {
  private currentProjectId;
  constructor(private store: Store<RootStoreState.State>) {
    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(
        tap((ws) => {
          if (!ws) {
            return;
          }
          this.currentProjectId = ws.projectId;
        }),
      )
      .subscribe();
  }

  public async getProjectItem(itemId) {
    if (!this.currentProjectId) {
      return;
    }
    const id = `${this.currentProjectId}:${itemId}`;
    return new Entities().get({ entityName: 'project-item', id, relations: ['project'] });
  }

  public async upsertProjectItem(itemId, data) {
    if (!this.currentProjectId) {
      return;
    }
    const id = `${this.currentProjectId}:${itemId}`;
    return new Entities().update({ entityName: 'project-item', id, object: data });
  }

  public async batchUpsert(batchChanges: Array<{ id: string; changes: any }>): Promise<any[]> {
    const entityChangesInBatches = chunk(batchChanges, 50);
    const promises: Array<Promise<any>> = [];

    for (const changesBatch of entityChangesInBatches) {
      const promise = limit(async () => {
        return await this.upsertBatchOfProjectItems(changesBatch);
      });
      promises.push(promise);
    }

    const updatedEntities = await Promise.all(promises);
    return updatedEntities.flat();
  }

  private async upsertBatchOfProjectItems(batchChanges: Array<{ id: string; changes: any }>) {
    const changes: Array<{ id: string; changes: any }> = [];
    for (const change of batchChanges) {
      const id = `${this.currentProjectId}:${change.id}`;
      changes.push({
        id,
        changes: change.changes,
      });
    }
    return new Entities().batchUpdate({ entityName: 'project-item', objects: changes });
  }

  public async getProjectItemForItem(itemId: string) {
    let projectId;
    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(
        take(1),
        tap(async (ws) => {
          projectId = ws.projectId;
        }),
      )
      .subscribe();
    const results = await new Entities().get({ entityName: 'project-item', criteria: { projectId, itemId } });
    if (results.length) {
      return results[0];
    }
  }

  public async getByIds(ids: string[]): Promise<ProjectItem[]> {
    const uniqueIds = [...new Set(ids)];
    const idChunks = chunk(uniqueIds, 200);
    const promises: Array<Promise<any>> = [];

    for (const projectItemIds of idChunks) {
      const promise = limit(async () => {
        return await new Entities().get({ entityName: 'project-item', criteria: { ids: projectItemIds } });
      });
      promises.push(promise);
    }

    const projectItems = await Promise.all(promises);
    return projectItems.flat();
  }

  public async getByProjectIdAndItemIds(projectId: string, itemIds: string[]): Promise<ProjectItem[]> {
    const idChunks = chunk(itemIds, 200);
    const promises: Array<Promise<any>> = [];

    for (const itemIdsChunk of idChunks) {
      const promise = limit(async () => {
        return await new Entities().get({ entityName: 'project-item', criteria: { projectId, itemIds: itemIdsChunk } });
      });
      promises.push(promise);
    }

    const projectItems = await Promise.all(promises);
    return projectItems.flat();
  }
}
