import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Type, TypeProperty } from '@contrail/types';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
  TypePropertyFormComponent,
  TypePropertyFormConfiguration,
} from '../../types/forms/type-property-form/type-property-form.component';
import { AccessManagedType } from '@contrail/sdk';

@Component({
  selector: 'app-entity-properties-form',
  templateUrl: './entity-properties-form.component.html',
  styleUrls: ['./entity-properties-form.component.scss'],
})
export class EntityPropertiesFormComponent implements OnChanges, AfterViewInit, OnDestroy {
  private destroy$ = new Subject();
  @Input() type: AccessManagedType;
  @Input() entity: any;
  @Input() accessLevel = 'EDIT';
  @Input() errors: any;
  @ViewChild(TypePropertyFormComponent) propertyForm: TypePropertyFormComponent;
  public changes: Subject<any> = new Subject();
  public propertyConfigurations: Array<TypePropertyFormConfiguration>;
  constructor() {}

  ngOnChanges(): void {
    if (!this.type) {
      this.propertyConfigurations = null;
      return;
    }
    const canEdit = this.accessLevel === 'EDIT' && this.type.canUpdate !== false;
    const propertieConfigurations: Array<TypePropertyFormConfiguration> = [];

    // Special handling for name properties.
    const nameProperty = this.type.typeProperties.find((prop) => prop.slug === 'name');
    if (nameProperty) {
      const editable = canEdit;
      propertieConfigurations.push({ typeProperty: nameProperty, editable });
    }

    // Level based properties
    let remaining: any = this.type.typeProperties.filter(
      (prop) => !['name', 'updatedOn', 'createdOn'].includes(prop.slug),
    );

    remaining = remaining.map((prop) => {
      return { typeProperty: prop, editable: canEdit };
    });
    remaining.sort((t1, t2) => (t1.typeProperty?.label > t2.typeProperty?.label ? 1 : -1));
    this.propertyConfigurations = propertieConfigurations.concat(remaining);
  }

  ngAfterViewInit() {
    this.propertyForm.formChanges
      .pipe(
        tap((change) => {
          this.changes.next(change as any);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
