import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BodyComponent } from './body/body.component';
import { MainComponent } from './main.component';
import { AuthModule } from '../common/auth/auth.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatCommonModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrgSelectorComponent } from './header/org-selector/org-selector.component';
import { HeaderUserMenuComponent } from './header/header-user-menu/header-user-menu.component';
import { RouterModule } from '@angular/router';
import { SideNavComponent } from './side-nav/side-nav.component';
import { PageHeaderComponent } from './header/page-header/page-header.component';
import { PageHeaderService } from './header/page-header/page-header.service';
import { HelpSelectorComponent } from './header/header-help/help-selector.component';
import { SelectOrgComponent } from './select-org/select-org.component';
import { ComponentsModule } from '../common/components/components.module';
import { UserSessionsModule } from '../common/user-sessions/user-sessions.module';
import { LoadingIndicatorModule } from '../common/loading-indicator/loading-indicator.module';
import { PlansStoreModule } from '../plans/plans-store';
import { RootStoreModule } from '../root-store';
import { RenamePlanComponent } from './header/page-header/rename-plan/rename-plan.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ShareAppModule } from '../common/share-app/share-app.module';
import { PlansModule } from '../plans/plans.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AssortmentHistoryModule } from '../common/assortment-history/assortment-history.module';
import { CommentsModule } from '../common/comments/comments.module';
import { CollectionManagerModule } from '../collection-manager/collection-manager.module';
import { SidePanelModule } from '../collection-manager/side-panel/side-panel.module';
import { WorkspaceSelectorComponent } from './side-nav/workspace-selector/workspace-selector.component';
import { CollectionStatusMessageModule } from '@common/collection-status-message/collection-status-message.module';
import { UserProfileModule } from '@common/user-profile/user-profile.module';
import { ClipboardModule } from '@common/clipboard/clipboard.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    MainComponent,
    OrgSelectorComponent,
    HeaderUserMenuComponent,
    SideNavComponent,
    PageHeaderComponent,
    HelpSelectorComponent,
    SelectOrgComponent,
    RenamePlanComponent,
    WorkspaceSelectorComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
    AuthModule,
    FormsModule,
    MatSidenavModule,
    MatCommonModule,
    BrowserAnimationsModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    UserSessionsModule,
    LoadingIndicatorModule,
    RootStoreModule,
    PlansStoreModule,
    ReactiveFormsModule,
    MatInputModule,
    ShareAppModule,
    PlansModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    AssortmentHistoryModule,
    CommentsModule,
    CollectionManagerModule,
    SidePanelModule,
    CollectionStatusMessageModule,
    UserProfileModule,
    ClipboardModule,
  ],
  providers: [PageHeaderService],
  exports: [MainComponent],
})
export class MainModule {}
