import { OnChanges, ViewEncapsulation } from '@angular/core';
import { Component, Input } from '@angular/core';
import { DataCellViewBaseComponent } from '../data-cell-view-base-component';
@Component({
  selector: 'app-data-cell-view-text-area',
  template: ` <div [matTooltip]="value" matTooltipClass="text-tooltip" matTooltipShowDelay="500">{{ display }}</div> `,
  styles: [
    `
      .text-tooltip {
        white-space: pre-line;
        line-height: normal;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DataCellViewTextAreaComponent extends DataCellViewBaseComponent implements OnChanges {
  @Input() value;
  @Input() display;

  constructor() {
    super();
  }

  ngOnChanges(): void {
    let delimiter = '\n';
    if (this.value) {
      if (this.value.indexOf('\r\n') > -1) {
        delimiter = '\r\n';
      }
      const data = this.value.split(delimiter);
      if (data.length > 0) {
        this.display = data[0]; // only show the truncated value of the first line.
      }
    }
  }
}
