import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { SelectedCellInfo } from '../context-menu.component';
import { PlaceholderItemCopyService } from '../../placeholders/placeholder-item-copy-service';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';

export class CopyItemMenuAction extends ContextMenuAction {
  constructor(
    protected store: Store<RootStoreState.State>,
    protected copyItemService: PlaceholderItemCopyService,
  ) {
    super(store);
    this.svgActionIcon = 'item-copy-icon';
    this.actionLabel = 'Quick item copy';
    this.iconClass = 'material-icons-outlined';
    this.shortCutKey = 'Ctrl-Alt-C';
    this.testRef = 'app-menu-copy-item-action';
  }

  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    this.copyItemService.copyPlaceholderItems(ids);
  }

  canInvoke(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo = null,
    isInFocusMode: boolean = false,
  ): boolean {
    if (editorMode !== EditorMode.EDIT) {
      return false;
    }

    if (isInFocusMode) {
      return false;
    }

    return true;
  }
}
