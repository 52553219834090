import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Entities, Types } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { AssortmentItem } from 'src/app/common/assortments/assortment-item';
import { AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';
import { ItemData } from 'src/app/common/item-data/item-data';
import { Item } from 'src/app/common/items/item';
import { LoadingIndicatorActions } from 'src/app/common/loading-indicator/loading-indicator-store';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { AuthSelectors } from 'src/app/common/auth/auth-store';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { PlaceholderUtil } from './placeholder-util';
import { PropertyType, Type } from '@contrail/types';
import { CollectionElementActionValidator } from '../collection-element-validator/collection-element-action-validator';
import { ProjectItemUpdateService } from '../project-items/project-item-update-service';
import { ItemService } from '@common/items/item.service';
import { ProjectItemService } from '@common/projects/project-item.service';
import { CollectionDataEntity } from '../collection-manager.service';
import { Actions, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';

export const ITEM_ALREADY_EXISTS = 'Item already exists on plan and can not be added twice.';

interface ItemAssignmentData {
  items: Item[];
  projectItems: any[];
  carryoverProjectItems: any[];
}

@Injectable({
  providedIn: 'root',
})
export class PlaceholderItemAssignmentService {
  constructor(
    private store: Store<RootStoreState.State>,
    private projectItemUpdateService: ProjectItemUpdateService,
    private itemService: ItemService,
    private projectItemService: ProjectItemService,
    private collectionElementActionValidator: CollectionElementActionValidator,
    private snackBar: MatSnackBar,
    private actions$: Actions,
  ) {}

  public static async assignItemPropertiesToPlaceholder(item: Item, placeholder: any, removeItemOption = false) {
    if (!item) {
      return;
    }
    const phType = await new Types().getType({ root: 'plan-placeholder', path: 'plan-placeholder' });
    const itemType = await new Types().getType({ id: item.typeId });
    const itemOption = item.roles?.includes('option');
    for (let prop of phType.typeProperties) {
      if (!['itemFamily'].includes(prop.slug)) {
        const property = itemType.typeProperties.find((p) => p.slug === prop.slug);
        if (property) {
          if (property.propertyLevel === 'option' && removeItemOption) {
            placeholder[prop.slug] = null; // clears all option level attribute values
          } else if (itemOption || (!itemOption && property?.propertyLevel !== 'option')) {
            // Do not copy option-level property values that could exist in an item-family into the placeholder.
            // This could happen when an item-family was loaded with values for properties that did not have a level during
            // data loading but the property level was set to "option" after these values were loaded.
            placeholder[prop.slug] = item[prop.slug];
          }
        } else if (prop.slug === 'itemType') {
          placeholder['itemTypeId'] = item['typeId'];
        }
      }
    }
  }
  public static async assignAssortmentItemPropertiesToPlaceholder(assortmentItem: AssortmentItem, placeholder: any) {
    if (!assortmentItem) {
      return;
    }
    const phType = await new Types().getType({ root: 'plan-placeholder', path: 'plan-placeholder' });
    phType.typeProperties.forEach((prop) => {
      if (assortmentItem[prop.slug]) {
        placeholder[prop.slug] = assortmentItem[prop.slug];
      }
    });
  }

  public static async assignProjectItemPropertiesToPlaceholder(projectItem: any, placeholder: any) {
    if (!projectItem) {
      return;
    }
    const phType = await new Types().getType({ root: 'plan-placeholder', path: 'plan-placeholder' });
    phType.typeProperties.forEach((prop) => {
      if (projectItem[prop.slug]) {
        placeholder[prop.slug] = projectItem[prop.slug];
      }
    });
  }

  /**
   * Seeds data into a placeholder.  Handles a passed in item as well as looking up an assortment-item
   * from the souce assortment for the plan.
   * @param placeholder  Placeholde which will be mutated with updated state
   * @param sourceItem:  Optionally provided item in the case that there is no source assortment data.
   */
  public async copyPropertiesFromSource(placeholder, sourceItem = null) {
    const sourceItemData: ItemData = this.getSourceItemDataForPlaceholder(placeholder);
    const item = sourceItemData?.item || sourceItem;
    if (item) {
      await PlaceholderItemAssignmentService.assignItemPropertiesToPlaceholder(item, placeholder);
    }
    if (sourceItemData?.assortmentItem) {
      await PlaceholderItemAssignmentService.assignAssortmentItemPropertiesToPlaceholder(
        sourceItemData.assortmentItem,
        placeholder,
      );
    }
  }
  public getSourceItemDataForPlaceholder(placeholder) {
    const itemId = placeholder?.itemOptionId;
    if (!itemId) {
      return null;
    }
    let sourceItemData;
    this.store
      .select(AssortmentsSelectors.sourceAssortmentItemData)
      .pipe(
        take(1),
        tap((assortment) => {
          sourceItemData = assortment?.find((itemData) => itemData.id === itemId);
        }),
      )
      .subscribe();
    return sourceItemData;
  }

  public async assignItemsToPlaceholders(
    newItemsToAdd: ItemData[],
    newItemsToAddAtIndexes: { itemData: ItemData[]; index: number }[],
    itemsToSetOnExistingPlaceholders: { placeholder: any; itemData: ItemData }[],
  ) {
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));

    try {
      await this.assignItemsToPlaceholdersAndHandleCarryover(
        newItemsToAdd,
        newItemsToAddAtIndexes,
        itemsToSetOnExistingPlaceholders,
      );
    } catch (error) {
      this.snackBar.open('An unexpected error was encountered.', '', { duration: 5000 });
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
      throw error;
    }
  }

  private async assignItemsToPlaceholdersAndHandleCarryover(
    newItemsToAdd: ItemData[],
    newItemsToAddAtIndexes: { itemData: ItemData[]; index: number }[],
    itemsToSetOnExistingPlaceholders: { placeholder: any; itemData: ItemData }[],
  ) {
    const allNewItemsToAdd = [...newItemsToAdd, ...newItemsToAddAtIndexes.flatMap((i) => i.itemData)];
    const allItemData = [...allNewItemsToAdd, ...itemsToSetOnExistingPlaceholders.map((i) => i.itemData)];

    const allItems = await this.getItemsForItemDataArray(allItemData);
    const allProjectItems = await this.getProjectItemsForItems(allItems);
    const allCarryoverProjectItems = await this.createProjectItemsForCarryover(allItemData, allItems, allProjectItems);
    const itemAssignmentData: ItemAssignmentData = {
      items: allItems,
      projectItems: allProjectItems,
      carryoverProjectItems: allCarryoverProjectItems,
    };

    const itemFamiliesToSetOnExistingPlaceholders = itemsToSetOnExistingPlaceholders.filter((item) =>
      item.itemData.item.roles.includes('family'),
    );

    const itemOptionsToSetOnExistingPlaceholders = itemsToSetOnExistingPlaceholders.filter((item) =>
      item.itemData.item.roles.includes('option'),
    );

    const hasExistingPlaceholdersToUpdateWithFamilyItems = Boolean(itemFamiliesToSetOnExistingPlaceholders?.length);
    const hasExistingPlaceholdersToUpdateWithOptionItems = Boolean(itemOptionsToSetOnExistingPlaceholders?.length);
    const hasNewItemsToAdd = Boolean(newItemsToAdd?.length);
    const hasNewItemsToAddAtIndices = Boolean(newItemsToAddAtIndexes?.length);

    if (hasExistingPlaceholdersToUpdateWithFamilyItems) {
      for (const itemFamily of itemFamiliesToSetOnExistingPlaceholders) {
        await this.setItemFamilyOnPlaceholder(itemFamily.placeholder, itemFamily.itemData);
      }
    }

    if (hasExistingPlaceholdersToUpdateWithOptionItems) {
      await this.setItemOptionOnPlaceholders(itemOptionsToSetOnExistingPlaceholders, itemAssignmentData, {
        skipHideLoaderOnComplete: true,
      });
    }

    if (hasNewItemsToAdd || hasNewItemsToAddAtIndices) {
      const allNewPlaceholdersToCreate = await this.buildNewPlaceholdersWithItemData(
        allNewItemsToAdd,
        itemAssignmentData,
      );

      if (hasNewItemsToAdd) {
        const newItemIds = newItemsToAdd.map((item) => item.id);
        const newPlaceholders = allNewPlaceholdersToCreate.filter((placeholder) =>
          this.isPlaceholderOfAnyItemIds(placeholder, newItemIds),
        );

        this.store.dispatch(CollectionManagerActions.createCollectionDataEntities({ entities: newPlaceholders }));

        // Workaround to allow row-order updates to be synced before additional row-order changes are applied
        if (hasNewItemsToAddAtIndices) {
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
      }

      if (hasNewItemsToAddAtIndices) {
        const placeholdersGroupedByInsertIndex = this.buildPlaceholdersToInsertByIndex(
          newItemsToAddAtIndexes,
          allNewPlaceholdersToCreate,
        );

        this.store.dispatch(
          CollectionManagerActions.createCollectionEntitiesAtIndices({
            entitiesByIndex: placeholdersGroupedByInsertIndex,
          }),
        );
      }
    }

    const updateEntities$ = hasExistingPlaceholdersToUpdateWithOptionItems
      ? this.actions$.pipe(
          ofType(CollectionManagerActions.CollectionElementsActionTypes.UPDATE_COLLECTION_DATA_ENTITIES_COMPLETE),
          take(1),
        )
      : of(true);

    const createEntities$ = hasNewItemsToAdd
      ? this.actions$.pipe(
          ofType(CollectionManagerActions.CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITIES_COMPLETE),
          take(1),
        )
      : of(true);

    const createEntitiesAtIndices$ = hasNewItemsToAddAtIndices
      ? this.actions$.pipe(
          ofType(
            CollectionManagerActions.CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITIES_AT_INDICES_COMPLETE,
          ),
          take(1),
        )
      : of(true);

    combineLatest([updateEntities$, createEntities$, createEntitiesAtIndices$]).subscribe(() => {
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
    });
  }

  private buildPlaceholdersToInsertByIndex(
    newItemsToAddAtIndexes: { itemData: ItemData[]; index: number }[],
    placeholderData: CollectionDataEntity[],
  ): { entities: CollectionDataEntity[]; index: number }[] {
    const placeholdersByIndex: { entities: CollectionDataEntity[]; index: number }[] = [];

    newItemsToAddAtIndexes.forEach(({ itemData, index }) => {
      const itemIds = itemData.map((item) => item.id);
      const matchingPlaceholders = placeholderData.filter((placeholder) =>
        this.isPlaceholderOfAnyItemIds(placeholder, itemIds),
      );

      if (matchingPlaceholders.length > 0) {
        placeholdersByIndex.push({
          entities: matchingPlaceholders,
          index,
        });
      }
    });

    return placeholdersByIndex;
  }

  private isPlaceholderOfAnyItemIds(placeholder: CollectionDataEntity, itemIds: string[]) {
    if (placeholder.itemOptionId) {
      return itemIds.includes(placeholder.itemOptionId);
    }

    if (placeholder.itemFamilyId && !placeholder.itemOptionId) {
      return itemIds.includes(placeholder.itemFamilyId);
    }
  }

  public async addNewPlaceholderWithItems(
    itemDataArray: Array<ItemData>,
    itemAssignmentData: ItemAssignmentData = null,
  ) {
    const placeholders: any[] = await this.buildNewPlaceholdersWithItemData(itemDataArray, itemAssignmentData);
    this.store.dispatch(CollectionManagerActions.createCollectionDataEntities({ entities: placeholders }));
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
  }

  private async buildNewPlaceholdersWithItemData(
    itemDataArray: Array<ItemData>,
    itemAssignmentData: ItemAssignmentData = null,
  ): Promise<CollectionDataEntity[]> {
    const placeholders: CollectionDataEntity[] = [];
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
    const typeMap = this.getTypeMap();
    const assortment = this.getAssortment();
    const orgConfig = this.getOrgConfig();

    const items = itemAssignmentData ? itemAssignmentData.items : await this.getItemsForItemDataArray(itemDataArray);
    const projectItems = itemAssignmentData
      ? itemAssignmentData.projectItems
      : await this.getProjectItemsForItems(items);
    const carriedOverProjectItems = itemAssignmentData
      ? itemAssignmentData.carryoverProjectItems
      : await this.createProjectItemsForCarryover(itemDataArray, items, projectItems);

    for (const itemData of itemDataArray) {
      const item = items.find((i) => i.id === itemData.id);
      if (!itemData.id || !item) {
        placeholders.push({});
        continue;
      }

      const isOption = item.roles?.includes('option');
      const itemOptionId = isOption ? item.id : null;
      const itemFamilyId = isOption ? item.itemFamilyId : item.id;
      const optionProjectItem = itemOptionId ? projectItems.find((pi) => pi.itemId === itemOptionId) : null;
      const familyProjectItem = projectItems.find((pi) => pi.itemId === itemFamilyId);

      const defaultPlaceholderData = itemData.placeholderData ?? {};
      delete defaultPlaceholderData.itemFamily;
      delete defaultPlaceholderData.itemOption;

      const carryoverProjectItems = carriedOverProjectItems.filter(
        (pi) => pi && (pi.itemId === itemOptionId || pi.itemId === itemFamilyId),
      );
      const placeholderWithItemData = await this.buildPlaceholderWithItem(
        item,
        itemData,
        familyProjectItem,
        optionProjectItem,
        carryoverProjectItems,
      );
      const placeholderToCreate = { ...defaultPlaceholderData, ...placeholderWithItemData };

      const placeholder = await PlaceholderUtil.postProcessPlaceholder(
        placeholderToCreate,
        typeMap,
        assortment,
        orgConfig,
      );
      placeholders.push(placeholder);
    }

    return placeholders;
  }

  private async createProjectItemsForCarryover(
    itemDataArray: ItemData[],
    items: Array<Item>,
    projectItems: Array<any>,
  ) {
    const carryoverData = [];
    const addedCarryoverItemIds = new Set<string>();

    for (const itemData of itemDataArray) {
      const item = items.find((i) => i.id === itemData.id);
      if (!itemData.id || !item) {
        continue;
      }

      const isOption = item.roles?.includes('option');
      const itemOptionId = isOption ? item.id : null;
      const itemFamilyId = isOption ? item.itemFamilyId : item.id;

      const optionProjectItem = itemOptionId ? projectItems.find((pi) => pi.itemId === itemOptionId) : null;
      const familyProjectItem = projectItems.find((pi) => pi.itemId === itemFamilyId);

      const isFamilyCarryover = !familyProjectItem?.addedFromSource;
      if (isFamilyCarryover && !addedCarryoverItemIds.has(itemFamilyId)) {
        const sourceProjectItem = itemData.item?.itemFamily?.projectItem ?? itemData.projectItem;
        addedCarryoverItemIds.add(itemFamilyId);

        carryoverData.push({
          itemId: itemFamilyId,
          sourceProjectItem,
          sourceAssortmentItem: itemData.assortmentItem,
          isOption: false,
        });
      }

      const isOptionCarryover = isOption && !optionProjectItem?.addedFromSource;
      if (isOptionCarryover && !addedCarryoverItemIds.has(itemOptionId)) {
        const sourceProjectItem = itemData.projectItem;
        addedCarryoverItemIds.add(itemOptionId);

        carryoverData.push({
          itemId: itemOptionId,
          sourceProjectItem,
          sourceAssortmentItem: itemData.assortmentItem,
          isOption: true,
        });
      }
    }

    const createdCarryoverProjectItems = await this.createAndCarryoverProjectItems(carryoverData);
    return createdCarryoverProjectItems;
  }

  private async getItemsForItemDataArray(itemDataArray: Array<ItemData>) {
    console.log('getting item ids: itemDataArray', itemDataArray);

    const allItemIds = [...new Set(itemDataArray.map((itemData) => itemData.id).filter((id) => !!id))];
    allItemIds.forEach((itemId) => this.checkForDuplicateItemOptionId(itemId));

    console.log('getting item ids', allItemIds);

    const fetchedItems = await this.itemService.getByIds(allItemIds, ['itemFamily']);
    return fetchedItems;
  }

  private async getProjectItemsForItems(items: Item[]) {
    const projectItems = [];
    const projectItemItemIdsToGet = new Set<string>();
    for (const item of items) {
      const isOption = item?.roles?.includes('option');
      if (isOption) {
        projectItemItemIdsToGet.add(item.id);
        const projectItem = this.getFamilyProjectItemInPlan(item.itemFamilyId);
        if (projectItem) {
          projectItems.push(projectItem);
        } else {
          projectItemItemIdsToGet.add(item.itemFamilyId);
        }
      } else {
        const projectItem = this.getFamilyProjectItemInPlan(item.id);
        if (projectItem) {
          projectItems.push(projectItem);
        } else {
          projectItemItemIdsToGet.add(item.itemFamilyId);
        }
      }
    }

    const fetchedProjectItems = [];
    if (projectItemItemIdsToGet.size) {
      const projectId = this.getCurrentProjectId();
      const itemIds = Array.from(projectItemItemIdsToGet).filter((id) => !!id);
      const foundProjectItems = await this.projectItemService.getByProjectIdAndItemIds(projectId, itemIds);
      fetchedProjectItems.push(...foundProjectItems);
    }

    return [...projectItems, ...fetchedProjectItems];
  }

  private async buildPlaceholderWithItem(
    item: Item,
    itemData: any,
    familyProjectItem: any = null,
    optionProjectItem: any = null,
    carryoverProjectItems: any[],
  ) {
    const itemId = item.id;
    const assortmentItem = itemData.assortmentItem;

    const placeholder: any = {};
    placeholder.itemFamilyId = item.itemFamilyId;
    if (item.roles.includes('family')) {
      item.projectItem = familyProjectItem;
      placeholder.itemFamily = item;
      placeholder.itemTypeId = item.typeId;
      placeholder.thumbnail = item?.smallViewableDownloadUrl || item.itemFamily?.smallViewableDownloadUrl;
    } else if (item.roles.includes('option')) {
      item.projectItem = optionProjectItem;
      item.itemFamily.projectItem = familyProjectItem;
      placeholder.itemFamilyId = item.itemFamilyId;
      placeholder.itemFamily = item.itemFamily;
      placeholder.itemOptionId = itemId;
      placeholder.itemOption = item;
      placeholder.thumbnail = item?.smallViewableDownloadUrl || item.itemFamily?.smallViewableDownloadUrl;
      placeholder.itemTypeId = item.itemFamily.typeId;
    }

    let carryoverOptionProjectItem = null;
    let carryoverFamilyProjectItem = null;
    const isOption = item.roles?.includes('option');
    const isOptionCarryover = isOption && !optionProjectItem?.addedFromSource;
    const isFamilyCarryover = !isOption && !familyProjectItem?.addedFromSource;

    if (!familyProjectItem?.addedFromSource) {
      console.log('Handling carryover item / First time adding item to plan for family');
      const familyItemId = isOption ? item.itemFamilyId : item.id;
      carryoverFamilyProjectItem = carryoverProjectItems.find((pi) => pi.itemId === familyItemId);
      if (item.roles.includes('family')) {
        item.projectItem = carryoverFamilyProjectItem;
      } else if (item.roles.includes('option')) {
        item.itemFamily.projectItem = carryoverFamilyProjectItem;
      }
    }

    if (isOptionCarryover) {
      console.log('Handling carryover item / First time adding item to plan for option');
      carryoverOptionProjectItem = carryoverProjectItems.find((pi) => pi.itemId === item.id);
      item.projectItem = carryoverOptionProjectItem;
    }

    if (assortmentItem) {
      await PlaceholderItemAssignmentService.assignAssortmentItemPropertiesToPlaceholder(assortmentItem, placeholder);
    }

    if (isFamilyCarryover || carryoverFamilyProjectItem !== null) {
      const projectItemToAssign = carryoverFamilyProjectItem !== null ? carryoverFamilyProjectItem : familyProjectItem;
      console.log('carryoverFamilyProjectItem USING: ', projectItemToAssign);
      await PlaceholderItemAssignmentService.assignProjectItemPropertiesToPlaceholder(projectItemToAssign, placeholder);
    }

    if (isOptionCarryover) {
      const projectItemToAssign = carryoverOptionProjectItem !== null ? carryoverOptionProjectItem : optionProjectItem;
      console.log('isOptionCarryover USING: ', projectItemToAssign);
      await PlaceholderItemAssignmentService.assignProjectItemPropertiesToPlaceholder(projectItemToAssign, placeholder);
    }

    // COPY IN PROPERTIES FROM SOURCE ITEM / ASSORTMENT
    await this.copyPropertiesFromSource(placeholder, item);
    return placeholder;
  }

  /** Handles setting a different itemFamily (or clearing) */
  // item can be an actual item object, an instance of itemData, or an item id.
  public async setItemFamilyOnPlaceholder(placeholder: any, item: any, removeItemOption = false, skipSaving = false) {
    console.log('changing itemFamilyId: ', placeholder, item);
    const changeObject = await this.buildChangesToSetItemFamilyOnPlaceholder(
      placeholder,
      item,
      removeItemOption,
      skipSaving,
    );

    console.log('setItemFamilyOnPlaceholder: changes: ', changeObject.changes);
    if (!skipSaving) {
      this.store.dispatch(CollectionManagerActions.updateCollectionDataEntity(changeObject));
    }

    return changeObject.changes;
  }

  public async buildChangesToSetItemFamilyOnPlaceholder(
    placeholder: any,
    item: any,
    removeItemOption = false,
    skipSaving = false,
  ): Promise<{ id: string; changes: any }> {
    let changes: any = {};
    changes.itemOptionId = null; // CLEAR ITEM OPTION
    if (item) {
      let itemFamily;
      let passedProjectItem;
      let passedAssortmentItem;
      if (typeof item === 'string') {
        changes = { itemFamilyId: item };
        itemFamily = await new Entities().get({ entityName: 'item', id: changes.itemFamilyId, relations: [] });
      } else if (typeof item === 'object') {
        itemFamily = ObjectUtil.cloneDeep(item.item ? item.item : item);
        changes = { itemFamilyId: itemFamily.id };
        passedProjectItem = item.projectItem;
        passedAssortmentItem = item.assortmentItem;
      }

      let projectItem = await this.getProjectItemForItemFamily(itemFamily.id);
      let carryoverProjectItem = null;
      if (!projectItem?.addedFromSource) {
        console.log('Handling carryover item / First time adding item to plan');
        const carryoverProjectItems = await this.createAndCarryoverProjectItems([
          {
            itemId: itemFamily.id,
            sourceProjectItem: passedProjectItem,
            sourceAssortmentItem: passedAssortmentItem,
            isOption: false,
          },
        ]);
        carryoverProjectItem = carryoverProjectItems[0];
      }

      console.log('itemFamily = ', itemFamily);
      changes.itemFamily = itemFamily;
      changes.itemFamilyId = itemFamily.id;
      changes.thumbnail = itemFamily?.smallViewableDownloadUrl;
      if (removeItemOption) {
        changes.itemOption = null;
        changes.optionName = null;
        changes.itemOptionId = null;
      }

      await PlaceholderItemAssignmentService.assignItemPropertiesToPlaceholder(itemFamily, changes, removeItemOption);
      if (projectItem || carryoverProjectItem) {
        const projectItemToSet = carryoverProjectItem !== null ? carryoverProjectItem : projectItem;
        changes.itemFamily.projectItem = projectItemToSet;
        await PlaceholderItemAssignmentService.assignProjectItemPropertiesToPlaceholder(projectItemToSet, changes);
      }

      PlaceholderItemAssignmentService.clearUnmappedItemFamilyPropertiesOfPlaceholder(placeholder, changes);
    } else {
      changes.itemFamily = null;
      changes.itemFamilyId = null;
      changes.itemOption = null;
      changes.itemOptionId = null;
      const additionalChanges = await PlaceholderUtil.clearMappedPropertiesOnPlaceholder(placeholder);
      changes = Object.assign(changes, additionalChanges);
    }

    const addCheck = this.collectionElementActionValidator.isItemFamilyAddable(changes.itemFamily);
    if (!addCheck.isValid) {
      this.snackBar.open(addCheck.reason, '', { duration: 5000 });
      return placeholder;
    }

    return { id: placeholder.id, changes };
  }

  /** Handles setting a different itemFamily (or clearing) */
  public async setItemOptionOnPlaceholder(placeholder: any, itemOption: ItemData) {
    const placeholderData = [{ placeholder, itemData: itemOption }];
    await this.setItemOptionOnPlaceholders(placeholderData);
  }

  // placeholderData.itemOption can be an id or itemData
  public async setItemOptionOnPlaceholders(
    placeholderData: { placeholder: any; itemData: ItemData }[],
    itemAssignmentData: ItemAssignmentData = null,
    options?: { skipHideLoaderOnComplete: boolean },
  ) {
    const { entities, errorMessage } = await this.buildChangesToSetItemOptionsOnPlaceholders(
      placeholderData,
      itemAssignmentData,
    );

    if (errorMessage) {
      this.snackBar.open(errorMessage, '', { duration: 5000 });
    }

    if (!options?.skipHideLoaderOnComplete) {
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
    }

    this.store.dispatch(CollectionManagerActions.updateCollectionDataEntities({ entities }));
  }

  public async buildChangesToSetItemOptionsOnPlaceholders(
    placeholderData: { placeholder: any; itemData: ItemData }[],
    itemAssignmentData: ItemAssignmentData = null,
  ): Promise<{ entities: { id: string; changes: any }[]; errorMessage?: string }> {
    const entities = [];
    let errorMessage: string;

    const itemOptions = placeholderData.map((data) => data.itemData).filter((option) => !!option);
    const hydratedItemOptions = itemAssignmentData
      ? itemAssignmentData.items
      : await this.getItemsForItemDataArray(itemOptions);

    const projectItems = itemAssignmentData
      ? itemAssignmentData.projectItems
      : await this.getProjectItemsForItems(hydratedItemOptions);

    const carriedOverProjectItems = itemAssignmentData
      ? itemAssignmentData.carryoverProjectItems
      : await this.createProjectItemsForCarryover(itemOptions, hydratedItemOptions, projectItems);

    for (let index = 0; index < placeholderData.length; index++) {
      const { placeholder, itemData } = placeholderData[index];
      let changes: any = {};
      let hydratedItemOption;

      console.log('changing itemOption: ', placeholder, itemData);
      const itemOptionId = this.getItemOptionId(itemData);
      if (itemOptionId === placeholder.itemOptionId) {
        console.log(`no change in item option ${itemOptionId}, skipping`);
        continue;
      }

      changes = { id: placeholder.id, itemOptionId: itemOptionId };
      hydratedItemOption = hydratedItemOptions.find((option) => option.id === itemOptionId);

      // CHECK FOR DUPLICATES
      if (itemData) {
        try {
          this.checkForDuplicateItemOptionId(changes.itemOptionId);
        } catch (error) {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
          this.snackBar.open(error, '', { duration: 2000 });
          throw Error(error);
        }

        changes.itemOption = hydratedItemOption;
        changes.itemFamilyId = hydratedItemOption.itemFamilyId;
        changes.itemFamily = hydratedItemOption.itemFamily || {};
        changes.thumbnail =
          hydratedItemOption?.smallViewableDownloadUrl || hydratedItemOption.itemFamily?.smallViewableDownloadUrl;

        // We may not want this behavior
        await this.copyPropertiesFromSource(changes, hydratedItemOption);

        let projectItem = projectItems.find((pi) => pi.itemId === hydratedItemOption.id);
        let carryoverProjectItem = null;
        if (!projectItem?.addedFromSource) {
          console.log('Handling carryover item / First time adding item to plan');
          carryoverProjectItem = carriedOverProjectItems.find((pi) => pi.itemId === hydratedItemOption.id);
        }
        projectItem = carryoverProjectItem !== null ? carryoverProjectItem : projectItem;

        console.log('setItemOptionOnPlaceholder: projectItem: ', projectItem);
        // Get the project-item that belongs to the itemFamily so that its property values can be copied to the hydratedItemOption's project-item.
        const projectItemOnFamily = projectItems.find((pi) => pi.itemId === hydratedItemOption.itemFamilyId);
        projectItem = projectItem || {};
        if (projectItemOnFamily) {
          const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
          const projectItemProps = projectItemType.typeProperties.filter((prop) =>
            ['family', 'overridable'].includes(prop.propertyLevel),
          );
          projectItemProps.forEach((prop) => {
            if (prop.propertyLevel === 'family') {
              projectItem[prop.slug] = projectItemOnFamily[prop.slug];
            } else if (projectItem[prop.slug] === null || projectItem[prop.slug] === undefined) {
              projectItem[prop.slug] = projectItemOnFamily[prop.slug];
            }
          });
        }

        if (projectItem) {
          changes.itemOption.projectItem = projectItem;
          changes.itemFamily.projectItem = projectItemOnFamily;
          await PlaceholderItemAssignmentService.assignProjectItemPropertiesToPlaceholder(projectItemOnFamily, changes);
          await PlaceholderItemAssignmentService.assignProjectItemPropertiesToPlaceholder(projectItem, changes);
        }
      } else {
        // CLEARING ITEM OPTION ID
        changes.itemOptionId = null;
        changes.itemOption = null;
        const additionalChanges = await PlaceholderUtil.clearMappedPropertiesOnPlaceholder(placeholder, true);
        changes = Object.assign(changes, additionalChanges);
      }

      const addCheck = this.collectionElementActionValidator.isItemOptionAddable(
        changes.itemOption,
        changes.itemFamily,
      );
      if (!addCheck.isValid) {
        errorMessage = addCheck.reason;
        continue;
      }

      entities.push({ id: placeholder.id, changes });
    }

    return { entities, errorMessage };
  }

  private getItemOptionId(itemOption) {
    if (typeof itemOption === 'string') return itemOption;
    return itemOption?.item?.id || itemOption?.id;
  }

  public async setSingleItemOptionOnPlaceholder(placeholder: any, itemOptionName?: string) {
    let itemOption;
    let itemOptionId;

    if (itemOptionName && placeholder.itemFamily.id) {
      // CHECK FOR DUPLICATES
      itemOption = this.checkForDuplicateItemOptionName(placeholder.itemFamily.id, itemOptionName);
      if (itemOption) {
        this.snackBar.open(ITEM_ALREADY_EXISTS, '', { duration: 2000 });
        return null;
      } else {
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
        const options = await new Entities().get({
          entityName: 'item',
          criteria: { optionName: itemOptionName, itemFamilyId: placeholder.itemFamily.id },
          relations: ['itemFamily'],
        });
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
        if (options.length > 0) {
          itemOption = options[0];
          itemOptionId = options[0].id;
        }
      }
    }
    let projectItem = await this.getProjectItemForItem(itemOption.id);
    const projectItemOnFamily = await this.getProjectItemForItemFamily(itemOption.itemFamilyId);
    let changes: any = {};
    if (itemOptionId) {
      changes.itemOption = itemOption;
      changes.itemOptionId = itemOptionId;
      changes.itemFamilyId = itemOption.itemFamilyId;
      changes.itemFamily = itemOption.itemFamily;
      changes.thumbnail = itemOption?.smallViewableDownloadUrl || itemOption.itemFamily?.smallViewableDownloadUrl;
      if (projectItem) {
        changes.itemOption.projectItem = projectItem;
        changes.itemFamily.projectItem = projectItemOnFamily;
        await PlaceholderItemAssignmentService.assignProjectItemPropertiesToPlaceholder(projectItem, changes);
        await PlaceholderItemAssignmentService.assignProjectItemPropertiesToPlaceholder(projectItemOnFamily, changes);
      }
      await PlaceholderItemAssignmentService.assignItemPropertiesToPlaceholder(itemOption.itemFamily, changes);
      await PlaceholderItemAssignmentService.assignItemPropertiesToPlaceholder(itemOption, changes);
    }
    return changes;
  }

  private async getProjectItemForItem(itemId: string) {
    let projectId;
    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(
        take(1),
        tap(async (ws) => {
          projectId = ws.projectId;
        }),
      )
      .subscribe();
    const results = await new Entities().get({ entityName: 'project-item', criteria: { projectId, itemId } });
    if (results.length) {
      return results[0];
    }
  }

  private getCurrentProjectId(): string {
    let projectId;
    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(
        take(1),
        tap(async (ws) => {
          projectId = ws.projectId;
        }),
      )
      .subscribe();

    return projectId;
  }

  private async getProjectItemForItemFamily(itemId: string) {
    const projectItemInPlan = this.getFamilyProjectItemInPlan(itemId);
    if (projectItemInPlan) {
      return projectItemInPlan;
    }

    return await this.getProjectItemForItem(itemId);
  }

  private getFamilyProjectItemInPlan(itemId: string) {
    let existingPlaceholders;
    this.store
      .select(CollectionManagerSelectors.selectCollectionData)
      .pipe(take(1))
      .subscribe((phs) => (existingPlaceholders = phs));
    const ph = existingPlaceholders.find((obj) => obj.itemOptionId === itemId);
    return ph?.itemFamily?.projectItem;
  }

  private checkForDuplicateItemOptionId(id) {
    let existingPlaceholders;
    this.store
      .select(CollectionManagerSelectors.selectCollectionData)
      .pipe(take(1))
      .subscribe((phs) => (existingPlaceholders = phs));

    const match = existingPlaceholders.find((obj) => obj.itemOptionId === id);
    if (match) {
      throw Error(ITEM_ALREADY_EXISTS);
    }
  }

  private checkForDuplicateItemOptionName(itemFamilyId, itemOptionName) {
    let existingPlaceholders;
    this.store
      .select(CollectionManagerSelectors.selectCollectionData)
      .pipe(take(1))
      .subscribe((phs) => (existingPlaceholders = phs));
    const match = existingPlaceholders.find(
      (obj) => obj.itemFamilyId === itemFamilyId && obj.itemOption?.optionName === itemOptionName,
    );
    return match;
  }

  private async upsertNewProjectItem(itemId, projectItem): Promise<any> {
    const data = ObjectUtil.cloneDeep(projectItem);
    delete data.createdById;
    delete data.createdOn;
    delete data.id;
    delete data.itemId;
    delete data.updatedById;
    delete data.updatedOn;
    return await this.projectItemUpdateService.upsertProjectItem(itemId, data);
  }

  private getTypeMap() {
    let typeMap;
    this.store
      .select(CollectionManagerSelectors.typeDefinitions)
      .pipe(
        take(1),
        tap((map) => {
          typeMap = map;
        }),
      )
      .subscribe();
    return typeMap;
  }

  private getAssortment() {
    let plan;
    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(
        take(1),
        tap((currentPlan) => {
          plan = currentPlan;
        }),
      )
      .subscribe();
    return plan.targetAssortment;
  }

  private getOrgConfig() {
    let org;
    this.store
      .select(AuthSelectors.currentOrg)
      .pipe(
        take(1),
        tap((currentOrg) => {
          org = currentOrg;
        }),
      )
      .subscribe();
    return org.orgConfig;
  }

  private async createAndCarryoverProjectItems(
    carryoverData: { itemId: string; sourceProjectItem: any; sourceAssortmentItem: any; isOption: boolean }[],
  ) {
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
    const missingSourceProjectItems = await this.getSourceProjectItemsForCarryover(carryoverData);

    const carryoverProjectItemsToCreate = [];
    for (const { itemId, sourceProjectItem, sourceAssortmentItem, isOption } of carryoverData) {
      const projectItem = sourceProjectItem
        ? sourceProjectItem
        : missingSourceProjectItems.find((pi) => pi.itemId === itemId);

      carryoverProjectItemsToCreate.push(
        this.buildProjectItemsToUpsertForCarryover(
          itemId,
          projectItem,
          sourceAssortmentItem,
          isOption,
          projectItemType,
        ),
      );
    }

    if (carryoverProjectItemsToCreate.length) {
      return await this.projectItemUpdateService.batchUpsert(carryoverProjectItemsToCreate);
    }

    return [];
  }

  private async getSourceProjectItemsForCarryover(carryoverData: any) {
    const assortmentIds = [];
    const projectItemIds = [];
    for (const { itemId, sourceProjectItem, sourceAssortmentItem } of carryoverData) {
      if (!sourceProjectItem && sourceAssortmentItem) {
        if (sourceAssortmentItem.projectItem?.projectId) {
          const projectItemId = `${sourceAssortmentItem?.projectItem?.projectId}:${itemId}`;
          projectItemIds.push(projectItemId);
        } else {
          assortmentIds.push(sourceAssortmentItem.assortmentId);
        }
      }
    }

    const assortments = assortmentIds.length
      ? await new Entities().get({ entityName: 'assortment', criteria: { ids: assortmentIds } })
      : [];

    const workspaceIds = assortments.map((assortment) => assortment.rootWorkspaceId ?? assortment.workspaceId);
    const workspaces = workspaceIds.length
      ? await new Entities().get({ entityName: 'workspace', criteria: { ids: workspaceIds } })
      : [];

    for (const { itemId, sourceProjectItem, sourceAssortmentItem } of carryoverData) {
      if (!sourceProjectItem && sourceAssortmentItem) {
        const assortment = assortments.find((assortment) => assortment.id === sourceAssortmentItem.assortmentId);
        const workspace = workspaces.find(
          (workspace) => workspace.id === assortment.rootWorkspaceId || workspace.id === assortment.workspaceId,
        );
        if (workspace.projectId) {
          const projectItemId = `${workspace.projectId}:${itemId}`;
          projectItemIds.push(projectItemId);
        }
      }
    }

    const uniqueProjectItemIds = [...new Set(projectItemIds)];
    if (uniqueProjectItemIds.length) {
      return await new Entities().get({ entityName: 'project-item', criteria: { ids: uniqueProjectItemIds } });
    }

    return [];
  }

  private buildProjectItemsToUpsertForCarryover(
    itemId,
    projectItem,
    assortmentItem,
    isOption: boolean,
    projectItemType: Type,
  ) {
    const newProjectItem: any = {};

    if (!projectItem) {
      newProjectItem.addedFromSource = 'LIBRARY';
      newProjectItem.addedFromSourceDate = new Date();
      return { id: itemId, changes: newProjectItem };
    }

    const criteria = this.getFilterCriteria(isOption);
    const projectItemPropsToCarryover = projectItemType.typeProperties.filter(
      (prop) =>
        ['carryover'].includes(prop.carryOverBehavior) &&
        (criteria.includes(prop.propertyLevel) || !prop.propertyLevel),
    );
    const projectItemPropsToDefault = projectItemType.typeProperties.filter(
      (prop) =>
        ['default'].includes(prop.carryOverBehavior) && (criteria.includes(prop.propertyLevel) || !prop.propertyLevel),
    );

    projectItemPropsToCarryover.forEach((prop) => {
      if (
        [PropertyType.UserList, PropertyType.ObjectReference, PropertyType.TypeReference].includes(prop.propertyType)
      ) {
        newProjectItem[prop.slug + 'Id'] = projectItem[prop.slug + 'Id']
          ? projectItem[prop.slug + 'Id']
          : projectItem[prop.slug]?.id;
      } else {
        newProjectItem[prop.slug] = projectItem[prop.slug];
      }
    });
    projectItemPropsToDefault.forEach((prop) => {
      newProjectItem[prop.slug] = prop.carryOverDefault;
      if (prop.propertyType === PropertyType.Boolean) {
        newProjectItem[prop.slug] =
          prop.carryOverDefault === 'true' ? true : prop.carryOverDefault === 'false' ? false : prop.carryOverDefault;
      }
    });

    newProjectItem.itemId = itemId;
    newProjectItem.addedFromProject = projectItem?.projectId;
    newProjectItem.addedFromAssortment = assortmentItem?.assortmentId;
    newProjectItem.addedFromSource = 'ASSORTMENT';
    newProjectItem.addedFromSourceDate = new Date();

    return { id: itemId, changes: newProjectItem };
  }

  private getFilterCriteria(isOption) {
    if (isOption) {
      return ['option', 'all', 'overridable'];
    }
    return ['family', 'all', 'overridable'];
  }

  private static clearUnmappedItemFamilyPropertiesOfPlaceholder(placeholder: any, changes: any) {
    if (!placeholder.itemFamily || !changes.itemFamily) return changes;

    const itemFamilyChanges = {};
    for (const property in placeholder.itemFamily) {
      if (!changes.itemFamily.hasOwnProperty(property)) {
        itemFamilyChanges[property] = null;
        changes[property] = null;
      }
    }

    changes.itemFamily = { ...changes.itemFamily, ...itemFamilyChanges };

    if (!placeholder.itemFamily.projectItem || !changes.itemFamily.projectItem) return changes;

    const itemFamilyProjectItemChanges = {};
    for (const property in placeholder.itemFamily.projectItem) {
      if (!changes.itemFamily.projectItem.hasOwnProperty(property)) {
        itemFamilyProjectItemChanges[property] = null;
        changes[property] = null;
      }
    }

    changes.itemFamily.projectItem = { ...changes.itemFamily.projectItem, ...itemFamilyProjectItemChanges };

    return changes;
  }

  private splitIntoChunksByLen(arr, len) {
    const chunks = [],
      n = arr.length;
    let i = 0;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  }
}
