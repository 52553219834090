<div *ngIf="!currentPlan" class="title-bar">
  <div class="plan-logo flex items-center ml-4 cursor-pointer" (click)="goHome()">
    <img src="assets/images/plan_icon.svg" class="w-9 h-9 object-contain mr-4 non-selectable" />
    <h6>Plans</h6>
  </div>
  <div class="actions">
    <app-help-selector *ngIf="false" class="mr-1"></app-help-selector>
    <app-app-switch class="mr-2"></app-app-switch>
    <app-org-selector class="mx-2"></app-org-selector>
    <app-header-user-menu></app-header-user-menu>
  </div>
</div>

<div *ngIf="currentPlan" class="title-bar">
  <div class="left flex items-center">
    <mat-icon
      *ngIf="!isGuest && !entitySnapshot"
      data-test="back-home-button"
      class="cursor-pointer mr-2"
      (click)="goHome()"
      >keyboard_arrow_left</mat-icon
    >
    <img
      *ngIf="!entitySnapshot"
      src="assets/images/plan_icon.svg"
      class="w-9 h-9 object-contain mr-4 non-selectable"
      (click)="goHome()"
    />
    <div class="flex" *ngIf="entitySnapshot">
      <button
        class="flex items-center justify-center w-10 h-10 min-w-0 text-black60 px-3.5 py-1.5"
        (click)="clearSnapshot()"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="text-lg font-medium p-1.5">{{ entitySnapshot?.createdOn | date: 'medium' }}</div>
      <div class="ml-5">
        <button data-test="snapshot-version" color="primary" disabled="true" mat-stroked-button>
          V{{ entitySnapshot.versionNumber || ' ' + (entitySnapshot.createdOn | date: 'short') }}
        </button>
      </div>
      <div class="ml-5" *ngIf="isSnapshotRestoreAllowed">
        <button data-test="restore-snapshot-button" color="primary" mat-stroked-button (click)="restoreSnapshot()">
          RESTORE VERSION
        </button>
      </div>
    </div>
    <div class="title mr-3 flex items-center" *ngIf="!entitySnapshot">
      <h6 *ngIf="!editName">{{ currentPlan?.name }}</h6>
      <app-rename-plan *ngIf="editName" [plan]="currentPlan" (complete)="editName = false"></app-rename-plan>
    </div>

    <button
      data-test="edit-plan-name-icon"
      [matTooltip]="'Edit Name'"
      class="btn-32 !mr-2"
      *ngIf="!editName && canEdit"
      mat-button
    >
      <mat-icon (click)="editName = true">edit</mat-icon>
    </button>

    <app-menu data-test="plan-configuration-button" name="" icon="settings" [matTooltip]="'Plan Configuration'">
      <app-plan-configuration [plan]="currentPlan"></app-plan-configuration>
    </app-menu>
    <!-- <div (click)="viewAlternates()" data-test="plan-alternates-button" [matTooltip]="'Plan Alterates'" *ngIf="currentPlan?.targetAssortmentId && editorMode === 'EDIT'" class="icon-button" mat-button>
      <mat-icon svgIcon="alternate_icon"></mat-icon>
    </div> -->
    <button
      data-test="assortment-history-button"
      [matMenuTriggerFor]="historyMenu"
      [matTooltip]="'History'"
      class="btn-32 !mr-2"
      mat-button
    >
      <mat-icon>history</mat-icon>
    </button>
    <mat-menu #historyMenu="matMenu" yPosition="below" xPosition="before">
      <button
        *ngIf="currentPlan?.targetAssortmentId"
        data-test="Assortment-history"
        mat-menu-item
        (click)="viewAssortmentHistory()"
      >
        Assortment History
      </button>
      <button data-test="version-history" mat-menu-item (click)="viewVersionHistory()">Version History</button>
    </mat-menu>
    <div data-test="editor-mode" *ngIf="editorMode === 'VIEW' || editorMode === 'COMMENT'" class="editor-mode">
      <span *ngIf="editorMode === 'VIEW'">READ ONLY</span>
      <span *ngIf="editorMode === 'COMMENT'">REVIEW ONLY</span>
    </div>
    <div *ngIf="isGuest" class="editor-mode">Org Guest</div>
  </div>
  <!-- <app-tab-navigation *ngIf="navOptions" [navOptions]="navOptions"></app-tab-navigation> -->
  <div class="actions relative gap-1">
    <app-user-sessions-avatars class="mr-1"></app-user-sessions-avatars>
    <app-header-clipboard
      *ngIf="editorMode === 'EDIT'"
      data-test="clipboard-button"
      matTooltip="Clipboard"
      (showClipboard)="showClipboard()"
    ></app-header-clipboard>
    <app-status-messages-indicator
      data-test="status-messages-button"
      [matTooltip]="'Alerts'"
      [messages]="messages$ | async"
      [isLoading]="isLoadingMessages$ | async"
      [hasFailedToLoad]="hasFailedToLoadMessages$ | async"
      (click)="showMessages()"
    ></app-status-messages-indicator>
    <app-header-comments
      data-test="comments-button"
      [allowToggleSideBar]="true"
      (commentCountClicked)="showComment()"
      matTooltip="Comments"
    ></app-header-comments>
    <app-help-selector *ngIf="false" class="help-selector"></app-help-selector>
    <app-header-user-menu class="mx-2"></app-header-user-menu>
    <!-- <button mat-stroked-button color="primary" *ngIf="currentPlan?.targetAssortmentId" (click)="viewAlterates()"><mat-icon>compare_arrows</mat-icon>Alternates</button> -->
    <button
      data-test="share-plan-button"
      mat-stroked-button
      color="primary"
      (click)="share()"
      *ngIf="!isGuest && !entitySnapshot && editorMode === 'EDIT'"
    >
      <mat-icon class="mr-1">share</mat-icon> SHARE
    </button>
    <button
      data-test="publish-plan-button"
      mat-raised-button
      color="primary"
      *ngIf="canPublish && !entitySnapshot && editorMode === 'EDIT'"
      (click)="publish()"
      class="!mx-2"
    >
      <mat-icon class="mr-1">double_arrow</mat-icon> PUBLISH
    </button>
  </div>
</div>
