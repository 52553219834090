import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { SidePanelService, SidePanelView } from '../side-panel.service';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';

@Component({
  selector: 'app-side-panel-options',
  templateUrl: './side-panel-options.component.html',
  styleUrls: ['./side-panel-options.component.scss'],
})
export class SidePanelOptionsComponent implements OnInit, OnDestroy {
  public editorModeSub: Subscription;
  public editorMode = 'VIEW';
  public productView = {
    label: 'Add Item',
    slug: 'addItem',
    icon: 'add_new_icon',
    isSVGIcon: true,
  };
  public summaryView = {
    label: 'Summary',
    slug: 'summary',
    icon: 'dashboard_icon',
    isSVGIcon: true,
  };
  public messagesView = {
    label: 'Messages',
    slug: 'messages',
    icon: 'warning',
  };
  public commentsView = {
    label: 'Comments',
    slug: 'comments',
    icon: 'comment',
  };
  constructor(
    private sidePanelService: SidePanelService,
    private store: Store<RootStoreState.State>,
  ) {}

  ngOnInit(): void {
    this.editorModeSub = this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
  }
  ngOnDestroy() {
    this.editorModeSub.unsubscribe();
  }

  loadSidePanel(view) {
    this.sidePanelService.setCurrentView(view);
  }
  showComment() {
    this.sidePanelService.setCurrentView(this.commentsView);
  }
  showMessages() {
    this.sidePanelService.setCurrentView(this.messagesView);
  }
}
