import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SortOrderOptions } from '@contrail/sdk';
import { ListColumnDefinition } from './list-column-definition';
import { ListItemData } from './list-item-data';

export interface ListSortBy {
  label: string;
  direction: SortOrderOptions;
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnChanges {
  @Input() columnDefinitions: Array<ListColumnDefinition>;
  @Input() sortBy: ListSortBy = null;
  @Input() listData: Array<ListItemData>;
  @Output() doubleClickRow = new EventEmitter();
  @Output() clickRow = new EventEmitter();
  @Output() contextMenu = new EventEmitter();
  @Output() clickHeaderColumn = new EventEmitter();
  constructor() {}

  ngOnChanges(): void {}

  sort(column) {
    this.clickHeaderColumn.emit(column);
  }

  handleDoubleClickRow(listItemData) {
    this.doubleClickRow.emit(listItemData);
  }
  handleClickRow(listItemData) {
    this.clickRow.emit(listItemData);
    this.setListItemAsSelected(listItemData);
  }
  handleContextMenu(mouseEvent: MouseEvent, listItem) {
    console.log('list component: handleContextMenu');
    this.contextMenu.emit({ mouseEvent, listItem });
    this.setListItemAsSelected(listItem);
  }

  setListItemAsSelected(listItem) {
    setTimeout(() => {
      this.listData = this.listData.map((data) => {
        const isSelected = data.id === listItem.id;
        return { ...data, selected: isSelected };
      });
    }, 1);
  }
}
