import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentHistoryActions } from './document-history-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';

@Component({
  selector: 'app-document-history',
  templateUrl: './document-history.component.html',
  styleUrls: ['./document-history.component.scss'],
})
export class DocumentHistoryComponent implements OnInit {
  @Input() document: any;
  @Input() documentReference: any;
  @Input() clearEntitySnapshotWhenClosed = true;
  @Input() editorMode: EditorMode;
  @Input() historyType: string; // this can either be 'sourceChangeHistory' or 'versionHistory'
  @Output() panelClosed = new EventEmitter();
  constructor(private store: Store<RootStoreState.State>) {}

  async ngOnInit() {}

  handleClose() {
    this.panelClosed.emit();
    if (this.clearEntitySnapshotWhenClosed) {
      this.store.dispatch(DocumentHistoryActions.clearCurrentEntitySnapshot());
    }
  }
}
