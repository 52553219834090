import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { DataCellEditBaseComponent } from '../data-cell-edit-base-component';
import { NumberFormat, PropertyType } from '@contrail/types';
@Component({
  selector: 'app-data-cell-edit-percentage',
  template: `<input
    appLimitFractionDigits
    [maximumFractionDigits]="precision"
    [attr.data-test]="'input-' + property.slug"
    #inputField
    (blur)="handleChange()"
    [(ngModel)]="displayedValue"
  />`,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        justify-content: flex-end;
        text-align: right;
      }
      input {
        justify-content: flex-end;
        text-align: right;
        border: none;
        font-size: 12px;
        line-height: normal;
        padding: 0px;
        width: 95%;
      }
    `,
  ],
})
export class DataCellEditPercentageComponent extends DataCellEditBaseComponent implements AfterViewInit {
  @ViewChild('inputField') input: ElementRef;
  displayedValue: any;
  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.precision = this.property?.propertyDefinition?.numberFormat?.precision;

    const valueAsPercent = this.getValueAsPercent();
    const shouldRoundValue = Boolean(typeof this.precision === 'number' && (!!valueAsPercent || valueAsPercent === 0));
    this.displayedValue = shouldRoundValue ? valueAsPercent.toFixed(this.precision) : valueAsPercent;

    this.focusOnCell.emit(this.input?.nativeElement);
  }

  handleChange() {
    const updatedValue = parseFloat(this.displayedValue);
    if (isNaN(updatedValue)) {
      this.value = null;
    } else {
      const decimalAdjustmentForDivideBy100 = 2;
      const numberOfDecimalPlaces = this.getDecimalPlacesInNumber(updatedValue) + decimalAdjustmentForDivideBy100;
      this.value = Number((updatedValue / 100).toFixed(numberOfDecimalPlaces));
    }
    super.handleChange();
  }

  getValueAsPercent(): number {
    const currentPercentValue = parseFloat(this.value);
    if (isNaN(currentPercentValue)) {
      return null;
    } else {
      const decimalAdjustmentForMultiplyBy100 = 2;
      const numberOfDecimalPlaces = Math.max(
        this.getDecimalPlacesInNumber(currentPercentValue) - decimalAdjustmentForMultiplyBy100,
        0,
      );
      return Number((currentPercentValue * 100).toFixed(numberOfDecimalPlaces));
    }
  }

  getDecimalPlacesInNumber(numberValue: number): number {
    const numberString = numberValue.toString();
    const decimalIndex = numberString.indexOf('.');
    if (decimalIndex === -1) return 0;

    const numberOfDecimalPlaces = numberString.substring(decimalIndex + 1).length;
    return numberOfDecimalPlaces < 0 ? 0 : numberOfDecimalPlaces;
  }
}
