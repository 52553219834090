import { ValidationSummary } from '@contrail/type-validation';

export class BadRequestError extends Error {
  public validationSummary: ValidationSummary;
  public firstValidationErrorMessage: string;

  constructor(message: any, validationSummary?: ValidationSummary) {
    super();
    this.message = message;
    this.validationSummary = validationSummary;
    this.setValidationErrorMessage(validationSummary);
  }

  private setValidationErrorMessage(validationSummary?: ValidationSummary) {
    if (!validationSummary?.validationErrorCount || !validationSummary.validationErrors) {
      return;
    }

    const keys = Object.keys(validationSummary.validationErrors);
    if (keys.length > 0) {
      const firstValidationErrors = validationSummary.validationErrors[keys[0]];
      if (firstValidationErrors.length > 0) {
        this.firstValidationErrorMessage = firstValidationErrors[0].message;
      }
    }
  }
}
