import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { PlaceholderItemAssignmentService } from './placeholder-item-assignment-service';
import { ItemData } from '@common/item-data/item-data';
import { CollectionDataEntity } from '../collection-manager.service';
import { Item } from '@contrail/entity-types';

@Injectable({
  providedIn: 'root',
})
export class PlaceholderService {
  constructor(private placeholderItemAssignmentService: PlaceholderItemAssignmentService) {}

  public async addPlaceholdersToPlan(placeholders: CollectionDataEntity[]) {
    const itemData: ItemData[] = placeholders.map((placeholder) => {
      const itemId = this.getItemIdFromPlaceholder(placeholder);
      if (itemId) {
        return { id: itemId, placeholderData: placeholder } as ItemData;
      }

      return {} as ItemData;
    });

    await this.placeholderItemAssignmentService.addNewPlaceholderWithItems(itemData);
  }

  private getItemIdFromPlaceholder(placeholder: CollectionDataEntity): string {
    const itemFamilyId = placeholder.itemFamily?.id ?? placeholder.itemFamilyId ?? placeholder.itemOption?.itemFamilyId;
    const itemOptionId = placeholder.itemOption?.id ?? placeholder.itemOptionId;

    return itemOptionId || itemFamilyId;
  }
}
