import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './undo-redo.state';

export interface AppState {
  undoRedo: State;
}

export const selectUndoRedoActions = createFeatureSelector<State>('undoRedo');

export const selectUndoActions = createSelector(selectUndoRedoActions, (undoRedoActions) => {
  return undoRedoActions.undoActions;
});

export const selectLatestUndoAction = createSelector(selectUndoActions, (undoActions) => {
  if (undoActions.length > 0) {
    return undoActions[undoActions.length - 1];
  }
  return null;
});

export const selectRedoActions = createSelector(selectUndoRedoActions, (undoRedoActions) => {
  return undoRedoActions.redoActions;
});

export const selectLatestRedoAction = createSelector(selectRedoActions, (redoActions) => {
  if (redoActions.length > 0) {
    return redoActions[redoActions.length - 1];
  }
  return null;
});
