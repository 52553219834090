import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions } from '../../collection-manager-store';
import { UndoRedoDetails } from '../undo-redo-objects';
import { BasicUndoRedoHandler } from './basic-undo-redo.handler';
import { ItemUpdateService } from '../../items/item-update-service';
import { ProjectItemUpdateService } from '../../project-items/project-item-update-service';

export class UpdatePlaceholderUndoRedoHandler implements BasicUndoRedoHandler {
  constructor(
    public undoRedoDetails: UndoRedoDetails,
    public store: Store<RootStoreState.State>,
    public itemUpdateService: ItemUpdateService,
    public projectItemUpdateService: ProjectItemUpdateService,
    public data: any,
  ) {}

  undoActions(): void {
    const optionChanges = {};
    const placeholderUpdates = [];
    const itemUpdates = [];
    const optionUpdates = [];
    const projectItemUpdates = [];

    this.undoRedoDetails.changesList
      .filter((undo) => undo?.level === 'option')
      .forEach((undo) => {
        optionChanges[undo.id] = { changes: undo.undoChanges };
      });
    this.undoRedoDetails.changesList.forEach((undo) => {
      if (undo.scope && undo.scope === 'item') {
        if (undo.level !== 'family') {
          optionUpdates.push({ id: undo.id, changes: undo.undoChanges });
        } else {
          itemUpdates.push({ id: undo.id, changes: undo.undoChanges });
        }
      } else if (undo.scope && undo.scope === 'project-item') {
        projectItemUpdates.push({ id: undo.id, changes: undo.undoChanges, level: undo.level });
      } else {
        placeholderUpdates.push({ id: undo.id, changes: undo.undoChanges });
      }
    });
    if (placeholderUpdates.length > 0) {
      this.store.dispatch(
        CollectionManagerActions.updateCollectionDataEntities({
          entities: placeholderUpdates,
          skipRecordingUndoRedo: true,
        }),
      );
    }
    setTimeout(async () => {
      // needs to delay this to allow the placeholders in the store to update.
      if (itemUpdates.length > 0 || optionUpdates.length > 0) {
        await this.updateItem(itemUpdates, optionUpdates, optionChanges);
      }

      if (projectItemUpdates.length > 0) {
        this.updateProjectItems(projectItemUpdates);
      }
    }, 1);
  }
  redoActions(): void {
    const optionChanges = {};
    const placeholderUpdates = [];
    const familyUpdates = [];
    const optionUpdates = [];
    const projectItemUpdates = [];
    this.undoRedoDetails.changesList
      .filter((redo) => redo?.level === 'option')
      .forEach((redo) => {
        optionChanges[redo.id] = { changes: redo.changes };
      });
    this.undoRedoDetails.changesList.forEach((redo) => {
      if (redo?.scope === 'item') {
        if (redo.level !== 'family') {
          optionUpdates.push({ id: redo.id, changes: redo.changes });
        } else {
          familyUpdates.push({ id: redo.id, changes: redo.changes });
        }
      } else if (redo?.scope === 'project-item') {
        projectItemUpdates.push({ id: redo.id, changes: redo.changes, level: redo.level });
      } else {
        placeholderUpdates.push({ id: redo.id, changes: redo.changes });
      }
    });
    if (placeholderUpdates.length > 0) {
      this.store.dispatch(
        CollectionManagerActions.updateCollectionDataEntities({
          entities: placeholderUpdates,
          skipRecordingUndoRedo: true,
        }),
      );
    }
    setTimeout(async () => {
      // needs to delay this to allow the placeholders in the store to update.
      if (familyUpdates.length > 0 || optionUpdates.length > 0) {
        await this.updateItem(familyUpdates, optionUpdates, optionChanges);
      }

      if (projectItemUpdates.length > 0) {
        this.updateProjectItems(projectItemUpdates);
      }
    }, 1);
  }

  async updateItem(familyChanges = [], optionChanges = [], optionUpdates?) {
    const familyPlaceholderUpdates =
      familyChanges.length > 0 ? this.buildPlaceholderUpdates(familyChanges, 'family') : [];
    const optionPlaceholderUpdates =
      optionChanges.length > 0 ? this.buildPlaceholderUpdates(optionChanges, 'option') : [];
    const mergedPlaceholderUpdates = optionPlaceholderUpdates.concat(familyPlaceholderUpdates);

    const mergedChanges = optionChanges.concat(familyChanges);

    await this.itemUpdateService.batchUpdateItems({
      changes: mergedChanges,
      placeholderUpdates: {
        updates: mergedPlaceholderUpdates,
        skipRecordingUndoRedo: true,
        optionUpdates,
      },
    });
  }

  buildPlaceholderUpdates(changes, level): Array<any> {
    const itemChanges = [];
    changes.forEach((change) => {
      let item = null;
      if (level === 'option' || Object.keys(change.changes).includes('optionName')) {
        item = this.data.filter((itemObject) => itemObject.itemOption && itemObject.itemOption.id === change.id)[0];
        item = item.itemOption;
      } else {
        item = this.data.filter((itemObject) => itemObject.itemFamily && itemObject.itemFamily.id === change.id)[0];
        item = item.itemFamily;
      }
      itemChanges.push({
        item,
        changes: change.changes,
      });
    });

    return itemChanges;
  }

  updateProjectItems(changes) {
    const projectItemChanges = [];
    changes.forEach((change) => {
      let item = null;
      if (change.level === 'option') {
        const ph = this.data.filter((itemObject) => itemObject.itemOption && itemObject.itemOption.id === change.id)[0];
        item = ph.itemOption;
      } else {
        const ph = this.data.filter((itemObject) => itemObject.itemFamily && itemObject.itemFamily.id === change.id)[0];
        item = ph.itemFamily;
      }
      projectItemChanges.push({
        item,
        changes: change.changes,
      });
    });

    this.projectItemUpdateService.batchUpdateProjectItems({
      changes,
      placeholderUpdates: {
        updates: projectItemChanges,
        skipRecordingUndoRedo: true,
        undoRedo: true,
      },
    });
  }
}
