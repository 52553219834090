import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() dialogRef: MatDialogRef<any>;
  @Input() title: string;
  @Input() showHeaderSeparator = false;
  @Input() showHide = true;

  constructor() {}

  ngOnInit(): void {}
}
