import { OnChanges, ViewEncapsulation } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Types } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { from, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataCellViewBaseComponent } from '../data-cell-view-base-component';
@Component({
  selector: 'app-data-cell-type-reference',
  template: ` <div>{{ display$ | async }}</div> `,
})
export class DataCellViewTypeReferenceComponent extends DataCellViewBaseComponent implements OnChanges {
  @Input() value;
  public display$ = new Subject();
  constructor() {
    super();
  }

  async ngOnChanges() {
    if (this.value) {
      const type: Type = await new Types().getType({ id: this.value });
      this.display$.next(type.label);
    }
  }
}
