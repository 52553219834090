import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthActions } from 'src/app/common/auth/auth-store';
import { PlansActions, RootStoreState } from 'src/app/root-store';
import { WorkspacesActions } from '.';
import { WorkspacesService } from '../workspaces.service';

@Injectable()
export class WorkspacesEffects {
  constructor(
    private actions$: Actions,
    private workspaceService: WorkspacesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  authContextChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.AuthActionTypes.SET_AUTH_CONTEXT),
        tap((action: any) => {
          this.store.dispatch(WorkspacesActions.clearWorkspaces());
          this.store.dispatch(WorkspacesActions.loadWorkspaces());
        }),
      ),
    { dispatch: false },
  );

  loadWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_WORKSPACES),
      switchMap((action: any) => {
        return from(this.workspaceService.getWorkspaces()).pipe(
          map((data) => WorkspacesActions.loadWorkspacesSuccess({ data })),
          catchError((error) => observableOf(WorkspacesActions.loadWorkspacesFailure({ error }))),
        );
      }),
    ),
  );
  loadWorkspacesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_WORKSPACES_SUCCESS),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          // SET THE CURRENT WORKSPACE
          const orgSlug = store.auth.authContext.currentOrg.orgSlug;
          const lastWorkspaceId = localStorage.getItem(`lastWorkspaceId_${orgSlug}`);
          let workspaceId = store.workspaces.workspaces.ids[0] as string;
          const workspaceIds = store.workspaces.workspaces.ids;
          if (lastWorkspaceId && workspaceIds.findIndex((id) => id === lastWorkspaceId) > -1) {
            workspaceId = lastWorkspaceId;
          }

          if (
            !store.workspaces.currentWorkspace ||
            store.workspaces.currentWorkspace?.orgId !== store.auth.authContext.currentOrg.id
          ) {
            this.store.dispatch(WorkspacesActions.loadCurrentWorkspace({ id: workspaceId }));
          }
        }),
      ),
    { dispatch: false },
  );
  loadCurrentWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE),
      switchMap((action: any) => {
        return from(this.workspaceService.getWorkspaceById(action.id)).pipe(
          map((data) => WorkspacesActions.loadCurrentWorkspaceSuccess({ workspace: data })),
          catchError((error) => observableOf(WorkspacesActions.loadCurrentWorkspaceFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentWorkspaceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          const orgSlug = store.auth.authContext.currentOrg.orgSlug;
          localStorage.setItem(`lastWorkspaceId_${orgSlug}`, action.workspace?.id);
          this.store.dispatch(PlansActions.loadPlans());
          // TODO: Dispatch in item-chooser
          // this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolder({ workspace: action.workspace }));
        }),
      ),
    { dispatch: false },
  );

  loadCurrentFolder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.SET_CURRENT_WORKSPACE_FOLDER),
        tap((action: any) => {
          this.store.dispatch(WorkspacesActions.loadWorkspaceFolderEntities({ workspaceId: action.workspace?.id }));
        }),
      ),
    { dispatch: false },
  );

  loadCurrentFolderEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_WORKSPACE_FOLDER_ENTITIES),
      withLatestFrom(this.store),
      tap(() => {
        this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolderLoading({ loading: true }));
      }),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const currentFolderWorkspace = store.workspaces.currentWorkspaceFolder;
        return from(this.workspaceService.getWorkspaceEntities(currentFolderWorkspace)).pipe(
          tap(() => {
            this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolderLoading({ loading: false }));
          }),
          map((workspaceEntities) => WorkspacesActions.loadWorkspaceFolderEntitiesSuccess({ workspaceEntities })),
          catchError((error) => observableOf(WorkspacesActions.loadCurrentWorkspaceFailure({ error }))),
        );
      }),
    ),
  );

  createWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.CREATE_WORKSPACE),
      tap(() => {
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Creating workspace.' }));
      }),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.workspaceService.createWorkspace(action.workspace)).pipe(
          map((data) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open('Project Created.', '', { duration: 2000 });
            // this.store.dispatch(WorkspacesActions.setCurrentWorkspace({ currentWorkspace: data }));
            return WorkspacesActions.createWorkspaceSuccess({ workspace: data });
          }),
          catchError((error) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(WorkspacesActions.createWorkspaceFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.DELETE_WORKSPACE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.workspaceService.deleteWorkspace(action.workspace)).pipe(
          map((data) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open('Project Deleted.', '', { duration: 2000 });
            // this.store.dispatch(WorkspacesActions.setCurrentWorkspace({ currentWorkspace: null }));
            return WorkspacesActions.deleteWorkspaceSuccess({ workspace: data });
          }),
          catchError((error) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(WorkspacesActions.deleteWorkspaceFailure({ error }));
          }),
        );
      }),
    ),
  );
  updateWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.UPDATE_WORKSPACE),
      tap(() => {
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Updating Workspace.' }));
      }),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.workspaceService.updateWorkspace(action.id, action.changes)).pipe(
          tap(() => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
          }),
          map((data) => {
            this.snackBar.open('Project Updated.', '', { duration: 2000 });
            // this.store.dispatch(WorkspacesActions.loadWorkspaceFolderEntities({ workspaceId: store.workspaces.currentWorkspaceFolder.id }));
            return WorkspacesActions.updateWorkspaceSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(WorkspacesActions.updateWorkspaceFailure({ error }));
          }),
        );
      }),
    ),
  );
}
