import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { TabNavigationComponent } from './tab-navigation/tab-navigation.component';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { FilterMenuModalComponent } from './filter-menu/filter-menu-modal/filter-menu-modal.component';
import { FilterEntityComponent } from './filter-menu/filter-entity/filter-entity.component';
import { AvatarComponent } from './avatar/avatar.component';
import { PaginationComponent } from './pagination/pagination.component';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MenuComponent } from './menu/menu.component';
import { PageSectionComponent } from './page-section/page-section.component';
import { PageSectionHeaderComponent } from './page-section/page-section-header/page-section-header.component';
import { PageSectionSubTitleComponent } from './page-section/page-section-sub-title/page-section-sub-title.component';
import { PageSectionTitleComponent } from './page-section/page-section-title/page-section-title.component';
import { PageSectionActionsComponent } from './page-section/page-section-actions/page-section-actions.component';
import { PageSectionBodyComponent } from './page-section/page-section-body/page-section-body.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ConfirmationBoxComponent } from './confirmation-box/confirmation-box';
import { ListViewToggleComponent } from './list-view-toggle/list-view-toggle.component';
import { ListCardComponent } from './list-card/list-card.component';
import { ObjectReferenceChipComponent } from './object-reference-chip/object-reference-chip.component';
import { EmptyPlaceholderComponent } from './empty-placeholder/empty-placeholder.component';
import { AppSwitchComponent } from './app-switch/app-switch.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { SearchReplaceComponent } from './search-replace/search-replace.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSpinnerComponent } from '@standalone-components/mat-spinner/mat-spinner.component';
import { PipesModule } from '@pipes/pipes.module';
import { PivotGridComponent } from './pivot-grid/pivot-grid.component';
import { PivotGridModule } from './pivot-grid/pivot-grid.module';
import { PivotViewConfiguratorComponent } from './pivot-grid/pivot-view-configurator/pivot-view-configurator.component';
import { CopyRenameEntityModalComponent } from './copy-rename-entity/copy-rename-entity-modal/copy-rename-entity-modal.component';
import { CopyRenameEntityFormComponent } from './copy-rename-entity/copy-rename-entity-form/copy-rename-entity-form.component';
import { CopyRenameEntityModalLauncher } from './copy-rename-entity/copy-rename-entity-modal-launcher';
import { AutoCompleteComponent } from './autocomplete/autocomplete.component';

@NgModule({
  declarations: [
    SearchBarComponent,
    ModalComponent,
    TabNavigationComponent,
    FilterMenuComponent,
    FilterMenuModalComponent,
    FilterEntityComponent,
    AvatarComponent,
    PaginationComponent,
    MenuComponent,
    PageSectionComponent,
    PageSectionHeaderComponent,
    PageSectionSubTitleComponent,
    PageSectionTitleComponent,
    PageSectionBodyComponent,
    PageSectionActionsComponent,
    ConfirmationBoxComponent,
    ListViewToggleComponent,
    ListCardComponent,
    ObjectReferenceChipComponent,
    EmptyPlaceholderComponent,
    AppSwitchComponent,
    SearchReplaceComponent,
    LeftSidebarComponent,
    CopyRenameEntityModalComponent,
    CopyRenameEntityFormComponent,
    AutoCompleteComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    FormsModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatMenuModule,
    MatInputModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSelectModule,
    DragDropModule,
    PipesModule,

    // standalone components
    MatSpinnerComponent,
    PivotGridModule,
  ],
  providers: [CopyRenameEntityModalLauncher],
  exports: [
    SearchBarComponent,
    ModalComponent,
    TabNavigationComponent,
    FilterMenuComponent,
    FilterMenuModalComponent,
    AvatarComponent,
    PaginationComponent,
    MenuComponent,
    PageSectionComponent,
    PageSectionHeaderComponent,
    PageSectionSubTitleComponent,
    PageSectionTitleComponent,
    PageSectionActionsComponent,
    PageSectionBodyComponent,
    ListViewToggleComponent,
    ListCardComponent,
    ObjectReferenceChipComponent,
    EmptyPlaceholderComponent,
    AppSwitchComponent,
    SearchReplaceComponent,
    LeftSidebarComponent,
    AutoCompleteComponent,

    // standalone components
    MatSpinnerComponent,
    PivotGridComponent,
    PivotViewConfiguratorComponent,
  ],
})
export class ComponentsModule {}
