import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ViewDefinition, ViewPropertyConfiguration } from '@contrail/client-views';
import { ObjectUtil } from '@contrail/util';
import { Subscription } from 'rxjs';
import { PivotGridManager } from './pivot-grid.manager';
import { PivotContextMenuComponent } from './context-menu/pivot-context-menu.component';
import { PivotGoals } from './pivot-goals';
import { PivotGridService } from './pivot-grid-service';
import { PropertyType } from '@contrail/types';

@Component({
  selector: 'app-pivot-grid',
  templateUrl: './pivot-grid.component.html',
  styleUrls: ['./pivot-grid.component.scss'],
})
export class PivotGridComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data: any[] = [];
  @Input() goals: Array<PivotGoals>;
  @Input() gridWidth = 1000; // maximum grid width: if calculated gridTotalWidth exceeds this value, horizontal scroll will appear
  @Input() gridHeight = 500;
  @Input() viewDefinition: ViewDefinition;
  @ViewChild('verticalScroll') verticalScroll: ElementRef;
  @ViewChild(PivotContextMenuComponent) contextMenu: PivotContextMenuComponent;

  public leftProperties: ViewPropertyConfiguration[] = [];
  public rightProperties: ViewPropertyConfiguration[] = [];
  public leftSideColumnWidth = 0;
  public rightSideColumnWidth = 0;
  public gridTotalWidth = this.gridWidth;
  public pivotData: any[] = [];
  public rowsToRender = 0;
  public rowViewPortHeight = 0;
  public rowStartIndex = 0;
  public hoveredRowId = '';
  public rowContainerHeight = 0; // height of visible row areas without header
  public groupingProperties: Array<ViewPropertyConfiguration>;
  public showGoals = false;
  public gridViewRowHeight = 24;
  public pivotGridManager: PivotGridManager;
  public scrollVerticalPercentage = 0;
  public scrollableHeight = 0;
  public rightColumnMinWidth = 0;
  public subscriptions = new Subscription();

  constructor(private pivotGridService: PivotGridService) {
    this.pivotGridManager = new PivotGridManager();
    this.subscriptions.add(
      this.pivotGridManager.pivotData$.subscribe((pivotData) => {
        if (pivotData) {
          this.pivotData = pivotData;
          console.log('pivotData: ', pivotData);
          // this.pivotData = this.data;
          this.calculateViewportHeight();
        }
      }),
    );
    this.subscriptions.add(
      this.pivotGridManager.viewDefinition$.subscribe((viewDefinition) => {
        if (viewDefinition) {
          this.viewDefinition = ObjectUtil.cloneDeep(viewDefinition);
          this.configureGrid(false);
        }
      }),
    );
    this.subscriptions.add(
      this.pivotGridService.showGoals$.subscribe((val) => {
        if (this.showGoals !== val) {
          this.showGoals = val;
          this.configureGrid(false);
        }
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewDefinition || changes.data) {
      if (
        changes.viewDefinition &&
        !this.pivotGridManager.arePropertiesEqual(
          changes.viewDefinition?.previousValue,
          changes.viewDefinition?.currentValue,
        )
      ) {
        this.configureGrid(true);
      } else if (changes.data) {
        this.configureGrid(true, true);
      } else if (
        !this.pivotGridManager.areSortsEqual(changes.viewDefinition.previousValue, changes.viewDefinition.currentValue)
      ) {
        this.pivotGridManager.sortPivotData(changes.viewDefinition.currentValue);
      } else {
        this.configureGrid(false);
      }
    } else if (changes.gridWidth || changes.gridHeight) {
      if (
        changes.gridWidth?.currentValue !== changes.gridWidth?.previousValue ||
        changes.gridHeight?.currentValue !== changes.gridHeight?.previousValue
      ) {
        this.configureGrid(false);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.configureGrid();
    }, 500);
  }

  configureGrid(calculateData = true, keepExpandedRows = false) {
    this.groupingProperties = this.viewDefinition.properties.filter(
      (property) => property.enabled && property.frozen && property.propertyDefinition,
    );
    this.leftProperties = this.groupingProperties;
    this.rightProperties = this.viewDefinition.properties.filter(
      (property) => property.enabled && !property.frozen && property.propertyDefinition,
    );

    if (this.showGoals) {
      this.rightProperties.unshift({
        slug: 'count',
        frozen: false,
        enabled: true,
        width: 180,
        aggregateFunction: 'SUM',
        typeRootSlug: 'plan-placeholder',
        propertyDefinition: {
          slug: 'count',
          label: 'Count',
          propertyType: PropertyType.Number,
        },
      });
    }

    const dimension = this.pivotGridManager.calculateWidth(this.viewDefinition);
    this.leftSideColumnWidth = dimension.leftSideColumnWidth;
    this.rightSideColumnWidth = dimension.rightSideColumnWidth + 180; // add count
    this.rowContainerHeight = this.gridHeight - 25; // deduct header height
    if (this.gridWidth > this.leftSideColumnWidth + this.rightSideColumnWidth) {
      // no horizontal scroll
      this.gridTotalWidth = this.leftSideColumnWidth + this.rightSideColumnWidth + 20;
      this.rightColumnMinWidth = this.rightSideColumnWidth;
    } else {
      this.gridTotalWidth = this.gridWidth + 20;
      this.rightColumnMinWidth = 0;
    }

    if (this.data.length > 0 && calculateData) {
      // only recalculate if a row grouping property is available
      // if (this.viewDefinition.properties.filter(property => property.frozen).length > 0) {
      this.pivotGridManager.calculatePivotData(this.data, this.viewDefinition, keepExpandedRows);
      // }
    }
    this.rowsToRender = Math.ceil(this.gridHeight / this.gridViewRowHeight);
    setTimeout(() => {
      this.scrollableHeight =
        this.verticalScroll.nativeElement.scrollHeight - this.verticalScroll.nativeElement.clientHeight;
    }, 100);
  }

  @HostListener('mousewheel', ['$event']) // for window scroll events
  onMouseWheel(event) {
    //event.preventDefault();
    const amount = event.deltaY;
    this.scrollVertical(-amount);
  }

  private scrollVertical(delta) {
    if (this.scrollableHeight <= 0) {
      return;
    }
    let multiplier = -1;
    if (delta < 0) {
      multiplier = 1;
    }
    const oneRowPercentage = this.gridViewRowHeight / this.scrollableHeight;
    let newPercentage = this.scrollVerticalPercentage + oneRowPercentage * multiplier;
    newPercentage = newPercentage < 0 ? 0 : newPercentage;
    newPercentage = newPercentage > 1 ? 1 : newPercentage;
    const scrollTop =
      newPercentage * (this.verticalScroll.nativeElement.scrollHeight - this.verticalScroll.nativeElement.clientHeight);
    this.verticalScroll.nativeElement.scrollTop = scrollTop;
  }

  onScroll(event) {
    const target = event.target;
    if (target.scrollHeight > target.clientHeight) {
      this.scrollVerticalPercentage = target.scrollTop / (target.scrollHeight - target.clientHeight);
      const hiddenVerticalHeight = this.rowViewPortHeight - this.gridHeight;
      this.scrollableHeight = hiddenVerticalHeight;
      const hiddenRowCount = hiddenVerticalHeight / this.gridViewRowHeight;
      let index = 0;
      index = (hiddenRowCount + 2) * this.scrollVerticalPercentage; // added 2 so that the last row is not half-hidden.
      index = Math.round(index);
      this.rowStartIndex = index;
    } else {
      this.rowStartIndex = 0;
    }
  }

  calculateViewportHeight() {
    this.rowViewPortHeight = this.pivotData.length * this.gridViewRowHeight;
  }

  onRowAction(event) {
    if (event.action === 'expand') {
      this.pivotGridManager.expandSingleRow(event.rowData);
    } else {
      this.pivotGridManager.collapseSingleRow(event.rowData);
    }
    setTimeout(() => {
      this.scrollableHeight =
        this.verticalScroll.nativeElement.scrollHeight - this.verticalScroll.nativeElement.clientHeight;
    }, 100);
  }

  onHoverRow(event) {
    this.hoveredRowId = event;
  }

  performResizeColumn(columnChanges: any) {
    this.pivotGridManager.handleResizeColumn(columnChanges);
  }

  toggleRows(rowChanges: any) {
    this.pivotGridManager.toggleAllRows(rowChanges);
  }

  closeContextMenu() {
    this.contextMenu.closeContextMenu();
  }

  handleContextMenu(eventData) {
    const groupPropIndex = this.leftProperties.findIndex((prop) => prop.slug === eventData.row.propertySlug);
    if (groupPropIndex < this.leftProperties.length - 1) {
      const groupProp = this.leftProperties[groupPropIndex];
      this.contextMenu.onContextMenu(eventData.event, eventData.row, groupProp.propertyDefinition.label);
    }
  }

  handleRowAction(eventData) {
    if (eventData.event === 'expand-rows') {
      this.pivotGridManager.expandRowsAtLevel(eventData.propLevel);
    } else {
      this.pivotGridManager.collapseRowsAtLevel(eventData.propLevel);
    }
  }
}
