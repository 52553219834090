<div class="row-drag-preview" #rowDragPreview></div>

<div #planGrid class="grid-body flex" id="grid-body" cdkDrop>
  <div
    *ngIf="(overrideOptionCells$ | async).length > 0"
    class="absolute bg-slate-500 opacity-20 h-[calc(100%-18px)] w-[calc(100%-18px)] z-[100]"
  ></div>
  <div class="left-side" [style.width.px]="leftSideColumnWidth$ | async">
    <div>
      <app-header-row [properties]="leftProperties$ | async" [data]="data$ | async" side="left"></app-header-row>
      <app-data-rows
        [editorMode]="editorMode"
        [allData]="allData$ | async"
        [data]="data$ | async"
        side="left"
        [sideWidth]="leftSideColumnWidth$ | async"
        [properties]="leftProperties$ | async"
      ></app-data-rows>
    </div>
    <div class="agg-row">
      <app-aggregate-row [properties]="leftProperties$ | async" side="left"></app-aggregate-row>
    </div>
  </div>

  <div class="right-side horizontally-scrollable" [style.left.px]="leftSideColumnWidth$ | async">
    <div>
      <app-header-row [properties]="rightProperties$ | async"></app-header-row>
      <app-data-rows
        [editorMode]="editorMode"
        [allData]="allData$ | async"
        [data]="data$ | async"
        side="right"
        [properties]="rightProperties$ | async"
      ></app-data-rows>
    </div>
    <div class="agg-row">
      <app-aggregate-row [properties]="rightProperties$ | async"></app-aggregate-row>
    </div>
  </div>
  <!-- <app-vertical-scroller></app-vertical-scroller> -->
</div>

<div data-test="add-placeholder-fab" *ngIf="editorMode === 'EDIT'" class="newPlaceholder">
  <app-create-placeholders
    (createPlaceholders)="addNewPlaceholders($event)"
    matTooltip="You can also add a placeholder by pressing Shift-Enter"
  ></app-create-placeholders>
</div>

<app-horizontal-scroller></app-horizontal-scroller>
<app-column-drop-preview></app-column-drop-preview>
