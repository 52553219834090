import { Injectable } from '@angular/core';
import { DocumentHistoryActions } from '@common/document-history/document-history-store';
import { DocumentHistoryActionTypes } from '@common/document-history/document-history-store/document-history.actions';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { WebSocketService } from '@common/web-socket/web-socket.service';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PlansActions, PlansSelectors, RootStoreState } from '@rootstore';
import { tap } from 'rxjs/operators';
import { Request } from '@contrail/sdk';
import { SidePanelService } from '../side-panel/side-panel.service';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root',
})
export class PlanSnapshotService {
  private plan: any;
  private editorMode: string;

  constructor(
    private confirmationBoxService: ConfirmationBoxService,
    private actions$: Actions,
    private webSocketService: WebSocketService,
    private sidePanelService: SidePanelService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {
    this.store.select(PlansSelectors.currentPlan).subscribe((currentPlan) => (this.plan = currentPlan));
    this.store.select(PlansSelectors.editorMode).subscribe((editorMode) => (this.editorMode = editorMode));

    // listens to the RESTORE_ENTITY_SNAPSHOT action from the store
    this.actions$
      .pipe(
        ofType(DocumentHistoryActionTypes.RESTORE_ENTITY_SNAPSHOT),
        tap((action: any) => {
          this.restoreSnapshot(action.id);
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(DocumentHistoryActionTypes.LOAD_CURRENT_ENTITY_SNAPSHOT_SUCCESS),
        tap((action: any) => {
          this.store.dispatch(PlansActions.setEditorMode({ editorMode: EditorMode.VIEW }));
          if (!localStorage.getItem('editorMode')) {
            localStorage.setItem('editorMode', this.editorMode);
          }
        }),
      )
      .subscribe();
  }

  async performRestoreSnapshot(planId: string, snapshotId: string) {
    const requestUrl = '/plans/' + planId + '/restore/' + snapshotId;
    await Request.request(requestUrl, {
      method: 'PUT',
      body: JSON.stringify({}),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  async restoreSnapshot(snapshotId: string) {
    const confirm = await this.confirmationBoxService.open(
      'Confirmation',
      'This action will save the current state of the Plan and replace it with this version. Do you want to proceed?',
    );
    if (confirm) {
      // start the loading indicator
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
      try {
        await this.performRestoreSnapshot(this.plan.id, snapshotId);
        this.webSocketService.sendSessionEvent({
          eventType: 'RESTORE_PLAN_SNAPSHOT',
          changes: this.plan.id,
        });

        this.clearSnapshot();
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
      } catch (error) {
        console.error(error);
        // stop the loading indicator
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));

        if (error.message === 'Endpoint request timed out') {
          this.snackBar.open('Error: ' + error.message + '. The operation may still have been successful.', '', {
            duration: 8000,
          });
        } else {
          this.snackBar.open(`Error: ${error.message}`, '', { duration: 5000 });
        }
      }
    }
  }

  public clearSnapshot() {
    // revert to the latest plan version
    const planId = this.plan.id;

    this.store.dispatch(DocumentHistoryActions.clearCurrentEntitySnapshot());
    this.store.dispatch(PlansActions.loadCurrentPlan({ id: planId }));
    if (localStorage.getItem('editorMode')) {
      this.store.dispatch(PlansActions.setEditorMode({ editorMode: localStorage.getItem('editorMode') }));
      localStorage.removeItem('editorMode');
    }
    this.sidePanelService.setCurrentView(null);
  }
}
