import { ElementRef, Renderer2 } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { fromEvent } from 'rxjs';
import { PlanRowOrder } from 'src/app/plans/plans-store/plans.state';
import { PlansActions, PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../../collection-manager-store';
import { DataRowComponent } from '../data-rows/data-row/data-row.component';
import { GridViewManager } from '../grid-view.manager';

const BORDER_STYLE = '3px solid #00a0ff';

export class RowDragHandler {
  dragActiveRow = null;
  selectedEntityIds = [];
  sorts = [];
  hovered = false;
  currentDraggedOverPosition = '';
  currentPlanRowOrder: PlanRowOrder;
  rowId: string;
  dataRowElem: ElementRef;
  rowDragHandleElem: ElementRef;
  draggingAllowed = true;

  constructor(
    private store: Store<RootStoreState.State>,
    private renderer: Renderer2,
    private gridViewManager: GridViewManager,
    private dataRowComponent: DataRowComponent,
  ) {
    this.rowId = dataRowComponent.rowId;
    this.dataRowElem = dataRowComponent.dataRowElem;
    this.rowDragHandleElem = dataRowComponent.rowDragHandleElem;

    this.store.select(CollectionManagerSelectors.dragActiveRow).subscribe((dragActiveRow) => {
      this.dragActiveRow = dragActiveRow;
      if (!dragActiveRow) {
        this.removeBorderStyle();
      }
    });

    this.store.select(PlansSelectors.planRowOrder).subscribe((planRowOrder: any) => {
      this.currentPlanRowOrder = planRowOrder;
    });

    this.store.select(CollectionManagerSelectors.selectedEntityIds).subscribe((selectedEntityIds) => {
      this.selectedEntityIds = selectedEntityIds;
    });

    // This sets the cursor for the drag row cell.
    // The "grab" cursor is used to provide a visual that row drag is available when sorts are not set.
    this.store.select(CollectionManagerSelectors.selectCurrentView).subscribe((currentView) => {
      const sorts = currentView?.sorts || [];
      this.draggingAllowed = sorts.length === 0;
    });

    //  This sets the bottom or top border of the row to provide a visual of when the dragged row(s) can be dropped
    //  When dragging a row to rows below it, the bottom border is used.
    //  When dragging a row to rows above it, the top border is used.
    this.store.select(CollectionManagerSelectors.hoveredEntityId).subscribe((id) => {
      this.hovered = id && id === this.rowId;
      if (this.dragActiveRow) {
        if (!this.hovered) {
          this.removeBorderStyle();
        } else {
          this.setBorderStyle();
        }
      }
    });

    const dataRowElement = this.dataRowElem.nativeElement;
    const dataRowMouseup$ = fromEvent(dataRowElement, 'mouseup');

    dataRowMouseup$.subscribe((event) => {
      // Listens to mouseup event when dragged row(s) are dropped.
      this.handleRowDragMouseup(event);
    });

    if (this.rowDragHandleElem) {
      const rowDragHandleElement = this.rowDragHandleElem.nativeElement;
      const rowDragHandleMousedown$ = fromEvent(rowDragHandleElement, 'mousedown');
      rowDragHandleMousedown$.subscribe((event) => {
        // rowDragHandleElem is the cell that acts as the row drag handle.
        // During a mousedown event, the row that the row drag handle cell belongs to becomes the "dragActiveRow".
        this.handleRowDragHandleMousedown(event);
      });
    }
  }

  handleRowDragHandleMousedown(event) {
    if (event.button === 0 && this.sorts.length === 0) {
      event.preventDefault();
      this.store.dispatch(CollectionManagerActions.setRowDragActive({ dragActiveRow: this.rowId }));
    }
  }

  handleRowDragMouseup(event) {
    if (this.dragActiveRow) {
      if (this.rowId !== this.dragActiveRow) {
        // make sure it's not dropped on the same row
        const currentRowOrder = this.currentPlanRowOrder.rowOrder;
        const rowOrder = ObjectUtil.cloneDeep(currentRowOrder);
        const targetRowIndex = rowOrder.indexOf(this.rowId);
        let rowIds = [];
        if (this.selectedEntityIds.length > 0) {
          let clonedSelectedIds = ObjectUtil.cloneDeep(this.selectedEntityIds);
          clonedSelectedIds = clonedSelectedIds.sort((a, b) => {
            if (currentRowOrder.indexOf(a) > currentRowOrder.indexOf(b)) {
              return 1;
            } else {
              return -1;
            }
          });
          rowIds = rowIds.concat(clonedSelectedIds);
        } else {
          rowIds.push(this.dragActiveRow);
        }
        this.store.dispatch(PlansActions.updatePlanRowOrder({ rowIds, targetRowIndex }));
      }
      this.store.dispatch(CollectionManagerActions.setRowDragActive({ dragActiveRow: null }));
      this.store.dispatch(CollectionManagerActions.removeSelectedEntityIds({ ids: this.selectedEntityIds }));
    }
  }

  setBorderStyle() {
    this.currentDraggedOverPosition = this.getDraggedOverPosition();
    this.renderer.setStyle(this.dataRowElem.nativeElement, this.currentDraggedOverPosition, BORDER_STYLE);
  }

  removeBorderStyle() {
    this.renderer.removeStyle(this.dataRowElem.nativeElement, this.currentDraggedOverPosition);
  }

  getDraggedOverPosition() {
    if (this.gridViewManager.rowIds.indexOf(this.rowId) < this.gridViewManager.rowIds.indexOf(this.dragActiveRow)) {
      return 'border-top';
    }
    return 'border-bottom';
  }

  isDraggingAllowed() {
    return this.draggingAllowed;
  }
}
