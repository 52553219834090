import { DateFormatingOptions, NumberFormat } from '@contrail/types';

export class ListColumnDefinition {
  label: string;
  index?: string;
  width?: number;
  alignment?: string;
  propertyType: string;
  renderType?: ListColumnRenderType;
  constantValue?: string;
  numberFormat?: {
    format?: NumberFormat;
    decimalPlaces?: number;
    currencyCode?: string;
  };
  dateFormat?: DateFormatingOptions;
}

export enum ListColumnRenderType {
  TEXT_OR_IMAGE = 'textOrImage',
}
