import { ContextMenuAction } from './context-menu-action';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerSelectors } from '../../collection-manager-store';
import { SelectedCellInfo } from '../context-menu.component';
import { take, tap } from 'rxjs/operators';
import { PlaceholderItemPromoteService } from '../../placeholders/placeholder-item-promote-service';

export class PromotContextMenuAction extends ContextMenuAction {
  constructor(
    protected store: Store<RootStoreState.State>,
    private placeholderItemPromoteService: PlaceholderItemPromoteService,
  ) {
    super(store);
    this.actionIcon = 'bolt';
    this.actionLabel = 'Promote';
    this.shortCutKey = 'Ctrl-P';
    this.testRef = 'app-menu-promote-item-action';
  }
  async handleAction(
    editorMode: string,
    ids: Array<string>,
    selectedCellInfo: SelectedCellInfo,
    selectedCellLocation: DOMRect = null,
  ) {
    let placeholders;
    this.store
      .select(CollectionManagerSelectors.displayedData)
      .pipe(
        take(1),
        tap((data) => {
          placeholders = data.filter((obj) => ids.includes(obj.id));
        }),
      )
      .subscribe();
    console.log(`promoting ${placeholders.length} placeholders`);
    this.placeholderItemPromoteService.promotePlaceholderItems(placeholders);
  }
  canInvoke(editorMode: string, ids: Array<string>, selectedCellInfo: SelectedCellInfo = null) {
    if (editorMode !== 'EDIT') {
      return false;
    }
    if (!ids?.length) {
      return false;
    }
    return true;
  }
}
