<div cdkDropListGroup class="matrix">
  <div *ngFor="let row of matrix; trackBy: matrixRowTrackByFn" class="matrix-row">
    <app-data-group
      *ngFor="let group of row.set; trackBy: groupTrackByFn"
      [group]="group"
      [editorMode]="editorMode"
      (onDrop)="drop($event)"
      [properties]="currentView.properties"
    >
    </app-data-group>
  </div>
</div>
