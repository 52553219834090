<div
  #scrollBar
  *ngIf="(scrollBarWidth$ | async) > 0"
  class="holder"
  [ngStyle]="{ width: 'calc(100vw - ' + (sidePanelWidth$ | async) + 'px)' }"
  (click)="handleClick($event)"
>
  <div
    id="horzScrollbar"
    [style.width.px]="barSize"
    class="bar"
    [cdkDragBoundary]="scrollBar"
    cdkDrag
    cdkDragLockAxis="x"
    (cdkDragMoved)="setScroll($event)"
    [cdkDragFreeDragPosition]="scrollDragPosition"
  ></div>
</div>
