import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppExtensionMessage, AppExtensionMessageHandler } from '@contrail/extensions-sdk';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { PlaceholderService } from '../placeholders/placeholder.service';
import { CollectionManagerActions } from '../collection-manager-store';

enum WEB_SDK_COMMAND {
  ADD_PLACEHOLDERS = 'plan:add_rows',
  SHOW_MESSAGE = 'plan:show_message',
  CLEAR_SELECTED_ROWS = 'plan:clear_selected_rows',
}

@Injectable({
  providedIn: 'root',
})
export class PlanWebSDKMessageHandler implements AppExtensionMessageHandler {
  constructor(
    private placeholderService: PlaceholderService,
    private snackBar: MatSnackBar,
    private store: Store<RootStoreState.State>,
  ) {}

  public async handleMessage(message: AppExtensionMessage) {
    const command = message.command;
    if (!command || !command.startsWith('plan:')) {
      return;
    }

    switch (command) {
      case WEB_SDK_COMMAND.ADD_PLACEHOLDERS: {
        const placeholdersToAdd = message.data;
        if (!placeholdersToAdd || !Array.isArray(placeholdersToAdd) || placeholdersToAdd.length === 0) {
          return;
        }

        await this.placeholderService.addPlaceholdersToPlan(placeholdersToAdd);
        break;
      }

      case WEB_SDK_COMMAND.SHOW_MESSAGE: {
        const alertMessage = message.data;
        if (typeof alertMessage !== 'string') {
          return;
        }

        this.snackBar.open(alertMessage, '', { duration: 5000 });
      }

      case WEB_SDK_COMMAND.CLEAR_SELECTED_ROWS: {
        this.store.dispatch(CollectionManagerActions.clearSelectedEntityIds());
      }
    }
  }
}
