<div class="progress h-48 flex-center flex-col w-full mb-6" *ngIf="!diff">
  <mat-spinner [diameter]="60"></mat-spinner>
  <div class="mt-5">Computing Difference</div>
</div>

<div class="diff flex-center mb-7" *ngIf="diff">
  <div class="text-success-dark">
    <mat-icon>add_circle_outline</mat-icon> <span>{{ diff.adds?.length || 0 }} Added</span>
  </div>
  <div class="text-error-light">
    <mat-icon svgIcon="drop-icon" class="!h-[24px] !w-[24px]"></mat-icon>
    <span>{{ diff.deletes?.length || 0 }} Dropped</span>
  </div>
  <div class="text-warn-dark">
    <mat-icon>change_history</mat-icon> <span>{{ diff.updates?.length || 0 }} Modified</span>
  </div>
</div>
