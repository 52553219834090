import { Injectable } from '@angular/core';
import { PropertyType, PropertyValueFormatter } from '@contrail/types';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { GridNavigationHandler } from './grid-navigation-handler';
import { GridViewManager } from './grid-view.manager';
import { SelectorCell } from './selectors/grid-selector.service';
import { ReplaySubject } from 'rxjs';

const formatter = new PropertyValueFormatter();

@Injectable({
  providedIn: 'root',
})
export class SearchReplaceService {
  data: Array<any>;
  currentViewProperties: Array<any>;
  activeSearchReplaceCell: SelectorCell;
  scrollVerticalPercentage: any;
  displayedData: Array<any>;
  toggleSearchReplace$: ReplaySubject<any> = new ReplaySubject(null);

  constructor(
    private store: Store<RootStoreState.State>,
    private gridViewManager: GridViewManager,
    private gridNavigationHandler: GridNavigationHandler,
  ) {
    this.store.select(CollectionManagerSelectors.displayedData).subscribe((data) => (this.data = data));
    this.store.select(CollectionManagerSelectors.currentViewProperties).subscribe((currentViewProperties) => {
      this.currentViewProperties = currentViewProperties.filter((property) => property.enabled === true);
    });

    this.store
      .select(CollectionManagerSelectors.displayedData)
      .subscribe((displayedData) => (this.displayedData = displayedData));
    this.store
      .select(CollectionManagerSelectors.scrollVerticalPercentage)
      .subscribe((scrollVerticalPercentage) => (this.scrollVerticalPercentage = scrollVerticalPercentage));
  }

  public toggleSearchReplace() {
    this.toggleSearchReplace$.next(true);
  }

  public search(keyword: string) {
    this.generateAffectedCells(keyword);
  }

  public setActiveSearchReplaceCell(activeSearchReplaceCell) {
    this.activeSearchReplaceCell = activeSearchReplaceCell;
    this.store.dispatch(CollectionManagerActions.setActiveSearchReplaceCell({ activeSearchReplaceCell }));
    this.scrollIfNecessary();
  }

  public resetSearchReplace() {
    this.store.dispatch(CollectionManagerActions.resetSearchReplace());
  }

  private generateAffectedCells(keyword: string) {
    const searchReplaceCells: Array<SelectorCell> = [];
    if (keyword.trim() !== '') {
      for (const property of this.currentViewProperties) {
        // look for header columns
        if (property.propertyDefinition.label?.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
          searchReplaceCells.push({ rowId: 'header', columnId: property.slug });
        }
      }
      for (const row of this.data) {
        for (const property of this.currentViewProperties) {
          const propertyType = property.propertyDefinition.propertyType;
          let displayData = this.getDisplay(row[property.slug], property).toString();
          if (displayData !== '' && propertyType !== 'image') {
            if ([PropertyType.Number, PropertyType.Currency, PropertyType.Percent].includes(propertyType)) {
              displayData = displayData.replace(/[$,%]/g, '');
            }
            if (displayData.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
              searchReplaceCells.push({ rowId: row.id, columnId: property.slug });
            }
          }
        }
      }
    }
    if (searchReplaceCells.length > 0) {
      this.activeSearchReplaceCell = searchReplaceCells[0];
      this.scrollIfNecessary();
    }
    this.store.dispatch(CollectionManagerActions.setSearchReplaceCells({ searchReplaceCells }));
  }

  private getDisplay(value, property) {
    return formatter.formatValueForProperty(value, property.propertyDefinition);
  }

  private scrollIfNecessary() {
    if (this.activeSearchReplaceCell) {
      this.gridNavigationHandler.goToCell(this.activeSearchReplaceCell.rowId, this.activeSearchReplaceCell.columnId);
    }
  }
}
