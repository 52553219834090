import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { RootStoreState } from 'src/app/root-store';
import { CollectionElementsActionTypes } from '../collection-elements/collection-elements.actions';

@Injectable()
export class CollectionStatusMessagesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private webSocketService: WebSocketService,
    private snackBar: MatSnackBar,
  ) {}

  addElement$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionElementsActionTypes.ADD_COLLECTION_ENTITY),
        tap((action: any) => {}),
      ),
    { dispatch: false },
  );
}
